import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {LeaseDocument, LeaseDocumentType} from "../../leasing.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LeaseDocumentsService {

  constructor(private http: HttpClient) {
  }

  getLeaseDocuments(leaseId: string): Observable<LeaseDocument[]> {
    return this.http.get<LeaseDocument[]>(this.getBaseUrl(leaseId));
  }

  getOneDocument(leaseId: string, docId: string): Observable<LeaseDocument> {
    return this.http.get<LeaseDocument>(this.getBaseUrl(leaseId) + `/${docId}`);
  }

  saveLeaseDocument(documentTypeCode: string, fileToUpload: File, leaseId: string): Observable<LeaseDocument> {
    const params = { documentTypeCode, leaseUuid: leaseId };

    const formData = new FormData();
    formData.append('file', fileToUpload);

    return this.http.post<LeaseDocument>(this.getBaseUrl(leaseId), formData, { params })
  }

  removeLeaseDocument(documentUuid: string, leaseId: string) {
    return this.http.delete<void>(this.getBaseUrl(leaseId) + `/${documentUuid}`);
  }

  public getDocumentTypes(): Observable<LeaseDocumentType[]> {
    const uri = environment.apiSharedServicesHost + `/console/types/document`;
    return this.http.get<LeaseDocumentType[]>(uri, { params: { includeInactive: true } });
  }

  public getDocumentFile(leaseId: string, docId: string): Observable<ArrayBuffer> {
    const options = { responseType: 'arraybuffer' as 'json' };
    return this.http.get<ArrayBuffer>(this.getBaseUrl(leaseId) + `/${docId}/file`, options);
  }

  getBaseUrl(leaseId: string): string {
    return environment.apiLeaseHost + `/console/manage-lease/${leaseId}/documents`;
  }
}
