export interface OfferingTemplateModel extends SharedTemplateModel {
  uuid: string | null;
  offeringUuid: string;
  baseTemplateUuid: string;
  templateName: string;
  templateCode: string;
  baseNotificationActive: boolean;
  notificationEnabled: boolean;
  fromAddress: string;
  replyToAddress: string;
}

export interface BaseTemplateModel extends SharedTemplateModel {
  uuid: string;
  templateName: string;
  templateCode: string;
  active: boolean;
  recipientList: string[];
}

export interface SharedTemplateModel {
  design: any;
  html: string;
  mergeTags: MergeTag[];
  type: OfferingNotificationType;

}


export enum OfferingNotificationType {
  SYSTEM = "SYSTEM",
  RETAILER = "RETAILER",
  LANDLORD = "LANDLORD"
}

export interface MergeTag {
  [fieldName: string]: {
    name: string,
    value: string,
    sample: string
  }
}

export type EmailTemplateModel = OfferingTemplateModel | BaseTemplateModel;
