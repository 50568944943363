<div class="dashboard-card full-width mb-3" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
     *ngIf="displayBestStats">
  <div fxLayout="column" fxLayoutGap="7px" *ngFor="let stat of bestMonthlyStats">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px">
      <div class="subtitle-default">{{ stat.statName ? stat.statName : null }}</div>
      <div class="heading-bold">{{ stat ? stat.statFormattedValue : '-' }}</div>
    </div>
    <div class="heading-regular">{{ stat ? stat.statMonth : '-' }}</div>
  </div>
</div>
