<div class="full-width page-content page-padding" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Roles and Permissions</span>
    <div fxLayoutGap="10px" fxFlexOffset="50px" >
      <button class="button-action" mat-flat-button (click)="addUserToRole()"><div class="button-text">Add</div></button>
      <button class="button-action" mat-flat-button (click)="revokeUserRole()"
              [disabled]="selection.selected.length == 0 || selection.selected.length == dataSource.data.length">
        <div class="button-text">Remove</div>
      </button>
    </div>
    <app-confirm-remove (confirmRemove)="revokeResponse($event)" *ngIf="revokeUserGrant"
                        [message]="'Do you want to remove the selected user(s) from their role'"></app-confirm-remove>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Retailer Managers</span>

    <div class="edit-table-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" fxFlexOffset="20px" *ngIf="addUserGrant">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div class="body-1-primary">NEW_ID</div>
        <mat-form-field class="custom-text-field ms-3" appearance="outline">
          <mat-label>User</mat-label>
          <input class="input-field" placeholder="Input User Details"
                 matInput type="text"
                 [formControl]="searchTerm" (input)="loadUsers()" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectNewUser($event)">
            <mat-option *ngFor="let option of allUsers" [value]="option">
              {{option.id}}. {{option.firstname}} {{option.lastname}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>


      <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <button class="button-primary" mat-flat-button (click)="saveUserGrant()">
          <mat-icon>check</mat-icon>
          <div class="button-text">Save</div>
        </button>

        <button class="button-close-top" mat-icon-button (click)="clearNewUserGrant()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

    </div>

    <div fxLayout="row" fxFlexOffset="25px">
      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlex="50">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns1.length"> Permissions </th>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.userId}} </td>
        </ng-container>

        <ng-container matColumnDef="firstname">
          <th mat-header-cell *matHeaderCellDef> First name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.userFirstname}} </td>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef> Last name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.userLastname}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.userEmail}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row class="custom-table-row btn-cursor" (click)="selectRole(row)"
            [class.selected-row]="selectedUser === row.id"
            *matRowDef="let row; columns: displayedColumns1;"></tr>
      </table>
    </div>

    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.data.length"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
