<div fxLayout="column">
  <app-page-header [section]="'Interests'" [title]="'Look who is interested'" [description]="'Don’t miss out on placement opportunities. Explore to see the retailers who logged an interest in what and when.'"></app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">
    <div fxFlexOffset="20px">
      <div fxLayout="row">
        <span class="subtitle-default">Applicants with specific interests</span>
        <div fxLayoutGap="10px" fxFlexOffset="50px">
<!--          <button class="button-action" mat-flat-button (click)="createLease()" [disabled]="selection.selected.length != 1 || (loader.loading$ | async)"><div class="button-text">Create a lease</div></button>-->
        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
      <span class="heading-default">Qualified applicants with interests</span>

      <form [formGroup]="filterForm" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 270px;">
              <mat-label>Industry And Product Collection</mat-label>
              <mat-select class="custom-select-field-value" formControlName="industryTypes" multiple [(value)]="selectedIndustries">
                <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
                  <mat-label class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" (click)="groupClicked(group)">
                    <span>{{group.name}}</span>
                    <mat-icon class="btn-cursor">add</mat-icon>
                  </mat-label>

                  <mat-option class="custom-select-field-option" *ngFor="let ind of group.industries" [value]="ind.code" (click)="optionClicked(group, ind)">{{ ind.name }}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Interest Offering</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="offeringType">
                <mat-option class="custom-select-field-option" *ngFor="let offering of platformOfferings" [value]="offering.code">{{offering.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <app-custom-date-filter [customDateRangePresets]="dateFilters" (dateRange)="getDateRange($event)"></app-custom-date-filter>
        </div>
      </form>


      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Interests </th>
        </ng-container>

        <ng-container matColumnDef="createDate">
          <th mat-header-cell *matHeaderCellDef> Submit Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.createDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="industryType">
          <th mat-header-cell *matHeaderCellDef> Industry or Product Collection </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayString(displayIndustryList(element.industryTypes), 30)}} </td>
        </ng-container>

        <ng-container matColumnDef="offering">
          <th mat-header-cell *matHeaderCellDef> Interest Offering </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{displayPlatformOffering(element.leaseTypeCode)}} </td>
        </ng-container>

        <ng-container matColumnDef="takeupTimeframe">
          <th mat-header-cell *matHeaderCellDef> Take-up Timeframe </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{displayTimeframe(element.takeupTimeframeCode)}} </td>
        </ng-container>

        <ng-container matColumnDef="interestPeriod">
          <th mat-header-cell *matHeaderCellDef> Interest Period </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{displayPeriod(element)}} </td>
        </ng-container>

        <ng-container matColumnDef="propValueSize">
          <th *matHeaderCellDef mat-header-cell> Store Size</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ element.properties[0].propCode === 'STORE_SIZE' ? element.properties[0].propValue : null }}
            <span *ngIf="element.properties[0].propCode === 'STORE_SIZE'">m<sup>2</sup></span></td>
        </ng-container>

        <ng-container matColumnDef="propValueSkus">
          <th *matHeaderCellDef mat-header-cell> No. of SKUs</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ element.properties[0].propCode === 'PRODUCT_NUM_SKU' ? element.properties[0].propValue : null }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th *matHeaderCellDef mat-header-cell>Action</th>
          <td *matCellDef="let element" class="custom-table-cell" mat-cell>
            <button (click)="createLease($event, element)" class="button-action" mat-flat-button>
              <div class="button-text">Create a lease</div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="openPipelineViewTab(row.retailerId)" *matRowDef="let row; columns: displayedColumns;" class="custom-table-row"
            mat-row></tr>
      </table>
      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="50"
                     [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
