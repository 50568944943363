import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {PageHeaderTab} from "../../../core/components/page-header/page-header-tabs.interface";

@Component({
  selector: 'app-run-products',
  templateUrl: './run-products.component.html',
  styleUrls: ['./run-products.component.scss']
})
export class RunProductsComponent extends BasePageComponent {
  tabs: PageHeaderTab[] = [
    {
      name: 'Products',
      isActive: true,
      code: "import_products"
    },
    {
      name: 'Inventory',
      isActive: false,
      code: "import_inventory"
    }
  ];

  activeTab: string = "import_products";

  constructor(injector: Injector) {
    super(injector);
  }

  changeTabs($event: PageHeaderTab): void {
    this.activeTab = $event.code;
  }

  getInitialTab(): PageHeaderTab {
    return this.tabs.at(0)!;
  }
}
