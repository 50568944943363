import {ChartConfiguration} from "chart.js";
import {chartColors} from "../chart-options/chart-colors.model";
import {defaultChartOptions, defaultRadarChartOptions} from "../chart-options/chart-options.model";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {ChartConfig, ChartDataConfig} from "../common/chart-config";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {DashboardsLeasingReportComponent} from "./dashboards-leasing-report.component";

export class LeasingDashboardChartConfig extends ChartConfig<DashboardsLeasingReportComponent> {
  constructor(componentInstance: DashboardsLeasingReportComponent,
              private chartOptionsService: ChartOptionsService,
              private dashboardUtils: DashboardUtilsService) {
    super(componentInstance);
  }

  get bestMonthlyStats(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestMonthlyStats = value;
      },
      error: err => console.log(err)
    };
  }

  get rentalIncome(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Rental Income'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[rentalIncomeChartConfig] service response -> completed");
        const rentalIncome1 = value.chartSeries[0];
        const rentalIncome2 = value.chartSeries[1];

        this.instance.rentalIncomeChart.chartName = value.name;

        this.instance.rentalIncomeChart.stats.rentalIncome1.statValue = Object(value.params)['rentalIncome1'];
        this.instance.rentalIncomeChart.stats.rentalIncome2.statValue = Object(value.params)['rentalIncome2'];

        this.instance.rentalIncomeChart.chartData = {
          labels: rentalIncome1.chartData.map((m: any) => m.label.slice(0, 3)),
          datasets: [
            {
              label: rentalIncome1.name,
              data: rentalIncome1.chartData.map((m: any) => m.data),
              order: 0,
              ...this.chartOptionsService.lineChart(chartColors.LIGHT_PURPLE)
            },
            {
              label: rentalIncome2.name,
              data: rentalIncome2.chartData.map((m: any) => m.data),
              order: 1,
              ...this.chartOptionsService.areaChart(chartColors.BLUE, chartColors.BLUE)
            },
          ]
        }
        this.instance.rentalIncomeChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.rentalIncomeChart.loading = false;
      }
    }
  }

  get rentalIncomeOccupancy(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', 100)!;

    this.chartOptionsService.setTooltips(chartOptions!, [], ['Rental Income'], ['Occupancy Percentage'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[rentalIncomeOccupancyChartConfig] service response -> completed");
        const occupancy = value.chartSeries[0];
        const rentalIncome = value.chartSeries[1];

        this.instance.rentalIncomeOccupancyChart.chartName = value.name;

        this.instance.rentalIncomeOccupancyChart.stats.avgOccupancy.statValue = Object(value.params)['avgOccupancy'];
        this.instance.rentalIncomeOccupancyChart.stats.rentalIncome.statValue = Object(value.params)['rentalIncome'];

        this.instance.rentalIncomeOccupancyChart.chartData = {
          labels: rentalIncome.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: occupancy.name,
              data: occupancy.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            },
            {
              label: rentalIncome.name,
              data: rentalIncome.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.BLUE)
            },
          ]
        }
        this.instance.rentalIncomeOccupancyChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.rentalIncomeOccupancyChart.loading = false;
      }
    }
  }

  get tradingDensityOccupancy(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', 100)!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Trading Density'], [], ['Occupancy Percentage'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[tradingDensityOccupancyChartConfig] service response -> completed");
        const occupancy = value.chartSeries[0];
        const tradingDensity = value.chartSeries[1];

        this.instance.tradingDensityOccupancyChart.chartName = value.name;

        this.instance.tradingDensityOccupancyChart.stats.avgOccupancy.statValue = Object(value.params)['avgOccupancy'];
        this.instance.tradingDensityOccupancyChart.stats.avgTradingDensity.statValue = Object(value.params)['avgTradingDensity'];

        this.instance.tradingDensityOccupancyChart.chartData = {
          labels: occupancy.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: occupancy.name,
              data: occupancy.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            },
            {
              label: tradingDensity.name,
              data: tradingDensity.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.areaChart(chartColors.MAROON, chartColors.MAROON)
            }
          ]
        }
        this.instance.tradingDensityOccupancyChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.tradingDensityOccupancyChart.loading = false;
      }
    }
  }

  get rentalIncomeTradingDensity(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y1')!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Trading Density'], ['Rental Income'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[rentalIncomeTradingDensityChartConfig] service response -> completed");
        const rentalIncome = value.chartSeries[0];
        const tradingDensity = value.chartSeries[1];

        this.instance.rentalIncomeTradingDensityChart.chartName = value.name;

        this.instance.rentalIncomeTradingDensityChart.stats.rentalIncome.statValue = Object(value.params)['rentalIncome'];
        this.instance.rentalIncomeTradingDensityChart.stats.avgTradingDensity.statValue = Object(value.params)['avgTradingDensity'];

        this.instance.rentalIncomeTradingDensityChart.chartData = {
          labels: rentalIncome.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: tradingDensity.name,
              data: tradingDensity.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.MAROON)
            },
            {
              label: rentalIncome.name,
              data: rentalIncome.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.BLUE)
            },
          ]
        }
        this.instance.rentalIncomeTradingDensityChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.rentalIncomeTradingDensityChart.loading = false;
      }
    }
  }

  get effectiveIncomeRentRatio(): ChartDataConfig {
    let chartOptions: ChartConfiguration<'bar'>["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setStackedAxis(chartOptions!, 'y');
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', 100)!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Effective Income'], [], ['Rent Ratio'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[effectiveIncomeRentRatioChartConfig] service response -> completed");
        const effectiveIncome = value.chartSeries[0];
        const rentRatio = value.chartSeries[1];

        this.instance.effectiveIncomeRentRatioChart.chartName = value.name;

        this.instance.effectiveIncomeRentRatioChart.stats.avgEffectiveIncome.statValue = Object(value.params)['avgEffectiveIncome'];
        this.instance.effectiveIncomeRentRatioChart.stats.avgRentRatio.statValue = Object(value.params)['avgRentRatio'];

        this.instance.effectiveIncomeRentRatioChart.chartData = {
          labels: rentRatio.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: effectiveIncome.name,
              data: effectiveIncome.chartData.map((m: any) => m.data),
              order: 1,
              ...this.chartOptionsService.barChart(chartColors.YELLOW)
            },
            {
              label: rentRatio.name,
              data: rentRatio.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.barChart(chartColors.GREEN_75)
            },
          ]
        }
        this.instance.effectiveIncomeRentRatioChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.effectiveIncomeRentRatioChart.loading = false;
      }
    }
  }

  get rentalIncomeByCategory(): ChartDataConfig {
    let chartOptions: ChartConfiguration<"radar">["options"] = JSON.parse(JSON.stringify(defaultRadarChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'r')!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Rental income by category'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[rentalIncomeByCategoryChartConfig] service response -> completed");
        const rentalIncome = value.chartSeries[0];

        this.instance.rentalIncomeByCategoryChart.chartName = value.name;

        this.instance.rentalIncomeByCategoryChart.stats.rentalIncome.statValue = Object(value.params)['rentalIncome'];
        this.instance.rentalIncomeByCategoryChart.chartData = {
          labels: rentalIncome.chartData.map((m: any) => this.dashboardUtils.getIndustryName(m.label).split(/\s(?!&)/)),
          datasets: [
            {
              label: rentalIncome.name,
              data: rentalIncome.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.radarChart(chartColors.LIGHT_PURPLE, chartColors.LIGHT_PURPLE_85)
            },
          ]
        }
        this.instance.rentalIncomeByCategoryChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.rentalIncomeByCategoryChart.loading = false;
      }
    }
  }

  get rentalIncomePerSqmByCategory(): ChartDataConfig {
    let chartOptions: ChartConfiguration<"radar">["options"] = JSON.parse(JSON.stringify(defaultRadarChartOptions))!;
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'r')!;
    this.chartOptionsService.setTooltips(chartOptions!, ['Rental income per m² by category'], [], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[rentalIncomePerSqmByCategoryChartConfig] service response -> completed");
        const rentalIncomePerSqm = value.chartSeries[0];

        this.instance.rentalIncomePerSqmByCategoryChart.chartName = value.name;

        this.instance.rentalIncomePerSqmByCategoryChart.stats.rentalIncomePerSqm.statValue = Object(value.params)['rentalIncomePerSqm'];
        this.instance.rentalIncomePerSqmByCategoryChart.chartData = {
          labels: rentalIncomePerSqm.chartData.map((m: any) => this.dashboardUtils.getIndustryName(m.label).split(/\s(?!&)/)),
          datasets: [
            {
              label: rentalIncomePerSqm.name,
              data: rentalIncomePerSqm.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.radarChart(chartColors.BRIGHT_PINK, chartColors.BRIGHT_PINK_85)
            },
          ]
        }
        this.instance.rentalIncomePerSqmByCategoryChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.rentalIncomePerSqmByCategoryChart.loading = false;
      }
    }
  }
}






