export enum chartColors {
  BLUE = '#15CCF2',
  BRIGHT_PINK = '#FF007C',
  BRIGHT_PINK_85 = 'rgba(255,0,124,0.85)',
  MAROON = '#5C1651',
  MAROON_85 = 'rgba(92,22,81,0.85)',
  YELLOW = '#FEBA03',
  YELLOW_85 = 'rgba(254,186,3,0.85)',
  YELLOW_75 = 'rgba(254,186,3,0.75)',
  GREEN = '#9F990F',
  GREEN_75 = 'rgba(159,153,15,0.75)',
  ORANGE = '#FE9E03',
  LIGHT_PURPLE = '#BB86FC',
  LIGHT_PURPLE_85 = 'rgba(187,134,252,0.85)',
  GREY = '#A2A2A0',
  LIGHT_PINK = '#F778A9',
  PASTEL_PINK = '#F8B8D0',
  DARK_PURPLE = '#4E09EA',
  DARK_GREEN = '#5EA03B',
  POINT = 'rgba(0, 0, 0, 0.87)'
}

export const industryColors = [
  {code: 'UNOCCUPIED', color: '#F8F8F8'},
  {code: 'HOME_FURNITURE', color: chartColors.BLUE},
  {code: 'ACCESSORIES_BAGS', color: chartColors.DARK_PURPLE},
  {code: 'ENTERTAINMENT', color: chartColors.DARK_GREEN},
  {code: 'LIFESTYLE', color: chartColors.MAROON},
  {code: 'BEAUTY_WELLNESS', color: chartColors.BRIGHT_PINK},
  {code: 'FOOD_DRINK', color: chartColors.GREEN},
  {code: 'FASHION', color: chartColors.YELLOW},
  {code: 'KIDS', color: chartColors.LIGHT_PURPLE}
]
