<div class="remove-panel p-2 pt-3" fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-icon class="confirm-warn-icon">error</mat-icon>
        <span class="footer-default">{{data.message}}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <button class="button-action" mat-flat-button [mat-dialog-close]="true"
            [disabled]="data.buttonConfirmDisabled || (loading$ | async)">
            <div class="button-text">{{data.buttonConfirmLabel}}</div>
        </button>
        <button class="button-action" mat-flat-button [mat-dialog-close]="false"
            [disabled]="data.buttonCancelDisabled || (loading$ | async)">
            <div class="button-text">{{data.buttonCancelLabel}}</div>
        </button>
    </div>
</div>