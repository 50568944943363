import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LeaseCreateMethod} from "../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {ServiceInterest, TakeupTimeframe} from "../interests.model";
import {UtilsService} from "../../../../shared/services/utils.service";
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {tap} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {PipelineService} from "../../pipeline/pipeline.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {DateRange} from "@angular/material/datepicker";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {PipelineViewComponent} from "../../pipeline/pipeline-view/pipeline-view.component";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {MatDialog} from "@angular/material/dialog";
import {IndustryCategory, IndustryType} from "../../industry.model";
import {LeaseSummary} from "../../../leasing/leasing.model";
import {
  CreateALeaseDialogComponent
} from "../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {InterestsService} from "../interests.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-interests-list',
  templateUrl: './interests-list.component.html',
  styleUrls: ['./interests-list.component.scss']
})
export class InterestsListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  displayedColumns: string[] = ['createDate', 'companyName', 'industryType', 'offering', 'takeupTimeframe', 'interestPeriod', 'propValueSize', 'propValueSkus', 'action'];
  dataSource = new GenericDatasource<ServiceInterest>(this.interestsService);

  allIndustries: IndustryCategory[] = [];
  selectedIndustries: string[] = [];

  platformOfferings = [{code: 'FLEXIBLE_LEASING', name: 'Your Space'}, {code: 'CONTAINER_MODEL', name: 'Our Space'}];

  filterForm: FormGroup;

  dateRange?: DateRange<Date> = new DateRange<Date>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 2, TimeFrames.DAYS),
    new CustomDateRangeModel('2', 1, TimeFrames.WEEK),
    new CustomDateRangeModel('3', 1, TimeFrames.MONTH),
  ]
  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private pipelineService: PipelineService,
              private interestsService: InterestsService,
              private tabService: TabManagementService,
              private matDialog: MatDialog,
              public loader: LoadingService) {
    super(injector);

    this.filterForm = new FormGroup({
      industryTypes: new FormControl([]),
      offeringType: new FormControl(null)
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<ServiceInterest>(this.interestsService);
    this.dataSource.loadData('/qualified-applicants', {size: '50', page: '0', sort: 'createDate,desc'},
      []);
    this.loadIndustries();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadInterests()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadInterests());
  }

  loadInterests() {
    const filters = [
      { name: 'industryTypes', val: this.filterForm.get('industryTypes')?.value },
      { name: 'leaseTypeCode', val: this.filterForm.get('offeringType')?.value },
      { name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange?.start!) },
      { name: 'toDate', val: this.dateRange?.end ? this.dateUtils.displayShortDate(this.dateRange?.end) : null },
    ]
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'createDate,desc'};
    this.dataSource.loadData('/qualified-applicants', page, filters);
  }

  loadIndustries() {
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
      }
    })
  }

  displayPlatformOffering(code: string): string {
    if (code === 'FLEXIBLE_LEASING') return 'Your Space';
    if (code === 'CONTAINER_MODEL') return 'Our Space';
    return '';
  }

  displayTimeframe(code: string): TakeupTimeframe {
    return TakeupTimeframe[code as keyof typeof TakeupTimeframe];
  }

  displayPeriod(interest: ServiceInterest): string {
    switch (interest.takeupTimeframeCode) {
      case 'MONTHS_1_TO_3':
        return `Between ${this.dateUtils.displayShortDate(interest.serviceStartDateMin)} and ${this.dateUtils.displayShortDate(interest.serviceStartDateMax)}`;
      case 'IMMEDIATE':
        return `From ${this.dateUtils.displayShortDate(interest.serviceStartDateMin)}`;
      case 'SPECIFIED':
        return interest.serviceEndDate ? `${this.dateUtils.displayShortDate(interest.serviceStartDateMin)} to ${this.dateUtils.displayShortDate(interest.serviceEndDate)}` : `From ${this.dateUtils.displayShortDate(interest.serviceStartDateMin)}`;
      case 'MONTHS_3_PLUS':
        return `From ${this.dateUtils.displayShortDate(interest.serviceStartDateMin)}`;
    }
    return '';
  }

  openPipelineViewTab(id: string) {
    let payload: PageEventInterface = {
      componentToRegister: PipelineViewComponent,
      pageName: 'Pipeline ' + id,
      pageHeader: this.offering!.label,
      data: { id: id },
      id: this.utils.generateUuidWithPrefix('leads'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'leads',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadInterests();
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
      }
    });
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
  }

  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  displayIndustryList(listString: string) {
    const list = listString.split(',').map(m => this.utils.displayStatus(m));
    return this.utils.displayListAsString(list);
  }

  createLease($event: Event, element: ServiceInterest) {
    $event.stopPropagation();
    const lease = new LeaseSummary({retailerId: element.retailerId, retailerCompanyName: element.companyName});
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: LeaseCreateMethod.NEW_LEASE_FOR_RETAILER,
        lease: lease,
        header: 'Create a new lease',
        offering: this.offering
      }
    })
  }

}
