import {Injectable} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of, timeout} from "rxjs";
import {catchError} from "rxjs/operators";
import {GenericService} from "../../../../shared/services/generic.service";
import {PaymentBatch, PaymentBatchGroupRequest} from "../../transactions/transactions.model";

@Injectable({
  providedIn: 'root'
})
export class RunTenantPayoutsService extends GenericService<PaymentBatch> {
  constructor(http: HttpClient) {
    super(http, environment.apiTransactHost + '/console/offering/district/accounts/run-tenant-payouts')
  }

  timeOutSecs = 300; // 5 minutes

  public importNewTransactions(startDate?: string, endDate?: string): Observable<any> {
    let body: object | null = { correlationId: null, startDate, endDate };
    return this.callEndpoint( this.getBaseUrl + `/import`, body);
  }

  public processPayoutBilling(): Observable<any> {
    return this.callEndpoint(this.getBaseUrl + `/billing`, null);
  }

  public createPaymentBatch(request: PaymentBatchGroupRequest[]): Observable<any> {
    let body: object | null = { correlationId: null, groupRequests: request };
    return this.callEndpoint(this.getBaseUrl + `/create-batch`, body);
  }

  public submitPaymentBatch(batchUuids: string[]): Observable<any> {
    let body: object | null = { correlationId: null, batchUuids };
    return this.callEndpoint(this.getBaseUrl + `/submit-batch`, body);
  }

  public getJobInfo(id: string): Observable<any> {
    const requestParams = new HttpParams()
      .set('sort', 'createDate,desc')
      .set('eventTypeCode', id);

    return this.http.get<any>(this.getBaseUrl + `/events`, {params: requestParams})
      .pipe(
        timeout( this.timeOutSecs * 1000),
        catchError(e => {
          console.log(e, 'timeout')
          return of(null)
        }));
  }

  callEndpoint(uri: string, body: object | null) {
    // @ts-ignore
    return this.http.post<any>(uri, body, {responseType: 'text'})
      .pipe(
        timeout( this.timeOutSecs * 1000),
        catchError(e => {
          console.log(e, 'timeout')
          return of(null)
        }));
  }

  get getBaseUrl() {
    return environment.apiTransactHost + '/console/offering/district/accounts/run-tenant-payouts';
  }
}

