<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-default">{{header}}</div>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px">
      <span class="body-3-default">Retailer</span>
      <mat-form-field class="custom-text-autofill" appearance="outline">
        <input matInput type="text" [value]="lease.retailerId + '. ' + lease.retailerCompanyName" disabled>
      </mat-form-field>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px">
      <span class="body-3-default">Choose a lease period</span>
      <mat-form-field class="custom-date-picker" appearance="outline" style="width: 100%;">
        <mat-date-range-input [rangePicker]="picker" [min]="minStart" [max]="maxEnd">
          <input matStartDate placeholder="Custom" [(ngModel)]="startDate"  *ngIf="!isLeaseSpacePastDated()"/>
          <input matStartDate placeholder="Custom" [value]="startDate" disabled *ngIf="isLeaseSpacePastDated()"/>
          <input matEndDate [(ngModel)]="endDate" (dateChange)="updateDates()"/>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker [startAt]="isLeaseSpacePastDated() ? startDate! : null"></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="isSubLease">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="body-3-default">Space</span>
        <span class="body-3-default" [class.error-form-heading]="sharedSpace.occupiedSpace > availableSpace">
          Available space: {{availableSpace}} / {{ parentSpace }} m²
        </span>
      </div>

      <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
        <input matInput type="number" placeholder="Input space" [(ngModel)]="sharedSpace.occupiedSpace" [disabled]="(edit && isLeaseSpacePastDated())">
      </mat-form-field>
    </div>

    <mat-error *ngIf="getErrorMessage() && isSubLease" class="link-error">{{ getErrorMessage() }}</mat-error>

    <div *ngIf="!edit && !isSubLease" class="full-width" fxLayout="column" fxLayoutGap="7px">
      <span class="body-3-default">Choose an available space</span>
      <table mat-table class="custom-table" matSort
             [dataSource]="dataSource">
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" class="custom-table-label"
              mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.districtSpaceId"
              *matCellDef="let element"> {{element.districtSpaceId}} </td>
        </ng-container>

        <ng-container matColumnDef="spaceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.spaceName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.spaceSize}} m<sup>2</sup> </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource ? dataSource.data.length : 0"
                     [hidePageSize]="true"
                     [pageSize]="5"></mat-paginator>
    </div>

  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="15px">
    <button class="button-primary" mat-flat-button (click)="saveSpaces()" [disabled]="loader.loading$ | async">
      <mat-icon>check</mat-icon>
      <div class="button-text">Save Changes</div>
    </button>
  </div>

</div>
