import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CardReader} from '../../../leasing.model';
import {DateUtilsService} from '../../../../../shared/services/dateUtils.service';
import {LeaseViewService} from '../../lease-view.service';

@Component({
  selector: 'app-payment-devices-settings-dialog',
  templateUrl: './payment-devices-settings-dialog.component.html',
  styleUrls: ['./payment-devices-settings-dialog.component.scss']
})
export class PaymentDevicesSettingsDialogComponent {
  reader: CardReader;
  cardReadersOriginal: CardReader[] = [];
  cardReaders: CardReader[] = [];
  availableCardReaders: { code: string, readers: CardReader[] }[] = [];
  newAvailableCardReaders: CardReader[] = [];
  newCardReaders: CardReader[] = [];
  isEditing: boolean;


  constructor(public dialogRef: MatDialogRef<PaymentDevicesSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dateUtils: DateUtilsService,
              private leaseViewService: LeaseViewService) {
    this.availableCardReaders = data.availableCardReaders;
    if (data.cardReader == null) {
      this.isEditing = false;
      this.reader = new CardReader({
        districtUuid: data.lease.districtUuid,
        leaseUuid: data.lease.uuid,
        retailerId: data.lease.retailerId,
        startDate: new Date()
      });
    } else {
      this.isEditing = true;
      this.reader = data.cardReader;
    }
    this.loadAvailableLeaseCardReaders(this.reader, !this.isEditing);
  }


  close() {
    this.dialogRef.close({save: false, cardReader: null});
  }

  saveChanges(): void {
    this.dialogRef.close({save: true, cardReader: this.reader})
  }

  cancelChanges(): void {
    this.dialogRef.close({save: false, cardReader: null});
  }

  getAvailableCardReaders(reader: CardReader): CardReader[] {
    if (this.availableCardReaders.length > 0) {
      const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
      return this.availableCardReaders[objIndex] ? this.availableCardReaders[objIndex].readers : [];
    }
    return [];
  }


  cardReaderFutureDated(reader: CardReader) {
    return new Date(reader.startDate!) > new Date();
  }

  loadAvailableLeaseCardReaders(reader: CardReader, newReader: boolean) {
    reader.startDate = this.dateUtils.displayShortDate(reader.startDate);
    reader.endDate = this.dateUtils.displayShortDate(reader.endDate);
    this.leaseViewService.getAvailableLeaseCardReaders(this.reader.leaseUuid!, this.dateUtils.displayShortDate(reader.startDate)!, this.dateUtils.displayShortDate(reader.endDate)!).subscribe({
      next: value => {
        if (!newReader) {
          const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
          if (objIndex < 0) {
            this.availableCardReaders.push({
              code: reader.cardReaderCode ? reader.cardReaderCode : 'new',
              readers: value
            });
          } else {
            this.availableCardReaders[objIndex].readers = value;
          }
        } else {
          this.newAvailableCardReaders = value;
        }
      },
      error: err => console.log(err)
    })
  }

  cardReadersOverlap() {
    let overlap = false;
    const allReaders = this.cardReaders.map(m => m);
    const newCardReaders = this.newCardReaders.filter(f => f.cardReaderCode != null);
    newCardReaders.length > 0 ? allReaders.push(...newCardReaders) : null;
    allReaders.forEach((reader1, i) => {
      allReaders.forEach((reader2, j) => {
        if (i != j) {
          overlap = overlap || (new Date(reader1.startDate!) <= new Date(reader2.endDate!) && new Date(reader1.endDate!) >= new Date(reader2.startDate!))
        }
      })
    })
    return overlap;
  }

}
