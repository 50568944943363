<div fxLayout="column">
  <app-page-header [section]="'Contacts'" [title]="'Manage your retailer information'" [description]="'The retailers module allows you to see all the relevant retailer information. This information automatically updates across all other functionalities as the retailer makes changes.'"></app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

    <div fxFlexOffset="20px">
      <div fxLayout="row">
        <span class="subtitle-default">Retailer List</span>
        <button class="button-action" mat-flat-button fxFlexOffset="50px"
                (click)="openCreateRetailer()"
                [disabled]="loader.loading$ | async">
          <div class="button-text">Create a contact</div>
        </button>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
      <span class="heading-default">Retailer Information</span>
      <form [formGroup]="filterForm" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">

          <mat-form-field class="custom-text-field ms-3" appearance="outline" floatLabel="always">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="companyName">
          </mat-form-field>

  <!--        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">-->
  <!--          <mat-label>Retailer</mat-label>-->
  <!--          <input matInput type="text" placeholder="Search here" formControlName="userName">-->
  <!--        </mat-form-field>-->

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Curation Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="curated">
              <mat-option class="custom-select-field-option" *ngFor="let status of curationStatuses" [value]="status">{{utils.displayStatus(status)}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Tenant Type</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" formControlName="tenantType">
              <mat-option class="custom-select-field-option" [value]="TenantType.TENANT">{{utils.displayStatus(TenantType.TENANT)}}</mat-option>
              <mat-option class="custom-select-field-option" [value]="TenantType.OFFERING">{{utils.displayStatus(TenantType.OFFERING)}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </form>

      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Retailers </th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.id"
              *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="retailer">
          <th mat-header-cell *matHeaderCellDef> Retailer </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.ownerFirstname + ' ' + element.ownerLastname, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="website">
          <th mat-header-cell *matHeaderCellDef> Website </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element" (click)="utils.openLinkInNewTab(element.websiteUrl)"> {{utils.displayString(element.websiteUrl, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="socialLink">
          <th mat-header-cell *matHeaderCellDef> Social Link </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element" (click)="utils.openLinkInNewTab(element.socialLink)"> {{utils.displayString(element.socialLink, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.ownerEmail"
              *matCellDef="let element"> {{utils.displayString(element.ownerEmail, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="tenantType">
          <th mat-header-cell *matHeaderCellDef> Tenant Type </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayStatus(element.tenantType)}} </td>
        </ng-container>

        <ng-container matColumnDef="curationStatus">
          <th mat-header-cell *matHeaderCellDef> Curation Status </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayStatus(element.curated)}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="openRetailerViewTab(row.id, row.companyName, row.tenantType)"></tr>
      </table>
      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>
