import {ChartConfig, ChartDataConfig} from "../common/chart-config";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardsLeadsReportComponent} from "./dashboards-leads-report.component";
import {ChartConfiguration} from "chart.js";
import {defaultChartOptions} from "../chart-options/chart-options.model";
import {chartColors} from "../chart-options/chart-colors.model";
import {DashboardUtilsService} from "../dashboard-utils.service";

export class LeadsDashboardChartConfig extends ChartConfig<DashboardsLeadsReportComponent> {
  constructor(componentInstance: DashboardsLeadsReportComponent,
              private chartOptionsService: ChartOptionsService,
              private dashboardUtils: DashboardUtilsService) {
    super(componentInstance);
  }

  get bestMonthlyStats(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestMonthlyStats = value;
      },
      error: err => console.log(err)
    };
  }

  get newQualifiedLeads(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setTooltips(chartOptions!, [], [], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[newQualifiedLeadsChartConfig] service response -> completed");
        const year1Leads = value.chartSeries[0];
        const year2Leads = value.chartSeries[1];

        this.instance.newQualifiedLeadsChart.chartName = value.name;

        this.instance.newQualifiedLeadsChart.stats.year1Leads.statValue = Object(value.params)['year1Leads'];
        this.instance.newQualifiedLeadsChart.stats.year2Leads.statValue = Object(value.params)['year2Leads'];

        this.instance.newQualifiedLeadsChart.chartData = {
          labels: year1Leads.chartData.map((m: any) => m.label.slice(0, 3)),
          datasets: [
            {
              label: year1Leads.name,
              data: year1Leads.chartData.map((m: any) => m.data),
              order: 0,
              ...this.chartOptionsService.lineChart(chartColors.LIGHT_PURPLE)
            },
            {
              label: year2Leads.name,
              data: year2Leads.chartData.map((m: any) => m.data),
              order: 1,
              ...this.chartOptionsService.areaChart(chartColors.PASTEL_PINK, chartColors.PASTEL_PINK)
            },
          ]
        }
        this.instance.newQualifiedLeadsChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.newQualifiedLeadsChart.loading = false;
      }
    }
  }

  get approvalRates(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y', 100)!;
    this.chartOptionsService.setTooltips(chartOptions!, [], [], ['Approval Rate Percentage'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[approvalRatesChartConfig] service response -> completed");
        const approvalRate = value.chartSeries[0];

        this.instance.approvalRatesChart.chartName = value.name;

        this.instance.approvalRatesChart.stats.averageApprovalRate.statValue = Object(value.params)['averageApprovalRate'];
        this.instance.approvalRatesChart.stats.averageLeadTime.statValue = Object(value.params)['averageLeadTime'];

        this.instance.approvalRatesChart.chartData = {
          labels: approvalRate.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: approvalRate.name,
              data: approvalRate.chartData.map((m: any) => m.data),
              order: 0,
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            }
          ]
        }
        this.instance.approvalRatesChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.approvalRatesChart.loading = false;
      }
    }
  }

  get leadCategories(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', 100)!;
    this.chartOptionsService.setAutoSkipValue(chartOptions!, 'x', false);
    this.chartOptionsService.setTooltips(chartOptions!, [], [], ['Average Percentage of Leads'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[leadCategoriesChartConfig] service response -> completed");
        const leadsAmount = value.chartSeries[0];
        const leadsPercentage = value.chartSeries[1];

        this.instance.leadCategoriesChart.chartName = value.name;

        this.instance.leadCategoriesChart.stats.categoriesWithLeads.statValue = Object(value.params)['categoriesWithLeads'];

        this.instance.leadCategoriesChart.chartData = {
          labels: leadsAmount.chartData.map((m: any) => this.dashboardUtils.getIndustryName(m.label).split(/\s(?!&)/)),
          datasets: [
            {
              label: leadsAmount.name,
              data: leadsAmount.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y',
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            },
            {
              label: leadsPercentage.name,
              data: leadsPercentage.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y1',
              ...this.chartOptionsService.barChart(chartColors.LIGHT_PURPLE)
            },
          ]
        }
        this.instance.leadCategoriesChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.leadCategoriesChart.loading = false;
      }
    }
  }
}
