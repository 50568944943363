import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-preview-application-form',
  templateUrl: './preview-application-form.component.html',
  styleUrls: ['./preview-application-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PreviewApplicationFormComponent implements OnInit, OnDestroy {
  @ViewChild("nterAppFormEmbedContainer", { static: true }) embedContainer!: ElementRef;
  locationCode!: string;
  offeringCode!: string;
  formState: string = 'preview';
  formCode!: string;
  // todo take this out
  embedKey!: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.locationCode = this.route.snapshot.queryParamMap.get('locationCode')!;
    this.offeringCode = this.route.snapshot.queryParamMap.get('offeringCode')!;
    this.embedKey = this.route.snapshot.queryParamMap.get('embedKey')!;
    this.formCode = this.route.snapshot.paramMap.get('formCode')!;

    this.loadCustomElement();
  }

  ngOnDestroy(): void {
    // Clean up script and custom element on destroy
    const container = this.embedContainer.nativeElement;
    container.innerHTML = '';
  }

  public loadCustomElement() {
    const container = this.embedContainer.nativeElement;

    if (container) {
      // Clear the container
      container.innerHTML = '';

      const scriptElement = document.createElement('script');
      scriptElement.src = environment.apiAppForm;

      // Create the custom element
      const customElement = document.createElement('nter-app-form');
      customElement.setAttribute('location-code', this.locationCode);
      customElement.setAttribute('offering-code', this.offeringCode);
      customElement.setAttribute('mode', this.formState);
      customElement.setAttribute('code', this.formCode);
      customElement.setAttribute('embed-key', this.embedKey);

      container.appendChild(scriptElement);
      container.appendChild(customElement);
    }
  }

  protected readonly environment = environment;
}
