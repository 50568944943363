<div class="full-width page-content page-padding" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Billing Details</span>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px" *ngIf="billingDetails">
    <div class="info-card" fxLayout="column">
      <span class="heading-default">Company Billing Details</span>
      <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">

        <mat-form-field class="custom-text-autofill" appearance="outline">
          <mat-label>Company Name</mat-label>
          <input matInput type="text" [value]="utils.displayString(billingDetails.billingName, 50)" disabled>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="35">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Company Registration Number</mat-label>
              <input matInput type="text" [value]="utils.displayString(billingDetails.compReg, 50)" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="65">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>VAT Registration Number</mat-label>
              <input matInput type="text" [value]="utils.displayString(billingDetails.vatReg, 50)" disabled>
            </mat-form-field>
          </div>
        </div>

<!--        location-->

        <mat-form-field class="custom-text-autofill" appearance="outline">
          <mat-label>Billing Address</mat-label>
          <input matInput type="text" [value]="utils.displayString(billingDetails.formattedAddress, 50)" disabled>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="35">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" [value]="utils.displayString(billingDetails.billingEmail, 50)" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="65">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input matInput type="tel" [value]="utils.displayString(billingDetails.phoneNum, 50)" disabled>
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
