<div fxLayout="column" class="view-container">

    <app-page-header [backgroundWhite]="true"
                     [bottomBorder]="true"
                     [section]="'Lease Planning'"
                     [title]="'Manage your occupancy'">
    </app-page-header>

    <mat-sidenav-container class="sidenav-container body-1-regular-2">
        <!-- Left sidenav -->
        <!-- <mat-sidenav #leftSidenav mode="side" opened class="sidenav-left">
        </mat-sidenav> -->

        <!-- Right sidenav -->
        <mat-sidenav #rightSidenav mode="side" position="end" class="sidenav-right"
                     [fixedInViewport]="false" [fixedTopGap]="0"
                     [fixedBottomGap]="0">

            <div class="sidenav-right-content" fxLayout="column" fxLayoutGap="15px">
                <app-lease-space-info-panel [selectedSpace]="selectedSpace!"
                                            [selectedLeaseSpaceGroup]="selectedLeaseSpace"
                                            [statSpaceStatistics]="statSpaceStatistics"
                                            [leaseSummary]="selectedLease"
                                            [offering]="offering"
                                            [(timelineDrawerOpen)]="timelineDrawerOpened"
                                            [editTimeline]="spaceChangesDirty()"
                                            (updateLeaseSpace)="updateLeaseSpace()"
                                            (save)="savePlannedLeases()"
                                            (cancel)="cancelChanges()"
                                            (removeLeaseSpace)="removeLeaseSpace($event)"
                                            (switchTab)="switchTabs($event)"></app-lease-space-info-panel>
            </div>

        </mat-sidenav>

        <!-- Main content -->
        <mat-sidenav-content cdkScrollable class="sidenav-main-content" >
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
              <ng-container *ngIf="selectedTab === 'lease_list'">
                <app-planned-leases-list
                  (spaceSelected)="selectSpace($event)"
                  (selectedLeaseGridSpace)="selectLeaseSpace($event)"
                  [drawerOpened]="timelineDrawerOpened"
                  #timelineComponent>
                </app-planned-leases-list>
                <ng-container *ngTemplateOutlet="leaseDrawer"></ng-container>

              </ng-container>

              <ng-container *ngIf="selectedTab === 'timeline'">
                <app-timeline *ngIf="timeline" #timelineComponent
                              [timeline]="timeline"
                              [editing]="spaceChangesDirty()"
                              (clickEl)="selectSpace($event)"
                              (clickObj)="selectLeaseSpace($event)"
                              (addNewObj)="addNewLeaseSpaceGroup($event)"
                              (updateObj)="updateLeaseSpaceGroup($event)"
                              (scroll)="updateDateRange($event)"
                              (emitAction)="openSpacesTab($event)"
                              [drawerOpened]="timelineDrawerOpened"
                              (searchList)="filterSpaces($event)">
                </app-timeline>
                <ng-container *ngTemplateOutlet="leaseDrawer"></ng-container>
              </ng-container>
            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>

</div>
<ng-template #leaseDrawer>
  <app-responsive-drawer [drawerOpened]="timelineDrawerOpened"
                         (onDrawerClose)="timelineDrawerOpened = false">
    <ng-template #drawerContent>
      <app-retailers-pipeline
        [(selectedLeaseSpaceGroup)]="selectedLeaseSpace"
        [offering]="this.offering"
        (addRetailer)="addLeaseGridSpaceToGroup($event)"
        (removeRetailer)="removeLeaseGridSpaceFromGroup($event)"
        [selectedSpace]="selectedSpace">
      </app-retailers-pipeline>
    </ng-template>
  </app-responsive-drawer>
</ng-template>
