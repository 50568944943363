import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UpdatePasswordService} from '../service/update-password.service';
import {MatDialog} from '@angular/material/dialog';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../core/services/loading.service";


@Component({
  selector: 'app-check-your-email',
  templateUrl: './check-your-email.component.html',
  styleUrls: ['./check-your-email.component.scss'],
})
export class CheckYourEmailComponent {
  public errMessage: string | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: UpdatePasswordService,
    private dialogService: MatDialog,
    public loader: LoadingService
  ) {
    this.errMessage = null;
  }

  public loading = false;

  onSubmit(): void {
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.router.navigate([`${clientId}/forgot-password/password-reset`]);
  }

  onResendOTP(): void {
    this.loading = true;
    lastValueFrom(this.service.requestNewOTP(true))
      .then(
        (res) => {
          this.loading = false;
          this.dialogService.open(HTMLDialogElement, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Resend OTP',
              description: 'We have sent a One Time Pin to the provided email.',
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
        },
        (err) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
              }
              break;
            default:
              console.log(err);
              this.dialogService.open(HTMLDialogElement, {
                height: '300px',
                width: '450px',
                data: {
                  header: 'Oops!',
                  description: 'An unexpected error occurred',
                },
              });
          }
        }
      );
  }
}
