import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {lastValueFrom} from 'rxjs';
import {IconPopupComponent} from '../../../../../shared/components/dialogs/icon-popup/icon-popup.component';
import {ErrorPopupComponent} from '../../../../../shared/components/dialogs/error-popup/error-popup.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UpdatePasswordService} from '../../../../../features/password-reset/service/update-password.service';

@Component({
  selector: 'app-password-change-otp-dialog',
  templateUrl: './password-change-otp-dialog.component.html',
  styleUrls: ['./password-change-otp-dialog.component.scss']
})
export class PasswordChangeOtpDialogComponent {
  public otpForm;
  public errMessage: string | null;
  public loading = false;
  public len = 0;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  otpInputConfig = {
    length: 4,
    allowNumbersOnly: true,
    containerClass: 'otp-wrapper-element',
    inputClass: 'otp-input-element'
  };

  constructor(public dialogRef: MatDialogRef<PasswordChangeOtpDialogComponent>,
              public updatePasswordService: UpdatePasswordService,
              private dialogService: MatDialog,
              private fb: FormBuilder) {
    this.otpForm = this.resetForm();
    this.errMessage = null;
  }

  onVerify() {
    const otp = this.otpForm.value.pin;
    this.updatePasswordService.updateOtp(otp);

    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.updatePasswordService.updatePassword())
      .then((res) => {
          this.loading = false;
          this.dialogService.open(IconPopupComponent, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Password Reset',
              description: 'Your password has successfully been updated!',
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
        },
        (err) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('otp')!.setValue(null);
              }
              break;
            default:
              console.log(err);
              this.dialogService.open(ErrorPopupComponent, {
                height: '300px',
                width: '450px',
                data: {
                  header: 'Oops!',
                  description: 'An unexpected error occurred',
                },
              });
          }
        }
      );
  }

  close(): void {
    this.dialogRef.close();
  }

  onOtpChange(event: string) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }
}
