<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-default">Import Report</div>
    </div>

    <div fxLayout="column">
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Total</span>
        <mat-form-field class="custom-text-autofill" style="width: 100%;" appearance="outline">
          <input class="input-field" matInput type="text" [value]="report.total">
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="report.successful > 0">
        <span class="body-3-default">Success</span>
        <mat-form-field class="custom-text-autofill" style="width: 100%;" appearance="outline">
          <input class="input-field" matInput type="text" [value]="report.successful">
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="report.replaced > 0">
        <span class="body-3-default">Replaced</span>
        <mat-form-field class="custom-text-autofill" style="width: 100%;" appearance="outline">
          <input class="input-field" matInput type="text" [value]="report.replaced">
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="report.ignored > 0">
        <span class="body-3-default">Ignored</span>
        <mat-form-field class="custom-text-autofill" style="width: 100%;" appearance="outline">
          <input class="input-field" matInput type="text" [value]="report.ignored">
        </mat-form-field>
      </div>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="report.error > 0">
      <span class="body-3-default">Error</span>
      <mat-form-field class="custom-text-autofill" style="width: 100%;" appearance="outline">
        <input class="input-field" matInput type="text" [value]="report.error">
      </mat-form-field>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="report.error > 0">
      <span class="body-3-default">Error Messages</span>
      <table mat-table class="custom-table" matSort
             [dataSource]="dataSource">
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" class="custom-table-label" mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ getItem(element) }} </td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayString(getMessage(element), 200) }}</td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource ? dataSource.data.length : 0"
                     [hidePageSize]="true"
                     [pageSize]="5"></mat-paginator>
    </div>

  </div>

</div>
