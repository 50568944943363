<div class="full-screen-height-page-banner" fxLayout="column" fxLayoutGap="50px">
  <div class="subtitle-default" *ngIf="changeContext">
    All Available Properties
  </div>
  <div class="properties-container full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div *ngFor="let property of properties">
      <mat-card class="property-card btn-cursor" (click)="selectProperty(property)">
        <div class="mat-card-image-container">
          <img class="overlay-under-image" src="{{property.bannerImage}}" alt="Photo of a {{property.locationName}}">
          <div *ngIf="showOfferingsOnHover" class="overlay" fxLayoutGap="15px">
            <button class="button-outline" *ngFor="let offering of property.locationOfferings" style="background-color: white !important;">
              <div class="button-text">{{offering.offeringName}}</div>
            </button>
          </div>
        </div>
        <mat-card-content fxFlexOffset="20px">
          <div class="heading-regular">{{property.locationName}}</div>
          <div class="heading-regular">{{property.locationAddress.formattedAddress}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
