<div fxLayout="column">

  <app-page-header [section]="'Retailer in leads view'" [title]="'View specific retailer information'" [description]="'This is specific retailer in your leads that applied to your retail offering categorised according to their qualification status.'"></app-page-header>

  <div fxLayout="column" class="page-content full-screen-height-page-default">

    <app-retailer-details *ngIf="retailerId" [createLease]="true" [header]="'Retailer Details'"
                          [id]="retailerId" [offering]="this.offering!"></app-retailer-details>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <app-applicant-results [id]="retailerId" *ngIf="retailerId"></app-applicant-results>

  </div>

</div>
