<div fxLayout="column">

  <app-page-header
    [description]="'This is the internal system pay-out module to setup and create payment batches for automatic pay-outs to clients.'"
    [isTabbed]="false"
    [section]="'Run Pay-Outs'"
    [title]="'Setup your client pay-outs to run smoothly'"></app-page-header>

  <div class="page-content page-padding" fxLayout="column">
    <app-payout-jobcard [jobType]="BatchType.DISTRICT_PAYOUT"></app-payout-jobcard>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <app-payment-batches-list [batchType]="BatchType.DISTRICT_PAYOUT"></app-payment-batches-list>
  </div>

</div>
