import {UPDATE_PASSWORD_OTP_TYPE} from '../model/update-password-otp-type.enum.model';

export class UpdatePasswordContextModel {
  constructor(email: string | null,
              userId: number | null,
              secret: string | null,
              confirmSecret: string | null,
              otp: string | null,
              updateType: UPDATE_PASSWORD_OTP_TYPE | null) {
    this._email = email;
    this._userId = userId;
    this._secret = secret;
    this._confirmSecret = confirmSecret;
    this._otp = otp;
    this._updateType = updateType;
  }

  private _email: string | null;

  get email(): string | null {
    return this._email;
  }

  set email(value: string | null) {
    this._email = value;
  }

  private _userId: number | null;

  get userId(): number | null {
    return this._userId;
  }

  set userId(value: number | null) {
    this._userId = value;
  }

  private _secret: string | null;

  get secret(): string | null {
    return this._secret;
  }

  set secret(value: string | null) {
    this._secret = value;
  }

  private _confirmSecret: string | null;

  get confirmSecret(): string | null {
    return this._confirmSecret;
  }

  set confirmSecret(value: string | null) {
    this._confirmSecret = value;
  }

  private _otp: string | null;

  get otp(): string | null {
    return this._otp;
  }

  set otp(value: string | null) {
    this._otp = value;
  }

  private _updateType: UPDATE_PASSWORD_OTP_TYPE | null;

  get updateType(): UPDATE_PASSWORD_OTP_TYPE | null {
    return this._updateType;
  }

  set updateType(value: UPDATE_PASSWORD_OTP_TYPE | null) {
    this._updateType = value;
  }

  public static getEmptyContext(): UpdatePasswordContextModel {
    return new UpdatePasswordContextModel(
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
