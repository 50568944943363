import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {BlockingQueueService} from "../../../shared/services/blocking-queue.service";
import {MonthlyRetailerStats, RetailerProductPerformance} from "../dashboards.model";

export class DashboardsRetailerReportService extends BlockingQueueService {
  private http: HttpClient

  constructor(http: HttpClient) {
    super();
    this.http = http;
  }

  getBaseUrl(retailerId: number): string {
    return environment.apiTransactHost + `/console/offering/district/dashboard/retailer-report/${retailerId}`;
  }

  refreshBestMonthlyStats(retailerId: number, callback: (data: any) => void, error: (data: any) => void): void {
    const task = () => this.http.get<MonthlyRetailerStats>(this.getBaseUrl(retailerId) + '/best-stats');
    this.enqueue(task, callback, error);
  }

  refreshSalesPerformanceChart(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-performance', retailerId);
  }

  refreshRentalRateRentRatioChart(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rent-performance', retailerId);
  }

  refreshAvgBasketSizeChart(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'basket-size', retailerId);
  }

  refreshBusiestSalesTimesChart(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-times', retailerId);
  }

  refreshBusiestSalesDaysChart(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-days', retailerId);
  }

  refreshBestSellers(retailerId: number, fromDate: string, toDate: string, topPercent: number, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('topPercentage', topPercent);
    const task = () => this.http.get<RetailerProductPerformance[]>(this.getBaseUrl(retailerId) + '/best-sellers', {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshInventoryForecast(retailerId: number, fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<RetailerProductPerformance[]>(this.getBaseUrl(retailerId) + '/inventory-forecast', {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void, urlSuffix: string, retailerId: number): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl(retailerId) + `/${urlSuffix}`, {params: requestParams});
    this.enqueue(task, callback, error);
  }
}
