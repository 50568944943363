<div class="full-width page-content page-padding" fxLayout="column">
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="subtitle-default">Suitability and results</span>
    </div>
    <div *ngIf="!(recommendation && applicantAssessment && applicantAssessment.scoringBreakdown)" fxFlexOffset="20px">
      <app-info-panel
        [message]="['Scoring is not available for this application']" [type]="PanelType.WARN"></app-info-panel>
    </div>
    <div *ngIf="applicantAssessment && applicantAssessment.applicationSource == 'SYSTEM'" fxFlexOffset="20px">
      <app-info-panel
        [message]="['This tenant was manually created and therefore may have an incomplete assessment']" [type]="PanelType.WARN"></app-info-panel>
    </div>
  </div>

  <div class="info-card form-info-card px-0" fxLayout="column" fxFlexOffset="20px" *ngIf="applicantAssessment">

    <div class="px-3" fxLayout="column" *ngIf="recommendation && applicantAssessment.scoringBreakdown">
      <div class="heading-default">Suitability Score</div>
      <div fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="10px">
        <div class="p-3 pe-5" fxFlex="60" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutGap="10px">
            <div class="rating-bar-container" *ngFor="let bar of ratingBars.slice(0, 3)">
              <div class="rating-bar" [ngStyle]="{'width': bar.value + '%', 'background-color': bar.color}"></div>
              <div class="rating-bar-text ps-3" fxLayout="row" fxLayoutGap="40px">
                <span>{{bar.value}}%</span>
                <span [innerHTML]="bar.label"></span>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="rating-bar-container">
            <div class="rating-bar" [ngStyle]="{'width': ratingBars[3].value + '%', 'background-color': ratingBars[3].color}"></div>
            <div class="rating-bar-text ps-3" fxLayout="row" fxLayoutGap="40px">
              <span>{{ratingBars[3].value}}%</span>
              <span [innerHTML]="ratingBars[3].label"></span>
            </div>
          </div>

        </div>

        <div fxLayout="row" fxLayoutGap="15px" fxFlex="40">
          <div fxFlex="50" style="height: 200px">
            <mat-progress-spinner class="score-progress-spinner" mode="determinate" color="primary"
                                  diameter="200" strokeWidth="20"
                                  [value]="applicantAssessment.overallScore">
            </mat-progress-spinner>
            <div class="score-progress-spinner-bg">
              <svg width="188" height="188" viewBox="0 0 210 210">
                <circle cx="50%" cy="50%" r="100" fill="transparent" stroke-width="8"/>
                <text class="score-progress-spinner-score" x="50%" y="48%" text-anchor="middle">{{applicantAssessment.overallScore}}%</text>
                <text class="score-progress-spinner-text" x="50%" y="60%" text-anchor="middle">Overall score</text>
              </svg>
            </div>

          </div>
          <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div>
              <app-confirm-remove (confirmRemove)="rejectApplicant($event)" *ngIf="removePanelOpen"
                                  [message]="'Do you want to reject this retailer for your curated leads?'"></app-confirm-remove>
            </div>
            <div *ngIf="!curated" class="heading-regular" style="text-align: center">{{recommendation.message}}</div>
            <div *ngIf="curated" class="heading-regular" style="text-align: center">Application status:</div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
              <button (click)="removePanelOpen = true" *ngIf="!curated"
                      class="button-outline-white" mat-flat-button [disabled]="(loader.loading$ | async)">
                <div class="button-text">Reject</div>
              </button>
              <button (click)="curateApplicant(true)" *ngIf="!curated"
                      class="button-primary" mat-flat-button [disabled]="(loader.loading$ | async)">
                <div class="button-text">Approve</div>
              </button>
              <button *ngIf="curated" class="button-primary" mat-flat-button>
                <div class="button-text">{{getApplicantStatus().status}}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider fxFlexOffset="15px" *ngIf="applicantAssessment.scoringBreakdown"></mat-divider>

    <div class="px-3" fxLayout="column" fxFlexOffset="20px">
      <div class="heading-default">Results</div>

      <div *ngFor="let cat of sortItemsByDisplayOrder(applicantAssessment.payload.categories); let i = index" style="padding-top: 20px">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="50px">

          <div *ngFor="let questionGroup of sortItemsByDisplayOrder(cat.questionGroups); let j = index; let last = last"
               class="full-width ps-0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">

            <div class="question-rating-container">
              <div *ngIf="!canBeScored(questionGroup); else currentWeightBar" class="body-1-regular-2">{{displayCantBeScoredMessage(questionGroup)}}</div>
              <ng-template #currentWeightBar>
                <mat-slider class="weight-slider" [class.mdc-slider--disabled]="false" max="4" min="0" step="1" showTickMarks disabled>
                  <input [value]="questionGroup.importanceRating" matSliderThumb/>
                </mat-slider>
              </ng-template>

              <div *ngIf="canBeScored(questionGroup)" class="body-1-regular-2">This question is rated
                <span class="body-1-default">{{getQuestionGroupImportanceRating(questionGroup)}}</span>
              </div>
            </div>

            <div class="full-width" fxFlexOffset="15px">
              <mat-divider class="mat-divider" style="margin-left: 34px"></mat-divider>
            </div>

            <div *ngIf="!questionGroup.repeatedInput; else repeatingQuestionTemplate" class="full-width px-4 ps-0"
                 fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start start" fxLayoutGap="5px"
                 fxLayoutGap.gt-sm="10px">

              <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
                <mat-icon class="more-icon">more_vert</mat-icon>
              </div>

              <div
                *ngFor="let question of sortItemsByDisplayOrder(questionGroup.questions); let k = index; let last = last"
                class="full-width question-label-and-answer-container" fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="5px" fxLayoutGap.gt-sm="15px">

                <ng-container *ngIf="getQuestionTemplate(question); let qt">
                  <div class="full-width" fxLayout="column" fxLayoutGap="20px">
                    <pre class="heading-bold pt-1">{{applicantAssessment.payload!.formCode!.includes('MVP') ? qt.controlPayload.label : qt.controlDescription}}</pre>

                    <pre class="heading-regular" *ngIf="qt.controlType == ControlType.HEADING_TEXT">{{qt.controlPayload.label}}</pre>

                    <div>
                      <div class="body-1-default full-width" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="20px"
                           *ngIf="!(qt.controlType == ControlType.HEADING || qt.controlType == ControlType.HEADING_TEXT)">
                        <div class="application-result-input-box input-margin" fxFlex>

                          <ng-container *ngIf="isControlSelectMultiple(qt)">
                            <div fxLayout="column" class="py-3" fxLayoutGap="20px">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngFor="let answer of getQuestionValue(question)">
                                <span *ngIf="isControlSelectMultiple(qt)" class="material-icons-outlined input-box-icon">check_box_outline_blank</span>
                                <span class="body-1-regular-1">{{answer.label}}</span>
                              </div>
                            </div>
                          </ng-container>

                          <div fxLayout="row" fxLayoutGap="10px" *ngIf="qt.controlType != ControlType.FILE && qt.controlType != ControlType.INFO_FILE">
                            <span *ngIf="isControlSelectSingle(qt)" class="material-icons-outlined input-box-icon">radio_button_unchecked</span>
                            <span *ngIf="!isControlSelectMultiple(qt)" class="body-1-regular-1">{{getQuestionValue(question).length == 0 ? '-' : getQuestionValue(question)}}</span>
                          </div>

                          <div class="pe-2 py-3 full-width" fxLayout="column" fxLayoutGap="20px"
                               *ngIf="qt.controlType == ControlType.FILE">
                            <div *ngFor="let file of getFileQuestionValues(question)"
                                 fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutGap="15px">
                                <span class="body-1-default">{{file.fileLabel}}: </span>
                                <span class="body-1-regular-1">{{file.fileValue ? file.fileValue.fileName : ''}}</span>
                              </div>
                              <button  mat-flat-button class="button-action" [disabled]="!file.fileValue"
                                       (click)="downloadFormFile(file.fileValue!, generateQuestionFilter(cat.uuid, questionGroup.uuid, question.uuid, file.fileValue!.fileCode))">
                                <div class="button-text">Download</div>
                              </button>
                            </div>
                          </div>

                          <div class="pe-2 py-3 full-width" fxLayout="column" fxLayoutGap="20px"
                               *ngIf="qt.controlType == ControlType.INFO_FILE">
                            <div *ngFor="let file of getFileQuestionValues(question)"
                                 fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutGap="15px">
                                <span class="body-1-default">{{file.fileLabel}}: </span>
                                <span class="body-1-regular-1">{{file.fileValue ? file.fileValue.fileName : ''}}</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="answer-rating-container" fxFlex="238px">
                          <ng-container *ngIf="canBeScored(questionGroup)">

                            <svg *ngIf="isPassingScore(question)" class="badge-rating-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24.119 31.656" >
                              <g transform="translate(-11762.98 -10751.5)">
                                <path d="M163.166-848.344V-859.8a10.665,10.665,0,0,1-2.431-3.881,12.872,12.872,0,0,1-.735-4.259,11.668,11.668,0,0,1,3.467-8.592A11.668,11.668,0,0,1,172.059-880a11.668,11.668,0,0,1,8.592,3.467,11.668,11.668,0,0,1,3.467,8.592,12.872,12.872,0,0,1-.735,4.259,10.665,10.665,0,0,1-2.431,3.881v11.457l-8.894-2.977Zm8.894-9.8a9.458,9.458,0,0,0,6.953-2.845,9.458,9.458,0,0,0,2.845-6.953,9.458,9.458,0,0,0-2.845-6.953,9.458,9.458,0,0,0-6.953-2.845,9.458,9.458,0,0,0-6.953,2.845,9.458,9.458,0,0,0-2.845,6.953,9.458,9.458,0,0,0,2.845,6.953A9.458,9.458,0,0,0,172.059-858.142Zm-6.633,6.633,6.633-2.073,6.633,2.073v-6.444a10.221,10.221,0,0,1-3.241,1.583,12.442,12.442,0,0,1-3.392.49,12.442,12.442,0,0,1-3.392-.49,10.221,10.221,0,0,1-3.241-1.583ZM172.059-854.751Z" transform="translate(11602.98 11631.5)"/>
                                <text transform="translate(11775.04 10768.394)" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="-3.442" y="0">{{getQuestionRating(question)}}</tspan></text>
                              </g>
                            </svg>

                            <mat-icon *ngIf="!isPassingScore(question)" class="material-icons-outlined mat-icon warn-orange-color" color="warn" matIconSuffix> block</mat-icon>

                          </ng-container>
                          <ng-container *ngIf="!canBeScored(questionGroup)">
                            {{displayCantBeScoredMessage(questionGroup)}}
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-container>

              </div>
            </div>

            <ng-template #repeatingQuestionTemplate>
              <div *ngFor="let repeatedValue of questionGroup.repeatedValues; let repeatedIdx = index; let first = first"
                   class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">

                <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
                  <mat-icon class="more-icon" *ngIf="first">more_vert</mat-icon>
                </div>

                <div class="full-width" fxLayout="column" fxLayoutGap="20px">
                  <div class="heading-bold" *ngIf="first">{{questionGroup.displayLabel}}</div>

                  <div class="full-width pe-4" fxLayout="row" fxLayoutGap="10px">
                    <div *ngFor="let rQuestion of repeatedValue; let rk = index" class="full-width" fxLayout="column"
                         fxLayoutAlign="center start" fxLayoutGap="5px" fxLayoutGap.gt-sm="15px">

                      <ng-container *ngIf="getQuestionTemplate(rQuestion); let rqt">

                        <div *ngIf="repeatedValue.length === 1" class="mat-tagline question-text pb-2 mt-4 full-width">
                          {{ getQuestionDisplayLabel(rqt) }}
                        </div>

                        <div class="body-1-regular-2">{{rqt.controlDescription}}</div>

                        <div class="application-result-input-box">
                          <span class="body-1-default">{{getQuestionValue(rQuestion).length == 0 ? '-' : getQuestionValue(rQuestion)}}</span>
                        </div>

                      </ng-container>

                    </div>
                  </div>
                </div>
              </div>

            </ng-template>

          </div>

        </div>

      </div>

    </div>

    <mat-divider fxFlexOffset="40px"></mat-divider>

    <div class="px-4" fxLayout="column" fxFlexOffset="30px">
      <div class="full-width">
        <mat-form-field class="custom-text-area" appearance="outline" floatLabel="always">
          <mat-label>Notes</mat-label>
          <textarea class="full-width" matInput placeholder="Leave a note" rows="4"
                    (ngModelChange)="hasFormChanged()"
                    [(ngModel)]="applicantAssessment.notes"></textarea>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayoutAlign="end center" fxFlexOffset="20px">
        <div fxLayoutGap="10px" *ngIf="formChanged">
          <button class="button-primary" mat-flat-button *ngIf="!curated" (click)="saveApplication()"><div class="button-text">Save</div></button>
          <button class="button-outline-white" mat-flat-button (click)="cancel()"><div class="button-text">Cancel</div></button>
        </div>
        <div *ngIf="!formChanged" fxFlexOffset="30px" fxLayoutGap="10px">
          <button (click)="removePanelOpen = true" class="button-outline-white" mat-flat-button [disabled]="(loader.loading$ | async)">
            <div class="button-text">Reject</div>
          </button>
          <button (click)="curateApplicant(true)" *ngIf="!curated" class="button-primary" mat-flat-button [disabled]="(loader.loading$ | async)">
            <div class="button-text">Approve</div>
          </button>
        </div>
        <div fxFlexOffset="20px" *ngIf="removePanelOpen">
          <app-confirm-remove [message]="'Do you want to reject this retailer from your curated leads?'" (confirmRemove)="rejectApplicant($event)"></app-confirm-remove>
        </div>
      </div>
    </div>

  </div>

</div>



