<div class="px-4 py-3 full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px"
     style="padding-left: 40px !important;">
  <div class="full-width full-height" fxLayout="row" fxLayoutAlign="end center" style="margin-bottom: 0">
    <button (click)="close()" class="button-close-top" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="title-small" style="font-size: 14px!important;">Upload a new image</div>

  <form class="full-width full-height"
        fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="20px">

    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="7px">
      <div *ngIf="!isFileSelected()" class="full-width" fxLayout="column" fxLayoutAlign="center center"
           fxLayoutGap="7px">
        <label class="subtitle-default file-label-wrapper" for="file-upload">
          <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
            <mat-icon class="material-icons-outlined file-upload-icon">
              upload_file
            </mat-icon>
            <div class="subtitle-default">Select file to upload</div>
          </div>
          <input #fileInput (change)="fileChangeEvent($event)" accept=".jpg,.png,.jpeg" class="custom-text-field"
                 id="file-upload"
                 type="file"/>
        </label>
      </div>
    </div>

    <div *ngIf="isFileSelected()" class="full-width" fxLayout="column" fxLayoutGap="7px" style="min-height: 200px">
      <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
        <mat-icon (click)="onZoom(-1)" class="btn-cursor">zoom_out</mat-icon>
        Zoom
        <mat-icon (click)="onZoom(1)" class="btn-cursor">zoom_in</mat-icon>
      </div>
      <image-cropper
        (cropperReady)="cropperReady()"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        [aspectRatio]="aspectRatio"
        [containWithinAspectRatio]="true"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [roundCropper]="false"
        [transform]="transform"
        backgroundColor="#EFEFF3"
        format="jpeg"
      ></image-cropper>
    </div>
    <div class="full-width pe-4" fxLayout="row" fxLayoutAlign="center">
      <button (click)="upload()" [disabled]="!isFileSelected()" class="button-primary" mat-flat-button
              style="width: 130px" type="submit">
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          Upload
        </div>
      </button>
    </div>
  </form>
</div>
