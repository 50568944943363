import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {
  ElementRow,
  GridItemData,
  Timeline,
  TimelineColumn,
  TimelineDate,
  TimelineElement,
  TimelineList,
  TimelineObject, TimelineObjectData,
  TimelinePeriod,
  TimelinePeriodConfig
} from "./timeline.model";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";
import {DateUtilsService} from "../../services/dateUtils.service";
import {GridsterComponent, GridsterConfig, GridsterItem, GridsterItemComponentInterface} from "angular-gridster2";
import {DateRange} from "@angular/material/datepicker";
import {UtilsService} from "../../services/utils.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewInit, OnChanges {
  protected readonly TimelinePeriod = TimelinePeriod;
  @ViewChildren('gridContainer') gridContainer!: QueryList<ElementRef>;
  @ViewChildren('gridster') gridster!: QueryList<GridsterComponent>;
  @ViewChildren('dateHeader') dateHeader!: QueryList<ElementRef>;
  @ViewChildren('timelineBody') timelineBody!: QueryList<ElementRef>;

  // inputs
  @Input() timeline!: Timeline;
  @Input() editing: boolean = false;

  // output event emitters

  @Output() searchList = new EventEmitter();
  @Output() actionClicked = new EventEmitter();
  @Output() scroll = new EventEmitter<DateRange<Date>>();
  @Output() clickEl = new EventEmitter<string>();
  @Output() clickObj = new EventEmitter<string>();
  @Output() addNewObj = new EventEmitter<string>();
  @Output() updateObj = new EventEmitter<{listIndex: number, oldEl: string, newEl: string, objId: string}>();
  @Input() drawerOpened = false;
  @Output() emitAction = new EventEmitter<string>();

  options!: GridsterConfig;

  timelineCurrentDate: { listId: string, items: GridsterItem[] }[] = [];
  timelineObjects: { listId: string, items: GridsterItem[] }[] = [];
  displayedTimelineObjects: { listId: string, items: GridsterItem[] }[] = [];
  elementRows: { listId: string, items: ElementRow[] }[] = [];

  periodConfigs: TimelinePeriodConfig[] = [
    { label: 'Week', period: TimelinePeriod.WEEK, columns: 7, columnSize: 1 },
    { label: 'Month', period: TimelinePeriod.MONTH, columns: 4, columnSize: 7 },
  ]

  periodConfig: TimelinePeriodConfig = { label: 'Week', period: TimelinePeriod.WEEK, columns: 7, columnSize: 1 };

  displayedHeaderColumns = 5;
  loadedHeaderColumnsPerSide = 20;
  headerWidth = 147;
  columnHeight = 42;
  minRows = 10;

  fullCalendar: TimelineColumn[] = [];
  weekCalendar: TimelineDate[] = [];
  monthCalendar: TimelineDate[] = [];
  selectedCalendar: TimelineDate[] = [];

  displayedDateRange = new DateRange<Date>(
    moment(new Date()).startOf(this.periodConfig.period).add(-this.loadedHeaderColumnsPerSide, this.periodConfig.period).toDate(),
    moment(new Date()).endOf(this.periodConfig.period).add(this.loadedHeaderColumnsPerSide + this.displayedHeaderColumns, this.periodConfig.period).toDate()
  );
  displayedCalendar: TimelineColumn[] = [];

  disableScroll = false;

  selectedObject: TimelineObject | null = null;
  selectedElement: ElementRow | null = null;
  selectedList: string | null = null;

  focusDate: Date = new Date();
  dateOffset: number = 0;

  loading: boolean = false;
  timelineLoading: boolean = false;

  constructor(public dateUtils: DateUtilsService,
              private utils: UtilsService,
              private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              private loader: LoadingService,
              private cdr: ChangeDetectorRef) {
    this.matIconRegistry.addSvgIcon('cancel', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/cancel.svg'));
    this.matIconRegistry.addSvgIcon('check_circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/check_circle.svg'));
    this.matIconRegistry.addSvgIcon('pending', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/pending.svg'));
    this.matIconRegistry.addSvgIcon('preliminary', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/preliminary.svg'));
    this.matIconRegistry.addSvgIcon('page_info', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/page_info.svg'));

    this.loader.loading$.subscribe(load => this.loading = load);
  }

  ngOnInit() {
    if (this.timeline) {
      this.periodConfig = this.periodConfigs.filter(f => f.period == this.timeline.initialPeriod)[0];

      this.setOptions();
      this.weekCalendar = this.setCalendar(TimelinePeriod.WEEK);
      this.monthCalendar = this.setCalendar(TimelinePeriod.MONTH);
      if (this.periodConfig.period == TimelinePeriod.WEEK) this.selectedCalendar = this.weekCalendar;
      if (this.periodConfig.period == TimelinePeriod.MONTH) this.selectedCalendar = this.monthCalendar;

      this.setDateOffset();
      this.timeline.timelineLists = this.timeline.timelineLists.sort((a, b) => a.displayOrder - b.displayOrder);

      this.setTimelineDateColumns();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.timeline && changes["timeline"] && !changes["timeline"].isFirstChange()) {
      this.timeline.timelineLists = this.timeline.timelineLists.sort((a, b) => a.displayOrder - b.displayOrder);

      if (this.timeline.focusCurrentDate) {
        this.initTimeline(true);
      } else {
        this.timeline.timelineLists.forEach(list => this.addNewObjectsToTimeline(list.listId));
      }
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  // INIT

  initTimeline(scroll: boolean) {
    this.setDateOffset();
    this.displayCalendar();
    this.initTimelineObjects();
    this.setTimelineCurrentDate();

    if (scroll) {
      setTimeout(() => {
        this.scrollIntoView(new Date());
      }, 1000);
    }

  }

  // SET HEADERS

  setTimelineDateColumns() {
    if (this.periodConfig.period == TimelinePeriod.WEEK) {
      this.fullCalendar = this.selectedCalendar.reduce((acc: TimelineColumn[], curr) => {
        const foundIndex = acc.findIndex(item => item.week === curr.week);
        if (foundIndex !== -1) {
          acc[foundIndex].dates.push(curr.dates);
        } else {
          acc.push({ month: curr.month, week: curr.week, dates: [curr.dates] });
        }
        return acc;
      }, []);
    }

    if (this.periodConfig.period == TimelinePeriod.MONTH) {
      this.fullCalendar = this.selectedCalendar.reduce((acc: TimelineColumn[], curr) => {
        const foundIndex = acc.findIndex(item => item.month === curr.month);
        if (foundIndex !== -1) {
          acc[foundIndex].dates.push(curr.dates);
        } else {
          acc.push({ month: curr.month, week: curr.week, dates: [curr.dates] });
        }
        return acc;
      }, []);
    }

    this.displayCalendar();
  }

  // SET CURRENT DATE

  setTimelineCurrentDate() {
    this.timelineCurrentDate = [];
    const xValue = this.getXValueFromDate(new Date()) - this.dateOffset;
    const objects: { listId: string, items: GridsterItem[] }[] = [];
    if (xValue <= this.totalLoadedColumns && xValue >= 0) {
      this.elementRows.forEach(list => {
        const listObj: { listId: string, items: GridsterItem[] } = {listId: list.listId, items: []};
        list.items.forEach(el => {
          el.y.forEach(row => {
            listObj.items.push({x: xValue, y: row, cols: 1, rows: 1, layerIndex: 1, dragEnabled: false, resizeEnabled: false});
          })
        })
        if (listObj.items.length < this.minRows) {
          while (listObj.items.length < this.minRows) {
            listObj.items.push({x: xValue, y: listObj.items.length, cols: 1, rows: 1, layerIndex: 1, dragEnabled: false, resizeEnabled: false});
          }
        }
        objects.push(listObj);
      })
    }
    this.timelineCurrentDate = [...objects];
  }

  // SET OBJECTS

  initTimelineObjects() {
    this.timelineLoading = true;

    this.elementRows = [];
    this.timelineObjects = [];
    this.displayedTimelineObjects = [];

    this.timeline.timelineLists.forEach((list, k) => {
      let yValue = -1;
      const objectArray: GridsterItem[] = []
      const elementRow: { listId: string, items: ElementRow[] } = {listId: list.listId, items: []};

      list.listElements.forEach((el, i) => {
        yValue = yValue + 1;

        const elRow = {
          uuid: el.uuid,
          displayName: el.displayName,
          displayValue: el.displayValue,
          listId: list.listId,
          y: [yValue]
        };

        el.elementObjects.forEach((obj, j) => {
          const newObject = this.createTimelineObject(obj, yValue, k + 2);

          while (this.checkObjectCollision(objectArray, newObject)) {
            yValue++;
            elRow.y.push(yValue);
            newObject.y = yValue;
          }

          objectArray.push(newObject)
        })

        elementRow.items.push(elRow);
      })

      this.elementRows.push(elementRow);

      this.timelineObjects.push({listId: list.listId, items: objectArray});
    })

    if (this.elementRows.length > 0) {
      this.options.minRows = this.elementRows[0].items.length < this.minRows ? this.minRows : this.elementRows[0].items.length;
      this.selectElement(this.elementRows[0].items[0].uuid, this.elementRows[0].listId, true);
    }

    this.setDisplayedTimelineObjects();
  }

  createTimelineObject(obj: TimelineObject, yValue: number, index: number): GridsterItem {
    const data: GridItemData = {
      column: null,
      date: null,
      object: { listId: obj.listId, elementId: obj.elementId, uuid: obj.uuid}
    };

    const startPoint = this.getXValueFromDate(moment(obj.startDate!).toDate());
    let endPoint = this.getXValueFromDate(moment(obj.endDate).toDate());

    if (obj.expandInfinitely && obj.endDate == null) {
      endPoint = this.getXValueFromDate(this.timeline.latestDate);
    }
    const columns = endPoint - startPoint + 1;

    return {
      cols: columns,
      rows: 1,
      y: yValue,
      x: startPoint,
      layerIndex: index,
      dragEnabled: obj.editable && obj.draggable != 'none',
      resizeEnabled: obj.editable && obj.expandable != 'none',
      resizableHandles: {
        e: obj.expandable == 'right' || obj.expandable == 'any',
        w: obj.expandable == 'left' || obj.expandable == 'any'
      },
      data
    }
  }

  checkObjectCollision(objectsArray: GridsterItem[], newObject: GridsterItem) {
    const startPosition = newObject.x
    const endPosition = newObject.x + newObject.cols - 1;

    const conflicts = objectsArray.filter(f => {
      const start = f.x
      const end = f.x + f.cols - 1;
      return (f.y == newObject.y) && (startPosition < end && endPosition > start);
    })

    return conflicts.length > 0;
  }

  displayCalendar() {
    let offset = 0;
    const startColumn = this.fullCalendar.findIndex(column => {
      let match = false;
      column.dates.forEach(dates => {
        match = (dates.filter(date => moment(date).isSameOrAfter(this.displayedDateRange.start)).length > 0 || match);
      })
      if (match && column.dates.length != this.periodConfig.columns) offset = column.dates.length;
      return match && column.dates.length == this.periodConfig.columns;
    });
    this.dateOffset = this.dateOffset + offset;
    this.displayedCalendar = this.fullCalendar.slice(startColumn, startColumn + this.totalLoadedHeaderColumns);
  }

  setDisplayedTimelineObjects() {
    const displayedObjects = this.timelineObjects.map(list => {
      return {listId: list.listId, items: list.items.map(m => { return { ...m } })};
    });

    displayedObjects.forEach(list => {
      list.items = list.items.filter(item => {
        let startX = item.x - this.dateOffset;
        let endX = item.x + item.cols - this.dateOffset;
        return startX <= this.totalLoadedColumns && endX >= 0;
      })
    })

    displayedObjects.forEach(list => {
      list.items.forEach(item => {
        const object = this.getTimelineObject(item)!;

        let startX = item.x - this.dateOffset;
        let endX = item.x + item.cols - this.dateOffset;

        const displayedStart = startX > 0 ? startX : 0;
        let displayedEnd = endX;

        if ((object.expandInfinitely && object.endDate == null) || endX > this.totalLoadedColumns) {
          displayedEnd = this.totalLoadedColumns;
        }

        let columns = displayedEnd - displayedStart;

        item.x = displayedStart;
        item.cols = columns
      })
    })

    this.displayedTimelineObjects = displayedObjects;

    this.updateTimelineOptions();
    this.timelineLoading = false;
  }

  // ADD LOADED DATA

  addNewObjectsToTimeline(listId: string) {
    const existingItems: GridsterItem[] = this.getObjectsForList(listId, this.timelineObjects).items;

    if (existingItems) {
      const existingUuids = existingItems.map(m => this.getTimelineObject(m)!.uuid);

      const listIndex = this.getListIndex(listId, this.timeline.timelineLists);

      this.timeline.timelineLists[listIndex]
        .listElements.forEach(el => {
        el.elementObjects.forEach(obj => {
          if (!existingUuids.includes(obj.uuid)) {
            const yValues = this.getElementValue(obj.elementId, listId);
            const item = this.createTimelineObject(obj, 0, listIndex + 2);

            for (let yValue of yValues) {
              item.y = yValue;
              if (!this.checkObjectCollision(existingItems, item)) {
                existingItems.push(item);
              }
            }
          }
        })
      })
    }

  }

  // UPDATE FROM TIMELINE

  addElement(listId: string) {
    const elId = this.utils.generateUuid();

    const newObj: TimelineObject = {
      uuid: '',
      elementId: elId,
      listId: listId,
      objectData: [],
      startDate: new Date(),
      endDate: moment(new Date()).add(1, 'week').toDate(),
      editable: true,
      expandable: this.timeline.newElementConfig!.expandable,
      draggable: this.timeline.newElementConfig!.draggable,
      expandInfinitely: this.timeline.newElementConfig!.expandInfinitely,
      lighten: this.timeline.newElementConfig!.lighten,
      icon: this.timeline.newElementConfig!.icon,
    }

    const newEl: TimelineElement = {
      uuid: elId,
      displayValue: '0 m²',
      displayName: 'New Space',
      listId: listId,
      elementObjects: [newObj]
    }

    const list: TimelineList = this.timeline.timelineLists[this.getListIndex(listId, this.timeline.timelineLists)];
    list.listElements.push(newEl);

    const lastY = this.getObjectsForList(listId, this.elementRows).flatMap((m: ElementRow) => m.y)
      .sort((a: number, b: number) => b - a)[0];

    const elRow = {
      uuid: newEl.uuid,
      displayName: newEl.displayName,
      displayValue: newEl.displayValue,
      listId: listId,
      y: [lastY + 1]
    };

    this.getObjectsForList(listId, this.elementRows).push(elRow);

    this.clickElement(elId, listId, true);
    this.editing = true;

    this.setDisplayedTimelineObjects();
  }

  addObject(event: MouseEvent, item: GridsterItem): void {
    if (item) {
      const listIndex = this.getListIndex(this.selectedList!, this.elementRows);
      const elementRow = this.elementRows[listIndex].items.filter(f => f.y.includes(item.y))[0];

      if (elementRow) {
        this.clickElement(elementRow.uuid, elementRow.listId, true);

        if (!this.editing) {
          item.cols = item.cols == 1 ? this.periodConfig.columns : item.cols;
          item.layerIndex = listIndex + 2;

          const objectUuid = this.utils.generateUuid();

          const itemObject = {
            listId: elementRow.listId,
            elementId: elementRow.uuid,
            uuid: objectUuid
          }

          const newObject: TimelineObject = {
            uuid: objectUuid,
            elementId: elementRow.uuid,
            listId: elementRow.listId,
            objectData: [],
            startDate: this.getDatesFromXValue(item.x + this.dateOffset)[0],
            endDate: this.getDatesFromXValue(item.x + item.cols - 1 + this.dateOffset)[0],
            editable: true,
            expandable: 'any',
            draggable: 'any',
            expandInfinitely: false,
            lighten: 40,
            icon: 'pending',
          }

          item = { ...item, data: { column: null, date: null, object: itemObject } }
          const baseItem = { ...item };
          baseItem.x = baseItem.x + this.dateOffset;

          this.displayedTimelineObjects[this.getListIndex(newObject.listId, this.displayedTimelineObjects)].items.push(item);
          this.timelineObjects[this.getListIndex(newObject.listId, this.timelineObjects)].items.push(baseItem);
          this.getTimelineElement(item).elementObjects.push(newObject);

          this.selectedObject = newObject;
          this.addNewObj.emit(itemObject.uuid);
        }
      }
    }
  }

  // UPDATE EVENTS

  updateObject(item: GridsterItem, gridsterItem: GridsterItemComponentInterface, event: MouseEvent) {
    this.clickObject(item);
    const data = this.getTimelineObject({...item})!;
    if (gridsterItem.$item.y <= this.getTimelineMaxYValue(data.listId)) {
      if (!this.editing || this.getItemData(item).object?.uuid == this.selectedObject?.uuid) {
        const oldPos = { ...item };
        const newPos = gridsterItem.$item;
        const datesChanged = oldPos.x != newPos.x || oldPos.cols != newPos.cols;
        const elChanged = oldPos.y != newPos.y;


        const listId = this.getListIndex(data.listId, this.elementRows);

        const oldEl = this.getTimelineElement(oldPos);
        const newEl = this.getTimelineElementFromYValue(newPos);

        // update dates first
        if (datesChanged) {
          this.getTimelineObject(oldPos)!.startDate = this.getDatesFromXValue(newPos.x + this.dateOffset)[0];
          this.getTimelineObject(oldPos)!.endDate = this.getDatesFromXValue(newPos.x + newPos.cols - 1 + this.dateOffset)[0];

          this.timelineObjects[listId].items.forEach(f => {
            if (this.getItemData(f) && this.getItemData(f).object!.uuid == data.uuid) {
              f.x = newPos.x + this.dateOffset;
              f.cols = newPos.cols;
            }
          })
        }
        if (elChanged) {
          const objectToMove= {...this.getTimelineObject(oldPos)};
          objectToMove.elementId = newEl.uuid;

          // move object in timeline object
          this.getTimelineElement(oldPos).elementObjects = oldEl.elementObjects.filter(f => f.uuid != objectToMove.uuid);
          this.getTimelineElementFromYValue(newPos).elementObjects.push(objectToMove);

          this.timelineObjects[listId].items.forEach(f => {
            if (this.getItemData(f) && this.getItemData(f).object!.uuid == data.uuid) {
              f.y = newPos.y
              this.getItemData(f).object!.elementId = newEl.uuid;
            }
          })

          this.getItemData(item).object!.elementId = newEl.uuid;
          item.y = newPos.y;

          this.selectElement(newEl.uuid, data.listId, true);
        }

        this.updateTimelineOptions();
        this.updateObj.emit({listIndex: this.timeline.timelineLists[listId].displayOrder, oldEl: oldEl.uuid, newEl: newEl.uuid, objId: data.uuid});
      }
    }
  }

  removeItem(item: GridsterItem, itemsList: GridsterItem[]): void {
    itemsList.splice(itemsList.indexOf(item), 1);
    this.updateTimelineOptions();
  }

  removeItemViaObject(object: TimelineObject) {
    const listIndex = this.getListIndex(object.listId, this.timelineObjects);
    const item = this.timelineObjects[listIndex].items.filter(f => this.getItemData(f).object?.uuid == object.uuid)[0];
    this.timelineObjects[listIndex].items.splice(this.timelineObjects[listIndex].items.indexOf(item), 1);
    this.displayedTimelineObjects[listIndex].items.splice(this.displayedTimelineObjects[listIndex].items.indexOf(item), 1);

    this.getTimelineElement(item).elementObjects.splice(this.getTimelineElement(item).elementObjects.indexOf(object), 1);
    this.updateTimelineOptions();
  }

  clickElement(id: string, listId: string, emit: boolean) {
    if (!this.editing) this.selectElement(id, listId, emit)
  }

  selectElement(id: string, listId: string, emit: boolean) {
    const listIndex = this.getListIndex(listId, this.elementRows);
    this.selectedElement = this.elementRows[listIndex].items.filter(f => f.uuid == id)[0];

    if (emit) this.clickEl.emit(this.selectedElement.uuid);
  }

  clickObject(item: GridsterItem) {
    if (!this.editing) {
      this.selectedObject = this.getTimelineObject(item);
      const el = this.getTimelineElement(item);

      const listIndex = this.getListIndex(this.selectedObject!.listId, this.elementRows);
      this.selectedElement = this.elementRows[listIndex].items.filter(f => f.uuid == el.uuid)[0];

      this.clickEl.emit(this.selectedObject?.elementId);
      this.clickObj.emit(this.selectedObject?.uuid);
    }
  }

  updateTimelineItemFromObject(uuid: string, listId: string, datesChanged: boolean, elChanged: boolean, oldEl?: string, newEl?: string) {
    const totalIndex = this.getObjectsForList(listId, this.timelineObjects).findIndex((item: GridsterItem) => this.getItemData(item).object!.uuid == uuid);
    const baseItem: GridsterItem = this.getObjectsForList(listId, this.timelineObjects)[totalIndex];

    if (elChanged) this.getItemData(baseItem).object!.elementId = newEl!;
    const baseObject = this.getTimelineObject(baseItem);

    const listIndex = this.getListIndex(listId, this.timelineObjects);

    if (datesChanged) {
      const startPoint = this.getXValueFromDate(moment(baseObject.startDate!).toDate());
      let endPoint = this.getXValueFromDate(moment(baseObject.endDate).toDate());

      if (baseObject.expandInfinitely && baseObject.endDate == null) {
        endPoint = this.getXValueFromDate(this.timeline.latestDate);
      }

      baseItem.x = startPoint;
      baseItem.cols = endPoint;
    }

    if (elChanged) {
      const newPos = this.getElementValue(newEl!, listId);
      this.timelineObjects[listIndex].items.forEach(f => {
        if (this.getItemData(f) && this.getItemData(f).object!.uuid == baseObject.uuid) {
          f.y = newPos[0]
          this.getItemData(f).object!.elementId = newEl!;
        }
      })

      this.displayedTimelineObjects[listIndex].items.forEach(f => {
        if (this.getItemData(f) && this.getItemData(f).object!.uuid == baseObject.uuid) {
          f.y = newPos[0]
          this.getItemData(f).object!.elementId = newEl!;
        }
      })
    }

    this.setDisplayedTimelineObjects();
  }

  // GET UTILS

  getItemData(item: GridsterItem): GridItemData {
    return item['data'];
  }

  getTimelineObject(item: GridsterItem) {
    const objIds = this.getItemData(item).object!;

    return this.timeline
      .timelineLists.filter(list => list.listId == objIds.listId)[0]
      .listElements.filter(el => el.uuid == objIds.elementId)[0]
      .elementObjects.filter(obj => !!obj && (obj.uuid == objIds.uuid))[0];
  }

  getTimelineElement(item: GridsterItem) {
    const objIds = this.getItemData(item).object!;

    return this.timeline
      .timelineLists.filter(list => list.listId == objIds.listId)[0]
      .listElements.filter(el => el.uuid == objIds.elementId)[0];
  }

  getTimelineElementFromYValue(item: GridsterItem) {
    const elRows: ElementRow[] = this.getObjectsForList(this.selectedList!, this.elementRows);
    const elId = elRows.filter(f => f.y.includes(item.y))[0].uuid;

    return this.timeline
      .timelineLists.filter(list => list.listId == this.selectedList!)[0]
      .listElements.filter(el => el.uuid == elId)[0];
  }

  getTimelineMaxYValue(listId: string) {
    const elements: ElementRow[] = this.getObjectsForList(listId, this.elementRows);
    return Math.max(...elements.flatMap(fm => fm.y));
  }

  getDatesFromXValue(x: number) {
    const item = this.selectedCalendar.filter(f => f.x == x)[0];
    return item.dates;
  }

  getElementValue(elId: string, listId: string) {
    const listIndex = this.getListIndex(listId, this.elementRows);
    const el = this.elementRows[listIndex].items.filter(f => f.uuid == elId)[0];
    return el.y;
  }

  // ON SCROLL

  onScroll(right: boolean) {
    const atStart = this.timeline.earliestDate.getTime() >= this.focusDate.getTime();

    if (!this.disableScroll && !this.loading && !(!right && atStart)) {
      const scrollEl = this.dateHeader.get(0)!.nativeElement;
      const scrollLeft = !right ? scrollEl.scrollLeft - this.headerWidth : scrollEl.scrollLeft + this.headerWidth;
      this.focusDate = !right
        ? moment(this.focusDate).add(-1, this.periodConfig.period).toDate()
        : moment(this.focusDate).add(1, this.periodConfig.period).toDate();

      this.scrollTo(scrollLeft);
      const end = scrollEl.scrollWidth - scrollEl.clientWidth;

      if (!this.disableScroll && (scrollEl.scrollLeft == 0 || scrollEl.scrollLeft >= end) && !this.timelineLoading) {
        this.disableScroll = true;

        if (scrollEl.scrollLeft == 0) this.scrollDates(true);
        if (scrollEl.scrollLeft >= end) this.scrollDates(false);

        setTimeout(() => {
          this.scrollTo(this.dateHeader.get(0)!.nativeElement.scrollLeft);
          this.updateTimelineOptions();
          this.disableScroll = false;
        }, 500);
      }
    }
  }

  scrollTo(scrollLeft: number) {
    this.dateHeader.forEach(header => {
      header.nativeElement.scrollLeft = scrollLeft;
    });
    this.gridContainer.forEach(grid => {
      grid.nativeElement.scrollLeft = scrollLeft;
    });
  }

  scrollDates(left: boolean) {
    this.timelineLoading = true;
    const start = moment(this.displayedDateRange.start).add(this.loadedHeaderColumnsPerSide * (left ? -1 : 1), this.periodConfig.period).toDate();
    this.displayedDateRange = new DateRange<Date>(start, moment(start).add(this.totalLoadedColumns, this.periodConfig.period).toDate());
    this.setDateOffset();

    this.displayCalendar();
    this.setDisplayedTimelineObjects();
    this.setTimelineCurrentDate();
  }

  scrollIntoView(date: Date | string | Moment) {
    this.focusDate = moment(date).toDate();
    const startOfPeriod = moment(date).startOf(this.periodConfig.period).toDate();
    const scrollAmount = (this.getXValueFromDate(startOfPeriod!) - this.dateOffset) * (this.headerWidth / this.periodConfig.columns);

    this.scrollTo(scrollAmount);
    this.timelineBody.forEach(body => {
      body.nativeElement.scrollTop = 0;
    })

  }

  // PERIOD UTILS

  switchPeriod() {
    this.disableScroll = true;
    if (this.periodConfig.period == TimelinePeriod.WEEK) this.selectedCalendar = this.weekCalendar;
    if (this.periodConfig.period == TimelinePeriod.MONTH) this.selectedCalendar = this.monthCalendar;

    this.displayedDateRange = new DateRange<Date>(
      moment(this.focusDate).startOf(this.periodConfig.period).add(-this.loadedHeaderColumnsPerSide, this.periodConfig.period).toDate(),
      moment(this.focusDate).endOf(this.periodConfig.period).add(this.loadedHeaderColumnsPerSide + this.displayedHeaderColumns, this.periodConfig.period).toDate()
    );

    this.setDateOffset();
    this.setTimelineDateColumns();

    this.timelineObjects.forEach(obj => {
      obj.items.forEach(item => {
        const timelineObj = this.getTimelineObject(item);

        const startPoint = this.getXValueFromDate(moment(timelineObj.startDate!).toDate());
        let endPoint = this.getXValueFromDate(moment(timelineObj.endDate).toDate());
        if (timelineObj.expandInfinitely && timelineObj.endDate == null) {
          endPoint = this.getXValueFromDate(this.timeline.latestDate);
        }
        const columns = endPoint - startPoint + 1;

        item.x = startPoint;
        item.cols = columns;
      })
    })

    this.setOptions();
    this.updateTimelineOptions();

    setTimeout(() => {
      this.scrollIntoView(this.focusDate);
      this.scrollDates(true);
      this.scrollDates(false);
      this.disableScroll = false;
    }, 500);
  }

  setDateOffset() {
    this.dateOffset = this.getXValueFromDate(this.displayedDateRange.start!);
  }

  // CALC SET VALUES

  get timelineEndDate() {
    return moment(this.displayedDateRange.start).add(this.totalLoadedHeaderColumns, this.periodConfig.period).toDate();
  }

  get timelineDateRange() {
    return new DateRange(this.displayedDateRange.start, this.timelineEndDate);
  }

  get totalLoadedColumns() {
    return this.totalLoadedHeaderColumns * this.periodConfig.columns;
  }

  get totalDisplayedColumns() {
    // 5 * 7
    return this.displayedHeaderColumns * this.periodConfig.columns;
  }

  get totalLoadedHeaderColumns() {
    // middle 5 + 5 each side = 15
    return this.displayedHeaderColumns + (2 * this.loadedHeaderColumnsPerSide);
  }

  get scrollableHeight() {
    return (this.columnHeight * this.elementRows.length) + 'px';
  }

  get timelineColumnWidth() {
    return this.headerWidth / this.periodConfig.columns;
  }

  generateDateId(dates: Date[]) {
    return dates.map(m => this.dateUtils.displayShortDate(m)).join("|");
  }

  compareItems(o1: TimelinePeriodConfig, o2: TimelinePeriodConfig) {
    return o1.period == o2.period;
  }

  containsCurrentDate(dates: Date[]) {
    const shortDates = dates.map(m => this.dateUtils.displayShortDate(m)!);
    return shortDates.includes(this.dateUtils.displayShortDate(new Date())!);
  }

  getObjectsForList(listId: string, list: any[]) {
    const index = this.getListIndex(listId, list);
    return index >= 0 ? list[index].items : [];
  }

  getExtraRows(list: any[]) {
    const extraList = [];
    for (let i = 0; i < (this.minRows - list.length); i++) extraList.push(null);
    return extraList;
  }

  getListIndex(listId: string, list: any[]) {
    return list.findIndex(f => f.listId == listId);
  }

  // TIMELINE OPTIONS

  updateTimelineOptions() {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  setOptions() {
    this.options = {
      gridType: "fixed",
      displayGrid: "always",
      outerMargin: false,
      margin: 0,
      draggable: {
        enabled: true,
        stop: this.updateObject.bind(this)
      },
      resizable: {
        enabled: true,
        handles: {
          s: false,
          e: true,
          n: false,
          w: true,
          se: false,
          ne: false,
          sw: false,
          nw: false
        },
        stop: this.updateObject.bind(this)
      },
      fixedColWidth: this.timelineColumnWidth,
      fixedRowHeight: this.columnHeight,
      swap: false,
      maxCols: this.totalLoadedColumns,
      minCols: this.totalLoadedColumns,
      minRows: this.minRows,
      maxRows: 1000,
      mobileBreakpoint: 100,
      maxItemCols: this.totalLoadedColumns,
      maxItemRows: 1,
      setGridSize: true,
      enableEmptyCellClick: true,
      emptyCellClickCallback: this.addObject.bind(this),
      enableEmptyCellDrag: true,
      emptyCellDragCallback: this.addObject.bind(this),
      pushResizeItems: false,
      pushItems: true,
      pushDirections: { north: false, east: false, south: true, west: false },
      allowMultiLayer: true,
      defaultLayerIndex: 2,
      baseLayerIndex: 1
    }
  }

  // OTHER UTILS

  getXValueFromDate(date: Date): number {
    const column = this.selectedCalendar.filter(f => {
      const dates = f.dates.map(m => this.dateUtils.displayShortDate(m)!);
      return dates.includes(this.dateUtils.displayShortDate(date)!);
    })

    return column.length > 0 ? column[0].x : 0;
  }

  setCalendar(period: TimelinePeriod) {
    const calendar: TimelineDate[] = [];

    let x = 0;

    if (period == TimelinePeriod.WEEK) {
      let week = moment(this.timeline.earliestDate).week();
      for (let i = this.timeline.earliestDate; i <= this.timeline.latestDate; i = moment(i).add(1, 'day').toDate()) {
        if (moment(i).day() == 0) week++;
        calendar.push({ x: x, month: this.dateUtils.displayMonthAndYear(i)!, week: week, dates: [i] });
        x++;
      }
      return calendar;
    }

    if (period == TimelinePeriod.MONTH) {
      for (let i = this.timeline.earliestDate; i <= this.timeline.latestDate; i = moment(i).add(1, 'month').toDate()) {
        const allMonthWeeks = this.compactMonthWeeks(this.dateUtils.getWeeksOfMonth(i));

        allMonthWeeks.forEach(week => {
          const dates = week.map(m => m.toDate());
          calendar.push({ x: x, month: this.dateUtils.displayMonthAndYear(i)!, week: 0, dates: dates });
          x++;
        })
      }
      return calendar;
    }

    return calendar;
  }

  compactMonthWeeks(allMonthWeeks: Moment[][]) {
    switch (allMonthWeeks.length) {
      case 6:
        allMonthWeeks[4].push(...allMonthWeeks[5]);
        allMonthWeeks.pop();

        allMonthWeeks[1].unshift(...allMonthWeeks[0]);
        allMonthWeeks.shift();

        return allMonthWeeks;
      case 5:
        if (allMonthWeeks[0].length >= allMonthWeeks[4].length) {
          allMonthWeeks[3].push(...allMonthWeeks[4]);
          allMonthWeeks.pop();

          return allMonthWeeks;
        } else {
          allMonthWeeks[1].unshift(...allMonthWeeks[0]);
          allMonthWeeks.shift();

          return allMonthWeeks;
        }
      default:
        return allMonthWeeks;
    }
  }

  getLabelPosition(item: GridsterItem, offset: number, data: TimelineObjectData[]) {
    let value = 0;
    if (this.dateHeader.get(0)) {
      const itemWidthDisplayed = this.widthOfItemDisplayed(item);
      const columnsDisplayed = itemWidthDisplayed / this.headerWidth;

      const scrollEl = this.dateHeader.get(0)!.nativeElement;
      const itemWidth = (item.cols) * this.timelineColumnWidth;
      const displayedHeaders = scrollEl.clientWidth / this.headerWidth;

      if (data.length > 1 && columnsDisplayed < displayedHeaders) {
        const displayNormally = itemWidthDisplayed == itemWidth || columnsDisplayed >= data.length;
        if (displayNormally) return {'left': 0 + 'px'};
      }

      const floatAmount = scrollEl.scrollLeft - ((item.x - 1) * this.timelineColumnWidth) - offset;
      const endPoint = ((item.x + item.cols) * this.timelineColumnWidth) - scrollEl.scrollLeft;
      value = (floatAmount < 0 || endPoint < (this.headerWidth)) ? 0 : floatAmount;
    }
    return {'left': value + 'px'};
  }

  getEndIconPosition(item: GridsterItem, offset: number, data: TimelineObjectData[]) {
    let value = 0;
    if (this.dateHeader.get(0)) {
      const itemWidthDisplayed = this.widthOfItemDisplayed(item);
      const columnsDisplayed = itemWidthDisplayed / this.headerWidth;

      const scrollEl = this.dateHeader.get(0)!.nativeElement;
      const itemWidth = (item.cols) * this.timelineColumnWidth;
      const displayedHeaders = scrollEl.clientWidth / this.headerWidth;

      if (data.length > 1 && columnsDisplayed < displayedHeaders) {
        const displayNormally = itemWidthDisplayed == itemWidth || columnsDisplayed >= data.length;
        if (displayNormally) return {'left': 0 + 'px'};
      }

      const floatAmount = scrollEl.scrollLeft - ((item.x + item.cols) * this.timelineColumnWidth) + scrollEl.clientWidth - offset;
      const startPoint = scrollEl.scrollLeft - ((item.x - 1) * this.timelineColumnWidth) + scrollEl.clientWidth;
      value = (floatAmount > 0 || startPoint < this.headerWidth) ? 0 : floatAmount;
    }
    return {'left': value + 'px'};
  }

  showMultipleItemsLabel(data: TimelineObjectData[], item: GridsterItem): boolean {
    if (data.length > 1) {
      const widthOfItem = this.widthOfItemDisplayed(item);
      const columnsDisplayed = widthOfItem / this.headerWidth;
      if (columnsDisplayed < data.length) {
        return true;
      }
    }
    return false;
  }

  widthOfItemDisplayed(item: GridsterItem) {
    if (this.dateHeader.get(0)) {

      const itemWidth = (item.cols) * this.timelineColumnWidth;

      const scrollEl = this.dateHeader.get(0)!.nativeElement;
      // position of start of item - amount timeline scroll
      const offsetFromStart = (item.x) * this.timelineColumnWidth - scrollEl.scrollLeft;
      let itemCutoffStart = 0;
      if (offsetFromStart < 0) itemCutoffStart = Math.abs(offsetFromStart);

      // (amount timeline scroll + width of timeline displayed) - position of end of item
      const offsetFromEnd = (scrollEl.scrollLeft + (scrollEl.clientWidth - 1)) - (item.x + item.cols) * this.timelineColumnWidth;
      let itemCutoffEnd = 0;
      if (offsetFromEnd < 0) itemCutoffEnd = Math.abs(offsetFromEnd);

      let displayedWidth = itemWidth;

      if (itemCutoffStart > itemWidth || itemCutoffEnd > itemWidth) return 0;
      if (itemCutoffStart <= itemWidth) displayedWidth = displayedWidth - itemCutoffStart;
      if (itemCutoffEnd <= itemWidth) displayedWidth = displayedWidth - itemCutoffEnd;
      return displayedWidth;
    }
    return 0;
  }

  filterElements(listId: string): void{
    const searchTerm = this.timeline.timelineLists.find(list => list.listId == listId)!.searchTerm;
    this.searchList.emit({listId: listId, searchTerm: searchTerm, value : searchTerm});
  }

  preventHorizontalScroll(event: WheelEvent) {
    if (event.deltaX != 0) event.preventDefault();
  }
}
