import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilsService} from "../../services/utils.service";
import {tap} from "rxjs";
import {Retailer, retailerRoles, retailers, Role, UserGrant, userGrants} from "./styled-components.model";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmRemoveComponent} from "../confirm-remove/confirm-remove.component";
import {Location} from "@angular-material-extensions/google-maps-autocomplete";
import {AddressService} from "../../services/address/address.service";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-styled-components',
  templateUrl: './styled-components.component.html',
  styleUrls: ['./styled-components.component.scss']
})
export class StyledComponentsComponent implements OnInit, AfterViewInit{
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // normal table
  // (for sorting to work on a column - displayed column name must match variable name)

  normalTableColumns: string[] = ['select', 'uuid', 'companyName', 'retailer', 'website', 'socialLink', 'email', 'curationStatus'];
  normalTableDatasource = new MatTableDataSource<Retailer>(retailers);
  normalTableSelection = new SelectionModel<Retailer>(true, []);

  editRow: Retailer | null = null;

  // half-and-half table

  hnhTableColumns1: string[] = ['id', 'role', 'description'];
  hnhTableColumns2: string[] = ['select', 'userId', 'userEmail'];
  hnhTableDataSource1 = new MatTableDataSource<Role>(retailerRoles);
  hnhTableDataSource1Displayed = new MatTableDataSource<Role>(retailerRoles);
  hnhTableDataSource2 = new MatTableDataSource<UserGrant>(this.getItemsForSelection1());
  hnhTableSelection1 = 1;
  hnhTableSelection2 = new SelectionModel<UserGrant>(true, []);

  // google maps autocomplete

  place: PlaceResult | null = null;
  location: Location | null = null;

  constructor(public utils: UtilsService, private matDialog: MatDialog, private addressService: AddressService) {
  }

  ngOnInit() {
    this.hnhTableDataSource2 = new MatTableDataSource<UserGrant>(this.getItemsForSelection1());
  }

  ngAfterViewInit() {
    this.normalTableDatasource.sort = this.sort;

    this.paginator._intl.getRangeLabel = this.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => {}))//load paged data here//
      .subscribe();
  }

  getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    length = Math.max(length, 0);
    const numberOfPages = Math.ceil(length / pageSize);
    return `${length} Results \xa0 \xa0 \xa0 Page ${page + 1} of ${numberOfPages}`;
  };

  openLinkInNewTab(link: string) {
    window.open(link, '_blank');
  }

  // table

  addRow() {
    this.editRow = new Retailer();
  }

  removeRows() {
    const dialogRef = this.matDialog.open(ConfirmRemoveComponent, {
      panelClass: 'dialog-remove',
      data: {
        message: 'Do you want to remove the selected user from their role?'
      }
    })

    const removeSub = dialogRef.componentInstance.confirmRemove.subscribe(() => {
      // call remove service
    });

    dialogRef.afterClosed().subscribe(() => removeSub.unsubscribe());
  }

  // half-and-half table

  getItemsForSelection1(): UserGrant[] {
    const column1 = this.hnhTableDataSource1.data.map(m => m);
    const column2 = userGrants.filter(f => f.roleId === this.hnhTableSelection1);
    if (column2.length > column1.length) {
      const length = column1.length;
      for (let i = 0; i < column2.length - length; i++) column1.push(Object.create(null));
    }
    if (column2.length < column1.length) {
      const length = column2.length;
      for (let i = 0; i < column1.length - length; i++) column2.push(Object.create(null));
    }
    this.hnhTableDataSource1Displayed = new MatTableDataSource(column1);
    return column2;
  }

  setSelection1(role: Role) {
    if (role.id) {
      this.hnhTableSelection1 = role.id;
      this.hnhTableDataSource2 = new MatTableDataSource<UserGrant>(this.getItemsForSelection1());
    }
  }

  // google maps autocomplete

  public onAddressAutocompleteSelected(event: PlaceResult): void {
    this.place = event;
  }

  public onAddressLocationSelected(event: Location): void {
    this.location = event;
  }

  saveAddress() {
    const addressData = this.addressService.parseAddressData(<Location>this.location, <PlaceResult>this.place);
  }

}
