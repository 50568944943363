import {Injectable} from "@angular/core";
import {GenericService} from "../../../shared/services/generic.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ServiceInterest} from "./interests.model";

@Injectable({
  providedIn: 'root'
})
export class InterestsService extends GenericService<ServiceInterest> {
  constructor(http: HttpClient) {
    super(http, environment.apiRetailersHost + '/console/offering/district/leads/interests');
  }
}
