import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericChart} from "../../../shared/model/generic-chart.interface";

import {
  DailyDistrictOccupancy,
  LifeTimeDistrictOccupancyStats,
  OfferingSpaceLeaseHistory,
  OfferingSpacesInterface,
  OfferingSpaceStatistics,
  SpaceFilterRequest
} from '../model/offering-spaces.interface';
import {lastValueFrom, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {DateUtilsService} from '../../../shared/services/dateUtils.service';
import {GenericService, PagedContent} from '../../../shared/services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ManageSpacesService extends GenericService {


  constructor(http: HttpClient,
              public dateUtils: DateUtilsService) {
    super(http, `${environment.apiLeaseHost}/console/offering/spaces`);
  }

  getSpacesForOffering(filter: SpaceFilterRequest): Observable<OfferingSpacesInterface[]> {
    let params: any = {}
    if (filter.locked != null) {
      params['locked'] = filter.locked;
    }
    if (filter.spaceName != null) {
      params['spaceName'] = filter.spaceName;
    }

    return this.http.get<OfferingSpacesInterface[]>(`${this.baseUrl}`, {params: params});
  }

  getCurrentOccupancy(): Observable<DailyDistrictOccupancy> {
    let params: any = {
      startDate: this.dateUtils.displayShortDate(new Date())
    }
    return this.http.get<DailyDistrictOccupancy>(`${environment.apiTransactHost}/console/offering/district/stats/daily-occupancy`, {params: params});
  }

  getLifetimeOccupancyStats(): Observable<LifeTimeDistrictOccupancyStats> {
    return this.http.get<LifeTimeDistrictOccupancyStats>(`${environment.apiTransactHost}/console/offering/district/stats/lifetime-occupancy`);
  }

  getStatsForSpace(id: number): Observable<OfferingSpaceStatistics> {
    return this.http.get<OfferingSpaceStatistics>(`${this.baseUrl}/stats/${id}`);
  }

  createNewSpace(selectedSpace: OfferingSpacesInterface): Observable<OfferingSpacesInterface> {
    return this.http.post<OfferingSpacesInterface>(this.baseUrl, selectedSpace);
  }

  updateSpace(selectedSpace: OfferingSpacesInterface): Observable<OfferingSpacesInterface> {
    return this.http.post<OfferingSpacesInterface>(`${this.baseUrl}/${selectedSpace.id}`, selectedSpace);
  }

  deleteSpace(selectedSpace: OfferingSpacesInterface): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/stats/${selectedSpace.id}`);
  }

  getIndustryByCategoryChart(fromDate: string, toDate: string): Observable<GenericChart> {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    return this.http.get<GenericChart>(environment.apiTransactHost +  `/console/offering/district/dashboard/leasing/industry-category`, {params: requestParams});
  }

  public displayDuration(avgNumDays: number): string {
    if (avgNumDays < 31) {
      return `${Math.round(avgNumDays / 4)} weeks`
    }

    if (avgNumDays >= 31 && avgNumDays < 730) {
      return `${Math.round(avgNumDays / 31)} months`
    }

    if (avgNumDays >= 730) {
      return `${Math.round(avgNumDays / 365)} years`
    }
    return '0 months';
  }

  getLeaseHistory(page: { size: string; page: string; sort: string }, params?: { val: string; name: string }[]): Observable<PagedContent<OfferingSpaceLeaseHistory[]>> {
    let requestParams = new HttpParams()
      .set('sort', page.sort)
      .set('size', page.size)
      .set('page', page.page);

    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {
        requestParams = requestParams.append(ea.name, ea.val)
      });
    }
    return this.http.get<PagedContent<OfferingSpaceLeaseHistory[]>>(`${this.baseUrl}/leases`, {params: requestParams});
  }

  /**
   * Gets the total size of all the spaces for an offering that are available and not locked. Method call is synchronous
   * and will block until the response is received.
   */
  async loadTotalSize(): Promise<number> {
    let totalSize = 0;

    const spaces = await lastValueFrom(this.getSpacesForOffering({spaceName: null, locked: false}));
    spaces?.forEach(space => {
      if (!space.locked) {
        totalSize += space.spaceSize;
      }
    });

    return totalSize;
  }

}
