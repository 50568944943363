<div class="smart-form-container page-padding pe-0" fxLayout="row">
  <!--  MIDDLE-->
  <div fxFlex fxLayout="column" fxLayoutGap="15px" class="middle-container">
    <div class="form-container" fxLayout="column" fxLayoutGap="30px">
      <div *ngFor="let question of questionItems; let i = index" fxLayout="column">

        <div (click)="selectQuestionItem(question)"
             [class.question-card-active]="selectedItem?.uuid == question.uuid"
             class="question-card btn-cursor ps-0"
             fxLayout="column"
             fxLayoutGap="10px">
          <div *ngIf="!canBeScored(question); else currentWeightBar" style="padding-left: 44px"
               class="subheading-default">{{displayCantBeScoredMessage(question)}}</div>
          <ng-template #currentWeightBar>
            <mat-slider class="weight-slider" showTickMarks max="4" min="0" step="1" [disabled]="selectedItem?.uuid != question.uuid">
              <input  [value]="getQuestionGroup(question.questionGroupUuid)!.importanceRating!" (valueChange)="updateGroupRating($event)" matSliderThumb [style.pointer-events]="'none'"/>
            </mat-slider>
          </ng-template>

          <div class="full-width">
            <mat-divider class="mat-divider" style="margin-left: 44px"></mat-divider>
          </div>

          <div class="full-width smart-form-question-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div style="height: 100%">
              <mat-icon class="more-icon">more_vert</mat-icon>
            </div>
            <ng-container *ngIf="getQuestionTemplate(question); let qt">
              <div class="full-width" fxLayout="column" fxLayoutGap="20px">

                <div class="pt-1 heading-bold" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <pre class="pt-1 heading-bold">{{qt.controlDescription}}</pre>
                </div>

                <div>
                  <div *ngIf="qt.controlType == ControlType.HEADING_TEXT">
                    <pre
                      class="heading-regular">{{qt.controlPayload.label ? qt.controlPayload.label : 'Your sub-heading goes here'}}</pre>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.TEXT">
                    <mat-form-field appearance="outline" class="custom-text-field-full-width">
                      <input [(ngModel)]="question.value" matInput placeholder="Enter your answer" disabled/>
                    </mat-form-field>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.SELECT">
                    <mat-selection-list #multiList
                                        *ngIf="qt.controlPayload.selectType == 'MULTI'"
                                        [(ngModel)]="question.value" class="custom-selection-list-outline"
                                        fxLayout="column"
                                        fxLayoutGap="5px">
                      <mat-list-option *ngFor="let option of qt.controlPayload.options!"
                                       [value]="option.valueCode"
                                       togglePosition="before"
                                       (click)="multiList.deselectAll()">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                          <div>{{option.label}}</div>
                        </div>
                      </mat-list-option>
                    </mat-selection-list>

                    <mat-selection-list #singleList
                                        *ngIf="qt.controlPayload.selectType == 'SINGLE'"
                                        [(ngModel)]="question.value" [multiple]="false"
                                        class="custom-selection-list-outline"
                                        fxLayout="column"
                                        fxLayoutGap="5px">
                      <mat-list-option *ngFor="let option of qt.controlPayload.options!"
                                       [value]="option.valueCode"
                                       togglePosition="before"
                                       (click)="singleList.deselectAll()">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                          <div>{{option.label}}</div>
                        </div>
                      </mat-list-option>
                    </mat-selection-list>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.CHOICE">
                    <mat-selection-list #choiceList
                                        [(ngModel)]="question.value"
                                        [multiple]="false" class="custom-selection-list-outline"
                                        fxLayout="column"
                                        fxLayoutGap="5px">
                      <mat-list-option [value]="qt.controlPayload.selectedOption" (click)="choiceList.deselectAll()">Yes</mat-list-option>
                      <mat-list-option [value]="qt.controlPayload.unselectedOption" (click)="choiceList.deselectAll()">No</mat-list-option>
                    </mat-selection-list>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.LOCATION">
                    <mat-form-field appearance="outline" class="custom-text-field-full-width">
                      <input
                        matGoogleMapsAutocomplete
                        matInput
                        placeholder="Enter your location" [style.pointer-events]="'none'">
                    </mat-form-field>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.FILE">

                    <div *ngIf="qt.controlPayload.files!.length > 0" fxLayout="row" fxLayoutGap="10px">
                      <div *ngFor="let file of qt.controlPayload.files;let i = index">
                        <input hidden id="file-upload-{{i}}"
                               type="file"/>

                        <label class="btn-cursor" for="file-upload-{{i}}">
                          <div class="file-upload-container btn-cursor"
                               fxLayout="column" fxLayoutAlign="end center" matRipple>
                            <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                            <div class="body-1-regular-1 py-3">{{ file.label }}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.INFO_FILE">

                    <div *ngIf="qt.controlPayload.files!.length > 0" fxLayout="row" fxLayoutGap="10px">
                      <div *ngFor="let file of qt.controlPayload.files;let i = index">
                        <label class="btn-cursor">
                          <div class="file-upload-container btn-cursor"
                               fxLayout="column" fxLayoutAlign="end center" matRipple>
                            <mat-icon class="material-icons-outlined">file_download</mat-icon>
                            <div class="body-1-regular-2 pt-3">{{ file.label }}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.DATE">

                    <mat-form-field appearance="outline" class="custom-date-picker-full-width">
                      <input [matDatepicker]="picker" [value]="question.value"
                             disabled
                             matInput placeholder="Select your date">
                      <mat-datepicker-toggle [for]="picker" matIconSuffix>
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.NUMBER">
                    <mat-form-field appearance="outline" class="custom-text-field-full-width">
                      <input [(ngModel)]="question.value" matInput placeholder="Enter your answer"
                             type="number" readonly>
                      <div *ngIf="qt.controlPayload.numberType == 'CURRENCY'" class="ms-2 body-1-regular-1"
                           matPrefix>R
                      </div>
                      <div *ngIf="qt.controlPayload.numberType == 'PERCENTAGE'" class="me-2 body-1-regular-1"
                           matSuffix> %
                      </div>
                    </mat-form-field>
                  </div>

                  <div *ngIf="qt.controlType == ControlType.RANGE">
                    <app-custom-range-selector
                      [questionItem]="toQuestionItem(question)"></app-custom-range-selector>
                  </div>

                </div>
              </div>
            </ng-container>

          </div>

        </div>
      </div>
    </div>
  </div>

  <!--  RIGHT-->
  <div class="right-container" fxFlex="300px">
    <app-question-weight-config (publishForm)="publishFormTemplate()"
                                (questionGroupChange)="updateQuestionGroup($event)"
                                (saveForm)="saveForm()"
                                (previewForm)="previewForm()"
                                [questionGroup]="selectedQuestionGroup">

    </app-question-weight-config>
  </div>
</div>
