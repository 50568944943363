import {BlockingQueueService} from "../../../shared/services/blocking-queue.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {MonthlyStat} from "../dashboards.model";

export function build(http: HttpClient): DashboardsLeadsReportService {
  return new DashboardsLeadsReportService(http);
}

export class DashboardsLeadsReportService extends BlockingQueueService {
  private http: HttpClient;

  constructor(http: HttpClient) {
    super();
    this.http = http;
  }

  private get getBaseUrl(): string {
    return environment.apiTransactHost + '/console/offering/district/dashboard/leads';
  }

  refreshBestMonthlyStats(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<MonthlyStat[]>(this.getBaseUrl + `/best-stats`, {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshNewQualifiedLeadsChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'qualified-leads');
  }

  refreshApprovalRatesChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'approval-rates');
  }

  refreshLeadCategoriesChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'categories');
  }

  refreshChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void, urlSuffix: string): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl + `/${urlSuffix}`, {params: requestParams});
    this.enqueue(task, callback, error);
  }
}
