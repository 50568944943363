<div class="dashboard-page" fxLayout="column">

  <app-page-header [banner]="true" [title]="'Drive every aspect of your property performance from one central dashboard'" [description]="'Select one of the reports below to access.'"></app-page-header>

  <div class="full-screen-height-page-banner full-width page-padding" fxLayout="column" style="padding-top: 60px">
    <div class="subtitle-default">All Available Dashboards</div>

    <div class="full-width properties-container" fxLayout="column" fxFlexOffset="30px" fxLayoutGap="20px">
      <div *ngFor="let offering of offerings" fxLayout="column" fxLayoutGap="20px">
        <div class="heading-bold" *ngIf="getDashboardTypes(offering.offeringUuid).length > 0">{{offering.offeringName}}</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
             *ngIf="getDashboardTypes(offering.offeringUuid).length > 0">
          <mat-card class="property-card btn-cursor" fxFlex="33.3"
                    *ngFor="let type of getDashboardTypes(offering.offeringUuid)"
                    (click)="openOfferingDashboardTab(type.name, offering)" fxLayout="column">
            <div class="mat-card-image-container">
              <img class="overlay-under-image" [src]="type.src" alt="Image of an example {{type.name}} dashboard">
              <div class="overlay" fxLayout="row" fxLayoutAlign="center center">
                <button mat-button class="button-outline">
                  <div class="button-text">View</div>
                </button>
              </div>
            </div>
            <mat-card-content fxFlexOffset="20px">
              <div class="heading-default">{{type.name}}</div>
              <div class="heading-regular">{{offering.offeringName}}</div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

  </div>
</div>
