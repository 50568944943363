import {ControlType} from "../../../features/leads/application-form-builder/add-questions/question-components.model";
import {ApplicationAssessmentStatus} from '../../../features/leads/retailers/retailers.model';

export interface RetailerApplicationAssessment {
  id: number,
  retailerId: number,
  finalised: boolean,
  outcome: ApplicationAssessmentStatus
  notes: string,
  payload: ApplicationForm,
  formTemplateCode: string,
  formTemplateVersion: number,
  overallScore: number,
  scoringBreakdown: ScoringBreakdown,
  applicationSource: string
}

export interface ApplicationForm {
  uuid: string | null;
  formCode: string | null;
  version: number | null;
  startDate: string | null;
  endDate: string | null;
  isCurrentlyActive: boolean,
  categories: Category[];
  formName: string | null;
  formStatus: string | null;
  updateDate: string | null;
}

export class ApplicationFormImp implements ApplicationForm {
  uuid = null;
  formCode = null;
  version = 1;
  startDate = null;
  endDate = null;
  categories = [];
  formName = null;
  updateDate = null;
  formStatus = null;
  isCurrentlyActive: boolean = false;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

}

export class Category {
  uuid: string | null = null;
  formUuid: string | null = null;
  displayOrder: number = 0;
  code: string = 'form_builder';
  displayLabel: string = 'Form builder';
  displayDescription: string | null = null;
  notes: string | null = null;
  questionGroups: QuestionGroup[] = [];
}


export interface QuestionGroup {
  uuid: string;
  categoryUuid: string | null;
  displayOrder: number;
  code: string | null;
  displayLabel: string | null;
  displayDescription: string | null;
  importanceRating?: number | null;
  questions: Question[];
  repeatedInput: boolean;
  repeatedValues: Question[][] | null;
}

export interface Question {
  uuid: string;
  questionGroupUuid: string;
  displayOrder: number;
  required: boolean;
  value: any | null;
  replacedByQuestionUuid: string | null;
  questionTemplate: QuestionTemplate;
}

export interface QuestionTemplate {
  uuid: string;
  districtUuid: string | null;
  controlCode: string | null;
  controlDescription: string | null;
  controlType: ControlType;
  controlPayload: ControlPayload;
}

export interface ControlPayload {
  label: string | null;
  weight?: number | null;
  precision?: number | null;
  min?: number | null;
  max?: number | null;
  passingValue?: number | null;
  scorePassType?: ScorePassType;
  selectType?: SelectType;
  options?: Option[];
  format?: string | null;
  selectedOption?: Option;
  unselectedOption?: Option;
  start?: number;
  end?: number;
  increments?: RangeOption[];
  maxSizeMb?: number;
  fileTypes?: string[];
  files?: Option[];
  rangeType?: RangeType;
  numberType?: NumberType;
}

export interface Option {
  label: string;
  valueCode: string | null;
  weight?: number | null;
}

export interface RangeOption extends Option {
  start: number;
  end: number;
  incrementSize: number;
}

export enum NumberType {
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export enum RangeType {
  NUMBER = 'NUMBER',
  DATE = 'DATE'
}

export enum SelectType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI'
}

export enum ScorePassType {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW'
}

export interface ScoringBreakdown {
  preferredAndPassed: number;
  firstRating: number;
  secondThirdRating: number;
  dontPass: number;
}

export interface RecommendationConfig {
  lowerLimit: number;
  upperLimit: number;
  recommendationType: string;
  message: string;
}

export interface FormQuestionFilter {
  assessmentId: number | null;
  formCode: string;
  categoryUuid: string;
  questionGroupUuid: string;
  questionUuid: string;
  fileCode: string | null;
}
