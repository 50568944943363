<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button (click)="close()" class="button-close-top" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width flex-column" fxLayoutAlign="center center" fxLayoutGap="15px">
      <div class="title-default"> Please verify changes to your banking details</div>
      <div class="subtitle-default"> We have sent a One Time Pin to {{email}}</div>
    </div>

    <div fxFlexOffset="20px"></div>

    <div class="flex-row">
      <div class=" p3 full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <div class="otp-text" fxFlexOffset="10px" fxFlexOffset.gt-sm="15px">
          <ng-otp-input
            #ngOtpInput
            (onInputChange)="onOtpChange($event)"
            [config]="{
              length: 4,
              allowNumbersOnly: true,
              containerClass: 'otp-wrapper-element',
              inputClass: 'otp-input-element'
            }"
          ></ng-otp-input>
        </div>
        <mat-error *ngIf="errMessage">{{ errMessage }}</mat-error>

        <div class="" fxFlexOffset="30px" fxFlexOffset.gt-sm="30px" fxLayoutGap="20px">
          <button (click)="onVerify()" [disabled]="loading || len < 4" class="button-primary" mat-flat-button
                  style="width: 200px">
            <div class="button-text">{{ loading ? 'Working...' : 'Verify'}}</div>
            <mat-icon *ngIf="loading">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </button>
        </div>

        <div class="text-center" fxFlexOffset.gt-sm="15px">
          Didn’t receive an OTP?
          <a (click)="!loading ? onResendOTP() : null" [routerLink]="" class="link-text btn-cursor">Resend OTP.</a>
        </div>
      </div>
    </div>
  </div>
</div>
