import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent {
  @Input() message!: string[];
  @Input() type!: PanelType;
  @Input() fullWidth: boolean = false;

  @Input() buttons: boolean = false;
  @Input() buttonLabels?: (string | null)[] = [];
  @Input() buttonDisabled?: (boolean)[] = [];

  @Output() emitEvent = new EventEmitter<number>();

  getPanelType(): { iconClass: string, icon: string } {
    switch (this.type) {
      case PanelType.DISABLED:
        return { iconClass: 'disabled-icon', icon: 'cancel'};
      case PanelType.WARN:
        return { iconClass: 'warn-icon', icon: 'error'};
      case PanelType.ERROR:
        return { iconClass: 'error-icon', icon: 'cancel'};
      case PanelType.INFO:
        return { iconClass: 'info-icon', icon: 'info'};
      default:
        return { iconClass: 'info-icon', icon: 'info'};
    }
  }

  emit(index: number) {
    this.emitEvent.emit(index);
  }

  protected readonly PanelType = PanelType;
}

export enum PanelType {
  ERROR,
  WARN,
  INFO,
  DISABLED
}
