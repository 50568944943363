import {SelectionModel} from "@angular/cdk/collections";
import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../../core/services/loading.service";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {OfferingSpacesInterface} from '../../../../spaces/model/offering-spaces.interface';
import {
  AgreementType,
  LeaseGridSpace,
  LeaseSpaceForTerm,
  LeaseSummary,
  SharedLeasesSpaceSize,
  SpaceSelection,
  SubLeaseRequest
} from "../../../leasing.model";
import {LeaseSubLeasesService} from "../../lease-sub-leases/lease-sub-leases.service";
import {LeaseViewService} from "../../lease-view.service";

@Component({
  selector: 'app-add-a-space-dialog',
  templateUrl: './add-a-space-dialog.component.html',
  styleUrls: ['./add-a-space-dialog.component.scss']
})
export class AddASpaceDialogComponent implements AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  dataSource!: MatTableDataSource<LeaseSpaceForTerm>;
  displayedColumns = ['select', 'id', 'spaceName', 'size'];

  retailer!: { id: string, companyName: string };

  edit = false;
  header: string = '';
  lease!: LeaseSummary;
  space: LeaseGridSpace;
  sharedSpace: SharedLeasesSpaceSize;
  parentSpace: number;
  offering: PageOffering;

  startDate!: Date;
  endDate!: Date;

  minStart: Date | null = null;
  maxEnd: Date | null = null;

  availableSpace = 0;

  selection = new SelectionModel<OfferingSpacesInterface>(true, []);

  constructor(public dialogRef: MatDialogRef<AddASpaceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private subLeasesService: LeaseSubLeasesService,
              private leaseViewService: LeaseViewService,
              public loader: LoadingService) {
    this.edit = data.edit;
    this.header = data.header;
    this.lease = data.lease;
    this.space = data.space;
    this.sharedSpace = data.sharedSpace;
    this.offering = data.offering;
    this.parentSpace = data.parentSpace;

    if (!this.isSubLease && this.space && this.space.leaseStartDate && this.space.leaseEndDate) {
      this.startDate = new Date(this.space.leaseStartDate);
      this.endDate = new Date(this.space.leaseEndDate);
      this.loadSpaces();
    }

    if (data.minStart && data.maxEnd) {
      this.minStart = new Date(data.minStart);
      this.maxEnd = new Date(data.maxEnd);
    }

    if (this.isSubLease && this.sharedSpace && this.sharedSpace.startDate && this.sharedSpace.endDate) {
      this.startDate = new Date(this.sharedSpace.startDate);
      this.endDate = new Date(this.sharedSpace.endDate);
      this.loadSpaces();
    }

    if (this.isSubLease && !this.sharedSpace) {
      this.sharedSpace = {
        uuid: null,
        leaseUuid: this.lease.parentUuid!,
        subLeaseUuid: this.lease.uuid,
        occupiedSpace: 0,
        availabilityStatus: 'PENDING',
        startDate: this.dateUtils.displayShortDate(new Date(this.lease.leaseStartDate))!,
        endDate: this.dateUtils.displayShortDate(new Date(this.lease.leaseEndDate))!,
      }
    }
  }

  ngAfterViewInit() {
    if (this.paginator) this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }

  loadSpaces() {
    if (!this.isSubLease) {
      this.leaseViewService.getDistrictSpacesForDateRange(this.dateUtils.displayShortDate(this.startDate)!, this.endDate ? this.dateUtils.displayShortDate(this.endDate) : null).subscribe({
        next: value => {
          const availableSpaces = value.leaseSpaces.filter(f => f.availabilityStatus === 'AVAILABLE');
          this.dataSource = new MatTableDataSource<LeaseSpaceForTerm>(availableSpaces);
          this.dataSource.paginator = this.paginator;
        },
        error: err => console.log(err)
      })
    }
    if (this.isSubLease) {
      this.subLeasesService.getAvailableSpaceForDateRange(this.lease.parentUuid!, this.lease.uuid, this.sharedSpace.uuid, this.dateUtils.displayShortDate(this.startDate)!, this.endDate ? this.dateUtils.displayShortDate(this.endDate) : null).subscribe({
        next: value => {
          this.availableSpace = value;
        },
        error: err => console.log(err)
      })
    }
  }

  saveSpaces() {
    if (!this.isSubLease) {
      const request = new SpaceSelection(
        this.lease.uuid,
        this.lease.retailerId,
        this.offering.offeringUuid,
        this.lease.occupancyType!,
        this.lease.agreementType!,
        this.dateUtils.displayShortDate(this.startDate),
        this.dateUtils.displayShortDate(this.endDate),
        this.edit ? [this.space.spaceName!] : this.selection.selected.map(m => m.spaceName!),
        this.edit || this.lease.leaseType == 'APPLICATION',
        null,
        null
      )
      this.leaseViewService.saveLeaseSpaces(request, this.lease.uuid)
        .subscribe({
          next: () => {
            this.close();
          },
          error: err => console.log(err)
        })
    }

    if (this.isSubLease) {
      const request: SubLeaseRequest = {
        retailerId: this.lease.retailerId,
        offeringUuid: this.lease.districtUuid!,
        subLeaseUuid: this.lease.uuid,
        leaseUuid: this.lease.parentUuid!,
        spaceUuid: this.sharedSpace.uuid,
        occupiedSpace: this.sharedSpace.occupiedSpace,
        leaseStartDate: this.dateUtils.displayShortDate(this.startDate)!,
        leaseEndDate: this.dateUtils.displayShortDate(this.endDate)!
      }
      this.subLeasesService.updateSubLease(request)
        .subscribe({
          next: () => {
            this.close();
          },
          error: err => console.log(err)
        })
    }

  }

  updateDates() {
    if (this.isLeaseSpacePastDated()) {
      this.startDate = new Date(this.isSubLease ? this.sharedSpace.startDate : this.space.leaseStartDate);
    }
    if (this.startDate && this.endDate) {
      this.loadSpaces();
    }
  }

  close() {
    this.dialogRef.close();
  }

  isLeaseSpacePastDated(): boolean {
    if (!this.isSubLease) {
      return this.edit
        && (this.dateUtils.isPastDated(this.space.leaseStartDate)
        || this.dateUtils.isPresentDated(this.space.leaseEndDate));
    }
    if (this.isSubLease) {
      return this.edit
        && (this.dateUtils.isPastDated(this.sharedSpace.startDate)
          || this.dateUtils.isPresentDated(this.sharedSpace.endDate));
    }
    return false;
  }

  getErrorMessage() {
    if (this.sharedSpace) {
      if (this.sharedSpace.occupiedSpace == 0) return 'You must enter a value above 0';
      if (this.sharedSpace.occupiedSpace > this.availableSpace) return 'There is not sufficient available space for the chosen dates';
    }
    return null;
  }

  get isSubLease() {
    return this.lease.agreementType == AgreementType.SUB_LEASE;
  }

  protected readonly AgreementType = AgreementType;
}
