<div class="container" fxLayout="column">
  <div class="full-width" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
    <button [disabled]="selectedTemplate == null" mat-icon-button (click)="clearCurrentSelectedTemplate()">
      <mat-icon class="material-icons-outlined" >keyboard_return</mat-icon>
    </button>
    <button [disabled]="selectedTemplate == null" mat-flat-button class="button-primary-dark"
            (click)="saveAndExportHtml()">
      <div class="button-text">Save and Activate</div>
    </button>
  </div>
  <div fxLayout="row" class="full-width">

    <div class="unlayer-editor-container">

      <div id="{{pageId}}" class="unlayer-editor" [hidden]="selectedTemplate == null"></div>
    </div>
    <!--
        <ng-container *ngIf="isVisible$ | async">
        <email-editor class="unlayer-editor full-width" #editor id="{{pageId}}"
                      [projectId]="environment.unlayerApiProjectId"
                      (loaded)="editorLoaded($event)"
                      (ready)="editorReady($event)"></email-editor>
        </ng-container>-->
  </div>
</div>
