import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {RegisterRetailer, Retailer} from "../../retailers.model";

@Injectable({
  providedIn: 'root'
})
export class CreateARetailerService {

  constructor(private http: HttpClient) {
  }

  public registerNewRetailer(request: RegisterRetailer): Observable<Retailer> {
    return this.http.post<Retailer>(this.baseUrl + '/register', request);
  }

  public registerNewOfferingRetailer(request: RegisterRetailer): Observable<Retailer> {
    console.log('creating offering retailer', request);
    return this.http.post<Retailer>(this.baseUrl + '/register/offering', request);
  }

  get baseUrl() {
    return environment.apiRetailersHost + `/console/offering/district/pipeline/retailers`;
  }

}
