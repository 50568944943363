<div style="background-color: white" fxLayout="column">

  <app-page-header [banner]="true" [title]="'Leads Report'" [subtitle]="'Explore the report for a great overview of your lead\'s statistics!'"></app-page-header>

  <div class="page-padding" fxLayout="column" fxLayoutGap="30px">

    <app-dashboards-best-stats [bestMonthlyStats]="bestMonthlyStats"></app-dashboards-best-stats>

    <app-dashboards-chart-section sectionName="New Qualified Leads"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [pickerType]="'year'"
                                  [(date1)]="year1"
                                  [(date2)]="year2"
                                  [dateFilterType]="'picker'"
                                  [chart1]="newQualifiedLeadsChart"
                                  (datePickerClosed)="datePickerUpdated()">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Approval Rates"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="0"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="approvalRatesChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Lead Categories"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_month'"
                                  [dateRangeIndex]="1"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="leadCategoriesChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>
  </div>

</div>
