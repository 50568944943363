import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DateRange, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {LoadingService} from "../../../core/services/loading.service";
import {CustomDateRangeChangeEventModel} from './custom-date-range-change-event.model';
import {CustomDateRangeModel, TimeFrames} from './custom-date-range.model';

@Component({
  selector: 'app-custom-date-filter',
  templateUrl: './custom-date-filter.component.html',
  styleUrls: ['./custom-date-filter.component.scss']
})
export class CustomDateFilterComponent implements OnInit {

  @Input() id!: string;
  @Input() presetsShowing: boolean = true;
  @Input() datePickerShowing: boolean = true;
  @Input() showBorder: boolean = false;
  @Input() customLabel?: string | null;
  @Input() initialPreset?: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() showClearButton: boolean = false;
  @Input() placeholder = 'Custom';
  @Input() displayDateInRange: boolean = true;

  @Input() customDateRangePresets?: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 2, TimeFrames.DAYS),
    new CustomDateRangeModel('2', 1, TimeFrames.WEEK),
    new CustomDateRangeModel('3', 1, TimeFrames.MONTH)
  ];
  @Output() dateRange: EventEmitter<CustomDateRangeChangeEventModel> = new EventEmitter<CustomDateRangeChangeEventModel>();
  selectedPreset: string = '';
  dateOption!: string;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  loading$ = this.loader.loading$;

  constructor(private loader: LoadingService) {
  }

  ngOnInit() {
    if (this.initialPreset) {
      this.dateOption = this.initialPreset;
      this.broadcastDateRange({value: this.initialPreset});
    }
  }

  public broadcastDateRange(event: {value: any}) {
    this.setSelectedPreset(event);
    //Check if a preset has been selected
    switch (this.dateOption) {
      case 'custom': {
        let startDate = this.range.controls.start === null ? new Date() : this.range.controls.start.value;
        let endDate = this.range.controls.end === null ? new Date() : this.range.controls.end.value;
        this.dateRange.emit(new CustomDateRangeChangeEventModel(
          this.id,
          new DateRange<Date>(startDate, endDate)
        ));
        break;
      }
      default: {
        let dateRangeModel = this.customDateRangePresets!.find(preset => {
          return (preset.presetId === this.selectedPreset)
        });
        if (dateRangeModel === undefined) {
          dateRangeModel = new CustomDateRangeModel('0', 1, TimeFrames.DAYS);
        }

        if (dateRangeModel.timeFrame == TimeFrames.BOUNDARY) {
          let startDate = dateRangeModel.getDateRange().start;
          let endDate = dateRangeModel.getDateRange().end;

          if (dateRangeModel.numericValue == 0) startDate =  this.range.controls.start === null ? new Date() : this.range.controls.start.value;
          if (dateRangeModel.numericValue == 1) endDate = this.range.controls.end === null ? new Date() : this.range.controls.end.value;

          this.dateRange.emit(new CustomDateRangeChangeEventModel(
            this.id,
            new DateRange<Date>(startDate, endDate)
          ));

          if (this.displayDateInRange) {
            this.range.setValue({start: startDate, end: endDate});
          }
          break;
        } else {
          this.dateRange.emit(new CustomDateRangeChangeEventModel(this.id, dateRangeModel.getDateRange()));

          if (this.displayDateInRange) {
            this.range.setValue({start: dateRangeModel.getDateRange().start, end: dateRangeModel.getDateRange().end});
          }
        }
        break;
      }
    }
  }

  public setCustomDate(dateRange: DateRange<Date | null>) {
    this.setSelectedPreset({value: "custom"});
    this.range.setValue({start: dateRange.start, end: dateRange.end});
  }

  public toggleDatePickerShowing(): void {
    this.datePickerShowing = !this.datePickerShowing;
  }

  public togglePresetsShowing(): void {
    this.presetsShowing = !this.presetsShowing;
  }

  setSelectedPreset($event: { value: any }) {
    this.selectedPreset = $event.value;
  }

  selectCustomDate(update: boolean) {
    this.dateOption = 'custom';
    if (update) {
      this.broadcastDateRange({value: ''});
    }
  }

  selectBoundaryDate(update: boolean, event: MatDatepickerInputEvent<Date>, dateRange: CustomDateRangeModel) {
    this.dateOption = dateRange.presetId;
    if (update) {
      if (dateRange.numericValue == 0) {
        this.range.setValue({start: event.value, end: dateRange.getDateRange().end});
      }
      if (dateRange.numericValue == 1) {
        this.range.setValue({start: dateRange.getDateRange().start, end: event.value});
      }
      this.broadcastDateRange({value: dateRange.presetId});
    }
  }

  clearFilters(): void {
    this.range.get('start')?.setValue(null);
    this.range.get('end')?.setValue(null);

    if (this.initialPreset) {
      this.dateOption = this.initialPreset;
      this.selectedPreset = this.initialPreset;
      this.broadcastDateRange({value: this.initialPreset});
    } else {
      this.selectedPreset = '';
      this.dateRange.emit(new CustomDateRangeChangeEventModel(
        this.id,
        new DateRange<Date>(null, null)
      ));
    }
  }

  protected readonly TimeFrames = TimeFrames;
}
