import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';

import {BillingDetailsModel} from '../model/billing-details.model';
import {environment} from '../../../../environments/environment';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {Observable} from 'rxjs';
import {BaseService} from '../../../shared/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class BillingDetailsService extends BaseService {

  baseUrl = environment.apiDistrictsHost;

  constructor(private http: HttpClient, private context: CurrentContextService) {
    super();
  }

  updateBillingDetails(request: BillingDetailsModel): Observable<BillingDetailsModel> {
    const offeringUuid = this.context.getCurrentOfferingId();
    const uri = this.baseUrl + `/console/districts/${offeringUuid}/billing`;
    return this.http.post<BillingDetailsModel>(uri, request);
  }

  getBillingDetailsForRetailer(): Observable<BillingDetailsModel> {
    const offeringUuid = this.context.getCurrentOfferingId();
    const uri = this.baseUrl + `/console/districts/${offeringUuid}/billing`;
    return this.http.get<BillingDetailsModel>(uri);
  }
}
