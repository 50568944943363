import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {GenericService} from "../../../../shared/services/generic.service";
import {LeaseStatus, LeaseSummary, SharedLeasesSpaceSize, SubLeaseRequest} from "../../leasing.model";

@Injectable({
  providedIn: 'root'
})
export class LeaseSubLeasesService extends GenericService<LeaseSummary>{
  constructor(http: HttpClient) {
    super(http, environment.apiLeaseHost + `/console/manage-lease/sub-lease`);
  }

  getSharedSpaces(leaseId: string): Observable<SharedLeasesSpaceSize[]> {
    return this.http.get<SharedLeasesSpaceSize[]>(this.baseUrl + `/${leaseId}/spaces`);
  }

  removeSharedSpaces(offeringUuid: string, leaseId: string, spaceUuids: string[]) {
    let requestParams = new HttpParams()
      .set('offeringUuid', offeringUuid)
      .set('spaceUuids', spaceUuids.toString());

    return this.http.delete<void>(this.baseUrl + `/${leaseId}/spaces`, {params: requestParams});
  }

  getAvailableSpaceForDateRange(leaseUuid: string, subLeaseUuid: string | null, uuid: string | null, startDate: string, endDate: string | null): Observable<number> {
    let requestParams = new HttpParams()
      .set('leaseUuid', leaseUuid)
      .set('startDate', startDate);

    if (subLeaseUuid) requestParams = requestParams.append('subLeaseUuid', subLeaseUuid);
    if (uuid) requestParams = requestParams.append('uuid', uuid);
    if (endDate) requestParams = requestParams.append('endDate', endDate);

    return this.http.get<number>(this.baseUrl + `/available-space`, {params: requestParams});
  }

  createSubLease(lease: SubLeaseRequest): Observable<LeaseSummary> {
    return this.http.post<LeaseSummary>(this.baseUrl + `/new`, lease);
  }

  updateSubLease(lease: SubLeaseRequest): Observable<LeaseSummary> {
    return this.http.post<LeaseSummary>(this.baseUrl + `/${lease.subLeaseUuid}`, lease);
  }

  updateSubLeaseStatus(leaseId: string, newStatus: LeaseStatus): Observable<LeaseSummary> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<LeaseSummary>(this.baseUrl + `/${leaseId}/update-status`, `"${newStatus}"`, options);
  }

  removeSubLease(leaseId: string) {
    return this.http.delete<void>(this.baseUrl + `/${leaseId}`);
  }
}
