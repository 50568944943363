<div class="full-width" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Spaces</span>
      <div fxLayoutGap="10px" fxFlexOffset="30px">
        <button (click)="addSpace()" class="button-action" mat-flat-button [disabled]="!!(loader.loading$ | async)" *ngIf="lease?.leaseType == 'LEASE'">
          <div class="button-text">Add</div>
        </button>
        <button (click)="removePanelOpen = true" [disabled]="canRemove || !!(loader.loading$ | async)" class="button-action"
                mat-flat-button *ngIf="lease?.leaseType == 'LEASE'">
          <div class="button-text">Remove</div>
        </button>
        <button (click)="editSpace()" *ngIf="canEdit" class="button-action" mat-flat-button [disabled]="!!(loader.loading$ | async)">
          <div class="button-text">Edit</div>
        </button>

      </div>
      <div fxFlexOffset="20px" *ngIf="removePanelOpen">
        <app-confirm-remove [message]="'Do you want to remove the selected spaces from this lease.'" (confirmRemove)="removeSpace($event)"></app-confirm-remove>
      </div>
    </div>
    <div>
      <app-info-panel *ngIf="lease?.leaseStatus == 'INVALID'" [type]="PanelType.ERROR" [message]="['The spaces is overlapping with an approved or active lease. Change the space or end the overlapping lease.']"></app-info-panel>
    </div>

  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Current Spaces</span>

    <table mat-table class="custom-table" [dataSource]="leaseSpaceDataSource" fxFlexOffset="25px" *ngIf="leaseSpaceDataSource">

      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedSpaceColumns.length"> Lease Spaces </th>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="custom-table-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? leaseGridSpaceSelection.toggle(row) : null"
                        [checked]="leaseGridSpaceSelection.isSelected(row)" [disabled]="lease?.leaseType == 'APPLICATION'">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="spaceName">
        <th mat-header-cell *matHeaderCellDef> Space Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.spaceName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size (sum) </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.spaceSize}} m<sup>2</sup> </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedSpaceColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedSpaceColumns;"></tr>
    </table>

    <table mat-table class="custom-table" [dataSource]="sharedSpacesDataSource" fxFlexOffset="25px" *ngIf="sharedSpacesDataSource">

      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedSharedSpaceColumns.length"> Lease Spaces </th>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="custom-table-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? sharedSpacesSpaceSelection.toggle(row) : null"
                        [checked]="sharedSpacesSpaceSelection.isSelected(row)" [disabled]="lease?.leaseType == 'APPLICATION'">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="occupiedSpace">
        <th mat-header-cell *matHeaderCellDef> Occupied Space </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.occupiedSpace}} m<sup>2</sup> </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.startDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.endDate)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedSharedSpaceColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedSharedSpaceColumns;"></tr>
    </table>

    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource ? dataSource.length : 0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
