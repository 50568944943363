import {Component, Input, OnInit} from '@angular/core';
import {
  ControlType,
  FormFileValue,
  templateComponents
} from 'src/app/features/leads/application-form-builder/add-questions/question-components.model';
import {
  Category, FormQuestionFilter,
  Question,
  QuestionGroup,
  QuestionTemplate,
  RangeType,
  RecommendationConfig,
  RetailerApplicationAssessment,
  ScorePassType,
  ScoringBreakdown,
  SelectType
} from '../../../../../core/model/retailer/application-form.model';
import {LoadingService} from "../../../../../core/services/loading.service";
import {PanelType} from "../../../../../shared/components/info-panel/info-panel.component";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {chartColors} from "../../../../dashboards/chart-options/chart-colors.model";
import {ApplicationAssessmentStatus, Retailer} from "../../../retailers/retailers.model";

import {ApplicationsService} from "../../applications.service";

@Component({
  selector: 'app-applicant-results',
  templateUrl: './applicant-results.component.html',
  styleUrls: ['./applicant-results.component.scss']
})
export class ApplicantResultsComponent implements OnInit {
  @Input() id!: string;
  curated!: boolean;

  applicantAssessment!: RetailerApplicationAssessment;
  originalAssessment!: RetailerApplicationAssessment;
  retailer!: Retailer;
  recommendation!: RecommendationConfig;

  removePanelOpen: boolean = false;

  formChanged = false;

  groupUuids: string[] = [];

  ratingBars: { key: string, label: string, value: number, color: chartColors }[] = [
    {key: 'preferredAndPassed', label: 'Preferred and passed', value: 0, color: chartColors.BLUE},
    {key: 'firstRating', label: '1<sup>st</sup> Rating', value: 0, color: chartColors.LIGHT_PURPLE},
    {key: 'secondThirdRating', label: '2<sup>nd</sup> and 3<sup>rd</sup> Rating', value: 0, color: chartColors.GREEN},
    {key: 'dontPass', label: 'Don\'t pass', value: 0, color: chartColors.ORANGE}
  ];

  controlTypesFilterList = [
    ControlType.FILE,
    ControlType.TEXT,
    ControlType.LOCATION,
    ControlType.DATE,
    ControlType.HEADING,
    ControlType.HEADING_TEXT,
    ControlType.INFO_FILE
  ];

  failIcon = '<mat-icon class="material-icons-outlined mat-icon warn-orange-color" color="warn" matIconSuffix> block</mat-icon>'
  passIcon = '<mat-icon color="primary" class="material-icons-outlined mat-icon primary-color">workspace_premium</mat-icon>'

  constructor(private applicationsService: ApplicationsService,
              private utils: UtilsService, public loader: LoadingService, private dateUtils: DateUtilsService) {
  }

  ngOnInit() {
    this.getAssessment();
    this.loadRetailer();
    this.getAssessmentRecommendation();
  }

  loadRetailer() {
    this.applicationsService.getOne('', this.id).subscribe({
      next: value => {
        this.retailer = value;
        this.curated = (this.retailer.curated === ApplicationAssessmentStatus.APPROVED);
      },
      error: err => {
        console.log(err);
      }
    })
  }

  getAssessment() {
    this.applicationsService.getLatestRetailerAssessment(this.id).subscribe({
      next: value => {
        this.applicantAssessment = value;
        this.originalAssessment = {...value};
        this.hasFormChanged();
        this.setGroupUuids();
        this.setRatingBars();
      },
      error: err => { console.log(err); },
    })
  }

  getAssessmentRecommendation() {
    this.applicationsService.getAssessmentRecommendation(this.id).subscribe({
      next: value => {
        this.recommendation = value;
      },
      error: err => { console.log(err); },
    })
  }

  getQuestionValue(question: Question): any {
    if (question.value) {
      if (this.isControlSelectSingle(question.questionTemplate) && question.value.length > 0) {
        if (typeof question.value == 'string') return question.questionTemplate.controlPayload.options?.filter((f) => question.value == f.valueCode!)[0].label;
        let selectedOptions = [...question.value];
        return question.questionTemplate.controlPayload.options?.filter((f) => selectedOptions.includes(f.valueCode!))[0].label;
      }
      if (this.isControlSelectMultiple(question.questionTemplate) && question.value.length > 0) {
        let selectedOptions = [...question.value];
        return question.questionTemplate.controlPayload.options?.filter((f) => selectedOptions.includes(f.valueCode!));
      }
      if (question.questionTemplate.controlType === ControlType.DATE) {
        return this.dateUtils.displayShortDate(question.value);
      }
      if (question.questionTemplate.controlType === ControlType.RANGE) {
        return question.questionTemplate.controlPayload.increments!.filter(increment => increment.valueCode === question.value)[0].label;
      }
      if (question.questionTemplate.controlType === ControlType.LOCATION) {
         if (typeof question.value == 'object') {
           return question.value.address.formatted_address;
         }
      }
      if (question.questionTemplate.controlType == ControlType.CHOICE && question.value.length > 0) {
        const controlPayload = question.questionTemplate.controlPayload;
        const value = [...question.value];
        if (value.length > 0 && typeof value[0] == 'string') {
          return (controlPayload.selectedOption?.valueCode == question.value) ? controlPayload.selectedOption!.label : controlPayload.unselectedOption!.label;
        }
        return question.value.includes(controlPayload.selectedOption) ? controlPayload.selectedOption!.label : controlPayload.unselectedOption?.label;
      }
      if (question.questionTemplate.controlType == ControlType.FILE) {
        return JSON.parse(question.value);
      }
      return question.value;
    }
    return [];
  }


  getFileQuestionValues(question: Question) {
    const fileValues: FormFileValue[] = JSON.parse(question.value);
    const fileConfig = question.questionTemplate.controlPayload.files!;

    if (fileValues) {
      return fileConfig.map(m => {
        const value = fileValues.filter(f => f.fileCode == m.valueCode);
        if (value.length > 0) return { fileLabel: m.label, fileValue: value[0] };
        return { fileLabel: m.label, fileValue: null };
      })
    }
    return [];
  }

  curateApplicant(approve: boolean) {
    this.applicantAssessment.outcome = approve ? ApplicationAssessmentStatus.APPROVED : ApplicationAssessmentStatus.REJECTED;
    this.applicantAssessment.finalised = true;
    this.applicationsService.updateCurateDetails(this.id, this.applicantAssessment).subscribe({
      next: () => {
        this.removePanelOpen = false;
        this.loadRetailer();
        this.getAssessment();
      },
      error: err => { console.log(err); },
    })
  }

  rejectApplicant(reject: boolean) {
    if (reject) this.curateApplicant(false);
    if (!reject) this.removePanelOpen = false;
  }

  saveApplication() {
    this.applicationsService.updateCurateDetails(this.id, this.applicantAssessment).subscribe({
      next: () => {
        this.removePanelOpen = false;
        this.loadRetailer();
        this.getAssessment();
      },
      error: err => { console.log(err); },
    })
  }

  getApplicantStatus() {
    if (this.originalAssessment) {
      switch (this.originalAssessment.outcome) {
        case ApplicationAssessmentStatus.PENDING:
          return {
            status: this.utils.displayStatus(ApplicationAssessmentStatus.PENDING),
            icon: 'check_circle',
            outline: false,
            className: 'disabled-chip'
          };
        case ApplicationAssessmentStatus.APPROVED:
          return {
            status: this.utils.displayStatus(ApplicationAssessmentStatus.APPROVED),
            icon: 'check_circle',
            outline: false,
            className: 'success-chip'
          };
        case ApplicationAssessmentStatus.REJECTED:
          return {
            status: this.utils.displayStatus(ApplicationAssessmentStatus.REJECTED),
            icon: 'cancel',
            outline: false,
            className: 'error-chip'
          };
        case  ApplicationAssessmentStatus.NEW:
          return {
            status: this.utils.displayStatus(ApplicationAssessmentStatus.PENDING),
            icon: 'check_circle',
            outline: false,
            className: 'disabled-chip'
          };
      }
    }
    return {
      status: this.utils.displayStatus("LOADING"),
      icon: 'check-circle',
      outline: false,
      className: 'disabled-chip'
    }
  }

  hasFormChanged(): void {
    this.formChanged = this.applicantAssessment.notes !== this.originalAssessment.notes;
  }

  cancel(): void {
    this.applicantAssessment.notes = this.originalAssessment.notes;
    this.hasFormChanged();
  }

  sortItemsByDisplayOrder(items: Category[] | QuestionGroup[] | Question[]): any {
    return items.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  setGroupUuids() {
    this.groupUuids = [];
    this.applicantAssessment.payload?.categories.forEach(cat => {
      const uuids = cat.questionGroups.map(m => m.uuid);
      this.groupUuids.push(...uuids);
    })
  }

  getIndex(groupUuid: string): number {
    return this.groupUuids.indexOf(groupUuid) + 1;
  }

  getQuestionTemplate(question: Question): QuestionTemplate {
    return question.questionTemplate;
  }

  setRatingBars() {
    if (this.applicantAssessment.scoringBreakdown) {
      const breakdown: ScoringBreakdown = this.applicantAssessment.scoringBreakdown;
      Object.keys(breakdown).forEach(key => {
        const bar = this.ratingBars.filter(f => f.key == key)[0];
        // @ts-ignore
        bar.value = breakdown[key];
      })
    }
  }

  protected readonly status = status;

  canBeScored(questionGroup: QuestionGroup): Boolean {
    if (questionGroup.questions.length > 1) {
      return false;
    }
    return !this.controlTypesFilterList.includes(questionGroup.questions[0].questionTemplate.controlType);
  }

  displayCantBeScoredMessage(questionGroup: QuestionGroup): string {
    if (questionGroup.questions.length > 1) {
      return `Question groups with multiple questions can't be scored`;
    }
    const questionType = templateComponents.filter(f => f.controlType == questionGroup.questions[0].questionTemplate.controlType)[0];
    return `${questionType.label} questions can't be scored`;
  }

  getQuestionGroupImportanceRating(questionGroup: any): string {
    switch (questionGroup.importanceRating) {
      case 0:
        return 'Not important';
      case 1:
        return 'Less important';
      case 2:
        return 'Moderately important';
      case 3:
        return 'Very important';
      case 4:
        return 'Extremely important';
      default:
        return 'Not important';
    }
  }

  isPassingScore(question: Question): boolean {
    const controlPayload = question.questionTemplate.controlPayload;
    if (!question.value) {
      return false;
    }
    switch (question.questionTemplate.controlType) {
      case ControlType.SELECT: {
        const passingCodes = controlPayload.options!
          .filter(f => f.weight! > 0)
          .map(m => m.valueCode);
        if (question.value) return passingCodes.some(element => question.value.includes(element));
        return false;
      }
      case ControlType.RANGE: {
        if (controlPayload.rangeType === RangeType.NUMBER) {
          let index: number = controlPayload.increments!
            .findIndex(increment => increment.valueCode == question.value);
          if (index >= 0) {
            const answerConfig = controlPayload.increments![index];
            if (controlPayload.scorePassType == ScorePassType.ABOVE) return answerConfig.end > controlPayload.passingValue!;
            if (controlPayload.scorePassType == ScorePassType.BELOW) return answerConfig.start < controlPayload.passingValue!;
          }
        }
        return false;
      }
      case ControlType.NUMBER: {
        if (question.value) {
          if (controlPayload.scorePassType == ScorePassType.ABOVE) return question.value >= controlPayload.passingValue!;
          if (controlPayload.scorePassType == ScorePassType.BELOW) return question.value <= controlPayload.passingValue!;
        }
        return false;
      }
      case ControlType.CHOICE: {
        const choseSelectedOption = controlPayload.selectedOption?.valueCode == question.value[0];
        const choseUnselectedOption = controlPayload.unselectedOption?.valueCode == question.value[0];
        if (choseSelectedOption) return controlPayload.selectedOption!.weight! > 0;
        if (choseUnselectedOption) return controlPayload.unselectedOption!.weight! > 0;
        return false;
      }
      default:
        return false;
    }
  }

  getQuestionRating(question: Question): number {
    if (!question.value) {
      return 0;
    }
    switch (question.questionTemplate.controlType) {
      case ControlType.SELECT: {
        const passableOptions = question.questionTemplate.controlPayload.options!
          .filter(option => !!option.weight && option.weight > 0)
          .sort((option1, option2) => option2.weight! - option1.weight!);
        const index = passableOptions.findIndex(f => f.valueCode == question.value[0]);
        if (index >= 0) {
          return index + 1;
        }
        return 0;
      }
      case ControlType.CHOICE: {
        const choseSelectedOption = question.questionTemplate.controlPayload.selectedOption?.valueCode == question.value[0];
        const choseUnselectedOption = question.questionTemplate.controlPayload.unselectedOption?.valueCode == question.value[0];
        if (choseSelectedOption && question.questionTemplate.controlPayload.selectedOption!.weight! > 0) return 1;
        if (choseUnselectedOption && question.questionTemplate.controlPayload.unselectedOption!.weight! > 0) return 1;
        return 2;
      }
      default:
        return 1;
    }
  }

  isControlSelectMultiple(qt: QuestionTemplate): boolean {
    return (qt.controlType === ControlType.SELECT && qt.controlPayload.selectType === SelectType.MULTI);
  }

  isControlSelectSingle(qt: QuestionTemplate): boolean {
    return (qt.controlType === ControlType.SELECT && qt.controlPayload.selectType === SelectType.SINGLE);
  }

  getQuestionDisplayLabel(qt: QuestionTemplate): string {
    if (qt.controlDescription) {
      return qt.controlDescription;
    }
    if (qt.controlPayload.label) {
      return qt.controlPayload.label;
    }
    return ''
  }

  downloadFormFile(fileValue: FormFileValue, questionFilter: FormQuestionFilter) {
    this.applicationsService.downloadFormFile(this.id, fileValue.fileUuid, questionFilter).subscribe({
      next: (value) => {
        const type = ('application/' + fileValue.fileType).toString();
        const file = new Blob([value], { type });
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement('a');

        anchor.download = fileValue.fileName;
        anchor.href = fileURL;
        anchor.click();
      },
      error: err => console.log(err)
    })
  }

  generateQuestionFilter(catId: string, qgId: string, qId: string, fileCode: string | null) {
    const filter: FormQuestionFilter = {
      assessmentId: this.applicantAssessment.id,
      formCode: this.applicantAssessment.formTemplateCode,
      categoryUuid: catId,
      questionGroupUuid: qgId,
      questionUuid: qId,
      fileCode: fileCode
    }
    return filter;
  }

  protected readonly ControlType = ControlType;
  protected readonly PanelType = PanelType;
}
