<app-pre-auth-toolbar></app-pre-auth-toolbar>

<div class="p-3 full-width password-reset-gap-container full-screen-height" fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="15px">

  <div class="full-width flex-row reset-password-header-div" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <h1 class="title-large">Enter your OTP</h1>
      <h2 class="subtitle-regular">We have sent you an One Time Pin</h2>
    </div>
  </div>

  <div fxLayout="row" fxFlexOffset="25px" class="mb-5">
    <div class="p-3 full-width flex-column" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">

      <div class="otp-text" fxFlexOffset="10px">
        <ng-otp-input #ngOtpInput
                      (onInputChange)="onOtpChange($event)"
                      [config]="otpInputConfig">
        </ng-otp-input>
      </div>

      <div fxFlexOffset="50px">
        <button (click)="onVerify()" [disabled]="loading || len < 4 || (loader.loading$ | async)" class="button-primary" mat-flat-button style="width: 200px">
          <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            {{ loading ? 'Working...' : 'Update new password'}}
            <mat-icon *ngIf="loading">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </div>
        </button>
      </div>

      <div fxFlexOffset="10px">
        <div class="heading-regular">
          Didn’t receive an OTP?
          <span (click)="!loading ? onResendOTP() : null" class="heading-primary-1 btn-cursor" style="font-size: 15px">Resend OTP.</span>
        </div>
        <mat-error class="link-error" *ngIf="errMessage">{{errMessage}}</mat-error>
      </div>
    </div>
  </div>

  <div>
    <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
  </div>
</div>
