import {Component, OnInit, ViewChild} from '@angular/core';
import {CurrentContextService} from '../../core/services/security/current-context.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ErrorPopupComponent} from '../../shared/components/dialogs/error-popup/error-popup.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IconPopupComponent} from '../../shared/components/dialogs/icon-popup/icon-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {UpdatePasswordService} from '../password-reset/service/update-password.service';
import {VerifyOTPRequest} from '../../core/model/user.model';
import {lastValueFrom} from 'rxjs';
import {AuthenticationService} from '../../core/services/security/authentication.service';
import {LoadingService} from '../../core/services/loading.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  public otpForm;
  public errMessage: string | null = null;
  public loading: boolean = false;
  public len = 0;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;


  constructor(private context: CurrentContextService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private dialogService: MatDialog,
              private passwordService: UpdatePasswordService,
              private authService: AuthenticationService,
              private router: Router,
              public loader: LoadingService
  ) {
    this.otpForm = this.resetForm();

  }


  get userEmail(): string {
    if (this.context.currentUser) {
      return this.context.currentUser.email;
    }
    if (this.route.snapshot.paramMap.get('userEmail')) {
      return this.route.snapshot.paramMap.get('userEmail')!;
    }
    return '';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.hasOwnProperty('sendOtp')) {
        this.onResendOTP(false);
      }
    });
  }

  onResendOTP(showPopUp: boolean = true): void {
    this.loading = true;
    this.errMessage = null;
    this.passwordService.requestOtp(this.context.currentUser!.id!).subscribe(
      () => {
        this.loading = false;
        // show success message
        if (showPopUp) {
          this.dialogService.open(IconPopupComponent, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Resend OTP',
              description: 'We have sent a One Time Pin to',
              boldDescription: this.context.currentUser!.email,
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
        }
      },
      (err) => {
        this.loading = false;
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
              this.otpForm.get('pin')!.setValue(null);
              this.ngOtpInputRef.setValue(null);
            }
            break;
          default:
            this.dialogService.open(ErrorPopupComponent, {
              height: '300px',
              width: '450px',
              data: {
                header: 'Oops!',
                description: 'An unexpected error occurred',
              },
            });
        }
      }
    );
  }

  onVerify() {
    const id = this.context.currentUser!.id!;
    const pin = this.otpForm.value.pin;
    const request: VerifyOTPRequest = {id: id, pin: pin};

    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.passwordService.verifyNewUserAccount(request))
      .then(
        (res) => {
          this.loading = false;
          this.authService.setToken(res);

          // set user as verified in the context
          this.authService.verifyUser();
          let returnUrl = this.route.snapshot.paramMap.get('returnUrl');
          if (returnUrl == null) {
            returnUrl = `${this.context.getCurrentLandlordCode()!}/home/dashboard`;
          }

          this.router.navigate([returnUrl])
            .catch((error: string) => {
              console.log(error)
            });
        },
        (err) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('pin')!.setValue(null);
                this.ngOtpInputRef.setValue(null);
              }
              break;
            default:
              console.log(err);
              this.errMessage = 'An unexpected error occurred, please try again';
          }
        }
      );
  }

  onOtpChange(event: any) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }
}
