import {SelectionModel} from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {lastValueFrom, Observable} from 'rxjs';
import {PageOffering} from "../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PagedContent} from '../../../../shared/services/generic.service';
import {PaginatorService} from '../../../../shared/services/paginator.service';
import {UtilsService} from "../../../../shared/services/utils.service";
import {
  AssignedSuppliersDialogComponent
} from "../../../settings/payments/assigned-suppliers-dialog/assigned-suppliers-dialog.component";
import {PaymentConfigsService} from "../../../settings/service/payment-configs.service";
import {RequestPosDialogComponent} from "../../../support/request-pos-dialog/request-pos-dialog.component";
import {NotifyPosRequest} from "../../../support/request-pos-dialog/request-pos.model";
import {RequestPosService} from "../../../support/request-pos-dialog/request-pos.service";
import {
  AgreementType,
  CardReader,
  LeaseStatus,
  LeaseSummary,
  mapRequestsToData,
  mapRequestToData,
  OccupancyType,
  PaymentConfigData,
  PaymentConfigRequest,
  SupplierConfigSummary,
  SupplierSourceConfig,
  TransactionSource,
  TransactionSourceConfig
} from "../../leasing.model";
import {LeaseViewService} from "../lease-view.service";
import {
  PosSoftwareSettingsDialogComponent
} from './pos-software-settings-dialog/pos-software-settings-dialog.component';

@Component({
  selector: 'app-lease-pos-settings',
  templateUrl: './lease-pos-settings.component.html',
  styleUrls: ['./lease-pos-settings.component.scss']
})
export class LeasePosSettingsComponent implements OnInit, AfterViewInit {
  @Input() id!: string;
  @Input() offering!: PageOffering;
  @Input() reload!: Observable<void>;
  @ViewChild('posSettingsPaginator') set posSettingsPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.leaseTransactionSourceConfigDataSource) this.leaseTransactionSourceConfigDataSource.paginator = paginator;
  }
  @ViewChild('posSupplierPaginator') set posSupplierPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.leaseSupplierSourceConfigDataSource) this.leaseSupplierSourceConfigDataSource.paginator = paginator;
  }

  @Output() reloadLease = new EventEmitter<void>();

  leaseSummary!: LeaseSummary;
  parentPaymentConfig?: PaymentConfigRequest;
  offeringPaymentConfigs: PaymentConfigRequest[] = []

  paymentConfig!: PaymentConfigData;
  selectedPaymentConfig!: PaymentConfigData;
  vendConfigOriginal: TransactionSourceConfig | null = null;
  vendConfig: TransactionSourceConfig | null = null;

  cardReadersOriginal: CardReader[] = [];
  cardReaders: CardReader[] = [];
  availableCardReaders: { code: string, readers: CardReader[] }[] = [];
  newAvailableCardReaders: CardReader[] = [];
  newCardReaders: CardReader[] = [];

  leaseTransactionSourceConfigDataSource: MatTableDataSource<TransactionSourceConfig> = new MatTableDataSource<TransactionSourceConfig>([]);
  leaseSupplierSourceConfigDataSource: MatTableDataSource<SupplierConfigSummary> = new MatTableDataSource<SupplierConfigSummary>([]);

  paymentDeviceDataSource: MatTableDataSource<CardReader> = new MatTableDataSource<CardReader>([]);

  posSettingsDisplayedColumns = ['select', 'uuid', 'sourceType', 'provider', 'domainPrefix', 'startDate', 'endDate', 'activationStatus', 'action'];
  posSuppliersDisplayedColumns = ['select', 'displayName', 'uuid', 'supplierName', 'startDate', 'endDate'];

  posTransactionSourceSelection = new SelectionModel<TransactionSourceConfig>(false, []);
  posSupplierSourceSelection = new SelectionModel<SupplierConfigSummary>(false, []);
  paymentDeviceSelection = new SelectionModel<CardReader>(false, []);

  selectedPosSettingDomainPrefix: string | null = null;
  selectedPosSupplierUuid: string | null = null;
  selectedCardReaderUuid: string | null = null;

  paymentConfigs: PaymentConfigData[] = [];

  isInternalUser = false;

  posDialogRef!: MatDialogRef<PosSoftwareSettingsDialogComponent>;


  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private leaseViewService: LeaseViewService,
              public currentContext: CurrentContextService,
              private offeringPaymentConfigService: PaymentConfigsService,
              private matDialog: MatDialog,
              private requestPosService: RequestPosService,
              public loader: LoadingService,
              public paginatorService: PaginatorService,
              private changeDetectorRefs: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadLease();
    this.isInternalUser = this.currentContext.checkUserFeatures(['leasing_internal_admin'], this.offering.offeringUuid);
    this.loadAssignedLeaseCardReaders();
    this.reload.subscribe(() => this.loadLease());
  }

  initHistory() {
    lastValueFrom(this.leaseViewService.getLeasePaymentConfigHistory(this.id, {
      size: '5',
      page: '0',
      sort: 'startDate,desc'
    }))
      .then((res: PagedContent<PaymentConfigRequest[]>) => {
        this.paymentConfigs = mapRequestsToData(res.content);
        this.loadLeasePaymentConfigHistoryToTable(res);
      });
  }

  private loadLeasePaymentConfigHistoryToTable(res: PagedContent<PaymentConfigRequest[]>): void {
    this.leaseTransactionSourceConfigDataSource.data = [];
    let paymentData = mapRequestsToData(res.content);
    const vendData = paymentData.filter(f => f.transactionSource == TransactionSource.VEND || f.transactionSource == TransactionSource.POSTER);
    const leaseSupplierData = paymentData.filter(f => f.transactionSource == TransactionSource.VEND_SUPPLIER_LEASE);
    const offeringSupplierData = paymentData.filter(f => f.transactionSource == TransactionSource.VEND_SUPPLIER_OFFERING);

    if (vendData.length > 0) {
      let vendConfigs = vendData.map(item => {
        if (item.transactionSourceConfig) {
          item.transactionSourceConfig!.paymentConfigUuid = item.uuid!;
          (item.transactionSourceConfig! as TransactionSourceConfig).activationStatus = item.activationStatus!;
          item.transactionSourceConfig!.startDate = item.startDate;
          item.transactionSourceConfig!.endDate = item.endDate
        }

        return item.transactionSourceConfig as TransactionSourceConfig;
      }).filter(this.notEmpty);

      vendConfigs = vendConfigs.filter(f => f.domainPrefix != null && f.accessToken != null);
      this.leaseTransactionSourceConfigDataSource = new MatTableDataSource<TransactionSourceConfig>(
        vendConfigs
      );
    }


    let supplierConfigs: SupplierConfigSummary[] = [];

    if (leaseSupplierData.length > 0) {
      const parentConfig = this.parentPaymentConfig ? mapRequestToData(this.parentPaymentConfig!) : null;
      leaseSupplierData.forEach(data => {
        if (parentConfig && data.transactionSourceConfig) {
          const configs = (data.transactionSourceConfig as SupplierSourceConfig).suppliers!.map(m => {
            const config: SupplierConfigSummary = {
              uuid: data.uuid,
              domainPrefix: (parentConfig!.transactionSourceConfig as TransactionSourceConfig).domainPrefix,
              supplierName: m.name,
              startDate: data.startDate,
              endDate: data.endDate
            }
            return config;
          })
          supplierConfigs.push(...configs);
        }
      })
    }

    if (offeringSupplierData.length > 0) {
      let parentConfig: PaymentConfigData | null = null;
      offeringSupplierData.forEach(data => {
        const offeringParentConfig = this.offeringPaymentConfigs
          .filter(f => f.uuid == data.transactionSourceConfig?.paymentConfigUuid)[0];
        parentConfig = offeringParentConfig ? mapRequestToData(offeringParentConfig) : null;
        if (parentConfig && data.transactionSourceConfig) {
          const configs = (data.transactionSourceConfig as SupplierSourceConfig).suppliers!.map(m => {
            const config: SupplierConfigSummary = {
              uuid: data.uuid,
              domainPrefix: (parentConfig!.transactionSourceConfig as TransactionSourceConfig).domainPrefix,
              supplierName: m.name,
              startDate: data.startDate,
              endDate: data.endDate
            }
            return config;
          })
          supplierConfigs.push(...configs);
        }
      })
    }

    if (supplierConfigs.length > 0) {
      supplierConfigs = supplierConfigs.filter(f => f.supplierName != null);
      this.leaseSupplierSourceConfigDataSource = new MatTableDataSource<SupplierConfigSummary>(
        supplierConfigs
      );
    }

    this.changeDetectorRefs.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  loadLease() {
    this.leaseViewService.getOne(``, this.id).subscribe({
      next: value => {
        this.leaseSummary = value;
        this.loadAssignedLeaseCardReaders();
        this.loadLeasePaymentConfig();
      },
      error: err => console.log(err)
    })
  }

  loadLeasePaymentConfig() {
    this.leaseViewService.getLeaseCurrentPaymentConfig(this.id).subscribe({
      next: value => {
        this.paymentConfig = value ? { ...mapRequestToData(value) } : new PaymentConfigData();
        if (value && value.transactionSourceConfig) {
          this.vendConfigOriginal = { ...mapRequestToData(value).transactionSourceConfig! as TransactionSourceConfig };
          this.vendConfig = { ...mapRequestToData(value).transactionSourceConfig! as TransactionSourceConfig };
        } else {
          this.vendConfigOriginal = new TransactionSourceConfig();
          this.vendConfig = new TransactionSourceConfig();
        }
        this.loadParentConfigs();
      },
      error: err => console.log(err)
    })
  }

  loadParentConfigs() {
    if (this.isSubLease) {
      this.loadLeaseParentPaymentConfig();
      return;
    }

    if (this.leaseSummary.occupancyType == OccupancyType.SINGLE) {
      this.loadOfferingParentPaymentConfigs();
      return;
    }

    this.initHistory();
  }

  loadLeaseParentPaymentConfig() {
    this.leaseViewService.getLeaseCurrentPaymentConfig(this.leaseSummary.parentUuid!).subscribe({
      next: value => {
        this.parentPaymentConfig = value;
        this.initHistory();
      },
      error: err => {
        console.log('Parent lease does not have active payment config');
        console.log(err);
      }
    })
  }

  loadOfferingParentPaymentConfigs() {
    this.offeringPaymentConfigService.getDistrictPosConfigs().subscribe({
      next: value => {
        this.offeringPaymentConfigs = value;
        this.initHistory();
      },
      error: err => {
        console.log(err);
      }
    })
  }

  private loadLeasePaymentConfigHistory(): void {
    const paginator = this.posSettingsPaginator ? this.posSettingsPaginator : this.posSupplierPaginator;
    const page = {
      size: paginator ? paginator.pageSize.toString() : '5',
      page: paginator ? paginator.pageIndex.toString() : '0',
      sort: 'startDate,desc'
    }
    lastValueFrom(this.leaseViewService.getLeasePaymentConfigHistory(this.id, page))
      .then((res: PagedContent<PaymentConfigRequest[]>) => {
        this.paymentConfigs = mapRequestsToData(res.content);
        this.loadLeasePaymentConfigHistoryToTable(res);
      })
  }

  loadAssignedLeaseCardReaders() {
    this.leaseViewService.getAssignedLeaseCardReaders(this.id).subscribe({
      next: value => {
        this.cardReadersOriginal = value.map(m => {
          return {...m};
        });
        this.cardReadersOriginal.forEach(ea => {
          ea.startDate = this.dateUtils.displayShortDate(ea.startDate);
          ea.endDate = this.dateUtils.displayShortDate(ea.endDate);
        })

        this.cardReaders = [...value];
        this.paymentDeviceDataSource = new MatTableDataSource<CardReader>(this.cardReaders);
        this.changeDetectorRefs.detectChanges();
        this.cardReaders.forEach(ea => {
          ea.startDate = this.dateUtils.displayShortDate(ea.startDate);
          ea.endDate = this.dateUtils.displayShortDate(ea.endDate);
        })
        this.cardReaders.forEach(ea => this.loadAvailableLeaseCardReaders(ea, false));

        this.setNewCardReaders();
      },
      error: err => console.log(err)
    })
  }

  get hasNoPricingPeriod(): boolean {
    if (this.leaseSummary) {
      return (this.leaseSummary.rentalType === null || this.leaseSummary.monthlyPricePerSqm === null)
    }
    return true;
  }

  get leaseIsNotApproved(): boolean {
    const unapprovedStatuses = [LeaseStatus.PLANNED, LeaseStatus.INVALID];
    if (this.leaseSummary) {
      return (unapprovedStatuses.includes(this.leaseSummary.leaseStatus))
    }
    return true;
  }

  loadAvailableLeaseCardReaders(reader: CardReader, newReader: boolean) {
    reader.startDate = this.dateUtils.displayShortDate(reader.startDate);
    reader.endDate = this.dateUtils.displayShortDate(reader.endDate);
    this.leaseViewService.getAvailableLeaseCardReaders(this.id, this.dateUtils.displayShortDate(reader.startDate)!, this.dateUtils.displayShortDate(reader.endDate)!).subscribe({
      next: value => {
        if (!newReader) {
          const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
          if (objIndex < 0) {
            this.availableCardReaders.push({code: reader.cardReaderCode ? reader.cardReaderCode : 'new', readers: value});
          } else {
            this.availableCardReaders[objIndex].readers = value;
          }
        } else {
          this.newAvailableCardReaders = value;
        }
      },
      error: err => console.log(err)
    })
  }

  assignLeaseCardReaders(reader: CardReader) {
    reader.startDate = this.dateUtils.displayShortDate(reader.startDate);
    reader.endDate = this.dateUtils.displayShortDate(reader.endDate);
    reader.retailerId = this.leaseSummary.retailerId;
    reader.districtUuid = this.leaseSummary.districtUuid!;
    this.leaseViewService.assignLeaseCardReader(this.id, reader).subscribe({
      next: value => {
        this.loadLease();
        this.reloadLease.emit();
      },
      error: err => console.log(err)
    })

  }

  saveCardReadersChanges() {
    const cardReadersChanged = JSON.stringify(this.cardReadersOriginal) != JSON.stringify(this.cardReaders);

    if (cardReadersChanged) {
      this.cardReaders.forEach(reader => {
        this.assignLeaseCardReaders(reader);
      })
    }

    this.newCardReaders.forEach(ea =>  {
      if (ea.cardReaderCode != null) this.assignLeaseCardReaders(ea);
    })
  }

  cancelCardReaderChanges() {
    this.cardReaders = [...this.cardReadersOriginal];
  }

  get isLeaseActiveOrApproved(): boolean {
    if (this.leaseSummary) {
      return (this.leaseSummary.leaseStatus.toLowerCase() === LeaseStatus.ACTIVE.toLowerCase() ||
        this.leaseSummary.leaseStatus.toLowerCase() === LeaseStatus.APPROVED.toLowerCase());
    }
    return false;
  }

  setNewCardReaders() {
    this.newCardReaders = [];
    const extraCardReaders = 3 - this.cardReaders.length;
    if (this.leaseSummary) {
      for (let i = 0; i < extraCardReaders; i++) {
        this.newCardReaders.push(new CardReader({
          leaseUuid: this.leaseSummary.uuid,
          retailerId: this.leaseSummary.retailerId,
          districtUuid: this.leaseSummary.districtUuid,
          startDate: this.leaseSummary.leaseStartDate,
          endDate: this.leaseSummary.leaseEndDate
        }))
      }
    }

    this.newCardReaders.forEach(ea => this.loadAvailableLeaseCardReaders(ea, true));
  }

  updatePaymentConfigStatus(paymentConfigUuid: string, status: string) {
    this.leaseViewService.updatePaymentConfigStatus(paymentConfigUuid, this.id, status).subscribe({
      next: () => {
        this.loadLeasePaymentConfig();
        this.reloadLease.emit();
      },
      error: err => console.log(err)
    })
  }

  getAvailableCardReaders(reader: CardReader): CardReader[] {
    if (this.availableCardReaders.length > 0) {
      const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
      return this.availableCardReaders[objIndex] ? this.availableCardReaders[objIndex].readers : [];
    }
    return [];
  }

  get editingCardReaders(): boolean {
    const newCardReaders = this.newCardReaders.filter(f => f.cardReaderCode != null);
    return JSON.stringify(this.cardReadersOriginal) != JSON.stringify(this.cardReaders) || newCardReaders.length > 0;
  }

  get canRequestPos(): boolean {
    if (!this.posTransactionSourceSelection.isEmpty()) {
      return this.getLeasePaymentConfigByDomainPrefix(this.selectedPosSettingDomainPrefix!)!.activationStatus == 'NEW';
    }
    return false;
  }

  get canCancelPos(): boolean {
    if (!this.posTransactionSourceSelection.isEmpty()) {
      const activationStatus = this.selectedPaymentConfig!.activationStatus;
      return activationStatus == 'ACTIVATED';
    }
    return false;
  }

  getActivationRequestMessage(): string {
    if (!this.posTransactionSourceSelection.isEmpty()) {
      return `The point-of-sale account ${this.selectedPaymentConfig!.activationStatus == 'ACTIVATION_REQUESTED' ? '' : 'cancellation'} has been requested and pending ${this.paymentConfig.activationStatus == 'ACTIVATION_REQUESTED' ? 'activation' : 'deactivation'}`;
    }
    return '';
  }

  getActivationConfirmedMessage(): string {
    if (!this.posTransactionSourceSelection.isEmpty()) {
      return `The point-of-sale account has been ${this.selectedPaymentConfig!.activationStatus == 'ACTIVATED' ? 'activated' : 'deactivated'}. The retailer has been notified via email.`;
    }
    return '';
  }

  setSelectedPosSettings(row: TransactionSourceConfig): void {
    this.selectedPosSettingDomainPrefix = row.domainPrefix;
    this.selectedPaymentConfig = this.getLeasePaymentConfigByDomainPrefix(row.domainPrefix!)!;
    this.posTransactionSourceSelection.toggle(row);

    if (this.posTransactionSourceSelection.selected.length == 0) this.selectedPosSettingDomainPrefix = null;

    this.selectedPosSupplierUuid = null;
    this.posSupplierSourceSelection.clear();
  }

  setSelectedPosSupplier(row: SupplierConfigSummary): void {
    this.selectedPosSupplierUuid = row.uuid;
    this.selectedPaymentConfig = this.paymentConfigs.filter(f => f.uuid == row.uuid)[0];
    this.posSupplierSourceSelection.toggle(row);

    if (this.posSupplierSourceSelection.selected.length == 0) this.selectedPosSupplierUuid = null;

    this.selectedPosSettingDomainPrefix = null;
    this.posTransactionSourceSelection.clear();
  }

  requestPOS(cancel: boolean) {
    this.matDialog.open(RequestPosDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        cancel: cancel,
        header: cancel ? 'Cancel a POS account' : 'Request a POS account',
        property: this.currentContext.getCurrentLocation(),
        offering: this.offering,
        retailerId: this.leaseSummary.retailerId,
        companyName: this.leaseSummary.retailerCompanyName,
        leaseId: this.leaseSummary.uuid
      }
    });
  }

  activatePOSAccount(active: boolean) {
    let paymentConfig = {...this.selectedPaymentConfig};
    if ((paymentConfig.transactionSource == TransactionSource.VEND || paymentConfig.transactionSource == TransactionSource.POSTER) && paymentConfig.transactionSourceConfig) {
      if (active) {
        const request = new NotifyPosRequest(
          this.id,
            (paymentConfig.transactionSourceConfig as TransactionSourceConfig).loginUrl!,
            (paymentConfig.transactionSourceConfig as TransactionSourceConfig).loginUsername!,
          (paymentConfig.transactionSourceConfig as TransactionSourceConfig).loginPassword!,
        )
        this.requestPosService.notifyPosActivation(this.leaseSummary.retailerId, request).subscribe({
          next: () => this.updatePaymentConfigStatus(paymentConfig.uuid!, 'ACTIVATED'),
          error: err => console.log(err)
        })
      }

      if (!active && (paymentConfig.transactionSource == TransactionSource.VEND || paymentConfig.transactionSource == TransactionSource.POSTER) && paymentConfig.transactionSourceConfig) {
        const request = new NotifyPosRequest(
          this.id,
          (this.paymentConfig.transactionSourceConfig as TransactionSourceConfig).loginUrl!
        )
        this.requestPosService.notifyPosDeactivation(this.leaseSummary.retailerId, request).subscribe({
          next: () => this.updatePaymentConfigStatus(paymentConfig.uuid!, 'DEACTIVATED'),
          error: err => console.log(err)
        })
      }
    }
  }

  getLeasePaymentConfigByDomainPrefix(domainPrefix: string): PaymentConfigData | null {
    let index: number = this.paymentConfigs.findIndex((val) =>
      (val.transactionSource == TransactionSource.VEND || val.transactionSource == TransactionSource.POSTER) && val.transactionSourceConfig
      && (val.transactionSourceConfig as TransactionSourceConfig)?.domainPrefix == domainPrefix);
    if (index >= 0) {
      return this.paymentConfigs.at(index)!;
    } else {
      return null;
    }
  }

  addNewPOSSettings(): void {
    const defaultType = this.isInternalUser ? this.allowedPosTypes[0] : this.allowedPosTypes[this.allowedPosTypes.length - 1];
    this.posDialogRef = this.matDialog.open(PosSoftwareSettingsDialogComponent, {
      panelClass: 'dialog-large',
      maxHeight: 'auto',
      data: {
        vendConfig: null,
        supplierConfig: null,
        offering: this.offering,
        posType: defaultType,
        allowedPosTypes: this.allowedPosTypes,
        paymentConfigUuid: this.parentPaymentConfig ? this.parentPaymentConfig.uuid : null,
        parentComponent: this,
        offeringConfig: false
      }
    })

    this.posDialogRef.afterClosed().subscribe((response: { save: boolean, vendConfig: TransactionSourceConfig, supplierConfig: SupplierSourceConfig }) => {
      if (response.save) {
        this.paymentConfig.leaseUuid = this.id;
        this.paymentConfig.active = true;

        if (this.isSubLease) {
          this.paymentConfig.transactionSource = TransactionSource.VEND_SUPPLIER_LEASE;
          this.paymentConfig.transactionSourceConfig = response.supplierConfig;
        }

        if (this.isHeadLease) {
          this.paymentConfig.transactionSource = response.vendConfig!.paymentSourceName;
          this.paymentConfig.transactionSourceConfig = response.vendConfig;
        }

        if (this.leaseSummary.occupancyType == OccupancyType.SINGLE) {
          if (response.vendConfig && response.vendConfig.domainPrefix) {
            this.paymentConfig.transactionSource = response.vendConfig!.paymentSourceName;
            this.paymentConfig.transactionSourceConfig = response.vendConfig;
          }

          if (response.supplierConfig && response.supplierConfig.paymentConfigUuid) {
            this.paymentConfig.transactionSource = TransactionSource.VEND_SUPPLIER_OFFERING;
            this.paymentConfig.transactionSourceConfig = response.supplierConfig;
          }
        }

        this.leaseViewService.savePaymentConfig(this.generateConfigRequest(this.paymentConfig), this.leaseSummary.uuid)
          .subscribe({
            next: () => {
              this.reloadLease.emit();
              this.loadLeasePaymentConfigHistory();
            },
            error: err => console.log(err)
          })
      }
    });
  }

  loadPosSuppliers(parentConfigUuid: string) {
    this.leaseViewService.getLeaseSuppliers(this.leaseSummary.parentUuid!, parentConfigUuid).subscribe({
      next: value => {
        this.posDialogRef.componentInstance.availableSuppliers = value.unassignedSuppliers;
        this.posDialogRef.componentInstance.availableSuppliers.push(...this.posDialogRef.componentInstance.assignedSuppliers);
      },
      error: err => console.log(err)
    })
  }

  loadOfferingSuppliers(parentConfigUuid: string) {
    this.offeringPaymentConfigService.checkSuppliers(parentConfigUuid).subscribe({
      next: value => {
        this.posDialogRef.componentInstance.availableSuppliers = value.unassignedSuppliers;
        this.posDialogRef.componentInstance.availableSuppliers.push(...this.posDialogRef.componentInstance.assignedSuppliers);
      },
      error: err => console.log(err)
    })
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  cardReaderFutureDated(reader: CardReader) {
    return new Date(reader.startDate!) > new Date();
  }

  editPosSettings(): void {
    let posType: string;
    if (this.selectedPosSettingDomainPrefix) {
      posType = this.selectedPaymentConfig.transactionSource!;
    } else {
      posType = this.paymentConfigs.filter(f => f.uuid == this.selectedPosSupplierUuid)[0].transactionSource!;
    }

    this.posDialogRef = this.matDialog.open(PosSoftwareSettingsDialogComponent, {
      panelClass: 'dialog-large',
      maxHeight: 'auto',
      data: {
        vendConfig: this.posTransactionSourceSelection.selected[0],
        supplierConfig: this.getSupplierConfigFromSelection() ? this.getSupplierConfigFromSelection()?.transactionSourceConfig : null,
        offering: this.offering,
        posType: posType,
        allowedPosTypes: [posType],
        paymentConfigUuid: this.parentPaymentConfig ? this.parentPaymentConfig.uuid : null,
        parentComponent: this,
        offeringConfig: false
      }
    })

    this.posDialogRef.afterClosed().subscribe((response: { save: boolean, vendConfig: TransactionSourceConfig, supplierConfig: SupplierSourceConfig }) => {
      if (response.save) {
        let configToSave: PaymentConfigData | null = null;
        if (response.vendConfig.paymentSourceName == TransactionSource.VEND || response.vendConfig.paymentSourceName == TransactionSource.POSTER) {
          configToSave = this.getLeasePaymentConfigByDomainPrefix(this.selectedPosSettingDomainPrefix!);
          configToSave!.transactionSourceConfig = response.vendConfig;
        }
        if (response.vendConfig.paymentSourceName != TransactionSource.VEND && response.vendConfig.paymentSourceName != TransactionSource.POSTER) {
          configToSave = this.getSupplierConfigFromSelection();
          configToSave!.transactionSourceConfig = response.supplierConfig;
        }


        this.leaseViewService.savePaymentConfig(this.generateConfigRequest(configToSave!), this.leaseSummary.uuid)
          .subscribe({
            next: () => {
              this.reloadLease.emit();
              this.loadLeasePaymentConfigHistory();
            },
            error: err => console.log(err)
          })
      }
    });
  }

  getSupplierConfigFromSelection(): PaymentConfigData | null {
    if (this.selectedPosSupplierUuid) {
      return this.paymentConfigs.filter(f => f.uuid == this.selectedPosSupplierUuid)[0];
    }
    return null;
  }

  checkSuppliers(row: TransactionSourceConfig) {
    this.matDialog.open(AssignedSuppliersDialogComponent, {
      panelClass: 'dialog-large',
      height: 'auto',
      data: {
        configUuid: row.paymentConfigUuid,
        offering: this.offering,
        configType: 'LEASE',
        leaseUuid: this.leaseSummary.uuid
      }
    })
  }

  generateConfigRequest(paymentConfig: PaymentConfigData): PaymentConfigRequest {
    const requestConfig = { ...paymentConfig.transactionSourceConfig };
    console.log(requestConfig);
    if ('startDate' in requestConfig) delete (requestConfig as any)['startDate'];
    if ('endDate' in requestConfig) delete (requestConfig as any)['endDate'];
    if ('activationStatus' in requestConfig) delete (requestConfig as any)['activationStatus'];
    if ('paymentConfigUuid' in requestConfig && (paymentConfig.transactionSource == TransactionSource.VEND || paymentConfig.transactionSource == TransactionSource.POSTER))
      delete (requestConfig as any)['paymentConfigUuid'];
    if ('displayName' in requestConfig && (paymentConfig.transactionSource == TransactionSource.VEND || paymentConfig.transactionSource == TransactionSource.POSTER))
      delete (requestConfig as any)['displayName'];

    return {
      uuid: paymentConfig.uuid,
      leaseUuid: paymentConfig.leaseUuid,
      activationStatus: paymentConfig.activationStatus,
      billingUpdateRequested: paymentConfig.billingUpdateRequested,
      transactionSource: paymentConfig.transactionSource,
      transactionSourceConfig: JSON.stringify(requestConfig),
      active: paymentConfig.active,
      startDate: paymentConfig.startDate,
      endDate: paymentConfig.endDate
    }
  }

  removeSelectedPosSetting(): void {
    if (!this.posTransactionSourceSelection.isEmpty()) {
      let paymentConfigUuid = this.getLeasePaymentConfigByDomainPrefix(this.selectedPosSettingDomainPrefix!)!.uuid!;
      lastValueFrom(this.leaseViewService.removePaymentConfigFromLease(paymentConfigUuid, this.id))
        .then(() => {
          this.reloadLease.emit();
          this.loadLeasePaymentConfigHistory();
        })
        .catch((error: string) => {
          console.log(error);
        })
    }
  }

  cardReadersOverlap(): boolean {
    let overlap = false;
    const allReaders = this.cardReaders.map(m => m);
    const newCardReaders = this.newCardReaders.filter(f => f.cardReaderCode != null);
    newCardReaders.length > 0 ? allReaders.push(...newCardReaders) : null;
    allReaders.forEach((reader1, i) => {
      allReaders.forEach((reader2, j) => {
        if (i != j) {
          overlap = overlap || (new Date(reader1.startDate!) <= new Date(reader2.endDate!) && new Date(reader1.endDate!) >= new Date(reader2.startDate!))
        }
      })
    })
    return overlap;
  }

  get displayTransactionSourceTable() {
    return this.leaseTransactionSourceConfigDataSource
      && this.leaseTransactionSourceConfigDataSource.data
      && this.leaseTransactionSourceConfigDataSource.data.length > 0
  }

  get displaySupplierSourceTable() {
    return this.leaseSupplierSourceConfigDataSource
      && this.leaseSupplierSourceConfigDataSource.data
      && this.leaseSupplierSourceConfigDataSource.data.length > 0
  }

  getLeasePricingPeriodRequiredMessage(): string {
    return 'Cannot add a lease payment config without Rates and Charges being completed';
  }

  getLeaseMustBeApprovedRequiredMessage(): string {
    return 'Lease must be approved before adding a lease payment config';
  }

  getCardReaderLeaseNotActiveMessage(): string {
    return 'Cannot add/edit card readers on a planned/expired/terminated lease';
  }

  getMaskedAccessToken(accessToken: string): string {
    if (this.isInternalUser) {
      return accessToken;
    }
    return '**********'
  }

  get hasOwnPosSettings() {
    return this.paymentConfigs.filter(f => f.transactionSource == "VEND").length > 0;
  }

  get isSubLease() {
    return this.leaseSummary.agreementType == AgreementType.SUB_LEASE;
  }

  get isHeadLease() {
    return this.leaseSummary.agreementType == AgreementType.LEASE && this.leaseSummary.occupancyType == OccupancyType.SHARED;
  }

  get allowedPosTypes() {
    if (this.isSubLease) return [TransactionSource.VEND_SUPPLIER_LEASE];
    if (this.isHeadLease) return [TransactionSource.VEND];
    return [TransactionSource.VEND, TransactionSource.VEND_SUPPLIER_OFFERING,TransactionSource.POSTER];
  }

  getProviderName(provider: string) {
    if (provider == 'VEND') return 'Lightspeed Retail X (Vend)';
    return provider;
  }

  protected readonly OccupancyType = OccupancyType;
  protected readonly PanelType = PanelType;
}
