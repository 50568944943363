import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";

@Component({
  selector: 'app-dashboard-footfall-component',
  templateUrl: './dashboard-footfall-component.component.html',
  styleUrls: ['./dashboard-footfall-component.component.scss']
})
export class DashboardFootfallComponentComponent extends BasePageComponent {
  constructor(injector: Injector) {
    super(injector);
  }

}
