export class PosRequest {
  leaseUuid!: string;
  locationName!: string;
  offeringName!: string;
  activationDate!: string;
  configType!: ConfigType;
  paymentDeviceRequired?: boolean;

  constructor(leaseUuid: string, locationName: string, offeringName: string, activationDate: string, configType: ConfigType, paymentDeviceRequired?: boolean) {
    this.leaseUuid = leaseUuid;
    this.locationName = locationName;
    this.offeringName = offeringName;
    this.activationDate = activationDate;
    this.configType = configType;
    this.paymentDeviceRequired = paymentDeviceRequired;
  }
}

export class NotifyPosRequest {
  loginUrl!: string;
  loginUsername?: string;
  loginPassword?: string;
  leaseUuid: string

  constructor(leaseUuid: string, loginUrl: string, loginUsername?: string, loginPassword?: string) {
    this.loginUrl = loginUrl;
    this.loginUsername = loginUsername;
    this.loginPassword = loginPassword;
    this.leaseUuid = leaseUuid
  }
}

export enum ConfigType {
  VEND = "VEND",
  VEND_SUPPLIER_LEASE = "VEND_SUPPLIER_LEASE",
  VEND_SUPPLIER_OFFERING = "VEND_SUPPLIER_OFFERING",
  POSTER = "POSTER"
}
