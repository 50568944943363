<div class="full-width py-2 px-3" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="body-1-default">Add a new document</div>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px">
      <span class="body-3-default">Document Type</span>
      <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
        <mat-select class="custom-select-field-value" placeholder="Select" [(value)]="documentTypeCode">
          <mat-option class="custom-select-field-option"  *ngFor="let type of docTypes" [value]="type.code">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px">
      <span class="body-3-default">Upload</span>


      <div style="width: auto; height: auto" fxLayout="row" fxLayoutAlign="start start">
        <div class="pick-container" fxLayout="column" fxLayoutAlign="start start">
          <input id="file-upload" type="file" (change)="fileChangeEvent($event.target)" hidden/>
          <div class="full-width btn-cursor" fxLayout="row" fxLayoutAlign="space-between start">
            <label for="file-upload" fxFlex class="custom-file-upload btn-cursor" fxLayout="row"
                   fxLayoutAlign="start center" fxLayoutGap="5px" fxFlexOffset="5px" fxFlexOffset.gt-sm="0">
              <div class="body-1-default" fxFlexOffset="18px">Select Document</div>
              <mat-icon fxFlexOffset="5px">upload_file</mat-icon>
            </label>
          </div>
        </div>
      </div>

      <div fxFlexOffset="5px" class="body-2-default">{{fileName}}</div>

      <div *ngIf="errorMessage" fxFlexOffset="5px">
        <mat-error style="color: red">{{errorMessage}}</mat-error>
      </div>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="15px">
      <button class="button-primary" mat-flat-button (click)="uploadDocument()" [disabled]="fileToUpload == null || documentTypeCode == null">
        <div class="button-text">Upload</div>
      </button>
    </div>

  </div>
</div>
