import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LandlordPropertyModel} from '../../../core/model/landlordProperty.model';
import {CurrentContextService} from '../../../core/services/security/current-context.service';

@Component({
  selector: 'app-landlord-properties-carousel',
  templateUrl: './landlord-properties-carousel.component.html',
  styleUrls: ['./landlord-properties-carousel.component.scss']
})
export class LandlordPropertiesCarouselComponent {

  @Input() isPropertySelected: boolean = false;
  @Output() isPropertySelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedProperty: LandlordPropertyModel | null = null;
  @Output() selectedPropertyChange: EventEmitter<LandlordPropertyModel> = new EventEmitter<LandlordPropertyModel>();

  @Input() properties: LandlordPropertyModel[] = [];
  @Input() changeContext: boolean = true;
  @Input() showOfferingsOnHover: boolean = false;


  constructor(private contextService: CurrentContextService) {
    this.properties = this.properties.map((prop: LandlordPropertyModel) => {
      if (prop.bannerImage!.startsWith('https://ips.') && !prop.bannerImage!.endsWith('&height=260')) {
        prop.bannerImage += '&height=260';
      }
      return prop;
    });
  }


  selectProperty(property: LandlordPropertyModel) {
    if (this.changeContext) {
      this.contextService.setCurrentLocation(property);
    }
    this.selectedProperty = property;
    this.selectedPropertyChange.emit(property);
    this.isPropertySelected = true;
    this.isPropertySelectedChange.emit(true)
  }

}
