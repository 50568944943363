<div class="full-width py-2 px-3" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="heading-default">Payment device settings</div>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px">
      <!--      <mat-form-field class="custom-select-field" appearance="outline">
              <mat-select class="custom-select-field-value" [placeholder]="reader.cardReaderCode!"
                          [(ngModel)]="reader.cardReaderCode" [disabled]="!cardReaderFutureDated(reader) || this.isEditing">
                <mat-option class="custom-select-field-option" *ngFor="let available of getAvailableCardReaders(reader)"
                            [value]="available.cardReaderCode">{{available.cardReaderCode}}</mat-option>
              </mat-select>

            </mat-form-field>

            <span class="body-3-default">Start Date</span>
            <mat-form-field class="custom-date-picker" appearance="outline">
              <input [(ngModel)]="reader.startDate" matInput [matDatepicker]="startDatePicker">
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>


            <span class="body-3-default">End Date</span>
            <mat-form-field class="custom-date-picker" appearance="outline">
              <input [(ngModel)]="reader.endDate" matInput [matDatepicker]="endDatePicker">
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>-->
      <mat-error class="error-text" *ngIf="cardReadersOverlap()">Card readers cannot have over-lapping dates
      </mat-error>


      <span class="body-3-default">Card code</span>
      <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
        <mat-select class="custom-select-field-value" [placeholder]="reader.cardReaderCode!"
                    [(ngModel)]="reader.cardReaderCode" [disabled]="!cardReaderFutureDated(reader)">
          <mat-option class="custom-select-field-option" *ngFor="let available of getAvailableCardReaders(reader)"
                      [value]="available.cardReaderCode">{{available.cardReaderCode}}</mat-option>
        </mat-select>
      </mat-form-field>

      <span class="body-3-default">Start Date</span>
      <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">

        <input matInput placeholder="select" [matDatepicker]="startPicker"
               [(ngModel)]="reader.startDate" (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)"
               [disabled]="!cardReaderFutureDated(reader)"/>
        <mat-datepicker-toggle matIconSuffix [for]="startPicker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>

      <span class="body-3-default">End Date</span>
      <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
        <input matInput placeholder="select" [matDatepicker]="endPicker" [(ngModel)]="reader.endDate"
               (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)"/>
        <mat-datepicker-toggle matIconSuffix [for]="endPicker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>


      <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
        <button mat-flat-button class="button-outline button-primary" (click)="cancelChanges()">
          <div class="button-text">Cancel</div>
        </button>
        <button mat-flat-button class="button-primary" (click)="saveChanges()">
          <div class="button-text">Save</div>
        </button>
      </div>


    </div>
  </div>
</div>
