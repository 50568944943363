<div class="p-3 full-width" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="15px">
  <div class="full-width close-button-container" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-close-small" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
    <span class="title-default">Authentication required</span>
  </div>

  <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxFlexOffset="10px" fxLayout="column" fxLayoutAlign="center center">
      <p class="heading-default">Please enter the One Time Pin</p>
      <ng-otp-input #ngOtpInput
                    (onInputChange)="onOtpChange($event)"
                    [config]="otpInputConfig">
      </ng-otp-input>
    </div>

    <div fxFlexOffset="50px">
      <button (click)="onVerify()" [disabled]="loading || len < 4" class="button-primary" mat-flat-button
              style="width: 138px">
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          {{ loading ? 'Working...' : 'Submit'}}
          <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </div>
      </button>
    </div>
  </div>
</div>
