import {Component, ElementRef, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent, ImageTransform} from 'ngx-image-cropper';
import {MatIconRegistry} from '@angular/material/icon';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentContextService} from '../../../../services/security/current-context.service';
import {lastValueFrom} from 'rxjs';
import {User} from '../../../../model/user.model';
import {ImageUploadService} from '../../../../../shared/services/image-upload.service';
import {ImageUploadUtilsService} from '../../../../../shared/services/image-upload-utils.service';

@Component({
  selector: 'app-upload-user-profile-image',
  templateUrl: './upload-user-profile-image.component.html',
  styleUrls: ['./upload-user-profile-image.component.scss']
})
export class UploadUserProfileImageComponent {
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale = 1;
  transform: ImageTransform = {};
  fileName: string;
  route = this.activatedRoute.snapshot;
  routeParam = true;
  aspectRatio: number;
  cropWidth: number;
  width?: string;
  round: boolean = true;
  image: any;
  loading = false;
  url?: string | ArrayBuffer;
  errorMessage: string | null = null;


  constructor(private dialogRef: MatDialogRef<UploadUserProfileImageComponent>,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private service: ImageUploadService,
              private context: CurrentContextService,
              private imageUploadUtils: ImageUploadUtilsService) {
    this.fileName = 'no file selected';
    this.aspectRatio = 1;
    this.cropWidth = 200;
    this.round = true;
    /*this.matIconRegistry.addSvgIcon('back-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/actions/arrow-back-dark.svg'));*/
  }

  fileChangeEvent($event: Event): void {
    this.errorMessage = null;
    this.fileName = this.fileInput.nativeElement.value.replace(/^.*[\\\/]/, '');
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(image?: HTMLImageElement): void {
    console.log(image);
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
    this.errorMessage = 'Invalid image type, only png and jpg are allowed';
  }

  onZoom(amount: number): void {
    console.log(this.scale);
    if ((amount > 0) && (this.scale < 2)) {
      this.scale = this.scale + 0.1;
    } else if ((amount < 0) && (this.scale >= 0.5)) {
      this.scale = this.scale - 0.1;
    }
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  upload(): void {
    this.errorMessage = null;
    const blob = this.imageUploadUtils.dataURItoBlob(this.croppedImage);
    if (blob.size > 10485760) {
      this.errorMessage = 'File size too large, files must be less than 10MB';
      return;
    }

    const type = this.fileName.replace(/^.+[\W]/, '');
    const file = new File([blob], this.fileName, {
      type: `image/${type}`
    });

    this.loading = true;
    lastValueFrom(this.service.save(file))
      .then((res: User) => {
        this.context.setCurrentUserInContext(res);
        this.close();
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }



  close() {
    this.dialogRef.close();
  }
}
