import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {
  getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    length = Math.max(length, 0);
    const numberOfPages = Math.ceil(length / pageSize);
    return `${length} Results \xa0 \xa0 \xa0 Page ${page + 1} of ${numberOfPages}`;
  };
}
