<div fxLayout="column">
  <app-page-header [banner]="true"
                   [title]="'Pick your property'"
                   [description]="'Select one of the properties below to access.'">
  </app-page-header>

  <div fxFlexOffset="40px"></div>

  <div class="page-padding">
    <app-landlord-properties-carousel [(isPropertySelected)]="isPropertySelected"
                                      [properties]="this.properties"
                                      [selectedProperty]="selectedProperty"
                                      (selectedPropertyChange)="broadcastChangeProperty($event)"
    ></app-landlord-properties-carousel>
  </div>
</div>


