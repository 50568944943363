import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {CommunicationDialogInterface} from '../../../interfaces/communication-dialog.interface';

@Component({
  selector: 'app-icon-popup',
  templateUrl: './icon-popup.component.html',
  styleUrls: ['./icon-popup.component.scss']
})
export class IconPopupComponent implements OnInit {
  form?: FormGroup;
  header: string;
  description: string;
  boldDescription: string;
  imageSrc: string;
  cancelButton: string;
  submitButton: string;
  submitNav: string;

  constructor(
    private dialogRef: MatDialogRef<IconPopupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: CommunicationDialogInterface
  ) {
    this.header = data.header;
    this.description = data.description;
    this.boldDescription = data.boldDescription;
    this.imageSrc = data.imageSrc;
    this.cancelButton = data.cancelButton;
    this.submitButton = data.submitButton ? data.submitButton[0] : null;
    this.submitNav = data.submitButton ? data.submitButton[1] : null;
  }

  ngOnInit(): void {}

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  navTo(): void {
    this.router.navigate([this.submitNav]);
    this.confirm();
  }

}
