import {Injectable} from "@angular/core";
import {LeaseGridSpaceGroup, LeaseSummary, OccupiedSpace} from "./leasing.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {GenericService} from "../../shared/services/generic.service";
import {OfferingSpacesInterface} from '../spaces/model/offering-spaces.interface';

@Injectable({
  providedIn: 'root'
})
export class LeasingService extends GenericService<LeaseSummary> {
  constructor(http: HttpClient) {
    super(http, environment.apiLeaseHost + `/console/offering/district/leasing`);
  }

  getDistrictSpaces(spaceNames: string | null): Observable<OfferingSpacesInterface[]> {
    let requestParams = new HttpParams();
    if (spaceNames) {
      requestParams = requestParams.append('spaceCodes', spaceNames);
    }
    return this.http.get<OfferingSpacesInterface[]>(this.getBaseUrl + `/lease-planning/grid-spaces`, {params: requestParams});
  }

  getLeaseOccupancy(searchTerm: string | null, startDate: string, endDate: string, status: string | null) {
    let requestParams = new HttpParams()
      .set('leaseStartDate', startDate)
      .set('leaseEndDate', endDate);

    if (searchTerm) {
      requestParams = requestParams.append('spaceName', searchTerm);
    }

    if (status) {
      requestParams = requestParams.append('leaseStatus', status);
    }
    return this.http.get<OccupiedSpace[]>(this.getBaseUrl + `/lease-planning/vacancy`, { params: requestParams });
  }

  savePlannedLeaseSpaceGroup(group: LeaseGridSpaceGroup) {
    return this.http.post<LeaseGridSpaceGroup>(this.getBaseUrl + `/lease-planning/planned`, group);
  }

  get getBaseUrl() {
    return environment.apiLeaseHost + '/console/offering/district/leasing'
  }

  getRentalType(typeCode: string | null): string {
    if (typeCode === 'TURNOVER_PERCENTAGE') return 'Percentage rental';
    if (typeCode === 'PRICING_MODEL') return 'Fixed rental';
    return '-';
  }
}
