import {Component, Injector, Renderer2} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {LandlordPropertyModel} from '../../../core/model/landlordProperty.model';
import {
  CustomTimeRangeChangeEvent
} from '../../../shared/components/custom-time-range-picker/custom-time-range-change-event.interface';
import {LocationOffering, OfferingListing, TradingHours} from '../../../core/model/side-nav.model';
import {MatDialog} from '@angular/material/dialog';
import {
  UploadOfferingListingPhotoDialogComponent
} from './upload-offering-listing-photo-dialog/upload-offering-listing-photo-dialog.component';
import {lastValueFrom} from 'rxjs';
import {OfferingListingService} from '../service/offering-listing.service';
import {LoadingService} from '../../../core/services/loading.service';
import {SharedServicesService} from '../service/shared-services.service';
import {GenericTypeDef} from '../../../core/model/generic-type-def.model';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-manage-listing',
  templateUrl: './manage-listing.component.html',
  styleUrls: ['./manage-listing.component.scss']
})
export class ManageListingComponent extends BasePageComponent {
  descriptionForm: FormGroup;
  currentLocation: LandlordPropertyModel | null = null;
  tradingHours: TradingHours[] | null = null;

  offeringListing: OfferingListing | null = null;
  editingDescription: boolean = false;
  industryCategories: GenericTypeDef[] = [];
  loading$ = this.loader.loading$;
  propertyDisplayImage: string | null = null;


  constructor(injector: Injector,
              public loader: LoadingService,
              private currentContext: CurrentContextService,
              public matDialog: MatDialog,
              private offeringListingService: OfferingListingService,
              private sharedService: SharedServicesService,
              private renderer: Renderer2) {
    super(injector);
    this.descriptionForm = new FormGroup({
      description: new FormControl({value: '', disabled: false})
    });
    this.descriptionForm.disable();
    this.initFromContext();
    if (!this.offering!.offeringListing) {
      this.offeringListing = {
        locationOfferingUuid: this.offering!.uuid,
        description: null,
        tradingHours: null,
        offeringListingIndustries: []
      };
      this.sendOfferingListingUpdate();
      this.currentContext.refreshCurrentLocation()
        .then(() => {
          this.initFromContext();
        }).catch((error: string) => {
        console.log(error);
      })
    }


    lastValueFrom(this.sharedService.getAllIndustryCategories())
      .then((res: { content: GenericTypeDef[] }) => {
        this.industryCategories = res.content;
      });
  }

  initFromContext(): void {
    this.currentLocation = this.currentContext.getCurrentLocation();

    let index: number | undefined = this.currentLocation?.locationOfferings
      ?.findIndex(location => location.offeringUuid === this.offering!.offeringUuid);

    if (index != undefined && index >= 0){
      let locationOffering: LocationOffering= this.currentLocation!.locationOfferings!.at(index)!;
      if (locationOffering.offeringListing) {
        this.offeringListing = locationOffering.offeringListing;
        this.tradingHours = locationOffering.offeringListing.tradingHours;
        this.descriptionForm.get('description')!.setValue(locationOffering.offeringListing.description);
      }
      this.propertyDisplayImage = locationOffering.bannerImage;
    }
  }

  handleTradingHourChange(label: string, $event: CustomTimeRangeChangeEvent) {
    if (this.tradingHours) {
      let tradingHours: TradingHours[] = this.tradingHours!.filter((construct: TradingHours) => {
        return construct.name.toLowerCase() === label.toLowerCase()
      });
      if (tradingHours.length == 1) {
        tradingHours.at(0)!.endTime = $event.endTime;
        tradingHours.at(0)!.startTime = $event.startTime;
      } else if (tradingHours.length == 0) {
        this.tradingHours.push({
          name: label,
          startTime: $event.startTime,
          endTime: $event.endTime
        });
      }
    } else {
      this.tradingHours = [];
      this.tradingHours.push({
        name: label,
        startTime: $event.startTime,
        endTime: $event.endTime
      });
      this.offeringListing!.tradingHours = this.tradingHours;
    }
    if ($event.startTime && $event.endTime) {
      this.sendOfferingListingUpdate();
    }
  }

  getTradingHoursForDay(label: string): TradingHours {
    if (this.tradingHours) {
      let item: TradingHours[] = this.tradingHours.filter((tradingHour: TradingHours) => {
        return tradingHour.name.toLowerCase() === label.toLowerCase()
      });
      if (item.length == 1) {
        return item.at(0)!;
      }
    }
    return {
      name: label
    }
  }

  showUploadDialog(): void {
    this.matDialog.open(UploadOfferingListingPhotoDialogComponent, {
      panelClass: 'dialog-large-file-upload',
      /*      width: '202px',
            height: '300px',*/
      position: {right: '230px'},
      backdropClass: 'dialog-backdrop',
      data: {
        locationOfferingUuid: this.offering?.uuid
      }
    }).afterClosed().subscribe((res?: LocationOffering) => {
      if (res) {
        this.updateAndReloadContext(res);
      }
    })
  }

  sendOfferingListingUpdate(): void {
    lastValueFrom(this.offeringListingService.updateOfferingListing(this.offering!.uuid, this.offeringListing!))
      .then((res: LocationOffering) => {
        this.updateAndReloadContext(res);
      });
  }

  toggleDescriptionEdit(): void {
    this.editingDescription = !this.editingDescription;
    if (this.editingDescription) {
      this.descriptionForm.enable();
      this.renderer.selectRootElement('#descriptionInput').focus();
    } else {
      this.descriptionForm.disable();
    }
  }

  updateDescription(): void {
    this.offeringListing!.description = this.descriptionForm.get('description')!.value;
    this.sendOfferingListingUpdate();
    this.toggleDescriptionEdit();
  }

  toggleCategoryActive($event: MatSlideToggleChange, id: number): void {
    if ($event.checked) {
      lastValueFrom(this.offeringListingService.addIndustry(this.offeringListing!.locationOfferingUuid, {
        industryCategoryId: id,
        offeringListingUuid: this.offeringListing!.uuid!
      }))
        .catch((error: string) => console.log(error));
    } else {
      lastValueFrom(this.offeringListingService.deleteIndustry(this.offeringListing!.uuid!, id))
        .catch((error: string) => console.log(error));
    }
  }

  getIndustryCheckedStatus(category: GenericTypeDef): boolean {
    if (this.offeringListing) {
      let searchResults: GenericTypeDef[] = this.offeringListing.offeringListingIndustries.filter((item) => {
        return (item.id == category.id);
      });
      return (searchResults.length == 1);
    }
    return false;
  }

  generateLinkToClipboard(): string {
    if (this.currentContext.getCurrentLandlordId() && this.offering) {
      let index: number | undefined = this.currentContext.getCurrentLocation()?.locationOfferings
        ?.findIndex(location => location.offeringUuid === this.offering!.offeringUuid);
      if (index != undefined && index >= 0 ) {
        let locationOffering: LocationOffering = this.currentContext.getCurrentLocation()!.locationOfferings!.at(index)!;
        return `${environment.apiPortal}/${locationOffering.locationCode}/${locationOffering.offeringCode}/apply?formState=embed`;
      }
    }
    return '';
  }

  private updateAndReloadContext(res: LocationOffering): void {
    this.currentContext.updateLocationOfferings(res);
    this.initFromContext();
  }
}
