export interface ColorTheme {
  id: string
  primary: string,
  secondary: string
}

export const defaultColorTheme: ColorTheme = {
  id: 'soko',
  primary: '#5600E8',
  secondary: '#efebfe'
}
export const colorThemes: ColorTheme[] = [
  defaultColorTheme,
  {
    id: 'facebook',
    primary: '#4267B3',
    secondary: '#e9ebee'
  },
  {
    id: 'mcdonalds',
    primary: '#cf240a',
    secondary: '#fff10a'
  },
]
