<app-pre-auth-toolbar></app-pre-auth-toolbar>

<div class="px-3 py-2 pb-3 full-screen-height" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width pre-auth-header-div" fxLayout="column" fxLayoutGap="20px"  fxLayoutAlign="center center">
    <h1 class="title-large">Log In</h1>
  </div>
  <div class=" p3 full-width flex-div-row" fxLayoutAlign="center center">
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayoutAlign="space-between center">
      <!--  fxLayoutGap="30px" -->
      <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" hideRequiredMarker>
        <mat-label>Email</mat-label>
        <input matInput
               type="email"
               placeholder="Email address"
               formControlName="email"
               pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
               required
        />
        <mat-error class="link-error"  *ngIf="loginForm.get('email')!.hasError('required')">Email is required</mat-error>
        <mat-error class="link-error"  *ngIf="loginForm.get('email')!.hasError('pattern')">Email is not valid</mat-error>
      </mat-form-field>
      <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" hideRequiredMarker>
        <mat-label>Password</mat-label>
        <input
          matInput [type]="hidePassword ? 'password' : 'text'"
          placeholder="password"
          formControlName="password"
          required/>
        <mat-icon class="btn-cursor icon-password-suffix" matIconSuffix
                  (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error class="link-error"  *ngIf="loginForm.get('password')!.hasError('required')">Password is required</mat-error>
      </mat-form-field>
      <mat-error class="link-error"  *ngIf="loginForm.get('password')!.hasError('login-failed')">Username or password is incorrect</mat-error>
      <button mat-button class="button-primary" type="submit" [disabled]="loader.loading$ | async"><div class="button-text">Login with Email</div></button>
      <div class="heading-regular btn-cursor" style="text-align: center" (click)="userForgotPassword()">Forgot your password?</div>
    </form>
  </div>

  <div class="mt-5 full-width flex-row" fxLayoutAlign="center center">
    <app-footer-links></app-footer-links>
  </div>
</div>
