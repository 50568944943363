<div fxLayout="column">
  <app-page-header [section]="'Tenants'" [title]="'Look who is currently leasing'" [description]="'This is all your current trading retailer leases summarising their lease terms. Stay on top of renewals by exploring their expiration periods.'"></app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

    <div fxFlexOffset="20px">
      <div fxLayout="row">
        <span class="subtitle-default">Lease List</span>
        <div fxLayoutGap="10px" fxFlexOffset="50px">
          <button class="button-action" mat-flat-button (click)="createLease()"><div class="button-text">Create a lease</div></button>
        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
      <span class="heading-default"> Current lease schedule </span>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px" fxFlexOffset="20px" [formGroup]="filterForm">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="companyName">
          </mat-form-field>
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Space Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="spaceNames">
          </mat-form-field>
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Occupancy Type</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="occupancyType">
              <mat-option class="custom-select-field-option"  *ngFor="let type of occupancyTypes" [value]="type">{{utils.displayStatus(type)}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Expiration In</mat-label>
          <input matInput type="text" disabled >
          <mat-button-toggle-group matSuffix class="custom-borderless-button-toggle" multiple (change)="setExpiresIn($event)">
            <ng-container *ngFor="let customRange of dateRanges">
              <mat-button-toggle value="{{customRange}}">{{customRange}} days</mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </mat-form-field>


      </div>
      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Lease schedule </th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
              *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailerCompanyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="spaceName">
          <th mat-header-cell *matHeaderCellDef> Space Name </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayString(element.spaceNames, 15)}} </td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef> Size </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.totalSize}} m<sup>2</sup></td>
        </ng-container>

        <ng-container matColumnDef="rentalType">
          <th mat-header-cell *matHeaderCellDef> Rental Type </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.rentalType ? displayRentalType(element.rentalType) : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="monthlyRentalFee">
          <th mat-header-cell *matHeaderCellDef> Monthly Rental Fee </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.totalMonthlyFee!) + (element.rentalType == 'TURNOVER_PERCENTAGE' ? ' + ' + element.turnoverPercentageValue + '%' : '')}}</td>
        </ng-container>

        <ng-container matColumnDef="expiresInDays">
          <th mat-header-cell *matHeaderCellDef> Days Until Expiration </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.expiresInDays ? element.expiresInDays + ' days' : '-'}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="openLeaseViewTab(row.uuid, row.occupancyType, row.agreementType)"></tr>
      </table>
      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>
