import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EmailTemplateModel, MergeTag} from '../model/offering-template.model';
import {UtilsService} from '../../../../shared/services/utils.service';
import {environment} from '../../../../../environments/environment';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import CryptoJS from 'crypto-js';
import {User} from '../../../../core/model/user.model';

declare var unlayer: any;


@Component({
  selector: 'app-email-template-designer',
  templateUrl: './email-template-designer.component.html',
  styleUrls: ['./email-template-designer.component.scss']
})
export class EmailTemplateDesignerComponent implements OnChanges, OnInit, AfterViewInit {



  @Input()
  selectedTemplate!: EmailTemplateModel | null;

  @Input()
  offeringUuid!: string;

  @Output()
  selectedTemplateChange: EventEmitter<EmailTemplateModel | null> = new EventEmitter<EmailTemplateModel | null>();

  @Output()
  saveTemplate: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageId: any;

  editorIsLoaded: boolean = false;


  constructor(public utilsService: UtilsService,
              public contextService: CurrentContextService) {
    // pageId is used as the id of the email component such that we can have multiple tabs of it
    this.pageId = utilsService.generateUuid();

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const userId = this.offeringUuid!;

      const signature = CryptoJS.HmacSHA256(userId, environment.unlayerUserSecret).toString();
      let user: User = this.contextService.getCurrentUser()!;
      unlayer.init({
        id: this.pageId,
        projectId: environment.unlayerApiProjectId,
        display: 'email',
        user: {
          id: userId,
          signature: signature,
          email: user.email,
          name: `${user.firstname} ${user.lastname}`
        },
      });
      this.editorIsLoaded = true;
    }, 1000);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTemplate'] && !changes['selectedTemplate'].isFirstChange()) {
      this.selectedTemplate = changes['selectedTemplate'].currentValue;
      this.loadTemplate(this.selectedTemplate!);
    }
  }

  loadTemplate(template: EmailTemplateModel): void {
    if (template && this.editorIsLoaded) {
      if (template.mergeTags && template.mergeTags.length > 0) {
        const jsonData = this.parseMergeTagsIntoUnlyerJson(template);
        unlayer.setMergeTags(jsonData);
      } else {
        unlayer.setMergeTags({});
      }
      unlayer.loadDesign(template.design);
    }
  }

  // Need to reduce the merge tag array into one merge tag which contains all of the tags
  private parseMergeTagsIntoUnlyerJson(template: EmailTemplateModel): MergeTag {
    return template.mergeTags.reduce((acc, tag) => {
      const [fieldName, properties] = Object.entries(tag)[0];
      acc[fieldName] = properties;
      return acc;
    }, {});
  }
  clearTemplate() {
    if (this.editorIsLoaded) {
      unlayer.loadBlank();
    }
  }

  saveAndExportHtml() {
    unlayer.exportHtml((data: any) => {
      if (this.selectedTemplate) {
        this.selectedTemplate.design = data.design;
        this.selectedTemplate.html = data.html
        this.selectedTemplateChange.emit(this.selectedTemplate);
        this.saveTemplate.emit(true);
      }
    }, {
      minify: true
    });
  }

  clearCurrentSelectedTemplate(): void {
    this.selectedTemplate = null;
    this.selectedTemplateChange.emit(null);
    this.clearTemplate();
  }

  protected readonly environment = environment;
}
