<div fxLayout="column">

  <app-page-header [section]="'Retailer Application'" [title]="'Hand pick retailers for your leads'" [description]="'The applications module allows you to see who applied to your retail offering. Based on the relevant curation criteria you can decide who do and don’t qualify.'"></app-page-header>

  <div fxLayout="column" class="page-content full-screen-height-page-default">

    <app-retailer-details [id]="retailerId" [header]="'Applicant Information'" [createLease]="false" *ngIf="retailerId" [offering]="offering!"></app-retailer-details>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <app-applicant-results [id]="retailerId"  *ngIf="retailerId"></app-applicant-results>

  </div>

</div>
