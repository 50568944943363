<div fxLayout="column">

  <app-page-header [section]="'Transaction Schedule'" [title]="'Keep an eye on transactions'" [description]="'The transaction module allows you to see all transactions for your retail offering. Everything happens automatically, your work here is done!'"></app-page-header>

  <div class="page-content page-padding" fxLayout="column">
    <app-daily-transaction-summary></app-daily-transaction-summary>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <app-transactions-schedule></app-transactions-schedule>
  </div>
</div>
