import {SelectionModel} from "@angular/cdk/collections";
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {LoadingService} from "../../../core/services/loading.service";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../shared/services/paginator.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {DistrictPaymentConfig} from "../../accounts/run-tenant-payouts/model/district-payout-config.model";
import {
  PosSoftwareSettingsDialogComponent
} from "../../leasing/lease-view/lease-pos-settings/pos-software-settings-dialog/pos-software-settings-dialog.component";
import {
  mapRequestToData,
  OfferingCardReaderConfig,
  PaymentConfigRequest,
  TransactionSource,
  TransactionSourceConfig
} from "../../leasing/leasing.model";
import {PaymentConfigsService} from "../service/payment-configs.service";
import {AssignedSuppliersDialogComponent} from "./assigned-suppliers-dialog/assigned-suppliers-dialog.component";

@Component({
  selector: 'app-payments-settings',
  templateUrl: './payments-settings.component.html',
  styleUrls: ['./payments-settings.component.scss']
})
export class PaymentsSettingsComponent extends BasePageComponent implements OnInit {
  @ViewChild('netCashPaginator') set netCashPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.netCashDataSource) this.netCashDataSource.paginator = paginator;
  }

  @ViewChild('posPaginator') set posPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.posDataSource) this.posDataSource.paginator = paginator;
  }

  netCashDataSource!: MatTableDataSource<DistrictPaymentConfig>;
  posDataSource!: MatTableDataSource<PaymentConfigRequest>;

  netCashDisplayedColumns: string[] = ['uuid', 'statementRef', 'startDate', 'endDate'];
  posDisplayedColumns: string[] = ['select', 'uuid', 'displayName', 'paymentSourceName', 'paymentTypeName', 'domainPrefix', 'outletId', 'startDate', 'endDate', 'activationStatus', 'action'];

  isInternalUser = false;

  paymentConfig: PaymentConfigRequest | null = null;
  selectedConfig: string | null = null;

  configSelectionModel = new SelectionModel<PaymentConfigRequest>(false, []);

  cardReadersOriginal: OfferingCardReaderConfig[] = [];
  cardReaders: OfferingCardReaderConfig[] = [];
  availableCardReaders: { code: string, readers: OfferingCardReaderConfig[] }[] = [];
  newAvailableCardReaders: OfferingCardReaderConfig[] = [];
  newCardReaders: OfferingCardReaderConfig[] = [];

  constructor(injector: Injector,
              private paymentConfigsService: PaymentConfigsService,
              private paginatorService: PaginatorService,
              public utilsService: UtilsService,
              private matDialog: MatDialog,
              private contextService: CurrentContextService,
              public dateUtils: DateUtilsService,
              public loader: LoadingService) {
    super(injector);
  }

  ngOnInit() {
    this.loadDistrictPaymentConfigs();
    this.loadDistrictTransactionSources();
    this.isInternalUser = this.contextService.checkUserFeatures(['settings_internal_admin'], this.offering!.offeringUuid)
  }

  loadDistrictPaymentConfigs(): void {
    this.paymentConfigsService.getDistrictPaymentConfigs().subscribe({
      next: value => {
        this.netCashDataSource = new MatTableDataSource([value]);
      },
      error: err => console.log(err)
    })
  }

  loadDistrictTransactionSources(): void {
    this.paymentConfigsService.getDistrictPosConfigs().subscribe({
      next: value => {
        this.posDataSource = new MatTableDataSource(value);
      },
      error: err => console.log(err)
    })
  }


  getConfigValue(key: string, providerConfig: string) {
    const json = JSON.parse(providerConfig);
    const keys = key.split('.')
    let result = json;
    for (const key of keys) {
      if (result.hasOwnProperty(key)) {
        result = result[key];
      } else {
        return undefined; // or throw an error, or handle the case as needed
      }
    }
    return result;
  }

  addPosConfig() {
    this.matDialog.open(PosSoftwareSettingsDialogComponent, {
      panelClass: 'dialog-large',
      maxHeight: 'auto',
      data: {
        vendConfig: null,
        offering: this.offering,
        posType: TransactionSource.VEND,
        allowedPosTypes: [TransactionSource.VEND],
        offeringConfig: true
      }
    }).afterClosed().subscribe((response: { save: boolean, vendConfig: TransactionSourceConfig }) => {
      if (response.save) {
        const requestConfig = { ...response.vendConfig };
        if ('startDate' in requestConfig) delete (requestConfig as any)['startDate'];
        if ('endDate' in requestConfig) delete (requestConfig as any)['endDate'];
        if ('paymentConfigUuid' in requestConfig) delete (requestConfig as any)['paymentConfigUuid'];

        this.paymentConfig = {
          uuid: null,
          leaseUuid: null,
          activationStatus: 'NEW',
          districtUuid: this.offering?.offeringUuid!,
          billingUpdateRequested: null,
          transactionSource: response.vendConfig.paymentSourceName,
          transactionSourceConfig: JSON.stringify(requestConfig),
          active: true,
          startDate: null,
          endDate: null
        }

        this.paymentConfigsService.saveDistrictPosConfig(this.paymentConfig!)
          .subscribe({
            next: () => {
              this.loadDistrictTransactionSources();
            },
            error: err => console.log(err)
          })
      }
    });
  }

  removePosConfig() {
    if (this.selectedConfig && this.configSelectionModel.hasValue()) {
      this.paymentConfigsService.removeDistrictPosConfig(this.selectedConfig).subscribe({
        next: value => console.log('removed'),
        error: err => console.log(err)
      })
    }
  }

  activatePOSAccount(active: boolean) {
    let paymentConfig = {...this.configSelectionModel.selected[0]};
    if (paymentConfig.transactionSourceConfig) {
      this.paymentConfigsService.updatePaymentConfigStatus(paymentConfig.uuid!, active ? 'ACTIVATED' : 'DEACTIVATED').subscribe({
        next: () => this.loadDistrictTransactionSources(),
        error: err => console.log(err)
      })
    }
  }

  setSelectedConfig(row: PaymentConfigRequest) {
    this.configSelectionModel.toggle(row);
    if (this.configSelectionModel.hasValue()) {
      this.selectedConfig = row.uuid;
      this.loadAssignedLeaseCardReaders();
    } else {
      this.selectedConfig = null;
    }
  }

  checkSuppliers(row: PaymentConfigRequest) {
    this.matDialog.open(AssignedSuppliersDialogComponent, {
      panelClass: 'dialog-large',
      height: 'auto',
      data: {
        configUuid: row.uuid,
        offering: this.offering,
        configType: 'OFFERING'
      }
    })
  }

  // CARD READERS

  loadAssignedLeaseCardReaders() {
    if (this.selectedConfig) {
      const config = mapRequestToData(this.configSelectionModel.selected[0]).transactionSourceConfig as TransactionSourceConfig;
      this.paymentConfigsService.getAssignedOfferingCardReaders(config.domainPrefix!).subscribe({
        next: value => {
          this.cardReadersOriginal = value.map(m => {
            return {...m};
          });
          this.cardReadersOriginal.forEach(ea => {
            ea.startDate = this.dateUtils.displayShortDate(ea.startDate);
            ea.endDate = this.dateUtils.displayShortDate(ea.endDate);
          })

          this.cardReaders = [...value];
          this.cardReaders.forEach(ea => {
            ea.startDate = this.dateUtils.displayShortDate(ea.startDate);
            ea.endDate = this.dateUtils.displayShortDate(ea.endDate);
          })
          this.cardReaders.forEach(ea => this.loadAvailableLeaseCardReaders(ea, false));

          this.setNewCardReaders();
        },
        error: err => console.log(err)
      })
    }
  }

  get editingCardReaders(): boolean {
    const newCardReaders = this.newCardReaders.filter(f => f.cardReaderCode != null);
    return JSON.stringify(this.cardReadersOriginal) != JSON.stringify(this.cardReaders) || newCardReaders.length > 0;
  }

  cardReadersOverlap(): boolean {
    let overlap = false;
    const allReaders = this.cardReaders.map(m => m);
    const newCardReaders = this.newCardReaders.filter(f => f.cardReaderCode != null);
    newCardReaders.length > 0 ? allReaders.push(...newCardReaders) : null;
    allReaders.forEach((reader1, i) => {
      allReaders.forEach((reader2, j) => {
        if (i != j) {
          overlap = overlap || (new Date(reader1.startDate!) <= new Date(reader2.endDate!) && new Date(reader1.endDate!) >= new Date(reader2.startDate!))
        }
      })
    })
    return overlap;
  }

  saveCardReadersChanges() {
    const cardReadersChanged = JSON.stringify(this.cardReadersOriginal) != JSON.stringify(this.cardReaders);

    if (cardReadersChanged) {
      this.cardReaders.forEach(reader => {
        this.assignLeaseCardReaders(reader);
      })
    }

    this.newCardReaders.forEach(ea =>  {
      if (ea.cardReaderCode != null) this.assignLeaseCardReaders(ea);
    })
  }

  cancelCardReaderChanges() {
    this.cardReaders = [...this.cardReadersOriginal];
  }

  assignLeaseCardReaders(reader: OfferingCardReaderConfig) {
    const sourceConfig = mapRequestToData(this.configSelectionModel.selected[0]).transactionSourceConfig;
    reader.startDate = this.dateUtils.displayShortDate(reader.startDate);
    reader.endDate = this.dateUtils.displayShortDate(reader.endDate);
    reader.offeringUuid = this.offering?.offeringUuid!;
    reader.configDomainPrefix = (sourceConfig as TransactionSourceConfig).domainPrefix;
    this.paymentConfigsService.assignOfferingCardReader(reader).subscribe({
      next: value => {
        this.loadAssignedLeaseCardReaders();
      },
      error: err => console.log(err)
    })
  }

  cardReaderFutureDated(reader: OfferingCardReaderConfig) {
    return new Date(reader.startDate!) > new Date();
  }

  getAvailableCardReaders(reader: OfferingCardReaderConfig): OfferingCardReaderConfig[] {
    if (this.availableCardReaders.length > 0) {
      const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
      return this.availableCardReaders[objIndex] ? this.availableCardReaders[objIndex].readers : [];
    }
    return [];
  }

  loadAvailableLeaseCardReaders(reader: OfferingCardReaderConfig, newReader: boolean) {
    reader.startDate = this.dateUtils.displayShortDate(reader.startDate);
    reader.endDate = this.dateUtils.displayShortDate(reader.endDate);
    this.paymentConfigsService.getAvailableOfferingCardReaders(this.dateUtils.displayShortDate(reader.startDate)!,this.dateUtils.displayShortDate(reader.endDate)!).subscribe({
      next: value => {
        if (!newReader) {
          const objIndex = this.availableCardReaders.findIndex(f => f.code === reader.cardReaderCode!);
          if (objIndex < 0) {
            this.availableCardReaders.push({code: reader.cardReaderCode ? reader.cardReaderCode : 'new', readers: value});
          } else {
            this.availableCardReaders[objIndex].readers = value;
          }
        } else {
          this.newAvailableCardReaders = value;
        }
      },
      error: err => console.log(err)
    })
  }

  setNewCardReaders() {
    this.newCardReaders = [];
    const extraCardReaders = 3 - this.cardReaders.length;
    if (this.selectedConfig) {
      const offeringConfig = mapRequestToData(this.configSelectionModel.selected[0]).transactionSourceConfig as TransactionSourceConfig;
      for (let i = 0; i < extraCardReaders; i++) {
        this.newCardReaders.push(new OfferingCardReaderConfig({
          offeringUuid: this.offering?.offeringUuid!,
          configDomainPrefix: offeringConfig.domainPrefix,
          startDate: new Date(),
          endDate: null
        }))
      }
    }

    this.newCardReaders.forEach(ea => this.loadAvailableLeaseCardReaders(ea, true));
  }
}
