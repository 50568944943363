import {Component, Injector, Input} from '@angular/core';
import {PageComponentDataProvider} from '../../interfaces/page-component-data-provider';
import {PageOffering} from '../../model/side-nav.model';
import {BasePageId} from '../../interfaces/page-event.interface';

@Component({template: ''})
export class BasePageComponent<T = any> {
  @Input() data: any;
  @Input() offering?: PageOffering;
  @Input() pageId!: BasePageId;

  constructor(private injector: Injector) {
    this.data = this.injector.get(PageComponentDataProvider);
    this.offering = this.injector.get(PageOffering);
    this.pageId = this.injector.get(BasePageId);
  }
}
