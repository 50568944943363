import {Injectable} from '@angular/core';
import {BaseService} from '../../../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {DistrictPaymentConfig, UpdateMinPayoutRequest} from '../model/district-payout-config.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictPayoutsConfigService extends BaseService {

  baseUrl = environment.apiDistrictsHost + '/console/offering/district/accounts/run-payouts/config'

  constructor(private http: HttpClient) {
    super();
  }

  public getConfigForOffering(): Observable<DistrictPaymentConfig> {
    return this.http.get<DistrictPaymentConfig>(this.baseUrl);
  }

  public updateMinPayoutAmount(request: UpdateMinPayoutRequest): Observable<DistrictPaymentConfig> {
    return this.http.post<DistrictPaymentConfig>(this.baseUrl, request);
  }
}
