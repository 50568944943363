<div class="name-form-container page-padding" fxLayout="column">
  <div class="my-3" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
<!--    <button (click)="exit()" class="button-outline-white" mat-flat-button style="width: 90px;">-->
<!--      <div class="button-text">Back</div>-->
<!--    </button>-->
    <button (click)="saveForm()" *ngIf="this.edit" [disabled]="loader.loading$ | async" class="button-outline" mat-flat-button
            style="width: 90px">
      <div class="button-text">Save</div>
    </button>
    <button class="button-primary" mat-flat-button style="width: 90px;"
            [disabled]="!formTemplate.formName || formTemplate.formName.trim().length == 0 || (loader.loading$ | async)"
            (click)="setFormName()">
      <div class="button-text">Next</div>
    </button>
  </div>

  <div class="question-card ps-0" fxLayout="row">
    <div class="question-card-left" fxLayout="column" fxLayoutAlign="center start">
      <mat-icon class="more-icon">more_vert</mat-icon>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="15px">
      <div class="question-card-header pb-2" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="select-icon me-2">
          <mat-icon svgIcon="assignment"></mat-icon>
        </div>
        <div class="body-1-regular-2">Don’t worry, the name will not be displayed on the form</div>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div class="heading-bold">Give it a name</div>

        <div [formGroup]="formNameGroup">
          <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline">
            <input formControlName="formName"
                   matInput
                   placeholder="Enter your form name"
                   type="text"/>
          </mat-form-field>
        </div>
      </div>
    </div>


  </div>
</div>
