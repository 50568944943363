<div fxLayout="column" fxLayoutGap="15px">

<!--  info card-->

  <div class="info-card" fxLayout="column" fxLayoutGap="15px">

    <!--    slide toggle -->

    <mat-slide-toggle checked color="primary"></mat-slide-toggle>

    <!--    checkbox -->

    <mat-checkbox checked color="accent"></mat-checkbox>

    <!--    text input -->

    <mat-form-field class="custom-text-field" appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput type="text" placeholder="Hello" [value]="'this is the value'">
    </mat-form-field>

<!--    text input disabled-->

    <mat-form-field class="custom-text-field" appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput type="text" placeholder="Hello" [value]="'this is the value'" disabled>
    </mat-form-field>

<!--    autofill text-->

    <mat-form-field class="custom-text-autofill" appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput type="text" placeholder="Hello" [value]="'this is the value'" disabled>
    </mat-form-field>

    <!--    select input -->

    <mat-form-field class="custom-select-field" appearance="outline">
      <mat-label>Label</mat-label>
      <mat-select class="custom-select-field-value" multiple [value]="[]">
        <mat-option class="custom-select-field-option" [value]="'one'">Choice one</mat-option>
        <mat-option class="custom-select-field-option" [value]="'two'">Choice two</mat-option>
      </mat-select>
    </mat-form-field>

<!--    location autocomplete-->

    <mat-form-field appearance="outline" class="custom-text-field">
      <mat-label>Billing address</mat-label>
      <input
        matInput
        matGoogleMapsAutocomplete
        (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
        (onLocationSelected)="onAddressLocationSelected($event)">
    </mat-form-field>

    <!--    secondary/cancel button -->

    <button class="button-secondary" mat-flat-button>
      <mat-icon style="transform: rotate(90deg)">sync</mat-icon>
      <div class="button-text">Reset</div>
    </button>

    <!--    primary button -->

    <button class="button-primary" mat-flat-button>
      <mat-icon>check</mat-icon>
      <div class="button-text">Save Changes</div>
    </button>

    <!--    action button-->

    <button class="button-action" mat-flat-button>
      <div class="button-text">Reset Password</div>
    </button>

    <!--    in page tabs -->

    <mat-tab-group class="custom-info-tab">
      <mat-tab label="Tab"></mat-tab>
      <mat-tab label="Tab"></mat-tab>
      <mat-tab label="Tab"></mat-tab>
    </mat-tab-group>


<!--    chips form field-->

    <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
      <mat-label>Lease Status</mat-label>
      <input matInput disabled>
      <div matPrefix class="warn-chip ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [class.material-icons-outlined]="true">check_circle</mat-icon>
        </div>
        <span class="footer-default">Planned</span>
      </div>
    </mat-form-field>

    <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
      <mat-label>Lease Status</mat-label>
      <input matInput disabled>
      <div matPrefix class="success-chip ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [class.material-icons-outlined]="true">check_circle</mat-icon>
        </div>
        <span class="footer-default">Approved</span>
      </div>
    </mat-form-field>

    <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
      <mat-label>Lease Status</mat-label>
      <input matInput disabled>
      <div matPrefix class="success-chip ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [class.material-icons-outlined]="false">check_circle</mat-icon>
        </div>        <span class="footer-default">Active</span>
      </div>
    </mat-form-field>

    <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
      <mat-label>Lease Status</mat-label>
      <input matInput disabled>
      <div matPrefix class="disabled-chip ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [class.material-icons-outlined]="false">check_circle</mat-icon>
        </div>
        <span class="footer-default">Expired</span>
      </div>
    </mat-form-field>

    <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
      <mat-label>Lease Status</mat-label>
      <input matInput disabled>
      <div matPrefix class="error-chip ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [class.material-icons-outlined]="false">cancel</mat-icon>
        </div>
        <span class="footer-default">Terminated</span>
      </div>
    </mat-form-field>
    <mat-icon class="material_icons_outline">check_circle</mat-icon>


  </div>

<!--table header  -->

  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Roles and Permissions</span>
    <div fxLayoutGap="10px" fxFlexOffset="50px">
      <button class="button-action" mat-flat-button (click)="addRow()"><div class="button-text">Add</div></button>
      <button class="button-action" mat-flat-button (click)="removeRows()"><div class="button-text">Remove</div></button>
    </div>
  </div>

<!--table card -->

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">

    <span class="heading-default">Retailer Information</span>

<!--    table filters-->

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px" fxFlexOffset="20px" *ngIf="!editRow">
      <mat-form-field class="custom-text-field ms-3" appearance="outline" floatLabel="always">
        <mat-label>Company Name</mat-label>
        <input matInput type="text" placeholder="Search here">
      </mat-form-field>
      <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
        <mat-label>Retailer</mat-label>
        <input matInput type="text" placeholder="Search here">
      </mat-form-field>
    </div>

<!--    edit row-->

    <div class="edit-table-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" fxFlexOffset="20px" *ngIf="editRow">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div class="body-1-primary">NEW_ID</div>
        <mat-form-field class="custom-text-field ms-3" appearance="outline" floatLabel="always">
          <mat-label>Company Name</mat-label>
          <input matInput type="text" [value]="editRow.companyName" placeholder="Search here">
        </mat-form-field>

        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Retailer</mat-label>
          <input matInput type="text" [value]="editRow.retailer" placeholder="Search here">
        </mat-form-field>

        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Email</mat-label>
          <input matInput type="text" [value]="editRow.email" placeholder="Search here">
        </mat-form-field>

        <mat-form-field class="custom-select-field" appearance="outline">
          <mat-label>Curation Status</mat-label>
          <mat-select class="custom-select-field-value" [value]="editRow.curationStatus">
            <mat-option class="custom-select-field-option" [value]="'one'">Choice one</mat-option>
            <mat-option class="custom-select-field-option" [value]="'two'">Choice two</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <button class="button-primary" mat-flat-button>
          <mat-icon>check</mat-icon>
          <div class="button-text">Save</div>
        </button>

        <button class="button-close-top" mat-icon-button (click)="editRow = null">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

    </div>

<!--    table-->

    <table mat-table class="custom-table" fxFlexOffset="25px" matSort
           [dataSource]="normalTableDatasource" >
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="normalTableColumns.length"> Retailers </th>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="custom-table-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? normalTableSelection.toggle(row) : null"
                        [checked]="normalTableSelection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="uuid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="retailer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Retailer </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailer, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="website">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Website </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element" (click)="openLinkInNewTab(element.website)"> {{utils.displayString(element.website, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="socialLink">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Social Link </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element" (click)="openLinkInNewTab(element.socialLink)"> {{utils.displayString(element.socialLink, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.email"
            *matCellDef="let element"> {{utils.displayString(element.email, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="curationStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Curation Status </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayStatus(element.curationStatus)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="normalTableColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: normalTableColumns;"></tr>
    </table>

<!--    paginator-->
    <mat-paginator #paginator class="custom-paginator"
                   [length]="normalTableDatasource.data.length"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

<!--  half & half table -->

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">

    <span class="heading-default">Retailer user roles and permissions</span>

    <div fxLayout="row" fxFlexOffset="25px">
      <table mat-table class="custom-table half-table" [dataSource]="hnhTableDataSource1Displayed" fxFlex="50">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="hnhTableColumns1.length"> Permissions </th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.description ? utils.displayString(element.description, 20) : null}} </td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? normalTableSelection.toggle(row) : null"
                          [checked]="normalTableSelection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="hnhTableColumns1"></tr>
        <tr mat-row class="custom-table-row btn-cursor" (click)="setSelection1(row)"
            [class.selected-row]="hnhTableSelection1 === row.id"
            *matRowDef="let row; columns: hnhTableColumns1;"></tr>
      </table>

      <table mat-table class="custom-table half-table" [dataSource]="hnhTableDataSource2" fxFlex="50">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="hnhTableColumns2.length"></th>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" *ngIf="row.userId"
                          (change)="$event ? hnhTableSelection2.toggle(row) : null"
                          [checked]="hnhTableSelection2.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef> User Id </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="userEmail">
          <th mat-header-cell *matHeaderCellDef> User Email </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.userEmail"
              *matCellDef="let element"> {{element.userEmail ? utils.displayString(element.userEmail, 30) : null}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="hnhTableColumns2"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: hnhTableColumns2;"></tr>
      </table>
    </div>

    <mat-paginator #paginator class="custom-paginator"
                   [length]="hnhTableDataSource1.data.length"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
