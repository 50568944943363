<div style="background-color: white" fxLayout="column">

  <app-page-header [banner]="true" [title]="'Performance Report'" [subtitle]="'Explore the report for a great overview of your lead\'s statistics!'"></app-page-header>

  <div class="page-padding" fxLayout="column" fxLayoutGap="30px">

    <app-dashboards-best-stats [bestMonthlyStats]="bestMonthlyStats"></app-dashboards-best-stats>

    <app-dashboards-chart-section sectionName="Sales Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="0"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="salesPerformanceChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Sales Comparison"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [pickerType]="'month'"
                                  [(date1)]="month1"
                                  [(date2)]="month2"
                                  [dateFilterType]="'picker'"
                                  [chart1]="salesComparisonChart"
                                  (datePickerClosed)="datePickerUpdated()">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Category Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="1"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="salesByIndustryChart"
                                  [chart2]="tradingDensityByIndustryChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

<!--   Top Performers Table-->

    <div fxLayout="column" fxFlexOffset="25px">
      <div fxLayout="row" fxLayoutAlign="space-between start">

        <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="30">
          <div class="title-small">Top Performers</div>
          <mat-icon>more_vert</mat-icon>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="70">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="heading-default">Over this period</div>
            <app-custom-date-filter [customDateRangePresets]="customDateRangePresets2"
                                    [initialPreset]="'past_month'"
                                    [showBorder]="false"
                                    (dateRange)="getDateRange($event, 2)"></app-custom-date-filter>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="heading-default">Of this percentage of total sales</div>
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 190px;">
              <mat-select class="custom-select-field-value" placeholder="Select" [(value)]="topFilter" (selectionChange)="loadTopPerformersTable()">
                <mat-option class="custom-select-field-option"  *ngFor="let percent of topPercentSalesOptions" [value]="percent">{{percent + '%'}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxFlexOffset="20px">
        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="subtitle-default">Top Performers By Sales</span>
          <table mat-table class="dashboard-table" #sortSales matSort
                 [dataSource]="topPerformersBySalesDataSource"
                 *ngIf="!topPerformersLoading; else canvasLoadingSpinner">

            <ng-container matColumnDef="retailerLogo">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element">
                <mat-icon class="material-icons-outlined">account_circle</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="retailerCompanyName">
              <th mat-header-cell *matHeaderCellDef> Retailer </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailerCompanyName, 20)}} </td>
            </ng-container>

            <ng-container matColumnDef="industryCategoryCode">
              <th mat-header-cell *matHeaderCellDef> Industry </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ this.dashboardUtils.getIndustryName(element.industryCategoryCode) }} </td>
            </ng-container>

            <ng-container matColumnDef="saleAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayRandAmount(element.saleAmount) }} </td>
            </ng-container>

            <ng-container matColumnDef="contributingPercentage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Contributing to total sales </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayNumber(element.contributingPercentage) }}% </td>
            </ng-container>

            <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumnsBySales"></tr>
            <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumnsBySales;"></tr>
          </table>
        </div>

        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="subtitle-default">Top Performers By Trading Density</span>
          <table mat-table class="dashboard-table" #sortTd matSort
                 [dataSource]="topPerformersByTradingDensityDataSource"
                 *ngIf="!topPerformersLoading; else canvasLoadingSpinner">

            <ng-container matColumnDef="retailerLogo">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element">
                <mat-icon class="material-icons-outlined">account_circle</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="retailerCompanyName">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailerCompanyName, 20)}} </td>
            </ng-container>

            <ng-container matColumnDef="industryCategoryCode">
              <th mat-header-cell *matHeaderCellDef> Industry </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ this.dashboardUtils.getIndustryName(element.industryCategoryCode) }} </td>
            </ng-container>

            <ng-container matColumnDef="tradingDensity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Trading Density </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayRandAmount(element.tradingDensity) }} /m<sup>2</sup> </td>
            </ng-container>

            <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumnsByTradingDensity"></tr>
            <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumnsByTradingDensity;"></tr>
          </table>

          <mat-paginator #topPerformersPaginator class="custom-paginator"
                         [length]="topPerformersByTradingDensityDataSource ? topPerformersByTradingDensityDataSource.data.length : 0"
                         [pageSize]="5"
                         [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>

<!--   Top Performers Table-->

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Times & Transactions"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="3"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="busiestSalesTimesChart"
                                  [chart2]="busiestSalesDaysChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

<!--    <mat-divider fxFlexOffset="20px"></mat-divider>-->

<!--    <app-dashboards-chart-section sectionName="Footfall Statistics"-->
<!--                                  [customDateRangePresets]="customDateRangePresets1"-->
<!--                                  [initialPreset]="'this_year'"-->
<!--                                  [dateRangeIndex]="4"-->
<!--                                  [dateFilterType]="'custom-range'"-->
<!--                                  [chart1]="footfallStatisticsChart"-->
<!--                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">-->
<!--    </app-dashboards-chart-section>-->

  </div>

  <ng-template #canvasLoadingSpinner>
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>
  </ng-template>
</div>
