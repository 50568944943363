import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PageOffering} from '../../../../../core/model/side-nav.model';
import {LoadingService} from "../../../../../core/services/loading.service";
import {CurrentContextService} from '../../../../../core/services/security/current-context.service';
import {UtilsService} from "../../../../../shared/services/utils.service";
import {
  ProductSupplier,
  SupplierSourceConfig,
  TransactionSource,
  TransactionSourceConfig
} from '../../../leasing.model';
import {LeasePosSettingsComponent} from "../lease-pos-settings.component";

@Component({
  selector: 'app-pos-software-settings-dialog',
  templateUrl: './pos-software-settings-dialog.component.html',
  styleUrls: ['./pos-software-settings-dialog.component.scss']
})
export class PosSoftwareSettingsDialogComponent {
  vendConfig?: TransactionSourceConfig;
  supplierConfig?: SupplierSourceConfig;
  offering: PageOffering;
  isEditing: boolean = false;
  canAccess: boolean = false;

  vendConfigs: {label: string, code: string, uuid: string}[] = [];
  availableSuppliers: ProductSupplier[] = [];
  assignedSuppliers: ProductSupplier[] = [];
  offeringConfig: boolean = false;

  parentComponent: LeasePosSettingsComponent;

  posType: TransactionSource = TransactionSource.VEND;

  posTypes = [
    { label: 'Lightspeed Retail X-Series (Vend)', source: TransactionSource.VEND },
    { label: 'Lightspeed Retail X-Series (Vend) Supplier', source: TransactionSource.VEND_SUPPLIER_OFFERING },
    { label: 'Lightspeed Retail X-Series (Vend) Supplier', source: TransactionSource.VEND_SUPPLIER_LEASE },
    { label: 'Poster', source: TransactionSource.POSTER}
  ];
  allowedPosTypes: {label: string, source: TransactionSource}[] = [];

  loading$ = this.loader.loading$;

  constructor(public dialogRef: MatDialogRef<PosSoftwareSettingsDialogComponent>,
              public currentContext: CurrentContextService,
              public utils: UtilsService,
              public loader: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.posType = data.posType;
    this.allowedPosTypes = this.posTypes.filter(f => data.allowedPosTypes.includes(f.source));
    this.parentComponent = data.parentComponent;
    this.offeringConfig = data.offeringConfig;

    if (data.vendConfig) {
      this.isEditing = true;
      this.vendConfig = data.vendConfig;
      this.vendConfig!.paymentSourceName = this.posType;
    } else {
      this.isEditing = false;
      this.vendConfig = new TransactionSourceConfig();
      this.vendConfig!.paymentSourceName = this.posType;
    }

    if (data.supplierConfig) {
      this.isEditing = true;
      this.supplierConfig = data.supplierConfig;
    } else {
      this.isEditing = false;
      this.supplierConfig = new SupplierSourceConfig();
    }

    if (data.paymentConfigUuid) {
      this.supplierConfig!.paymentConfigUuid = data.paymentConfigUuid;
      this.loadConfigSuppliers(this.supplierConfig?.paymentConfigUuid!, this.posType);
    }

    if (this.isEditing && this.supplierConfig && this.posType == TransactionSource.VEND_SUPPLIER_OFFERING) {
      this.loadConfigSuppliers(this.supplierConfig?.paymentConfigUuid!, this.posType);
    }

    if (this.isEditing && this.supplierConfig && this.supplierConfig.suppliers) {
      this.assignedSuppliers = this.supplierConfig.suppliers!.map(m => m);
    }

    this.offering = data.offering;
    this.canAccess = this.currentContext.checkUserFeatures(['leasing_internal_admin'], this.offering.offeringUuid);

    if (data.allowedPosTypes.includes(TransactionSource.VEND_SUPPLIER_OFFERING)) this.loadOfferingPaymentConfigs();

    // if (this.isEditing && this.posType == TransactionSource.VEND_SUPPLIER_OFFERING) {
    //   this.loadConfigSuppliers(this.supplierConfig?.paymentConfigUuid, this.posType);
    // }
  }

  saveChanges(): void {
    this.dialogRef.close({save: true, vendConfig: this.vendConfig, supplierConfig: this.supplierConfig});
  }

  cancelChanges(): void {
    this.dialogRef.close({save: false, vendConfig: null})
  }

  close() {
    this.dialogRef.close({save: false, vendConfig: null});
  }

  loadOfferingPaymentConfigs() {
    this.vendConfigs = this.parentComponent.offeringPaymentConfigs.map(m => {
      const displayName = JSON.parse(m.transactionSourceConfig!)['displayName'];
      const domainPrefix = JSON.parse(m.transactionSourceConfig!)['domainPrefix'];
      return { label: displayName, code: domainPrefix, uuid: m.uuid! };
    })
  }

  loadConfigSuppliers(uuid: string, source: TransactionSource) {
    if (source == TransactionSource.VEND_SUPPLIER_LEASE) this.parentComponent.loadPosSuppliers(uuid);
    if (source == TransactionSource.VEND_SUPPLIER_OFFERING) this.parentComponent.loadOfferingSuppliers(uuid);
  }

  get canSave() {
    if ((this.posType == TransactionSource.VEND || this.posType == TransactionSource.POSTER) && this.vendConfig) {
      return !!this.vendConfig.paymentTypeName
        && !!this.vendConfig.paymentSourceName
        && !!this.vendConfig.domainPrefix
        && !!this.vendConfig.outletId
        && !!this.vendConfig.accessToken
        && this.vendConfig.domainPrefix.trim().length != 0
        && this.vendConfig.outletId.trim().length != 0
        && this.vendConfig.accessToken.trim().length != 0
    } else {
      if (this.supplierConfig) {
        return !!this.supplierConfig.paymentConfigUuid
          && !!this.supplierConfig.suppliers
          && this.supplierConfig.suppliers.length > 0
      }
    }
    return false;
  }

  get canEdit() {
    return ((this.posType != TransactionSource.VEND && this.posType != TransactionSource.POSTER)) || (this.canAccess);
  }

  protected readonly TransactionSource = TransactionSource;
}
