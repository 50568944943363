import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Question,
  QuestionGroup,
  RangeOption,
  RangeType,
  ScorePassType
} from '../../../../../core/model/retailer/application-form.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlType} from '../../add-questions/question-components.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {distinctUntilChanged, pairwise} from "rxjs";

@Component({
  selector: 'app-question-weight-config',
  templateUrl: './question-weight-config.component.html',
  styleUrls: ['./question-weight-config.component.scss']
})
export class QuestionWeightConfigComponent implements OnInit {

  @Output() questionGroupChange: EventEmitter<QuestionGroup> = new EventEmitter<QuestionGroup>();
  @Output() saveForm = new EventEmitter<boolean>();
  @Output() publishForm = new EventEmitter<boolean>();
  @Output() previewForm = new EventEmitter<boolean>();

  passAndPreferOptions: string[] = [];
  failOptions: string[] = [];
  importanceForm: FormGroup;
  selectControlTypes: ControlType[] = [
    ControlType.SELECT,
    ControlType.CHOICE
  ];
  passNumberGroup: FormGroup;

  constructor() {
    this.importanceForm = new FormGroup({
      importanceRating: new FormControl(0),
      qgUuid: new FormControl(null)
    });

    this.importanceForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]) => {
      if (this._questionGroup && (prev.qgUuid == next.qgUuid && prev.importanceRating != next.importanceRating)) {
        this._questionGroup!.importanceRating = next.importanceRating;
        this.rankAnswers();
      }
    });

    this.passNumberGroup = new FormGroup({
      aboveOrBelow: new FormControl('ABOVE', Validators.required),
      numberValue: new FormControl(0, Validators.required),
      qgUuid: new FormControl(null)
    });

    this.passNumberGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        if (prev.qgUuid == next.qgUuid && (prev.aboveOrBelow != next.aboveOrBelow || prev.numberValue != next.numberValue)) {
          this.rankAnswers();
        }
    });


  }

  _questionGroup: QuestionGroup | null = null;

  @Input() set questionGroup(value: QuestionGroup | null) {
    this._questionGroup = value;
    this.passAndPreferOptions = [];
    this.failOptions = [];
    this.importanceForm.patchValue({importanceRating: value?.importanceRating, qgUuid: this._questionGroup?.uuid});

    if (this.isNumberControlType()) {
      this.passNumberGroup.patchValue({
        aboveOrBelow: this._questionGroup?.questions[0].questionTemplate.controlPayload.scorePassType,
        numberValue: this._questionGroup?.questions[0].questionTemplate.controlPayload.passingValue,
        qgUuid: this._questionGroup?.uuid
      })
    }
    if (this.isNumberRangeControlType()) {
      this.passNumberGroup.patchValue({
        aboveOrBelow: this._questionGroup?.questions[0].questionTemplate.controlPayload.scorePassType,
        numberValue: this._questionGroup?.questions[0].questionTemplate.controlPayload.passingValue,
        qgUuid: this._questionGroup?.uuid
      })
    }
    this.populateOptionLists();
  }

  ngOnInit(): void {
    if (this._questionGroup && this._questionGroup.importanceRating != null) {
      this.importanceForm.get('importanceRating')?.setValue(this._questionGroup.importanceRating);
    }

    this.populateOptionLists();
  }

  saveFormTemplate(): void {
    if (this._questionGroup) {
      this.rankAnswers();
    }
    this.saveForm.emit(true);
  }


  publishFormTemplate(): void {
    this.publishForm.emit(true);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.rankAnswers();
  }

  formatRateLabel = (value: number) => {
    switch (value) {
      case 0:
        return 'Not important';
      case 1:
        return 'Less important';
      case 2:
        return 'Moderately important';
      case 3:
        return 'Very important';
      case 4:
        return 'Extremely important';
      default:
        return 'Not important';
    }
  }

  isSelectTypeControl(): boolean {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      return (this.selectControlTypes.includes(question.questionTemplate.controlType));
    }
    return false;
  }

  isNumberRangeControlType(): boolean {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      return question.questionTemplate.controlType === ControlType.RANGE && question.questionTemplate.controlPayload.rangeType === RangeType.NUMBER;
    }
    return false;
  }

  isNumberControlType(): boolean {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      return question.questionTemplate.controlType == ControlType.NUMBER;
    }
    return false;
  }

  isDateControlType(): boolean {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      return question.questionTemplate.controlType === ControlType.RANGE && question.questionTemplate.controlPayload.rangeType === RangeType.DATE;
    }
    return false;
  }

  getNumberRangeMin(): number {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      if (question.questionTemplate.controlType == ControlType.NUMBER) return question.questionTemplate.controlPayload.min!;
      if (question.questionTemplate.controlType == ControlType.RANGE) return question.questionTemplate.controlPayload.start!;
    }
    return 0;
  }

  getNumberRangeMax(): number {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      if (question.questionTemplate.controlType == ControlType.NUMBER) return question.questionTemplate.controlPayload.max!;
      if (question.questionTemplate.controlType == ControlType.RANGE) return question.questionTemplate.controlPayload.end!;
    }
    return 0;
  }

  private populateOptionLists(): void {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question = this._questionGroup!.questions[0];
      switch (question.questionTemplate.controlType) {
        case ControlType.CHOICE: {
          const selected = question.questionTemplate.controlPayload.selectedOption;
          const unselected = question.questionTemplate.controlPayload.unselectedOption;
          if (selected!.weight == 0) this.failOptions.push(selected!.label);
          if (unselected!.weight == 0) this.failOptions.push(unselected!.label);
          if (selected!.weight != 0) this.passAndPreferOptions.push(selected!.label);
          if (unselected!.weight != 0) this.passAndPreferOptions.push(unselected!.label);
          break;
        }
        case ControlType.SELECT: {
          if (question.questionTemplate.controlPayload!.options) {
            let passOptionLabels: string[] = question.questionTemplate.controlPayload!.options.filter(option => {
              return (option.weight !== undefined && option.weight !== null && option.weight != 0)
            }).sort((option1, option2) => option2.weight! - option1.weight!)
              .map(options => options.label);
            let failOptionLabels: string[] = question.questionTemplate.controlPayload!.options.filter(option => {
              return (option.weight === undefined || option.weight === null || option.weight === 0)
            }).map(option => option.label);
            this.passAndPreferOptions.push(...passOptionLabels);
            this.failOptions.push(...failOptionLabels);
          }
          break;
        }
        case ControlType.RANGE: {
          if (question.questionTemplate.controlPayload.increments) {
            let passOptionLabels: string[] = question.questionTemplate.controlPayload.increments.filter(increment => {
              return (increment.weight !== undefined && increment.weight !== null && increment.weight != 0)
            }).map(options => options.label);
            let failOptionLabels: string[] = question.questionTemplate.controlPayload!.increments.filter(option => {
              return (option.weight === undefined || option.weight === null || option.weight === 0)
            }).map(option => option.label);
            this.passAndPreferOptions.push(...passOptionLabels);
            this.failOptions.push(...failOptionLabels);
          }
          break;
        }
      }
    }
  }

  private rankAnswers(): void {
    if (this._questionGroup && this._questionGroup!.questions.length == 1) {
      let question: Question = this._questionGroup.questions[0];
      switch (question.questionTemplate.controlType) {
        case ControlType.RANGE: {
          this.handleRangeRanking(question);
          break;
        }
        case ControlType.SELECT: {
          this.handleSelectQuestionRating(question);
          break;
        }
        case ControlType.CHOICE: {
          if (this._questionGroup.importanceRating != null) {
            const selected = question.questionTemplate.controlPayload.selectedOption;
            const unselected = question.questionTemplate.controlPayload.unselectedOption;

            this.failOptions.forEach(option => {
              const matchesSelected = option == selected!.label;
              const matchesUnselected = option == unselected!.label;
              if (matchesSelected) selected!.weight = 0;
              if (matchesUnselected) unselected!.weight = 0;
            });

            this.passAndPreferOptions.forEach((option: string, order: number) => {
              const weight = (this._questionGroup!.importanceRating! + 1) * 5 / (order + 1);
              const matchesSelected = option == selected!.label;
              const matchesUnselected = option == unselected!.label;
              if (matchesSelected) selected!.weight = weight;
              if (matchesUnselected) unselected!.weight = weight;
            });
          }
          break;
        }
        case ControlType.NUMBER: {
          if (this._questionGroup.importanceRating != null) {
            let passAnswer = this.passNumberGroup.get('aboveOrBelow')!.value;
            let passValue: number = this.passNumberGroup.get('numberValue')!.value;
            if (passAnswer === 'ABOVE') {
              question.questionTemplate.controlPayload.scorePassType = ScorePassType.ABOVE;
            } else {
              question.questionTemplate.controlPayload.scorePassType = ScorePassType.BELOW;
            }
            question.questionTemplate.controlPayload.passingValue = passValue;
          }
          break;
        }
        default:
          break;
      }
      this._questionGroup.questions[0] = question;
      this.questionGroupChange.emit(this._questionGroup);
    }
  }

  private handleSelectQuestionRating(question: Question): void {
    if (question.questionTemplate.controlPayload!.options) {
      this.failOptions.forEach(option => {

        let index: number = question.questionTemplate.controlPayload.options!.findIndex(((increment) => increment.label === option));
        if (index >= 0) {
          question.questionTemplate.controlPayload.options!.at(index)!.weight = 0;
        }
      });
      this.passAndPreferOptions.forEach((option: string, order: number) => {
        if (this._questionGroup?.importanceRating != null) {
          const weight = (this._questionGroup.importanceRating + 1) * 5 / (order + 1);
          let index: number | undefined = question.questionTemplate.controlPayload.options!.findIndex((increment) => increment.label === option);
          if (index >= 0) {
            question.questionTemplate.controlPayload.options![index]!.weight = weight;
          }
        }
      });
    }
  }

  private handleRangeRanking(question: Question): void {
    if (question.questionTemplate.controlPayload.increments && question.questionTemplate.controlPayload.rangeType == RangeType.NUMBER) {
      let passAnswer = this.passNumberGroup.get('aboveOrBelow')!.value;
      let passValue: number = this.passNumberGroup.get('numberValue')!.value;

      this._questionGroup!.questions[0].questionTemplate.controlPayload.scorePassType = passAnswer;
      this._questionGroup!.questions[0].questionTemplate.controlPayload.passingValue = passValue;

      let increments: RangeOption[] = (question.questionTemplate.controlPayload.increments);
      increments.forEach((increment: RangeOption) => {
        if (passAnswer === 'ABOVE') {
          if (increment.start >= passValue) {
            increment.weight = (this._questionGroup!.importanceRating! + 1) * 5;
          } else {
            increment.weight = 0;
          }
        } else {
          if (increment.start <= passValue) {
            increment.weight = (this._questionGroup!.importanceRating! + 1) * 5;
          } else {
            increment.weight = 0;
          }
        }
      });
      question.questionTemplate.controlPayload.increments = increments;

      this._questionGroup!.questions[0].questionTemplate.controlPayload.increments = [];
      increments.forEach(increment => {
        this._questionGroup!.questions[0].questionTemplate.controlPayload.increments!.push( {
          label: increment.label,
          weight: increment.weight,
          start: increment.start,
          end: increment.end,
          valueCode: increment.valueCode,
          incrementSize: increment.incrementSize
        });
      });
    }
  }

  getRatingText(): any {
    switch (this.importanceForm.get('importanceRating')?.value) {
      case 0:
        return 'Not important';
      case 1:
        return 'Less important';
      case 2:
        return 'Moderately important';
      case 3:
        return 'Very important';
      case 4:
        return 'Extremely important';
      default:
        return 'Not important';
    }
  }

  // FOR ABOVE BELOW CHECKS
  // private calculateRangeAboveBelow(): void {
  //   if (this._questionGroup?.questions && this._questionGroup.questions.length == 1) {
  //     let lowestStart = 0;
  //     let highestStart = 0;
  //     let question: Question = this._questionGroup.questions[0];
  //     question.questionTemplate.controlPayload.increments!.filter(increment => increment.weight === 0)
  //       .forEach((increment: RangeOption) => {
  //         if (lowestStart > increment.start) {
  //           lowestStart = increment.start;
  //         }
  //       });
  //     question.questionTemplate.controlPayload.increments!.filter(increment => increment.weight !== 0)
  //       .forEach((increment: RangeOption) => {
  //         if (highestStart < increment.start) {
  //           highestStart = increment.start;
  //         }
  //       });
  //     this.passNumberGroup.get('numberValue')!.setValue(lowestStart);
  //     if (highestStart > lowestStart) {
  //       this.passNumberGroup.get('aboveOrBelow')!.setValue('ABOVE');
  //     } else {
  //       this.passNumberGroup.get('aboveOrBelow')!.setValue('BELOW');
  //     }
  //   }
  // }

  sendPreviewForm(): void {
    this.previewForm.emit(true);
  }
}
