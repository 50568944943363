<div fxLayout="column" fxLayoutGap="15px">
  <div class="content-padding" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="subtitle-default">Spaces</div>
    <button class="button-secondary button-outline" mat-flat-button (click)="addNewSpaceEvent()">
      <span class="button-text">Create space</span>
    </button>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxLayoutGap="15px">
    <form [formGroup]="filters" (keydown.enter)="applyFilters()" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <span class="heading-default"> Filters </span>

        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field class="custom-text-autofill small-toggle" appearance="outline" floatLabel="always">
            <mat-label></mat-label>
            <input matInput disabled>
            <span matPrefix class="body-1-regular-1 ms-3">{{filters.value['locked'] ? 'All' : 'Active'}}</span>
            <div matSuffix class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-slide-toggle [checked]="showExpired()" (change)="updateShowExpired($event.checked)"
                                formControlName="locked"
                                color="primary"></mat-slide-toggle>
            </div>
          </mat-form-field>
          <button *ngIf="filters.pristine" (click)="applyFilters()" class="form-icon-button" mat-icon-button>
            <mat-icon class="material-icons-outlined">search</mat-icon>
          </button>
          <button *ngIf="filters.dirty" (click)="resetFilters()" class="form-icon-button" mat-icon-button>
            <mat-icon class="material-icons-outlined">filter_alt_off</mat-icon>
          </button>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="15px">
        <mat-form-field class="custom-text-field my-2" appearance="outline" floatLabel="always">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Search here" formControlName="spaceName" name="spaceName">
        </mat-form-field>

        <mat-form-field class="custom-text-field my-2" appearance="outline" floatLabel="always">
          <mat-label>Size</mat-label>
          <input matInput placeholder="Search here" formControlName="spaceSize" name="spaceSize">
        </mat-form-field>

        <mat-form-field class="custom-date-picker my-2" appearance="outline" floatLabel="always">
          <mat-label>Available from</mat-label>
          <input matInput [matDatepicker]="startDatePicker" placeholder="Select a date" formControlName="startDate"
                 name="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="custom-date-picker my-2" appearance="outline" floatLabel="always">
          <mat-label>Available Until</mat-label>
          <input matInput [matDatepicker]="endDatePicker" placeholder="Select a date" formControlName="endDate"
                 name="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="custom-select-field my-2" appearance="outline" floatLabel="always" style="width: 250px;">
          <mat-label>Industry And Product Collection</mat-label>
          <mat-select class="custom-select-field-value" [multiple]="true" formControlName="districtSpacesIndustryCodes">
              <mat-option class="custom-select-field-option" *ngFor="let ind of allIndustries" [value]="ind.code">{{ ind.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef
            [attr.colspan]="displayedColumns.length"> {{filters.value['locked'] ? 'All spaces' : 'Active spaces'}} </th>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let space">{{ space.spaceName }}</td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Size</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let space">{{ space.spaceSize }}</td>
      </ng-container>
      <ng-container matColumnDef="availableFrom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Available from</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let space">{{ space.startDate | date }}</td>
      </ng-container>
      <ng-container matColumnDef="availableUntil">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Available until</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let space">{{ space.endDate | date }}</td>
      </ng-container>
      <ng-container matColumnDef="industry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Industry</th>
        <td mat-cell class="custom-table-cell"
            *matCellDef="let space">{{ getIndustryLabels(space.districtSpacesIndustries) }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let space">{{ space.locked ? 'Expired' : 'Active' }}</td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row btn-cursor" *matRowDef="let space; columns: displayedColumns"
          [class.selected-row]="space.id === selectedRowId"
          (click)="spaceSelectedEvent(space)"></tr>

    </table>
    <mat-paginator class="custom-paginator"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   [pageSize]="pageSize || 25"
                   [pageIndex]="pageIndex || 0"
                   [length]="totalElements()"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>

  <mat-divider fxFlexOffset="30px"></mat-divider>
</div>
