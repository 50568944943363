import {Injectable} from "@angular/core";
import {ColorTheme, defaultColorTheme} from "./theme.model";
import {ColorUtilsService} from "./colorUtils.service";
import {CurrentContextService} from "../app/core/services/security/current-context.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private colorUtils: ColorUtilsService, private currentContext: CurrentContextService) {
    this.setThemeColors(null);

    this.currentContext.landlord().subscribe(landlord => {
      if (landlord && landlord.theme) {
        this.setThemeColors(landlord.theme)
      } else {
        this.setThemeColors(null)
      }
    })
  }
  public setThemeColors(theme: ColorTheme | null): void {
    if (theme) {
      document.documentElement.style.setProperty('--primary-color', theme.primary);
      document.documentElement.style.setProperty('--primary-color-h', this.colorUtils.hexToHsl(theme.primary).h.toString());
      document.documentElement.style.setProperty('--primary-color-s', this.colorUtils.hexToHsl(theme.primary).s.toString() + '%');
      document.documentElement.style.setProperty('--primary-color-l', this.colorUtils.hexToHsl(theme.primary).l.toString() + '%');
      document.documentElement.style.setProperty('--secondary-color', theme.secondary);
    } else {
      document.documentElement.style.setProperty('--primary-color', defaultColorTheme.primary);
      document.documentElement.style.setProperty('--primary-color-h', this.colorUtils.hexToHsl(defaultColorTheme.primary).h.toString());
      document.documentElement.style.setProperty('--primary-color-s', this.colorUtils.hexToHsl(defaultColorTheme.primary).s.toString() + '%');
      document.documentElement.style.setProperty('--primary-color-l', this.colorUtils.hexToHsl(defaultColorTheme.primary).l.toString() + '%');
      document.documentElement.style.setProperty('--secondary-color', defaultColorTheme.secondary);
    }
  }
}
