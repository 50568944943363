import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CurrentContextService} from './current-context.service';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PathContextGuardService implements CanActivate {

  constructor(private context: CurrentContextService,
              private auth: AuthenticationService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let clientId = route.paramMap.get('clientId')?.toLowerCase();
    if (clientId != this.context.getCurrentLandlordCode()?.toLowerCase() && clientId != this.context.getCurrentLandlordId()?.toLowerCase()) {
      return this.doLogout(state, clientId);
    }
    return true;
  }

  private doLogout(state: RouterStateSnapshot, clientId: string | undefined): boolean {
    this.auth.logout();
    this.router.navigate([`${clientId}/login`, {returnUrl: state.url}])
      .catch((error: string) => {
        console.log(error)
      });
    return false;
  }
}
