import {SelectionModel} from "@angular/cdk/collections";
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {lastValueFrom, Observable, of, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {
  GrantedModuleRole,
  GrantedUser,
  GrantForConsoleRequest,
  RevokeGrant
} from "../../../../../core/model/user-access.model";
import {UserGrant} from "../../../../../core/model/user-grant.model";
import {User} from "../../../../../core/model/user.model";
import {CurrentContextService} from "../../../../../core/services/security/current-context.service";
import {UserAccessService} from "../../../../../core/services/user-access.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {Role} from "./retailer-roles-and-permissions.model";

@Component({
  selector: 'app-retailer-roles-and-permissions',
  templateUrl: './retailer-roles-and-permissions.component.html',
  styleUrls: ['./retailer-roles-and-permissions.component.scss']
})
export class RetailerRolesAndPermissionsComponent implements OnInit, AfterViewInit {
  @Input() id!: string;
  @Input() offering!: PageOffering;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns1: string[] = ['select', 'id', 'firstname', 'lastname', 'email'];
  dataSource = new MatTableDataSource<GrantedUser>([]);

  selectedUser: number | null = null;
  selection = new SelectionModel<GrantedUser>(true, []);
  newUser: User | null = null;

  addUserGrant: boolean = false;
  revokeUserGrant: boolean = false;

  searchTerm = new FormControl('');
  loadingSearch: boolean = false;
  allUsers: User[] = [];

  RETAILER_RBAC_MANAGER = 'retailer_rbac_manager';
  managerRoleId = 0;

  constructor(public utils: UtilsService,
              public context: CurrentContextService,
              private paginatorService: PaginatorService,
              private userAccessService: UserAccessService) {
  }

  ngOnInit() {
    this.loadRetailerManagers();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadRetailerManagers()))//load paged data here//
      .subscribe();
  }

  loadRetailerManagers() {
    this.userAccessService.getAll('/granted-roles', {
      size: '1000',
      page: '0',
      sort: 'roleId,asc'
    }).pipe(
      catchError(() => of([]))
    )
      // @ts-ignore
      .subscribe((value: {content: GrantedModuleRole[]}) => {
        this.managerRoleId = value.content.filter(f => f.roleCode == this.RETAILER_RBAC_MANAGER)[0].roleId;
        const grantedUsers = value.content
          .filter(f => f.roleCode == this.RETAILER_RBAC_MANAGER && f.grantPrincipleId == this.id)
          .flatMap(m => m.users);

        console.log(value.content
          .filter(f => f.roleCode == this.RETAILER_RBAC_MANAGER && f.grantPrincipleId == this.id));

        const managerUsers = this.createSet(grantedUsers) as GrantedUser[];
        this.dataSource = new MatTableDataSource<GrantedUser>(managerUsers);

        const managerIds = managerUsers.map(m => m.userId);

        const pipelineUsers = value.content
          .filter(f => f.roleCode.includes('pipeline'))
          .flatMap(m => m.users)
          .map(m => new User({email: m.userEmail, id: m.userId, firstname: m.userFirstname, lastname: m.userLastname}))
          .filter(f => !managerIds.includes(f.id!));

        this.allUsers = this.createSet(pipelineUsers) as User[];
    });
  }

  selectRole(role: Role) {
    this.selectedUser = role.id;
  }

  selectNewUser(event: MatAutocompleteSelectedEvent) {
    this.newUser = event.option.value;
  }

  addUserToRole() {
    this.addUserGrant = true;
  }

  revokeUserRole() {
    this.revokeUserGrant = true;
  }

  revokeResponse(confirm: boolean) {
    confirm ? this.confirmRevoke() : this.cancelRevoke();
  }

  saveUserGrant() {
    const request: GrantForConsoleRequest = {
      offeringUuid: this.offering.offeringUuid,
      principleTypes: 'RETAILER',
      principleId: this.id,
      user: this.newUser!,
      roleCodes: [this.RETAILER_RBAC_MANAGER]
    }

    lastValueFrom(this.userAccessService.saveAccessRequest(request))
      .then(() => {
        this.clearNewUserGrant();
        this.loadRetailerManagers();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  confirmRevoke(): void {
    let request: RevokeGrant[] = [];
    this.selection.selected
      .forEach((selectedGrantUser) => {
        request.push({
          grantId: selectedGrantUser.grantId!,
          userId: selectedGrantUser.userId,
          offeringUuid: this.offering.offeringUuid,
          principleId: this.id,
          roleId: this.managerRoleId
        });
      });
    lastValueFrom(this.userAccessService.revokeAccess(request))
      .then(() => {
        this.revokeUserGrant = false;
        this.selection.clear();
        this.loadRetailerManagers();
      })
      .catch((error: string) => {
        console.log(error)
      });
  }

  clearNewUserGrant() {
    this.addUserGrant = false;
    this.newUser = null;
  }

  cancelRevoke(): void {
    this.revokeUserGrant = false;
  }

  loadUsers() {
    const searchTerm = this.searchTerm.value;
    if (searchTerm && searchTerm.length >= 3) {
      this.loadingSearch = true;
    }
  }

  displayFn(value: User) {
    if (value) {
      return value.id && value.firstname && value.lastname ? `${value.id}. ${value.firstname} ${value.lastname}` : '';
    }
    return '';
  }

  createSet(array: any[]): any[] {
    return Array.from(new Set(array
      .map(m => JSON.stringify(m))))
      .map(m => JSON.parse(m));
  }
}
