<div fxLayout="column">
  <app-page-header [section]="'Manage Listing'"
                   [title]="'Manage your public listing information'"
                   [description]="'The settings module allows you manage information around your retail space on \n'+
'the marketplace. This information automatically updates and will be seen by prospective retailers.'">
  </app-page-header>


  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column" fxLayoutGap="15px">
    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span class="subtitle-default">Links</span>
      </div>
    </div>

    <div class="info-card-dark" fxLayout="column" fxLayoutGap="20px">
      <button color="accent" disabled mat-icon-button>
        <mat-icon class="material-icons-outlined">language</mat-icon>
      </button>
      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="heading-bold">Embed links</span>
        <button [cdkCopyToClipboardAttempts]="5" [cdkCopyToClipboard]="generateLinkToClipboard()" [disabled]="loading$ | async"
                class="button-action" mat-flat-button>
          <div class="button-text">Get link</div>
        </button>
      </div>
      <div>
        <span class="body-1-regular-2">This link can be used to embed application forms for your retail spaces on your website</span>
      </div>
    </div>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span class="subtitle-default">Listing View</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px">
      <div class="info-card" fxLayout="column" fxLayoutGap="15px">
        <span class="heading-default">My Value offering to prospective retailers</span>
        <mat-form-field class="custom-text-area-paragraph" [formGroup]="descriptionForm" appearance="outline"
                        floatLabel="always">
          <textarea (focusout)="updateDescription()" formControlName="description" id="descriptionInput" matInput
                    placeholder="Enter value proposition description to prospective retailers"
                    type="text"></textarea>
        </mat-form-field>
        <div class="description-icon-container mat-fab-icon-container full-width">
          <button (click)="toggleDescriptionEdit()" [disabled]="loading$ | async" class="mat-fab-background-primary" color="accent"
                  mat-mini-fab>
            <mat-icon class="material-icons-outlined"> edit</mat-icon>
          </button>
        </div>
      </div>
      <mat-card class="offering-image-card">
        <div class="mat-card-image-container">
          <img class="offering-image" src="{{propertyDisplayImage}}" alt="Photo of offering">
        </div>
        <mat-card-content fxFlexOffset="20px">
          <div class="upload-icon-container mat-fab-icon-container">
            <button (click)="showUploadDialog()" [disabled]="loading$ | async" class="mat-fab-background-primary" color="accent"
                    mat-mini-fab>
              <mat-icon class="material-icons-outlined"> photo_camera</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>

    </div>


    <mat-divider fxFlexOffset="30px"></mat-divider>


    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span class="subtitle-default">Property information</span>
      </div>
    </div>
    <div class="full-width" fxLayout="row" fxLayoutGap="20px">
      <div class="info-card">
        <span class="heading-default">My active industries to prospective retailers</span>
        <div class="industry-category-container">
          <div *ngFor="let category of industryCategories;" class="industry-category">
            <mat-slide-toggle (change)="toggleCategoryActive($event, category.id)" [checked]="getIndustryCheckedStatus(category)"
                              [disabled]="loading$ | async"
                              color="primary">{{category.name}}</mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="info-card">
        <span class="heading-default">My Trading Hours</span>
        <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutGap="20px">
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Monday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Monday')"></app-custom-time-range-picker>
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Friday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Friday')"></app-custom-time-range-picker>
          </div>
          <div fxLayout="row" fxLayoutGap="20px">
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Tuesday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Tuesday')"></app-custom-time-range-picker>
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Saturday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Saturday')"></app-custom-time-range-picker>
          </div>
          <div fxLayout="row" fxLayoutGap="20px">
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Wednesday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Wednesday')"></app-custom-time-range-picker>
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Sunday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Sunday')"></app-custom-time-range-picker>
          </div>
          <div fxLayout="row" fxLayoutGap="20px">
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Thursday', $event)"
                                          [tradingHours]="getTradingHoursForDay('Thursday')"></app-custom-time-range-picker>
            <app-custom-time-range-picker (timeRangeChange)="handleTradingHourChange('Public Holidays', $event)"
                                          [tradingHours]="getTradingHoursForDay('Public Holidays')"></app-custom-time-range-picker>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

