import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Notification, notifications} from "./notifications.model";
import * as moment from 'moment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  @Input() showAll!: boolean;
  @Output() closeNotifications = new EventEmitter<boolean>();
  notifications: Notification[] = notifications;

  constructor() {
  }

  messagesToDisplay(): Notification[] {
    if (this.showAll) {
      return this.notifications;
    }
    return this.notifications.filter(f => !f.read);
  }

  markAllAsRead(): void {
    this.notifications.forEach(ea => ea.read = true);
  }

  markAsRead(notification: Notification): void {
    notification.read = true;
  }

  toggleShowAll(): void {
    this.close(true);
  }

  close(switchView: boolean): void {
    this.closeNotifications.emit(switchView);
  }

  displayNotificationTime(createDate: string): string {
    const date = moment(createDate);
    return date.fromNow();
  }

}
