import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {BaseService} from '../../../shared/services/base.service';

import {environment} from 'src/environments/environment';
import {lastValueFrom, Observable} from 'rxjs';

import {RegisterUserRequest, User, UserUpdateRequest, VerifyOTPRequest} from '../../model/user.model';
import {UserFeaturesResponseInterface} from '../../interfaces/user-features-response.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  baseUrl: string = environment.apiSecurityHost + '/api/user';
  baseRbacUrl: string = environment.apiSecurityHost + '/console/rbac/user';

  constructor(private http: HttpClient) {
    super();
  }

  // register
  public registerNewUser(request: RegisterUserRequest): Observable<User> {
    const uri = this.baseUrl + '/register';
    return this.http.post<User>(uri, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:4200',
      }),
    });
  }

  public registerNewPortalUser(request: RegisterUserRequest): Observable<User> {
    const uri = this.baseRbacUrl + '/portal/register';
    return this.http.post<User>(uri, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:4200',
      }),
    });
  }

  public verifyNewUserAccount(request: VerifyOTPRequest): Observable<any> {
    const uri = this.baseUrl + '/verify';
    return this.http.post(uri, request, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public requestNewOTP(id: number): Observable<any> {
    const uri = this.baseUrl + '/' + id + '/verify/otp/resend';
    return this.http.post(uri, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  /* updateUserDetails(request: UserUpdateRequest): Observable<User> {
     return this.http.post<User>(this.baseUrl, request, this.options);
   }*/

  loadUser(userId: number): Observable<User> {
    const uri = this.baseUrl + '/' + userId;
    return this.http.get<User>(uri, this.options);
  }

  getUserFeaturesAndGrants(): Promise<UserFeaturesResponseInterface> {
    const uri = this.baseRbacUrl + '/location/features';
    let observable$ = this.http.get<UserFeaturesResponseInterface>(uri, this.options);
    return lastValueFrom(observable$)
  }

  updateUserDetails(request: UserUpdateRequest): Observable<User> {
    let uri = this.baseRbacUrl + '/manage/user-info'
    return this.http.post<User>(uri, request, this.options);
  }

  getAllowedLocationsForLandlord(landlordId: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiSecurityHost}/console/rbac/user/landlord/${landlordId}/granted-locations`);
  }
}
