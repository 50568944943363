import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '../../core/components/page-content/base-page.component';
import {TabManagementService} from '../../core/services/tab-management.service';
import {PageEventInterface} from '../../core/interfaces/page-event.interface';
import {BaseTemplateBuilderComponent} from './base-template-builder/base-template-builder.component';
import {UtilsService} from '../../shared/services/utils.service';

@Component({
  selector: 'app-internal-features-landing',
  styleUrls: ['./internal-features-landing.component.html'],
  templateUrl: './internal-features-landing.component.html'
})
export class InternalFeaturesLandingComponent extends BasePageComponent implements OnInit {


  constructor(injector: Injector,
              private tabsService: TabManagementService,
              private utilService: UtilsService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  openTemplateBuilder(): void {
    let payload: PageEventInterface = {
      componentToRegister: BaseTemplateBuilderComponent,
      pageName: 'Base Template Builder',
      pageHeader: 'Template Builder',
      data: {
        offerings: this.data.offerings
      },
      id: this.utilService.generateUuidWithPrefix(''),
      offeringId: '',
      sectionCode: 'template_builder',
      pageCode: 'template_builder'
    }
    this.tabsService.sendPageAddEvent(payload);
  }
}
