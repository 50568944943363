<div class="p-3 full-width" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="15px">
  <div class="full-width close-button-container" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-close-top" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" style="height: 64px;">
    <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="5px" class="page-logo"
         [ngStyle]="{'background-image': 'url(' + (data.user.profileImage ? data.user.profileImage : defaultLogo) + ')'}">
      <mat-icon (click)="openUploadProfilePicture()" class="camera-logo btn-cursor material-icons-outlined"
                fxFlexAlign="end">photo_camera
      </mat-icon>
    </div>

    <div fxLayout="column">
      <span *ngIf="!editingUsername" class="body-1-regular-1"
            style="font-weight: bold">{{data.user.firstname + ' ' + data.user.lastname}}</span>
      <form (ngSubmit)="updateUserProfile()" [formGroup]="userProfileFormGroup">
        <mat-form-field *ngIf="editingUsername" appearance="fill" class="custom-text-field no-underline-text-field"
                        floatLabel="always">
          <input formControlName="userNames" matInput placeholder="{{data.user.firstname + ' ' + data.user.lastname}}"
                 type="text"/>
        </mat-form-field>
      </form>
      <span class="body-3-default">{{utilsService.displayString(data.currentProperty, 25)}}</span>
    </div>

    <div>
      <mat-icon (click)="toggleEditUsername()" *ngIf="!editingUsername" class="edit-icon material-icons-outlined">edit
      </mat-icon>
    </div>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="15px" hidden>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-icon>notifications</mat-icon>
      <span>Notifications</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-slide-toggle (change)="toggleChanged($event)" checked color="primary"></mat-slide-toggle>
    </div>
  </div>

  <mat-divider class="full-width" fxFlexOffset="5px"></mat-divider>

  <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
    <div class="body-3-default">Client</div>
    <mat-form-field appearance="outline" class="custom-select-field">
      <mat-select (selectionChange)="selectedPropertyChanged($event)" [disabled]="isPropChangeDisabled()"
                  [ngModel]="selectedProperty.value" class="custom-select-field-value">
        <div *ngFor="let property of data.accessibleTenants">
          <mat-option class="custom-select-field-option" [value]="property.value">{{property.name}}</mat-option>
        </div>

      </mat-select>
    </mat-form-field>
  </div>

  <div class="dialog-row-container" fxLayoutAlign="center center">
    <form [formGroup]="passwordResetForm" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="18px">
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="body-3-default">Email</p>
        <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
          <input formControlName="email" matInput type="text"/>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="body-3-default">Password</p>
        <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
          <input
            [type]="hideFirstPassword ? 'password' : 'text'"
            formControlName="password" matInput required/>
          <mat-hint (click)="toggleChangePassword()" *ngIf="!isChangingPassword" class="btn-cursor footer-primary full-width" style="text-align: end">Change password?</mat-hint>
          <mat-icon (click)="hideFirstPassword = !hideFirstPassword" *ngIf="hideFirstPassword" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
          <mat-icon (click)="hideFirstPassword = !hideFirstPassword" *ngIf="!hideFirstPassword" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
          <mat-error *ngIf="passwordResetForm.get('password')!.hasError('required')" class="link-error">Password is required</mat-error>

          <mat-error *ngIf="passwordResetForm.get('password')!.hasError('not-valid')" class="link-error">
            Password needs to meet requirements: 8 chars, 1 upper, 1 lower, 1 number, 1 special
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <p *ngIf="isChangingPassword" class="subheading-default">Confirm New Password</p>
        <mat-form-field *ngIf="isChangingPassword" appearance="outline" class="custom-text-field" floatLabel="always"
                        hideRequiredMarker>

          <input
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            matInput
            required/>

          <mat-icon (click)="hideConfirmPassword = !hideConfirmPassword" *ngIf="hideConfirmPassword" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
          <mat-icon (click)="hideConfirmPassword = !hideConfirmPassword" *ngIf="!hideConfirmPassword" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
          <mat-error *ngIf="passwordResetForm.get('confirmPassword')!.hasError('required')" class="link-error">Confirm password is required</mat-error>
          <mat-error *ngIf="passwordResetForm.get('confirmPassword')!.hasError('no-match')" class="link-error">Passwords do not match</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isChangingPassword" class="full-width" fxLayout="row" fxLayoutAlign="center center"
           fxLayoutGap="15px">
        <button (click)="submit()" [disabled]="isSubmitting" class="button-action form-buttons" mat-flat-button
                type="submit">
          <div class="button-text">Submit</div>
        </button>
        <button (click)="toggleChangePassword()" [disabled]="isSubmitting" class="button-action-cancel form-buttons"
                mat-flat-button>
          <div class="button-text">Cancel</div>
        </button>
      </div>

    </form>

  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="logout()" class="button-action logout-button" mat-flat-button>
      <div class="button-text">Log out</div>
    </button>
  </div>
</div>
