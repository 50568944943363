import {Component, Injector} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {LandlordPropertyModel} from '../../../core/model/landlordProperty.model';
import {Location} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-settings-details',
  templateUrl: './settings-details.component.html',
  styleUrls: ['./settings-details.component.scss']
})
export class SettingsDetailsComponent extends BasePageComponent {

  currentLocation: LandlordPropertyModel | null = null;
  propertyPlace: PlaceResult | null = null;
  propertyLocation: Location | null = null;

  constructor(injector: Injector,
              private contextService: CurrentContextService) {
    super(injector);
    this.currentLocation = this.contextService.getCurrentLocation();
  }

  saveUpdatesToDetails(): void {
  }

  public onAddressAutocompleteSelected(event: PlaceResult): void {
    this.propertyPlace = event;
  }

  public onAddressLocationSelected(event: Location): void {
    this.propertyLocation = event;
  }

}
