import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {CurrentContextService} from './current-context.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 5 * 1000
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private context: CurrentContextService,
    private _snackBar: MatSnackBar
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.validLogin() && this.verified()) {
      return true;
    }

    let clientId = route.paramMap.get('clientId');
    const queryParams = this.buildQueryParams(state);


    if (!this.tryNavigateToVerify(queryParams)) {
      if (clientId)
        this.navigateToLogin(queryParams, clientId);
      else
        this.navigateToNotFound(queryParams);
    }
    return false;
  }

  /**
   * If the user is logged in but not verified, navigate to the verify page
   * @param queryParams
   * @private
   * @return boolean - if the navigation was invoked
   */
  public tryNavigateToVerify(queryParams: any): boolean {
    if (this.validUnverifiedLogin()) {
      this.openSnackBar("Welcome back! Please verify your account to keep your profile secure.");

      this.router.navigate([`/${this.context.getCurrentLandlordCode()!}/account/verify`, {queryParams}], {
        queryParams: {
          sendOtp: true
        }
      })
        .catch((error: string) => {
          console.log(error);
        });
      return true;
    }
    return false;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds,
    });
  }

  private validLogin(): boolean {
    return !!(this.auth.getToken() && this.auth.getExpiration() > new Date() && this.context.currentUser !== null);
  }

  private verified(): boolean {
    return this.auth.isVerified;
  }

  private buildQueryParams(state: RouterStateSnapshot): any {
    const urlTree = this.router.parseUrl(state.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    let urlParamMap: any = [];

    let queryParams: any = {
      returnUrl: urlWithoutParams
    }

    urlTree.queryParamMap.keys.forEach((key: string) => {
      urlParamMap.push(`${key}=${urlTree.queryParamMap.get(key)}`);
      queryParams[key] = urlTree.queryParamMap.get(key);
    });

    return queryParams;
  }

  private validUnverifiedLogin(): boolean {
    return this.validLogin() && !this.verified();
  }

  private navigateToLogin(queryParams: any, clientId: string) {
    this.openSnackBar("Please login to continue");
    this.auth.logout();
    this.router.navigate([`${clientId}/login`], {queryParams})
      .catch((error: string) => {
        console.log(error);
      });
  }

  private navigateToNotFound(queryParams: any): void {
    this.openSnackBar("URL incorrect. Please check with the landlord for the correct URL");

    this.auth.logout();
    this.router.navigate(['404'], {queryParams})
      .catch((error: string) => {
        console.error(error);
      })
  }

}
