<app-pre-auth-toolbar></app-pre-auth-toolbar>

<div class="p-3 full-width full-screen-height flex-column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width reset-password-header-div" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
    <h1 class="title-large">Reset Password</h1>
    <h2 class="subtitle-regular">Please enter your new password and confirm your new password.</h2>
  </div>

  <div class="p-3 mb-5 full-width flex-row" fxLayoutAlign="center center">

    <form [formGroup]="passwordResetForm" class="pre-auth-form" fxLayout="column" fxLayoutAlign="center center"
          fxLayoutGap="20px">

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>New Password</mat-label>

        <input
          [type]="hideFirst ? 'password' : 'text'"
          formControlName="password"
          matInput
          required/>
        <mat-icon (click)="hideFirst = !hideFirst" *ngIf="hideFirst" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
        <mat-icon (click)="hideFirst = !hideFirst" *ngIf="!hideFirst" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="passwordResetForm.get('password')!.hasError('required')">Password is required</mat-error>

        <mat-error class="link-error" *ngIf="passwordResetForm.get('password')!.hasError('not-valid')">
          Password needs to meet requirements: 8 chars, 1 upper, 1 lower, 1 number, 1 special
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Confirm New Password</mat-label>
        <input
          [type]="hideSecond ? 'password' : 'text'"
          formControlName="confirmPassword"
          matInput
          required/>

        <mat-icon (click)="hideSecond = !hideSecond" *ngIf="hideSecond" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
        <mat-icon (click)="hideSecond = !hideSecond" *ngIf="!hideSecond" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="passwordResetForm.get('confirmPassword')!.hasError('required')">Confirm password is required</mat-error>
        <mat-error class="link-error" *ngIf="passwordResetForm.get('confirmPassword')!.hasError('no-match')">Passwords do not match</mat-error>
      </mat-form-field>

      <button
        (click)="onSubmit()"
        [disabled]="passwordResetForm.invalid || (loader.loading$ | async)"
        class="button-primary"
        mat-button
        type="submit">
        <div class="button-text">Update</div>
      </button>

    </form>
  </div>

  <div>
    <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
  </div>
</div>
