import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {RetailerPaymentMethodsService} from '../../service/payment-methods.service';
import {
  AccountType,
  BankOption,
  PaymentMethodType,
  PaymentModelClient,
  PaymentModelDatabase
} from '../../model/payments.model';
import {User} from '../../../../core/model/user.model';
import {lastValueFrom} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmRemoveComponent} from '../../../../shared/components/confirm-remove/confirm-remove.component';
import {ErrorPopupComponent} from '../../../../shared/components/dialogs/error-popup/error-popup.component';
import {BillingDetailsService} from '../../service/billing-details.service';
import {PaymentValidationService} from '../../service/payment-validation.service';
import {Router} from '@angular/router';
import {DateUtilsService} from '../../../../shared/services/dateUtils.service';
import {UserService} from '../../../../core/services/security/user.service';
import {VerifyBankingDetailsComponent} from '../verify-banking-details/verify-banking-details.component';
import {PageOffering} from '../../../../core/model/side-nav.model';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {LoadingService} from '../../../../core/services/loading.service';

@Component({
  selector: 'app-banking-details',
  templateUrl: './banking-details.component.html',
  styleUrls: ['./banking-details.component.scss']
})
export class BankingDetailsComponent implements OnInit {

  @Input() offering: PageOffering | undefined;
  retailerPaymentMethodTypes: PaymentMethodType[] = [];
  paymentMethodsArray: PaymentModelDatabase[] = [];
  errMessage: string = '';
  bankOptions: BankOption[] = [];
  updateUsers: { id: number, name: string }[] = [];
  loading: boolean = false;
  valid: boolean = false;

  addPaymentMethodForm = new FormGroup({
    accountName: new FormControl('', [Validators.required]),
    bankAccountType: new FormControl('', [Validators.required]),
    bankName: new FormControl('', [Validators.required]),
    bankAccountNumber: new FormControl('', [Validators.required]),
    methodType: new FormControl({value: '', disabled: true}, [Validators.required]),
  });

  addingPaymentMethod: boolean = false;
  bankAccountTypes: AccountType[] = [];
  selectedPaymentMethod: string | null = null;
  paymentMethodsOpts =
    [
      {
        typeCode: "TRANSACT_DISTRICT_PAYOUT",
        typeName: "Transact district payout"
      }
    ]

  constructor(public paymentsService: RetailerPaymentMethodsService,
              private userService: UserService,
              private dialogService: MatDialog,
              private billingService: BillingDetailsService,
              private validateBankService: PaymentValidationService,
              public dateUtils: DateUtilsService,
              private router: Router,
              private matDialog: MatDialog,
              private currentContext: CurrentContextService,
              public loader: LoadingService) {
    this.bankAccountTypes = this.paymentsService.getBankAccountTypes();
  }

  ngOnInit(): void {
    this.loadPageData();
    this.resetPaymentMethodForm();
  }


  loadPageData(): void {
    this.loading = true;
    lastValueFrom(this.paymentsService.getAllOptions())
      .then((res: PaymentMethodType[]) => {
        this.retailerPaymentMethodTypes = res;
        if (this.paymentMethodsOpts.length == 1) {
          this.selectedPaymentMethod = this.paymentMethodsOpts[0].typeCode;
          this.addPaymentMethodForm.get('methodType')?.setValue(this.selectedPaymentMethod);
        }
        return this.validateBankService.getBankListWithDefaultBranchCode();
      }, err => {
        this.handleError(err);
        this.loading = false;
      })
      .then((bankList: BankOption[] | void) => {
        if (bankList) {
          this.bankOptions = bankList;
          this.resetPaymentMethodForm();
          return lastValueFrom(this.paymentsService.getAll());
        }
        return null;
      })
      .then((methods: PaymentModelDatabase[] | null) => {
        if (methods) {
          this.paymentMethodsArray = methods;
          this.getUpdateUsers();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public resetPaymentMethodForm(): void {
    this.addPaymentMethodForm.reset();
    if (this.paymentMethodsOpts.length == 1) {
      this.selectedPaymentMethod = this.paymentMethodsOpts[0].typeCode;
      this.addPaymentMethodForm.get('methodType')?.setValue(this.selectedPaymentMethod);
    }
  }

  getUpdateUsers(): void {
    this.paymentMethodsArray.forEach(method => {
      console.log(method);
      lastValueFrom(this.userService.loadUser(method.updateUserId))
        .then((res: User) => {
          const user = {
            id: method.updateUserId,
            name: res.firstname + ' ' + res.lastname
          };
          this.updateUsers.push(user);
        }, err => {
          console.log(err);
        });
    });
  }

  getUsernameForId(userId: number): string {
    const user = this.updateUsers.filter(f => f.id === userId)[0];
    return user.name;
  }

  removeMethod(item: PaymentModelDatabase): void {
    if (!this.loading) {
      const matDialogRef = this.dialogService.open(ConfirmRemoveComponent, {
        height: '350px',
        width: '350px',
        data: {
          description: `Are you sure you want to remove this method?`
        },
      });
      matDialogRef.componentInstance.confirmRemove
        .subscribe((outcome: boolean) => {
          if (outcome) {
            this.loading = true;
            lastValueFrom(this.paymentsService.deletePaymentMethod(item.uuid))
              .then(() => {
                this.loading = false;
                this.loadPageData();
              }, (err: string) => {
                this.loading = false;
                this.handleError(err);
              });
          }
        });
    }
  }

  addMethod(): void {
    if (!this.loading) {
      const blankPaymentMethod: PaymentModelClient = {
        accountName: '', bankAccountNumber: '', bankAccountType: '', branchCode: '', methodType: ''
      };
      this.paymentsService.initPaymentMethod(blankPaymentMethod);
      // this.router.navigate(['account/settings/billing/add-banking-details']);
    }
  }

  updateMethod(method: PaymentModelDatabase): void {
    if (!this.loading) {
      const blankPaymentMethod: PaymentModelClient = {
        accountName: '',
        bankAccountNumber: '',
        bankAccountType: '',
        branchCode: '',
        methodType: method.paymentMethodTypeCode,
        uuid: method.uuid
      };
      this.paymentsService.initPaymentMethod(blankPaymentMethod);
      // this.router.navigate(['account/settings/billing/add-banking-details']);
    }
  }

  addNewPaymentMethod(): void {
    this.addingPaymentMethod = true;
  }

  toggleIsAddingPaymentMethod(): void {
    this.addingPaymentMethod = !this.addingPaymentMethod;
  }

  public onSaveChanges(): void {
    this.valid = false;
    this.errMessage = '';
    const formValue = this.addPaymentMethodForm.getRawValue();
    if (this.addPaymentMethodForm.valid && this.paymentTypeOptionSelected(formValue.methodType)) {
      const validateBankAccount: PaymentModelClient = {
        bankAccountNumber: formValue.bankAccountNumber!,
        bankAccountType: formValue.bankAccountType!,
        branchCode: formValue.bankName!,
        accountName: formValue.accountName!,
        methodType: formValue.methodType!
      };
      this.loading = true;
      lastValueFrom(this.validateBankService.validateAccountDetails(validateBankAccount))
        .then((res: any) => {
          if (res === 'SUCCESS') {
            this.valid = true;
            const model: PaymentModelClient = {
              bankAccountNumber: formValue.bankAccountNumber!,
              bankAccountType: formValue.bankAccountType!,
              branchCode: formValue.bankName!,
              methodType: formValue.methodType!,
              accountName: formValue.accountName!
            };
            this.paymentsService.updatePaymentMethod(model);
            return lastValueFrom(this.paymentsService.createOtpRequest());

          } else {
            this.loading = false;
            switch (res) {
              case 'INVALID_BRANCH_CODE':
                this.errMessage = 'Invalid bank name';
                break;
              case 'INVALID_ACCOUNT_NUMBER':
                this.errMessage = 'Invalid account number';
                break;
              case 'INVALID_ACCOUNT_TYPE':
                this.errMessage = 'Invalid account type';
                break;
              case 'ERROR_AUTH':
                this.errMessage = 'Unexpected error';
                break;
              case 'ERROR_WS':
                this.errMessage = 'Unexpected error';
                break;
              default:
                this.errMessage = 'Unexpected error';
                break;
            }
            this.dialogService.open(ErrorPopupComponent, {
              height: '300px',
              width: '450px',
              data: {
                description: `Could not validate your bank account details: ` + this.errMessage,
              },
            });
          }
          return;
        }, (err: any) => {
          this.handleError(err);
          this.loading = false;
        })
        .then(() => {
          if (this.valid) {
            this.matDialog.open(VerifyBankingDetailsComponent, {
              panelClass: 'dialog-large',
              data: {
                offeringUuid: (this.offering) ? this.offering.offeringUuid : this.currentContext.getCurrentOfferingId()!
              }
            }).afterClosed().subscribe(() => {
              this.loading = false;
            });
          }
          this.loading = false;
        }, (err: any) => {
          this.handleError(err);
          this.loading = false;
        });
    }
  }

  public paymentTypeOptionSelected(type: any): boolean {
    let returnValue = true;
    this.paymentMethodsArray.forEach(savedType => {
      if (savedType.paymentMethodTypeCode === type) {
        this.addPaymentMethodForm.patchValue({
          methodType: 'TRANSACT_PAYBACK',
        });
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: `Payment method type of ${this.paymentsService.getTypeNameFromCode(type)} already exists.`,
          },
        });
        returnValue = false;
      }
    });
    return returnValue;
  }

  private handleError(err: any): void {
    switch (err.status) {
      case 400:
        if ('ValidationException' === err.headers.get('error_code')) {
          this.errMessage = '' + err.headers.get('error_message');
        }
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'unexpected error: ' + this.errMessage,
          },
        });
        break;
      default:
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'unexpected error',
          },
        });
    }
  }

  protected readonly PanelType = PanelType;
}
