export interface Notification {
  uuid: string,
  title: string,
  description: string,
  created: string,
  read: boolean,
  critical: boolean
}

export const notifications = [
  {
    uuid: 'N001',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-14T10:31:00+02:00',
    read: false,
    critical: false
  },
  {
    uuid: 'N002',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-13T11:31:00',
    read: false,
    critical: false
  },
  {
    uuid: 'N003',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-13T11:31:00',
    read: false,
    critical: false
  },
  {
    uuid: 'N004',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-13T11:31:00',
    read: true,
    critical: false
  },
  {
    uuid: 'N005',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-13T11:31:00',
    read: true,
    critical: true
  },
  {
    uuid: 'N006',
    title: 'Notification Title',
    description: 'Notification consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    created: '2023-02-13T11:31:00',
    read: true,
    critical: false
  }
]
