import {SelectionModel} from "@angular/cdk/collections";
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {lastValueFrom, map, Observable, startWith} from "rxjs";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {PageOffering} from "../../../../core/model/side-nav.model";
import {RegisterUserRequest} from "../../../../core/model/user.model";
import {LoadingService} from "../../../../core/services/loading.service";
import {UserService} from "../../../../core/services/security/user.service";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {IndustryCategory, IndustryType} from "../../../leads/industry.model";
import {PipelineService} from "../../../leads/pipeline/pipeline.service";
import {
  CreateARetailerService
} from "../../../leads/retailers/create-a-retailer/create-a-lease-dialog/create-a-retailer.service";
import {
  ApplicationAssessmentStatus,
  RegisterRetailer,
  Retailer,
  TenantType
} from "../../../leads/retailers/retailers.model";
import {RetailersService} from "../../../leads/retailers/retailers.service";
import {LeaseViewComponent} from "../../lease-view/lease-view.component";
import {LeaseViewService} from "../../lease-view/lease-view.service";
import {
  AgreementType,
  LeaseGridSpace,
  LeaseSpaceForTerm,
  LeaseSummary,
  OccupancyType,
  SpaceSelection
} from "../../leasing.model";
import {LeaseCreateMethod} from "./create-a-lease-dialog.interface";

@Component({
  selector: 'app-create-a-lease-dialog',
  templateUrl: './create-a-lease-dialog.component.html',
  styleUrls: ['./create-a-lease-dialog.component.scss']
})
export class CreateALeaseDialogComponent implements OnInit, AfterViewInit{
  @ViewChild('paginator') paginator!: MatPaginator;

  searchTerm = new FormControl('');
  loadingSearch: boolean = false;
  allRetailers!: Retailer[];
  selectedRetailer!: Retailer;
  filteredRetailers!: Observable<Retailer[]>;

  dataSource!: MatTableDataSource<LeaseSpaceForTerm>;
  displayedColumns = ['select', 'id', 'spaceName', 'size'];

  edit = false;
  header: string = '';
  lease!: LeaseSummary;

  existingRetailer = true;
  selection = new SelectionModel<LeaseGridSpace>(true, []);

  offering!: PageOffering;
  retailerName: FormControl<string | null> = new FormControl(null);
  retailerId?: number | null;

  createMethod!: LeaseCreateMethod;

  leaseRequest!: SpaceSelection;
  leaseForm: FormGroup;

  selectedIndustries: string[] = [];
  allIndustries: IndustryCategory[] = [];

  userCreated = false;
  retailerCreated = false;

  userId!: number;
  errMessage = '';

  constructor(public dialogRef: MatDialogRef<CreateALeaseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private tabService: TabManagementService,
              private paginatorService: PaginatorService,
              private retailersService: RetailersService,
              private leaseViewService: LeaseViewService,
              public loader: LoadingService,
              private usersService: UserService,
              private pipelineService: PipelineService,
              private createARetailerService: CreateARetailerService) {
    this.header = data.header;
    this.lease = data.lease;
    this.offering = data.offering;
    this.createMethod = data.createMethod;

    this.leaseForm = new FormGroup({
      occupancyType: new FormControl<OccupancyType>(this.lease ? data.lease.occupancyType : OccupancyType.SINGLE, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      companyName: new FormControl(null, Validators.required),
      industryTypes: new FormControl<string[]>([], Validators.required),
      startDate: new FormControl(null, [Validators.required]),
      endDate: new FormControl(null, [Validators.required]),
    })
  }

  ngOnInit() {
    this.loadIndustries();
  }

  ngAfterViewInit() {
    switch (this.createMethod) {
      case LeaseCreateMethod.NEW_LEASE:
        this.loadRetailers();
        break;
      case LeaseCreateMethod.NEW_LEASE_FOR_RETAILER:
        this.leaseForm.get('occupancyType')?.disable();
        this.retailerName.setValue(this.lease.retailerCompanyName!);
        this.retailerId = this.lease.retailerId;
        this.searchTerm.setValue(this.retailerName!.value);
        break;
      case LeaseCreateMethod.EDIT_LEASE:
        this.edit = true;
        this.leaseForm.get('occupancyType')?.disable();
        this.retailerName.setValue(this.lease.retailerCompanyName!);
        this.retailerId = this.lease.retailerId;
        this.searchTerm.setValue(this.retailerName!.value);

        this.leaseForm.get('startDate')?.setValue(new Date(this.lease.leaseStartDate));
        this.leaseForm.get('endDate')?.setValue(new Date(this.lease.leaseEndDate));
        break;
    }

    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;

    this.leaseForm.get('occupancyType')!.valueChanges.subscribe((value) => {
      this.retailerId = null;
      this.retailerName.setValue(null);
      this.searchTerm.setValue('');
    })

    this.leaseForm.get('endDate')?.valueChanges.subscribe(() => this.updateDates());
  }

  loadRetailers() {
    this.loadingSearch = true;
    const page = {size: '1000', page: '0', sort: 'id,asc'}
    this.retailersService.getAll('', page, [{
      name: 'curated',
      val: ApplicationAssessmentStatus.APPROVED
    }]).subscribe({
      next: value => {
        this.allRetailers = value.content;
        this.loadingSearch = false;

        this.filteredRetailers = this.searchTerm.valueChanges.pipe(
          startWith(''),
          map((value: string | Retailer | null) => {
            const name = typeof value == 'string' ? value : value?.companyName;
            return (name && name.trim().length > 0) ? this._filter(name as string) : this.getAllRetailers().slice();
          }),
        );
      },
      error: err => {
        console.log(err);
        this.loadingSearch = false;
      }
    })
  }

  loadSpaces() {
    this.leaseViewService.getDistrictSpacesForDateRange(this.dateUtils.displayShortDate(this.leaseForm.get('startDate')?.value)!, this.leaseForm.get('endDate')?.value ? this.dateUtils.displayShortDate(this.leaseForm.get('startDate')?.value) : null).subscribe({
      next: value => {
        const availableSpaces = value.leaseSpaces.filter(f => f.availabilityStatus === 'AVAILABLE');
        this.dataSource = new MatTableDataSource<LeaseSpaceForTerm>(availableSpaces);
        this.dataSource.paginator = this.paginator;
      },
      error: err => console.log(err)
    })
  }

  loadIndustries(){
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
      }
    })
  }

  // submitLease() {
  //   retailerId: this.selectedRetailer ? this.selectedRetailer.id! : null,
  // }

  submitLease() {
    if (this.occupancyType == OccupancyType.SINGLE || !this.retailerCreated) {
      const leaseUuid = this.createMethod == LeaseCreateMethod.EDIT_LEASE ? this.lease.uuid : null;
      let retailerId = null;
      if (this.createMethod == LeaseCreateMethod.EDIT_LEASE) {
        retailerId = this.lease.retailerId
      } else if (this.existingRetailer) {
        retailerId = this.selectedRetailer.id!;
      }

      const spaceNames = this.selection.selected.map(m => m.spaceName!);

      this.leaseRequest = new SpaceSelection(
        leaseUuid,
        retailerId,
        this.offering.offeringUuid,
        this.occupancyType,
        AgreementType.LEASE,
        this.dateUtils.displayShortDate(this.leaseForm.get('startDate')?.value),
        this.dateUtils.displayShortDate(this.leaseForm.get('endDate')?.value),
        spaceNames,
        this.edit,
        null,
        null
      );
    }

    if (this.occupancyType == OccupancyType.SHARED) {
      if (this.existingRetailer) {
        this.createLease();
      } else {
        if (!this.retailerCreated) {
          this.createOfferingRetailer();
        } else {
          this.createLease();
        }
      }
    }

    if (this.occupancyType == OccupancyType.SINGLE) {
      if (this.existingRetailer) {
        this.createLease();
      } else {
        if (this.retailerCreated && this.userCreated) {
          this.createLease();
        } else {
          if (this.userCreated) {
            this.registerRetailer()
              .then(ret => {
                if (ret) this.createLease();
              })
          } else {
            this.registerUser()
              .then(res => {
                if (res) this.registerRetailer()
                  .then(ret => {
                    if (ret) this.createLease();
                  });
              })
          }
        }
      }
    }
  }

  registerUser() {
    const newUser = new RegisterUserRequest({
      firstname: this.leaseForm.get('firstName')?.value,
      lastname: this.leaseForm.get('lastName')?.value,
      email: this.leaseForm.get('email')?.value,
    })

    return lastValueFrom(this.usersService.registerNewPortalUser(newUser))
      .then(res => {
        this.userCreated = true;
        this.userId = res.id!;
        return true;
      })
      .catch((err) => {
        this.userCreated = false;
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
            }
            break;
          default:
            this.errMessage = 'An unexpected error occurred, please try again';
        }
        console.log(err);
        return false;
      })
  }

  registerRetailer() {
    const newRetailer: RegisterRetailer = {
      companyName: this.leaseForm.get('companyName')?.value,
      userId: this.userId,
      industries: this.leaseForm.get('industryTypes')?.value
    }

    return lastValueFrom(this.createARetailerService.registerNewRetailer(newRetailer))
      .then(res => {
        this.leaseRequest.retailerId = res.id!;
        console.log('Retailer created');
        return true;
      })
      .catch(err => {
        console.log('Error');
        console.log(err);
        return false;
      })
  }

  createOfferingRetailer() {
    const newRetailer: RegisterRetailer = {
      companyName: this.leaseForm.get('companyName')?.value,
      userId: null,
      industries: this.leaseForm.get('industryTypes')!.value!
    }
    this.createARetailerService.registerNewOfferingRetailer(newRetailer).subscribe({
      next: value => {
        console.log(value);
        this.retailerCreated = true;
        this.leaseRequest.retailerId = value.id!
        this.createLease();
      }, error: err => {
        console.log(err);
      }
    })
  }

  createLease() {
    this.leaseViewService.createPlannedLease(this.leaseRequest).subscribe({
      next: value => {
        this.openLeaseViewTab(value.uuid, value.occupancyType!, value.agreementType!);
        this.close();
      },
      error: err => console.log(err)
    });
  }


  updateDates() {
    if (this.leaseForm.get('startDate')?.value && this.leaseForm.get('endDate')?.value) {
      this.loadSpaces();
    }
  }

  displayFn(value: Retailer) {
    if (value) {
      return value.id && value.companyName ? `${value.id}. ${value.companyName}` : '';
    }
    return '';
  }

  selectRetailer(event: MatAutocompleteSelectedEvent) {
    this.selectedRetailer = event.option.value;
  }

  close() {
    this.dialogRef.close();
  }

  openLeaseViewTab(id: string, occupancyType: OccupancyType, agreementType: AgreementType) {
    const payload: PageEventInterface = {
      componentToRegister: LeaseViewComponent,
      pageName: 'Lease ' + this.utils.displayUuid(id),
      pageHeader: this.offering.label,
      data: { id, occupancyType, agreementType },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering.offeringUuid,
      sectionCode: 'leasing',
      pageCode: 'lease-schedule',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  disableRetailerSelection(): boolean {
    return !!this.lease && !!this.lease.retailerId && !!this.lease.retailerCompanyName;
  }

  getPlaceHolder(): string {
    if (this.lease) {
      return `${this.lease.retailerId}. ${this.lease.retailerCompanyName}`;
    } else if (this.retailerName && this.retailerId) {
      return `${this.retailerId}. ${this.retailerName}`;
    }
    return 'Enter a retailer company name';
  }

  get occupancyType(): OccupancyType {
    return this.leaseForm.get('occupancyType')?.value;
  }

  getErrorMessage(formField: string) {
    if (this.leaseForm.get(formField)!.hasError('required')) return 'You must enter a value';
    if (formField == 'email' && this.leaseForm.get(formField)!.hasError('email')) return 'Not a valid email';

    if (formField == 'occupiedSpace') {
      if (this.leaseForm.get(formField)!.hasError('min')) return 'You must enter a value above 0';
      if (this.leaseForm.get(formField)!.hasError('max')) return 'There is not sufficient available space for the chosen dates';
    }
    return null;
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
        this.updateSelected();
      }
    });
  }

  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
  }

  getAllRetailers() {
    if (this.allRetailers) {
      switch (this.occupancyType) {
        case OccupancyType.SINGLE:
          return this.allRetailers.filter(f => f.tenantType == TenantType.TENANT);
        case OccupancyType.SHARED:
          return this.allRetailers.filter(f => f.tenantType == TenantType.OFFERING);
      }
    }
    return [];
  }

  private _filter(name: string): Retailer[] {
    const filterValue = name.toLowerCase();
    return this.getAllRetailers().filter(option => option.companyName.toLowerCase().includes(filterValue));
  }

  protected readonly OccupancyType = OccupancyType;
}
