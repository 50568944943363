import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {monthlyTurnoverConfig, TurnoverPeriod} from "./import-config/monthly-turnover-config.model";
import {UploadDataModel} from "../../../../shared/components/csv-import/csv-import.model";
import {salesTurnoverConfig} from "./import-config/sales-turnover-config.model";
import {LeaseTurnoverService} from "./lease-turnover.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-lease-turnover',
  templateUrl: './lease-turnover.component.html',
  styleUrls: ['./lease-turnover.component.scss']
})
export class LeaseTurnoverComponent implements OnInit {
  @Input() id!: string;
  @Input() offeringId!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  importType: 'sales' | 'turnover' = 'sales';
  uploadConfig: UploadDataModel[] = monthlyTurnoverConfig;

  turnoverPeriods: TurnoverPeriod[] = [];
  dataSource = new MatTableDataSource<TurnoverPeriod>();
  displayedColumns = ['billingPeriodUuid', 'pricingPeriodStartDate', 'pricingPeriodEndDate', 'turnoverMinDate', 'turnoverMaxDate'];
  displayedLabels = ['Period Uuid', 'Period Start Date', 'Period End Date', 'Uploaded Min Date', 'Uploaded Max Date'];

  latestTransactionDate: Date | null = null;

  uploadEndpoint: string = '';

  params = { name: 'mergeMethod', val: 'REPLACE' };
  pageSize: number = 5;

  monthlyTurnoverConfig: UploadDataModel[] = monthlyTurnoverConfig;
  salesTurnoverConfig: UploadDataModel[] = salesTurnoverConfig;

  constructor(private leaseTurnoverService: LeaseTurnoverService,
              private utils: UtilsService,
              public dateUtils: DateUtilsService,
              private contextService: CurrentContextService) {
  }

  ngOnInit() {
    this.changeImportType('sales');

    this.monthlyTurnoverConfig.find(f => f.property == 'turnoverMonth')!.computeMethod = this.getMonthFromPeriod.bind(this);
    this.monthlyTurnoverConfig.find(f => f.property == 'turnoverYear')!.computeMethod = this.getYearFromPeriod.bind(this);

    this.salesTurnoverConfig.find(f => f.property == 'processingStatus')!.computeMethod = this.setTransactionStatus.bind(this);
    this.salesTurnoverConfig.find(f => f.property == 'saleStatus')!.computeMethod = this.setSaleStatus.bind(this);
    this.salesTurnoverConfig.find(f => f.property == 'saleType')!.computeMethod = this.setSaleType.bind(this);
    this.salesTurnoverConfig.find(f => f.property == 'transactionSource')!.computeMethod = this.setTransactionSource.bind(this);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeImportType(type: 'sales' | 'turnover') {
    this.importType = type;
    this.updateUploadEndpoint();

    if (this.importType == 'turnover') {
      this.uploadConfig = this.monthlyTurnoverConfig;

      if (this.turnoverPeriods.length == 0) {
        this.leaseTurnoverService.getTurnoverPeriods(this.id).subscribe({
          next: value => {
            this.turnoverPeriods = value;
            this.turnoverPeriods.forEach(tp => {
              tp.billingPeriodUuid = this.utils.displayUuid(tp.billingPeriodUuid);
              tp.pricingPeriodStartDate = this.dateUtils.displayShortDate(tp.pricingPeriodStartDate)!;
              tp.pricingPeriodEndDate = this.dateUtils.displayShortDate(tp.pricingPeriodEndDate)!;
              tp.turnoverMinDate = this.dateUtils.displayShortDate(tp.turnoverMinDate)!;
              tp.turnoverMaxDate = this.dateUtils.displayShortDate(tp.turnoverMaxDate)!;
            })
            this.dataSource = new MatTableDataSource<TurnoverPeriod>(this.turnoverPeriods);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          },
          error: err => console.log(err)
        })
      }
    }

    if (this.importType == 'sales') {
      this.uploadConfig = this.salesTurnoverConfig;

      if (this.latestTransactionDate == null) {
        this.leaseTurnoverService.getLatestTransactionDate(this.id).subscribe({
          next: value => this.latestTransactionDate = value,
          error: err => console.log(err)
        })
      }
    }
  }

  updateParams(event: MatButtonToggleChange) {
    this.params.val = event.value;
  }

  updateUploadEndpoint() {
    this.uploadEndpoint = this.leaseTurnoverService.baseUrl(this.id) + `/${this.importType}`;
  }

  setTransactionStatus(data: any) {
    return 'PAID';
  }

  setSaleStatus(data: any) {
    return 'CLOSED';
  }

  setTransactionSource(data: any) {
    return 'UPLOAD'
  }

  setSaleType(data: any) {
    const saleAmount = data['saleAmount'];
    if (saleAmount != null) return saleAmount >= 0 ? 'SALE' : 'RETURN';
    return null;
  }

  getMonthFromPeriod(data: any) {
    const startField = data['periodStartDate'];
    if (startField) return this.dateUtils.displayFullMonth(startField);
    return null;
  }

  getYearFromPeriod(data: any) {
    const startField = data['periodStartDate'];
    if (startField) return this.dateUtils.displayYear(startField);
    return null;
  }

  canViewTab(type: 'sales' | 'turnover') {
    switch (type) {
      case "sales": return this.contextService.checkUserFeatures(['leasing_current_view_sales_upload', 'leasing_current_edit_sales_upload'], this.offeringId);
      case "turnover": return this.contextService.checkUserFeatures(['leasing_current_view_turnover_upload', 'leasing_current_edit_turnover_upload'], this.offeringId);
    }
  }

  get disableUpload() {
    switch (this.importType) {
      case "sales": return !this.contextService.checkUserFeatures(['leasing_current_edit_sales_upload'], this.offeringId);
      case "turnover": return !this.contextService.checkUserFeatures(['leasing_current_edit_turnover_upload'], this.offeringId);
    }
  }
}
