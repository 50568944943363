import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericTypeDef} from '../../../core/model/generic-type-def.model';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedServicesService extends BaseService {


  baseUrl: string = environment.apiSharedServicesHost;

  constructor(private http: HttpClient) {
    super();
  }

  getAllIndustryCategories(): Observable<{ content: GenericTypeDef[] }> {
    const uri = this.baseUrl + '/console/types/industry-category/not-paged';
    return this.http.get<{ content: GenericTypeDef[] }>(uri);
  }
}
