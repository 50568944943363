import {Component} from '@angular/core';
import {BasePageComponent} from '../../page-content/base-page.component';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent extends BasePageComponent {

}
