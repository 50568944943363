<div style="background-color: white" fxLayout="column">

  <app-page-header [banner]="true" [title]="'Footfall Report'" [subtitle]="'Get an overview of foot traffic in your space'"></app-page-header>

  <div class="footfall-container">
    <iframe class="footfall-responsive-iframe"
            title="SOKO Rosebank Footfall 2023"
            src="https://app.powerbi.com/reportEmbed?reportId=9f94e544-fb55-48b6-88ec-4f2c3e61dc0c&autoAuth=true&ctid=0c814f6c-9531-495e-90c4-e87c21fa875c">
    </iframe>
  </div>
</div>
