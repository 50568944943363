<div class="full-width page-content page-padding" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Retailer Events</span>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Retailer Information</span>
<!--    <form [formGroup]="filterForm" fxFlexOffset="20px">-->
<!--      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">-->

<!--        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">-->
<!--          <mat-label>EventType</mat-label>-->
<!--          <input matInput type="text" placeholder="Search here" formControlName="eventType">-->
<!--        </mat-form-field>-->

<!--        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">-->
<!--          <mat-label>Retailer</mat-label>-->
<!--          <input matInput type="text" placeholder="Search here" formControlName="eventId">-->
<!--        </mat-form-field>-->

<!--        <app-custom-date-filter (dateRange)="getDateRange($event)"></app-custom-date-filter>-->

<!--      </div>-->
<!--    </form>-->

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Retailer Event List </th>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.id"
            *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef> Event Type </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.eventTypeName, 30)}} </td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.createDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="triggeredBy">
        <th mat-header-cell *matHeaderCellDef> Triggered By </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.userId + '. ' + element.triggeredBy}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
