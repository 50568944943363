import {Clipboard} from '@angular/cdk/clipboard';
import {AfterViewInit, Component, Injector, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {MatTableDataSource} from '@angular/material/table';
import {Router} from "@angular/router";
import QRCode from 'qrcode';
import {lastValueFrom} from 'rxjs';
import {CurrentContextService} from 'src/app/core/services/security/current-context.service';
import {environment} from 'src/environments/environment';
import {BasePageComponent} from '../../../../core/components/page-content/base-page.component';
import {PageItemModel} from '../../../../core/model/page-item.model';
import {ApplicationForm} from '../../../../core/model/retailer/application-form.model';
import {LoadingService} from '../../../../core/services/loading.service';
import {TabManagementService} from '../../../../core/services/tab-management.service';
import {DateUtilsService} from '../../../../shared/services/dateUtils.service';
import {UtilsService} from '../../../../shared/services/utils.service';

import {SettingsApplicationFormsService} from '../../../settings/service/settings-application-forms.service';
import {FormStepperComponent} from '../form-stepper/form-stepper.component';

@Component({
  selector: 'app-manage-application-form-builder',
  templateUrl: './manage-application-forms.component.html',
  styleUrls: ['./manage-application-forms.component.scss']
})
export class ManageApplicationFormsComponent extends BasePageComponent implements AfterViewInit {
  @ViewChild('rightSidenav') sidenav!: MatSidenav;

  displayedColumns: string[] = ['formName', 'isCurrentlyActive', 'updateDate', 'preview', 'makeActive', 'edit', 'duplicate', 'delete'];
  private applicationFormTemplates: ApplicationForm[] = [];
  dataSource = new MatTableDataSource(this.applicationFormTemplates);

  websiteLink: string = 'https://wwww.nter.io';
  qrCodeImageSrc?: string;

  constructor(injector: Injector,
              public loader: LoadingService,
              public dateUtils: DateUtilsService,
              public utils: UtilsService,
              private applicationFormsService: SettingsApplicationFormsService,
              private router: Router,
              private tabManagementService: TabManagementService,
              private clipboard: Clipboard,
              private currentContext: CurrentContextService) {

    super(injector);
    this.loadAllTemplates();
    this.generateQRCode();

    this.tabManagementService.getPageInFocus().subscribe((page: PageItemModel | null) => {
      if (page && page.id == this.pageId.id) {
        this.loadAllTemplates();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.sidenav.open(), 200);
  }

  deleteFormTemplate(element: ApplicationForm): void {
    lastValueFrom(this.applicationFormsService.deleteOneFormTemplate(element.uuid!))
      .then(() => {
        this.loadAllTemplates();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  setAsActiveFormTemplate(element: ApplicationForm): void {
    lastValueFrom(this.applicationFormsService.setOneFormTemplateAsActive(element.uuid!))
      .then((res: ApplicationForm[]) => {
        this.applicationFormTemplates = res;
        this.dataSource.data = res;
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  public previewForm(element: ApplicationForm) {
    const locationOffering = this.currentContext.getCurrentLocation()?.locationOfferings?.find(offering => offering.offeringUuid === this.offering?.offeringUuid)!;

    const url = this.router.serializeUrl(this.router.createUrlTree([`/preview-apply/${element.formCode}`],
      {
        queryParams: {
          locationCode: locationOffering.locationCode!,
          offeringCode: locationOffering.offeringCode!,
          embedKey: locationOffering.embedKey!
        }
      }));
    window.open(url, '_blank');
  }

  editExistingApplication(element: ApplicationForm) {
    if (this.canBeUpdated(element)) {
      this.tabManagementService.sendPageAddEvent({
        componentToRegister: FormStepperComponent,
        data: element,
        pageName: 'Edit Application Form',
        pageCode: 'edit_application_form',
        sectionCode: 'settings',
        offering: this.offering,
        pageHeader: 'Edit Application Form',
        id: this.utils.generateUuidWithPrefix('edit_application_form')
      });
    }
  }

  startNewApplication(): void {
    this.tabManagementService.sendPageAddEvent({
      componentToRegister: FormStepperComponent,
      data: null,
      pageName: 'New Application Form',
      pageCode: 'new_application_form',
      sectionCode: 'settings',
      offering: this.offering,
      pageHeader: 'New Application Form',
      id: this.utils.generateUuidWithPrefix('new_application_form')
    });
  }

  duplicateForm(element: ApplicationForm): void {
    lastValueFrom(this.applicationFormsService.duplicateForm(element.uuid))
      .then(() => {
        this.loadAllTemplates();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  private loadAllTemplates(): void {
    lastValueFrom(this.applicationFormsService.getAllFormTemplatesForCurrentOffering())
      .then((res: ApplicationForm[]) => {
        this.applicationFormTemplates = res;
        this.dataSource.data = res;
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  canBeUpdated(element: ApplicationForm): boolean {
    if (element.formStatus) {
      return element.formStatus?.toLowerCase() !== 'used';
    }
    return true;
  }


  copyEmbedCode() {
    const locationOffering = this.currentContext.getCurrentLocation()?.locationOfferings?.find(offering => offering.offeringUuid === this.offering?.offeringUuid)!;

    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = environment.apiAppForm;

    const formEl: HTMLElement = document.createElement('nter-app-form');
    formEl.setAttribute('location-code', locationOffering.locationCode);
    formEl.setAttribute('offering-code', locationOffering.offeringCode!);
    formEl.setAttribute('embed-key', locationOffering.embedKey!);

    const stringToCopy = script.outerHTML + '\n' + formEl.outerHTML;
    this.clipboard.copy(stringToCopy)
  }

  generateQRCode() {
    QRCode.toDataURL(this.websiteLink)
      .then(url => {
        this.qrCodeImageSrc = url;
      })
      .catch(err => {
        console.error(err);
        // todo show snackbar with error message that qr code could not be generated
      })

  }

  downloadQRCodeImage() {
    if (this.qrCodeImageSrc) {
      const link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = this.qrCodeImageSrc;
      link.click();
    } else {
      console.error('QR code image source is undefined');
      // todo show snackbar with error message that qr code could not be downloaded
      return;
    }
  }
}
