<div class="full-width" fxLayout="column" fxLayoutGap="7px">
  <span class="body-3-default">Industry And Product Collection</span>
  <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;" hideRequiredMarker>
    <mat-select class="custom-select-field-value" [formControl]="selectedIndustries"
                [multiple]="true" placeholder="Industry And Product Collection">
      <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
        <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                   fxLayoutGap="5px">
          <span>{{group.name}}</span>
          <mat-icon class="btn-cursor">add</mat-icon>
        </mat-label>
        <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                    class="custom-select-field-option">{{ ind.name }}</mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-error class="link-error">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>
