<div class="dashboard-page" fxLayout="column">
  <app-page-header [banner]="true" [description]="'Select one of the features below to access.'"
                   [title]="'Setup Nter wide components'"></app-page-header>

  <div class="full-screen-height-page-banner full-width page-padding" fxLayout="column" style="padding-top: 60px">
    <div class="subtitle-default">All Available Features</div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-card (click)="openTemplateBuilder()" class="property-card btn-cursor"
                fxFlex="33.3" fxLayout="column">
        <div class="mat-card-image-container">
          <div class="overlay full-height" fxLayout="row" fxLayoutAlign="center center">
            <button class="button-outline" mat-button>
              <div class="button-text">View</div>
            </button>
          </div>
        </div>
        <mat-card-content fxFlexOffset="20px">
          <div class="heading-default">Base Template Builder</div>
          <div class="heading-regular">Nter</div>
        </mat-card-content>
      </mat-card>
    </div>

  </div>
</div>
