import {GenericTypeDef} from "../../../core/model/generic-type-def.model";

export class Address {
  [key: string]: any;
  id: number = 0;
  retailerId: number = 0;
  addressType: GenericTypeDef = new GenericTypeDef();
  administrativeAreaLevel1: string = '';
  administrativeAreaLevel1Code: string = '';
  administrativeAreaLevel2: string = '';
  country: string = '';
  countryCode: string = '';
  formattedAddress: string | undefined = '';
  latitude: number = 0;
  locality: string = '';
  longitude: number = 0;
  mapsUrl: string | undefined = '';
  placeId: string | undefined = '';
  placeName: string = '';
  postal_code: string = '';
  route: string = '';
  streetNumber: number = 0;
  sublocalityLevel1: string = '';
}
