import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {PageHeaderTab} from '../../../../core/components/page-header/page-header-tabs.interface';
import {BatchType} from "../model/run-payouts.model";

@Component({
  selector: 'app-run-tenant-payouts-view',
  templateUrl: './run-tenant-payouts-view.component.html',
  styleUrls: ['./run-tenant-payouts-view.component.scss']
})
export class RunTenantPayoutsViewComponent extends BasePageComponent {
  tabs: PageHeaderTab[] = [
    {
      name: 'Import',
      isActive: true,
      code: "import_transactions"
    },
    {
      name: 'Process',
      isActive: false,
      code: "process_billing"
    },
    {
      name: 'Create Batches',
      isActive: false,
      code: "create_batches"
    },
    {
      name: 'Submit Batches',
      isActive: false,
      code: "submit_batches"
    },
    {
      name: 'Setup configs',
      isActive: false,
      code: "setup_configs"
    }
  ];

  selectedBatches: string[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  activeTab: string = "import_transactions"

  changeTabs($event: PageHeaderTab): void {
    this.activeTab = $event.code;
  }

  getInitialTab(): PageHeaderTab {
    return this.tabs.at(0)!;
  }

  protected readonly BatchType = BatchType;
}
