<div class="full-width" fxLayout="column">
  <div class="full-width" fxLayout="column">
    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="subtitle-default">{{jobType == BatchType.DISTRICT_PAYOUT ? 'Client pay-out jobcard' : 'Pay-out jobcard'}}</span>
      </div>
      <div *ngIf="newRetailersBool && jobType == BatchType.RETAILER_PAYOUT" fxFlexOffset="20px">
        <app-info-panel
          [message]="['There are new retailers that have not been added to a payment batch']"></app-info-panel>
      </div>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="20px">
      <div class="info-card" fxLayout="column">

        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" *ngFor="let job of payoutRunResponse; let last = last;">
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="40">
              <span class="body-1-default">{{job.name}}</span>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <span class="body-2-default">{{job.status}}</span>
                <button mat-icon-button class="run-job-button"
                        [class.run-button-success]="job.status === 'Success'"
                        [class.run-button-error]="job.status === 'Error'"
                        [class.run-button-timeout]="job.status === 'Timeout'"
                        (click)="runService(job)" [disabled]="isPageLoading() || !!(loader.loading$ | async)">
                  <mat-icon *ngIf="job.status === 'Ready'">arrow_forward</mat-icon>
                  <mat-icon *ngIf="job.status === 'Success'">check</mat-icon>
                  <mat-icon *ngIf="job.status === 'Timeout'">error_outline</mat-icon>
                  <mat-icon *ngIf="job.status === 'Error'">close</mat-icon>
                </button>
                <mat-spinner diameter="20" color="primary" *ngIf="isPageLoading()"></mat-spinner>
              </div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="17">
              <div class="body-2-default">Correlation ID</div>
              <div class="body-2-default">{{utils.displayString(job.currentCorrelationId, 35)}}</div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="17">
              <div class="body-2-default">Previous Correlation ID</div>
              <div class="body-2-default">{{utils.displayString(job.prevCorrelationId, 35)}}</div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="16">
              <div class="body-2-default">Update and Status</div>
              <div class="body-2-default">{{utils.displayString(job.prevOutcome, 35)}}</div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="10">
              <div class="body-2-default" style="height: 13px"> </div>
              <div class="body-2-default">{{dateUtils.displayDateWithTime(job.updateDate)}}</div>
            </div>

          </div>

          <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
            <app-custom-date-filter *ngIf="job.id === 'transaction_job_import_new_transactions'"
                                    [customDateRangePresets]="dateFilters"
                                    (dateRange)="updateImportDateRange($event)">
            </app-custom-date-filter>

            <mat-form-field class="custom-date-picker" appearance="outline" *ngIf="job.id === 'transaction_job_create_client_payment_batch'">
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Custom" (dateChange)="updateBatchDateRange($event, true)"/>
                <input matEndDate (dateChange)="updateBatchDateRange($event, false)"/>
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <mat-divider class="full-width" fxFlexOffset="10px" *ngIf="!last"></mat-divider>
        </div>

      </div>
    </div>

  </div>

  <div class="full-width" fxLayout="column" *ngIf="jobNames.includes('transaction_job_create_daily_payment_batch')">
    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="body-1-default">Active Batch Groups</span>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxFlexOffset="30px" fxLayout="column">
      <app-active-payout-groups *ngIf="singleBatchConfigs.length > 0"
                                [groupType]="ConfigType.VEND"
                                [data]="singleBatchConfigs"
                                [offering]="offering">
      </app-active-payout-groups>
      <app-active-payout-groups *ngIf="sharedBatchConfigs.length > 0"
                                [groupType]="ConfigType.VEND_SUPPLIER_LEASE"
                                [data]="sharedBatchConfigs"
                                [offering]="offering">
      </app-active-payout-groups>
      <app-active-payout-groups *ngIf="offeringBatchConfigs.length > 0"
                                [groupType]="ConfigType.VEND_SUPPLIER_OFFERING"
                                [data]="offeringBatchConfigs"
                                [offering]="offering">
      </app-active-payout-groups>
    </div>
  </div>


</div>

