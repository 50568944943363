import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {OfferingSpacesInterface, OfferingSpaceStatistics, SpaceType} from "../model/offering-spaces.interface";
import {UtilsService} from "../../../shared/services/utils.service";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {ManageSpacesService} from "../service/manage-spaces.service";
import {lastValueFrom, Subject, takeUntil} from "rxjs";
import {GenericTypeDef} from "../../../core/model/generic-type-def.model";
import {SharedServicesService} from "../../settings/service/shared-services.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-edit-single-space',
  templateUrl: './edit-single-space.component.html',
  styleUrls: ['./edit-single-space.component.scss']
})
export class EditSingleSpaceComponent implements OnChanges, OnDestroy {
  @Input() selectedSpace: OfferingSpacesInterface | null = null;
  @Input() addNewSpace  : Event | null = null;
  @Output('dirty') dirtyEvent = new EventEmitter<boolean>();
  @Output() spaceUpdated = new EventEmitter<OfferingSpacesInterface>();

  private unsubscribe$ = new Subject<void>();

  dirty = false;
  spaceDetailsForm!: FormGroup;
  statSpaceStatistics: OfferingSpaceStatistics | null = null;

  preferredIndustryGroup: FormGroup;
  allIndustries: GenericTypeDef[] = [];
  selectedIndustries: GenericTypeDef[] = [];
  originalIndustries: string[] = [];
  firstIndCode: string | null = null;
  secondIndCode: string | null = null;
  thirdIndCode: string | null = null;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private spaceService: ManageSpacesService,
              private sharedServices: SharedServicesService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,) {

    this.matIconRegistry.addSvgIcon('person_home', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/person_home.svg'));
    this.matIconRegistry.addSvgIcon('stacked_lines', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/stacked_lines.svg'));
    this.matIconRegistry.addSvgIcon('circle_underscore_check', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/underscore_check_encircled.svg'));

    this.preferredIndustryGroup = new FormGroup({
      first: new FormControl(''),
      second: new FormControl(''),
      third: new FormControl('')
    });

    this.preferredIndustryGroup.valueChanges.subscribe((changes) => {
      if (changes.first) {
        if (this.originalIndustries[0] != changes.first) {
          this.dirty = true;
        }
        this.selectedSpace!.districtSpacesIndustries[0] = this.getIndustryByCode(changes.first)
      }
      if (changes.second) {
        if (this.originalIndustries[1] != changes.second) {
          this.dirty = true;
        }
        this.selectedSpace!.districtSpacesIndustries[1] = this.getIndustryByCode(changes.second);
      }

      if (changes.third) {
        if (this.originalIndustries[2] != changes.third) {
          this.dirty = true;
        }
        this.selectedSpace!.districtSpacesIndustries[2] = this.getIndustryByCode(changes.third);
      }
    });
  }

  ngOnInit() {
    this.loadIndustries();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: any) {
    if(changes.addNewSpace && changes.addNewSpace.currentValue !== null) {
      this.selectedSpace = {
        id: -1,
        districtUuid: '',
        spaceType: SpaceType.SOKO_COMPOUND,
        spaceName: '',
        spaceSize: 0,
        startDate: new Date(),
        endDate: null,
        locked: false,
        hasHadLease: false,
        earliestEndDate: null,
        latestStartDate: null,
        districtSpacesIndustries: []
      }
      this.cancelChanges();
      this.statSpaceStatistics = {
        id: -1,
        avgRentalPricingPerSqmValue: 0,
        avgTerm: 0,
      }
    }

    if (changes.selectedSpace) {
      this.cancelChanges();
      this.loadSpaceStats();
      this.updateSelectedIndustriesForm(this.selectedSpace!);
      //todo after update event is emitted, the parent will update selected space and this will be called again to use latest values after update.
    }

    this.emitDirtyFormEvent();
  }

  private resetSpaceDetailsForm(value: OfferingSpacesInterface): FormGroup {
    let startDate = this.dateUtils.displayShortDate(new Date());
    let endDate = null;

    if(value?.startDate) {
      startDate = this.dateUtils.displayShortDate(value.startDate);
    }

    if (value?.endDate) {
      endDate = this.dateUtils.displayShortDate(value.endDate);
    }
    const fg = new FormGroup({
      spaceName: new FormControl(value?.spaceName, [Validators.required, Validators.minLength(3)]),
      spaceSize: new FormControl(value?.spaceSize, [Validators.required, Validators.min(0)]),
      startDate: new FormControl(startDate, [Validators.required]),
      endDate: new FormControl(endDate, [this.endDateValidator])
    });

    fg.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.emitDirtyFormEvent();
    });

    return fg;
  }

  private endDateValidator(control: AbstractControl): { [key: string]: any } | null {
    const startDate = control.root.get('startDate');
    const endDate = control.value;

    if (startDate && endDate) {
      const start = new Date(startDate.value);
      const end = new Date(endDate);

      if (end < start) {
        return { 'endDateBeforeStartDate': true };
      }
    }

    return null;
  }

  deleteSelectedSpace(): void {
    // lastValueFrom(this.spaceService.deleteSpace(this.selectedSpace!))
    //   .then(() => {
    //     this.showSpaceUpdate = false;
    //     this.showingActions = false;
    //
    //     if (this.selectedSpace!.locked) {
    //       let index: number = this.availableSpaces.findIndex(space => space.id === this.selectedSpace!.id);
    //       this.availableSpaces.splice(index, 1);
    //     } else {
    //       let index: number = this.unavailableSpaces.findIndex(space => space.id === this.selectedSpace!.id);
    //       this.unavailableSpaces.splice(index, 1);
    //     }
    //     this.selectedSpace = null;
    //     this.resetAllForms();
    //   })
    //   .catch((error: string) => {
    //     console.log(error);
    //   });
  }

  getSelectedSpaceSize(): string {
    return (this.selectedSpace) ? this.selectedSpace.spaceSize.toString() : '0';
  }

  getSpaceAveragePrice(): any {
    return (this.statSpaceStatistics) ? this.utils.displayCompactNumber(this.statSpaceStatistics.avgRentalPricingPerSqmValue) : 0
  }

  getSpaceAverageTerm(): any {
    return (this.statSpaceStatistics) ? this.spaceService.displayDuration(this.statSpaceStatistics.avgTerm) : '0 months';
  }

  cancelChanges(): void {
    this.dirty = false;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject<void>();

    this.spaceDetailsForm = this.resetSpaceDetailsForm(this.selectedSpace!);
    // this.spaceDateForm = this.resetSpaceDateForm(this.selectedSpace?.startDate, this.selectedSpace?.endDate);

    this.emitDirtyFormEvent();
  }

  saveSelectedSpace(): void {
    //validate form for errors, if form has errors exit method and trigger that errors show on ui
    if (this.spaceDetailsForm.invalid) {
      this.spaceDetailsForm.markAllAsTouched();
      return;
    }

    let updatedSpace = this.selectedSpace;
    if(this.spaceDetailsForm.dirty) {
      updatedSpace!.spaceName = this.spaceDetailsForm.get('spaceName')!.value;

      if(this.spaceDetailsForm.get('startDate')!.dirty) {
        updatedSpace!.startDate = this.spaceDetailsForm.get('startDate')!.value;
      }

      if (this.spaceDetailsForm.get('endDate')!.dirty) {
        updatedSpace!.endDate = this.spaceDetailsForm.get('endDate')!.value;
      }

      if(this.spaceDetailsForm.get('spaceSize')!.dirty) {
        updatedSpace!.spaceSize = this.spaceDetailsForm.get('spaceSize')!.value;
      }
    }

    updatedSpace?.id == -1 ? this.submitSpaceAdd(updatedSpace!) :
      this.submitSpaceUpdate(updatedSpace!);
  }

  getLatestStartDate(): any {
    if (this.selectedSpace?.latestStartDate) {
      return this.dateUtils.displayShortDate(this.selectedSpace.latestStartDate);
    }
    return null;
  }

  private submitSpaceUpdate(space: OfferingSpacesInterface) {
    lastValueFrom(this.spaceService.updateSpace(space))
      .then((res: OfferingSpacesInterface) => {
        this.processSpaceUpdateResponse(res);
      })
      .catch(error => {
        console.log(error)
      });
  }

  private submitSpaceAdd(space: OfferingSpacesInterface) {
    const update = {
      ...space,
      id : null as any,
      districtUuid: null as any
    }

    lastValueFrom(this.spaceService.createNewSpace(update))
      .then((res: OfferingSpacesInterface) => {
        this.processSpaceUpdateResponse(res);
      })
      .catch(error => {
        console.log(error)
      });
  }

  private processSpaceUpdateResponse(res: OfferingSpacesInterface) {
    this.selectedSpace = res;

    this.spaceUpdated.emit(res);
    this.selectedSpace = res;
  }

  loadSpaceStats(): void {
    if (this.selectedSpace) {
      lastValueFrom(this.spaceService.getStatsForSpace(this.selectedSpace.id))
        .then((res: OfferingSpaceStatistics) => {
          this.statSpaceStatistics = res;
        })
        .catch((error: string) => {
          this.statSpaceStatistics = null;
          console.log(error)
        });
    }
  }

  private getIndustryByCode(code: string): GenericTypeDef {
    return this.allIndustries.find((industry) => industry.code == code)!;
  }

  loadIndustries() {
    lastValueFrom(this.sharedServices.getAllIndustryCategories())
      .then((res: { content: GenericTypeDef [] }) => {
        this.allIndustries = res.content;
      })
  }

  clearIndustrySelection(index: number): void {
    this.selectedSpace!.districtSpacesIndustries.splice(index, 1);
    this.updateSelectedIndustriesForm(this.selectedSpace!);
  }

  private updateSelectedIndustriesForm(space: OfferingSpacesInterface): void {
    this.preferredIndustryGroup.reset();
    this.originalIndustries = Array(3).fill("");
    this.firstIndCode = '';
    this.secondIndCode = '';
    this.thirdIndCode = '';

    if (space != null && space.districtSpacesIndustries != null) {
      this.selectedIndustries = space.districtSpacesIndustries;
      if (this.selectedIndustries[0]) {
        this.firstIndCode = this.selectedIndustries[0].code;
        this.originalIndustries[0] = this.firstIndCode;
        this.preferredIndustryGroup.get('first')!.setValue(this.firstIndCode);
      }
      if (this.selectedIndustries[1]) {
        this.secondIndCode = this.selectedIndustries[1].code;
        this.originalIndustries[1] = this.secondIndCode;
        this.preferredIndustryGroup.get('second')!.setValue(this.secondIndCode);
      }
      if (this.selectedIndustries[2]) {
        this.thirdIndCode = this.selectedIndustries[2].code;
        this.originalIndustries[2] = this.thirdIndCode;
        this.preferredIndustryGroup.get('third')!.setValue(this.thirdIndCode);
      }
    } else {
      this.selectedIndustries = [];
    }
  }

  //todo update as more forms are added
  emitDirtyFormEvent(): void {
    this.dirty = (this.spaceDetailsForm?.dirty === true);
    this.dirtyEvent.emit(this.dirty);
    console.log('Dirty event emitted:', this.dirty);
  }

  get getDate() {
    return new Date();
  }
}
