import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from "../../../../../shared/services/utils.service";
import {LeaseSummary, OccupancyType} from "../../../../leasing/leasing.model";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {LeasingService} from "../../../../leasing/leasing.service";
import {GenericDatasource} from "../../../../../shared/datasource/generic.datasource";
import {
  CreateALeaseDialogComponent
} from "../../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../../core/services/loading.service";
import {TenantType} from "../../retailers.model";

@Component({
  selector: 'app-retailer-leasing-history',
  templateUrl: './retailer-leasing-history.component.html',
  styleUrls: ['./retailer-leasing-history.component.scss']
})
export class RetailerLeasingHistoryComponent implements OnInit, AfterViewInit{
  @Input() id!: string;
  @Input() companyName!: string;
  @Input() tenantType!: string;
  @Input() offering!: PageOffering;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'companyName', 'startDate', 'endDate', 'spaceName', 'size', 'rentalType', 'monthlyRentalFee', 'leaseStatus'];
  dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private leasingService: LeasingService,
              private matDialog: MatDialog,
              public loader: LoadingService) {
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
    this.dataSource.loadData('/current-leases', { size: '5', page: '0', sort: 'leaseStartDate,desc'}, [{ name: 'retailerId', val: this.id }]);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();
  }

  loadLeases() {
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'leaseStartDate,desc'};
    this.dataSource.loadData('/current-leases', page, [{ name: 'retailerId', val: this.id }]);
  }

  getLeaseStatus(lease: LeaseSummary) {
    switch (lease.leaseStatus) {
      case 'APPROVED':
        return { status: this.utils.displayStatus('APPROVED'), icon: 'check_circle', outline: true, className: 'success-chip'};
      case 'ACTIVE':
        return { status: this.utils.displayStatus('ACTIVE'), icon: 'check_circle', outline: false, className: 'success-chip'};
      case 'EXPIRED':
        return { status: this.utils.displayStatus('EXPIRED'), icon: 'check_circle', outline: false, className: 'disabled-chip'};
      case 'TERMINATED':
        return { status: this.utils.displayStatus('TERMINATED'), icon: 'cancel', outline: false, className: 'disabled-chip'};
      default:
        return { status: this.utils.displayStatus('PLANNED'), icon: 'check_circle', outline: true, className: 'warn-chip'};
    }
  }

  createLease() {
    const occupancyType = this.tenantType == TenantType.TENANT ? OccupancyType.SINGLE : OccupancyType.SHARED;
    const lease = new LeaseSummary({ retailerId: this.id, retailerCompanyName: this.companyName, occupancyType });
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: 'NEW_LEASE_FOR_RETAILER',
        lease: lease,
        header: 'Create a new lease',
        offering: this.offering
      }
    })
  }

  getRentalType(code: string) {
    return this.leasingService.getRentalType(code);
  }

}
