import {HttpClient} from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSidenav} from "@angular/material/sidenav";
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DomSanitizer} from '@angular/platform-browser';
import {ChartConfiguration, ChartData} from 'chart.js';
import {lastValueFrom} from 'rxjs';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {GenericTypeDef} from '../../../core/model/generic-type-def.model';
import {DateUtilsService} from '../../../shared/services/dateUtils.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {industryColors} from '../../dashboards/chart-options/chart-colors.model';
import {defaultDoughnutChartOptions} from '../../dashboards/chart-options/chart-options.model';
import {ChartOptionsService} from "../../dashboards/chart-options/chart-options.service";
import {DashboardUtilsService} from '../../dashboards/dashboard-utils.service';
import {
  DashboardsPerformanceReportService
} from "../../dashboards/dashboards-performance-report/dashboards-performance-report.service";
import {SharedServicesService} from '../../settings/service/shared-services.service';
import {EditSingleSpaceComponent} from "../edit-single-space/edit-single-space.component";
import {ListSpacesComponent} from "../list-spaces/list-spaces.component";
import {
  DailyDistrictOccupancy,
  LifeTimeDistrictOccupancyStats,
  OfferingSpacesInterface
} from '../model/offering-spaces.interface';
import {ManageSpacesService} from '../service/manage-spaces.service';

@Component({
  selector: 'app-manage-spaces',
  templateUrl: './manage-spaces.component.html',
  styleUrls: ['./manage-spaces.component.scss']
})
export class ManageSpacesComponent extends BasePageComponent implements OnInit, AfterViewInit {
//list and edit space
  @ViewChild("editSpaceComponent") editSpaceComponent?: EditSingleSpaceComponent;
  @ViewChild("listSpacesComponent") listSpacesComponent?: ListSpacesComponent;
  @ViewChild('rightSidenav') sidenav!: MatSidenav;

  selectedSpace: OfferingSpacesInterface | null = null;
  selectedSpaceDirty: boolean = false;
  addNewSpace: Event | null = null;

  previewImageSrc: string | null = null;
  selectedTabIndex: number;

  showingActions: boolean = false;
  showUploadAction: boolean = false;

  availableSpacesForm: FormGroup;
  unavailableSpacesForm: FormGroup;

  industryOccupancy: number = 0;

  statCurrentOccupancy: DailyDistrictOccupancy | null = null;
  statLifetimeOccupancy: LifeTimeDistrictOccupancyStats | null = null;

  industryPerformanceByTradingDensityChartData: ChartData<'doughnut'> = {labels: [], datasets: [{data: []}]};
  industryColors = industryColors.filter(f => f.code != 'UNOCCUPIED');
  industryPerformanceByTradingDensityLoading: boolean = false;

  industryPerformanceByTradingDensityChartOptions: ChartConfiguration<'doughnut'>['options'];
  dashboardData: any;

  allIndustries: GenericTypeDef[] = [];

  private dashboardService: DashboardsPerformanceReportService;

  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private spaceService: ManageSpacesService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private dashboardUtils: DashboardUtilsService,
              private sharedServices: SharedServicesService,
              private chartOptionsService: ChartOptionsService,
              private httpClient: HttpClient) {
    super(injector);
    this.dashboardService = new DashboardsPerformanceReportService(this.httpClient);
    this.matIconRegistry.addSvgIcon('person_home', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/person_home.svg'));
    this.matIconRegistry.addSvgIcon('stacked_lines', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/stacked_lines.svg'));
    this.matIconRegistry.addSvgIcon('circle_underscore_check', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/underscore_check_encircled.svg'));

    this.availableSpacesForm = new FormGroup({
      spaceName: new FormControl(''),
      locked: new FormControl(false)
    });

    this.unavailableSpacesForm = new FormGroup({
      spaceName: new FormControl(''),
      locked: new FormControl(true)
    });

    if (this.offering && this.offering.floorPlanImage) {
      //todo: add the image width component

      /*if (this.offering.floorPlanImage!.startsWith('https://ips.') && !this.offering.floorPlanImage!.endsWith('&height=260')) {
        this.offering.floorPlanImage += '&height=260';
        console.log("Updated: " + this.offering.floorPlanImage);
      }*/
      this.previewImageSrc = this.offering.floorPlanImage;
    }

    if (this.data.selectedTabCode === 'floor_plan') {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }

    this.dashboardData = {offering: this.offering}

    this.spaceService.getCurrentOccupancy()
      .subscribe({
        next: (value: DailyDistrictOccupancy) => {
          this.statCurrentOccupancy = value;
        },
        error: err => {
          console.log(err);
        }
      });
    this.spaceService.getLifetimeOccupancyStats()
      .subscribe({
        next: (value: LifeTimeDistrictOccupancyStats) => {
          this.statLifetimeOccupancy = value;
        },
        error: err => {
          console.log(err);
        }
      });

    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultDoughnutChartOptions))!;
    this.chartOptionsService.hideLegend(chartOptions!);
    this.chartOptionsService.setTooltips(chartOptions!, [], [], ['Industry by Occupancy'])!;
    //@ts-ignore
    this.industryPerformanceByTradingDensityChartOptions = chartOptions;
  }

  ngOnInit() {
    //  load chart data
    this.loadIndustries();
    this.loadIndustryPerformanceByTradingDensityChart();
  }

  loadIndustries() {

    lastValueFrom(this.sharedServices.getAllIndustryCategories())
      .then((res: { content: GenericTypeDef [] }) => {
        this.allIndustries = res.content;
      })
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.sidenav.open(), 200);
  }

  upload() {
    //todo fire event to trigger upload in the child component

  }

  getCurrentOffering(): string {
    return this.offering!.label;
  }

  switchTabs($event: MatTabChangeEvent): void {
    if ($event.index === 0) {
      this.data.selectedTabCode = 'spaces'
      // this.loadAllSpaces();//todo may need if spaces are null
    } else {
      this.data.selectedTabCode = 'floor_plan';
    }
  }

  clearUpload(): void {
    //todo fire event to trigger upload in the child component
  }


  getOfferingGLA(): string {
    return (this.statLifetimeOccupancy) ? this.utils.displayCompactNumber(this.statLifetimeOccupancy.avgGlaSqm) : '0';
  }

  getOfferingAvgPrice(): string {
    return (this.statLifetimeOccupancy) ? this.utils.displayCompactNumber(this.statLifetimeOccupancy.avgRentalPricingPerSqmValue) : '0';
  }

  getOfferingAvgTerm(): string {
    return (this.statLifetimeOccupancy) ? this.spaceService.displayDuration(this.statLifetimeOccupancy.avgTerm) : '0 months';
  }

  loadIndustryPerformanceByTradingDensityChart() {

    this.industryPerformanceByTradingDensityLoading = true;

    const start = this.dashboardData.offering.offeringStartDate;

    this.spaceService.getIndustryByCategoryChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(new Date())!).subscribe({
      next: value => {
        const series = value.chartSeries[0];

        this.industryOccupancy = Object(value.params)['occupancy'];

        this.industryPerformanceByTradingDensityChartData = {
          labels: series.chartData.map(m => this.getIndustryName(m.label)),
          datasets: [
            {
              label: series.name,
              data: series.chartData.map(m => m.data),
              backgroundColor: series.chartData.map(m => this.dashboardUtils.getIndustryColors(m.label)),
              borderColor: series.chartData.map(m => this.dashboardUtils.getIndustryColors(m.label)),
            }
          ]
        }
        this.industryPerformanceByTradingDensityLoading = false;
      },
      error: err => {
        console.log(err);
        this.industryPerformanceByTradingDensityLoading = false;
      }
    })
  }


  getIndustryName(code: string) {
    return this.dashboardUtils.getIndustryName(code);
  }

  getCurrentOccupancy(): string {
    return (this.statCurrentOccupancy) ? this.utils.displayCompactNumber(this.statCurrentOccupancy.percentageOccupancy) : '0';
  }

  getOfferingAvgOccupancy(): string {
    return (this.statLifetimeOccupancy) ? this.utils.displayCompactNumber(this.statLifetimeOccupancy.avgPercentageOccupancy) : '0';
  }

  getCurrentSize(): string {
    return (this.statCurrentOccupancy) ? this.utils.displayCompactNumber(this.statCurrentOccupancy.glaSqm) : '0';
  }
}

