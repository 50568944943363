import {Component} from '@angular/core';
import {BasePageComponent} from '../../page-content/base-page.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent extends BasePageComponent{

}
