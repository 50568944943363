import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-responsive-drawer',
  templateUrl: './responsive-drawer.component.html',
  styleUrls: ['./responsive-drawer.component.scss']
})
export class ResponsiveDrawerComponent {

  // Responsive drawer
  @ContentChild('drawerContent') drawerContent!: TemplateRef<any>;
  @ViewChild('drawer') drawer!: MatDrawer;
  @Input() drawerOpened = false;
  @Input() closeDrawerOnSubmit = false;
  @Output() onDrawerSubmit = new EventEmitter();
  @Output() onDrawerOpen = new EventEmitter();
  @Output() onDrawerClose = new EventEmitter();

  //Drawer handlers
  openDrawer() {
    this.drawerOpened = true;
    this.drawer.open();
    this.onDrawerOpen.emit()
  }

  closeDrawer() {
    this.drawerOpened = false;
    this.drawer.close();
    this.onDrawerClose.emit({submit: this.closeDrawerOnSubmit});
  }

  submitDrawer(event: any) {
    this.onDrawerSubmit.emit(event);
    if (this.closeDrawerOnSubmit) {
      this.closeDrawer();
    }
  }

}
