import {Injectable} from '@angular/core';
import {UserNotificationInterface} from '../interfaces/user-notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() {
  }

  getNotificationsForUser(): UserNotificationInterface[] {
    return [{
      data: 'notification one'
    }, {
      data: 'notification two'
    }]
  }

  notificationsPreferenceChanged(receiveNotifications: boolean): void {

  }
}
