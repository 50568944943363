<ng-container *ngIf="!showLinksOnly && !showCopyrightOnly; else linkText">
  <div class="p3 page-footer" style="display: flex; flex-direction: row">
    <footer>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
          <span class="link-default" fxLayout="row" fxLayoutGap="5px">
            <a class="link-default btn-cursor" (click)="openTermsAndConditionsLink()">Terms of Use</a>
            <span> | </span>
            <a class="link-default btn-cursor" (click)="openPrivacyPolicyLink()">Privacy Policy</a>
          </span>
        <div class="body-2-default pb-1">By logging in, you agree to our Terms of use and acknowledge that you have read
          our Privacy Policy.
        </div>
      </div>
    </footer>
  </div>
</ng-container>

<div *ngIf="!showLinksOnly || showCopyrightOnly === true" class="body-2-default pb-3 ps-4 bottom-left-text">© Nter {{getDate | date: 'yyyy'}}. All rights reserved.
</div>

<ng-template #linkText>
  <div *ngIf="!showCopyrightOnly">
    <span class="link-default" fxLayout="row" fxLayoutGap="5px">
      <a class="link-default btn-cursor" (click)="openTermsAndConditionsLink()">Terms of Use</a>
      <span> | </span>
      <a class="link-default btn-cursor" (click)="openPrivacyPolicyLink()">Privacy Policy</a>
    </span>
  </div>

</ng-template>
