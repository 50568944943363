import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MaterialModule} from "../../../../material.module";
import {UploadResponse} from "../csv-import.model";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-csv-import-report',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './csv-import-report.component.html',
  styleUrls: ['./csv-import-report.component.scss']
})
export class CsvImportReportComponent implements AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  dataSource = new MatTableDataSource<string>();
  report!: UploadResponse;
  pageSize: number = 5;

  displayedColumns = ['item', 'message'];

  constructor(public dialogRef: MatDialogRef<CsvImportReportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { uploadReport: UploadResponse },
              public utils: UtilsService) {
    this.report = data.uploadReport;
    this.dataSource = new MatTableDataSource<string>(data.uploadReport.errorsMessages);
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }
  }

  getItem(msg: string) {
    const startIndex = msg.indexOf('[');
    const endIndex = msg.indexOf(']');

    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      return msg.slice(startIndex + 1, endIndex).trim();
    } else {
      return null;
    }
  }

  getMessage(msg: string) {
    const startIndex = msg.indexOf('[');
    const endIndex = msg.indexOf(']');

    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      return msg.slice(endIndex + 2).trim();
    } else {
      return null;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
