import {Type} from '@angular/core';
import {PageOffering} from './side-nav.model';

export class PageItemModel {
  constructor(public component: Type<any>,
              public name: string,
              public heading: string,
              public data: any,
              public id: string,
              public offeringId?: string,
              public sectionCode?: string,
              public pageCode?: string,
              public offering?: PageOffering) {
  }
}
