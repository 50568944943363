import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GenericService} from "../../../shared/services/generic.service";
import {DailyTransactionsSummary, GenericChart} from "./transactions.model";

@Injectable({
  providedIn: 'root'
})
export class TransactionsService extends GenericService<DailyTransactionsSummary> {

  constructor(http: HttpClient) {
    super(http, environment.apiTransactHost + `/console/offering/district/accounts`);
  }

  getDailyTransactionChartData(params?: {name: string, val: any}[]): Observable<GenericChart> {
    let requestParams = new HttpParams();
    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {requestParams = requestParams.append(ea.name, ea.val)});
    }
    return this.http.get<GenericChart>(this.getBaseUrl + `/transactions/summary/chart`, {params: requestParams})
  }

  getAllForDateRange(params?: {name: string, val: any}[]) {
    let requestParams = new HttpParams();
    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {requestParams = requestParams.append(ea.name, ea.val)});
    }
    return this.http.get<DailyTransactionsSummary[]>(this.getBaseUrl + `/transactions/summary`, {params: requestParams})
  }

  get getBaseUrl(): string {
    return environment.apiTransactHost + `/console/offering/district/accounts`;
  }
}
