<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-default">{{ header }}</div>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="body-1-regular-1">Existing retailer?</span>
      <mat-checkbox [checked]="existingRetailer" (change)="existingRetailer = !existingRetailer"></mat-checkbox>
    </div>

    <form [formGroup]="subLeaseForm" class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">

      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!existingRetailer">
        <div class="full-width" fxLayout="column" fxLayoutGap="7px" fxFlex="48">
          <span class="body-3-default">First Name</span>
          <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
            <input matInput type="text" placeholder="Input text" formControlName="firstName">
            <mat-error class="link-error">{{getErrorMessage('firstName')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="full-width" fxLayout="column" fxLayoutGap="7px" fxFlex="48">
          <span class="body-3-default">Last Name</span>
          <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
            <input matInput type="text" placeholder="Input text" formControlName="lastName">
            <mat-error class="link-error">{{getErrorMessage('lastName')}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="!existingRetailer">
        <span class="body-3-default">Email</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="email" placeholder="Input text" formControlName="email">
          <mat-error class="link-error">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="!existingRetailer">
        <span class="body-3-default">Company Name</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="email" placeholder="Input text" formControlName="companyName">
          <mat-error class="link-error">{{getErrorMessage('companyName')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="!existingRetailer">
        <span class="body-3-default">Industry And Product Collection</span>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;" hideRequiredMarker>
          <mat-select [(value)]="selectedIndustries" class="custom-select-field-value"
                      formControlName="industryTypes" [multiple]="true" placeholder="Industry And Product Collection">
            <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
              <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                         fxLayoutGap="5px">
                <span>{{group.name}}</span>
                <mat-icon class="btn-cursor">add</mat-icon>
              </mat-label>
              <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                          class="custom-select-field-option">{{ ind.name }}</mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error class="link-error">{{getErrorMessage('industryTypes')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="existingRetailer">
        <span class="body-3-default">Choose a retailer</span>
        <mat-form-field [ngClass]="disableRetailerSelection() ? 'custom-text-autofill' : 'custom-text-field'" appearance="outline" style="width: 100%;">
          <input class="input-field" matInput type="text"
                 [formControl]="searchTerm"
                 [matAutocomplete]="auto"
                 [maxlength]="!!lease ? 0 : null"
                 [placeholder]="getPlaceHolder()"
                 [attr.disabled]="!!lease">
          <mat-spinner class="me-2" matSuffix diameter="20" color="primary" *ngIf="loadingSearch"></mat-spinner>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectRetailer($event)">
            <mat-option *ngFor="let option of filteredRetailers | async" [value]="option">
              {{option.id}}. {{option.companyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose a lease period</span>
        <mat-form-field class="custom-date-picker" appearance="outline" style="width: 100%;">
          <mat-date-range-input [rangePicker]="picker"
                                [min]="this.dateUtils.displayShortDate(this.parentLease.leaseStartDate)"
                                [max]="this.dateUtils.displayShortDate(this.parentLease.leaseEndDate)">
            <input matStartDate placeholder="Custom" formControlName="startDate" />
            <input matEndDate formControlName="endDate"/>
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="body-3-default">Space</span>
          <span class="body-3-default" [class.error-form-heading]="subLeaseForm.get('occupiedSpace')!.hasError('max')">
            Available space: {{ availableSpace }} / {{ parentLease.totalSize }} m²
          </span>
        </div>

        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="number" placeholder="Input space" formControlName="occupiedSpace">
          <mat-error class="link-error">{{getErrorMessage('occupiedSpace')}}</mat-error>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="15px">
    <button class="button-primary" mat-flat-button (click)="submitLease()" [disabled]="loader.loading$ | async">
      <mat-icon>check</mat-icon>
      <div class="button-text">Save Changes</div>
    </button>
  </div>

</div>
