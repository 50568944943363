export enum SessionKey {
  CURRENT_USER_KEY = 'currentUser',
  USER_GRANTS_KEY = 'userGrants',
  TOKEN_KEY = 'token',
  TOKEN_EXPIRY_KEY = 'tokenExpiresOn',
  CURRENT_LEASE_KEY = 'currentLease',
  CURRENT_DISTRICT = 'currentDistrict',
  CURRENT_LANDLORD = 'currentLandlord',
  CURRENT_WEB_CLIENT = 'currentWebClient',
  CURRENT_LANDLORD_PROPERTY = 'currentLandlordProperty',
  CURRENT_LANDLORD_ID = 'landlordUuid',
  CURRENT_USER_ID = 'userId',
  CURRENT_OFFERING_ID = 'currentOfferingUuid',
  CURRENT_LANDLORD_CODE = "currentLandlordCode",
  ALLOWED_LANDLORD_LOCATIONS = "allowedLandlordLocations",
}
