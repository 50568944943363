import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {IndustryCategory, IndustryType} from "../../../features/leads/industry.model";
import {IndustriesPickerService} from "./industries-picker.service";

@Component({
  selector: 'app-industries-picker',
  templateUrl: './industries-picker.component.html',
  styleUrls: ['./industries-picker.component.scss']
})
export class IndustriesPickerComponent implements OnInit {
  @Input() selectedIndustries: FormControl<string[] | null> = new FormControl([], Validators.required);
  allIndustries: IndustryCategory[] = [];

  constructor(private industriesService: IndustriesPickerService) {
  }

  ngOnInit() {
    this.loadIndustries()
  }

  loadIndustries(){
    this.industriesService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
      }
    })
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
        this.updateSelected();
      }
    });
  }

  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries.setValue(newSelects);
  }

  getErrorMessage() {
    if (this.selectedIndustries.hasError('required')) return 'You must select an industry';
    return null;
  }
}
