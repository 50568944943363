import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {OfferingNotificationsService} from '../../settings/service/offering-notifications.service';
import {LoadingService} from '../../../core/services/loading.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {
  BaseTemplateModel,
  EmailTemplateModel,
  OfferingNotificationType
} from '../../settings/template-builder/model/offering-template.model';
import {lastValueFrom} from 'rxjs';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CurrentContextService} from '../../../core/services/security/current-context.service';

@Component({
  selector: 'app-base-template-builder',
  templateUrl: './base-template-builder.component.html',
  styleUrls: ['./base-template-builder.component.scss']
})
export class BaseTemplateBuilderComponent extends BasePageComponent implements OnInit {
  isTemplateSelected: boolean = false;
  selectedTemplate: BaseTemplateModel | null = null;
  offeringTemplates: BaseTemplateModel[] = [];
  retailTemplates: BaseTemplateModel[] = [];
  systemTemplates: BaseTemplateModel[] = [];
  internalTemplates: BaseTemplateModel[] = [];

  currentLandlord: string;
  protected readonly Object = Object;

  constructor(injector: Injector,
              private offeringNotificationService: OfferingNotificationsService,
              private currentContext: CurrentContextService,
              public loader: LoadingService,
              public utils: UtilsService) {
    super(injector);
    this.currentLandlord = currentContext.getCurrentLandlordId()!;
  }

  ngOnInit(): void {
    lastValueFrom(this.offeringNotificationService.getAllBaseNotifications())
      .then((res: BaseTemplateModel[]) => {
        this.offeringTemplates = res;
        this.splitTemplatesByType();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  selectTemplate(selected: BaseTemplateModel): void {
    this.selectedTemplate = selected;
    this.isTemplateSelected = true;
  }

  saveAllChanges(): void {
    this.saveTemplateChange(this.selectedTemplate, true)
  }

  saveTemplateChange(template: BaseTemplateModel | null, isSelected: boolean): void {
    if (template) {
      lastValueFrom(this.offeringNotificationService.saveBaseNotification(template))
        .then((res: BaseTemplateModel) => {
          if (isSelected) {
            this.selectedTemplate = res;
            this.isTemplateSelected = true;
          }
          let index: number = this.offeringTemplates.findIndex((item) => item.uuid == template.uuid);
          if (index >= 0) {
            this.offeringTemplates[index] = res;
          }
          this.splitTemplatesByType();
        })
        .catch((error: string) => {
          console.log(error);
        });
    }
  }

  splitTemplatesByType() {
    this.retailTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.RETAILER);
    this.systemTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.SYSTEM);
    this.internalTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.LANDLORD);
  }

  changeNotificationEnabled($event: MatSlideToggleChange, template: BaseTemplateModel): void {
    template.active = $event.checked;
    this.saveTemplateChange(template, false);
  }

  changeSelectedTemplate($event: EmailTemplateModel | null): void {
    this.selectedTemplate = $event as BaseTemplateModel;
    this.isTemplateSelected = !this.selectedTemplate == null;
  }
}
