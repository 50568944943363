import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LeaseCreateMethod} from "../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {AgreementType, LeaseStatus, LeaseSummary, OccupancyType} from "../../leasing.model";
import {SelectionModel} from "@angular/cdk/collections";
import {Retailer} from "../../../leads/retailers/retailers.model";
import {MatPaginator} from "@angular/material/paginator";
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {UtilsService} from "../../../../shared/services/utils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {tap} from "rxjs";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {LeaseViewComponent} from "../../lease-view/lease-view.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {LeasingService} from "../../leasing.service";
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {MatDialog} from "@angular/material/dialog";
import {CreateALeaseDialogComponent} from "../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-lease-schedule-list',
  templateUrl: './lease-schedule-list.component.html',
  styleUrls: ['./lease-schedule-list.component.scss']
})
export class LeaseScheduleListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'companyName', 'startDate', 'endDate', 'spaceName', 'size', 'rentalType', 'monthlyRentalFee', 'occupancyType','leaseStatus'];

  dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
  selection = new SelectionModel<Retailer>(true, []);

  leaseStatuses = [LeaseStatus.INVALID, LeaseStatus.PLANNED, LeaseStatus.APPROVED, LeaseStatus.ACTIVE, LeaseStatus.EXPIRED];
  occupancyTypes = [OccupancyType.SINGLE, OccupancyType.SHARED];

  filterForm: FormGroup;
  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 2, TimeFrames.DAYS),
    new CustomDateRangeModel('2', 1, TimeFrames.WEEK),
    new CustomDateRangeModel('3', 1, TimeFrames.MONTH),
  ]

  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private tabService: TabManagementService,
              private leasingService: LeasingService,
              private matDialog: MatDialog,
              public loader: LoadingService) {
    super(injector);

    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      spaceNames: new FormControl(null),
      leaseStatus: new FormControl([]),
      occupancyType: new FormControl([])
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
    this.dataSource.loadData('/lease-schedule', {size: '10', page: '0', sort: 'leaseStartDate,desc'},
      [
        {name: 'statusList', val: this.leaseStatuses},
        {name: 'typeList', val: this.occupancyTypes},
        {name: 'agreementType', val: AgreementType.LEASE}
      ]);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadLeases());
  }

  loadLeases() {
    let filters = [
      { name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value},
      { name: 'spaceNames', val: this.filterForm.get('spaceNames')?.value},
      { name: 'statusList', val: this.filterForm.get('leaseStatus')?.value.length === 0 ? this.leaseStatuses : this.filterForm.get('leaseStatus')?.value},
      { name: 'leaseStartDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      { name: 'leaseEndDate', val: this.dateUtils.displayShortDate(this.dateRange.end)},
      { name: 'typeList', val: this.filterForm.get('occupancyType')?.value.length === 0 ? this.occupancyTypes : this.filterForm.get('occupancyType')?.value },
      { name: 'agreementType', val: AgreementType.LEASE }
    ];

    filters = filters.filter(item => item.val !== null && item.val !== undefined);
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'leaseStartDate,desc'}
    this.dataSource.loadData('/lease-schedule', page, filters);
  }

  openLeaseViewTab(id: string, occupancyType: OccupancyType, agreementType: AgreementType) {
    let payload: PageEventInterface = {
      componentToRegister: LeaseViewComponent,
      pageName: 'Lease ' + this.utils.displayUuid(id),
      pageHeader: this.offering!.label,
      data: { id, occupancyType, agreementType },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leasing',
      pageCode: 'lease-schedule',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  getLeaseStatus(lease: LeaseSummary) {
    switch (lease.leaseStatus) {
      case LeaseStatus.APPROVED:
        return { status: this.utils.displayStatus('APPROVED'), icon: 'check_circle', outline: true, className: 'success-chip'};
      case LeaseStatus.ACTIVE:
        return { status: this.utils.displayStatus('ACTIVE'), icon: 'check_circle', outline: false, className: 'success-chip'};
      case LeaseStatus.EXPIRED:
        return { status: this.utils.displayStatus('EXPIRED'), icon: 'check_circle', outline: false, className: 'disabled-chip'};
      case LeaseStatus.TERMINATED:
        return { status: this.utils.displayStatus('TERMINATED'), icon: 'cancel', outline: false, className: 'disabled-chip'};
      case LeaseStatus.INVALID:
        return {status: this.utils.displayStatus('INVALID'), icon: 'cancel', outline: false, className: 'disabled-chip'};
      default:
        return { status: this.utils.displayStatus('PLANNED'), icon: 'check_circle', outline: true, className: 'warn-chip'};
    }
  }

  displayRentalType(type: string | null) {
    return this.leasingService.getRentalType(type);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadLeases();
  }

  createLease() {
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: LeaseCreateMethod.NEW_LEASE,
        lease: null,
        header: 'Create a new lease',
        offering: this.offering
      }
    })
  }

  protected readonly OccupancyType = OccupancyType;
}
