import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ApplicationAssessmentStatus, Retailer} from "../../retailers/retailers.model";
import {UtilsService} from "../../../../shared/services/utils.service";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {ApplicationsViewComponent} from "../applications-view/applications-view.component";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {ApplicationsService} from "../applications.service";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.scss']
})
export class ApplicationsListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'createDate', 'companyName', 'retailer', 'website', 'industryType', 'overallScore', 'action'];
  dataSource = new GenericDatasource<Retailer>(this.applicationsService);

  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              public tabService:TabManagementService,
              private paginatorService: PaginatorService,
              private applicationsService: ApplicationsService,
              public loader: LoadingService) {
    super(injector);
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<Retailer>(this.applicationsService);
    this.dataSource.loadData('', {
      size: '10',
      page: '0',
      sort: ['overallScore,desc', 'retailerRegistrationDate,desc']
    }, [{
      name: 'curated',
      val: ApplicationAssessmentStatus.PENDING
    }]);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadRetailers()))
      .subscribe();
  }

  loadRetailers() {
    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: ['overallScore,desc', 'retailerRegistrationDate,desc']
    };
    this.dataSource.loadData('', page, [{name: 'curated', val: ApplicationAssessmentStatus.PENDING}]);
  }

  openApplicationsViewTab(id: string) {
    let payload: PageEventInterface = {
      componentToRegister: ApplicationsViewComponent,
      pageName: 'Application ' + id,
      pageHeader: this.offering!.label,
      data: { id: id },
      id: this.utils.generateUuidWithPrefix('application'),
      offeringId: this.offering!.uuid,
      sectionCode: 'leads',
      pageCode: 'applications',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  displayIndustriesList(retailer: Retailer): string {
    const industryArray = retailer.industries.map(m => m.industryType.name);
    return this.utils.displayListAsString(industryArray);
  }

}
