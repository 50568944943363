<div class="full-width" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="space-between center">
    <span class="subtitle-default">Lease Details</span>
  </div>

  <div class="full-width" fxLayout="column" fxLayoutGap="20px" fxFlexOffset="30px">
    <div class="info-card-grey" fxLayout="column" *ngIf="leaseSummary" fxLayoutGap="10px">

      <app-info-panel *ngFor="let notification of sortedNotifications | keyvalue"
                      [message]="notification.value.message" [fullWidth]="true"
                      [type]="notification.value.type"
                      [buttons]="notification.value.buttons"
                      [buttonLabels]="notification.value.buttonLabels"
                      [buttonDisabled]="notification.value.buttonDisabled"
                      [fxHide]="!notification.value.display"
                      (emitEvent)="infoPanelRequest(notification.value.code, $event)">
      </app-info-panel>

      <app-info-panel *ngIf="leaseApprovalPanel"
                      [message]="leaseApprovalPanel.message" [fullWidth]="true"
                      [type]="leaseApprovalPanel.type"
                      [buttons]="leaseApprovalPanel.buttons"
                      [buttonLabels]="leaseApprovalPanel.buttonLabels"
                      [buttonDisabled]="leaseApprovalPanel.buttonDisabled"
                      [fxHide]="!leaseApprovalPanel.display"
                      (emitEvent)="infoPanelRequest(leaseApprovalPanel.code == 'lease_approval_active' ? 'lease_approval_active' : 'lease_approval', $event)">
      </app-info-panel>
    </div>

    <div class="info-card" fxLayout="column" *ngIf="leaseSummary">

      <span class="heading-default">Lease Summary</span>

      <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="20px">
        <div fxLayout="column" fxLayoutGap="15px" fxFlex="32">
          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Occupancy Type</mat-label>
              <input matInput type="text" [value]="utils.displayStatus(leaseSummary.occupancyType!)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline" (click)="openRetailerViewTab(leaseSummary.retailerId!.toString(), leaseSummary.retailerCompanyName!)">
              <mat-label class="custom-form-field-link">Retailer ID</mat-label>
              <input matInput type="text" [value]="leaseSummary.retailerId + ' ' + leaseSummary.retailerCompanyName" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Commencement Date</mat-label>
              <input matInput type="text" [value]="dateUtils.displayShortDate(leaseSummary.leaseStartDate)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Location</mat-label>
              <input matInput type="text" [value]="leaseSummary.leaseLocation" disabled>
            </mat-form-field>
          </div>

          <div *ngIf="!isHeadLease">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Rental Type</mat-label>
              <input matInput type="text" [value]="leaseSummary.rentalType ? displayRentalType(leaseSummary.rentalType) : '-'" disabled>
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="column" fxLayoutGap="15px" fxFlex="32">

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Agreement Type</mat-label>
              <input matInput type="text" [value]="utils.displayStatus(leaseSummary.agreementType!)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Lease ID</mat-label>
              <input matInput type="text" [value]="utils.displayUuid(leaseSummary.uuid)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Expiry Date</mat-label>
              <input matInput type="text" [value]="dateUtils.displayShortDate(leaseSummary.leaseEndDate)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Space Name or Store Number</mat-label>
              <input matInput type="text" [value]="leaseSummary.spaceNames" disabled>
            </mat-form-field>
          </div>

          <div *ngIf="!isHeadLease">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Monthly Rental Fee</mat-label>
              <input matInput type="text" [value]="utils.displayRandAmount(leaseSummary.totalMonthlyFee!) + (leaseSummary.rentalType == 'TURNOVER_PERCENTAGE' ? ' + ' + leaseSummary.turnoverPercentageValue + '%' : '')" disabled>
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="column" fxLayoutGap="15px" fxFlex="14">

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Current Lease Status</mat-label>
              <input matInput disabled>
              <div matPrefix [class]="status.className" class="ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="getLeaseStatus(leaseSummary) as status">
                <div fxLayout="row" fxLayoutAlign="center center">
                  <mat-icon [class.material-icons-outlined]="status.outline">{{status.icon}}</mat-icon>
                </div>
                <span class="footer-default">{{status.status}}</span>
              </div>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Lease Period</mat-label>
              <input matInput type="text" [value]="dateUtils.displayDateDuration(leaseSummary.leaseStartDate, leaseSummary.leaseEndDate)" disabled>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Size</mat-label>
              <input matInput type="text" [value]="leaseSummary.totalSize ? leaseSummary.totalSize.toString() + ' m²' : null" disabled>
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="column" fxLayoutGap="15px" fxFlex="22"></div>

      </div>
    </div>
  </div>

</div>
