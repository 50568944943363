import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LocationOffering} from "../../../core/model/side-nav.model";
import {DateRange} from "@angular/material/datepicker";
import {PanelType} from "../../../shared/components/info-panel/info-panel.component";
import {UtilsService} from "../../../shared/services/utils.service";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {MatTableDataSource} from "@angular/material/table";
import {
  DashboardChart,
  MonthlyRetailerStats,
  MonthlyStat,
  RetailerProductPerformance,
  StatisticType
} from "../dashboards.model";
import {MatPaginator} from "@angular/material/paginator";
import {PaginatorService} from "../../../shared/services/paginator.service";
import {DashboardsRetailerReportService} from "./dashboards-retailer-report.service";
import {FormControl} from "@angular/forms";
import {ApplicationAssessmentStatus, Retailer} from "../../leads/retailers/retailers.model";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {CustomDateRangeModel, TimeFrames} from "../../../shared/components/custom-date-filter/custom-date-range.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {RetailersService} from "../../leads/retailers/retailers.service";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {map, Observable, startWith} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DashboardsRetailerReportChartConfig} from "./dashboards-retailer-report-chart-config";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-dashboards-retailer-report',
  templateUrl: './dashboards-retailer-report.component.html',
  styleUrls: ['./dashboards-retailer-report.component.scss']
})
export class DashboardsRetailerReportComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('bestSellersPaginator') bestSellersPaginator!: MatPaginator;
  @ViewChild('forecastPaginator') forecastPaginator!: MatPaginator;

  @ViewChild('sortProducts', { read: MatSort, static: false }) set productsContent(sort: MatSort) {
    if (this.bestSellersDatasource) this.bestSellersDatasource.sort = sort;
  }
  @ViewChild('sortInventory', { read: MatSort, static: false }) set inventoryContent(sort: MatSort) {
    if (this.inventoryForecastDatasource) this.inventoryForecastDatasource.sort = sort;
  }

  chartConfig: DashboardsRetailerReportChartConfig;
  dashboardService: DashboardsRetailerReportService;

  // best stats

  bestStats: MonthlyRetailerStats = new MonthlyRetailerStats({
    bestMonthlyStats: [],
    tradingOutsideOfLeasePeriod: false
  });

  // dashboard charts

  salesPerformanceChart: DashboardChart<'bar' | 'line'>;
  rentPerformanceChart: DashboardChart<'bar'>;
  avgBasketSizeChart: DashboardChart<'line'>;
  busiestSalesTimesChart: DashboardChart<'bar'>;
  busiestSalesDaysChart: DashboardChart<'bar'>;

  // dashboard tables

  bestSellersDatasource!: MatTableDataSource<RetailerProductPerformance>;
  inventoryForecastDatasource!: MatTableDataSource<RetailerProductPerformance>;

  bestSellersLoading: boolean = false;
  inventoryForecastLoading: boolean = false;

  displayedColumnsSellers: string[] = ['icon', 'productName', 'quantitySold', 'saleAmount', 'contributingPercentage'];
  displayedColumnsForecast: string[] = ['icon', 'productName', 'quantitySold', 'avgQuantitySold', 'currentInventoryLevel', 'weeksToLast'];

  // filtering

  dateRanges: DateRange<Date | null>[] = [
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null)
  ];

  customDateRangePresets1: CustomDateRangeModel[] = [];
  customDateRangePresets2: CustomDateRangeModel[] = [];
  dbOffering!: LocationOffering;

  topPercentSalesOptions: number[] = [20, 40, 60, 80, 100];

  topFilter: number = 100;

  // retailer search

  searchTerm = new FormControl('');
  loadingSearch: boolean = false;
  allRetailers!: Retailer[];
  selectedRetailer!: Retailer;
  filteredRetailers!: Observable<Retailer[]>;

  //

  constructor(
    public utils: UtilsService,
    public dateUtils: DateUtilsService,
    private paginatorService: PaginatorService,
    private retailersService: RetailersService,
    private dashboardUtils: DashboardUtilsService,
    httpClient: HttpClient,
    chartOptionsService: ChartOptionsService,
    injector: Injector) {
    super(injector);
    this.dashboardService = new DashboardsRetailerReportService(httpClient);
    this.chartConfig = new DashboardsRetailerReportChartConfig(this, chartOptionsService, this.dateUtils);
    this.dbOffering = this.data.offering;

    this.salesPerformanceChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.salesPerformance.options,
      chartType: 'line',
      stats: {
        totalSales: new MonthlyStat('Sales', 'totalSales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
        tradingDensity: new MonthlyStat('Trading density', 'tradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
      },
      loading: false
    };

    this.rentPerformanceChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentPerformance.options,
      chartType: 'bar',
      stats: {
        rentalRate: new MonthlyStat('Rental Rate', 'rentalRate', 'Rent charged per square meter for the leased space', StatisticType.CURRENCY_PER_SQM),
        rentRatio: new MonthlyStat('Rent Ratio', 'rentRatio', 'Sales to Rental fee ratio. \n The Rent ratio target is 30%', StatisticType.PERCENTAGE),
      },
      loading: false
    };

    this.avgBasketSizeChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.avgBasketSize.options,
      chartType: 'line',
      stats: {},
      loading: false
    };

    this.busiestSalesTimesChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.busiestSalesTimes.options,
      chartType: 'bar',
      stats: {
        salesPerHour: new MonthlyStat('Hourly sales', 'salesPerHour', 'Average sales made per hour', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.busiestSalesDaysChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.busiestSalesDays.options,
      chartType: 'bar',
      stats: {
        salesPerDay: new MonthlyStat('Daily sales', 'salesPerDay', 'Average sales made per day', StatisticType.CURRENCY),
      },
      loading: false
    };
  }

  ngOnInit() {
    this.setCustomDateRange();
    this.searchTerm.markAllAsTouched();
    this.loadRetailers();
  }

  ngAfterViewInit() {
    this.bestSellersPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.forecastPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }

  setCustomDateRange() {
    this.customDateRangePresets1 = this.dashboardUtils.customDateRangeChartPreset(this.dbOffering.offeringStartDate!);
    this.customDateRangePresets2 = this.dashboardUtils.customDateRangeTablePreset(this.dbOffering.offeringStartDate!);
  }

  loadBestMonthlyStats() {
    if (this.selectedRetailer) {
      const next = this.chartConfig.bestMonthlyStats.next;
      const error = this.chartConfig.bestMonthlyStats.error;
      this.dashboardService.refreshBestMonthlyStats(this.selectedRetailer.id!, next, error);
    }

  }

  loadSalesPerformanceChart() {
    if (this.selectedRetailer && this.dateRanges[0].start && this.dateRanges[0].end) {
      this.salesPerformanceChart.loading = true
      const start = this.dateRanges[0].start;
      const end = this.dateRanges[0].end;
      const next = this.chartConfig.salesPerformance.next;
      const error = this.chartConfig.salesPerformance.error;
      this.dashboardService.refreshSalesPerformanceChart(this.selectedRetailer.id!,
        this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadRentPerformanceChart() {
    if (this.selectedRetailer && this.dateRanges[1].start && this.dateRanges[1].end) {
      this.rentPerformanceChart.loading = true;
      const start = this.dateRanges[1].start;
      const end = this.dateRanges[1].end;
      const next = this.chartConfig.rentPerformance.next;
      const error = this.chartConfig.rentPerformance.error;
      this.dashboardService.refreshRentalRateRentRatioChart(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadAvgBasketSizeChart() {
    if (this.selectedRetailer && this.dateRanges[1].start && this.dateRanges[1].end) {
      this.avgBasketSizeChart.loading = true;
      const start = this.dateRanges[1].start;
      const end = this.dateRanges[1].end;
      const next = this.chartConfig.avgBasketSize.next;
      const error = this.chartConfig.avgBasketSize.error;
      this.dashboardService.refreshAvgBasketSizeChart(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBusiestSalesTimesChart() {
    if (this.selectedRetailer && this.dateRanges[2].start && this.dateRanges[2].end) {
      this.busiestSalesTimesChart.loading = true;
      const start = this.dateRanges[2].start;
      const end = this.dateRanges[2].end;
      const next = this.chartConfig.busiestSalesTimes.next;
      const error = this.chartConfig.busiestSalesTimes.error;
      this.dashboardService.refreshBusiestSalesTimesChart(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBusiestSalesDaysChart() {
    if (this.selectedRetailer && this.dateRanges[2].start && this.dateRanges[2].end) {
      this.busiestSalesDaysChart.loading = true;
      const start = this.dateRanges[2].start;
      const end = this.dateRanges[2].end;
      const next = this.chartConfig.busiestSalesDays.next;
      const error = this.chartConfig.busiestSalesDays.error;
      this.dashboardService.refreshBusiestSalesDaysChart(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBestSellersList() {
    if (this.selectedRetailer && this.dateRanges[3].start && this.dateRanges[3].end) {
      this.bestSellersLoading = true;
      const start = this.dateRanges[3].start;
      const end = this.dateRanges[3].end;
      const topPercent = this.topFilter;
      const next = this.chartConfig.bestSellersList.next;
      const error = this.chartConfig.bestSellersList.error;
      this.dashboardService.refreshBestSellers(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, topPercent, next, error);
    }
  }

  loadInventoryForecastList() {
    if (this.selectedRetailer && this.dateRanges[4].start && this.dateRanges[4].end) {
      this.inventoryForecastLoading = true;
      const start = this.dateRanges[4].start;
      const end = this.dateRanges[4].end;
      const next = this.chartConfig.inventoryForecastList.next;
      const error = this.chartConfig.inventoryForecastList.error;
      this.dashboardService.refreshInventoryForecast(this.selectedRetailer.id!, this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadRetailers() {
    this.loadingSearch = true;
    const page = {size: '1000', page: '0', sort: 'id,asc'}
    this.retailersService.getAll('', page, [{name: 'companyName', val: null}, {
      name: 'curated',
      val: ApplicationAssessmentStatus.APPROVED
    }]).subscribe({
      next: value => {
        this.allRetailers = value.content;
        this.loadingSearch = false;


        this.filteredRetailers = this.searchTerm.valueChanges.pipe(
          startWith(''),
          map((value: string | Retailer | null) => {
            if (typeof value == 'object') return this._filter(value!.companyName + value!.id)
            return this._filter(value || '')
          }),
        );
      },
      error: err => {
        console.log(err);
        this.loadingSearch = false;
      }
    })
  }

  private _filter(value: string): any[] {
    const filterValue = this._normalizeValue(value);
    return this.allRetailers.filter((retailer) => {
      return this._normalizeValue(retailer.companyName + retailer.id).includes(filterValue);
    });
  }

  private _normalizeValue(value: string): string {
    if (value) return value.toLowerCase().replace(/\s/g, '');
    return '';
  }

  loadCharts() {
    this.loadBestMonthlyStats();
    this.loadSalesPerformanceChart();
    this.loadRentPerformanceChart();
    this.loadAvgBasketSizeChart();
    this.loadBusiestSalesTimesChart();
    this.loadBusiestSalesDaysChart();
    this.loadBestSellersList();
    this.loadInventoryForecastList();
  }

  getDateRange(event: CustomDateRangeChangeEventModel, index: number) {
    this.dateRanges[index] = event.dateRange;
    switch (index) {
      case 0:
        this.loadSalesPerformanceChart();
        break;
      case 1:
        this.loadRentPerformanceChart();
        this.loadAvgBasketSizeChart();
        break
      case 2:
        this.loadBusiestSalesTimesChart();
        this.loadBusiestSalesDaysChart();
        break;
      case 3:
        this.loadBestSellersList();
        break;
      case 4:
        this.loadInventoryForecastList();
        break;
    }
  }

  selectRetailer(event: MatAutocompleteSelectedEvent) {
    this.selectedRetailer = event.option.value;
    this.loadCharts()
  }

  displayFn(value: Retailer) {
    if (value) {
      return value.id && value.companyName ? `${value.id}. ${value.companyName}` : '';
    }
    return '';
  }

  protected readonly PanelType = PanelType;
}

