import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-pre-auth-toolbar',
  templateUrl: './pre-auth-toolbar.component.html',
  styleUrls: ['./pre-auth-toolbar.component.scss']
})
export class PreAuthToolbarComponent {
  landlordLogo: string = '../assets/icons/default-nter-logo.svg';

  loading$ = this.loader.loading$;

  constructor(private currentContext: CurrentContextService, public loader: LoadingService) {
    setTimeout(() => {
      this.currentContext.getCurrentWebClient()
        .then((response) => {
          if (response) {
            this.landlordLogo = response.companyLogo!;
          }
        });
    }, 300)
  }

}
