<ng-container *ngIf="pages.length !== 0">
  <mat-tab-group class="nav-page-tab-content" preserveContent="true" [(selectedIndex)]="selectedTab"
                 (selectedTabChange)="onTabChange($event)" animationDuration="0ms" header-less-tabs>
    <!-- add to remove header header-less-tabs-->
    <mat-tab *ngFor="let page of pages; let i = index" style="width: 100%">
      <ng-template matTabContent *ngComponentOutlet="page.component; injector:getInjector(page)"></ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
<ng-container *ngIf="pages.length === 0">
  <div class="full-screen-height" fxLayout="column">
    <app-page-header [section]="'Home page'"
                     [fullHeight]="true"
                     [title]="getTitle()"
                     [description]="'Discover all the features you can use to operate and manage your property. Based on\n'+
'your role and set permissions you can click on the side menu to start.'">
    </app-page-header>
  </div>
</ng-container>
