<mat-sidenav-container class="sidenav-container body-1-regular-2">
  <!-- Left sidenav -->
  <mat-sidenav #leftSidenav mode="side" opened class="sidenav-left" [fixedInViewport]="false" [fixedTopGap]="0"
    [fixedBottomGap]="0">

    <div class="sidenav-left-content" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="upload-file-list-heading">
        <mat-form-field [formGroup]="availableSpacesForm" appearance="outline" class="custom-search-bar">
          <mat-label style="font-size: 14px !important;">Available spaces</mat-label>
          <input (keydown.enter)="searchAvailableForms()" formControlName="spaceName" matInput type="text" />
          <mat-icon class="material-icons-outlined search-icon-suffix" matIconSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <mat-list class="timeline-list-items-container">
        <mat-list-item *ngFor="let space of availableSpacesDataSource" class="timeline-list-item">
          <div class="timeline-list-item-content" fxLayoutAlign="space-between center">
            <div class="list-item-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
              <!-- <mat-icon class="material-icons-outlined" matListItemIcon>edit</mat-icon> -->
              <mat-icon svgIcon="stacked_lines"></mat-icon>
              <span class="heading-bold">{{space.spaceName}}</span>
            </div>
            <span class="body-1-regular-1">{{space.spaceSize}}m<sup>2</sup></span>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-paginator #availPaginator [length]="availableSpaces.length" [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="10" class="custom-paginator">
      </mat-paginator>

      <div class="heading-regular full-width px-3" fxLayout="row" fxLayoutAlign="space-between center"
        fxFlexOffset="10px">
        <div>Total size:</div>
        <div>{{totalSize}} m<sup>2</sup></div>
      </div>
    </div>

  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content cdkScrollable class="sidenav-main-content">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="upload-file-container">
      <div [formGroup]="imageUploadFormGroup" class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="heading-bold">Floor plan
          <ng-container *ngIf="imageUploadFormGroup.get('file')?.value">
            <span class="body-1-default"> - {{imageUploadFormGroup.get('file')?.value.name}}</span>
            <button (click)="upload()" class="button-action" mat-flat-button [disabled]="imageUploadFormGroup.pristine">
              <div class="button-text">Save</div>
            </button>
            <button (click)="clearUpload()" [disabled]="imageUploadFormGroup.pristine" class="button-action"
              mat-flat-button>
              <div class="button-text">Cancel</div>
            </button>
          </ng-container>
        </span>
        <label class="body-1-default file-label-wrapper" for="file-upload">
          <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-icon class="material-icons-outlined file-upload-icon">
              upload_file
            </mat-icon>
            <div class="body-1-default">Select file to upload</div>
          </div>
          <input #fileInput (change)="fileChangeEvent($event)" accept=".jpg,.png,.jpeg" formControlName="file"
            id="file-upload" class="custom-text-field" type="file" />
        </label>
      </div>
      <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
        <span> Last updated on {{getLastUpdated()}}</span>
      </div>

      <div class="full-width file-image-preview-container" fxLayout="row">
        <img *ngIf="previewImageSrc" class="preview-image" src="{{previewImageSrc}}" />
      </div>

    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
