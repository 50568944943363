import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {LocationOffering, PageOffering, PageSection, PageSectionItem,} from '../../model/side-nav.model';
import {PageItemModel} from '../../model/page-item.model';
import {UserNotificationInterface} from '../../interfaces/user-notification.interface';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ThemeService} from '../../../../themes/theme-service.service';
import {NotificationsService} from '../../services/notifications.service';
import {TabManagementService} from '../../services/tab-management.service';
import {NotificationsDialogComponent} from '../notifications/notifications-dialog/notifications-dialog.component';
import {UtilsService} from '../../../shared/services/utils.service';
import {LandlordPropertyModel} from '../../model/landlordProperty.model';
import {PageEventInterface} from "../../interfaces/page-event.interface";
import {
  accountsItems,
  dashboardSection,
  digitalAdvertisingPageSections,
  districtPageSections,
  exhibitionPageSections,
  generalLeasingPageSections,
  internalAdminSection,
  kiosksPageSections,
  leasingItems,
  marketsPageSections,
  pipelineItems,
  popUpsPageSections,
  settingsItems,
  spacesItems,
  storagePageSections,
  supportItem
} from "../../constants/side-nav.constants";
import {Location} from '@angular/common';
import {MatSelectChange} from '@angular/material/select';
import {CurrentContextService} from '../../services/security/current-context.service';
import {LandlordsService} from '../../services/landlords.service';
import {AuthenticationService} from '../../services/security/authentication.service';
import {AccessVerificationService} from '../../services/access-verification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalSettingsComponent} from '../../../features/settings/global-settings/global-settings.component';
import {CurrentLandlordModel} from '../../model/landlord.model';
import {DashboardsLandingComponent} from "../../../features/dashboards/dashboards-landing.component";
import {LoadingService} from "../../services/loading.service";
import {ConfirmationDialogComponent} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {UserService} from '../../services/security/user.service';
import {lastValueFrom} from 'rxjs';
import {InternalFeaturesLandingComponent} from '../../../features/internal/internal-features-landing.component';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})

export class HomeScreenComponent implements OnInit, AfterViewInit {

  isPropertySelected = false;
  @ViewChild("notificationsSidenav") notificationSideNav!: MatSidenav;
  @ViewChild("sidenav") sidenav!: MatSidenav;

  loading$ = this.loader.loading$;

  selectedProperty: LandlordPropertyModel | null = null;
  properties: LandlordPropertyModel[] = [];
  pageOfferings: PageOffering[] = [];
  pageSections: PageSection[] = [];
  pageSectionItems: PageSectionItem[] = [];
  landlord!: CurrentLandlordModel;
  landlordLogo: string = '../assets/icons/default-nter-logo.svg';

  dashboardSection: PageSection = new PageSection(this.utilService.generateUuidWithPrefix(''), '', dashboardSection.label, dashboardSection.label, dashboardSection.icon,);
  internalAdminSection: PageSection = new PageSection(this.utilService.generateUuidWithPrefix(''), '', internalAdminSection.label, internalAdminSection.label, internalAdminSection.icon,);

  pages: PageItemModel[] = [];
  notifications: UserNotificationInterface[] = []
  title = 'soko-console-web-v2';
  offeringTypes: string[] = ['DISTRICT', 'SPACES', 'KIOSK', 'STORAGE'];
  isInternalUser: boolean = false;

  offeringPageSections = new Map<string, PageSection[]>();
  offeringPageSectionItems = new Map<string, PageSectionItem[]>();

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private accessVerificationService: AccessVerificationService,
    private themeService: ThemeService,
    private notificationService: NotificationsService,
    private tabManagementService: TabManagementService,
    private location: Location,
    private contextService: CurrentContextService,
    private landlordService: LandlordsService,
    private authService: AuthenticationService,
    private utilService: UtilsService,
              private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private loader: LoadingService) {
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/back-arrow-button.svg'));
    this.tabManagementService.getPageAddEvent()
      .subscribe((pageToAdd: PageItemModel) => {
        this.pages.push(pageToAdd)
      });
    this.tabManagementService.getCloseAllTabsEvent()
      .subscribe(() => {
        this.pages = [];
      });

    this.tabManagementService.getLandlordChangeEvent()
      .subscribe(($event: MatSelectChange) => {
        this.isPropertySelected = false;
        this.selectedProperty = null;
        const landlordId = $event.value
        this.contextService.setCurrentWebClient(landlordId)
          .then((response: CurrentLandlordModel | null) => {
            if (response == null) {
              router.navigate(['/404'])
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.landlord = response!;
              this.landlordLogo = this.landlord.companyLogo ? this.landlord.companyLogo : '../assets/icons/default-logo.svg';
              this.location.replaceState(`${this.landlord.code}/home/dashboard`);
              this.resetLandlordPropertiesSelection(landlordId);
              this.themeService.setThemeColors(response ? response.theme : null);
            }
          });
      });

    let landlordId: string = this.contextService.getCurrentLandlordId()!;
    this.resetLandlordPropertiesSelection(landlordId);
    if (this.contextService.getCurrentLocation() != null) {
      this.changeSelectedProperty(this.contextService.getCurrentLocation()!);
    }
    //todo: check if the tokens are already set in storage if they aren't then make the requests
    this.contextService.getCurrentWebClient()
      .then((result: CurrentLandlordModel | null) => {
        this.landlord = result!;
        this.landlordLogo = this.landlord.companyLogo ? this.landlord.companyLogo : '../assets/icons/default-logo.svg';
        this.themeService.setThemeColors(result ? result.theme : null);
      });

    this.tabManagementService.getTabChangeEvent().subscribe(($event) => {
      this.setFocusedOffering($event.pageInFocus);
    });


  }

  get getDate(): any {
    return new Date();
  }

  ngOnInit(): void {
    if (this.selectedProperty != null) {
      this.initPageOfferingsAndSections();
    }
  }

  ngAfterViewInit() {
  }

  resetLandlordPropertiesSelection(landlordId: string) {
    lastValueFrom(this.userService.getAllowedLocationsForLandlord(landlordId))
      .then((result: string[]) => {
        this.contextService.setAllowedLocationsForLandlord(result);
        return Promise.resolve(result);
      })
      .then((allowedLocations: string[]) => {
        this.landlordService.getPropertiesForLandlord(landlordId)
          .then((result: LandlordPropertyModel[]) => {
            this.properties = result.filter(item => allowedLocations.includes(item.uuid));
            if (this.properties.length == 1) {
              setTimeout(() => {
                this.changeSelectedProperty(this.properties.at(0)!);
              }, 500)
            }
          });
      });
  }

  supportPage(): void {
    const item = supportItem;
    let payload: PageEventInterface = {
      componentToRegister: item.componentToRegister,
      pageName: item.pageName,
      pageHeader: 'Customer Care',
      data: {
        properties: this.properties,
      },
      id: this.utilService.generateUuidWithPrefix(item.idPrefix),
      sectionCode: item.sectionCode,
      pageCode: item.pageCode,
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  initPageOfferingsAndSections(): void {
    this.pageOfferings = this.setPageOfferings();
    this.pageSections = this.setPageSections();
    this.pageSectionItems = this.setPageSectionItems();
    this.isInternalUser = this.contextService.checkUserFeaturesWithoutOffering(['settings_template_builder_internal_admin'])

  }

  setPageOfferings(): PageOffering[] {
    const resultSet: PageOffering[] = [];
    if (this.selectedProperty != null) {
      if (this.utilService.arrayNotEmptyFilter(this.selectedProperty.locationOfferings)) {
        this.selectedProperty.locationOfferings.forEach(
          offering => {
            resultSet.push(new PageOffering(offering.uuid, offering.offeringName, offering.platformOfferingType, offering.offeringUuid, offering.offeringAddress, offering.offeringStartDate, offering.tileImage, offering.bannerImage, offering.floorPlanImage, offering.offeringListing));
          }
        )
      }
    }
    return resultSet.sort((a, b) => a.label.localeCompare(b.label));
  }

  // todo change the function which checks each offering type to loop through the whole list and find the name of constant which matches the offering
  setPageSections(): PageSection[] {
    const sections: PageSection[] = [];
    this.pageOfferings.forEach(ea => {
      if (ea.offeringType === 'DISTRICT') {
        sections.push(...districtPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'GENERAL_LEASING') {
        sections.push(...generalLeasingPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'EXHIBITIONS') {
        sections.push(...exhibitionPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'STORAGE') {
        sections.push(...storagePageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'POP_UPS') {
        sections.push(...popUpsPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'DIGITAL_ADVERTISING') {
        sections.push(...digitalAdvertisingPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'MARKETS') {
        sections.push(...marketsPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }
      if (ea.offeringType === 'KIOSK') {
        sections.push(...kiosksPageSections.map(m => new PageSection(this.utilService.generateUuidWithPrefix(''), ea.uuid, m.label, m.code, m.icon, ea)));
      }

    })
    this.setPageSectionsMap(sections);
    return sections;
  }

  setPageSectionItems(): PageSectionItem[] {
    let items: PageSectionItem[] = [];
    this.pageSections.forEach(ea => {
      const offeringName = ea.offering?.label!;
      switch (ea.code) {
        case 'leads':
          items.push(...pipelineItems.map(m => {
            return new PageSectionItem(m.idPrefix, ea.uuid, m.label, m.componentToRegister, m.pageName, offeringName, m.data, ea.offeringUuid, m.sectionCode, m.pageCode, m.openType, m.featureCodes, ea.offering)
          }));
          break;
        case 'leasing':
          items.push(...leasingItems.map(m => {
            return new PageSectionItem(m.idPrefix, ea.uuid, m.label, m.componentToRegister, m.pageName, offeringName, m.data, ea.offeringUuid, m.sectionCode, m.pageCode, m.openType, m.featureCodes, ea.offering)
          }));
          break;
        case 'settings':
          items.push(...settingsItems.map(m => {
            return new PageSectionItem(m.idPrefix, ea.uuid, m.label, m.componentToRegister, m.pageName, offeringName, m.data, ea.offeringUuid, m.sectionCode, m.pageCode, m.openType, m.featureCodes, ea.offering)
          }));
          break;
        case 'accounts':
          items.push(...accountsItems.map(m => {
            return new PageSectionItem(m.idPrefix, ea.uuid, m.label, m.componentToRegister, m.pageName, offeringName, m.data, ea.offeringUuid, m.sectionCode, m.pageCode, m.openType, m.featureCodes, ea.offering)
          }));
          break;
        case 'spaces':
          items.push(...spacesItems.map(m => {
            return new PageSectionItem(m.idPrefix, ea.uuid, m.label, m.componentToRegister, m.pageName, offeringName, m.data, ea.offeringUuid, m.sectionCode, m.pageCode, m.openType, m.featureCodes, ea.offering)
          }));
          break;
      }
    })
    items = items.filter(f => this.contextService.checkUserFeatures(f.featureCodes, f.offering!.offeringUuid));
    this.setPageSectionItemsMap(items);
    return items;
  }

  setPageSectionsMap(sections: PageSection[]) {
    this.offeringPageSections = sections.reduce((acc, section) => {
      if (acc.has(section.offeringUuid)) {
        acc.get(section.offeringUuid)!.push(section);
      } else {
        acc.set(section.offeringUuid, [section]);
      }
      return acc;
    }, new Map<string, PageSection[]>());
  }

  setPageSectionItemsMap(items: PageSectionItem[]) {
    this.offeringPageSectionItems = items.reduce((acc, item) => {
      if (acc.has(item.sectionUuid)) {
        acc.get(item.sectionUuid)!.push(item);
      } else {
        acc.set(item.sectionUuid, [item]);
      }
      return acc;
    }, new Map<string, PageSectionItem[]>());
  }

  getPageSections(offeringUuid: string): PageSection[] {
    return this.offeringPageSections.get(offeringUuid) ? this.offeringPageSections.get(offeringUuid)! : [];
  }

  getPageSectionItems(sectionUuid: string): PageSectionItem[] {
    return this.offeringPageSectionItems.get(sectionUuid) ? this.offeringPageSectionItems.get(sectionUuid)! : [];
  }

  togglePageSection(section: PageSection) {
    section.selected = !section.selected;
  }

  setFocusedOffering(pageInFocus: PageItemModel): void {
    if (pageInFocus) {
      this.pageOfferings.forEach(offering => {
        offering.selected = pageInFocus.offeringId === offering.uuid;
      })
      this.pageSections.forEach(section => {
        section.selected = (pageInFocus.sectionCode === section.code && pageInFocus.offeringId === section.offeringUuid);
      })
      this.pageSectionItems.forEach(item => {
        item.selected = pageInFocus.pageCode === item.pageCode && pageInFocus.offeringId === item.offeringId;
      })
    } else {
      this.pageSectionItems.forEach(item => {
        item.selected = false;
      })
    }
  }

  getNumberOfNotifications() {
    return this.notifications.length;
  }

  togglePageSectionItem(item: PageSectionItem) {
    item.selected = !item.selected;
  }


  openNotificationsDialog(): void {
    if (this.notificationSideNav.opened) {
      this.toggleNotifications(false);
    }

    let dialogRef = this.matDialog.open(NotificationsDialogComponent, {
      panelClass: 'dialog-medium',
      backdropClass: 'dialog-backdrop',
      position: {right: '130px', top: '70px'}
    })
    const openSidenav = dialogRef.componentInstance.openSidenav.subscribe(() => {
      this.notificationSideNav.open().then(() => dialogRef.close);
    })
    dialogRef.afterClosed().subscribe(() => openSidenav.unsubscribe());
  }

  toggleNotifications(switchView: boolean) {
    if (switchView) {
      this.notificationSideNav.close().then(() => this.openNotificationsDialog());
    } else {
      this.notificationSideNav.close();
    }
  }

  changeSelectedProperty($event: LandlordPropertyModel): void {
    this.tabManagementService.sendCloseAllTabsEvent();
    this.contextService.setCurrentLocation($event);
    this.isPropertySelected = true;
    this.selectedProperty = $event;
    this.authService.lookUpAndSetUser()
      .then(() => {
        this.authService.lookupAndSetUserFeatures()
          .then(() => {
            setTimeout(() => {
              this.initPageOfferingsAndSections();
            }, 500)
          });
      });
  }

  viewDashboards(): void {
    this.togglePageSection(this.dashboardSection);
    let payload: PageEventInterface = {
      componentToRegister: DashboardsLandingComponent,
      pageName: this.dashboardSection.label,
      pageHeader: 'Dashboards',
      data: {
        offerings: this.selectedProperty?.locationOfferings
      },
      id: this.utilService.generateUuidWithPrefix(''),
      offeringId: '',
      sectionCode: this.dashboardSection.code,
      pageCode: this.dashboardSection.code
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  viewInternalFeatures(): void {
    this.togglePageSection(this.internalAdminSection);

    let payload: PageEventInterface = {
      componentToRegister: InternalFeaturesLandingComponent,
      pageName: this.internalAdminSection.label,
      pageHeader: 'Dashboards',
      data: {
        offerings: this.selectedProperty?.locationOfferings
      },
      id: this.utilService.generateUuidWithPrefix(''),
      offeringId: '',
      sectionCode: this.internalAdminSection.code,
      pageCode: this.internalAdminSection.code
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  openPageSectionTab(item: PageSectionItem): void {
    let payload: PageEventInterface = {
      componentToRegister: item.componentToRegister,
      pageName: item.pageName,
      pageHeader: item.pageHeader,
      data: item.data,
      id: this.utilService.generateUuidWithPrefix(item.id),
      offeringId: item.offeringId,
      sectionCode: item.sectionCode,
      pageCode: item.pageCode,
      offering: item.offering
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  openPageSectionDialog(item: PageSectionItem): void {
    this.contextService.setCurrentOfferingId(item.offering!.offeringUuid);
    this.matDialog.open(item.componentToRegister, {
      panelClass: 'dialog-large',
      data: {
        ...item.data,
        offering: item.offering
      }
    })
  }

  openGlobalSettings() {
    let payload: PageEventInterface = {
      componentToRegister: GlobalSettingsComponent,
      pageName: 'Global Settings',
      pageHeader: this.landlord.companyName,
      data: [],
      id: this.utilService.generateUuidWithPrefix('settings')
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  toggleSidenav() {
    if (this.selectedProperty != null) {
      this.sidenav.toggle();
    }
  }

  changeSelectedPropertyFromNavConfirm($event: MatSelectChange) {
    const element: HTMLElement = $event.source._elementRef.nativeElement;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: 'Changing the selected property will close all open tabs. Are you sure you want to continue?',
      buttonConfirmLabel: 'OK',
      buttonCancelLabel: 'Cancel'
    };
    dialogConfig.position = {
      top: element.offsetTop + (element.offsetHeight * 2) + 'px',
      left: element.offsetLeft + element.offsetWidth + 'px'
    };
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.changeSelectedPropertyFromNav($event);
      } else {
        $event.source.value = this.selectedProperty?.uuid;
      }
    });
  }

  changeSelectedPropertyFromNav($event: MatSelectChange) {
    const uuid = $event.value;
    let landlordPropertyModel = this.properties.find(property => property.uuid === uuid);
    if (landlordPropertyModel !== null && landlordPropertyModel !== undefined) {
      this.changeSelectedProperty(landlordPropertyModel!);
    } else {
      console.log("error changing properties");
    }
  }

  pageSectionsIsEmpty(offeringUuid: string): boolean {
    let pageSectionItems = this.getPageSections(offeringUuid)
      .map(section => this.getPageSectionItems(section.uuid))
      .reduce((acc, val) => acc.concat(val), []);
    return pageSectionItems.length === 0;
  }

  public updateOffering(updatedOffering: LocationOffering) {
    this.selectedProperty?.locationOfferings?.forEach((offering: LocationOffering) => {
      if (offering.uuid === updatedOffering.uuid) {
        offering = updatedOffering;
      }
    });

    const index = this.pageOfferings.findIndex(el => updatedOffering.uuid === el.uuid);
    if (index !== -1) {
      this.pageOfferings[index].floorPlanImage = updatedOffering.floorPlanImage;
    }
  }
}
