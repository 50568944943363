import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {
  PreviewApplicationFormComponent
} from "./features/leads/application-form-builder/preview-application-form/preview-application-form.component";
import {LoginComponent} from './features/login/login.component';
import {ForgotPasswordComponent} from './features/password-reset/forgot-password/forgot-password.component';
import {CheckYourEmailComponent} from './features/password-reset/check-your-email/check-your-email.component';
import {ResetPasswordComponent} from './features/password-reset/password-reset/password-reset.component';
import {
  VerifyUpdatePasswordOtpComponent
} from './features/password-reset/verify-update-password-otp/verify-update-password-otp.component';
import {HomeScreenComponent} from './core/components/home-screen/home-screen.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {AuthGuard} from './core/services/security/auth-guard.service';
import {PathContextGuardService} from './core/services/security/path-context-guard.service';
import {VerifyAccountComponent} from './features/verify-account/verify-account.component';


const routes: Routes = [
  //Forgot Password
  {path: ':clientId/forgot-password', component: ForgotPasswordComponent},
  {path: ':clientId/forgot-password/check-your-email', component: CheckYourEmailComponent},
  {path: ':clientId/forgot-password/password-reset', component: ResetPasswordComponent},
  {path: ':clientId/forgot-password/verify', component: VerifyUpdatePasswordOtpComponent},

  //Login
  {path: ':clientId/login', component: LoginComponent},

  // Account Verification
  {path: ':clientId/account/verify', component: VerifyAccountComponent, canActivate: [PathContextGuardService]},

  //Home - properties
  {path: ':clientId/home/dashboard', component: HomeScreenComponent, canActivate: [AuthGuard, PathContextGuardService]},

 // form preview
  {path: 'preview-apply/:formCode', component: PreviewApplicationFormComponent},

  {path: ':clientId/hello', redirectTo: ':clientId/login', pathMatch: 'full'},
  {path: ':clientId', redirectTo: ':clientId/login', pathMatch: 'full'},

  {path: '404', component: PageNotFoundComponent},

  {path: '**', redirectTo: '/404', pathMatch: 'full'},

  //todo:create a route to load a new tab from url

]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
