import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {CurrentContextService} from "./current-context.service";

@Injectable({providedIn: 'root'})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService, private context: CurrentContextService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders(this.addSysContextHeaders(req));
    const cloned = req.clone({
      headers: headers
    });
    return next.handle(cloned);
  }

  private addSysContextHeaders(req: any) {
    let headersObject: { [key: string]: any } = {
      'x-system-id': 'CONSOLE'
    }

    if (this.auth.getToken()) {
      headersObject['Authorization'] = `Bearer ${this.auth.getToken()}`;
    }
    if (this.context.getCurrentLandlordId() != null) {
      headersObject['x-landlord-id'] = this.context.getCurrentLandlordId();
    }

    if (this.context.getCurrentLocation() != null) {
      headersObject['x-location-id'] = this.context.getCurrentLocation()!.uuid;
    }

    if (this.context.getCurrentOfferingId() != null) {
      headersObject['x-offering-id'] = this.context.getCurrentOfferingId()?.toUpperCase()!;
    }

    req.headers.keys().forEach((ea: string) => {
      headersObject[ea as keyof Object] = req.headers.get(ea);
    })

    return Object.entries(headersObject)
      .reduce((a, [k, v]) => (v === null ? a : (a[k as keyof Object] = v, a)), {});
  }
}
