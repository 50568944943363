import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {GenericDatasource} from '../../../shared/datasource/generic.datasource';
import {MatPaginator} from '@angular/material/paginator';
import {DistrictCardReadersService} from '../service/district-card-readers.service';
import {DistrictCardReader} from '../service/district-card-readers.model';
import {tap} from 'rxjs';
import {PaginatorService} from '../../../shared/services/paginator.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {ConfirmRemoveComponent} from '../../../shared/components/confirm-remove/confirm-remove.component';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {SelectionModel} from '@angular/cdk/collections';
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-hardware-settings',
  templateUrl: './hardware-settings.component.html',
  styleUrls: ['./hardware-settings.component.scss']
})
export class HardwareSettingsComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  dataSource = new GenericDatasource<{ content: DistrictCardReader }>(this.districtCardReaderService);
  displayedColumns: string[] = ['select', 'id', 'cardReaderCode'];
  selectedReader: string | null = null;
  filterForm: FormGroup;
  cardReaderValue: FormControl =  new FormControl('');
  addingCard: boolean = false;
  cardReaderSelectionModel = new SelectionModel<DistrictCardReader>(true, []);

  isInternalUser = false;

  constructor(injector: Injector,
              private districtCardReaderService: DistrictCardReadersService,
              private paginatorService: PaginatorService,
              public utilsService: UtilsService,
              private matDialog: MatDialog,
              private contextService: CurrentContextService,
              public loader: LoadingService) {
    super(injector);
    this.filterForm = new FormGroup({
      uuid: new FormControl(''),
      cardReaderCode: new FormControl('')
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<{ content: DistrictCardReader }>(this.districtCardReaderService);
    this.refreshData();
    this.filterForm.valueChanges.subscribe(() => {
      let uuidLength: any = this.filterForm.get('uuid')?.value.toString().length;
      let cardReaderCodeLength: any = this.filterForm.get('cardReaderCode')?.value.toString().length;
      if ((uuidLength > 3 || cardReaderCodeLength > 3) || (uuidLength === 0 || cardReaderCodeLength === 0)) {
        this.loadDistrictCards();
      }
    });

    this.isInternalUser = this.contextService.checkUserFeatures(['settings_internal_admin'], this.offering!.offeringUuid)
  }

  refreshData(): void {
    this.dataSource.loadData(`/${this.offering?.offeringUuid}/card-reader`, {
      size: '10',
      page: '0',
      sort: 'createDate,desc'
    });
  }

  ngAfterViewInit(): void {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadDistrictCards()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => {
      this.loadDistrictCards();
    });
  }

  loadDistrictCards() {
    const filters = [
      {name: 'uuid', val: this.filterForm.get('uuid')?.value},
      {name: 'cardReaderCode', val: this.filterForm.get('cardReaderCode')?.value},
    ];
    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'createDate,desc'
    };
    this.dataSource.loadData(`/${this.offering?.offeringUuid}/card-reader`, page, filters);
  }


  setSelectedReader(uuid: string) {
    this.selectedReader = uuid;
  }

  removeSelectedCardReader() {
    const dialogRef = this.matDialog.open(ConfirmRemoveComponent, {
      panelClass: 'dialog-remove',
      data: {
        message: 'Do you want to remove the selected card reader?'
      }
    })
    const removeSub = dialogRef.componentInstance.confirmRemove.subscribe((confirmation: boolean) => {
      // call remove service
      if (confirmation) {
        this.cardReaderSelectionModel.selected.forEach((cardReader: DistrictCardReader) => {
          this.districtCardReaderService.remove(this.offering!.offeringUuid, cardReader.uuid!);
        })
        this.selectedReader = null;
      }
      dialogRef.close();
      this.loadDistrictCards();
      this.cardReaderSelectionModel.clear();
    });
    dialogRef.afterClosed().subscribe(() => removeSub.unsubscribe());
  }

  addCardReader() {
    this.addingCard = true;
  }

  saveNewCard() {
    this.districtCardReaderService.saveNew({
      districtUuid: this.offering!.offeringUuid,
      cardReaderCode: this.cardReaderValue.value
    })
      .then(() => {
        this.addingCard = false;
        this.cardReaderValue.reset();
        this.refreshData();
      })
      .catch((error: string) => {
        console.error('failed to save new card: ', error);
      });
  }
}
