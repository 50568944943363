import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseTemplateModel, OfferingTemplateModel} from '../template-builder/model/offering-template.model';

@Injectable({
  providedIn: 'root'
})
export class OfferingNotificationsService {
  baseUrl: string = environment.apiDistrictsHost + '/console/offering/notifications';
  baseNotificationUrl: string = environment.apiDistrictsHost + '/console/notifications';


  constructor(private http: HttpClient) {
  }

  getAllForOffering(offeringUuid: string): Observable<OfferingTemplateModel[]> {
    return this.http.get<OfferingTemplateModel[]>(`${this.baseUrl}/${offeringUuid}`);
  }

  saveNotification(offeringUuid: string, request: OfferingTemplateModel): Observable<OfferingTemplateModel> {
    return this.http.post<OfferingTemplateModel>(`${this.baseUrl}/${offeringUuid}`, request);
  }

  getAllBaseNotifications(): Observable<BaseTemplateModel[]> {
    return this.http.get<BaseTemplateModel[]>(`${this.baseNotificationUrl}`);
  }

  saveBaseNotification(request: BaseTemplateModel): Observable<BaseTemplateModel> {
    return this.http.post<BaseTemplateModel>(`${this.baseNotificationUrl}`, request);
  }
}
