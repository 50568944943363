<div class="full-width" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Rates and Charges</span>
    <div fxLayoutGap="10px" fxFlexOffset="30px">
      <button (click)="isAddingTransactionSource = true" *ngIf="!isAddingTransactionSource" class="button-action" [disabled]="loader.loading$ | async"
              mat-flat-button>
        <div class="button-text">Edit</div>
      </button>
      <button (click)="save()" *ngIf="isAddingTransactionSource" class="button-action" mat-flat-button [disabled]="loader.loading$ | async">
        <div class="button-text">Save</div>
      </button>
      <button (click)="cancel()" *ngIf="isAddingTransactionSource" class="button-action" mat-flat-button [disabled]="loader.loading$ | async">
        <div class="button-text">Cancel</div>
      </button>
    </div>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px" *ngIf="leasePricingPeriod">
    <div class="info-card" fxLayout="column">

      <span class="heading-default">Lease Rates</span>

      <div fxLayout="column" fxLayoutGap="30px" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Rate Type</mat-label>
              <mat-select [(value)]="leasePricingPeriod.rentalPricingOption" [disabled]="!isAddingTransactionSource"
                          class="custom-select-field-value" placeholder="Select">
                <mat-option class="custom-select-field-option" [value]="'PRICING_MODEL'">Fixed Rental</mat-option>
                <mat-option [value]="'TURNOVER_PERCENTAGE'" class="custom-select-field-option">Percentage Rental
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
              <mat-label>Rate per m<sup>2</sup></mat-label>
              <input [(ngModel)]="leasePricingPeriod.rentalPricingPerSqmValue" class="align-right" matInput min="0"
                     [disabled]="!isAddingTransactionSource" type="number">
              <div matPrefix class="ms-3">R</div>
              <div matSuffix class="me-2"><span>/ </span><span class="ms-2">m<sup>2</sup></span></div>
            </mat-form-field>

            <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" *ngIf="leasePricingPeriod.rentalPricingOption === 'TURNOVER_PERCENTAGE'">
              <mat-label>% Rate</mat-label>
              <input [(ngModel)]="leasePricingPeriod.rentalPricingTurnoverPercentageValue" [disabled]="!isAddingTransactionSource" class="align-right"
                     matInput
                     type="number">
              <span matSuffix class="me-2">%</span>
            </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlexOffset="30px" fxLayoutAlign="start end" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between cneter" class="full-width">
          <span class="heading-default">Transaction Fee Charges</span>
          <app-info-panel [type]="leasePricingPeriod.applyTransactionFee ? PanelType.INFO : PanelType.WARN"
                          [message]="['Transaction fees will ' + (leasePricingPeriod.applyTransactionFee ? '' : 'NOT ')  + 'be charged for all internal and external payment method sales.']">
          </app-info-panel>
        </div>


        <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
          <mat-label>Apply Transaction Fees</mat-label>
          <input matInput disabled>
          <span matPrefix class="body-1-regular-1 ms-3">{{leasePricingPeriod.applyTransactionFee ? 'Yes' : 'No'}}</span>
          <div matSuffix class="me-3" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-slide-toggle (change)="applyTransactionFee($event)" [checked]="leasePricingPeriod.applyTransactionFee ? leasePricingPeriod.applyTransactionFee : false"
                              [disabled]="!isAddingTransactionSource"
                              color="primary"></mat-slide-toggle>
          </div>
        </mat-form-field>
      </div>



      <div fxLayout="column" fxLayoutGap="10px" fxFlexOffset="20px" *ngIf="leasePricingPeriod.applyTransactionFee">
        <div fxLayout="row">
          <div class="body-1-default" fxFlex="25">Payment Method</div>
          <div class="body-1-default" fxFlex="25">Rate Type</div>
          <div class="body-1-default" fxFlex="25">Rate</div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="column" *ngFor="let method of paymentMethods">
          <div fxLayout="row">
            <div class="body-1-regular-2" fxFlex="25">{{utils.displayStatus(method.collectionType)}} - {{method.name}}</div>
            <div class="body-1-regular-2" fxFlex="25">{{displayFeeType(method.transactionFeeType)}}</div>
            <div class="body-1-regular-2" fxFlex="25" *ngIf="method.transactionFeeType === 'FIXED'">R {{method.transactionFee ? method.transactionFee.toFixed(2) : null}}</div>
            <div class="body-1-regular-2" fxFlex="25" *ngIf="method.transactionFeeType === 'RATE'">{{method.transactionFee}} %</div>
          </div>

          <mat-divider fxFlexOffset="10px"></mat-divider>
        </div>

      </div>

    </div>
  </div>

</div>
