export interface ChartDataConfig {
    next: (value: any) => void; // callback function
    error: (err: any) => void; // error function
    options: any; // optional callback function
}

export abstract class ChartConfig<T extends any> {
    protected instance: any;

    protected constructor(componentInstance: T) {
        this.instance = componentInstance;
    }
}
