<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
    <button class="button-close-top" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title-default">Create a Retailer</div>
  </div>

  <form class="full-width" [formGroup]="retailerForm" fxFlexOffset="20px">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">First Name</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="text" placeholder="Input text" formControlName="firstName">
          <mat-error class="link-error">{{getErrorMessage('firstName')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Last Name</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="text" placeholder="Input text" formControlName="lastName">
          <mat-error class="link-error">{{getErrorMessage('lastName')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Email</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="email" placeholder="Input text" formControlName="email">
          <mat-error class="link-error">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Company Name</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="email" placeholder="Input text" formControlName="companyName">
          <mat-error class="link-error">{{getErrorMessage('companyName')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Industry And Product Collection</span>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select [(value)]="selectedIndustries" class="custom-select-field-value"
                      formControlName="industryTypes" [multiple]="true" placeholder="Industry And Product Collection">
            <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
              <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                         fxLayoutGap="5px">
                <span>{{group.name}}</span>
                <mat-icon class="btn-cursor">add</mat-icon>
              </mat-label>
              <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                          class="custom-select-field-option">{{ ind.name }}</mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error class="link-error">{{getErrorMessage('industryTypes')}}</mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>

  <mat-error class="body-1-error">{{this.errMessage}}</mat-error>

  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="20px">
    <button class="button-primary" mat-flat-button (click)="submitForm()" [disabled]="!!(loader.loading$ | async)">
      <mat-icon>check</mat-icon>
      <div class="button-text">Submit</div>
    </button>
  </div>

</div>
