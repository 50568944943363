import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {LeaseDocument} from "../../leasing.model";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilsService} from "../../../../shared/services/utils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {tap} from "rxjs";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {LeaseDocumentsService} from "./lease-documents.service";
import {MatDialog} from "@angular/material/dialog";
import {AddDocumentDialogComponent} from "./add-document-dialog/add-document-dialog.component";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-lease-documents',
  templateUrl: './lease-documents.component.html',
  styleUrls: ['./lease-documents.component.scss']
})
export class LeaseDocumentsComponent implements OnInit, AfterViewInit{
  @Input() id!: string;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['select', 'id', 'fileName', 'category', 'versionNo', 'documentType', 'download'];
  dataSource!: MatTableDataSource<LeaseDocument>;
  selection = new SelectionModel<LeaseDocument>(true, []);
  isRemovePanelOpen: boolean = false;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private documentsService: LeaseDocumentsService,
              private matDialog: MatDialog,
              public loader: LoadingService) {
  }

  ngOnInit() {
    this.loadDocuments();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadDocuments()))
      .subscribe();
  }

  loadDocuments() {
    this.documentsService.getLeaseDocuments(this.id).subscribe({
      next: value => this.dataSource = new MatTableDataSource<LeaseDocument>(value),
      error: err => console.log(err)
    })
  }

  addDocument() {
    this.matDialog.open(AddDocumentDialogComponent, {
      panelClass: 'dialog-large',
      width: '250px',
      data: {
        leaseId: this.id
      }
    }).afterClosed()
      .subscribe(() => {
        this.loadDocuments();
      });
  }

  removeDocuments($event: boolean) {
    if ($event) {
      this.selection.selected.forEach((doc: LeaseDocument) => {
        this.documentsService.removeLeaseDocument(doc.documentUuid!, this.id).subscribe({
          next: () => {
            this.loadDocuments();
          },
          error: err => console.log(err)
        });
      });
    }
    this.isRemovePanelOpen = false;
  }

  downloadDocumentFile(doc: LeaseDocument) {
    this.documentsService.getDocumentFile(this.id, doc.uuid!).subscribe({
      next: (value) => {
        const type = ('application/' + doc.mimeType).toString();
        const file = new Blob([value], { type });
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement('a');

        anchor.download = doc.fileName;
        anchor.href = fileURL;
        anchor.click();
      },
      error: err => console.log(err)
    })
  }
}
