<div style="background-color: white" fxLayout="column">

  <div class="full-width banner-header" fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="20px">
    <div class="page-padding full-width" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
      <span class="title-default" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span>Retailer Report of</span>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 200px">
          <input class="input-field" placeholder="Start typing to search for a retailer" matInput type="text"
                 [formControl]="searchTerm" required [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectRetailer($event)">
            <mat-option *ngFor="let option of filteredRetailers | async" [value]="option">
              {{option.id}}. {{option.companyName}}
            </mat-option>
          </mat-autocomplete>
          <mat-spinner class="me-2" matSuffix diameter="20" color="primary" *ngIf="loadingSearch"></mat-spinner>
          <mat-error class="link-error" *ngIf="!selectedRetailer">You have not yet selected a retailer</mat-error>
        </mat-form-field>
      </span>
      <span class="heading-regular" *ngIf="!!selectedRetailer && (bestStats && !bestStats.tradingOutsideOfLeasePeriod)">Explore the report to see highlights and other great insights!</span>
      <app-info-panel [type]="PanelType.WARN" [message]="['The retailer is trading outside of their lease period. Some data might be inaccurate.']"
                      *ngIf="!!selectedRetailer && bestStats && bestStats.tradingOutsideOfLeasePeriod"></app-info-panel>
      <app-info-panel [type]="PanelType.INFO" [message]="['Please select a retailer to view their statistics']" *ngIf="!selectedRetailer"></app-info-panel>
    </div>
    <mat-divider class="full-width"></mat-divider>
    <span class="heading-default page-padding" style="padding-bottom: 20px"></span>
  </div>

  <div class="page-padding" fxLayout="column" fxLayoutGap="30px">

    <app-dashboards-best-stats [bestMonthlyStats]="bestStats.bestMonthlyStats"></app-dashboards-best-stats>

    <app-dashboards-chart-section sectionName="Sales Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="0"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="salesPerformanceChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Rent Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="1"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="rentPerformanceChart"
                                  [chart2]="avgBasketSizeChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Overall Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="2"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="busiestSalesTimesChart"
                                  [chart2]="busiestSalesDaysChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <div fxLayout="column" fxFlexOffset="25px">
      <div fxLayout="row" fxLayoutAlign="space-between start">

        <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="30">
          <div class="title-small">Best Sellers</div>
          <mat-icon>more_vert</mat-icon>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="70">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="heading-default">Over this period</div>
            <app-custom-date-filter [customDateRangePresets]="customDateRangePresets2"
                                    [initialPreset]="'past_month'"
                                    [showBorder]="false"
                                    (dateRange)="getDateRange($event, 3)"></app-custom-date-filter>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="heading-default">Of this percentage of total sales</div>
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 190px;">
              <mat-select class="custom-select-field-value" placeholder="Select" [(value)]="topFilter" (selectionChange)="loadBestSellersList()">
                <mat-option class="custom-select-field-option"  *ngFor="let percent of topPercentSalesOptions" [value]="percent">{{percent + '%'}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxFlexOffset="20px">

        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="subtitle-default ps-2">Top Performing Products by Sales</span>
          <table mat-table class="dashboard-table" #sortProducts matSort
                 [dataSource]="bestSellersDatasource"
                 *ngIf="!bestSellersLoading; else canvasLoadingSpinner">

            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element">
                <mat-icon class="material-icons-outlined">local_offer</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef> Product Name </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.productName, 60)}} </td>
            </ng-container>

            <ng-container matColumnDef="quantitySold">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity Sold </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayNumber(element.quantitySold) }} </td>
            </ng-container>

            <ng-container matColumnDef="saleAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayRandAmount(element.saleAmount) }} </td>
            </ng-container>

            <ng-container matColumnDef="contributingPercentage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Contributing to total sales </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayNumber(element.contributingPercentage) }}% </td>
            </ng-container>

            <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumnsSellers"></tr>
            <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumnsSellers;"></tr>
          </table>

          <mat-paginator #bestSellersPaginator class="custom-paginator"
                         [length]="bestSellersDatasource ? bestSellersDatasource.data.length : 0"
                         [pageSize]="5"
                         [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <div fxLayout="column" fxFlexOffset="25px">
      <div fxLayout="row" fxLayoutAlign="space-between start">

        <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="30">
          <div class="title-small">Inventory Forecast</div>
          <mat-icon>more_vert</mat-icon>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="70">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="heading-default">Based on sales over this period</div>
            <app-custom-date-filter [customDateRangePresets]="customDateRangePresets2"
                                    [initialPreset]="'past_month'"
                                    [showBorder]="false"
                                    (dateRange)="getDateRange($event, 4)"></app-custom-date-filter>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxFlexOffset="20px">
        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="subtitle-default ps-2">Inventory Forecast based on Sales History</span>
          <table mat-table class="dashboard-table" #sortInventory matSort
                 [dataSource]="inventoryForecastDatasource"
                 *ngIf="!inventoryForecastLoading; else canvasLoadingSpinner">

            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element">
                <mat-icon class="material-icons-outlined">inventory_2</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef> Product Name </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.productName, 60)}} </td>
            </ng-container>

            <ng-container matColumnDef="quantitySold">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity Sold </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayNumber(element.quantitySold) }} </td>
            </ng-container>

            <ng-container matColumnDef="avgQuantitySold">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Average Quantity Sold Per Week</th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayDecimal(element.avgQuantitySold, 1) }} </td>
            </ng-container>

            <ng-container matColumnDef="currentInventoryLevel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Inventory </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayNumber(element.currentInventoryLevel) }} </td>
            </ng-container>

            <ng-container matColumnDef="weeksToLast">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Weeks to last </th>
              <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{ utils.displayDecimal(element.weeksToLast, 1) }} </td>
            </ng-container>

            <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumnsForecast"></tr>
            <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumnsForecast;"></tr>
          </table>

          <mat-paginator #forecastPaginator class="custom-paginator"
                         [length]="inventoryForecastDatasource ? inventoryForecastDatasource.data.length : 0"
                         [pageSize]="5"
                         [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <ng-template #canvasLoadingSpinner>
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>
  </ng-template>
</div>
