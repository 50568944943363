<div class="full-width info-header page-padding"
     [ngStyle]="{'background-color': backgroundWhite ? 'white' : null}"
     [class.bottom-border]="bottomBorder"
     fxLayout="column" [class.full-screen-height]="fullHeight" *ngIf="!banner">
  <span class="subheading-default" fxFlexOffset="30px">{{section}}</span>
  <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between start">
    <span class="title-default">{{title}}</span>
    <div *ngIf="isTabbed && tabs!.length > 1" fxLayoutGap="15px">
      <button (click)="changeSelectedTab(tab)" *ngFor="let tab of tabs" [class.button-tab-active]="isActive(tab)"
              [disabled]="tab.disabled!"
              class="button-tab" mat-flat-button>
        <div class="button-text">{{tab.name}}</div>
      </button>
    </div>
  </div>
  <span class="heading-default header-desc" fxFlexOffset="10px">{{description}}</span>
</div>

<div class="full-width banner-header" fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="20px" [class.full-screen-height]="fullHeight" *ngIf="banner">
  <div class="page-padding full-width" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <span class="title-default">{{title}}</span>
    <span class="heading-regular">{{subtitle}}</span>
  </div>
  <mat-divider class="full-width"></mat-divider>
  <span class="heading-default page-padding" style="padding-bottom: 20px">{{description}}</span>
</div>

