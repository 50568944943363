<div class="full-width page-content page-padding" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">{{header}}</span>
    <div fxLayoutGap="10px" fxFlexOffset="50px" *ngIf="createLease">
      <button (click)="openLeaseDialog()" class="button-action" mat-flat-button>
        <div class="button-text">Create a lease</div>
      </button>
    </div>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px" *ngIf="retailer">
    <div class="info-card" fxLayout="column">
      <span class="heading-default">General Information</span>
      <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">
        <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always" (click)="openRetailerViewTab()">
          <mat-label class="custom-form-field-link">System Id</mat-label>
          <input matInput type="text" [value]="retailer.id!.toString()" disabled>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Company Name</mat-label>
              <input matInput type="text" [value]="retailer.companyName" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Industry Type</mat-label>
              <input matInput type="text" [value]="displayIndustriesList(retailer)" disabled>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Name</mat-label>
              <input matInput type="text" [value]="retailer.ownerFirstname" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Surname</mat-label>
              <input matInput type="text" [value]="retailer.ownerLastname" disabled>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="info-card" fxLayout="column">
      <span class="heading-default">Contact Information</span>
      <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">
        <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
          <mat-label>Email</mat-label>
          <input matInput type="text" [value]="retailer.ownerEmail" disabled>
        </mat-form-field>
        <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" [value]="retailer.phoneNumber" disabled>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="15px">
          <div (click)="utils.openLinkInNewTab(retailer.websiteUrl)" class="input-link" fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Website</mat-label>
              <input matInput type="text" [value]="retailer.websiteUrl" disabled>
            </mat-form-field>
          </div>
          <div (click)="utils.openLinkInNewTab(retailer.socialLink!)" class="input-link" fxFlex="50">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Social Link</mat-label>
              <input matInput type="text" [value]="retailer.socialLink" disabled>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
