import {Component, Input} from '@angular/core';
import {TabManagementService} from '../../../services/tab-management.service';
import {TermsAndConditionsComponent} from '../terms-and-conditions/terms-and-conditions.component';
import {UtilsService} from '../../../../shared/services/utils.service';
import {PrivacyPolicyComponent} from '../privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss']
})
export class FooterLinksComponent {
  @Input() inAppTabs: boolean;
  @Input() showLinksOnly: boolean;
  @Input() showCopyrightOnly: boolean;


  constructor(private tabManagementService: TabManagementService,
              private utilService: UtilsService) {
    this.inAppTabs = false;
    this.showLinksOnly = false;
    this.showCopyrightOnly = false;
  }

  openPrivacyPolicyLink(): void {
    if (!this.inAppTabs) {
      window.open('https://www.iubenda.com/privacy-policy/85763951');
    } else {
      this.tabManagementService.sendPageAddEvent({
          componentToRegister: PrivacyPolicyComponent,
          pageName: 'Privacy Policy',
          pageHeader: 'View our privacy policy',
          id: this.utilService.generateUuidWithPrefix('privacy_policy'),
          data: {},
        }
      );
    }
  }

  openTermsAndConditionsLink() {
    if (!this.inAppTabs) {
      window.open('https://www.iubenda.com/terms-and-conditions/85763951');
    } else {
      this.tabManagementService.sendPageAddEvent({
          componentToRegister: TermsAndConditionsComponent,
          pageName: 'Terms and Conditions',
          pageHeader: 'View our terms and conditions',
          id: this.utilService.generateUuidWithPrefix('terms_and_conditions'),
          data: {},
        }
      );
    }
  }


  get getDate(): any {
    return new Date();
  }
}
