import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../../shared/services/base.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ApplicationForm} from '../../../core/model/retailer/application-form.model';


@Injectable({
  providedIn: 'root'
})
export class SettingsApplicationFormsService extends BaseService {
  baseUrl: string = environment.apiRetailersHost + '/console/offering/district/settings/application-forms';

  constructor(private http: HttpClient) {
    super();
  }

  public getAllFormTemplatesForCurrentOffering(): Observable<ApplicationForm[]> {
    return this.http.get<ApplicationForm[]>(this.baseUrl);
  }

  public deleteOneFormTemplate(formTemplateUuid: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${formTemplateUuid}`);
  }

  public setOneFormTemplateAsActive(formTemplateUuid: string): Observable<ApplicationForm[]> {
    return this.http.post<ApplicationForm[]>(`${this.baseUrl}/${formTemplateUuid}/activate`, null);
  }

  public getOneTemplate(formTemplateUuid: string): Observable<ApplicationForm> {
    return this.http.get<ApplicationForm>(`${this.baseUrl}/${formTemplateUuid}`);
  }

  duplicateForm(uuid: string | null): Observable<ApplicationForm> {
    return this.http.post<ApplicationForm>(`${this.baseUrl}/${uuid}/duplicate`, null);
  }
}
