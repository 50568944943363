<div class="table-card p-2" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <span class="heading-default">{{ configLabel }}</span>
    <span [matBadge]="selectedGroups" matBadgeSize="small" matBadgeOverlap="false" class="heading-default mx-2">Batches to generate</span>
  </div>

  <div class="full-width" fxLayout="column">

    <div fxFlexOffset="25px" fxLayout="row">
      <table mat-table class="custom-table half-table" [dataSource]="paymentGroupDatasource" fxFlex="68">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="paymentGroupColumns.length"> {{configLabel}} Payment Groups </th>
        </ng-container>

        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" class="custom-table-cell" mat-cell>
            <mat-checkbox (change)="selectGroup(element)" [checked]="element.selected" color="primary"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>Group Name</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="lastRunDate">
          <th *matHeaderCellDef mat-header-cell>Last Run Date</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ dateUtils.displayDayAndDateWithTime(element.lastRunDate) }} </td>
        </ng-container>

        <ng-container matColumnDef="runDateRange">
          <th mat-header-cell *matHeaderCellDef> Date Range </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">
            <app-custom-date-filter [customDateRangePresets]="element.dateFilter"
                                    [initialPreset]="'since_last_run'"
                                    [displayDateInRange]="true"
                                    [showBorder]="true"
                                    (dateRange)="getDateRange($event, element)">
            </app-custom-date-filter>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="paymentGroupColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: paymentGroupColumns;" (click)="viewGroup(row)"></tr>
      </table>

      <table [dataSource]="linkedRetailerDatasource" class="custom-table half-table" fxFlex="32" mat-table>
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="linkedRetailerColumns.length" class="custom-table-label"
              mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="retailerId">
          <th *matHeaderCellDef mat-header-cell>Retailer ID</th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell> {{element.retailerId}} </td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th *matHeaderCellDef mat-header-cell>Company Name</th>
          <td *matCellDef="let element"
              class="custom-table-cell btn-cursor" mat-cell
          > {{element.companyName}} </td>
        </ng-container>

        <ng-container matColumnDef="leaseStartDate">
          <th *matHeaderCellDef mat-header-cell>Lease Start</th>
          <td *matCellDef="let element"
              class="custom-table-cell btn-cursor" mat-cell
          > {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="leaseEndDate">
          <th *matHeaderCellDef mat-header-cell>Lease End</th>
          <td *matCellDef="let element"
              class="custom-table-cell btn-cursor" mat-cell
          > {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
        </ng-container>

        <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
        <tr *matHeaderRowDef="linkedRetailerColumns" class="custom-table-header" mat-header-row></tr>
        <tr *matRowDef="let row; columns: linkedRetailerColumns;" class="custom-table-row" mat-row></tr>
      </table>
    </div>

    <div fxLayout="row">
      <div fxFlex="68">
        <mat-paginator #paginator class="custom-paginator" [length]="paymentGroupDatasource.data.length"
                       [pageSize]="paginator.pageSize" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <div fxFlex="32">
        <mat-paginator #linkedPaginator class="custom-paginator" [length]="linkedRetailerDatasource.data.length"
                       [pageSize]="paginator.pageSize" [hidePageSize]="true"></mat-paginator>
      </div>
    </div>



  </div>
</div>
