<div fxLayout="column" fxLayoutGap="10px" *ngIf="selectedSpace">
  <div [formGroup]="spaceDetailsForm" class="info-card-dark full-width" fxFlexOffset="10px" fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="10px">
    <div class="svg-icon-primary-darker">
      <mat-icon svgIcon="circle_underscore_check"></mat-icon>
    </div>
    <mat-form-field appearance="outline" class="custom-text-field">
      <input (keydown.enter)="saveSelectedSpace()" formControlName="spaceName" matInput
             placeholder="Space name"/>
      <mat-icon class="material-icons-outlined search-icon-suffix" matIconSuffix>edit</mat-icon>
      <mat-error *ngIf="spaceDetailsForm.get('spaceName')?.hasError('required')" class="error-text mb-1">End date is required</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="!dirty" class="info-card-dark" fxLayout="column" fxLayoutGap="10px">
    <span>Quick Statistics</span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="quick-stat-item">
        <span class="quick-stat-title">Size</span>
        <span class="quick-stat-content">{{getSelectedSpaceSize()}}m<sup>2</sup></span>
      </div>
      <div class="quick-stat-item">
        <span class="quick-stat-title">Avg price</span>
        <span class="quick-stat-content">R{{getSpaceAveragePrice()}} m<sup>2</sup></span>
      </div>
      <div class="quick-stat-item">
        <span class="quick-stat-title">Avg term</span>
        <span class="quick-stat-content">{{getSpaceAverageTerm()}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="dirty" class="action-info-card" fxLayout="column" fxLayoutAlign="space-between">
    <div class="full-width body-1-regular-1" style="text-align: start">Action</div>
    <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <button (click)="cancelChanges()" class="button-secondary button-outline" mat-flat-button>
        <div class="button-text">Cancel</div>
      </button>

      <button (click)="saveSelectedSpace()" class="button-primary" mat-flat-button [disabled]="spaceDetailsForm.invalid">
        <div class="button-text">Save</div>
      </button>
    </div>
  </div>


  <div class="info-card-dark" fxLayout="column" fxLayoutGap="10px">
    <span class="heading-regular">Space configuration</span>


    <div [formGroup]="spaceDetailsForm" class="full-width" fxLayout="column" fxLayoutGap="25px" fxFlexOffset="10px">
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label>Size</mat-label>
        <input formControlName="spaceSize" matInput type="number" placeholder="Space size" min="0"/>
        <div matSuffix class="me-2"><span class="ms-2">m<sup>2</sup></span></div>
        <mat-error *ngIf="spaceDetailsForm.get('spaceSize')?.hasError('required')" class="error-text">Size is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-date-picker" floatLabel="always">
        <mat-label>{{selectedSpace.locked ? 'Make available from' : 'Choose the start date'}}</mat-label>
        <input [matDatepicker]="startDatepicker" [max]="getLatestStartDate()"
               [min]="dateUtils.displayShortDate(selectedSpace!.startDate)" formControlName="startDate"
               matInput>
        <mat-datepicker-toggle [for]="startDatepicker" matIconSuffix>
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #startDatepicker>
        </mat-datepicker>
        <mat-error *ngIf="spaceDetailsForm.get('startDate')?.hasError('required')" class="error-text">Start date is required</mat-error>
      </mat-form-field>

      <div *ngIf="selectedSpace?.locked === true && !selectedSpace?.hasHadLease" fxLayout="row"
           fxLayoutAlign="space-between center">
        <span class="body-1-default" style="padding-left: 10px">Delete Space</span>
        <button (click)="deleteSelectedSpace()" class="primary-color" mat-icon-button>
          <span class="material-icons-outlined">delete</span>
        </button>
      </div>

      <mat-form-field *ngIf="selectedSpace?.locked === false" appearance="outline" class="custom-date-picker" floatLabel="always">
        <mat-label>Make unavailable from</mat-label>
        <input [matDatepicker]="endDatepicker"
               [min]="selectedSpace.earliestEndDate ? dateUtils.displayShortDate(selectedSpace!.earliestEndDate) : getDate"
               formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="endDatepicker" matIconSuffix>
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #endDatepicker>
        </mat-datepicker>
        <mat-error *ngIf="spaceDetailsForm.get('endDate')?.hasError('required')" class="error-text">End date is required</mat-error>
        <mat-error *ngIf="spaceDetailsForm.get('endDate')?.hasError('endDateBeforeStartDate')">End date must be after start date</mat-error>
      </mat-form-field>

    </div>


    <div fxLayout="column" fxLayoutGap="10px">
      <div *ngIf="selectedSpace?.locked === false" class="body-1-regular-1" style="padding-left: 10px">Setup preferred
        industries
      </div>
      <div *ngIf="selectedSpace?.locked === true" class="body-1-regular-1" style="padding-left: 10px">Previous preferred
        industries
      </div>
      <div [formGroup]="preferredIndustryGroup" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="custom-select-field">
            <mat-select [(value)]="firstIndCode" [disabled]="selectedSpace.locked"
                        class="custom-select-field-value"
                        formControlName="first">
              <mat-option *ngFor="let option of allIndustries" [value]="option.code"
                          class="custom-select-field-option">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="clearIndustrySelection(0)" [disabled]="selectedSpace.locked"
                  class="warn-orange-color" color="warn"
                  mat-icon-button matIconSuffix>
            <span class="material-icons-outlined">delete</span>
          </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="custom-select-field">
            <mat-select [(value)]="secondIndCode" [disabled]="selectedSpace.locked"
                        class="custom-select-field-value"
                        formControlName="second">
              <mat-option *ngFor="let option of allIndustries" [value]="option.code"
                          class="custom-select-field-option">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="clearIndustrySelection(1)" [disabled]="selectedSpace.locked"
                  class="warn-orange-color" color="warn"
                  mat-icon-button matIconSuffix>
            <span class="material-icons-outlined">delete</span>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">

          <mat-form-field appearance="outline" class="custom-select-field">
            <mat-select [(value)]="thirdIndCode" [disabled]="selectedSpace.locked"
                        class="custom-select-field-value"
                        formControlName="third">
              <mat-option *ngFor="let option of allIndustries" [value]="option.code"
                          class="custom-select-field-option">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="clearIndustrySelection(2)" [disabled]="selectedSpace.locked"
                  class="warn-orange-color" color="warn"
                  mat-icon-button matIconSuffix>
            <span class="material-icons-outlined">delete</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
