import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";

@Component({
  selector: 'app-pipeline-view',
  templateUrl: './pipeline-view.component.html',
  styleUrls: ['./pipeline-view.component.scss']
})
export class PipelineViewComponent extends BasePageComponent{
  retailerId!: string;
  constructor(injector: Injector) {
    super(injector)
    this.retailerId = this.data.id;
  }

}
