import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {LocationOffering, OfferingListing} from '../../../core/model/side-nav.model';

@Injectable({
  providedIn: 'root'
})
export class OfferingListingService extends BaseService{

  baseUrl: string = environment.apiLandlordHost + '/console/landlord/location/offering/listing';

  constructor(private http: HttpClient) {
    super();
  }

  uploadOfferingListingImage(image: any, locationOfferingUuid: string): Observable<LocationOffering> {
    const formData = new FormData();
    formData.append('file', image);
    const uri = this.baseUrl + `/image-upload/${locationOfferingUuid}`;
    return this.http.post<LocationOffering>(uri, formData);
  }

  updateOfferingListing(locationOfferingUuid: string, request: OfferingListing): Observable<LocationOffering> {
    const uri = this.baseUrl + `/${locationOfferingUuid}`;
    return this.http.post<LocationOffering>(uri, request);
  }

  addIndustry(locationOfferingUuid: string, request: { industryCategoryId: number, offeringListingUuid: string }): Observable<LocationOffering> {
    const uri = this.baseUrl + `/${locationOfferingUuid}/industries`;
    return this.http.post<LocationOffering>(uri, request);
  }

  deleteIndustry(locationOfferingUuid: string, industryCategoryId: number): Observable<void> {
    const uri = this.baseUrl + `/${locationOfferingUuid}/industries/${industryCategoryId}`;
    return this.http.delete<void>(uri);
  }
}
