<div class="info-panel p-2 pe-3"
     [class.info-panel-full-width]="fullWidth"
     [class.info-panel-disabled]="type === PanelType.DISABLED"
     fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [class.mb-3]="message.length > 1">
    <mat-icon [class]="getPanelType().iconClass">{{getPanelType().icon}}</mat-icon>
    <div [class.mt-3]="message.length > 1" fxLayout="column" fxLayoutAlign="center start">
      <span class="info-panel-text" *ngFor="let line of message">{{line}}</span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="end center" fxFlexAlign="center">
    <div fxLayout="row" *ngIf="buttons" fxLayoutGap="5px">
      <button class="button-action" mat-flat-button *ngFor="let button of buttonLabels; let i = index" (click)="emit(i)" [disabled]="buttonDisabled![i]">
        <div class="button-text">{{button!}}</div>
      </button>
    </div>
  </div>
</div>
