import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from '../../../../environments/environment';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {BaseService} from '../../../shared/services/base.service';
import {AccountType, PaymentMethodType, PaymentModelClient, PaymentModelDatabase} from '../model/payments.model';

@Injectable({
  providedIn: 'root'
})
export class RetailerPaymentMethodsService extends BaseService {


  baseUrl = environment.apiDistrictsHost;
  private currentPaymentMethod: PaymentModelClient | null = null;

  constructor(private http: HttpClient, private context: CurrentContextService) {
    super();
  }

  updatePaymentMethod(paymentMethod: PaymentModelClient): void {
    this.currentPaymentMethod = paymentMethod;
  }

  initPaymentMethod(paymentMethod: PaymentModelClient): void {
    this.currentPaymentMethod = paymentMethod;
  }

  getMethod(): PaymentModelClient {
    return this.currentPaymentMethod!;
  }

  getTitle(): string {
    if (this.currentPaymentMethod?.uuid) {
      return ('Update');
    }
    return 'Add';
  }

  public areValuesNull(): boolean {
    return this.currentPaymentMethod === undefined || this.currentPaymentMethod === null;
  }

  getBankAccountTypes(): AccountType[] {
    return [
      {code: '1', type: 'Cheque'},
      {code: '2', type: 'Saving'}
    ];
  }

  createOtpRequest(): Observable<any> {
    const offeringUuid = this.context.getCurrentOfferingId();
    let uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/`;
    uri = uri + (this.currentPaymentMethod?.uuid ? 'update-request' : 'create-request');
    return this.http.get<any>(uri);
  }

  paymentMethodOtpConfirm(otp: string, offeringUuid: string): Observable<PaymentModelDatabase> {
    let uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/`;
    uri = uri + (this.currentPaymentMethod?.uuid ? `update-confirm/${otp}` : `create-confirm/${otp}`);
    return this.http.post<PaymentModelDatabase>(uri, this.currentPaymentMethod);
  }

  public getAll(): Observable<PaymentModelDatabase[]> {
    const offeringUuid = this.context.getCurrentOfferingId();
    const uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/get-all`;
    return this.http.get<PaymentModelDatabase[]>(uri);
  }

  public deletePaymentMethod(uuid: string): Observable<Object> {
    const offeringUuid = this.context.getCurrentOfferingId();
    const uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/${uuid}`;
    return this.http.delete(uri);
  }

  public getTypeNameFromCode(code: string): string {
    code = code.replace('_', ' ');
    code = code.toLowerCase();
    code = code.substring(0, 1).toUpperCase() + code.substring(1, code.length);
    return code;
  }

  public getAllOptions(): Observable<PaymentMethodType[]> {
    const offeringUuid = this.context.getCurrentOfferingId();
    // const uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/options`;
    const uri = this.baseUrl + `/console/districts/types/payment-types/${offeringUuid}/payment-method/types`;
    return this.http.get<PaymentMethodType[]>(uri);
  }

  /*
    public getOneUserById(userId: number): Observable<User> {
      const uri = environment.apiSecurityHost + `/api/user/${userId}`;
      return this.http.get<any>(uri);
    }*/
}
