import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Retailer} from "./retailers.model";
import {GenericService} from "../../../shared/services/generic.service";

@Injectable({
  providedIn: 'root'
})
export class RetailersService extends GenericService<Retailer> {

  constructor(http: HttpClient) {
    super(http, environment.apiRetailersHost + `/console/offering/district/pipeline/retailers`);
  }

}
