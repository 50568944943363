import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[header-less-tabs]',
})
export class HeaderLessTabDirective {
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void{
    this.elementRef.nativeElement.children[0].style.display = "none"
  }
}
