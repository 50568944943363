<!--  [description]="'This module allows you to customise the templates for communications'"-->
<div fxLayout="column" fxLayoutGap="20px">
  <app-page-header
    (tabsChangeEvent)="changeTabs($event)"
    [selectedTab]="getInitialTab()"
    [isTabbed]="true"
    [section]="'Template Builder'"
    [tabs]="tabs"
    [title]="'Setup your custom communications'"></app-page-header>

  <mat-sidenav-container class="sidenav-container body-1-regular-2" fxFlexOffset="20px">
  <!--  left Side Nav-->

  <mat-sidenav #leftSideNav mode="side" opened position="start" class="sidenav-left"
               [fixedInViewport]="false" [fixedTopGap]="0"
               [fixedBottomGap]="0">
    <div class="sidenav-left-content template-left-content" fxLayout="column" fxLayoutGap="15px">


      <ng-container [ngSwitch]="isTemplateSelected" fxLayout="column" class="full-width">
        <ng-container *ngSwitchCase="false">

          <div fxLayout="row" class="full-width">
            <div fxLayout="column" fxLayoutGap="15px">
              <div class="heading-default">Select a template to edit by clicking on its name. Activate a template by clicking on the toggle</div>
            </div>
          </div>

          <div fxLayout="row" class="full-width" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutGap="15px">
              <div class="body-1-default">Retailer Communication</div>
              <div fxFlexOffset="5px" *ngFor="let template of retailTemplates" fxLayout="row"
                   fxLayoutGap="10px" fxLayoutAlign="start center">
                <mat-slide-toggle class="template-slide-toggle" [disabled]="loader.loading$ | async"
                                  [checked]="template.notificationEnabled"
                                  (change)="changeNotificationEnabled($event, template)"
                                  color="primary"></mat-slide-toggle>
                <span  class="heading-default btn-cursor" (click)="selectTemplate(template)"> {{template.templateName}}</span>
                <!--              <button [disabled]="loader.loading$ | async" (click)="selectTemplate(template)">{{template.templateName}}</button>-->
              </div>
            </div>
          </div>
          <div fxLayout="row" class="full-width" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutGap="10px">
              <div class="body-1-default">System Notifications</div>
              <div fxFlexOffset="5px" *ngFor="let template of systemTemplates" fxLayout="row"
                   fxLayoutGap="10px" fxLayoutAlign="start center">
                <mat-slide-toggle class="template-slide-toggle" checked="true" disabled
                                  (change)="changeNotificationEnabled($event, template)"
                                  color="primary"></mat-slide-toggle>
                <span  class="heading-default btn-cursor" (click)="selectTemplate(template)"> {{template.templateName}}</span>
                <!--              <button [disabled]="loader.loading$ | async" (click)="selectTemplate(template)">{{template.templateName}}</button>-->
              </div>
            </div>
          </div>

          <div fxLayout="row" class="full-width" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutGap="10px">
              <div class="body-1-default">Internal Communication</div>
              <div fxFlexOffset="5px" *ngFor="let template of internalTemplates" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <mat-slide-toggle class="template-slide-toggle" [disabled]="loader.loading$ | async"
                                  [checked]="template.notificationEnabled"
                                  (change)="changeNotificationEnabled($event, template)"
                                  color="primary"></mat-slide-toggle>
                <span  class="heading-default btn-cursor" (click)="selectTemplate(template)"> {{template.templateName}}</span>
                <!--              <button  [disabled]="loader.loading$ | async" (click)="selectTemplate(template)">{{template.templateName}}</button>-->
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <div fxLayout = "row">
            <span>You are in edit mode. You can add dynamic values to your template and manage recipients of these emails.</span>
          </div>
          <div fxLayout="row">
            <div fxLayout="column" fxLayoutGap="10px">
              <div class="body-1-default">You are currently editing</div>
              <div class="currently-selected-template-icon" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <mat-icon class="material-symbols-outlined">done</mat-icon>
                <span  class="heading-default"> {{selectedTemplate!.templateName}}</span>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column" fxLayoutGap="10px">
              <div class="body-1-default">Set up a custom reply to address for this email</div>
              <mat-form-field appearance="outline" class="custom-text-field">
                <mat-label>Reply to Email Address</mat-label>
                <input [(ngModel)]="selectedTemplate!.replyToAddress" matInput placeholder="info@sokodistrict.com"
                       type="text"/>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" class="full-width">
            <div fxLayout="column" fxLayoutGap="15px">
              <div class="body-1-default">Dynamic Values</div>
              <div>These dynamic values are required for this specific template. They can be found under merge tags in
                the editor
              </div>

              <div *ngFor="let tag of selectedTemplate!.mergeTags">
                <button class="button-primary merge-tag-display" mat-flat-button>
                  <div class="button-text">{{ utils.camelCaseToWords(Object.keys(tag)[0]) }}</div>
                </button>
              </div>
            </div>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </mat-sidenav>

  <!--  Main Content-->
  <mat-sidenav-content cdkScrollable class="sidenav-main-content">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">

      <!--      Main content area-->
      <div class="content-padding" fxLayout="row" fxLayoutAlign="space-between center">
        <app-email-template-designer #templateDesignerComponent [offeringUuid]="offering!.uuid!" style="width: 100%" (saveTemplate)="saveAllChanges()" [selectedTemplate]="this.selectedTemplate!" (selectedTemplateChange)="changeSelectedTemplate($event)">

        </app-email-template-designer>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
