import {SelectionModel} from "@angular/cdk/collections";
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {tap} from "rxjs";
import {PageOffering} from "../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../core/services/loading.service";
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {
  AgreementType,
  LeaseGridSpace,
  LeaseSummary,
  SharedLeasesSpaceSize,
  SpaceSelection,
  SubLeaseRequest
} from "../../leasing.model";
import {LeaseSubLeasesService} from "../lease-sub-leases/lease-sub-leases.service";
import {LeaseViewService} from "../lease-view.service";
import {AddASpaceDialogComponent} from "./add-a-space-dialog/add-a-space-dialog.component";

@Component({
  selector: 'app-lease-spaces',
  templateUrl: './lease-spaces.component.html',
  styleUrls: ['./lease-spaces.component.scss']
})
export class LeaseSpacesComponent implements OnInit, AfterViewInit {
  @Input() id!: string;
  @Input() offering!: PageOffering;
  @ViewChild('paginator') paginator!: MatPaginator;
  @Output() reloadLease = new EventEmitter<void>();

  displayedSpaceColumns: string[] = ['select', 'id', 'spaceName', 'size', 'startDate', 'endDate'];
  displayedSharedSpaceColumns: string[] = ['select', 'id', 'occupiedSpace', 'startDate', 'endDate'];

  leaseSpaceDataSource!: MatTableDataSource<LeaseGridSpace>;
  sharedSpacesDataSource!: MatTableDataSource<SharedLeasesSpaceSize>;

  leaseGridSpaceSelection = new SelectionModel<LeaseGridSpace>(true, []);
  sharedSpacesSpaceSelection = new SelectionModel<SharedLeasesSpaceSize>(true, []);

  lease?: LeaseSummary;
  parentLease?: LeaseSummary;

  removePanelOpen: boolean = false;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private leaseViewService: LeaseViewService,
              private subLeasesService: LeaseSubLeasesService,
              private matDialog: MatDialog,
              public loader: LoadingService) {
  }

  ngOnInit() {
    this.loadLease();
  }

  loadLease() {
    this.leaseViewService.getOne(``, this.id).subscribe({
      next: value => {
        this.lease = value;
        this.loadSpaces();
        if (this.isSubLease) this.loadParentLease();
      },
      error: err => console.log(err)
    })
  }

  loadParentLease() {
    this.leaseViewService.getOne(``, this.lease!.parentUuid!).subscribe({
      next: value => {
        this.parentLease = value;
      },
      error: err => console.log(err)
    })
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => {}))//load paged data here//
      .subscribe();
  }

  loadSpaces() {
    if (!this.isSubLease) {
      this.leaseViewService.getLeaseSpaces(this.id).subscribe({
        next: value => this.leaseSpaceDataSource = new MatTableDataSource<LeaseGridSpace>(value),
        error: err => console.log(err)
      })
    }
    if (this.isSubLease) {
      this.subLeasesService.getSharedSpaces(this.id).subscribe({
        next: value => {
          this.sharedSpacesDataSource = new MatTableDataSource<SharedLeasesSpaceSize>(value);
          if (this.lease?.leaseType == 'APPLICATION') {
            this.sharedSpacesSpaceSelection.select(...value);
          }
        },
        error: err => console.log(err)
      })
    }
  }

  addSpace() {
    this.matDialog.open(AddASpaceDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        edit: false,
        lease: this.lease,
        space: null,
        header: 'Add a new space',
        offering: this.offering,
        sharedSpace: null,
        minStart: this.parentLease?.leaseStartDate,
        maxEnd: this.parentLease?.leaseEndDate,
        parentSpace: this.parentLease?.totalSize
      }
    }).afterClosed()
      .subscribe(() => {
        console.log('closed');
        this.leaseGridSpaceSelection.clear();
        this.sharedSpacesSpaceSelection.clear();
        this.loadLease();
        this.reloadLease.emit();
      });
  }

  editSpace() {
    this.matDialog.open(AddASpaceDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        edit: this.lease?.leaseType == 'LEASE',
        lease: this.lease,
        space: this.leaseGridSpaceSelection.selected[0],
        header: 'Edit a space',
        offering: this.offering,
        sharedSpace: this.sharedSpacesSpaceSelection.selected[0],
        minStart: this.parentLease?.leaseStartDate,
        maxEnd: this.parentLease?.leaseEndDate,
        parentSpace: this.parentLease?.totalSize
      }
    }).afterClosed()
      .subscribe(() => {
        console.log('closed');
        this.leaseGridSpaceSelection.clear();
        this.sharedSpacesSpaceSelection.clear();
        this.loadLease();
        this.reloadLease.emit();
      });
  }

  removeSpace(remove: boolean) {
    if (remove) {
      if (!this.isSubLease) {
        const request = new SpaceSelection(
          this.lease!.uuid,
          this.lease!.retailerId,
          this.offering.offeringUuid,
          null,
          null,
          null,
          null,
          this.leaseGridSpaceSelection.selected.map(m => m.uuid),
          true,
          null,
          null
        )

        const spaceUuids = this.leaseGridSpaceSelection.selected.map(m => m.uuid);

        this.leaseViewService.removeLeaseSpaces(request, this.id, spaceUuids).subscribe({
          next: () => {
            this.leaseGridSpaceSelection.clear();
            this.loadLease();
            this.reloadLease.emit();
            this.removePanelOpen = false;
          },
          error: err => console.log(err)
        })
      }

      if (this.isSubLease) {
        const spaceUuids = this.sharedSpacesSpaceSelection.selected.map(m => m.uuid!);

        this.subLeasesService.removeSharedSpaces(this.lease!.districtUuid!, this.id, spaceUuids).subscribe({
          next: () => {
            this.sharedSpacesSpaceSelection.clear();
            this.loadLease();
            this.reloadLease.emit();
            this.removePanelOpen = false;
          },
          error: err => console.log(err)
        })
      }

    } else {
      this.removePanelOpen = false;
    }
  }

  get isLastSpace(): boolean {
    return this.sharedSpacesDataSource && this.sharedSpacesDataSource.data.length < 2;
  }

  get areSpacePastDated(): boolean {
    const pastSpaces = this.leaseGridSpaceSelection.selected.filter(f => this.dateUtils.isPastDated(f.leaseStartDate));
    return pastSpaces.length > 0;
  }

  get dataSource() {
    if (this.leaseSpaceDataSource) return this.leaseSpaceDataSource.data;
    if (this.sharedSpacesDataSource) return this.sharedSpacesDataSource.data;
    return null;
  }

  get canRemove() {
    const invalid = this.isLastSpace || this.areSpacePastDated;
    if (!this.isSubLease) return invalid || !(this.leaseGridSpaceSelection.selected.length > 0);
    if (this.isSubLease) return invalid || !(this.sharedSpacesSpaceSelection.selected.length > 0);
    return invalid;
  }

  get canEdit() {
    const application = this.lease?.leaseType == 'APPLICATION';
    if (!this.isSubLease) return application || this.leaseGridSpaceSelection.selected.length == 1;
    if (this.isSubLease) return application || this.sharedSpacesSpaceSelection.selected.length == 1;
    return application;
  }

  get isSubLease() {
    if (this.lease) return this.lease.agreementType == AgreementType.SUB_LEASE;
    return false;
  }

  protected readonly PanelType = PanelType;
}
