import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {MatDialog} from '@angular/material/dialog';
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {BillingDetailsService} from '../../service/billing-details.service';
import {RetailerPaymentMethodsService} from '../../service/payment-methods.service';
import {PaymentModelDatabase} from '../../model/payments.model';
import {BillingDetailsModel} from '../../model/billing-details.model';
import {ErrorPopupComponent} from '../../../../shared/components/dialogs/error-popup/error-popup.component';
import {lastValueFrom} from 'rxjs';
import {AddressService} from '../../../../shared/services/address/address.service';
import {LoadingService} from '../../../../core/services/loading.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent {

  isEditing: boolean = false;

  userBillingForm = new FormGroup({
    billingName: new FormControl({value: '', disabled: true}, Validators.required),
    compReg: new FormControl({value: '', disabled: true}, Validators.required),
    vatReg: new FormControl({value: '', disabled: true}),
    phoneNum: new FormControl({value: '', disabled: true}, Validators.required),
    billingEmail: new FormControl({value: '', disabled: true}, Validators.required),
    address: new FormControl({value: '', disabled: true}),
  });
  loading = false;
  errMessage = '';
  paymentMethodsArray: PaymentModelDatabase[] = [];
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  billingInfoUuid = '';
  btnText = '...Working';
  sameAddress = true;
  formattedAddress = '';
  updateUsers: { id: number, name: string }[] = [];
  private place: any = null;
  private geometry: any = null;

  constructor(public paymentsService: RetailerPaymentMethodsService,
              private context: CurrentContextService,
              private dialogService: MatDialog,
              private billingService: BillingDetailsService,
              public mapsAddressUtil: AddressService,
              public loader: LoadingService) {
  }

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.loadForm();
    this.loadPageData();
  }

  loadPageData(): void {
    this.loading = true;
    lastValueFrom(this.paymentsService.getAll())
      .then((res: PaymentModelDatabase[]) => {
        this.paymentMethodsArray = res;
        return lastValueFrom(this.billingService.getBillingDetailsForRetailer())
          .then((result: BillingDetailsModel) => {
            return Promise.resolve(result);
          });
      }, (err: string) => {
        this.handleError(err);
        console.log(err);
        return Promise.resolve(null);
      })
      .then((res: BillingDetailsModel | null) => {
        if (res != null) {
          this.patchBillingInfo(res);
          this.formattedAddress = res.formattedAddress;
        } else {
          this.sameAddress = false;
        }
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        console.log(err);
        this.handleError(err);
      });
  }

  public onSaveChanges(): void {
    if (this.userBillingForm.invalid)
      return;

    this.loading = true;
    const retailerBillingDto: BillingDetailsModel = {
      billingName: this.userBillingForm.value.billingName!,
      compReg: this.userBillingForm.value.compReg!,
      phoneNum: this.userBillingForm.value.phoneNum!,
      vatReg: this.userBillingForm.value.vatReg!,
      billingEmail: this.userBillingForm.value.billingEmail!,
      sameAddress: this.sameAddress,
      uuid: this.billingInfoUuid,
      formattedAddress: this.formattedAddress
    };
    if (!this.sameAddress) {
      const addressData = this.mapsAddressUtil.parseAddressData(this.geometry, this.place);
      retailerBillingDto.address = addressData.address;
      retailerBillingDto.addressPlace = addressData.addressPlace;
    }
    lastValueFrom(this.billingService.updateBillingDetails(retailerBillingDto))
      .then((res: BillingDetailsModel) => {
        this.isEditing = false;
        this.patchBillingInfo(res);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }, (err: string) => {
        this.loading = false;
        this.handleError(err);
      });
  }

  public loadForm(): void {
    this.userBillingForm.reset();
  }

  public onAddressAutocompleteSelected(event: any): void {
    this.sameAddress = false;
    this.place = event;
  }

  public onAddressLocationSelected(event: any): void {
    this.sameAddress = false;
    this.geometry = event;
  }

  toggleIsEditing(): void {
    this.isEditing = (!this.isEditing);
    if (this.isEditing) {
      this.userBillingForm.enable();
    } else {
      this.userBillingForm.disable();
    }
  }

  showString(longString: any): string {
    const length = longString.length;
    return (longString.substring(length - 4, length));
  }

  private handleError(err: any): void {
    switch (err.status) {
      case 400:
        if ('ValidationException' === err.headers.get('error_code')) {
          this.errMessage = '' + err.headers.get('error_message');
        }
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'unexpected error: ' + this.errMessage,
          },
        });
        break;
      default:
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'unexpected error',
          },
        });
    }
  }

  private patchBillingInfo(info: BillingDetailsModel): void {
    this.billingInfoUuid = info.uuid!;
    this.formattedAddress = info.formattedAddress;
    this.userBillingForm.patchValue({
      billingName: info.billingName,
      compReg: info.compReg,
      vatReg: info.vatReg,
      phoneNum: info.phoneNum,
      billingEmail: info.billingEmail,
      address: info.formattedAddress
    });
  }

  protected readonly PanelType = PanelType;
}
