import {ChartConfiguration} from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

export const barChartOptions: ChartConfiguration['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.5
    }
  },
  scales: {
    x: {
      border: {display: true},
      grid: {display: false},
      ticks: {
        maxRotation: 0,
        font: {
          family: 'Roboto'
        }
      }
    },
    y: {
      display: true
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      font: {
        family: 'Roboto'
      }
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      backgroundColor: 'white',
      titleFont: {
        family:'Roboto',
        size: 10,
        weight: '500'
      },
      titleColor: '#000000BD',
      bodyFont: {
        family:'Roboto',
        size: 10,
        weight: '300'
      },
      bodyColor: '#00000099',
      usePointStyle: true
    }
  },
  layout: {
    padding: 10
  }
}

export const barChartPlugins = [ DataLabelsPlugin ];
