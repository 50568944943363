import {Component, Input, OnInit} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {PageEventInterface} from "../../../../../core/interfaces/page-event.interface";
import {PageOffering} from '../../../../../core/model/side-nav.model';
import {CurrentContextService} from "../../../../../core/services/security/current-context.service";
import {TabManagementService} from "../../../../../core/services/tab-management.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {
  CreateALeaseDialogComponent
} from '../../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component';
import {
  LeaseCreateMethod
} from "../../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {LeaseSummary, OccupancyType} from "../../../../leasing/leasing.model";
import {Retailer, TenantType} from "../../retailers.model";
import {RetailerViewComponent} from "../retailer-view.component";
import {RetailerViewService} from "../retailer-view.service";

@Component({
  selector: 'app-retailer-details',
  templateUrl: './retailer-details.component.html',
  styleUrls: ['./retailer-details.component.scss']
})
export class RetailerDetailsComponent implements OnInit {
  @Input() id!: string;
  @Input() header?: string = 'Retailer Details'
  @Input() createLease?: boolean = false;
  @Input() offering!: PageOffering;

  retailer!: Retailer;

  constructor(public context: CurrentContextService,
              private retailerService: RetailerViewService,
              private tabService: TabManagementService,
              public utils: UtilsService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.loadRetailer();
  }

  loadRetailer() {
    this.retailerService.getRetailerDetails(this.id).subscribe({
      next: value => {
        this.retailer = value;
      },
      error: err => {
        console.log(err);
      }
    })
  }

  displayIndustriesList(retailer: Retailer): string {
    const industryArray = retailer.industries.map(m => m.industryType.name);
    return this.utils.displayListAsString(industryArray);
  }

  openLeaseDialog(): void {
    const occupancyType = this.retailer.tenantType == TenantType.TENANT ? OccupancyType.SINGLE : OccupancyType.SHARED;
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: LeaseCreateMethod.NEW_LEASE_FOR_RETAILER,
        header: 'Create a new lease',
        offering: this.offering,
        lease: new LeaseSummary({retailerId: this.retailer.id, retailerCompanyName: this.retailer.companyName, occupancyType}),
      }
    });
  }

  openRetailerViewTab() {
    let payload: PageEventInterface = {
      componentToRegister: RetailerViewComponent,
      pageName: 'Retailer ' + this.retailer.id,
      pageHeader: this.offering.label,
      data: { id: this.retailer.id, companyName: this.retailer.companyName, tenantType: this.retailer.tenantType },
      id: this.utils.generateUuidWithPrefix('retailer'),
      offeringId: this.offering.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'retailer',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }
}
