import {Moment} from "moment";

export enum TimelinePeriod {
  WEEK = "week", MONTH = "month"
}

export interface TimelinePeriodConfig {
  label: string,
  period: TimelinePeriod,
  columns: number,
  columnSize: number
}

export interface Timeline {
  displayName: string // not needed now
  description: string // long description at top
  timelineLists: TimelineList[]
  focusCurrentDate: boolean
  earliestDate: Date,
  latestDate: Date,
  newElementConfig?: ObjectConfig,
  newObjectLabel?: string,
  initialPeriod: TimelinePeriod
}

export interface TimelineList {
  listId: string,
  displayName: string, // Available
  displayOrder: number,
  searchTerm: string, // doesn't need to be passed down but holds item until emit
  actionLabel: string, // Add space, Manage spaces
  actionButton: boolean,
  listElements: TimelineElement[] // All spaces,
  maxLines: number
}

export interface TimelineElement {
  uuid: string, // spaceId,
  listId: string,
  displayName: string, // spaceName
  displayValue: string, // spaceSize
  elementObjects: TimelineObject[]
}

export interface TimelineObject extends ObjectConfig {
  uuid: string, // lease grid space uuid
  elementId: string,
  objectData: TimelineObjectData[]
  startDate: Date | string,
  endDate: Date | string | null,
  listId: string,
}

export interface TimelineObjectData {
  uuid: string,
  displayName: string, // companyName
  parentUuid: string | null, // leaseUuid
  groupUuid: string | null,
  groupRanking: number
}

export interface ObjectConfig {
  editable?: boolean, // overrides all other drag flags if false
  expandable?: 'left' | 'right' | 'any' | 'none',
  draggable?: 'vertical' | 'horizontal' | 'any' | 'none',
  expandInfinitely?: boolean // only applies if end date is null
  lighten?: number, // use scss and preset classes on primary color
  darken?: number, // use scss and preset classes on primary color
  icon?: string, // svg name loaded using domSanitizer
}

export interface TimelineColumn {
  month: string,
  week: number,
  dates: Date[][]
}

export interface TimelineDate {
  x: number,
  month: string,
  week: number,
  dates: Date[]
}

export interface GridItemData {
  column: TimelineColumn | null,
  date: Moment[] | null,
  object: {listId: string, elementId: string, uuid: string} | null
}

export interface ElementRow {
  uuid: string,
  displayName: string,
  displayValue: string,
  listId: string,
  y: number[]
}
