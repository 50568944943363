<div fxLayout="column" style="background-color: white">

  <app-page-header [section]="'Support'" [title]="'Contacting Customer Care for Support'" [description]="'Get in touch with the right person at Nter, we’re here to help.'" [backgroundWhite]="true"></app-page-header>

  <div class="full-screen-height-page-default page-padding" fxLayout="column">
    <mat-divider fxFlexOffset="70px"></mat-divider>

    <div class="support-item" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Get General Support</div>
        <div class="body-1-regular-2">Get help with general or technical matters. Log a ticket on the helpdesk</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="logSupportRequest()">
          <div class="button-text">Log a ticket</div>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="support-item" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Ask an Accounting Question</div>
        <div class="body-1-regular-2">Get professional feedback regarding your account, billing and subscriptions.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="logAccountsRequest()">
          <div class="button-text">Log a ticket</div>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="support-item" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Become a point-of-sale Expert</div>
        <div class="body-1-regular-2">Watch tutorials, browse articles or contact an agent to help your retailers fulfil sales and manage inventory.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="goToHelpCentre()">
          <div class="button-text">Tour the Help Centre</div>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="support-item" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Point-of-sale or payment device Support </div>
        <div class="body-1-regular-2">Do you need a POS account for a new tenant or want to cancel a POS account. Let us help you.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <button class="button-primary button-width" mat-flat-button (click)="requestPOSAccount(false)">
          <div class="button-text">Request a POS account</div>
        </button>
        <button class="button-primary button-width" mat-flat-button (click)="requestPOSAccount(true)">
          <div class="button-text">Cancel a POS account</div>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>

</div>
