<div class="full-width custom-date-filter-wrapper" fxLayoutGap="15px"
     fxLayoutAlign="start center">
  <div *ngIf="presetsShowing">
    <mat-button-toggle-group [ngClass]="showBorder ? 'custom-date-button-toggle' : 'custom-borderless-button-toggle'" [(value)]="dateOption" (change)="broadcastDateRange($event)">
      <ng-container *ngFor="let customRange of customDateRangePresets">
        <mat-button-toggle value="{{customRange.presetId}}" [disabled]="loading$ | async" style="height: 33px">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>{{customRange.getReadableFormat()}}</div>

            <ng-container *ngIf="customRange.timeFrame == TimeFrames.BOUNDARY">
              <mat-form-field style="width: 1px; visibility: hidden; height: 0">
                <input matInput [matDatepicker]="picker" (dateChange)="selectBoundaryDate(true, $event, customRange)">
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-datepicker-toggle class="custom-button-date-picker custom-date-picker"
                                     [class.mat-datepicker-toggle-active]="dateOption === customRange.presetId"
                                     matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>today</mat-icon>
              </mat-datepicker-toggle>
            </ng-container>
          </div>
        </mat-button-toggle>

      </ng-container>

      <mat-button-toggle [value]="'custom'" fxHide></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-form-field [ngClass]="dateOption === 'custom' ? 'custom-date-picker-active' : 'custom-date-picker'"
                  appearance="outline"
                  *ngIf="datePickerShowing" floatLabel="always">
    <mat-label *ngIf="customLabel">{{customLabel}}</mat-label>
    <mat-date-range-input (click)="selectCustomDate(false)" [formGroup]="range" [max]="maxDate" [min]="minDate"
                          [rangePicker]="rangePicker" [disabled]="loading$ | async">
      <input formControlName="start" matStartDate [placeholder]="placeholder"/>
      <input matEndDate formControlName="end" (dateChange)="selectCustomDate(true)"/>
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="rangePicker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

  <div *ngIf="showClearButton">
    <button (click)="clearFilters()" class="button-action-cancel" mat-flat-button>
      <div class="button-text">Clear</div>
    </button>
  </div>
</div>



