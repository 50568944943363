<div fxLayout="column">

  <app-page-header
    (tabsChangeEvent)="changeTabs($event)"
    [selectedTab]="getInitialTab()"
    [description]="'This is the internal system pay-out module to setup and create payment batches for automatic pay-outs to retailers.'"
    [isTabbed]="true"
    [section]="'Run Pay-Outs'"
    [tabs]="tabs"
    [title]="'Setup your retailer pay-outs to run smoothly'"></app-page-header>

  <div class="full-screen-height-page-banner" fxLayout="column">
    <div *ngIf="activeTab == 'import_products'" class="page-content page-padding" fxLayout="column">
      <app-products-jobcard [offering]="offering!" [jobType]="'products'"
                            [jobNames]="['products_job_import_products', 'products_job_import_retailer_products']"></app-products-jobcard>
    </div>

    <div *ngIf="activeTab == 'import_inventory'" class="page-content page-padding" fxLayout="column">
      <app-products-jobcard [offering]="offering!" [jobType]="'inventory'"
                            [jobNames]="['products_job_import_product_inventory', 'products_job_import_retailer_product_inventory']"></app-products-jobcard>
    </div>
  </div>



</div>
