<div fxLayout="column">
  <app-page-header [section]="'Details'"
                   [title]="'Setup your details to run smoothly'"
                   [description]="'The settings module allows you to view and configure your information. This configuration ' +
'automatically integrates across all other features like support and accounts.'">
  </app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

    <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px">
      <div class="info-card" fxLayout="column">
        <span class="heading-default">General information</span>
        <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-form-field class="custom-text-autofill" appearance="outline">
            <mat-label>System ID</mat-label>
            <input matInput type="text" [value]="this.currentLocation?.uuid!.toString()" disabled />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-form-field class="custom-text-autofill" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" [value]="this.offering!.label" disabled/>
          </mat-form-field>
          <mat-form-field class="custom-text-autofill" appearance="outline" *ngIf="offering!.offeringAddress">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Input offering address" type="text" [value]="this.offering!.offeringAddress"
                   disabled/>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-form-field class="custom-text-autofill" appearance="outline">
            <mat-label>Property</mat-label>
            <input matInput type="text" [value]="this.currentLocation?.locationName" disabled/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="custom-text-field">
            <mat-label>Property Address</mat-label>
            <input
              matInput
              matGoogleMapsAutocomplete
              [value]="this.currentLocation?.locationAddress!.formattedAddress"
              (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
              (onLocationSelected)="onAddressLocationSelected($event)" disabled />
          </mat-form-field>
        </div>

      </div>
    </div>
  </div>
</div>
