import {
  alphanumericPattern,
  dateTimePattern,
  numberPattern,
  UploadDataModel
} from "../../../../../shared/components/csv-import/csv-import.model";

export const salesTurnoverConfig: UploadDataModel[] = [
  {
    property: 'transactionDate',
    label: 'Sale Date',
    required: true,
    validators: [dateTimePattern],
    validationMessage: 'Invalid format, requires: yyyy-MM-dd',
    description: 'The date on which the sale took place.',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'transactionInvoiceNumber',
    label: 'Transaction Invoice Number',
    required: true,
    validators: [alphanumericPattern],
    validationMessage: 'Invalid format, requires any alphanumeric pattern',
    description: 'The unique number/identifier for this specific sale.',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'saleAmount',
    label: 'Sale Amount',
    required: true,
    validators: [numberPattern],
    validationMessage: 'Invalid format, requires only numbers',
    description: 'The amount charged for this sale excluding tax.',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'saleAmountTax',
    label: 'Sale Amount Tax',
    required: true,
    validators: [numberPattern],
    validationMessage: 'Invalid format, requires only numbers',
    description: 'The tax amount generated for this sale.',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'processingStatus',
    label: 'Transaction Status',
    required: false,
    validators: [],
    validationMessage: '',
    description: '',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  },
  {
    property: 'saleStatus',
    label: 'Sale Status',
    required: false,
    validators: [],
    validationMessage: '',
    description: '',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  },
  {
    property: 'saleType',
    label: 'Sale Type',
    required: false,
    validators: [],
    validationMessage: '',
    description: '',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  },
  {
    property: 'transactionSource',
    label: 'Transaction Source',
    required: false,
    validators: [],
    validationMessage: '',
    description: '',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  },
];
