import {DateRange} from '@angular/material/datepicker';

export class CustomDateRangeChangeEventModel {
  private _id: string;
  private _dateRange: DateRange<Date>;


  constructor(id: string, dateRange: DateRange<Date>) {
    this._id = id;
    this._dateRange = dateRange;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get dateRange(): DateRange<Date> {
    return this._dateRange;
  }

  set dateRange(value: DateRange<Date>) {
    this._dateRange = value;
  }
}
