<div class="config-container full-width" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="sendPreviewForm()" class="form-icon-button" mat-icon-button>
      <mat-icon class="material-icons-outlined">visibility</mat-icon>
    </button>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <button (click)="saveFormTemplate()" class="button-outline-white form-button" mat-flat-button>
        <div class="button-text">Save</div>
      </button>

      <button (click)="publishFormTemplate()" class="button-primary form-button" mat-flat-button>
        <div class="button-text">Publish</div>
      </button>
    </div>

  </div>

  <div *ngIf="_questionGroup" class="scoring-rating-wrapper" fxLayout="column" fxLayoutGap="30px">
    <div [formGroup]="importanceForm" class="info-card py-3" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
      <span class="heading-regular">Question rating</span>
      <div class="heading-bold px-3" fxFlexOffset="15px">How much do you want this answer to affect the applicants overall suitability?</div>
      <span class="body-1-regular-2 px-3" fxFlexOffset="15px">Rate this question</span>
      <mat-slider discrete="false" max="4" min="0" showTickMarks="true" step="1" style="width: 205px">
        <input formControlName="importanceRating" matSliderThumb/>
      </mat-slider>
      <div class="footer-regular slider-footer" fxFlexAlign="end">Suitability Rating</div>
      <div class="full-width body-1-default" fxLayout="row" fxLayoutAlign="center end">{{getRatingText()}}</div>
    </div>

    <div class="info-card py-3" fxLayout="column" fxLayoutGap="15px">
      <div class="heading-regular">Answer scoring</div>
      <div class="heading-bold px-3" fxFlexOffset="15px">How much better would you rate one answer compared to another?</div>
      <span class="body-1-regular-2 px-3" fxFlexOffset="15px">Drag and drop pass and ranking</span>


      <ng-container *ngIf="isSelectTypeControl()">
        <div class="info-card-dark answer-container">
          <div class="body-1-default mb-2">Prefer and Pass</div>
          <div
            #passAndPreferList="cdkDropList"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListConnectedTo]="[dontPassList]"
            [cdkDropListData]="passAndPreferOptions"
            cdkDropList
            class="answer-option-list">
            <div *ngFor="let item of passAndPreferOptions; let i = index" cdkDrag class="answer-item-container">
              {{item}}

              <svg class="badge-rating-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 24.119 31.656">
                <g transform="translate(-11762.98 -10751.5)">
                  <path d="M163.166-848.344V-859.8a10.665,10.665,0,0,1-2.431-3.881,12.872,12.872,0,0,1-.735-4.259,11.668,11.668,0,0,1,3.467-8.592A11.668,11.668,0,0,1,172.059-880a11.668,11.668,0,0,1,8.592,3.467,11.668,11.668,0,0,1,3.467,8.592,12.872,12.872,0,0,1-.735,4.259,10.665,10.665,0,0,1-2.431,3.881v11.457l-8.894-2.977Zm8.894-9.8a9.458,9.458,0,0,0,6.953-2.845,9.458,9.458,0,0,0,2.845-6.953,9.458,9.458,0,0,0-2.845-6.953,9.458,9.458,0,0,0-6.953-2.845,9.458,9.458,0,0,0-6.953,2.845,9.458,9.458,0,0,0-2.845,6.953,9.458,9.458,0,0,0,2.845,6.953A9.458,9.458,0,0,0,172.059-858.142Zm-6.633,6.633,6.633-2.073,6.633,2.073v-6.444a10.221,10.221,0,0,1-3.241,1.583,12.442,12.442,0,0,1-3.392.49,12.442,12.442,0,0,1-3.392-.49,10.221,10.221,0,0,1-3.241-1.583ZM172.059-854.751Z" transform="translate(11602.98 11631.5)"/>
                  <text transform="translate(11775.04 10768.394)" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="-3.442" y="0">{{i + 1}}</tspan></text>
                </g>
              </svg>

            </div>
          </div>
        </div>

        <div class="info-card-dark answer-container">
          <div class="body-1-default mb-2">Don't pass</div>
          <div
            #dontPassList="cdkDropList"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListConnectedTo]="[passAndPreferList]"
            [cdkDropListData]="failOptions"
            cdkDropList
            class="answer-option-list">
            <div *ngFor="let item of failOptions" cdkDrag class="answer-item-container">{{item}}
              <mat-icon class="material-icons-outlined mat-icon warn-orange-color" color="warn" matIconSuffix> block</mat-icon>
            </div>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="isNumberRangeControlType() || isNumberControlType()">
        <div class="info-card-dark answer-container">
          <div class="body-1-default mb-2">Prefer and Pass</div>
          <div [formGroup]="passNumberGroup" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
            <mat-form-field appearance="outline" class="custom-select-field" style="width: 100%;">
              <mat-select formControlName="aboveOrBelow">
                <mat-option class="custom-select-field-option" value="ABOVE">Above</mat-option>
                <mat-option class="custom-select-field-option" value="BELOW">Below</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-text-field" style="background-color: white; width: 100%;">
              <input [max]="getNumberRangeMax()" [min]="getNumberRangeMin()" formControlName="numberValue" matInput
                     placeholder="Input Number" type="number"/>
            </mat-form-field>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
