import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {IndustryCategory} from "../../../features/leads/industry.model";

@Injectable({
  providedIn: 'root'
})
export class IndustriesPickerService {
  constructor(private http: HttpClient) {
  }

  getAllIndustries(): Observable<IndustryCategory[]> {
    return this.http.get<[]>(environment.apiRetailersHost + '/api/public/retailer-industries');
  }
}
