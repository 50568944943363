import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from "../../core/components/page-content/base-page.component";
import {LocationOffering} from "../../core/model/side-nav.model";
import {CurrentContextService} from "../../core/services/security/current-context.service";
import {PageEventInterface} from "../../core/interfaces/page-event.interface";
import {UtilsService} from "../../shared/services/utils.service";
import {TabManagementService} from "../../core/services/tab-management.service";
import {
  DashboardFootfallComponentComponent
} from "./dashboard-footfall-component/dashboard-footfall-component.component";
import {DashboardType, dashboardTypes} from "./dashboards.model";
import {DashboardsLeasingReportComponent} from "./dashboards-leasing-report/dashboards-leasing-report.component";
import {DashboardsLeadsReportComponent} from "./dashboards-leads-report/dashboards-leads-report.component";
import {
  DashboardsPerformanceReportComponent
} from "./dashboards-performance-report/dashboards-performance-report.component";
import {DashboardsRetailerReportComponent} from "./dashboards-retailer-report/dashboards-retailer-report.component";

@Component({
  selector: 'app-dashboards-landing',
  templateUrl: './dashboards-landing.component.html',
  styleUrls: ['./dashboards-landing.component.scss']
})
export class DashboardsLandingComponent extends BasePageComponent implements OnInit {

  offerings: LocationOffering[] = this.data.offerings;
  private _grantedDashboardTypes: Map<string, DashboardType[]> = new Map([]);

  constructor(injector: Injector, private currentContext: CurrentContextService, private utils: UtilsService, private tabsService: TabManagementService) {
    super(injector);
  }

  ngOnInit(): void {
    this.parseDashboardTypes(this.offerings);
  }

  private canAccessForOffering(offeringUuid: string, dashboardType:  DashboardType): boolean {
    return this.currentContext.checkUserFeatures(dashboardType.features, offeringUuid);
  }

  private parseDashboardTypes(offerings: LocationOffering[]): void {
    this._grantedDashboardTypes = new Map([]);

    offerings.forEach(offering => {
      const types: DashboardType[] = dashboardTypes.filter(type => this.canAccessForOffering(offering.offeringUuid, type));
      this._grantedDashboardTypes.set(offering.offeringUuid, types);
    });
  }

  getDashboardTypes(offeringUuid: string): DashboardType[] {
    return this._grantedDashboardTypes.get(offeringUuid) || [];
  }

  openOfferingDashboardTab(dashboard: string, offering: LocationOffering): void {
    let component: any;

    switch (dashboard) {
      case 'Leads Report':
        component = DashboardsLeadsReportComponent;
        break;
      case 'Leasing Report':
        component = DashboardsLeasingReportComponent;
        break;
      case 'Performance Report':
        component = DashboardsPerformanceReportComponent;
        break;
      case 'Tenant Trading Report':
        component = DashboardsRetailerReportComponent;
        break;
      case 'Footfall Report':
        component = DashboardFootfallComponentComponent;
        break;
    }

    this.currentContext.setCurrentOfferingId(offering.offeringUuid);

    let payload: PageEventInterface = {
      componentToRegister: component!,
      pageName: dashboard,
      pageHeader: offering.offeringName,
      data: { offering: offering },
      id: this.utils.generateUuidWithPrefix('dashboards'),
      offeringId: offering.offeringUuid,
      sectionCode: 'dashboards',
      pageCode: 'dashboard'
    }
    this.tabsService.sendPageAddEvent(payload);
  }

}
