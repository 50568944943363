import {Injectable} from '@angular/core';
import {UserGrant} from '../../model/user-grant.model';
import {SessionKey} from './session-key.enum';
import {User} from '../../model/user.model';
import {CurrentLeaseModel} from '../../model/currentLease.model';
import {HttpClient} from "@angular/common/http";
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, EMPTY, from, lastValueFrom, mergeMap, Observable, throwError, toArray} from "rxjs";
import {CurrentDistrictModel} from "../../model/district.model";
import {CurrentLandlordModel} from "../../model/landlord.model";
import {LandlordPropertyModel} from '../../model/landlordProperty.model';
import {MatSelectOption} from '../../interfaces/mat-select-component-option.interface';
import {LandlordsService} from '../landlords.service';
import {UserFeaturesResponseInterface} from '../../interfaces/user-features-response.interface';
import {AuthTokenInterface} from '../../interfaces/auth-token.interface';
import {JwtHelperService} from '../../../shared/services/jwt-helper.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {LocationOffering} from '../../model/side-nav.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentContextService {

  private currentUserInternal: User | null = null;
  private currentLeaseInternal: CurrentLeaseModel | null = null;
  private userGrantsInternal: UserGrant[] | null = null;
  private currentLease: CurrentLeaseModel | null = null;

  public selectedDistrict = new BehaviorSubject<CurrentDistrictModel | null>(null);
  public selectedLandlord = new BehaviorSubject<CurrentLandlordModel | null>(null);

  public userDistrictsList = new BehaviorSubject<CurrentDistrictModel[] | null>(null);

  constructor(private http: HttpClient,
              private landLordService: LandlordsService,
              private jwtHelper: JwtHelperService,
              private utilsService: UtilsService) {
  }

  //todo: remove all new keys made
  public reset(resetSession?: boolean): void {
    this.currentUserInternal = null;
    this.userGrantsInternal = [];
    this.currentLease = null;

    if (resetSession) {
      sessionStorage.removeItem(SessionKey.CURRENT_USER_KEY);
      sessionStorage.removeItem(SessionKey.USER_GRANTS_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_LEASE_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_DISTRICT);
      sessionStorage.removeItem(SessionKey.CURRENT_LANDLORD);
      sessionStorage.removeItem(SessionKey.CURRENT_OFFERING_ID);
      sessionStorage.removeItem(SessionKey.CURRENT_LANDLORD_CODE);
    }
  }

  public setCurrentUserInContext(user: User): void {
    sessionStorage.setItem(SessionKey.CURRENT_USER_KEY, JSON.stringify(user));
    this.currentUserInternal = user;
  }

  public setCurrentLeaseInContext(lease: CurrentLeaseModel): void {
    sessionStorage.setItem(SessionKey.CURRENT_LEASE_KEY, JSON.stringify(lease));
    this.currentLeaseInternal = lease;
  }

  public setCurrentDistrictInContext(district: CurrentDistrictModel) {
    sessionStorage.setItem(SessionKey.CURRENT_DISTRICT, JSON.stringify(district));
    this.selectedDistrict.next(district);
  }

  public setCurrentLandlordInContext(landlord: CurrentLandlordModel) {
    sessionStorage.setItem(SessionKey.CURRENT_LANDLORD, JSON.stringify(landlord));
    this.selectedLandlord.next(landlord);
  }

  public resetCurrentLeaseInContext(): void {
    // sessionStorage.setItem(SessionKey.CURRENT_LEASE_KEY, null);
  }

  public canRegisterRetailer(): boolean {
    if (this.userGrants) {
      const retailerUserGrants = this.userGrants.filter(f => 'retailer' === f.principleType);
      return !retailerUserGrants || retailerUserGrants.length === 0;
    }
    return false;
  }

  public doesUserHaveAccess(codes: string[]): boolean {
    if (this.selectedDistrict.value) {
      let hasAccess = false;
      if (this.userGrants) {
        const features = this.userGrants
          .filter(f => f.districtUuid === this.selectedDistrict.value?.uuid)
          .map(m => m.featureCode);
        if (features) {
          codes.forEach(ea => {
            if (features.includes(ea)) {
              hasAccess = true;
              return;
            }
          });
        }
        return hasAccess;
      }
    }
    return false;
  }

  public checkUserFeaturesWithoutOffering(codes: string[]): boolean {
    let hasAccess = false;
    if (this.getCurrentUserGrants) {
      const features: string[] = [];
      this.getCurrentUserGrants().userGrantedOfferings
        .forEach(off => {
          off.userGrantedModules.forEach(module => module.userGrantedRoles.forEach(fea => {
            const featureCodes = fea.userGrantedFeatures.map(m => m.featureCode);
            features.push(...featureCodes)
          }));
        })
      if (features) {
        codes.forEach(ea => {
          if (features.includes(ea)) {
            hasAccess = true;
            return;
          }
        });
      }
      return hasAccess;
    }
    return hasAccess;
  }

  public checkUserFeatures(codes: string[], offeringUuid: string): boolean {
    if (offeringUuid) {
      let hasAccess = false;
      if (this.getCurrentUserGrants) {
        const features: string[] = [];
        this.getCurrentUserGrants().userGrantedOfferings
          .filter(f => f.offeringUuid === offeringUuid)
          .forEach(off => {
            off.userGrantedModules.forEach(module => module.userGrantedRoles.forEach(fea => {
              const featureCodes = fea.userGrantedFeatures.map(m => m.featureCode);
              features.push(...featureCodes)
            }));
          })
        if (features) {
          codes.forEach(ea => {
            if (features.includes(ea)) {
              hasAccess = true;
              return;
            }
          });
        }
        return hasAccess;
      }
    }
    return false;
  }

  private validateSession(): boolean {
    const token = sessionStorage.getItem(SessionKey.TOKEN_KEY);
    const expiration: string | null = sessionStorage.getItem(SessionKey.TOKEN_EXPIRY_KEY) !== null ? sessionStorage.getItem(SessionKey.TOKEN_EXPIRY_KEY) : '';

    if (!token && !expiration) {
      return true;
    }

    const expirationDate = new Date(expiration!);
    const valid = token !== null && (expirationDate && expirationDate > new Date());
    if (!valid) {
      this.reset(true);
    }
    return valid;
  }

  get currentUser(): User | null {
    if (!this.currentUserInternal) {
      this.currentUserInternal = JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_USER_KEY)!);
    }

    this.validateSession();
    return this.currentUserInternal;
  }

  get userGrants(): UserGrant[] | null {
    if (!this.userGrantsInternal || this.userGrantsInternal.length === 0) {
      this.userGrantsInternal = JSON.parse(sessionStorage.getItem(SessionKey.USER_GRANTS_KEY)!);
    }

    this.validateSession();
    return this.userGrantsInternal;
  }

  public resetUserGrants() {
    this.userGrantsInternal = null;
    sessionStorage.removeItem(SessionKey.USER_GRANTS_KEY);
    this.lookupUserGrants();
  }

  private lookupUserGrants() {
    this.http.get(environment.apiSecurityHost + '/api/user/features').subscribe(res => {
      sessionStorage.setItem(SessionKey.USER_GRANTS_KEY, JSON.stringify(res));
    }, err => {
      throwError(err);
    });
  }

  public getCurrentLandlord(): CurrentLandlordModel | null {
    if (!this.selectedLandlord.value) {
      this.selectedLandlord.next(JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_LANDLORD)!));
    }
    return this.selectedLandlord.value;
  }

  public landlord(): Observable<CurrentLandlordModel | null> {
    return this.selectedLandlord.asObservable();
  }

  public lookupUserDistricts(): Observable<CurrentDistrictModel[]> | null {
    if (this.userGrants) {
      const uuids = [...new Set(this.userGrants.map(m => m.districtUuid))];
      const params = {filter: 'ACTIVE_AND_UUID_IN', district: uuids};
      return this.http.get<CurrentDistrictModel[]>(environment.apiDistrictsHost + '/api/districts', {params: params});
    }
    return null;
  }


  //return an empty observable if this is not the better solution throw an error
  public lookupUserLandlords() {
    if (this.userDistrictsList.value) {
      const uuids = this.userDistrictsList.value.map(m => m.landlordUuid);
      return this.http.get<CurrentLandlordModel[]>(environment.apiLandlordHost + '/console/landlord', {
        params: {
          filter: 'ACTIVE_AND_UUID_IN',
          landlord: uuids
        }
      });
    }
    return EMPTY;
  }

  public setCurrentWebClient(landlordId: string): Promise<CurrentLandlordModel | null> {

    const isValid = this.utilsService.isValidUuid(landlordId);
    if (landlordId != null) {
      if (isValid) {
        return lastValueFrom(this.landLordService.getLandlord(landlordId))
          .then((response: CurrentLandlordModel) => {
            sessionStorage.setItem(SessionKey.CURRENT_WEB_CLIENT, JSON.stringify(response));
            sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_ID, response.uuid);
            sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_CODE, response.code);
            return Promise.resolve(response);
          })
          .catch((reason: string) => {
            console.log("error retrieving landlord by uuid", reason);
            return Promise.resolve(null);
          });
      } else {
        return lastValueFrom(this.landLordService.getLandlordWithCode(landlordId))
          .then((response: CurrentLandlordModel) => {
            sessionStorage.setItem(SessionKey.CURRENT_WEB_CLIENT, JSON.stringify(response));
            sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_ID, response.uuid);
            sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_CODE, response.code);
            return Promise.resolve(response);
          })
          .catch((reason: string) => {
            console.log("error retrieving landlord by code", reason);
            return Promise.resolve(null);
          });
      }
    }
    return Promise.resolve(null);
  }

  public lookupLandlordDistricts(): Observable<CurrentDistrictModel[]> | null {
    if (this.selectedLandlord.value && this.userDistrictsList.value) {
      const districts = [...new Set(this.userDistrictsList.value.filter(f => f.landlordUuid === this.selectedLandlord.value?.uuid))];
      const uuids = districts.map(m => m.uuid);
      const params = {
        filter: 'ACTIVE_AND_UUID_IN',
        district: uuids,
        landlord: this.selectedLandlord.value.uuid
      }
      return this.http.get<CurrentDistrictModel[]>(environment.apiDistrictsHost + '/api/districts', {params: params});
    }
    return null;
  }

  get districtsList() {
    return this.userDistrictsList.asObservable();
  }

  getCurrentWebClient(): Promise<CurrentLandlordModel | null> {
    if (sessionStorage.getItem(SessionKey.CURRENT_WEB_CLIENT) === null || sessionStorage.getItem(SessionKey.CURRENT_WEB_CLIENT) === undefined) {
      return this.setCurrentWebClient(sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_ID)!);
    }
    return Promise.resolve(JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_WEB_CLIENT)!) as CurrentLandlordModel);
  }

  getCurrentLandlordCode(): string | null {
    return sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_CODE);
  }

  public getUserAccessibleLandlordsFromToken(): Promise<MatSelectOption[]> {

    let token: AuthTokenInterface = this.jwtHelper.decodeToken(sessionStorage.getItem(SessionKey.TOKEN_KEY)!) as AuthTokenInterface;
    return lastValueFrom(from(token['landlord-ids'])
      .pipe(mergeMap(landlordId => this.landLordService.getLandlord(landlordId)), toArray()))
      .then((allResponses) => {
        return allResponses.map(
          (landlord: CurrentLandlordModel) => {
            let response: MatSelectOption = {name: landlord.companyName, value: landlord.uuid}
            return response;
          });
      });
  }

  setCurrentLocation(location: LandlordPropertyModel) {
    sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_PROPERTY, JSON.stringify(location));
  }

  getCurrentLocation(): LandlordPropertyModel | null {
    if (sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_PROPERTY) != null) {
      return JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_PROPERTY)!) as LandlordPropertyModel;
    }
    return null;
  }

  setCurrentUserId(userId: number) {
    sessionStorage.setItem(SessionKey.CURRENT_USER_ID, userId.toString());
  }

  getCurrentUserId(): number | null {
    return parseInt(sessionStorage.getItem(SessionKey.CURRENT_USER_ID)!);
  }

  getCurrentLandlordId(): string | null {
    return sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_ID);
  }

  setCurrentLandlordId(clientId: string) {
    sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_ID, clientId);
  }

  setCurrentUserKey(user: Object) {
    sessionStorage.setItem(SessionKey.CURRENT_USER_KEY, JSON.stringify(user));
  }

  setCurrentUserGrants(userGrantsResponse: UserFeaturesResponseInterface) {
    sessionStorage.setItem(SessionKey.USER_GRANTS_KEY, JSON.stringify(userGrantsResponse));
  }

  getCurrentUserGrants(): UserFeaturesResponseInterface {
    return JSON.parse(sessionStorage.getItem(SessionKey.USER_GRANTS_KEY)!) as UserFeaturesResponseInterface;
  }

  getCurrentUser(): User | null {
    return this.currentUserInternal;
  }

  setCurrentOfferingId(offeringId: string) {
    sessionStorage.setItem(SessionKey.CURRENT_OFFERING_ID, offeringId);
  }

  getCurrentOfferingId(): string | null {
    if (sessionStorage.getItem(SessionKey.CURRENT_OFFERING_ID) != null) {
      return sessionStorage.getItem(SessionKey.CURRENT_OFFERING_ID)!;
    }
    return null;
  }

  updateLocationOfferings(res: LocationOffering): void {
    let currentLocation: LandlordPropertyModel | null = this.getCurrentLocation();
    if (currentLocation && currentLocation.locationOfferings) {
      let index: number = currentLocation.locationOfferings!.findIndex((offering) => offering.uuid == res.uuid);
      if (index >= 0) {
        currentLocation.locationOfferings![index] = res;
      } else {
        currentLocation.locationOfferings!.push(res);
      }
      this.setCurrentLocation(currentLocation);
    }
  }

  refreshCurrentLocation(): Promise<LandlordPropertyModel> {
    return lastValueFrom(this.landLordService.getLandlordProperty(this.getCurrentLandlord()!.uuid, this.getCurrentLocation()!.uuid))
      .then((res: LandlordPropertyModel) => {
        this.setCurrentLocation(res);
        return Promise.resolve(res);
      });
  }

  setAllowedLocationsForLandlord(allowedLocations: string[]): void {
    sessionStorage.setItem(SessionKey.ALLOWED_LANDLORD_LOCATIONS, JSON.stringify(allowedLocations));
  }
}
