<mat-sidenav-container>
  <mat-sidenav #sidenav class="main-sidenav" mode="side"
               [opened]="isPropertySelected"
               [fixedInViewport]="true"
               [fixedTopGap]="0"
               [fixedBottomGap]="0">
    <mat-toolbar class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
      <img class="owner-logo btn-cursor" [src]="landlordLogo"
           alt="owner-logo"
           (click)="toggleSidenav()">
      <button mat-icon-button class="drawer-button" (click)="toggleSidenav()">
        <mat-icon svgIcon="back-arrow-button"></mat-icon>
      </button>
    </mat-toolbar>

    <div fxLayout="column" fxLayoutAlign="start center">
      <mat-form-field class="select-round" appearance="outline" fxFlexOffset="20px">
        <mat-select (selectionChange)="pages.length > 0 ? changeSelectedPropertyFromNavConfirm($event) :
                    changeSelectedPropertyFromNav($event)" [value]="selectedProperty?.uuid"
                    class="select-round-value"
                    panelClass="select-round-panel" [hideSingleSelectionIndicator]="false">
          <ng-container *ngFor="let property of properties">
            <mat-option class="select-round-option" [value]="property.uuid">{{property.locationName}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <div class="sidenav-content full-width" fxLayout="column" fxLayoutAlign="start center" fxFlexOffset="30px">
        <div class="dashboard-container pb-2 full-width" fxLayout="column" fxLayoutAlign="center center">
          <button mat-flat-button
                  (click)="viewDashboards()"
                  [ngClass]="dashboardSection.selected ? 'sidenav-title-active' : 'sidenav-title'">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon class="material-icons-outlined">{{dashboardSection.icon}}</mat-icon>
                <div class="sidenav-heading">{{dashboardSection.label}}</div>
              </div>
              <mat-icon style="height: 18px; font-size: 18px">{{dashboardSection.selected ? 'remove' : 'add'}}</mat-icon>
            </div>
          </button>
        </div>

        <div *ngIf="isInternalUser" class="dashboard-container pb-2 full-width" fxLayout="column"
             fxLayoutAlign="center center">
          <button (click)="viewInternalFeatures()"
                  [ngClass]="internalAdminSection.selected ? 'sidenav-title-active' : 'sidenav-title'"
                  mat-flat-button>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon class="material-icons-outlined">{{ internalAdminSection.icon }}</mat-icon>
                <div class="sidenav-heading">{{ internalAdminSection.label }}</div>
              </div>
              <mat-icon style="height: 18px; font-size: 18px">{{ internalAdminSection.selected ? 'remove' : 'add' }}
              </mat-icon>
            </div>
          </button>
        </div>

        <mat-accordion [multi]="false" class="full-width">
          <mat-expansion-panel class="sidenav-section"
                               *ngFor="let offering of pageOfferings; let isFirst = first"
                               [expanded]="isFirst">
            <mat-expansion-panel-header class="subheading-default">{{offering.label}}</mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="center center">

              <div *ngFor="let section of getPageSections(offering.uuid)">
                <button mat-flat-button *ngIf="getPageSectionItems(section.uuid).length > 0"
                        (click)="togglePageSection(section)"
                        [ngClass]="section.selected ? 'sidenav-title-active' : 'sidenav-title'">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                      <mat-icon class="material-icons-outlined">{{section.icon}}</mat-icon>
                      <div class="sidenav-heading">{{section.label}}</div>
                    </div>
                    <mat-icon style="height: 18px; font-size: 18px">{{section.selected ? 'remove' : 'add'}}</mat-icon>
                  </div>
                </button>

                <mat-nav-list class="p-0" *ngIf="section.selected">
                  <mat-list-item *ngFor="let item of getPageSectionItems(section.uuid)"
                                 (click)="item.openType === 'tab' ? openPageSectionTab(item) : openPageSectionDialog(item)"
                                 [ngClass]="item.selected ? 'sidenav-item-active' : 'sidenav-item'">
                    <span class="sidenav-item-label">{{item.label}}</span>
                  </mat-list-item>
                </mat-nav-list>
              </div>

              <div *ngIf="pageSectionsIsEmpty(offering.uuid)" class="full-width">
                <mat-nav-list class="p-0">
                 <mat-list-item disabled style="padding-left: 30px;">
                   <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                     <mat-icon class="material-icons-outlined" fontIcon="lock" style="font-size: 15px;"></mat-icon>
                     <div class="sidenav-heading">No permissions granted</div>
                   </div>
                 </mat-list-item>
               </mat-nav-list>
              </div>
            </div>


          </mat-expansion-panel>

        </mat-accordion>

      </div>
    </div>

    <footer class="drawer-footer full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="support-button full-width btn-cursor" (click)="supportPage()" fxLayout="row"
           fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon class="support-icon material-icons-outlined">help_outline</mat-icon>
        <div class="body-1-primary">Support</div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="body-2-default pb-1">© Nter {{getDate | date: 'yyyy'}}. All rights reserved.</div>
        <app-footer-links [inAppTabs]="true" [showLinksOnly]="true"></app-footer-links>
      </div>
    </footer>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="full-width" fxLayout="row" fxLayoutAlign="space-between center"
                 [class.main-toolbar-logged-in]="isPropertySelected">
      <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
        <div class="ms-2" *ngIf="!sidenav.opened">
          <img class="owner-logo btn-cursor" [src]="landlordLogo"
               alt="owner-logo"
               (click)="toggleSidenav()">
        </div>

        <div class="pe-3 ms-3" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" *ngIf="!sidenav.opened && isPropertySelected">
          <button mat-icon-button class="drawer-button mat-elevation-z1" (click)="toggleSidenav()">
            <mat-icon svgIcon="back-arrow-button" style="transform: rotate(180deg)"></mat-icon>
          </button>
        </div>

        <div class="full-width" fxLayoutAlign="start start">
          <app-page-tab-navigator [pages]="pages" [sideNavOpenStatus]="sidenav.opened"></app-page-tab-navigator>
        </div>


        <div class="toolbar-end" fxLayout="row" fxLayoutAlign="center center" *ngIf="isPropertySelected">
          <button mat-icon-button class="toolbar-button" (click)="openGlobalSettings()">
            <mat-icon>settings</mat-icon>
          </button>

        <!--  <button mat-icon-button class="toolbar-button" (click)="openNotificationsDialog()">
            <mat-icon matBadge="{{getNumberOfNotifications()}}" matBadgeColor="primary">notifications</mat-icon>
          </button>-->
        </div>
        <div class="menu-vertical-divider"></div>
        <div>
          <app-user-profile-popover></app-user-profile-popover>
        </div>
      </div>
    </mat-toolbar>

    <mat-sidenav-container>
      <mat-sidenav #notificationsSidenav class="notif-sidenav" mode="side" position="end"
                   [fixedInViewport]="true"
                   [fixedTopGap]="75"
                   [fixedBottomGap]="10">
        <app-notifications fxFlex [showAll]="true"
                           (closeNotifications)="toggleNotifications($event)"></app-notifications>
      </mat-sidenav>

      <mat-sidenav-content>

        <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

        <div>
          <ng-container *ngIf="isPropertySelected; else propertiesLanding">
            <app-page-tab-content [pages]="pages" [selectedProperty]="selectedProperty!"></app-page-tab-content>
          </ng-container>
          <ng-template #propertiesLanding>
            <app-my-properties-landing [properties]="properties" [(isPropertySelected)]="isPropertySelected"
                                       [selectedProperty]="selectedProperty" (selectedPropertyChange)="changeSelectedProperty($event)"></app-my-properties-landing>
          </ng-template>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>


    <footer class="drawer-footer-float" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
            *ngIf="!sidenav.opened && isPropertySelected">
      <div class="support-button-float btn-cursor" (click)="supportPage()" fxLayout="row" fxLayoutAlign="start center"
           fxLayoutGap="10px">
        <mat-icon class="support-icon">help</mat-icon>
        <div class="body-1-primary">Support</div>
      </div>
    </footer>

  </mat-sidenav-content>

</mat-sidenav-container>
