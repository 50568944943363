import {Component} from '@angular/core';
import {BatchType} from "../../run-tenant-payouts/model/run-payouts.model";

@Component({
  selector: 'app-run-client-payouts-view',
  templateUrl: './run-client-payouts-view.component.html',
  styleUrls: ['./run-client-payouts-view.component.scss']
})
export class RunClientPayoutsViewComponent {
  protected readonly BatchType = BatchType;
}
