import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {PageHeaderTab} from '../../../core/components/page-header/page-header-tabs.interface';
import {EmailTemplateModel, OfferingNotificationType, OfferingTemplateModel} from './model/offering-template.model';
import {OfferingNotificationsService} from '../service/offering-notifications.service';
import {lastValueFrom} from 'rxjs';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {LoadingService} from '../../../core/services/loading.service';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent extends BasePageComponent implements OnInit {

  offeringTemplates: OfferingTemplateModel[] = [];
  retailTemplates: OfferingTemplateModel[] = [];
  systemTemplates: OfferingTemplateModel[] = [];
  internalTemplates: OfferingTemplateModel[] = [];
  selectedTemplate: OfferingTemplateModel | null = null;
  isTemplateSelected: boolean = false;

  constructor(injector: Injector,
              private offeringNotificationService: OfferingNotificationsService,
              public loader: LoadingService,
              public utils: UtilsService) {
    super(injector);
  }


  ngOnInit(): void {
    lastValueFrom(this.offeringNotificationService.getAllForOffering(this.offering!.offeringUuid))
      .then((res: OfferingTemplateModel[]) => {
        this.offeringTemplates = res;
        this.splitTemplatesByType();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }


  tabs: PageHeaderTab[] = [
    {
      name: 'Design',
      isActive: true,
      code: "design"
    },
  ];

  activeTab: string = "design"

  changeTabs($event: PageHeaderTab): void {
    this.activeTab = $event.code;
  }

  selectTemplate(selected: OfferingTemplateModel): void {
    this.selectedTemplate = selected;
    this.isTemplateSelected = true;
  }

  getInitialTab(): PageHeaderTab {
    return this.tabs.at(0)!;
  }

  saveAllChanges(): void {
    this.saveTemplateChange(this.selectedTemplate, true)
  }

  saveTemplateChange(template: OfferingTemplateModel | null, isSelected: boolean) :void {
    if (template) {
      lastValueFrom(this.offeringNotificationService.saveNotification(this.offering!.offeringUuid, template))
        .then((res: OfferingTemplateModel) => {
          if (isSelected) {
            this.selectedTemplate = res;
            this.isTemplateSelected = true;
          }
          let index: number = this.offeringTemplates.findIndex((item) => item.baseTemplateUuid == template.baseTemplateUuid);
          if (index >= 0) {
            this.offeringTemplates[index] = res;
          }
          this.splitTemplatesByType();
        })
        .catch((error: string) => {
          console.log(error);
        });
    }
  }

  splitTemplatesByType(){
    this.retailTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.RETAILER);
    this.systemTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.SYSTEM);
    this.internalTemplates = this.offeringTemplates.filter(template => template.type == OfferingNotificationType.LANDLORD);
  }

  changeNotificationEnabled($event: MatSlideToggleChange, template: OfferingTemplateModel): void {
    template.notificationEnabled = $event.checked;
    this.saveTemplateChange(template, false);
  }

  changeSelectedTemplate($event: EmailTemplateModel | null): void {
    let casted = $event as OfferingTemplateModel;
    this.selectedTemplate = casted;
    this.isTemplateSelected = !this.selectedTemplate == null;
  }

  protected readonly Object = Object;
}
