import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {BatchStatus, RetailerBatchSummary} from "../../transactions/transactions.model";
import {DateRange} from "@angular/material/datepicker";
import {TransactionsService} from "../../transactions/transactions.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {tap} from "rxjs";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";

@Component({
  selector: 'app-payout-history-list',
  templateUrl: './payout-history-list.component.html',
  styleUrls: ['./payout-history-list.component.scss']
})
export class PayoutHistoryListComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new GenericDatasource<RetailerBatchSummary>(this.transactionsService);
  displayedColumns = ['paymentBatchUuid', 'retailerCompanyName', 'processedDate', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'processStatus'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 0, TimeFrames.MONTH, 'This month')
  ]

  batchStatuses: string[] = Object.keys(BatchStatus);

  constructor(private transactionsService: TransactionsService, public utils: UtilsService, public dateUtils: DateUtilsService, private paginatorService: PaginatorService) {
    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      payoutStatus: new FormControl([])
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<RetailerBatchSummary>(this.transactionsService);
    this.dataSource.loadData('/payout-history/payout-summary', {size: '10', page: '0', sort: 'processedDate,desc'}, []);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadPayoutHistory()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadPayoutHistory());
  }

  loadPayoutHistory() {
    const filters = [
      {name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value},
      {name: 'startDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'endDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'processedDate,desc'}
    this.dataSource.loadData('/payout-history/payout-summary', page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadPayoutHistory()
  }

}
