import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {DistrictPaymentConfig} from "../../accounts/run-tenant-payouts/model/district-payout-config.model";
import {LeaseSupplierState, OfferingCardReaderConfig, PaymentConfigRequest} from "../../leasing/leasing.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentConfigsService {

  constructor(private context: CurrentContextService, private http: HttpClient) { }

  getDistrictPaymentConfigs(): Observable<DistrictPaymentConfig> {
    return this.http.get<DistrictPaymentConfig>(this.baseUrl + `/config`);
  }

  getDistrictPosConfigs(): Observable<PaymentConfigRequest[]> {
    return this.http.get<PaymentConfigRequest[]>(this.baseUrl + `/sources`);
  }

  checkSuppliers(configUuid: string): Observable<LeaseSupplierState> {
    return this.http.get<LeaseSupplierState>(environment.apiLeaseHost + `/console/manage-lease/rates/${configUuid}/suppliers`);
  }

  saveDistrictPosConfig(request: PaymentConfigRequest): Observable<PaymentConfigRequest> {
    return this.http.post<PaymentConfigRequest>(this.baseUrl + `/sources`, request);
  }

  updatePaymentConfigStatus(paymentConfigUuid: string, status: string): Observable<PaymentConfigRequest> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<PaymentConfigRequest>(this.baseUrl + `/status/${paymentConfigUuid}`, `"${status}"`, options);
  }

  removeDistrictPosConfig(configId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/sources/${configId}`);
  }

  getAssignedOfferingCardReaders(domainPrefix: string): Observable<OfferingCardReaderConfig[]> {
    return this.http.get<OfferingCardReaderConfig[]>(this.baseCardReaderUrl + `/assigned/${domainPrefix}`)
  }

  getAvailableOfferingCardReaders(startDate: string, endDate: string | null): Observable<OfferingCardReaderConfig[]> {
    let requestParams = new HttpParams()
      .set('startDate', startDate)

    if (endDate) requestParams.append('endDate', endDate);
    return this.http.get<OfferingCardReaderConfig[]>(this.baseCardReaderUrl + `/available`, {params: requestParams})
  }

  assignOfferingCardReader(request: OfferingCardReaderConfig): Observable<OfferingCardReaderConfig> {
    return this.http.post<OfferingCardReaderConfig>(this.baseCardReaderUrl + ``, request)
  }

  removeOfferingCardReader(cardReaderId: any): Observable<void> {
    return this.http.delete<void>(this.baseCardReaderUrl + `/${cardReaderId}`)
  }


  get baseUrl() {
    const offeringUuid = this.context.getCurrentOfferingId();
    return environment.apiDistrictsHost + `/console/districts/${offeringUuid}/payments`;
  }

  get baseCardReaderUrl() {
    const offeringUuid = this.context.getCurrentOfferingId();
    return environment.apiDistrictsHost + `/console/districts/${offeringUuid}/card-reader-config`;
  }
}
