export abstract class Auditable {
  public uuid?: string;
  public id?: number
  public active?: boolean;
  public createDate?: Date | string;
  public createUserId?: number;
  public updateDate?: Date | string;
  public updateUserId?: number;
  public verified?: boolean;

  protected constructor(obj: any) {
    Object.assign(this, obj);
  }
}
