<div class="full-width" fxLayout="column">
  <div fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Daily Transaction Summary</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Daily transaction summary for retailers</span>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">

        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Company Name</mat-label>
          <input matInput type="text" placeholder="Search here" formControlName="companyName">
        </mat-form-field>

        <app-custom-date-filter [customDateRangePresets]="dateFilters" (dateRange)="getDateRange($event)" [initialPreset]="'1'"></app-custom-date-filter>

      </div>
    </form>

    <div fxLayout="row" fxFlexOffset="25px">
      <table mat-table class="custom-table chart-table" [dataSource]="dataSource" fxFlex="60">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Daily Transaction Summary </th>
        </ng-container>

        <ng-container matColumnDef="retailerCompanyName">
          <th mat-header-cell *matHeaderCellDef> Retailer </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayString(element.retailerCompanyName, 20) }} </td>
        </ng-container>

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.transactionDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="saleAmountIncl">
          <th mat-header-cell *matHeaderCellDef> Total Sales </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.saleAmountIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="transactionFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.transactionFeeIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="turnoverRentalFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.turnoverRentalFeeIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
          <th mat-header-cell *matHeaderCellDef> Net Balance </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.netBalancePayableRetailerInclRounded)}} </td>
        </ng-container>

        <ng-container matColumnDef="chart">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell btn-cursor"
              *matCellDef="let element; let i = index" [attr.rowspan]="10" [ngClass]="i === 0 ? 'table-chart-show' : 'table-chart-hide'">
            <div class="subtitle-default mt-3">Daily Transaction Summary</div>
            <div class="full-width">
              <canvas baseChart
                      [height]="370"
                      [data]="chartData"
                      [options]="chartOptions"
                      [type]="'line'"></canvas>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSourceLength"
                   [pageSize]="10"></mat-paginator>
  </div>
</div>
