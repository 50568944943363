import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {NotifyPosRequest, PosRequest} from "./request-pos.model";

@Injectable({
  providedIn: 'root'
})
export class RequestPosService {

  constructor(private http: HttpClient) {
  }

  requestNewPos(retailerId: number, request: PosRequest, locationId: string, offeringId: string) {
    const options = {
      headers: new HttpHeaders({
        'x-location-id': locationId,
        'x-offering-id': offeringId
      }),
    };
    return this.http.post<void>(this.getBaseUrl(retailerId) + '/new', request, options);
  }

  requestCancelPos(retailerId: number, request: PosRequest, locationId: string, offeringId: string) {
    const options = {
      headers: new HttpHeaders({
        'x-location-id': locationId,
        'x-offering-id': offeringId
      }),
    };
    return this.http.post<void>(this.getBaseUrl(retailerId) + '/cancel', request);
  }

  notifyPosActivation(retailerId: number, request: NotifyPosRequest) {
    return this.http.post<void>(this.getBaseUrl(retailerId) + '/activation', request);
  }

  notifyPosDeactivation(retailerId: number, request: NotifyPosRequest) {
    return this.http.post<void>(this.getBaseUrl(retailerId) + '/deactivation', request);
  }

  getBaseUrl(retailerId: number) {
    return environment.apiRetailersHost + `/console/manage-retailer/${retailerId}/pos-request`;
  }
}
