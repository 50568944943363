<div class="remove-panel p-2 pt-3" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-icon class="warn-icon">error</mat-icon>
    <span class="footer-default">{{message}}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
    <button class="button-action" mat-flat-button (click)="removeItems(true)" [disabled]="buttonDisabled1 || (loading$ | async)">
      <div class="button-text">{{buttonLabel1}}</div>
    </button>
    <button class="button-action" mat-flat-button (click)="removeItems(false)" [disabled]="buttonDisabled2 || (loading$ | async)">
      <div class="button-text">{{buttonLabel2}}</div>
    </button>
  </div>
</div>
