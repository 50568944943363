import {Component, Input, OnInit} from '@angular/core';
import {CurrentContextService} from "../../../../../core/services/security/current-context.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {RetailerBillingDetails} from "../../retailers.model";
import {RetailerViewService} from "../retailer-view.service";

@Component({
  selector: 'app-retailer-billing-details',
  templateUrl: './retailer-billing-details.component.html',
  styleUrls: ['./retailer-billing-details.component.scss']
})
export class RetailerBillingDetailsComponent implements OnInit {
  @Input() id!: string;
  billingDetails!: RetailerBillingDetails;

  constructor(public context: CurrentContextService, private retailerService: RetailerViewService, public utils: UtilsService) {
  }

  ngOnInit() {
    this.loadBillingDetails();
  }

  loadBillingDetails() {
    this.retailerService.getRetailerBillingDetails(this.id).subscribe({
      next: value => {
        this.billingDetails = value;
        if (!value)
          this.billingDetails = new RetailerBillingDetails();
        },
      error: err => { console.log(err); }
    })
  }
}
