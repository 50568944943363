import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSelectOption} from '../../../../interfaces/mat-select-component-option.interface';
import {UserProfileDialogInterface} from './user-profile-dialog.interface';
import {AuthenticationService} from '../../../../services/security/authentication.service';
import {NotificationsService} from '../../../../services/notifications.service';
import {MatSelectChange} from '@angular/material/select';
import {TabManagementService} from '../../../../services/tab-management.service';
import {CurrentContextService} from '../../../../services/security/current-context.service';
import {CurrentLandlordModel} from '../../../../model/landlord.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UpdatePasswordService} from '../../../../../features/password-reset/service/update-password.service';
import {RequestPasswordUpdateOTP} from '../../../../../features/password-reset/model/request-password-update-otp.model';
import {lastValueFrom} from 'rxjs';
import {PasswordChangeOtpDialogComponent} from '../password-change-otp-dialog/password-change-otp-dialog.component';
import {UserService} from '../../../../services/security/user.service';
import {UploadUserProfileImageComponent} from '../upload-user-profile-image/upload-user-profile-image.component';
import {User, UserUpdateRequest} from '../../../../model/user.model';
import {UtilsService} from '../../../../../shared/services/utils.service';


@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent {

  selectedProperty: MatSelectOption = {name: '', value: ''};
  passwordResetForm: FormGroup;
  isChangingPassword: boolean = false;
  hideFirstPassword = true;
  hideConfirmPassword = true;
  isSubmitting: boolean = false;

  defaultLogo: string = '../assets/icons/default-nter-logo.svg';
  userProfileFormGroup: FormGroup;
  editingUsername: boolean = false;

  constructor(public dialogRef: MatDialogRef<UserProfileDialogComponent>,
              public matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: UserProfileDialogInterface,
              private authService: AuthenticationService,
              private notificationService: NotificationsService,
              private tabManagementService: TabManagementService,
              private contextService: CurrentContextService,
              private updatePasswordService: UpdatePasswordService,
              private userService: UserService,
              public utilsService: UtilsService) {

    contextService.getCurrentWebClient()
      .then((result: CurrentLandlordModel | null) => {
        this.selectedProperty = {
          value: result!.uuid,
          name: result!.companyName
        }
      });

    this.passwordResetForm = new FormGroup({
      email: new FormControl(this.data.user.email, [Validators.required]),
      password: new FormControl(this.data.user.secret, Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });

    this.userProfileFormGroup = new FormGroup<any>({
      userNames: new FormControl(`${data.user.firstname} ${data.user.lastname}`, [Validators.required])
    });

  }

  ngOnInit() {
    //load properties belonging to user from service
  }

  close() {
    this.dialogRef.close(this.data);
  }

  openUserSettings(): void {

  }

  logout() {
    this.authService.logout();
    this.close();
  }

  toggleChanged($event: MatSlideToggleChange) {
    this.notificationService.notificationsPreferenceChanged($event.checked);
  }

  selectedPropertyChanged($event: MatSelectChange) {
    this.selectedProperty = $event.value;
    this.tabManagementService.sendLandlordChangeEvent($event);
    this.close();
  }

  isPropChangeDisabled(): boolean {
    return this.data.accessibleTenants.length === 1;
  }

  toggleChangePassword(): void {
    this.isChangingPassword = !this.isChangingPassword;
  }

  submit(): void {
    if (this.updatePasswordService.passwordInvalid(this.passwordResetForm)) {
      return;
    }
    this.isSubmitting = true;
    const secret: string = this.passwordResetForm.get('password')!.value;
    const confirmSecret: string = this.passwordResetForm.get('confirmPassword')!.value;
    this.updatePasswordService.newPasswordRestContext(secret, confirmSecret, this.data.user.email, this.data.user.id!);
    const otpRequest: RequestPasswordUpdateOTP = new RequestPasswordUpdateOTP({
      email: this.data.user.email
    });
    lastValueFrom(this.updatePasswordService.requestForgotPasswordOTP(otpRequest))
      .then(() => {
        this.openDialog();
      })
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  openDialog(): void {
    this.matDialog.open(PasswordChangeOtpDialogComponent, {
      panelClass: 'dialog-medium-user-popover',
      backdropClass: 'dialog-backdrop',
      position: {right: '230px', top: '0px'},
    });

  }

  openUploadProfilePicture(): void {
    this.matDialog.open(UploadUserProfileImageComponent, {
      panelClass: 'dialog-large',
      height: '590px',
      backdropClass: 'dialog-backdrop-opaque',
      hasBackdrop: true
    }).afterClosed().subscribe(() => {
      this.data.user = this.contextService.currentUser!;
    });
  }

  updateUserProfile(): void {

    this.editingUsername = false;
    let unSplitNames: string = this.userProfileFormGroup.get('userNames')!.value;
    let nameComponents: string[] = unSplitNames.split(' ');
    if (nameComponents.length > 1) {
      let userLastName = nameComponents.at(-1);
      let userFirstName = nameComponents.slice(0, -1).join(' ');

      let request: UserUpdateRequest = {
        firstname: userFirstName,
        lastname: userLastName!,
        id: this.contextService.currentUser!.id!
      }

      lastValueFrom(this.userService.updateUserDetails(request))
        .then((user: User) => {
          this.contextService.setCurrentUserInContext(user);
          this.data.user = user;
        })
        .catch((error: string) => {
          console.log(error);
        });
    } else {
      this.userProfileFormGroup.get('userNames')?.setErrors({'error': 'first_and_last_req'});
    }
  }

  toggleEditUsername(): void {
    this.editingUsername = !this.editingUsername;
  }
}

