<div fxLayout="column">
  <app-page-header [section]="'Global Settings'"
                   [title]="'Manage your client profile'"
                   [description]="'The settings page allows you to see general information and enabled properties and offerings. \n'+
'You can create a contact list to improve communication and setup your users’ roles and permissions.'">
  </app-page-header>

  <div fxLayout="column" class="page-content page-padding full-screen-height-page-default">
    <span class="subtitle-default" fxFlexOffset="20px">Client Details</span>

    <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px">
      <div class="info-card" fxLayout="column">
        <span class="heading-default">General information</span>
        <div fxLayout="row" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-form-field class="custom-text-autofill" appearance="outline">
            <mat-label>Client ID</mat-label>
            <input matInput type="text" [value]="this.loggedInUser?.id!.toString()" disabled>
          </mat-form-field>
          <mat-form-field class="custom-text-autofill" appearance="outline">
            <mat-label>Client Company Name</mat-label>
            <input matInput type="text" [value]="this.currentLandlord?.companyName" disabled/>
          </mat-form-field>
          <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
            <mat-label>Physical Address</mat-label>
            <input matInput type="text" [value]="" disabled/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="section-divider"><mat-divider></mat-divider></div>

    <span class="subtitle-default" fxFlexOffset="30px">Properties and offerings</span>
    <div class="full-width page-content p-0" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px">
      <app-landlord-properties-carousel [properties]="this.properties"
                                        [changeContext]="false"
                                        [showOfferingsOnHover]="true">
      </app-landlord-properties-carousel>
    </div>

  <!--  Commenting out work on the Contact list section-->
  <!--  <div class="flex-row">
      <div>
        <span class="title-default" fxFlexOffset="20px">Contact List</span>
        <mat-button-toggle-group>
          <button mat-button class="button-secondary">Add</button>
          <button mat-button class="button-secondary">edit</button>
          <button mat-button class="button-secondary">remove</button>
        </mat-button-toggle-group>
      </div>
      <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
        <span class="heading-default">Help us talk to the right person</span>
        <form [formGroup]="filterForm" fxFlexOffset="20px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">

            <mat-form-field class="custom-select-field ms-3" appearance="outline" floatLabel="always">
              <mat-label>Property Type</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="propertyType">
                <mat-option class="custom-select-field-option" *ngFor="let type of propertyTypes" [value]="type">{{type}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-select-field ms-3" appearance="outline" floatLabel="always">
              <mat-label>Offering Type</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="offeringType">
                <mat-option class="custom-select-field-option" *ngFor="let type of offeringTypes" [value]="type">{{type}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-select-field ms-3" appearance="outline" floatLabel="always">
              <mat-label>Contact Type</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="contactType">
                <mat-option class="custom-select-field-option" *ngFor="let type of contactTypes" [value]="type">{{type}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </form>

        <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
          <ng-container matColumnDef="table-label">
            <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Retailer Event List </th>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell class="custom-table-cell btn-cursor"
                matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
                *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
          </ng-container>

          <ng-container matColumnDef="eventType">
            <th mat-header-cell *matHeaderCellDef> Event Type </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef> Time </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.userName, 20)}} </td>
          </ng-container>

          <ng-container matColumnDef="triggeredBy">
            <th mat-header-cell *matHeaderCellDef> Triggered By </th>
            <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
          </ng-container>

          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator class="custom-paginator"
                       [length]="dataSource.totalElements"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>-->
  </div>
</div>
