import {HttpClient} from "@angular/common/http";
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {LocationOffering} from "../../../core/model/side-nav.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CustomDateRangeModel} from "../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {DashboardChart, MonthlyStat, StatisticType} from "../dashboards.model";
import {build as buildDashboardService, DashboardsLeasingReportService} from "./dashboards-leasing-report.service";
import {LeasingDashboardChartConfig} from "./leasing-dashboard-chart-config";

@Component({
  selector: 'app-dashboards-leasing-report',
  templateUrl: './dashboards-leasing-report.component.html',
  styleUrls: ['./dashboards-leasing-report.component.scss']
})
export class DashboardsLeasingReportComponent extends BasePageComponent implements OnInit, OnDestroy {
   chartConfig: LeasingDashboardChartConfig;
   dashboardService: DashboardsLeasingReportService;

  // best stats

  bestMonthlyStats: MonthlyStat[] = [];

  // dashboard charts
  rentalIncomeChart: DashboardChart<'line'>;
  rentalIncomeOccupancyChart!: DashboardChart<'bar' | 'line'>;
  tradingDensityOccupancyChart!: DashboardChart<'line'>;
  rentalIncomeTradingDensityChart!: DashboardChart<'bar' | 'line'>;
  effectiveIncomeRentRatioChart!: DashboardChart<'bar' | 'line'>;
  rentalIncomeByCategoryChart!: DashboardChart<'radar'>;
  rentalIncomePerSqmByCategoryChart!: DashboardChart<'radar'>;

  // filtering

  dateRanges: DateRange<Date | null>[] = [
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
  ];

  year2 = new Date();
  year1 = new Date(this.year2.getFullYear() - 1, 1, 1);

  customDateRangePresets: CustomDateRangeModel[] = []
  dbOffering!: LocationOffering;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              injector: Injector,
              chartOptionsService: ChartOptionsService,
              httpClient: HttpClient,
              public dashboardUtils: DashboardUtilsService) {
    super(injector);
    this.dashboardService = buildDashboardService(httpClient);
    this.dbOffering = this.data.offering;
    this.chartConfig = new LeasingDashboardChartConfig(this, chartOptionsService, dashboardUtils);

    this.rentalIncomeChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentalIncome.options,
      chartType: 'line',
      stats: {
        rentalIncome1: new MonthlyStat('Rental Income ' + this.year1.getFullYear(), 'rentalIncome1', 'Total monthly rental amount charged to tenants', StatisticType.CURRENCY),
        rentalIncome2: new MonthlyStat('Rental Income ' + this.year2.getFullYear(), 'rentalIncome2', 'Total monthly rental amount charged to tenants', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.rentalIncomeOccupancyChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentalIncomeOccupancy.options,
      chartType: 'line',
      stats: {
        avgOccupancy: new MonthlyStat('Occupancy', 'avgOccupancy', 'Total percentage of space leased to tenants', StatisticType.PERCENTAGE),
        rentalIncome: new MonthlyStat('Rental Income', 'rentalIncome', 'Total monthly rental amount charged to tenants', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.tradingDensityOccupancyChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.tradingDensityOccupancy.options,
      chartType: 'line',
      stats: {
        avgOccupancy: new MonthlyStat('Occupancy', 'avgOccupancy', 'Total percentage of space leased to tenants', StatisticType.PERCENTAGE),
        avgTradingDensity: new MonthlyStat('Trading Density', 'avgTradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
      },
      loading: false
    };

    this.rentalIncomeTradingDensityChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentalIncomeTradingDensity.options,
      chartType: 'line',
      stats: {
        avgTradingDensity: new MonthlyStat('Trading Density', 'avgTradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
        rentalIncome: new MonthlyStat('Rental Income', 'rentalIncome', 'Total monthly rental amount charged to tenants', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.effectiveIncomeRentRatioChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.effectiveIncomeRentRatio.options,
      chartType: 'bar',
      stats: {
        avgEffectiveIncome: new MonthlyStat('Effective Income', 'avgEffectiveIncome', 'Rent charged per square meter for the leased spaced', StatisticType.CURRENCY_PER_SQM),
        avgRentRatio: new MonthlyStat('Rent Ratio', 'avgRentRatio', 'Sales to Rental fee ratio. \nThe Rent ratio target is 30%', StatisticType.PERCENTAGE),
      },
      loading: false
    };

    this.rentalIncomeByCategoryChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentalIncomeByCategory.options,
      chartType: 'radar',
      stats: {
        rentalIncome: new MonthlyStat('Rental Income', 'rentalIncome', 'Total monthly rental amount charged to tenants', StatisticType.CURRENCY)
      },
      loading: false
    };


    this.rentalIncomePerSqmByCategoryChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.rentalIncomePerSqmByCategory.options,
      chartType: 'radar',
      stats: {
        rentalIncomePerSqm: new MonthlyStat('Rental Income per m²', 'rentalIncomePerSqm', 'Average monthly rental amount charged to tenants per square meter', StatisticType.CURRENCY_PER_SQM)
      },
      loading: false
    };
  }

  ngOnInit() {
    this.loadBestMonthlyStats();
    this.loadRentalIncomeChart();
    this.setCustomDateRange();
  }

  ngOnDestroy() {
    this.dashboardService.flush();
  }

  setCustomDateRange() {
    this.customDateRangePresets = this.dashboardUtils.customDateRangeChartPreset(this.dbOffering.offeringStartDate!);
  }

  loadBestMonthlyStats() {
    const start = this.dbOffering.offeringStartDate!;
    const end = new Date();
    const next = this.chartConfig.bestMonthlyStats.next;
    const error = this.chartConfig.bestMonthlyStats.error;
    this.dashboardService.refreshBestMonthlyStats(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadRentalIncomeChart() {
    this.rentalIncomeChart.loading = true;
    const start = this.year1;
    const end = this.year2;
    const next = this.chartConfig.rentalIncome.next;
    const error = this.chartConfig.rentalIncome.error;
    this.dashboardService.refreshRentalIncomeChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadRentalIncomeOccupancyChart() {
    this.rentalIncomeOccupancyChart.loading = true;
    const start = this.dateRanges[0].start ? this.dateRanges[0].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[0].end ? this.dateRanges[0].end : new Date();
    const next = this.chartConfig.rentalIncomeOccupancy.next;
    const error = this.chartConfig.rentalIncomeOccupancy.error;
    this.dashboardService.refreshRentalIncomeOccupancyChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadTradingDensityOccupancyChart() {
    this.tradingDensityOccupancyChart.loading = true;
    const start = this.dateRanges[1].start ? this.dateRanges[1].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[1].end ? this.dateRanges[1].end : new Date();
    const next = this.chartConfig.tradingDensityOccupancy.next;
    const error = this.chartConfig.tradingDensityOccupancy.error;
    this.dashboardService.refreshTradingDensityOccupancyChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadRentalIncomeTradingDensityChart() {
    this.rentalIncomeTradingDensityChart.loading = true;
    const start = this.dateRanges[2].start ? this.dateRanges[2].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[2].end ? this.dateRanges[2].end : new Date();
    const next = this.chartConfig.rentalIncomeTradingDensity.next;
    const error = this.chartConfig.rentalIncomeTradingDensity.error;
    this.dashboardService.refreshRentalIncomeTradingDensityChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadEffectiveIncomeRentRatioChart() {
    this.effectiveIncomeRentRatioChart.loading = true;
    const start = this.dateRanges[3].start ? this.dateRanges[3].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[3].end ? this.dateRanges[3].end : new Date();
    const next = this.chartConfig.effectiveIncomeRentRatio.next;
    const error = this.chartConfig.effectiveIncomeRentRatio.error;
    this.dashboardService.refreshEffectiveIncomeRentRatioChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadRentalIncomeByCategoryChart() {
    this.rentalIncomeByCategoryChart.loading = true;
    const start = this.dateRanges[4].start ? this.dateRanges[4].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[4].end ? this.dateRanges[4].end : new Date();
    const next = this.chartConfig.rentalIncomeByCategory.next;
    const error = this.chartConfig.rentalIncomeByCategory.error;
    this.dashboardService.refreshRentalIncomeByCategoryChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadRentalIncomePerSqmByCategoryChart() {
    this.rentalIncomePerSqmByCategoryChart.loading = true;
    const start = this.dateRanges[4].start ? this.dateRanges[4].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[4].end ? this.dateRanges[4].end : new Date();
    const next = this.chartConfig.rentalIncomePerSqmByCategory.next;
    const error = this.chartConfig.rentalIncomePerSqmByCategory.error;
    this.dashboardService.refreshRentalIncomePerSqmByCategoryChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  getDateRange(event: CustomDateRangeChangeEventModel, index: number) {
    this.dateRanges[index] = event.dateRange;

    if (event.dateRange.start && event.dateRange.end) {
      switch (index) {
        case 0:
          this.loadRentalIncomeOccupancyChart();
          break;
        case 1:
          this.loadTradingDensityOccupancyChart();
          break;
        case 2:
          this.loadRentalIncomeTradingDensityChart();
          break;
        case 3:
          this.loadEffectiveIncomeRentRatioChart();
          break;
        case 4:
          this.loadRentalIncomeByCategoryChart();
          this.loadRentalIncomePerSqmByCategoryChart();
          break;
      }
    }
  }

  datePickerUpdated() {
    this.rentalIncomeChart.stats['rentalIncome1'].statName = 'Rental Income ' + this.year1.getFullYear();
    this.rentalIncomeChart.stats['rentalIncome2'].statName = 'Rental Income ' + this.year2.getFullYear();
    this.loadRentalIncomeChart();
  }
}
