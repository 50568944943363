<div fxLayout="column" fxLayoutAlign="center">
  <div class="dialog-header my-2" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="width: 24px"></span>
    <div class="mat-headline popup-header">{{ header }}</div>
    <mat-icon class="btn-cursor mb-3" (click)="close()">close</mat-icon>
  </div>
  <div class="separator-band"></div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div class="full-width"  fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="30" fxFlex.gt-sm="35"  fxLayout="row" fxLayoutAlign="center center">
        <img class="popup-icon" [src]="imageSrc">
      </div>
      <div fxFlex="70" fxFlex.gt-sm="65" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <p class="error-popup-description">{{ description }}</p>
        <p class="error-popup-boldDescription" *ngIf="boldDescription">
          {{ boldDescription }}
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <button class="mat-button-light" *ngIf="cancelButton" style="width: 300px !important;" (click)="close()">{{ cancelButton }}</button>
    <button class="mat-button-dark" *ngIf="submitButton" style="width: 300px !important;" (click)="navTo()">{{ submitButton }}</button>
  </div>
</div>
