import {Injectable} from "@angular/core";
import {
  Chart,
  ChartDataset,
  ChartMeta,
  ChartOptions,
  ChartTypeRegistry,
  LegendItem,
  TooltipItem,
  TooltipLabelStyle
} from "chart.js";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {chartColors} from "./chart-colors.model";

@Injectable({
  providedIn: 'root'
})
export class ChartOptionsService {

  constructor(private dateUtils: DateUtilsService) {
  }

  displaySecondaryYAxis(chartOptions: ChartOptions<"bar" | "line" | "scatter" | "bubble" | "pie" | "doughnut" | "polarArea" | "radar">) {
    chartOptions!.scales!["y1"]!.display = true;
  }

  setCurrencyAxis(chartOptions: ChartOptions<"bar" | "line" | "scatter" | "bubble" | "pie" | "doughnut" | "polarArea" | "radar">, axis: string): void {
    chartOptions!.scales![axis]!.ticks!.callback = (label: any, index: any, labels: any) => this.displayCompactRandAmount(label);
  }

  setCurrencyPerSqmAxis(chartOptions: ChartOptions<"bar" | "line" | "scatter" | "bubble" | "pie" | "doughnut" | "polarArea" | "radar">, axis: string): void {
    chartOptions!.scales![axis]!.ticks!.callback = (label: any, index: any, labels: any) => this.displayRandAmountPerSqm(label);
  }

  setPercentageAxis(chartOptions: ChartOptions, axis: string, maxValue: number): void {
    chartOptions!.scales![axis]!.ticks!.callback = (label: any, index: any, labels: any) => this.displayPercentage(label);
    chartOptions!.scales![axis]!.suggestedMax = maxValue;
    //@ts-ignore
    chartOptions!.scales![axis]!.grace = 0;
  }

  setStackedAxis(chartOptions: ChartOptions<"bar">, axis: string): void {
    chartOptions!.scales![axis]!.stacked = true;
    chartOptions!.scales!['x']!.stacked = true;
  }

  setAutoSkipValue(chartOptions: ChartOptions, axis: string, value: boolean) {
    //@ts-ignore
    chartOptions!.scales![axis]!.ticks!.autoSkip = value;
  }

  setTooltips(chartOptions: ChartOptions, currencyPerSqmLabels: string[], randAmountLabels: string[], percentageLabels: string[]): void {
    chartOptions!.plugins!.tooltip!.callbacks = {
      title: (tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]): string | string[] | void => {
        if (typeof tooltipItems[0].label == "string") return tooltipItems[0].label.split(',').join(' ');
        //@ts-ignore
        return tooltipItems[0].label.join(' ');
      },
      label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>): string | string[] | void => {
        const label = tooltipItem.dataset.label;
        if (currencyPerSqmLabels.some(substring => tooltipItem.dataset.label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmountPerSqm(tooltipItem.raw);
        }
        if (randAmountLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmount(tooltipItem.raw);
        }
        if (percentageLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayPercentage(tooltipItem.raw);
        }
        // @ts-ignore
        return label + ': ' + tooltipItem.raw;
      },
      labelColor(tooltipItem: TooltipItem<keyof ChartTypeRegistry>): TooltipLabelStyle | void {
        const datasetIndex = tooltipItem.datasetIndex;
        const dataset: ChartDataset = tooltipItem.chart.getDatasetMeta(datasetIndex).controller.getDataset();
        let borderColor = tooltipItem!.chart!.legend!.legendItems![datasetIndex]!.fillStyle!;
        let backgroundColor = tooltipItem!.chart!.legend!.legendItems![datasetIndex]!.fillStyle!;
        if (dataset && dataset!.borderColor) {
          borderColor = dataset.borderColor!.toString();
          backgroundColor = dataset.borderColor!.toString();
        }

        return {
          borderColor: borderColor,
          backgroundColor: backgroundColor
        }
      }
    }
  }

  setDateTooltips(chartOptions: ChartOptions, currencyPerSqmLabels: string[], randAmountLabels: string[], percentageLabels: string[]) {
    chartOptions!.plugins!.tooltip!.callbacks = {
      title: (tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]): string | string[] | void => {
        return tooltipItems[0].label.split(',').join(' ');
      },
      label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>): string | string[] | void => {
        const label = tooltipItem.dataset.label!;
        if (currencyPerSqmLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmountPerSqm(tooltipItem.raw);
        }
        if (randAmountLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmount(tooltipItem.raw);
        }
        if (percentageLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayPercentage(tooltipItem.raw);
        }
        // @ts-ignore
        return label + ': ' + tooltipItem.raw;
      }
    }
  }

  setTimeTooltips(chartOptions: ChartOptions, currencyPerSqmLabels: string[], randAmountLabels: string[], percentageLabels: string[]) {
    chartOptions!.plugins!.tooltip!.callbacks = {
      title: (tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]): string | string[] | void => {
        return tooltipItems[0].label + 'h00';
      },
      label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>): string | string[] | void => {
        const label = tooltipItem.dataset.label!;
        if (currencyPerSqmLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmountPerSqm(tooltipItem.raw);
        }
        if (randAmountLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayRandAmount(tooltipItem.raw);
        }
        if (percentageLabels.some(substring => label!.includes(substring))) {
          // @ts-ignore
          return label + ': ' + this.displayPercentage(tooltipItem.raw);
        }
        // @ts-ignore
        return label + ': ' + tooltipItem.raw;
      }
    }
  }

  hideLegend(chartOptions: ChartOptions<"bar" | "line" | "scatter" | "bubble" | "pie" | "doughnut" | "polarArea" | "radar">) {
    chartOptions!.plugins!.legend!.display = false;
  }

  setLegendLabels(chartOptions: ChartOptions) {
    chartOptions!.plugins!.legend!.labels = {
      usePointStyle: true,
      pointStyle: "circle",
      boxWidth: 15,
      boxHeight: 15,
      generateLabels: (chart: Chart) => {
        const datasets = chart.data.datasets;
        const {
          labels: {
            usePointStyle,
            pointStyle,
            textAlign,
            color
          }
        } = chart!.legend!.options;

        const legendItems: LegendItem[] = chart!.getSortedVisibleDatasetMetas().map((meta: ChartMeta, i) => {
          const style = meta.controller!.getStyle(i, true);
          const dataset: ChartDataset = meta.controller!.getDataset();
          const borderWidth = style["borderWidth"];
          let borderColor = style["borderColor"];
          if (dataset && dataset!.borderColor) borderColor = dataset.borderColor.toString();

          return {
            text: datasets[meta.index].label!,
            fillStyle: borderColor,
            fontColor: '#000000BD',
            hidden: !meta.visible,
            lineCap: style["borderCapStyle"],
            lineDash: style["borderDash"],
            lineDashOffset: style["borderDashOffset"],
            lineJoin: style["borderJoinStyle"],
            lineWidth: (borderWidth.width + borderWidth.height) / 4,
            strokeStyle: style["borderColor"],
            pointStyle: pointStyle || style["pointStyle"],
            rotation: style["rotation"],
            textAlign: textAlign || style["textAlign"],
            borderRadius: 15,
            datasetIndex: meta.index
          };
        }, this);
        return legendItems;
      }
    }
  }

  lineChart(color: chartColors) {
    return {
      backgroundColor: color,
      borderColor: color,
      pointBorderColor: chartColors.POINT,
      pointBackgroundColor: chartColors.POINT,
      pointBorderWidth: 0,
      pointRadius: 5,
      type: 'line'
    }
  }

  areaChart(color1: chartColors, color2: chartColors) {
    return {
      backgroundColor: color2,
      borderColor: color1,
      pointBorderColor: color1,
      pointBackgroundColor: color1,
      pointBorderWidth: 0,
      pointRadius: 0.5,
      fill: 'origin',
      type: 'line',
    }
  }

  barChart(color: chartColors) {
    return {
      backgroundColor: color,
      borderColor: color,
      pointBorderColor: chartColors.POINT,
      pointBackgroundColor: chartColors.POINT,
      borderRadius: 1,
      type: 'bar'
    }
  }

  radarChart(color1: chartColors, color2: chartColors) {
    return {
      tension: 0.5,
      backgroundColor: color2,
      borderColor: color1,
      pointBorderColor: chartColors.POINT,
      pointBackgroundColor: chartColors.POINT,
      pointRadius: 4,
      type: 'radar'
    }
  }

  displayRandAmount(amount: number | null): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {style:'currency', currency: 'ZAR', maximumFractionDigits: 0});
    }
    return 'R 0';
  }

  displayRandAmountPerSqm(amount: number | null): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {style:'currency', currency: 'ZAR', maximumFractionDigits: 0}) + ' m²';
    }
    return 'R 0 /m²';
  }

  displayCompactRandAmount(amount: number | null): string {
    if (amount) {
      return amount.toLocaleString('en-ZA',
        {style:'currency', currency: 'ZAR', minimumSignificantDigits: 1, maximumSignificantDigits: 2, notation: 'compact'});
    }
    return 'R 0';
  }

  displayPercentage(amount: number | null): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {maximumFractionDigits: 0}) + ' %';
    }
    return '0 %';
  }
}
