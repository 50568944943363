import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  loading$ = this.loader.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      buttonConfirmDisabled: boolean,
      buttonConfirmLabel: string,
      buttonCancelDisabled: boolean,
      buttonCancelLabel: string
    },
    private loader: LoadingService) { }
}
