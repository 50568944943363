<div fxLayout="column">
  <app-page-header
    [description]="'We will use your billing information to invoice you for your monthly lease and transactional fees. We require your banking details to process your Point of Sale payments to you.'"
    [section]="'Billing and Banking'"
    [title]="'Manage your billing and banking details'">
  </app-page-header>
  <app-billing-details></app-billing-details>

  <div class="section-divider">
    <mat-divider></mat-divider>
  </div>

  <app-banking-details [offering]="offering"></app-banking-details>
</div>
