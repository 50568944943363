import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PageItemModel} from '../../model/page-item.model';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TabManagementService} from '../../services/tab-management.service';

@Component({
  selector: 'app-page-tab-navigator',
  templateUrl: './page-tab-navigator.component.html',
  styleUrls: ['./page-tab-navigator.component.scss']
})
export class PageTabNavigatorComponent implements OnInit, OnDestroy {
  @Input() pages: PageItemModel[] = [];
  @Input() sideNavOpenStatus: boolean = true

  selectedTab = 0;
  activeLinkIndex = -1;

  constructor(private pageContentService: TabManagementService) {
  }

  ngOnInit(): void {
    this.pageContentService.getPageAddEvent().subscribe((page: PageItemModel) => {
      if (this.pages) {
        this.setTabIndex(page);
      }
    });
  }

  ngOnDestroy() {
  }

  onTabChange($event: MatTabChangeEvent): void {
    this.selectedTab = $event.index;
    const page = this.pages[this.selectedTab];
    this.pageContentService.sendTabChangeEvent($event, page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  setTabIndex(page: PageItemModel): void {
    const match = this.pages.filter(f => f.id == page.id)[0];
    this.selectedTab = this.pages.indexOf(match);
  }

  closeTab(page: PageItemModel) {
    this.pages.splice(this.pages.indexOf(page), 1);
    this.pageContentService.sendPageCloseEvent(page);
  }

  closeAllTabs() {
    this.pageContentService.sendCloseAllTabsEvent();
  }
}

/*
* we need to be able to highlight which menu item it is
* Ensure  that the instance data is working
* Ensure tabs stay stateful (there's a ng directive for this)
* Ensure that it's possible to open tabs from even links
* Ensure that there is ability to come from external with a link and open a specific tab with some prebuilt data
* The page loading bar needs to be matched to each page which is loading content
* Check the tab heading and title and format it that if it's longer than a certain length it formats to dots
* */

/*
 need to adda variable to the route for which company we're logging into
* when you hit login there's a conceptual split between credentials and claims
* your modules and features are stored in a shared cache on the service and indexed with the jwt token to get the features
*
* To be added is a map to the jwt token which will have a claim of the tenant codes (client id) this must then be injected to the base service where you add a header for clientId
* */
