<div fxLayout="row" fxLayoutGap="20px">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxFlex="30">

    <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-small">{{ sectionName }}</div>
      <mat-icon>more_vert</mat-icon>
    </div>

    <div class="full-width py-3" fxLayout="column" fxFlexOffset="30px" fxLayoutGap="30px">
      <div class="dashboard-card full-width" fxLayout="column" fxLayoutGap="10px" *ngFor="let stat of chartStats1">
        <div class="dashboard-icon">
          <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="subtitle-default">{{ chart1.stats[stat].statName }}</div>
          <div class="body-1-default">{{ dashboardUtils.displayBestStat(chart1.stats[stat], true) }}</div>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div class="body-1-regular-2">{{ chart1.stats[stat].statDescription }}</div>
        </div>
      </div>

      <div class="dashboard-card full-width" fxLayout="column" fxLayoutGap="10px" *ngFor="let stat of chartStats2">
        <div class="dashboard-icon">
          <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="subtitle-default">{{ chart2!.stats[stat].statName }}</div>
          <div class="body-1-default">{{ dashboardUtils.displayBestStat(chart2!.stats[stat], true) }}</div>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div class="body-1-regular-2">{{ chart2!.stats[stat].statDescription }}</div>
        </div>
      </div>
    </div>

  </div>

  <div class="full-width" fxLayout="column" fxFlex="70">
    <div fxLayout="row" fxLayoutAlign="space-between center">

      <div class="subtitle-default ps-2">{{ chart1.chartName }}</div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="dateFilterType == 'picker'">
        <mat-form-field class="custom-date-picker" appearance="outline">
          <input type="hidden" placeholder="Month" [matDatepicker]="picker1" [value]="date1"/>
          <input matInput type="text" [value]="pickerDisplayValue(date1!)"/>
          <mat-datepicker-toggle matIconSuffix [for]="picker1">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker1 [startView]="getPickerStartView"
                          (yearSelected)="selectYear($event, picker1, 1)"
                          (monthSelected)="selectMonth($event, picker1, 1)"></mat-datepicker>
        </mat-form-field>

        <div class="body-1-regular-2">and</div>

        <mat-form-field class="custom-date-picker" appearance="outline">
          <input type="hidden" placeholder="Month" [matDatepicker]="picker2" [value]="date2"/>
          <input matInput type="text" [value]="pickerDisplayValue(date2!)"/>
          <mat-datepicker-toggle matIconSuffix [for]="picker2">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker2 [startView]="getPickerStartView"
                          (yearSelected)="selectYear($event, picker2, 2)"
                          (monthSelected)="selectMonth($event, picker2, 2)"></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="dateFilterType == 'custom-range'">
        <app-custom-date-filter [customDateRangePresets]="customDateRangePresets"
                                [initialPreset]="initialPreset"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"></app-custom-date-filter>
      </div>

    </div>
    <div fxLayout="row">
      <div class="dashboard-canvas-container" *ngIf="chart1" [fxFlex]="chart2 ? 50 : 100">
        <canvas class="dashboard-canvas" baseChart *ngIf="!chart1.loading; else canvasLoadingSpinner"
                [height]="350"
                [data]="chart1.chartData"
                [options]="chart1.config"
                [type]="chart1.chartType">
        </canvas>
      </div>
      <div class="dashboard-canvas-container" *ngIf="chart2" [fxFlex]="50">
        <canvas class="dashboard-canvas" baseChart *ngIf="!chart2.loading; else canvasLoadingSpinner"
                [height]="350"
                [data]="chart2.chartData"
                [options]="chart2.config"
                [type]="chart2.chartType">
        </canvas>
      </div>
    </div>

  </div>

  <ng-template #canvasLoadingSpinner>
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>
  </ng-template>
</div>


