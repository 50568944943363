export const datePattern: RegExp = /^\d{4}-\d{2}-\d{2}$/;
export const dateTimePattern: RegExp = /^(\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2}(\.\d{1,7})?(\s?[+-]\d{2}:\d{2})?)?)$/;
export const numberPattern: RegExp = /^\d+(\.\d+)?$/;
export const alphanumericPattern: RegExp = /^[a-zA-Z0-9_-]+$/;
export const yearPattern: RegExp = /^(19|20)\d{2}$/;
export const monthPattern: RegExp = /^(January|February|March|April|May|June|July|August|September|October|November|December)$/i;

export interface UploadDataModel {
  property: string;
  label: string;
  required: boolean;
  validators: RegExp[];
  validationMessage: string;
  description: string;
  selectColumn: boolean;
  showInTable: boolean;
  computeValue: boolean;
  computeMethod?: any;
}

export interface UploadResponse {
  total: number,
  error: number,
  successful: number,
  ignored: number,
  replaced: number,
  errorsMessages: string[]
}
