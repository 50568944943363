<div class="pane right-pane" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span class="body-2-default full-width">Switch View</span>
    <mat-tab-group (selectedTabChange)="switchTabs($event)" [(selectedIndex)]="selectedTabIndex"
                   class="custom-tab-group" mat-align-tabs="center"
                   mat-stretch-tabs="false" preserveContent>
        <mat-tab>
            <ng-template mat-tab-label>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                    <div class="square-icon icon-smaller">
                        <mat-icon svgIcon="stacked_lines"></mat-icon>
                    </div>
                    <span class="body-1-default">Timeline</span>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                    <div class="square-icon">
                        <mat-icon svgIcon="list_alt"></mat-icon>
                    </div>
                    <span class="body-1-default">List</span>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <ng-container *ngTemplateOutlet="sidePanel"></ng-container>

    <ng-template #sidePanel>
        <div class="full-width" fxLayout="column" fxLayoutGap="10px" *ngIf="selectedSpace">

            <div class="info-card-dark pb-2" fxLayout="row" fxLayoutAlign="start center">
                <div class="space-name-icon">
                    <mat-icon svgIcon="pending"></mat-icon>
                </div>
                <span class="heading-bold" fxFlexOffset="20px">{{selectedSpace!.districtSpace.spaceName}}</span>
            </div>

            <div *ngIf="!editTimeline" class="info-card-dark" fxLayout="column" fxLayoutGap="10px">
                <span>Quick Statistics (lifetime)</span>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <div class="quick-stat-item">
                        <span class="quick-stat-title">Avg Gla</span>
                        <span class="quick-stat-content">{{selectedSpace!.districtSpace.spaceSize}}m<sup>2</sup></span>
                    </div>
                    <div class="quick-stat-item">
                        <span class="quick-stat-title">Avg price</span>
                        <span class="quick-stat-content">R{{getSpaceAveragePrice()}} m<sup>2</sup></span>
                    </div>
                    <div class="quick-stat-item">
                        <span class="quick-stat-title">Avg term</span>
                        <span class="quick-stat-content">{{getSpaceAverageTerm()}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="editTimeline" class="action-info-card" fxLayout="column" fxLayoutAlign="space-between">
                <div class="full-width heading-regular" style="text-align: start">Action</div>
                <div class="full-width full-height pt-3" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <button class="button-secondary button-outline" mat-flat-button
                            (click)="cancel.emit()">
                        <span class="button-text">Cancel</span>
                    </button>

                    <button class="button-primary" mat-flat-button
                            [disabled]="selectedLeaseSpaceGroup!.leaseGridSpaces.length == 0"
                            (click)="save.emit()">
                        <span class="button-text">Save</span>
                    </button>
                </div>
            </div>

            <div *ngIf="selectedLeaseSpaceGroup">
              <ng-container *ngTemplateOutlet="leaseSummary ? leaseHighlights : createPlannedLease"></ng-container>
            </div>

        </div>
    </ng-template>

    <ng-template #createPlannedLease>
      <div class="info-card-dark" fxLayout="column" fxLayoutGap="10px">
        <div class="heading-regular">Create a planned lease</div>

        <div class="px-2" fxLayout="column" fxLayoutGap="10px" fxFlexOffset="10px">
          <span>Choose the period</span>
          <app-custom-date-filter [presetsShowing]="false" #dateRangeFilter
                                  [minDate]="minDate"
                                  (dateRange)="updateDateRange($event)"></app-custom-date-filter>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div class="px-2" fxLayout="column" fxLayoutGap="10px" *ngIf="selectedLeaseSpaceGroup">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="10px">
              <span>Choose retailers</span>
              <div class="add-icon btn-cursor" (click)="toggleTimelineDrawer()">
                <mat-icon>add</mat-icon>
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="10px" (cdkDropListDropped)="drop($event)" cdkDropList class="answer-option-list">
              <div *ngFor="let retailer of preferredRetailers; let i = index"
                    cdkDrag fxLayout="row" fxLayoutAlign="space-between center">
                <div class="preferred-retailer-container btn-cursor"
                     fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="body-1-regular-1 retailer-label">
                      {{i + 1}}<sup>{{utils.displayOrdinalSuffix(i + 1)}}</sup> {{retailer.companyName}}
                  </span>
                    <mat-icon class="btn-cursor"
                              [ngClass]="getLeaseSpaceFromRetailer(retailer.id!) ? 'primary-color' : 'disabled-color'"
                              matTooltip="Open lease in new tab"
                              (click)="openLeaseViewTab(
                                getLeaseSpaceFromRetailer(retailer.id!)!.leaseUuid,
                                getLeaseSpaceFromRetailer(retailer.id!)!.occupancyType,
                                getLeaseSpaceFromRetailer(retailer.id!)!.agreementType)" svgIcon="open_in_new">
                    </mat-icon>
                </div>
                <mat-icon class="material-icons-outlined warn-orange-color btn-cursor"
                          (click)="removePlannedSpace(retailer.id!)">delete
                </mat-icon>
              </div>
            </div>

            <div *ngIf="selectedSpace.districtSpace.districtSpacesIndustries.length > 0"
                 class="industry-display" fxLayout="column"
                 fxLayoutGap="10px" fxFlexOffset="10px">
              <div class="body-1-default" style="padding-left: 5px">Preferred industries</div>

              <div fxLayout="column" fxLayoutGap="10px" style="padding-left: 10px">
                <div *ngFor="let ind of selectedSpace.districtSpace.districtSpacesIndustries; let i = index"
                     class="body-1-regular-1"
                     fxLayout="row"
                     fxLayoutAlign="space-between center" style="width: 190px; height: 33px;">
                  {{ind.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #leaseHighlights>
      <div class="info-card-dark" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="heading-regular">Lease highlights</div>
          <div class="space-name-icon btn-cursor" (click)="openLeaseViewTab(leaseSummary!.uuid, leaseSummary!.occupancyType!, leaseSummary!.agreementType!)">
            <mat-icon svgIcon="open_in_new"></mat-icon>
          </div>
        </div>

        <div class="px-2" fxLayout="column" fxLayoutGap="10px" fxFlexOffset="10px">
          <span class="body-1-regular-1">Lease period</span>

          <div class="preferred-retailer-container">
            <span class="body-1-regular-1 retailer-label ms-2">{{dateUtils.displayShortDate(selectedLeaseSpaceGroup!.startDate)! + '-' + dateUtils.displayShortDate(selectedLeaseSpaceGroup!.endDate)!}}</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div class="px-2" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="10px">
              <span class="body-1-regular-1">Lease spaces</span>
            </div>

            <div fxLayout="column" fxLayoutGap="10px">
              <div *ngFor="let lgs of leaseSummary!.leaseGridSpaces; let i = index"
                   class="preferred-retailer-container"
                   fxLayout="row" fxLayoutAlign="space-between center">
                <span class="body-1-regular-1 retailer-label ms-2">{{lgs.spaceName}}</span>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div class="px-2" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="10px">
              <span class="body-1-regular-1">Lease space total size</span>
            </div>

            <div class="preferred-retailer-container">
              <span class="body-1-regular-1 retailer-label ms-2">{{leaseSummary!.totalSize! + ' m²'}}</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div class="px-2" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="10px">
              <span class="body-1-regular-1">Monthly rental fee</span>
            </div>

            <div class="preferred-retailer-container">
              <span class="body-1-regular-1 retailer-label ms-2">
                {{utils.displayRandAmount(leaseSummary!.totalMonthlyFee!) + (leaseSummary!.rentalType == 'TURNOVER_PERCENTAGE' ? ' + ' + leaseSummary!.turnoverPercentageValue + '%' : '')}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

</div>
