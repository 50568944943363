import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PageHeaderTab} from './page-header-tabs.interface';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() banner: boolean = false;
  @Input() fullHeight: boolean = false;
  @Input() section?: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() subtitle?: string;
  @Input() backgroundWhite: boolean = false;
  @Input() isTabbed?: boolean = false;
  @Input() tabs?: PageHeaderTab[];
  @Output() tabsChangeEvent = new EventEmitter<PageHeaderTab>();
  @Input() selectedTab: PageHeaderTab | null = null;
  @Input() bottomBorder: boolean = false;

  changeSelectedTab(tab: PageHeaderTab): void {
    this.selectedTab = tab;
    this.tabsChangeEvent.emit(tab);
  }

  isActive(tab: PageHeaderTab): boolean {
    return this.selectedTab?.code === tab.code;
  }
}
