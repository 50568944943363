import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {LoadingService} from "../../../../core/services/loading.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {LeaseViewService} from "../../../leasing/lease-view/lease-view.service";
import {LeaseSummary, LeaseSupplierState, LeaseSupplierSummary, ProductSupplier} from "../../../leasing/leasing.model";
import {PaymentConfigsService} from "../../service/payment-configs.service";

@Component({
  selector: 'app-assigned-suppliers-dialog',
  templateUrl: './assigned-suppliers-dialog.component.html',
  styleUrls: ['./assigned-suppliers-dialog.component.scss']
})
export class AssignedSuppliersDialogComponent implements OnInit {
  @ViewChild('validSuppliersPaginator') set validSuppliersPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.validSuppliersDataSource) this.validSuppliersDataSource.paginator = paginator;
  }

  @ViewChild('invalidSuppliersPaginator') set invalidSuppliersPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.invalidSuppliersDataSource) this.invalidSuppliersDataSource.paginator = paginator;
  }

  @ViewChild('unassignedSuppliersPaginator') set unassignedSuppliersPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.unassignedSuppliersDataSource) this.unassignedSuppliersDataSource.paginator = paginator;
  }

  paymentConfigUuid: string;
  leaseUuid?: string;
  configType: string;

  supplierState!: LeaseSupplierState;

  validSuppliersDataSource: MatTableDataSource<LeaseSupplierSummary> = new MatTableDataSource<LeaseSupplierSummary>([]);
  invalidSuppliersDataSource: MatTableDataSource<LeaseSupplierSummary> = new MatTableDataSource<LeaseSupplierSummary>([]);
  unassignedSuppliersDataSource: MatTableDataSource<ProductSupplier> = new MatTableDataSource<ProductSupplier>([]);

  leaseSupplierColumns = ['leaseUuid', 'retailerCompanyName', 'supplierName'];
  unassignedSupplierColumns = ['supplierUuid', 'supplierName'];

  constructor(public dialogRef: MatDialogRef<AssignedSuppliersDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private paymentConfigsService: PaymentConfigsService,
              public utils: UtilsService,
              private paginatorService: PaginatorService,
              private leaseViewService: LeaseViewService,
              public loading: LoadingService
              ) {
    this.paymentConfigUuid = data.configUuid;
    this.configType = data.configType;
    this.leaseUuid = data.leaseUuid;
  }

  ngOnInit() {
    if (this.configType == 'OFFERING') this.getOfferingSupplierState();
    if (this.configType == 'LEASE') this.getLeaseSupplierState();
  }

  getOfferingSupplierState() {
    this.paymentConfigsService.checkSuppliers(this.paymentConfigUuid).subscribe({
      next: value => {
        this.supplierState = new LeaseSupplierState(value);
        this.setSupplierState();
      }
    })
  }

  getLeaseSupplierState() {
    this.leaseViewService.getLeaseSuppliers(this.leaseUuid!, this.paymentConfigUuid).subscribe({
      next: value => {
        this.supplierState = new LeaseSupplierState(value);
        this.setSupplierState()
      }
    })
  }

  setSupplierState() {
    this.supplierState.validAssignedSuppliersMap.forEach((lea, sup) => {
      this.validSuppliersDataSource.data.push({
        leaseUuid: lea.uuid,
        retailerCompanyName: lea.retailerCompanyName!,
        supplierUuid: sup.uuid,
        supplierName: sup.name
      })
    })

    this.supplierState.invalidAssignedSuppliersMap.forEach((lea, sup) => {
      this.invalidSuppliersDataSource.data.push({
        leaseUuid: lea.uuid,
        retailerCompanyName: lea.retailerCompanyName!,
        supplierUuid: sup.uuid,
        supplierName: sup.name
      })
    })
    this.invalidSuppliersDataSource.paginator = this.invalidSuppliersPaginator;
    this.unassignedSuppliersDataSource.data = this.supplierState.unassignedSuppliers;
  }

  close() {
    this.dialogRef.close();
  }
}
