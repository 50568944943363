import {Injectable} from "@angular/core";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";
import {DateRange} from "@angular/material/datepicker";
import StartOf = moment.unitOfTime.StartOf;

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor() {
    moment.locale('en-za');
  }

  displayOffsetDateTime(date: Date | string | number | null | Moment) {
    if (date) return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
    return null;
  }

  displayShortDateUnix(date: number): string | null {
    if (date) return moment.unix(date).format("YYYY-MM-DD");
    return null;
  }
  displayShortDate(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("YYYY-MM-DD");
    return null;
  }

  displayFullDate(date: Date | string | number | null): string | null {
    if (date) return moment(date).format("Do MMMM YYYY");
    return null;
  }

  displayMonthAndYear(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("MMM YYYY");
    return null;
  }

  displayFullMonthAndYear(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("MMMM YYYY");
    return null;
  }

  displayDateAndMonth(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("DD MMM");
    return null;
  }

  displayDateWithTime(date: Date | string | null | Moment): string | null {
    if (date) return moment(date).format("YYYY-MM-DD (hh:mm)");
    return null;
  }

  displayDayAndDateWithTime(date: Date | string | null | Moment): string | null {
    if (date) return moment(date).format("ddd DD MMM hh:mm");
    return null;
  }

  displayYear(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("YYYY");
    return null;
  }

  displayFullMonth(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("MMMM");
    return null;
  }

  displayDateDuration(date1: string | Date, date2: string | Date) {
    const start = moment(date1);
    const end = moment(date2);

    const months = end.diff(start, 'months');
    const days = end.diff(start, 'days');

    return months > 0 ? `${months} month${months > 1 ? 's' : ''}` : `${days} day${days > 1 ? 's' : ''}`;
  }

  displayDateDurationDays(date: string | Date) {
    const days = moment(date).diff(moment(), 'days');
    return days > 0 ? moment(date).diff(moment(), 'days') + ' days' : '0 days';
  }

  getCurrentDate(): Date {
    return new Date();
  }

  isPastDated(date: string | Date): boolean {
    return moment(date).isBefore(new Date());
  }

  isFutureDated(date: string | Date): boolean {
    return moment(date).isAfter(new Date());
  }

  isPresentDated(date: string | Date): boolean {
    return moment(date).isSame(new Date(), "day");
  }

  getFirstDayOfMonth(date: string | Date): string {
    return moment(date).startOf('month').format('YYYY-MM-DD');
  }

  getFirstDayOfYear(date: string | Date): string {
    return moment(date).startOf('year').format('YYYY-MM-DD');
  }

  getLastDayOfMonth(date: string | Date): string {
    return moment(date).endOf('month').format('YYYY-MM-DD');
  }

  getNumberOfDaysInMonth(date: string | Date): number {
    return moment(date).daysInMonth();
  }

  isWeekday(date: string | Date | Moment) {
    return !(moment(date).weekday() == 0 || moment(date).weekday() == 6);
  }

  doesOverlap(range1: DateRange<string | Date>, range2: DateRange<string | Date>) {
    return moment(range1.start).isSameOrBefore(moment(range2.end)) && moment(range1.end).isSameOrAfter(moment(range2.start));
  }

  datesInclude(dates: Date[], date: Date) {
    let includes = false;
    dates.forEach(ea => {
      includes = moment(ea).isSame(date, 'day') || includes;
    })
  }

  inRange(date: string | Date, range: DateRange<string | Date>) {
    return moment(date).isSameOrBefore(moment(range.end)) && moment(date).isSameOrAfter(moment(range.start));
  }

  getDaysBetweenIncl(date1: string | Date, date2: string | Date | null) {
    if (!date2) {
      date2 = new Date();
    }
    return moment(date2).diff(moment(date1), 'days');
  }

  isBefore(date1: string | Date, date2: string | Date) {
    return moment(date1).isBefore(moment(date2));
  }

  isStartOfCurrentPeriod(date: string | Date | Moment, period: StartOf) {
    return moment(date).isSame(moment(new Date()).startOf(period), 'day');
  }

  getWeeksOfMonth(date: string | Date | Moment) {
    const weeks = [];

    const firstDay = moment(date).startOf('month');
    const lastDay = moment(date).endOf('month');
    let currentSunday = moment(firstDay).startOf('week');

    while (currentSunday.isSameOrBefore(lastDay)) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        if (currentSunday.isSameOrBefore(lastDay)) {
          if (currentSunday.month() == lastDay.month()) {
            week.push(currentSunday.clone());
          }
          currentSunday.add(1, 'day');
        }
      }
      weeks.push(week);
    }
    return weeks;
  }
}
