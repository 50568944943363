<div *ngIf="timeline" class="timeline-component-container full-width full-screen-height-page-default" fxLayout="column">
  <div *ngFor="let list of timeline.timelineLists; let first = first" fxLayout="column">

<!--    HEADER (sticks to top)-->
    <div fxLayout="row">

<!--      DESCRIPTION AND SEARCH-->

      <div class="elements-container" fxLayout="column" fxFlex="282px">
        <div class="elements-list-description heading-regular pt-2" *ngIf="first">{{timeline.description}}</div>

        <div class="element-list-header" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="heading-bold">{{list.displayName}}</div>
          <div>
            <mat-form-field class="custom-text-autofill mx-2" style="width: 200px" appearance="outline"
            (keydown.enter)="filterElements(list.listId)">
              <input matInput [(ngModel)]="list.searchTerm">
              <mat-icon class="search-icon-suffix" matIconSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>

<!--      MONTHS AND DATES (scroll horizontally with timelines)-->

      <div class="timeline-header hide-scroll-bar" fxLayout="column"
           (wheel)="preventHorizontalScroll($event)"
           #dateHeader *ngIf="timeline.timelineLists.length > 0">
        <div *ngIf="first" fxLayout="row">

          <div class="nav-arrow back-arrow" (click)="onScroll(false)">
            <mat-icon>chevron_left</mat-icon>
          </div>
          <div class="nav-arrow forward-arrow" (click)="onScroll(true)">
            <mat-icon>chevron_right</mat-icon>
          </div>

          <div class="timeline-col-header" fxLayout="column" fxLayoutAlign="center center"
               [ngStyle]="{width: this.headerWidth + 'px'}"
               *ngFor="let item of displayedCalendar">
            <div class="timeline-col-header-item" fxLayout="row" fxLayoutAlign="center center">
              <div class="full-width body-1-default">{{item.month}}</div>
            </div>

            <div class="timeline-dates" fxLayout="row" fxLayoutAlign="center center">
              <div class="timeline-weekday"
                   [ngStyle]="{width: timelineColumnWidth + 'px'}"
                   [class.timeline-weekend]="!dateUtils.isWeekday(date[0]!)"
                   [id]="generateDateId(date!)"
                   *ngFor="let date of item.dates">
                {{date![0].getDate()}}
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-spacer-container" fxLayout="row">
          <div class="timeline-spacer"
               [ngStyle]="{width: (timelineColumnWidth! * periodConfig.columns!) + 'px'}"
               *ngFor="let item of displayedCalendar">
            <div class="timeline-dates" fxLayout="row" fxLayoutAlign="center center">
              <div class="timeline-weekday"
                   [ngStyle]="{width: timelineColumnWidth + 'px'}"
                   *ngFor="let date of item.dates">
                <div class="timeline-current-date timeline-current-date-header" *ngIf="containsCurrentDate(date)" fxLayout="column" fxLayoutAlign="end center">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--LIST BODY - scrolls vertically -->
    <div class="timeline-body hide-scroll-bar" fxLayout="row" cdkScrollable #timelineBody [ngStyle]="{'max-height': list.maxLines * 42 + 'px'}">

      <!--ELEMENTS -->

      <div fxLayout="column" fxFlex="280px">
        <div class="element-row-odd" *ngFor="let el of getObjectsForList(list.listId, elementRows)">
          <div class="element-row"
               [class.element-row-selected]="selectedElement && selectedElement.uuid == el.uuid && selectedElement.listId == list.listId"
               fxLayout="row" fxLayoutAlign="space-between center"
               (click)="clickElement(el.uuid, list.listId, true)"
               *ngFor="let y of el.y; let firstEl = first">
            <div class="heading-bold" *ngIf="firstEl">{{el.displayName}}</div>
            <div class="body-1-regular-1" *ngIf="firstEl">{{el.displayValue}}</div>
          </div>
          <div class="element-row" *ngIf="el == null"></div>
        </div>
        <div class="element-row-odd" *ngFor="let extra of getExtraRows(getObjectsForList(list.listId, elementRows))">
          <div class="element-row"></div>
        </div>
      </div>

      <!--TIMELINE - scrolls horizontally (link scroll to dates and other grids)-->

      <div class="full-width timeline-grid-container hide-scroll-bar" #gridContainer (wheel)="preventHorizontalScroll($event)">

        <gridster class="timeline-grid" #gridster (mouseover)="selectedList = list.listId"
                  [ngStyle]="{width: totalLoadedHeaderColumns * 148 + 'px !important'}"
                  [class.timeline-grid-month]="periodConfig.period == TimelinePeriod.MONTH"
                  [class.timeline-grid-week]="periodConfig.period == TimelinePeriod.WEEK"
                  [options]="options">

          <gridster-item class="timeline-grid-item" fxLayout="row" fxLayoutAlign="center center"
                         (click)="clickObject(item)"
                         [item]="item" *ngFor="let item of getObjectsForList(list.listId, displayedTimelineObjects)">
            <ng-container *ngIf="getTimelineObject(item)!; let data">
              <div class="timeline-object" [class.selected-timeline-object]="selectedObject && selectedObject.uuid == getTimelineObject(item).uuid"
                   fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
                   [ngClass]="'primary-bg-' + data.lighten">
                <div [class.timeline-icon-light]="data.lighten! < 40"
                     [class.timeline-icon-dark]="data.lighten! >= 40"
                     [ngClass]="'primary-bg-' + data.lighten"
                     style="position: relative"
                     [ngStyle]="getLabelPosition(item, 25, data.objectData)">
                  <mat-icon [svgIcon]="data.icon!"></mat-icon>
                </div>
                <div *ngIf="!showMultipleItemsLabel(data.objectData, item) && data.objectData.length > 0"
                     class="full-width" fxLayout="row" fxLayoutAlign="center center">
                  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
                       *ngFor="let objectData of data.objectData">
                    <div class="timeline-item-text"
                         [ngStyle]="getLabelPosition(item, 25, data.objectData)"
                         [class.body-1-white]="data.lighten! < 40"
                         [class.body-1-regular-1]="data.lighten! >= 40">
                      {{objectData.displayName}}
                    </div>

                    <div style="position: relative"
                         [ngStyle]="getEndIconPosition(item, 0, data.objectData)"
                         [class.timeline-icon-light]="data.lighten! < 40"
                         [class.timeline-icon-dark]="data.lighten! >= 40">
                      <mat-icon>more_vert</mat-icon>
                    </div>
                  </div>
                </div>

                <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" style="overflow: hidden"
                     *ngIf="showMultipleItemsLabel(data.objectData, item) && data.objectData.length > 0">
                  <div class="timeline-item-text full-width"
                       [ngStyle]="getLabelPosition(item, 25, data.objectData)">Multiple items</div>

                  <div style="position: relative"
                       [ngStyle]="getEndIconPosition(item, 0, data.objectData)"
                       [class.timeline-icon-light]="data.lighten! < 40"
                       [class.timeline-icon-dark]="data.lighten! >= 40">
                    <mat-icon>more_vert</mat-icon>
                  </div>
                </div>
                <div *ngIf="data.objectData.length == 0" class="timeline-item-text full-width"
                     [ngStyle]="getLabelPosition(item, 25, data.objectData)">{{timeline.newObjectLabel}}</div>
                <div style="position: relative"
                     *ngIf="data.objectData.length == 0"
                     [ngStyle]="getEndIconPosition(item, 0, data.objectData)"
                     [class.timeline-icon-light]="data.lighten! < 40"
                     [class.timeline-icon-dark]="data.lighten! >= 40">
                  <mat-icon>more_vert</mat-icon>
                </div>
              </div>
            </ng-container>
          </gridster-item>

          <gridster-item class="timeline-grid-item"
                         [item]="item" *ngFor="let item of getObjectsForList(list.listId, timelineCurrentDate)">
            <div class="timeline-current-date"  fxLayout="column" fxLayoutAlign="center center">
              <div class="full-line"></div>
            </div>
          </gridster-item>

        </gridster>

      </div>

      <div fxLayout="column" class="element-row-container">
        <div class="element-row-odd" *ngFor="let el of getObjectsForList(list.listId, elementRows)"
             [class.element-row-selected]="selectedElement && selectedElement.uuid == el.uuid && selectedElement.listId == list.listId">
          <div class="element-row"
               fxLayout="row" fxLayoutAlign="space-between center"
               *ngFor="let y of el.y; let firstEl = first">
          </div>
        </div>

        <div class="element-row-odd" *ngFor="let extra of getExtraRows(getObjectsForList(list.listId, elementRows))">
          <div class="element-row"></div>
        </div>
      </div>

    </div>

    <div class="list-action-button" fxLayout="column" *ngIf="list.actionButton">
      <div class="list-action-button-divider"
           fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="15px">
        <mat-divider></mat-divider>
        <mat-icon (click)="emitAction.emit(list.listId)">add</mat-icon>
        <mat-divider></mat-divider>
      </div>
      <div>{{list.actionLabel}}</div>
    </div>


  </div>

  <div class="timeline-period-selector" *ngIf="!drawerOpened">
    <mat-form-field class="custom-select-field select-white"
                    appearance="outline" fxLayoutGap="5px">
      <div class="select-icon ms-2" matPrefix>
        <mat-icon svgIcon="page_info"></mat-icon>
      </div>
      <mat-select [compareWith]="compareItems" [(ngModel)]="periodConfig" (ngModelChange)="switchPeriod()">
        <mat-option *ngFor="let period of periodConfigs" [value]="period">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div class="select-icon">
              <mat-icon svgIcon="page_info"></mat-icon>
            </div>
            <div class="body-1-default">{{period.label}}</div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>




