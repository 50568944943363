import {Auditable} from "../../core/model/auditable.model";
import {PanelType} from "../../shared/components/info-panel/info-panel.component";
import {OfferingSpacesInterface} from "../spaces/model/offering-spaces.interface";

export class LeaseSummary {
  uuid!: string;
  parentUuid!: string | null;
  retailerId!: number;
  districtUuid?: string;
  occupancyType?: OccupancyType;
  agreementType?: AgreementType;
  leaseType?: string;
  leaseStatus!: LeaseStatus;
  leaseStructureType!: string;
  districtName?: string;
  districtKey?: string;
  districtDescription?: string;
  spaceNames?: string;
  retailerCompanyName?: string;
  retailerWebsiteUrl?: string;
  retailerDescription?: string;
  leaseEndDate!: string;
  leaseStartDate!: string;
  totalSize!: number;
  termMonths?: number;
  monthlyPricePerSqm?: number | null;
  turnoverPercentageValue?: number | null;
  rentalType?: string | null;
  leaseLocation!: string | null;
  retailerIndustryTypes!: string | null;
  expiresInDays!: number | null;
  totalMonthlyFee!: number | null;
  bankAccountRef!: string | null;
  leaseGridSpaces?: LeaseGridSpace[]

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export enum LeaseStatus {
  PLANNED = 'PLANNED',
  UNSIGNED = 'UNSIGNED',
  INVALID = 'INVALID',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED'
}

export class PaymentConfigData {
  uuid: string | null = null;
  leaseUuid?: string | null = null;
  districtUuid?: string | null;
  activationStatus: string | null = null;
  billingUpdateRequested: string | null = null;
  transactionSource: string | null = null;
  transactionSourceConfig: TransactionSourceConfig | SupplierSourceConfig | null = null;
  active: boolean | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
}

export class PaymentConfigRequest {
  uuid: string | null = null;
  leaseUuid?: string | null = null;
  districtUuid?: string | null = null;
  activationStatus: string | null = null;
  billingUpdateRequested: string | null = null;
  transactionSource: string | null = null;
  transactionSourceConfig: string | null = null;
  active: boolean | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
}

export class LeaseSupplierState {
  validAssignedSuppliers!: { [key: string]: LeaseSummary };
  invalidAssignedSuppliers!: { [key: string]: LeaseSummary };
  unassignedSuppliers: ProductSupplier[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get validAssignedSuppliersMap(): Map<ProductSupplier, LeaseSummary> {
    const map = new Map<ProductSupplier, LeaseSummary>();
    Object.keys(this.validAssignedSuppliers).forEach(key => {
      // Assuming ProductSupplier and LeaseSummary are defined properly
      const supplier: ProductSupplier = deserializeProductSupplier(key);
      const summary: LeaseSummary = this.validAssignedSuppliers[key];
      map.set(supplier, summary);
    });
    return map;
  }

  get invalidAssignedSuppliersMap(): Map<ProductSupplier, LeaseSummary> {
    const map = new Map<ProductSupplier, LeaseSummary>();
    Object.keys(this.invalidAssignedSuppliers).forEach(key => {
      // Assuming ProductSupplier and LeaseSummary are defined properly
      const supplier: ProductSupplier = deserializeProductSupplier(key);
      const summary: LeaseSummary = this.invalidAssignedSuppliers[key];
      map.set(supplier, summary);
    });
    return map;
  }
}

function deserializeProductSupplier(key: string): ProductSupplier {
  const uuid = key.match(/uuid=(.*?),/)![1];
  const name = key.match(/name=(.*?),/)![1];
  const description = key.match(/description=(.*?),/)![1];
  const posAccountUuid = key.match(/posAccountUuid=(.*?),/) ? key.match(/posAccountUuid=(.*?),/)![1] : null;
  return {uuid, name, description, posAccountUuid};
}

export interface LeaseSupplierSummary {
  leaseUuid: string;
  retailerCompanyName: string;
  supplierUuid: string;
  supplierName: string
}

export function mapRequestsToData(requests: PaymentConfigRequest[]): PaymentConfigData[] {
  return requests.map(request => {
    const {
      uuid,
      leaseUuid,
      activationStatus,
      billingUpdateRequested,
      transactionSource,
      transactionSourceConfig,
      active,
      startDate,
      endDate
    } = request;
    return { uuid, leaseUuid, activationStatus, billingUpdateRequested, transactionSource, transactionSourceConfig: JSON.parse(transactionSourceConfig as string), active, startDate, endDate };
  })
}

export function mapRequestToData(request: PaymentConfigRequest): PaymentConfigData {
  const {
    uuid,
    leaseUuid,
    districtUuid,
    activationStatus,
    billingUpdateRequested,
    transactionSource,
    transactionSourceConfig,
    active,
    startDate,
    endDate
  } = request;
  return { uuid, leaseUuid, districtUuid, activationStatus, billingUpdateRequested, transactionSource, transactionSourceConfig: JSON.parse(transactionSourceConfig as string), active, startDate, endDate };
}

export class LeasePricingPeriod {
    uuid: string | null = null;
    leaseUuid: string | null = null;
    leasePaymentConfigUuid: string | null = null;
    applyTransactionFee: boolean | null = null;
    transactionFeeType: string | null = null;
    transactionFee: number | null = null;
    rentalPricingOption: string | null = null;
    rentalPricingTurnoverPercentageValue: number | null = null;
    rentalPricingPerSqmValue: number | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
}

export class TransactionSourceConfig {
  domainPrefix: string | null = null;
  displayName?: string | null = null;
  outletId: string | null = null;
  paymentTypeName: string | null = null;
  accessToken: string | null = null;
  paymentSourceName: string | null = null;
  loginUrl: string | null = null;
  loginUsername: string | null = null;
  loginPassword: string | null = null;
  paymentConfigUuid?: string | null = null;
  startDate?: Date | null = null;
  endDate?: Date | null = null;
  activationStatus?: string | null;
}

export class SupplierSourceConfig {
  paymentConfigUuid: string | null = null;
  suppliers: ProductSupplier[] | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
}

export class SupplierConfigSummary {
  uuid: string | null = null;
  domainPrefix: string | null = null;
  supplierName: string | null = null;
  startDate?: Date | null = null;
  endDate?: Date | null = null;
}

export class TransactionSourceRequest {
  domainPrefix: string | null = null;
  outletId: string | null = null;
  paymentTypeName: string | null = null;
  accessToken: string | null = null;
  paymentSourceName: string | null = null;
  loginUrl: string | null = null;
  loginUsername: string | null = null;
  loginPassword: string | null = null;
}

export class LeaseGridSpace {
  uuid!: string;
  leaseUuid!: string | null;
  occupancyType!: OccupancyType;
  agreementType!: AgreementType;
  districtSpaceId!: number;
  leaseStartDate!: string;
  leaseEndDate!: string;
  availabilityStatus!: string | null;
  spaceSize!: number | null;
  spaceName!: string | null;
  retailerId!: number | null;
  retailerCompanyName!: string | null;
  leaseStatus!: LeaseStatus | null;
  periodGroupUuid!: string | null;
  periodGroupRanking!: number | null;
}

export class LeaseDocument extends Auditable {
  documentUuid!: string;
  documentTypeCode!: string;
  leaseUuid!: string;
  fileName!: string;
  mimeType!: string;
  fileUuid!: string;
  versionNo!: number;
  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class LeaseDocumentType {
  code!: string;
  name!: string;
  description!: string;
  active!: boolean;
}

export class PaymentMethod {
  uuid!: string;
  districtUuid!: string;
  transactionSource!: string;
  name!: string;
  collectionType!: string;
  transactionFeeType!: string;
  transactionFee!: number;
}


export class CardReader {
  cardReaderCode: string | null = null;
  uuid: string | null = null;
  leaseUuid: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  districtUuid: string | null = null;
  retailerId: number | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class OfferingCardReaderConfig {
  cardReaderCode: string | null = null;
  uuid: string | null = null;
  offeringUuid: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  configDomainPrefix: string | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class DistrictCardReader {
  cardReaderCode: string | null = null;
  uuid: string | null = null;
  leaseUuid: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class SpaceSelection {
  leaseUuid: string | null = null;
  retailerId: number | null = null;
  districtUuid: string | null = null;
  occupancyType: OccupancyType | null = null;
  agreementType: AgreementType | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  selectedFloorSpaceNames: string[] = [];
  editSpaces: boolean = false;
  periodGroupRanking: number | null = null;
  periodGroupUuid: string | null = null;

  constructor(leaseUuid: string | null,
              retailerId: number | null,
              districtUuid: string | null,
              occupancyType: OccupancyType | null,
              agreementType: AgreementType | null,
              startDate: string | null,
              endDate: string | null,
              selectedFloorSpaceNames: string[],
              editSpaces: boolean,
              periodGroupRanking: number | null,
              periodGroupUuid: string | null) {
    this.leaseUuid = leaseUuid;
    this.retailerId = retailerId;
    this.districtUuid = districtUuid;
    this.occupancyType = occupancyType;
    this.agreementType = agreementType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedFloorSpaceNames = selectedFloorSpaceNames;
    this.editSpaces = editSpaces;
    this.periodGroupRanking = periodGroupRanking;
    this.periodGroupUuid = periodGroupUuid;
  }
}

export interface LeaseDetailsInfoPanel {
  message: string[],
  type: PanelType,
  buttons: boolean,
  buttonLabels: (string | null)[],
  buttonDisabled: (boolean)[],
  display: boolean,
  code: string
}

export interface LeaseApprovalPanel {
  message: string,
  type: string,
  approvalButton: boolean,
  approvalButtonLabel: string | null,
  approvalButtonType: string,
  approvalButtonDisabled: boolean,
  rejectButton: boolean,
  rejectButtonLabel: string | null,
  rejectButtonType: string,
  rejectButtonDisabled: boolean
  display: boolean
}

export interface OccupiedSpace {
  districtSpace: OfferingSpacesInterface,
  leaseGridSpaceGroups: LeaseGridSpaceGroup[]
}

export interface LeaseGridSpaceGroup {
  groupUuid: string,
  startDate: string,
  endDate: string,
  spaceId: number,
  spaceName: string,
  leaseGridSpaces: LeaseGridSpace[]
}


export interface DistrictGridSpacesForTerm {
  leaseSpaces: LeaseSpaceForTerm[];
  maxFloorSpaceForTerm: number;
  startDate: Date;
  endDate: Date;
}

export interface LeaseSpaceForTerm {
  districtSpaceId: number;
  spaceName: string;
  spaceSize: number;
  availabilityStatus: string;
  leasedCompanyName: string;
  leaseUuid: string;
}

export interface SubLeaseRequest {
  retailerId: number | null;
  offeringUuid: string;
  subLeaseUuid: string | null;
  leaseUuid: string;
  spaceUuid: string | null;
  occupiedSpace: number;
  leaseStartDate: string;
  leaseEndDate: string;
}

export interface SharedLeasesSpaceSize {
  uuid: string | null;
  subLeaseUuid: string;
  leaseUuid: string;
  occupiedSpace: number;
  startDate: string;
  endDate: string;
  availabilityStatus: string
}

export enum OccupancyType {
  SINGLE = 'SINGLE',
  SHARED = 'SHARED'
}

export enum AgreementType {
  LEASE = 'LEASE',
  SUB_LEASE = 'SUB_LEASE'
}

export enum TransactionSource {
  VEND = "VEND",
  VEND_SUPPLIER_OFFERING = "VEND_SUPPLIER_OFFERING",
  VEND_SUPPLIER_LEASE = "VEND_SUPPLIER_LEASE",
  POSTER = "POSTER"
}

export interface ProductSupplier {
  uuid: string;
  name: string;
  description: string;
  posAccountUuid: string | null;
}
