import {Injectable} from "@angular/core";
import {Location} from "@angular-material-extensions/google-maps-autocomplete";
import {Address} from "./address.model";
import PlaceResult = google.maps.places.PlaceResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

@Injectable({
  providedIn: 'root'
})

export class AddressService {

  public parseAddressData(location: Location, place: PlaceResult, addressTypeCode?: string): { address: any, addressPlace: any } {
    const address = this.decodeAddressComponents(place.address_components);
    address.addressType.code = addressTypeCode ? addressTypeCode : 'PHYSICAL';
    address.formattedAddress = place.formatted_address;
    address.latitude = location.latitude;
    address.longitude = location.longitude;
    address.mapsUrl = place.url;
    address.placeId = place.place_id;
    address.placeName = place.name;

    const addressPlace = {
      placeId: place.place_id,
      place: JSON.stringify(place)
    };

    return {address, addressPlace};
  }

  public decodeAddressComponents(addressComponents?: GeocoderAddressComponent[]): Address {
    const address: Address = new Address();
    // @ts-ignore
    addressComponents.forEach((addr: GeocoderAddressComponent) => {
      const type = this.formatAddressTypeKeys(addr.types[0]);
      address[type] = addr.long_name;

      if (type.includes('country') || type.includes('administrative_area_level_1')) {
        address[type + 'Code'] = addr.short_name;
      }
    });
    return address;
  }

  private formatAddressTypeKeys(key: string): string {
    const splitString = key.split('_');
    const capitalized = splitString.map((m, i) => {
      if (i === 0) { return m; }
      return m.charAt(0).toUpperCase() + m.slice(1);
    });
    const formatted = capitalized.join('');
    return formatted;
  }
}
