import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {UpdatePasswordService} from '../service/update-password.service';
import {MatDialog} from '@angular/material/dialog';
import {IconPopupComponent} from '../../../shared/components/dialogs/icon-popup/icon-popup.component';
import {ErrorPopupComponent} from '../../../shared/components/dialogs/error-popup/error-popup.component';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../core/services/loading.service";


@Component({
  selector: 'app-verify-update-password-otp',
  templateUrl: './verify-update-password-otp.component.html',
  styleUrls: ['./verify-update-password-otp.component.scss'],
})
export class VerifyUpdatePasswordOtpComponent {
  public otpForm;
  public errMessage: string | null;
  public loading = false;
  public len = 0;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  otpInputConfig = {
    length: 4,
    allowNumbersOnly: true,
    containerClass: 'otp-wrapper-element',
    inputClass: 'otp-input-element'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UpdatePasswordService,
    private fb: FormBuilder,
    private dialogService: MatDialog,
    public loader: LoadingService
  ) {
    this.otpForm = this.resetForm();
    this.errMessage = null;
  }

  onOtpChange(event: string) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }

  // call the update password service using vars in the context
  onVerify() {
    const otp = this.otpForm.value.pin;
    this.service.updateOtp(otp);

    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.service.updatePassword())
      .then(
        (res) => {
          this.loading = false;
          this.dialogService.open(IconPopupComponent, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Password Reset',
              description: 'Your password has successfully been updated!',
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
          const clientId = this.route.snapshot.paramMap.get('clientId');
          this.router.navigate([`${clientId}/login`]);
        },
        (err) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('otp')!.setValue(null);
              }
              break;
            default:
              console.log(err);
              this.dialogService.open(ErrorPopupComponent, {
                height: '300px',
                width: '450px',
                data: {
                  header: 'Oops!',
                  description: 'An unexpected error occurred',
                },
              });
          }
        }
      );
  }

  onResendOTP(): void {
    this.loading = true;
    this.service.requestNewOTP(true).subscribe(
      (res) => {
        this.loading = false;
        this.dialogService.open(IconPopupComponent, {
          height: '300px',
          width: '475px',
          data: {
            header: 'Resend OTP',
            description: 'We have sent a One Time Pin to the provided email.',
            imageSrc: '../../../../assets/icon-verification-dark.svg'
          },
        });
      },
      (err) => {
        this.loading = false;
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
              this.otpForm.get('pin')!.setValue(null);
              this.ngOtpInputRef.setValue(null);
            }
            break;
          default:
            console.log(err);
            this.dialogService.open(ErrorPopupComponent, {
              height: '300px',
              width: '450px',
              data: {
                header: 'Oops!',
                description: 'An unexpected error occurred',
              },
            });
        }
      }
    );
  }
}
