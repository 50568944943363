<div class="full-width" fxLayout="column">

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default"> Planned lease schedule </span>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Spaces</mat-label>
            <input (keydown.enter)="loadLeases()" formControlName="spaceName" matInput placeholder="Search here"
                   type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
            <mat-label>Company</mat-label>
            <input (keydown.enter)="loadLeases()" formControlName="companyName" matInput placeholder="Search here"
                   type="text">
          </mat-form-field>

        </div>
        <app-custom-date-filter [customDateRangePresets]="dateFilters" (dateRange)="getDateRange($event)"></app-custom-date-filter>
      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
      <ng-container matColumnDef="table-label">
        <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" class="custom-table-label" mat-header-cell>
          Leases
        </th>
      </ng-container>

      <ng-container matColumnDef="spaceName">
        <th *matHeaderCellDef mat-header-cell> Space Name</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{utils.displayString(element.spaceName, 15)}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th *matHeaderCellDef mat-header-cell> Size</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{utils.displayCompactNumber(element.size)}} </td>
      </ng-container>


      <ng-container matColumnDef="companyName">
        <th *matHeaderCellDef mat-header-cell> Company Name</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{utils.displayString(element.companyName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef mat-header-cell> Start Date</th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="rentalType">
        <th *matHeaderCellDef mat-header-cell> Rental Type</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{displayRentalType(element.rentalType)}} </td>
      </ng-container>

      <ng-container matColumnDef="monthlyRentalFee">
        <th *matHeaderCellDef mat-header-cell> Monthly Rental Fee</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{utils.displayRandAmount(element.totalMonthlyFee!) + (element.rentalType == 'TURNOVER_PERCENTAGE' ? ' + ' + element.turnoverPercentageValue + '%' : '')}}</td>
      </ng-container>

      <ng-container matColumnDef="leaseStatus">
        <th *matHeaderCellDef mat-header-cell> Lease Type</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor" mat-cell>
          <div class="lease-status-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-icon svgIcon="pending"></mat-icon>
            <span class="body-1-regular-2">{{this.utils.displayStatus(element.leaseStatus)}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <!--      Normal Row-->
      <tr (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"
          [class.expanded]="row.isExpanded === true"
          class="custom-table-row" mat-row></tr>

      <!--     Define the expanded row-->
      <ng-container matColumnDef="expanded">
        <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" mat-header-cell></th>
        <td (click)="onExpandedRowClick(row)" *matCellDef="let row" [colSpan]="displayedColumns.length" mat-cell>
          <div class="btn-cursor heading-regular" style="text-align: center">
            <b>Click to approve planned lease</b>
          </div>
        </td>
      </ng-container>

      <tr *matRowDef="let row; columns: ['expanded']; when isExpanded" [class.expanded]="row.isExpanded === true"
          mat-row>
    </table>


    <mat-paginator #paginator class="custom-paginator"
                   [length]="this.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
