import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';

import {BankOption, PaymentModelClient} from '../model/payments.model';
import {environment} from '../../../../environments/environment';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/shared/services/base.service';


@Injectable({
  providedIn: 'root'
})

export class PaymentValidationService extends BaseService {

  baseUrl = environment.apiDistrictsHost;
  bankListWithDefaultBranchCode: BankOption[] = [
    {
      bankName: 'Absa Bank',
      centralBranchCode: '632005'
    },
    {
      bankName: 'Capitec Bank',
      centralBranchCode: '470010'
    },
    {
      bankName: 'First National Bank (South Africa)',
      centralBranchCode: '250655'
    },
    {
      bankName: 'Investec Bank',
      centralBranchCode: '580105'
    },
    {
      bankName: 'Nedbank (South Africa)',
      centralBranchCode: '198765'
    },
    {
      bankName: 'Nedbank Corporate Saver Account',
      centralBranchCode: '720026'
    },
    {
      bankName: 'Postbank',
      centralBranchCode: '460005'
    },
    {
      bankName: 'Standard Bank (South Africa)',
      centralBranchCode: '51001'
    },
    {
      bankName: 'African Bank',
      centralBranchCode: '430000'
    },
    {
      bankName: 'Albaraka Bank',
      centralBranchCode: '800000'
    },
    {
      bankName: 'Bank Of Namibia',
      centralBranchCode: '980172'
    },
    {
      bankName: 'Bidvest Bank',
      centralBranchCode: '462005'
    },
    {
      bankName: 'Central Bank Of Lesotho',
      centralBranchCode: '586611'
    },
    {
      bankName: 'Citi Bank',
      centralBranchCode: '350005'
    },
    {
      bankName: 'Discovery Bank',
      centralBranchCode: '679000'
    },
    {
      bankName: 'Finbond Mutual Bank',
      centralBranchCode: '589000'
    },
    {
      bankName: 'First National Bank Lesotho',
      centralBranchCode: '280061'
    },
    {
      bankName: 'First National Bank Namibia',
      centralBranchCode: '282672'
    },
    {
      bankName: 'First National Bank Swaziland',
      centralBranchCode: '287364'
    },
    {
      bankName: 'Grinrod Bank',
      centralBranchCode: '584000'
    },
    {
      bankName: 'Hsbc Bank',
      centralBranchCode: '587000'
    },
    {
      bankName: 'Jp Morgan Chase Bank',
      centralBranchCode: '432000'
    },
    {
      bankName: 'Meeg Bank',
      centralBranchCode: '471001'
    },
    {
      bankName: 'Merchantile Bank',
      centralBranchCode: '450105'
    },
    {
      bankName: 'Mtn Banking',
      centralBranchCode: '490991'
    },
    {
      bankName: 'Standard Bank Namibia',
      centralBranchCode: '87373'
    },
    {
      bankName: 'State Bank Of India',
      centralBranchCode: '801000'
    },
    {
      bankName: 'Ubank',
      centralBranchCode: '431010'
    },
    {
      bankName: 'Unibank',
      centralBranchCode: '790005'
    },
    {
      bankName: 'Vbs Mutual Bank',
      centralBranchCode: '588000'
    },
    {
      bankName: 'Olympus Mobile Bank',
      centralBranchCode: '585001'
    },
    {
      bankName: 'Tyme Bank',
      centralBranchCode: '678910'
    }
  ];

  constructor(private http: HttpClient, private context: CurrentContextService) {
    super();
  }

  getBankListWithDefaultBranchCode(): Promise<BankOption[]> {
    return Promise.resolve(this.bankListWithDefaultBranchCode);
  }

  public validateAccountDetails(model: PaymentModelClient): Observable<any> {
    const offeringUuid = this.context.getCurrentOfferingId()!;
    const uri = this.baseUrl + `/console/districts/${offeringUuid}/payment-method/validate`;
    return this.http.post<PaymentModelClient>(uri, model);
  }
}
