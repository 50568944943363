import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {MatPaginator} from "@angular/material/paginator";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../../core/services/loading.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {ConfigType} from "../../../../support/request-pos-dialog/request-pos.model";
import {RunClientPayoutsService} from "../../../run-client-payouts/service/run-client-payouts.service";
import {PaymentBatchGroupRequest, PaymentBatchMembership} from "../../../transactions/transactions.model";
import {BatchType, clientPayoutJobs, payoutJobs, PayoutRunResponse} from "../../model/run-payouts.model";
import {RunPayoutsConfigService} from '../../service/run-payouts-config.service';
import {RunTenantPayoutsService} from "../../service/run-tenant-payouts.service";

@Component({
  selector: 'app-payout-jobcard',
  templateUrl: './payout-jobcard.component.html',
  styleUrls: ['./payout-jobcard.component.scss']
})
export class PayoutJobcardComponent implements OnInit {
  @Input() jobType!: BatchType;
  @Input() jobNames!: string[];
  @Input() offering!: PageOffering;
  @Input() selectedBatches?: string[] = [];
  @ViewChild('configInput') configInput!: ElementRef<HTMLInputElement>;
  @ViewChild('paginator') paginator!: MatPaginator;

  payoutRunResponse: PayoutRunResponse[] = [];

  importDateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  batchDateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  payoutJobs: {name: string, id: string}[] = [];
  newRetailersBool: boolean = false;

  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 1, TimeFrames.MONTH, 'Last month'),
    new CustomDateRangeModel('2', 0, TimeFrames.MONTH, 'This month')
  ]

  payoutsService!: RunClientPayoutsService | RunTenantPayoutsService;

  singleBatchConfigs: PaymentBatchMembership[] = [];
  sharedBatchConfigs: PaymentBatchMembership[] = [];
  offeringBatchConfigs: PaymentBatchMembership[] = [];

  constructor(private tenantPayoutsService: RunTenantPayoutsService,
              private clientPayoutsService: RunClientPayoutsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              public loader: LoadingService,
              private runPayoutConfig: RunPayoutsConfigService) {
    this.runPayoutConfig.newRetailers.subscribe((val) => {
      this.newRetailersBool = val;
    });
  }

  ngOnInit() {
    this.payoutJobs = this.jobType == BatchType.DISTRICT_PAYOUT ? clientPayoutJobs : payoutJobs;
    this.payoutsService = this.jobType == BatchType.DISTRICT_PAYOUT ? this.clientPayoutsService : this.tenantPayoutsService;

    this.loadPayoutRunInfo();
    this.loadPaymentBatchConfigs();
  }

  loadPayoutRunInfo() {
    this.payoutRunResponse = [];
    this.payoutJobs.forEach(job => {
      if (this.jobNames.includes(job.id)) {
        const model = new PayoutRunResponse({
          id: job.id,
          status: 'Ready',
          name: job.name,
        });

        this.payoutRunResponse.push(model);
        this.payoutsService.getJobInfo(job.id).subscribe({
          next: value => {
            if (value != null) {
              if (value.payload?.correlationId){
                model.prevCorrelationId = value.payload.correlationId;
              }
              if (value.payload?.message){
                model.prevOutcome = value.payload.message;
              }
              if (value.createDate){
                model.updateDate = value.createDate;
              }
            }
          },
          error: err => {
            model.prevOutcome = 'Error getting status.';
          }
        })
      }
    });
  }

  private loadPaymentBatchConfigs(): void {
    const page = { size: '1000', page: '0', sort: 'paymentGroupName,desc'};
    this.runPayoutConfig.getActiveBatchConfigs(page).subscribe({
      next: value => {
        this.singleBatchConfigs = value.content.filter(f => f.configType == 'INDIVIDUAL');
        this.sharedBatchConfigs = value.content.filter(f => f.configType == 'SHARED');
        this.offeringBatchConfigs = value.content.filter(f => f.configType == 'OFFERING');
      },
      error: err => console.log(err)
    })
  }

  public isPageLoading(): boolean {
    let value = false;
    this.payoutRunResponse.forEach(model => {
      if (model.loading){
        value = true;
      }
    });
    return value;
  }

  runService(model: PayoutRunResponse): void {
    if (this.isPageLoading()){
      return;
    } else {
      model.loading = true;

      const startDate = this.dateUtils.displayShortDate(this.batchDateRange.start);
      const endDate = this.dateUtils.displayShortDate(this.batchDateRange.end);

      switch (model.id) {
        case 'transaction_job_import_new_transactions':
          const startImport = this.dateUtils.displayShortDate(this.importDateRange.start);
          const endImport = this.dateUtils.displayShortDate(this.importDateRange.end);

          this.tenantPayoutsService.importNewTransactions(startImport!, endImport!).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'transaction_job_process_payout_billing':
          this.tenantPayoutsService.processPayoutBilling().subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'transaction_job_create_daily_payment_batch':
          const requests: PaymentBatchGroupRequest[] = this.batchGroupSelection.map(m => {
            return {
              paymentGroupUuid: m.uuid,
              groupType: m.configType,
              startDate: m.startDate,
              endDate: m.endDate
            }
          })
          this.tenantPayoutsService.createPaymentBatch(requests).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'transaction_job_submit_daily_transactions_for_payment':
          this.tenantPayoutsService.submitPaymentBatch(this.selectedBatches!).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'transaction_job_create_client_payment_batch':
          this.clientPayoutsService.createPaymentBatch(startDate!, endDate!).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'transaction_job_submit_client_transactions_for_payment':
          this.clientPayoutsService.submitPaymentBatch().subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
      }
    }
  }

  public setDatesToNullAndRunService(model: PayoutRunResponse): void {
    this.importDateRange = new DateRange<Date | null>(null, null);
    this.runService(model);
  }

  handleRes(res: any, id: string): void {
    const model = this.getModel(id);
    model.loading = false;
    if (res != null && res.length > 30){
      model.currentCorrelationId = res;
      model.status = 'Success';
    } else {
      model.status = 'Timeout';
    }
    this.refreshJob(id);
  }

  handleErr(err: any, id: string): void {
    const model = this.getModel(id);
    model.loading = false;
    model.currentCorrelationId = err.error.text;
    model.status = 'Error';
    this.refreshJob(id);
  }

  public refreshJob(id: string): void {
    const model = this.getModel(id);

    if (!model){
      return;
    }

    this.tenantPayoutsService.getJobInfo(id)
      .subscribe({
        next: value => {
          if (value.content && value.content.length > 0) {
            const latestEvent = value.content[0];
            if (latestEvent.payload?.correlationId){
              model.prevCorrelationId = latestEvent.payload.correlationId;
            }
            if (latestEvent.payload?.message){
              model.prevOutcome = latestEvent.payload.message;
            }
            if (latestEvent.createDate){
              model.updateDate = latestEvent.createDate;
            }
          }
        },
        error: err => model.prevOutcome = 'error getting status'
    });
  }

  private getModel(id: string): PayoutRunResponse {
    return this.payoutRunResponse.filter(model => model.id === id)[0];
  }

  updateImportDateRange(event: any): void {
    this.importDateRange = event.dateRange;
  }

  updateBatchDateRange(event: any, start: boolean): void {
    if (start) {
      this.batchDateRange = new DateRange<Date | null>(event.target.value, this.batchDateRange.end);
    }
    if (!start) {
      this.batchDateRange = new DateRange<Date | null>(this.batchDateRange.start, event.target.value);
    }
  }

  get batchGroupSelection(): PaymentBatchMembership[] {
    const selection: PaymentBatchMembership[] = [];
    selection.push(...this.singleBatchConfigs.filter(f => f.selected));
    selection.push(...this.sharedBatchConfigs.filter(f => f.selected));
    selection.push(...this.offeringBatchConfigs.filter(f => f.selected));
    return selection;
  }

  protected readonly BatchType = BatchType;
  protected readonly ConfigType = ConfigType;
}
