import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

import {CurrentContextService} from './current-context.service';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {Router} from '@angular/router';
import {SessionKey} from './session-key.enum';
import {BaseService} from '../../../shared/services/base.service';

import {UserService} from './user.service';
import {environment} from '../../../../environments/environment';
import {UserFeaturesResponseInterface} from '../../interfaces/user-features-response.interface';
import {User} from '../../model/user.model';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private contextService: CurrentContextService,
    private userService: UserService,
    private router: Router,
  ) {
    super();
  }

  get isLoggedIn(): any {
    this.loggedIn.next(this.getToken() !== null && (this.getExpiration() > new Date()));
    return this.loggedIn.asObservable();
  }

  public setToken(res: HttpResponse<Object>): void {
    //todo: need to throw errors if the token is not correct
    const token = res.headers.get(SessionKey.TOKEN_KEY);
    if (typeof token === "string") {
      sessionStorage.setItem(SessionKey.TOKEN_KEY, token);
    }

    const tokenExpiresOn: string | null = res.headers.get('token-expires-on');
    if (typeof tokenExpiresOn === "string") {
      const expiryDate = new Date(tokenExpiresOn);
      sessionStorage.setItem(SessionKey.TOKEN_EXPIRY_KEY, expiryDate.toString());
    }

    //todo: only look up user features after property is selected
    /*this.lookupUserFeatures()
      .then((response: Object) => {
        sessionStorage.setItem(SessionKey.USER_GRANTS_KEY, JSON.stringify(response));
      });*/
    this.loggedIn.next(true);
  }

  public doLogin(request: { email: string, secret: string }): any {
    return this.login(request)
      .then((response: HttpResponse<Object>) => {
        this.setToken(response);
        let userId = parseInt(<string>response.headers?.get('user-id'));
        this.contextService.setCurrentUserId(userId);
        return this.lookUpAndSetUser();
      });
  }

  private login(request: { email: string, secret: string }): any {
    const uri = environment.apiSecurityHost + '/login';
    let observable$ = this.http.post(uri, request, {observe: 'response'});
    return lastValueFrom(observable$)
  }

  public lookupUser(): any {
    let observable$ = this.userService.loadUser(this.contextService.getCurrentUserId()!);
    return lastValueFrom(observable$)
  }

  public lookupAndSetUserFeatures(): Promise<void> {
    return this.userService.getUserFeaturesAndGrants()
      .then((response: UserFeaturesResponseInterface) => {
        this.contextService.setCurrentUserGrants(response)
      }, (error: string) => {
        console.log(error);
      });
  }

  public logout(): void {
    const uri = environment.apiSecurityHost + '/logout';
    lastValueFrom(this.http.post(uri, null,))
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        let clientId = this.contextService.getCurrentLandlordCode();
        if (clientId == null) {
          clientId = this.contextService.getCurrentLandlordId();
        }
        sessionStorage.clear();
        this.contextService.reset();
        this.loggedIn.next(false);
        this.router.navigate([`${clientId}/login`]);
      });
  }

  public getExpiration(): Date {
    const expiration: string | null = sessionStorage.getItem(SessionKey.TOKEN_EXPIRY_KEY);

    return new Date((expiration ? expiration : ''));
  }

  public getToken(): string | null {
    return sessionStorage.getItem(SessionKey.TOKEN_KEY);
  }

  get isVerified(): boolean {
    return this.contextService.currentUser!.verified;
  }


  lookUpAndSetUser(): Promise<boolean> {
    return this.lookupUser()
      .then((response: Object) => {
        this.contextService.setCurrentUserKey(response);
        return true;
      }, (error: string) => {
        console.log(error);
        return false;
      });
  }


  verifyUser(): void {
    let currentUser: User = this.contextService.currentUser!;
    currentUser.verified = true;
    this.contextService.setCurrentUserInContext(currentUser);
  }
}
