<div class="batch-info-drawer" fxLayout="column" fxLayoutAlign="start start" *ngIf="batch" cdkScrollable>
  <div class="batch-info-drawer-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span></span>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="subtitle-default">{{isClient() ? 'Client' : 'Tenant'}} batch</div>
    </div>
    <button class="button-close-top" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider class="full-width"></mat-divider>

  <div class="batch-info-drawer-content" fxLayout="column">

    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center">

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div class="body-1-regular-1 full-width">Payment batch</div>
          <mat-form-field class="custom-text-field select-white" appearance="outline" floatLabel="always">
            <input matInput type="text" placeholder="Batch ID" formControlName="batchId">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div class="body-1-regular-1 full-width">Processed date</div>
          <app-custom-date-filter [presetsShowing]="false"
                                  (dateRange)="getDateRange($event)"
                                  placeholder="Processed date">
          </app-custom-date-filter>
        </div>

      </div>
    </form>

    <div class="full-width" fxLayout="column" fxFlexOffset="40px">
      <table mat-table class="custom-table" #batchTable
             [dataSource]="dataSource">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length">Retailers in batch</th>
        </ng-container>

        <ng-container matColumnDef="retailerCompanyName">
          <th mat-header-cell *matHeaderCellDef> {{isClient() ? 'Retailer pay-out ID' : 'Retailer'}} </th>
          <td mat-cell class="custom-table-cell custom-cell-grey" *matCellDef="let element"> {{ isClient() ? utils.displayUuid(element.retailerBatchUuid) : utils.displayString(element.retailerCompanyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="saleAmountIncl">
          <th mat-header-cell *matHeaderCellDef> {{isClient() ? 'Tenant Sales' : 'Sale Amount'}} </th>
          <td mat-cell class="custom-table-cell custom-cell-grey" *matCellDef="let element"> {{ isClient() ? utils.displayRandAmountWithDecimal(element.retailerSaleAmountIncl) : utils.displayRandAmountWithDecimal(element.saleAmountInclTotal) }} </td>
        </ng-container>

        <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
          <th *matHeaderCellDef mat-header-cell>{{isClient() ? 'Client Pay-out' : 'Retailer Pay-out'}}</th>
          <td mat-cell class="custom-table-cell custom-cell-grey" *matCellDef="let element"> {{ isClient() ? utils.displayRandAmountWithDecimal(element.clientPayoutAmount) : utils.displayRandAmountWithDecimal(element.rollupNetBalancePayableRounded) }} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>

  </div>

</div>
