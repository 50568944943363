import {QuestionGroup} from "../../../../core/model/retailer/application-form.model";

export interface QuestionItem {
  uuid: string | null,
  template: TemplateComponent,
  questionConfig: QuestionGroup | null,
  temp: boolean
}

export interface TemplateComponent {
  controlType: ControlType,
  label: string,
  icon: string,
  type: ComponentType,
  canScore: boolean
}

export enum ComponentType {
  FORM = 'form',
  QUESTION = 'question'
}

export enum ControlType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  CHOICE = 'CHOICE',
  SELECT = 'SELECT',
  RANGE = 'RANGE',
  FILE = 'FILE',
  LOCATION = 'LOCATION',
  HEADING = 'HEADING',
  HEADING_TEXT = 'HEADING_TEXT',
  INFO_FILE = 'INFO_FILE'
}

export const templateComponents: TemplateComponent[] = [
  {
    controlType: ControlType.HEADING,
    label: 'Heading',
    icon: 'title',
    type: ComponentType.FORM,
    canScore: false
  },
  {
    controlType: ControlType.HEADING_TEXT,
    label: 'With text',
    icon: 'format_size',
    type: ComponentType.FORM,
    canScore: false
  },
  {
    controlType: ControlType.TEXT,
    label: 'Free Text',
    icon: 'view_agenda_2',
    type: ComponentType.QUESTION,
    canScore: false
  },
  {
    controlType: ControlType.SELECT,
    label: 'Multiple Choice',
    icon: 'view_agenda',
    type: ComponentType.QUESTION,
    canScore: true
  },
  {
    controlType: ControlType.CHOICE,
    label: 'Yes / No',
    icon: 'rule_folder',
    type: ComponentType.QUESTION,
    canScore: true
  },
  {
    controlType: ControlType.LOCATION,
    label: 'Location',
    icon: 'location_on',
    type: ComponentType.QUESTION,
    canScore: false
  },
  {
    controlType: ControlType.DATE,
    label: 'Date',
    icon: 'calendar_month',
    type: ComponentType.QUESTION,
    canScore: false
  },
  {
    controlType: ControlType.FILE,
    label: 'File Upload',
    icon: 'attachment',
    type: ComponentType.QUESTION,
    canScore: false
  },
  {
    controlType: ControlType.RANGE,
    label: 'Range',
    icon: 'arrow_range',
    type: ComponentType.QUESTION,
    canScore: true
  },
  {
    controlType: ControlType.NUMBER,
    label: 'Number',
    icon: 'pin',
    type: ComponentType.QUESTION,
    canScore: true
  },
  {
    controlType: ControlType.INFO_FILE,
    label: 'Extra Info',
    icon: 'file_download',
    type: ComponentType.FORM,
    canScore: false
  }
]

export interface FormFileValue {
  fileName: string,
  fileUuid: string,
  fileType: string,
  fileCode: string
}
