import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {LeaseDetailsInfoPanel} from "../../leasing.model";

export const leaseNotifications: Map<string, LeaseDetailsInfoPanel> = new Map<string, LeaseDetailsInfoPanel>([
  ['invalid_spaces',
    {
      code: 'invalid_spaces',
      message: ['The spaces is overlapping with an approved or active lease. Change the space or end the overlapping lease.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['planned_incomplete_rates_and_charges',
    {
      code: 'planned_incomplete_rates_and_charges',
      message: ['The following section needs to be completed to approve the planned lease: Rates and charges.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['planned_incomplete_spaces',
    {
      code: 'planned_incomplete_spaces',
      message: ['The following section needs to be completed to approve the planned lease: Spaces.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['planned_parent_not_approved',
    {
      code: 'planned_parent_not_approved',
      message: ['Parent lease has not been approved. Please approve parent lease before approving sub-leases.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_parent_not_active',
    {
      code: 'approved_parent_not_active',
      message: ['Parent lease is not active. Please activate parent lease before proceeding with sub-leases.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['planned_no_sub_leases',
    {
      code: 'planned_no_sub_leases',
      message: ['Lease has no linked sub-leases. Please add a sub-lease before approving this lease.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_legal_docs',
    {
      code: 'approved_legal_docs',
      message: ['Make sure you have uploaded all the legal documents before setting the lease as active.'],
      type: PanelType.INFO,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_billing_requested',
    {
      code: 'approved_billing_requested',
      message: ['The retailer’s billing details have been requested on {{}}. Once updated the lease can be set active to enable pay-outs.'],
      type: PanelType.ERROR,
      buttons: true,
      buttonLabels: ['Request Update'],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_billing_incomplete',
    {
      code: 'approved_billing_incomplete',
      message: ['Retailer billing details not updated. The lease can not be set active to enable pay-outs.'],
      type: PanelType.ERROR,
      buttons: true,
      buttonLabels: ['Request Update'],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_pos_requested',
    {
      code: 'approved_pos_requested',
      message: ['The retailer’s point-of-sale account has been requested and pending activation. Once updated the lease can be set active to enable pay-outs.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_pos_incomplete',
    {
      code: 'approved_pos_incomplete',
      message: ['Point of sale is not linked/active. Pay-outs will not be enabled for this lease.'],
      type: PanelType.WARN,
      buttons: true,
      buttonLabels: ['Request POS'],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_parent_pos_not_active',
    {
      code: 'approved_parent_pos_not_active',
      message: ['Parent point of sale is not activated. The lease can not be set active to enable pay-outs.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_suppliers_incomplete',
    {
      code: 'approved_suppliers_incomplete',
      message: ['No suppliers linked to lease. Please link the tenants respective point-of-sales suppliers to enable payouts.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['approved_card_readers_incomplete',
    {
      code: 'approved_card_readers_incomplete',
      message: ['No card reader assigned to lease. Please link the tenants respective card reader to enable payouts.'],
      type: PanelType.ERROR,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['active_pos_active',
    {
      code: 'active_pos_active',
      message: ['Point of sale cancellation request not sent. The lease can not be terminated to stop pay-outs.'],
      type: PanelType.ERROR,
      buttons: true,
      buttonLabels: ['Cancel POS'],
      buttonDisabled: [false],
      display: false
    }
  ],
  ['active_outside_of_lease',
    {
      code: 'active_outside_of_lease',
      message: ['The retailer is trading outside of their lease period. Some dashboards might be affected.'],
      type: PanelType.WARN,
      buttons: false,
      buttonLabels: [],
      buttonDisabled: [false],
      display: true
    }
  ],
  ['active_sub_leases_not_abandoned',
    {
      code: 'active_sub_leases_not_abandoned',
      message: ['Lease has active/pending sub-leases. End sub-leases before abandoning lease'],
      type: PanelType.ERROR,
      buttons: true,
      buttonLabels: ['Abandon Applications'],
      buttonDisabled: [false],
      display: false
    }
  ],
  ['abandoned',
    {
      code: 'abandoned',
      message: ['This lease has been abandoned and cannot be edited'],
      type: PanelType.DISABLED,
      buttons: true,
      buttonLabels: [],
      buttonDisabled: [],
      display: true
    }
  ],
  ['lease_approval_planned',
    {
      code: 'lease_approval_planned',
      message: ['When ready you can approve or abandon the planned lease to reserve the space.'],
      type: PanelType.INFO,
      buttons: true,
      buttonLabels: ['Approve','Abandon'],
      buttonDisabled: [false, false],
      display: true
    }
  ],
  ['lease_approval_approved',
    {
      code: 'lease_approval_approved',
      message: ['When ready you can set the lease active to enable pay-outs.'],
      type: PanelType.INFO,
      buttons: true,
      buttonLabels: ['Set active','End lease'],
      buttonDisabled: [false, false],
      display: true
    }
  ],
  ['lease_approval_active',
    {
      code: 'lease_approval_active',
      message: ['When ready you can terminate the lease to stop pay-outs.'],
      type: PanelType.INFO,
      buttons: true,
      buttonLabels: ['End lease'],
      buttonDisabled: [false],
      display: true
    }
  ]
])
