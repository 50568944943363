<div class="info-card py-4" fxLayout="column" fxLayoutGap="20px">

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div class="heading-regular">1. Select a file to start the upload</div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between start">
      <div class="px-3" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div class="pick-container" fxLayout="column" fxLayoutAlign="start start">
          <input id="file-upload" type="file" (change)="onFileSelected($event)" hidden accept=".csv" [disabled]="disableUpload"/>
          <label class="btn-cursor" for="file-upload">
            <div class="file-upload-container btn-cursor"
                 fxLayout="column" fxLayoutAlign="end center" matRipple>
              <mat-icon class="material-icons-outlined">upload_file</mat-icon>
              <div class="body-1-regular-1 file-name py-3" *ngIf="!selectedFileName">Select file to upload</div>
              <div class="body-1-regular-1 file-name py-3" *ngIf="selectedFileName">{{selectedFileName}}</div>
            </div>
          </label>
        </div>

        <button class="button-action logout-button"
                (click)="uploadData()"
                [disabled]="columnMappingForm.invalid || disableUpload"
                mat-flat-button>
          <div class="button-text">Upload File</div>
        </button>
      </div>

      <div class="info-panel p-2 pe-3" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon [class]="infoIcon">error</mat-icon>
          <div fxLayout="column" fxLayoutAlign="center start">
            <span class="info-panel-text">{{infoMsg}}</span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="end center" fxFlexAlign="end" *ngIf="uploadReport && !loading">
          <button class="button-action" mat-flat-button (click)="openReport()">
            <span class="button-text">Report</span>
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="end center" fxFlexAlign="end" *ngIf="loading">
          <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </div>
      </div>
    </div>



  </div>

  <mat-divider></mat-divider>

  <div fxLayout="column" fxLayoutGap="15px">
    <div class="heading-regular">2. Import options</div>

    <!-- Column mapping form -->
    <div *ngIf="showColumnMappingForm">
      <form [formGroup]="columnMappingForm" fxLayout="row wrap">
        <div *ngFor="let modelProp of formColumns" class="p-3" fxLayout="column" fxFlex="300px" fxLayoutGap="10px">
          <label class="body-1-regular-1">{{ modelProp.label }} {{ modelProp.required ? '*' : ''}}</label>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 100%">
            <mat-select class="custom-select-field-value" (selectionChange)="updateTable()"
                        [formControlName]="modelProp.property">
              <mat-option class="custom-select-field-option" value="">Select CSV Column</mat-option>
              <mat-option class="custom-select-field-option" *ngFor="let column of csvColumnHeaders" [value]="column">
                {{ column }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-error class="link-error"
            *ngIf="columnMappingForm.get(modelProp.property)?.hasError('patternMismatch')">
            {{ modelProp.validationMessage }}
          </mat-error>

          <div class="heading-regular"> {{modelProp.description}} </div>
        </div>
      </form>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="column" fxLayoutGap="15px">
    <div class="heading-regular">3. Import results</div>

    <!-- Table -->
    <ng-container *ngIf="dataSource?.data?.length ?? 0 > 0">
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" style="width: 100%;">
        <input type="text" matInput placeholder="Search" (input)="applyFilter()" [(ngModel)]="searchText"/>
      </mat-form-field>

      <mat-table class="custom-table" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="table-label">
          <mat-header-cell mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Import results </mat-header-cell>
        </ng-container>

        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{getLabelForColumn(column) }}</mat-header-cell>
          <mat-cell class="custom-table-cell" *matCellDef="let row">{{ row[column] }}</mat-cell>
        </ng-container>

        <mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></mat-header-row>
        <mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        class="custom-paginator"
        [length]="dataSource.data.length"
        [pageSizeOptions]="[10, 25, 50]"
        [pageSize]="pageSize"
      ></mat-paginator>
    </ng-container>
  </div>
</div>





<!-- <hr />
<h5>Debugging Output</h5> -->
<!-- <div>
  <pre>Original Data: {{ originalData | json }}</pre>
  <pre>DataSource Data: {{ dataSource.data | json }}</pre>
</div> -->
