import {Injectable} from '@angular/core';
import {PageItemModel} from '../model/page-item.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PageEventInterface} from '../interfaces/page-event.interface';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatSelectChange} from '@angular/material/select';
import {TabChangeEventInterface} from "../interfaces/tab-change-event.interface";
import {CurrentContextService} from "./security/current-context.service";


@Injectable()
export class TabManagementService {

  private pageAddSubject = new Subject<PageItemModel>();
  private pageCloseSubject = new Subject<PageItemModel>();
  private tabChangeSubject = new Subject<TabChangeEventInterface>();
  private landlordChangeSubject = new Subject<MatSelectChange>();
  private closeAllTabsSubject = new Subject();
  private pageInFocus = new BehaviorSubject<PageItemModel | null>(null);

  constructor(private currentContext: CurrentContextService) {
  }

  sendPageAddEvent(event: PageEventInterface): void {
    if (event && event.offering) {
      this.currentContext.setCurrentOfferingId(event.offering.offeringUuid);
    }
    this.pageAddSubject.next(new PageItemModel(event.componentToRegister,
      event.pageName,
      event.pageHeader,
      event.data,
      event.id,
      event.offeringId,
      event.sectionCode,
      event.pageCode,
      event.offering))
  }

  sendPageCloseEvent(pageToClose: PageItemModel): void {
    this.pageCloseSubject.next(pageToClose);
  }

  sendTabChangeEvent($event: MatTabChangeEvent, page: PageItemModel): void {
    if (page && page.offering) {
      this.currentContext.setCurrentOfferingId(page.offering.offeringUuid);
    }
    this.tabChangeSubject.next({tabEvent: $event, pageInFocus: page});
    this.pageInFocus.next(page);
  }

  sendLandlordChangeEvent($event: MatSelectChange): void {
    this.landlordChangeSubject.next($event);
    this.sendCloseAllTabsEvent();
  }

  sendCloseAllTabsEvent(): void {
    this.closeAllTabsSubject.next([]);
  }

  getLandlordChangeEvent(): Observable<MatSelectChange> {
    return this.landlordChangeSubject.asObservable();
  }

  getCloseAllTabsEvent(): Observable<any> {
    return this.closeAllTabsSubject.asObservable();
  }

  getPageAddEvent(): Observable<PageItemModel> {
    return this.pageAddSubject.asObservable();
  }

  getPageCloseEvent(): Observable<PageItemModel> {
    return this.pageCloseSubject.asObservable();
  }

  getTabChangeEvent(): Observable<TabChangeEventInterface> {
    return this.tabChangeSubject.asObservable();
  }

  getPageInFocus(): Observable<PageItemModel | null> {
    return this.pageInFocus.asObservable();
  }

}
