<mat-sidenav-container class="sidenav-container body-1-regular-2">
  <!-- Left sidenav -->
  <!-- <mat-sidenav #leftSidenav mode="side" opened class="sidenav-left">
    AAA
  </mat-sidenav> -->

  <!-- Right sidenav -->
  <mat-sidenav #rightSidenav mode="side" position="end" class="sidenav-right"
               [fixedInViewport]="false" [fixedTopGap]="0"
               [fixedBottomGap]="0">

    <div class="sidenav-right-content" fxLayout="column" fxLayoutGap="15px">

      <!-- Embed into site -->
      <div class="info-card py-3" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <span class="heading-regular">Embed into your site</span>
        <div class="heading-bold px-3" fxFlexOffset="15px">
          Directly embed the active form onto your site. This way it will appear in the middle of your webpage.
        </div>
        <span class="body-1-regular-2 px-3" fxFlexOffset="15px">How to embed the form</span>


        <div class="stepper pt-3" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
          <div class="step" fxLayout="row" fxLayoutAlign="center start">
            <div class="dot">1</div>
            <div class="label">Identify the webpage where you want to add your form</div>
          </div>
          <div class="line"></div>
          <div class="step" fxLayout="row" fxLayoutAlign="center start">
            <div class="dot">2</div>
            <div class="label">Style and copy your active form source code</div>
          </div>
          <div class="line"></div>
          <div class="step" fxLayout="row" fxLayoutAlign="center start">
            <div class="dot">3</div>
            <div class="label">Add the HTML source code to your webpage</div>
          </div>
        </div>
      </div>

      <!-- Embed size form -->
      <div class="info-card py-3" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <span class="heading-regular">Source code options</span>

        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">

            <button (click)="copyEmbedCode()" class="button-action-primary" mat-button>
                <div class="button-text">Copy code</div>
            </button>
        </div>
      </div>

      <!-- QR code form -->
      <div class="info-card py-3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span class="heading-regular">QR code</span>
        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Embed website URL</mat-label>
          <input matInput type="text" placeholder="Search here" [(ngModel)]="websiteLink">
        </mat-form-field>

        <img [src]="qrCodeImageSrc" class="application-qr-code" alt="QR Code">

        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-around end" fxLayoutGap="10px">
          <button (click)="generateQRCode()" class="button-action-primary" mat-button [disabled]="!websiteLink">
            <div class="button-text">Generate</div>
          </button>

          <button (click)="downloadQRCodeImage()" class="button-action-primary" mat-button [disabled]="!qrCodeImageSrc">
              <div class="button-text">Download</div>
          </button>
        </div>
      </div>
    </div>

  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content cdkScrollable class="sidenav-main-content" >
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">

      <!-- Page header -->
      <app-page-header [section]="'Application Forms'"
        [title]="'Create custom application forms'"
        [description]="'Create and update custom forms that integrates with your applications\n'+
                      'module. Retailers can apply to your retail space with this form.'">
      </app-page-header>

      <!-- Main content area -->
        <div class="content-padding" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="subtitle-default">Application forms</div>
          <button (click)="startNewApplication()" class="button-outline-white" mat-flat-button style="width: 200px">
            <div class="button-text">Create a new application</div>
          </button>
        </div>

        <div class="content-padding">
          <div class="table-card mat-elevation-z3"  fxLayout="column">
            <span class="heading-regular">Application forms</span>

            <table [dataSource]="dataSource" class="custom-table-form" fxFlexOffset="25px" mat-table>
              <ng-container matColumnDef="formName">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell>{{element.formName}}</td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell>
                  <button (click)="startNewApplication()" *ngIf="dataSource.data.length == 0" class="button-outline-white" mat-flat-button style="width: 200px">
                    <div class="button-text">Create a new application</div>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="isCurrentlyActive">
                <th *matHeaderCellDef mat-header-cell>Status</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell>
                  <div *ngIf="element.isCurrentlyActive" class="success-chip" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>check_circle</mat-icon>
                    <span class="footer-default">Active</span>
                  </div>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="updateDate">
                <th *matHeaderCellDef mat-header-cell>Last edited</th>
                <td *matCellDef="let element" class="custom-table-cell"
                    mat-cell>{{dateUtils.displayShortDate(element.updateDate)}}</td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="preview">
                <th *matHeaderCellDef mat-header-cell style="text-align: center">Preview</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center">
                  <div class="form-hover-button" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="previewForm(element)" class="form-icon-button" mat-icon-button>
                      <mat-icon class="material-icons-outlined">visibility</mat-icon>
                    </button>

                  </div>
                </td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="makeActive">
                <th *matHeaderCellDef mat-header-cell style="text-align: center">Make Active</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center">
                  <div class="form-hover-button" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="setAsActiveFormTemplate(element)" class="form-icon-button" mat-icon-button>
                      <mat-icon class="material-icons-outlined">done</mat-icon>
                    </button>

                    <div class="form-button-tooltip">
                      <span class="body-1-default tooltip-text">Make this form your set offering application.</span>
                    </div>
                  </div>
                </td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th *matHeaderCellDef mat-header-cell style="text-align: center">Edit</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center">

                  <div class="form-hover-button" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="editExistingApplication(element)" class="form-icon-button" mat-icon-button>
                      <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>

                    <div class="form-button-tooltip" *ngIf="!canBeUpdated(element)">
                      <span class="body-1-default tooltip-text">Forms that have been used cannot be edited</span>
                    </div>
                  </div>
                </td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="duplicate">
                <th *matHeaderCellDef mat-header-cell style="text-align: center">Duplicate</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center">
                  <div class="form-hover-button" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="duplicateForm(element)" class="form-icon-button" mat-icon-button>
                      <mat-icon class="material-icons-outlined">content_copy</mat-icon>
                    </button>

                    <div class="form-button-tooltip">
                      <span class="body-1-default tooltip-text">This will create a new form version to edit.</span>
                    </div>
                  </div>
                </td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th *matHeaderCellDef mat-header-cell style="text-align: center">Delete</th>
                <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center">
                  <div class="form-hover-button" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="deleteFormTemplate(element)" class="form-icon-button" mat-icon-button>
                      <mat-icon class="material-icons-outlined">delete</mat-icon>
                    </button>

                    <div class="form-button-tooltip">
                      <span class="body-1-default tooltip-text">This will delete the form permanently.</span>
                    </div>
                  </div>
                </td>
                <td *matFooterCellDef [class.hidden-footer-cell]="dataSource.data.length > 0" mat-footer-cell></td>
              </ng-container>

              <tr *matHeaderRowDef="displayedColumns" class="custom-table-header" mat-header-row></tr>
              <tr *matRowDef="let row; columns: displayedColumns;" class="custom-table-row" mat-row></tr>
              <tr *matFooterRowDef="displayedColumns" [class.hidden-footer-row]="dataSource.data.length > 0" mat-footer-row></tr>
            </table>
          </div>
        </div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
