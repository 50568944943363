import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../../shared/datasource/generic.datasource";
import {BatchStatus, PaymentBatch, PaymentBatchMembership} from "../../../transactions/transactions.model";
import {DateRange} from "@angular/material/datepicker";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {tap} from "rxjs";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {RunTenantPayoutsService} from "../../service/run-tenant-payouts.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {MatDialog} from "@angular/material/dialog";
import {BatchType} from "../../model/run-payouts.model";
import {RunClientPayoutsService} from "../../../run-client-payouts/service/run-client-payouts.service";

@Component({
  selector: 'app-payment-batches-list',
  templateUrl: './payment-batches-list.component.html',
  styleUrls: ['./payment-batches-list.component.scss']
})
export class PaymentBatchesListComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() batchType!: BatchType;
  @Input() selectedBatches: string[] = [];
  @Output() selectedBatchesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  filterForm: FormGroup;

  dataSource = new GenericDatasource<PaymentBatch>(
    this.batchType == BatchType.DISTRICT_PAYOUT ? this.clientPayoutsService : this.runPayoutsService
  );
  displayedColumns = ['select', 'paymentBatchUuid', 'paymentGroupName', 'paymentBatchStatus', 'processedDate', 'saleAmountIncl'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 0, TimeFrames.MONTH, 'This month')
  ]

  batchStatuses: string[] = Object.keys(BatchStatus);
  selectedBatch: PaymentBatch | null = null;

  constructor(private runPayoutsService: RunTenantPayoutsService,
              private clientPayoutsService: RunClientPayoutsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private matDialog: MatDialog) {
    this.filterForm = new FormGroup({
      batchId: new FormControl(null),
      batchStatus: new FormControl([]),
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<PaymentBatch>(
      this.batchType == BatchType.DISTRICT_PAYOUT ? this.clientPayoutsService : this.runPayoutsService
    );
    this.dataSource.loadData('/payment-batches', {size: '10', page: '0', sort: 'processedDate,desc'}, []);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadPaymentBatches()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadPaymentBatches());
  }

  loadPaymentBatches() {

    let filters = [
      {name: 'batchStatuses', val: this.filterForm.get('batchStatus')?.value},
      {name: 'paymentBatchUuid', val: this.filterForm.get('batchId')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null}
    ].filter((filter: { name: string, val: any }) => {
      return (!(filter.val === null || filter.val === undefined))
    });

    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'processedDate,desc'
    }
    this.dataSource.loadData('/payment-batches', page, filters);
  }


  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadPaymentBatches()
  }

  viewBatchItems(batch: PaymentBatch) {
    this.selectedBatch = batch;
  }

  selectBatch(uuid: string) {
    const index = this.selectedBatches.indexOf(uuid);
    if (index >= 0) {
      this.selectedBatches.splice(index, 1)
    } else {
      this.selectedBatches.push(uuid);
    }
    this.selectedBatchesChange.emit(this.selectedBatches);
  }

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedBatches = this.dataSource.getData()
        .filter(f => f.processStatus == BatchStatus.UNSUBMITTED)
        .map(m => m.uuid);
    } else {
      this.selectedBatches = [];
    }
    this.selectedBatchesChange.emit(this.selectedBatches);
  }

  get isAllSelected() {
    const unsubmittedBatches = this.dataSource.getData().filter(f => f.processStatus == BatchStatus.UNSUBMITTED);
    return unsubmittedBatches.length == this.selectedBatches.length;
  }

  get selectedGroups() {
    return this.selectedBatches.length;
  }

  protected readonly BatchType = BatchType;
  protected readonly BatchStatus = BatchStatus;
}
