import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from "@angular/material/sort";
import {MatTableDataSource} from '@angular/material/table';
import {lastValueFrom, tap} from 'rxjs';
import {LoadingService} from '../../../../../core/services/loading.service';
import {GenericDatasource} from '../../../../../shared/datasource/generic.datasource';
import {DateUtilsService} from '../../../../../shared/services/dateUtils.service';
import {PaginatorService} from '../../../../../shared/services/paginator.service';
import {UtilsService} from '../../../../../shared/services/utils.service';
import {LeaseViewService} from "../../../../leasing/lease-view/lease-view.service";
import {AgreementType, OccupancyType, PaymentConfigRequest, TransactionSource} from "../../../../leasing/leasing.model";
import {PaymentConfigsService} from "../../../../settings/service/payment-configs.service";
import {
  AddLeaseToBatchRequest,
  LeaseNotInPaymentBatch,
  LinkedRetailer,
  PaymentBatchMembership
} from '../../../transactions/transactions.model';
import {RunPayoutsConfigService} from '../../service/run-payouts-config.service';

@Component({
  selector: 'app-payment-groups',
  templateUrl: './payment-groups.component.html',
  styleUrls: ['./payment-groups.component.scss']
})
export class PaymentGroupsComponent implements OnInit, AfterViewInit {

  configsType = new FormControl(TransactionSource.VEND);

  // batch groups
  @ViewChild('paymentGroupPaginator') paymentGroupPaginator!: MatPaginator;
  addingGroup = false;
  addGroupForm: FormGroup;
  filterGroupForm: FormGroup;

  removingRetailer: boolean = false;
  retailerToRemove: LinkedRetailer | null = null;

  linkedRetailerSelectionModel = new SelectionModel<LinkedRetailer>(true, []);
  selectedGroupUuid: string | null = null;

  batchMembershipColumns = ['active', 'uuid', 'paymentGroupName'];
  paymentBatchMembershipDataSource = new GenericDatasource<PaymentBatchMembership>(this.runPayoutConfigService);

  leaseInMembershipColumns = ['retailerId', 'companyName', 'leaseUuid', 'leaseStartDate', 'leaseEndDate', 'action'];
  linkedRetailerTableDataSource: MatTableDataSource<LinkedRetailer> = new MatTableDataSource<LinkedRetailer>([]);

  // not in batch

  @ViewChild('retailerNotBatchedPaginator') retailerNotBatchedPaginator!: MatPaginator;
  leaseStatuses = ['ACTIVE', 'APPROVED', 'EXPIRED', 'TERMINATED'];
  leasePaymentConfigStatuses = ['NO_ACTIVE_PAYMENT_CONFIG', 'PAYMENT_CONFIG_FOUND'];
  notInBatchSort: string | null = 'companyName,desc';
  notInBatchForm: FormGroup;

  addingLeaseToGroup: boolean = false;
  leaseToAdd: LeaseNotInPaymentBatch | null = null;

  leaseNotInGroupSelection = new SelectionModel<LeaseNotInPaymentBatch>(false, []);
  selectedLeaseNotInGroupUuid: string | null = null;

  notInGroupColumns = ['select', 'retailerId', 'companyName', 'leaseUuid', 'leaseStartDate', 'leaseEndDate', 'leaseStatus', 'leasePaymentConfigStatus', 'leaseBillingStatus', 'action']
  notInGroupDataSource = new GenericDatasource<LeaseNotInPaymentBatch>(this.runPayoutConfigService);

  // offering payment configs
  @ViewChild('configPaginator') set configPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.paymentConfigDatasource) this.paymentConfigDatasource.paginator = paginator;
  }

  paymentConfigSelection = new SelectionModel<PaymentConfigRequest>(false, []);
  selectedPaymentConfigUuid: string | null = null;

  paymentConfigColumns: string[] = ['select', 'uuid', 'transactionSource', 'domainPrefix', 'outletId', 'startDate', 'endDate'];
  paymentConfigDatasource = new MatTableDataSource<PaymentConfigRequest>();

  // shared payment configs
  @ViewChild('sharedLeasesPaginator', { static: false }) set sharedLeasesPaginator(paginator: MatPaginator) {
    if (paginator) paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.sharedLeasesDatasource) this.sharedLeasesDatasource.paginator = paginator;
  }

  sharedLeasesForm: FormGroup;

  sharedLeaseSelection = new SelectionModel<LeaseNotInPaymentBatch>(false, []);
  selectedSharedLeaseUuid: string | null = null;

  sharedLeasesColumns: string[] = ['select', 'retailerId', 'companyName', 'leaseUuid', 'leaseStartDate', 'leaseEndDate', 'leaseStatus'];
  sharedLeasesDatasource = new MatTableDataSource<LeaseNotInPaymentBatch>();

  configTypeFilters: Map<TransactionSource, { configType: string, occupancyType: OccupancyType, agreementType: AgreementType}>
    = new Map<TransactionSource, {configType: string; occupancyType: OccupancyType; agreementType: AgreementType}>();

  constructor(public loader: LoadingService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private runPayoutConfigService: RunPayoutsConfigService,
              private paginatorService: PaginatorService,
              private offeringPaymentConfigService: PaymentConfigsService,
              private leaseViewService: LeaseViewService) {
    this.addGroupForm = new FormGroup({
      groupName: new FormControl({value: '', disabled: true}, [Validators.required])
    });
    this.addGroupForm.disable();

    this.filterGroupForm = new FormGroup({
      active: new FormControl({value: 'null', disabled: false}, [Validators.required])
    });

    this.notInBatchForm = new FormGroup({
      leaseStatus: new FormControl({value: ['ACTIVE', 'APPROVED'], disabled: false}),
      leasePaymentConfigStatus: new FormControl({value: ['PAYMENT_CONFIG_FOUND'], disabled: false})
    });

    this.sharedLeasesForm = new FormGroup({
      leaseStatus: new FormControl({value: ['ACTIVE', 'APPROVED'], disabled: false}),
      leasePaymentConfigStatus: new FormControl({value: ['PAYMENT_CONFIG_FOUND'], disabled: false})
    });

    this.configTypeFilters.set(TransactionSource.VEND, { configType: 'INDIVIDUAL', occupancyType: OccupancyType.SINGLE, agreementType: AgreementType.LEASE});
    this.configTypeFilters.set(TransactionSource.POSTER, { configType: 'INDIVIDUAL', occupancyType: OccupancyType.SINGLE, agreementType: AgreementType.LEASE});
    this.configTypeFilters.set(TransactionSource.VEND_SUPPLIER_LEASE, { configType: 'SHARED', occupancyType: OccupancyType.SHARED, agreementType: AgreementType.SUB_LEASE});
    this.configTypeFilters.set(TransactionSource.VEND_SUPPLIER_OFFERING, { configType: 'OFFERING', occupancyType: OccupancyType.SINGLE, agreementType: AgreementType.LEASE});
  }

  ngOnInit(): void {
    this.refreshPaymentGroupData();
    this.refreshNotInGroupData();
  }

  ngAfterViewInit(): void {
    this.configsType.valueChanges.subscribe(change => {
      switch (change) {
        case TransactionSource.VEND || TransactionSource.POSTER:
          // load all single leases with own pos;
          this.filterGroupForm.get('parentUuid')?.setValue(null);
          this.loadPaymentBatches();
          this.refreshNotInGroupData();
          break;
        case TransactionSource.VEND_SUPPLIER_OFFERING:
          // load all offering configs;
          this.loadOfferingPaymentConfigs();
          this.refreshNotInGroupData();
          break;
        case TransactionSource.VEND_SUPPLIER_LEASE:
          // load all shared lease configs;
          this.loadSharedLeases();
          this.refreshNotInGroupData();
          break;
      }
    })
    this.setPaginatorsAndForms();
  }

  setPaginatorsAndForms() {
    this.paymentGroupPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paymentGroupPaginator.page
      .pipe(
        tap(() => this.loadPaymentBatches()))
      .subscribe();
    this.filterGroupForm.valueChanges.subscribe(() => this.loadPaymentBatches());

    this.retailerNotBatchedPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.retailerNotBatchedPaginator.page
      .pipe(
        tap(() => this.loadLeasesNotInPaymentBatch()))
      .subscribe();
    this.notInBatchForm.valueChanges.subscribe(() => this.loadLeasesNotInPaymentBatch());

    this.sharedLeasesForm.valueChanges.subscribe(() => this.loadSharedLeases());
  }


  loadOfferingPaymentConfigs() {
    this.offeringPaymentConfigService.getDistrictPosConfigs().subscribe({
      next: value => {
        this.paymentConfigDatasource.data = value;
        this.paymentConfigDatasource.paginator = this.configPaginator;
        this.setSelectedPaymentConfig(value[0]);
      },
      error: err => console.log(err)
    })
  }

  loadSharedLeases() {
    const filters = [
      {name: 'leaseStatuses', val: this.sharedLeasesForm.get('leaseStatus')?.value},
      {name: 'leasePaymentConfigStatuses', val: ['VEND']},
      {name: 'occupancyType', val: 'SHARED'},
      {name: 'agreementType', val: 'LEASE'}
    ];

    this.runPayoutConfigService.getAll('/no-membership', {
      size: '1000',
      page: '0',
      sort: 'companyName,desc'
    }, filters).subscribe({
      next: value => {
        this.sharedLeasesDatasource.data = [...value.content];
        this.setSelectedSharedLease(value.content[0]);
      },
      error: err => console.log(err)
    });
  }

  toggleAddGroup(): void {
    this.addingGroup = !this.addingGroup;
    if (this.addingGroup) {
      this.addGroupForm.enable();
    } else {
      this.addGroupForm.disable();
    }
  }

  saveNewGroup(): void {
    let groupName: string = this.addGroupForm.get('groupName')!.value;

    lastValueFrom(this.runPayoutConfigService.createNewPaymentBatch(groupName, this.getParentUuid, this.configTypeFilters.get(this.configsType.value!)!.configType))
      .then(() => {
        this.refreshPaymentGroupData();
        this.toggleAddGroup();
      })
      .catch((error: string) => {
        console.log(error);
      })
  }

  filterResults($event: MatButtonToggleChange): void {
    this.filterGroupForm.get('active')!.setValue($event.value);
  }

  setSelectedLeaseNotInGroup(row: LeaseNotInPaymentBatch) {
    this.leaseNotInGroupSelection.toggle(row);
    this.selectedLeaseNotInGroupUuid = row.leaseUuid;
  }

  setSelectedPaymentConfig(row: PaymentConfigRequest) {
    this.paymentConfigSelection.toggle(row);
    this.selectedPaymentConfigUuid = row.uuid;
    this.filterGroupForm.get('parentUuid')?.setValue(row.uuid);
    this.loadPaymentBatches();
    this.loadLeasesNotInPaymentBatch()
  }

  setSelectedSharedLease(row: LeaseNotInPaymentBatch) {
    this.sharedLeaseSelection.toggle(row);
    this.selectedSharedLeaseUuid = row.leaseUuid;
    this.filterGroupForm.get('parentUuid')?.setValue(row.leaseUuid);
    this.loadPaymentBatches();
    this.loadLeasesNotInPaymentBatch();
  }

  addLeaseToSelectedGroup(row: LeaseNotInPaymentBatch): void {
    this.leaseToAdd = row;
    this.addingLeaseToGroup = true;
  }

  isOnePaymentGroupSelected(): boolean {
    return this.selectedGroupUuid != null;
  }

  refreshPaymentGroupData(): void {

    const filters = [
      {name: 'active', val: this.filterGroupForm.get('active')!.value},
      {name: 'configType', val: this.configTypeFilters.get(this.configsType.value!)?.configType},
      {name: 'parentUuid', val: this.getParentUuid},
    ];
    this.paymentBatchMembershipDataSource.loadData(`/membership/filtered`, {
      size: '10',
      page: '0',
      sort: 'paymentGroupName,desc'
    }, filters);
    this.selectedGroupUuid = null;
    this.linkedRetailerSelectionModel.clear();
    this.linkedRetailerTableDataSource = new MatTableDataSource<LinkedRetailer>([]);
  }

  toggleGroupActive(element: PaymentBatchMembership): void {
    lastValueFrom(this.runPayoutConfigService.toggleGroupActive(element))
      .then(() => {
        this.refreshPaymentGroupData();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  setSelectedGroup(row: PaymentBatchMembership): void {
    this.selectedGroupUuid = row.uuid;
    this.linkedRetailerSelectionModel.clear();
    this.linkedRetailerTableDataSource = new MatTableDataSource<LinkedRetailer>(row.linkedRetailers);
  }

  removeRetailerFromBatch(element: LinkedRetailer): void {
    this.retailerToRemove = element;
    this.removingRetailer = true;
  }

  calculateActiveStatus(element: PaymentBatchMembership): boolean {
    return element.active;
  }

  confirmRemoveOfRetailer(event: boolean): void {
    if (event) {
      lastValueFrom(this.runPayoutConfigService.removeLeaseFromBatch(this.retailerToRemove!))
        .then(() => {
          this.refreshPaymentGroupData();
        })
        .catch((error: string) => {
          console.log(error);
        })
        .finally(() => {
          this.removingRetailer = false;
          this.retailerToRemove = null;
        });
    } else {
      this.removingRetailer = false;
      this.retailerToRemove = null;
    }
  }

  confirmAddLeaseToGroup($event: boolean): void {
    if ($event) {
      let request: AddLeaseToBatchRequest = {
        batchUuid: this.selectedGroupUuid!,
        leaseUuids: [this.leaseToAdd!.leaseUuid]
      }
      lastValueFrom(this.runPayoutConfigService.addLeaseToPaymentBatch(request))
        .then(() => {
          this.refreshPaymentGroupData();
          this.refreshNotInGroupData();
        })
        .catch((error: string) => {
          console.log(error);
        })
        .finally(() => {
          this.leaseToAdd = null;
          this.addingLeaseToGroup = false;
        });
    } else {
      this.leaseToAdd = null;
      this.addingLeaseToGroup = false;
    }
  }

  private loadPaymentBatches(): void {
    const filters = [
      {name: 'active', val: this.filterGroupForm.get('active')?.value},
      {name: 'configType', val: this.configTypeFilters.get(this.configsType.value!)?.configType},
      {name: 'parentUuid', val: this.getParentUuid},
    ];

    const page = {
      size: this.paymentGroupPaginator.pageSize.toString(),
      page: this.paymentGroupPaginator.pageIndex.toString(),
      sort: 'paymentGroupName,desc'
    };
    this.paymentBatchMembershipDataSource.loadData(`/membership/filtered`, page, filters);
  }

  private loadLeasesNotInPaymentBatch(): void {
    const filters = [
      {name: 'leaseStatuses', val: this.notInBatchForm.get('leaseStatus')?.value},
      {name: 'leasePaymentConfigStatuses', val: this.paymentConfigStatus},
      {name: 'occupancyType', val: this.configTypeFilters.get(this.configsType.value!)?.occupancyType},
      {name: 'agreementType', val: this.configTypeFilters.get(this.configsType.value!)?.agreementType},
      {name: 'parentUuid', val: this.getParentUuid},
    ];

    const sortName = this.notInBatchSort ? this.notInBatchSort : 'companyName,desc';

    this.notInGroupDataSource.loadData('/no-membership', {
      size: this.retailerNotBatchedPaginator.pageSize.toString(),
      page: this.retailerNotBatchedPaginator.pageIndex.toString(),
      sort: sortName
    }, filters);
  }

  private refreshNotInGroupData(): void {
    this.notInBatchSort = 'companyName,desc';
    const filters = [
      {name: 'leaseStatuses', val: ['ACTIVE', 'APPROVED']},
      {name: 'leasePaymentConfigStatuses', val: this.paymentConfigStatus},
      {name: 'occupancyType', val: this.configTypeFilters.get(this.configsType.value!)?.occupancyType},
      {name: 'agreementType', val: this.configTypeFilters.get(this.configsType.value!)?.agreementType},
    ];

    this.notInGroupDataSource.loadData('/no-membership', {
      size: '10',
      page: '0',
      sort: 'companyName,desc'
    }, filters);
  }

  sortData(sort: Sort) {
    if (!sort || sort.direction == "") {
      this.notInBatchSort = null;
      return;
    }
    this.notInBatchSort = sort.active + ',' + sort.direction;
    this.loadLeasesNotInPaymentBatch();
  }

  getConfigValue(key: string, providerConfig: string) {
    const json = JSON.parse(providerConfig);
    const keys = key.split('.')
    let result = json;
    for (const key of keys) {
      if (result.hasOwnProperty(key)) {
        result = result[key];
      } else {
        return undefined; // or throw an error, or handle the case as needed
      }
    }
    return result;
  }

  setConfigsType(event: MatButtonToggleChange) {
    this.configsType.setValue(event.value);
    this.refreshPaymentGroupData();
  }

  get paymentConfigStatus(): string[] {
    const statuses: string[] = [];
    if (this.notInBatchForm.get('leasePaymentConfigStatus')?.value) {
      this.notInBatchForm.get('leasePaymentConfigStatus')?.value.forEach((ea: string) => {
        if (ea == 'NO_ACTIVE_PAYMENT_CONFIG') {
          statuses.push('NO_ACTIVE_PAYMENT_CONFIG');
        } else {
          statuses.push(this.configsType.value!.toString());
        }
      })
    }
    return statuses;
  }

  get getParentUuid(): string | null {
    switch (this.configsType.value) {
      case TransactionSource.VEND_SUPPLIER_OFFERING.toString():
        return this.selectedPaymentConfigUuid;
      case TransactionSource.VEND_SUPPLIER_LEASE.toString():
        return this.selectedSharedLeaseUuid;
      case TransactionSource.VEND.toString() || TransactionSource.POSTER.toString():
        return null;
    }
    return null;
  }

  displayConfigStatus(status: string) {
    if (status == 'NO_ACTIVE_PAYMENT_CONFIG') return this.utils.displayStatus(status);
    return this.utils.displayStatus('PAYMENT_CONFIG_FOUND');
  }

  protected readonly TransactionSource = TransactionSource;
}
