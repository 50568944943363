import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LandlordPropertiesResponse, LandlordPropertyModel} from '../model/landlordProperty.model';
import {CurrentLandlordModel} from '../model/landlord.model';
import {environment} from '../../../environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {LocationOffering} from '../model/side-nav.model';

@Injectable({
  providedIn: 'root'
})
export class LandlordsService {

  offeringTypes: string[] = ['DISTRICT',
    'STORAGE',
    'KIOSK',
    'SPACES',
    'GENERAL_LEASING',
    'POP_UPS',
    'DIGITAL_ADVERTISING',
    'MARKETS',
    'EXHIBITIONS'];

  constructor(private http: HttpClient) {
  }

  getPropertiesForLandlord(landlordUuid: string): Promise<LandlordPropertyModel[]> {

    return lastValueFrom(this.http.get<LandlordPropertiesResponse>(environment.apiLandlordHost + `/console/landlord/${landlordUuid}/location`))
      .then((result: LandlordPropertiesResponse) => {
        return result.content;
      }, (error: string) => {
        console.log(error);
        return [];
      })
      .then((result: LandlordPropertyModel[]) => {
        result.forEach((property: LandlordPropertyModel) => {
          lastValueFrom(this.getOfferingsForLocation(property.uuid,
            this.offeringTypes))
            .then((offerings: LocationOffering[])  => {
              property.locationOfferings = offerings;
            })
            .catch((error: string) => {
              console.log(error);
            });
        });
        return Promise.resolve(result);
      });
  }

  getLandlordProperty(landlordUuid: string, locationUuid: string): Observable<LandlordPropertyModel> {
    return this.http.get<LandlordPropertyModel>(environment.apiLandlordHost + `/console/landlord/${landlordUuid}/location/${locationUuid}`)
  }

  getLandlord(landlordId: string): Observable<CurrentLandlordModel> {
    return this.http.get<CurrentLandlordModel>(environment.apiLandlordHost + `/api/public/landlord/${landlordId}`);
  }

  getOfferingsForLocation(locationUuid: string, offeringTypes: string[]): Observable<LocationOffering[]> {
    const params = {
      offeringTypes
    }
    return this.http.get<LocationOffering[]>(environment.apiLandlordHost +
      `/console/landlord/location/${locationUuid}/platform/offering`, {params: params}
    );
  }

  getLandlordWithCode(landlordCode: string): Observable<CurrentLandlordModel> {
    let params = {
      code: landlordCode
    }
    return this.http.get<CurrentLandlordModel>(environment.apiLandlordHost + `/api/public/landlord`, {params: params});
  }

  uploadFloorPlan(file: File, locationUuid: string, locationOfferingUuid: string): Observable<LocationOffering> {
    const formData = new FormData();
    formData.append('file', file);
    const uri = `${environment.apiLandlordHost}/console/landlord/location/${locationUuid}/platform/offering/${locationOfferingUuid}/floor-plan/upload`;
    return this.http.post<LocationOffering>(uri, formData);

  }
}
