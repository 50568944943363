import {Auditable} from '../../../core/model/auditable.model';

export class ServiceInterest extends Auditable {
  districtUuid?: string | null;
  districtName?: string | null;
  nominatedLocationPlacesUuid?: string | null;
  nominatedLocationAddress?: string | null;
  retailerId!: number;
  companyName!: string
  retailerCurationStatus!: string;
  locationType!: string;
  leaseTypeCode!: string;
  status!: string;
  periodType?: string | null;
  takeupTimeframeCode?: string | null;
  serviceStartDateMin!: string | null;
  serviceStartDateMax!: string | null;
  serviceEndDate!: string | null;
  industryTypes?: string | null;
  createUserName!: string;
  properties?: ServiceInterestProperty[] | null;
  additionalServices?: AdditionalService[] | null;
  additionalProducts?: AdditionalProduct[] | null;
}

export class ServiceInterestProperty extends Auditable {
  serviceInterestUuid!: string;
  propCode!: string;
  propValue!: number;
}

export class AdditionalService extends Auditable {
  parentType!: string;
  parentUuid!: string;
  serviceUuid!: string;
  serviceStatus!: string;
  serviceStartDate!: string;
  serviceEndDate!: string;
}

export class AdditionalProduct extends Auditable {
  parentType!: string;
  parentUuid!: string;
  productUuid!: string;
  productStatus!: string;
  productStartDate!: string;
  productEndDate!: string;
}

export class InterestService {
  uuid!: string;
  code!: string;
  listType!: string;
  name!: string;
  type!: string;
  selected!: boolean;
}

export class InterestProduct {
  uuid!: string;
  code!: string;
  listType!: string;
  name!: string;
  productSku!: string;
  type!: string;
  selected!: boolean;
}

export enum TakeupTimeframe {
  MONTHS_1_TO_3 = 'Within 1 to 3 months',
  MONTHS_3_PLUS = 'After 3 months',
  IMMEDIATE = 'Immediately',
  SPECIFIED = 'Specified'
}
