// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiSharedServicesHost: 'https://shared-services.api.dev.nterlabs.dev',
  apiRetailersHost: 'https://retailer.api.dev.nterlabs.dev',
  apiLeaseHost: 'https://lease.api.dev.nterlabs.dev',
  // apiLeaseHost: 'http://localhost:9500',
  apiTransactHost: 'https://transact.api.dev.nterlabs.dev',
  // apiTransactHost: 'http://localhost:9400',
  apiSecurityHost: 'https://security.api.dev.nterlabs.dev',
  // apiSecurityHost: 'http://localhost:9100',
  apiDistrictsHost: 'https://district.api.dev.nterlabs.dev',
  apiLandlordHost: 'https://landlord.api.dev.nterlabs.dev',
  apiProductsHost: 'https://products.api.dev.nterlabs.dev',
  // apiProductsHost: 'http://localhost:9300',
  googleMapsApiKey: 'AIzaSyAXJwQ-wJD1d67FV_HMVXBC8QcXugzHQjw',
  unlayerApiProjectId: 199871,
  unlayerApiKey:'4WfGkwdy8fMGPoe5Jmh504boX5iHqFuUIEEwZFepHFTgb3CZ5zclFbl9LUsx0vfS',
  unlayerUserSecret: 'm9w3QIPAvJ55454ZiM1gutwkUVrmYeCSgFKm9X7fCi4BbNNnaEWEPxeGKxmyXOdC',
  iubendaApiKey: '85763951',
  apiPortal: 'https://portal.dev.nterlabs.dev',
  apiAppForm: 'https://apply.dev.nterlabs.dev/nter-app-form.js'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
