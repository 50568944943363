import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UtilsService} from "../../../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../../../shared/services/dateUtils.service";
import {GenericDatasource} from "../../../../../../shared/datasource/generic.datasource";
import {PaymentBatch, RetailerBatchSummary} from "../../../../transactions/transactions.model";
import {TransactionsService} from "../../../../transactions/transactions.service";
import {BatchType} from "../../../model/run-payouts.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {MatTable} from "@angular/material/table";

@Component({
  selector: 'app-payment-batch-dialog',
  templateUrl: './payment-batch-transactions.component.html',
  styleUrls: ['./payment-batch-transactions.component.scss']
})
export class PaymentBatchTransactionsComponent implements OnInit, AfterViewInit, OnChanges{
  @ViewChild("batchTable") batchTable?: MatTable<any>;
  @Input() batch!: PaymentBatch | null;
  @Output() toggleDrawer = new EventEmitter<boolean>();

  dataSource = new GenericDatasource<RetailerBatchSummary>(this.transactionsService);
  displayedColumns = ['retailerCompanyName', 'saleAmountIncl', 'netBalancePayableRetailerInclRounded'];

  filterForm: FormGroup;
  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private transactionsService: TransactionsService) {
    this.filterForm = new FormGroup({
      batchId: new FormControl(null)
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<RetailerBatchSummary>(this.transactionsService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["batch"].currentValue) {
      this.batch = {...changes["batch"].currentValue};
      this.loadBatchItems();
    } else {
      this.dataSource = new GenericDatasource<RetailerBatchSummary>(this.transactionsService);
    }
  }

  ngAfterViewInit() {
    this.filterForm.valueChanges.subscribe(() => this.loadBatchItems());

    this.dataSource.loading$.subscribe(data => {
    })
  }

  loadBatchItems() {
    let filters = [
      {name: 'paymentBatchUuid', val: this.filterForm.get('batchId')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null}
    ].filter((filter: { name: string, val: any }) => {
      return (!(filter.val === null || filter.val === undefined))
    });
    const page = {size: '1000', page: '0', sort: 'processedDate,desc'};

    const urlItem = this.batch!.payoutType == BatchType.DISTRICT_PAYOUT ? 'client' : 'tenant'
    this.dataSource.loadData(`/run-${urlItem}-payouts/payment-batches/${this.batch!.uuid}/rollup`, page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadBatchItems()
  }

  close() {
    this.toggleDrawer.emit(false);
  }

  isClient() {
    return this.batch!.payoutType == BatchType.DISTRICT_PAYOUT;
  }
}
