import {Injectable} from "@angular/core";
import {GenericService} from "../../../shared/services/generic.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {GroupedRetailerPipeline, Retailer} from "../retailers/retailers.model";
import {IndustryCategory} from "../industry.model";
import {GenericChart} from "../../../shared/model/generic-chart.interface";

@Injectable({
  providedIn: 'root'
})
export class PipelineService extends GenericService<Retailer> {
  constructor(http: HttpClient) {
    super(http, environment.apiRetailersHost + '/console/offering/district/pipeline/retailer-pipeline');
  }


  getChartData(params: {name: string, val: any}[]): Observable<GenericChart> {
    let requestParams = new HttpParams();

    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {requestParams = requestParams.append(ea.name, ea.val)});
    }
    return this.http.get<GenericChart>(this.getBaseUrl + '/industries-chart', {params: requestParams});
  }

  getAllIndustries(): Observable<IndustryCategory[]> {
    return this.http.get<[]>(environment.apiRetailersHost + '/api/public/retailer-industries');
  }

  get getBaseUrl() {
    return environment.apiRetailersHost + `/console/offering/district/pipeline/retailer-pipeline`;
  }

  loadQualifiedGroupedApplicants(page: { size: string; page: string; sort: string }, params: any[]): Observable<GroupedRetailerPipeline> {
    let requestParams = new HttpParams()
      .set('sort', page.sort)
      .set('size', page.size)
      .set('page', page.page);

    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {
        requestParams = requestParams.append(ea.name, ea.val)
      });
    }
    return this.http.get<GroupedRetailerPipeline>(`${this.getBaseUrl}/qualified-applicants/grouped`, {params: requestParams})
  }
}
