<div fxLayout="column" class="full-width">
  <div fxLayout="row" class="pipeline-search-header">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="pt-1">
          <button (click)="responsiveDrawer.closeDrawer()" mat-icon-button>
            <mat-icon svgIcon="back-arrow-button" style="transform: rotate(180deg)"></mat-icon>
          </button>

          <div class="heading-bold">Company</div>

          <mat-form-field [formGroup]="filtersFormGroup" appearance="outline" class="custom-text-autofill mx-2" style="width: 200px">
            <input (keydown.enter)="loadPipeline()" formControlName="companyName" matInput placeholder="Search here"
                   type="text">
            <mat-icon class="search-icon-suffix" matIconSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="end end" fxFlex>
        <span class="subscript-float-right">Find more retailers for {{getSpaceName()}} date range</span>
      </div>
  </div>

  <hr/>

  <div class="full-width" fxLayout="row" fxLayoutGap="15px" [formGroup]="filtersFormGroup">
    <mat-form-field class="select-round" appearance="outline" floatLabel="always" style="width: 270px;">
      <mat-label>Industry And Product Collection</mat-label>
      <mat-select [(value)]="selectedIndustries" class="select-round-value"
                  formControlName="industryTypes" multiple placeholder="Industry And Product Collection">
        <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
          <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                     fxLayoutGap="5px">
            <span>{{group.name}}</span>
            <mat-icon class="btn-cursor">add</mat-icon>
          </mat-label>
          <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                      class="custom-select-field-option">{{ ind.name }}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <div class="chips-container hide-scroll-bar-bg" fxLayout="row" fxLayoutGap="10px">
      <mat-chip-row class="industry-chip" *ngFor="let ind of selectedIndustries"
                    (removed)="removeFromSelectedIndustries(ind)">
        <span class="chip-text">{{utils.displayStatus(ind)}}</span>
        <button matChipRemove [attr.aria-label]="'remove ' + ind">
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip-row>
    </div>
  </div>

  <table mat-table class="custom-table" [dataSource]="dataSource">
    <ng-container matColumnDef="table-label">
      <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"></th>
    </ng-container>
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element" class="custom-table-cell" mat-cell style="text-align: center" >

        <div class="hover-button-container-left" style="display: flex">
          <div class="select-retailer-button btn-cursor" [class.select-retailer-button-checked]="isPreferredRetailer(element)"
               (click)="isPreferredRetailer(element) ? removeRetailerFromShortlist(element) : addRetailerToShortlist(element)">
            <mat-icon class="check-icon" *ngIf="isPreferredRetailer(element)">check</mat-icon>
            <mat-icon class="add-icon" *ngIf="!isPreferredRetailer(element)">add</mat-icon>
          </div>

          <div class="form-button-tooltip" *ngIf="!isPreferredRetailer(element)">
            <span class="body-1-default tooltip-text">Add the retailer to the planned lease short list.</span>
          </div>
        </div>
      </td>
      <td *matFooterCellDef [class.hidden-footer-cell]="this.totalElements > 0" mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell class="heading-active" *matHeaderCellDef> Company Name </th>
      <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
    </ng-container>

    <ng-container matColumnDef="industryType">
      <th mat-header-cell class="heading-active" *matHeaderCellDef> Industry or Product Collection </th>
      <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">
        <mat-chip-row class="industry-chip" *ngFor="let ind of getRetailerIndustries(element.industryTypes, 6)">
          <span class="chip-text">{{ind}}</span>
        </mat-chip-row>
      </td>
    </ng-container>

    <ng-container matColumnDef="overallScore">
      <th mat-header-cell class="heading-active" *matHeaderCellDef> Overall Score </th>
      <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">
        <div style="display: flex" class="hover-button-container-right">
          <div fxLayout="row" fxLayoutAlign="space-between center" (click)="openPipelineViewTab(element.id)">
            <mat-progress-bar class="score-progress-bar" color="primary" mode="determinate" [value]="element.overallScore"></mat-progress-bar>
            <div *ngIf="element.overallScore"> {{element.overallScore}}% </div>
          </div>

          <div class="form-button-tooltip-right tooltip-right btn-cursor" (click)="openPipelineViewTab(element.id)">
            <span class="body-1-default tooltip-text ps-3">View Application</span>
          </div>
        </div>
      </td>
    </ng-container>
<!--    <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>-->
    <tr mat-header-row class="custom-table-header heading-active" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="custom-table-row-1" *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Group Header    -->
    <ng-container matColumnDef="groupHeader">
      <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" mat-header-cell></th>
      <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
        <div class="cell-text">
          <div [ngClass]="'heading-bold'">{{element.group}}</div>
          <div *ngIf="element.subText">{{element.subText}}</div>
        </div>
      </td>
    </ng-container>

    <tr *matRowDef="let row; columns: ['groupHeader']; when: isGroup" [ngClass]="row.styleClass" class="custom-table-row-1"
        mat-row></tr>
  </table>
  <mat-paginator #paginator class="custom-paginator"
                 [length]="this.totalElements"
                 [pageSize]="50"
                 [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>

</div>

