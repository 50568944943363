import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {OfferingListingService} from '../../service/offering-listing.service';
import {lastValueFrom} from 'rxjs';
import {LocationOffering} from '../../../../core/model/side-nav.model';
import {ImageCroppedEvent, ImageCropperComponent, ImageTransform} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageUploadUtilsService} from '../../../../shared/services/image-upload-utils.service';

@Component({
  selector: 'app-upload-offering-listing-photo-dialog',
  templateUrl: './upload-offering-listing-photo-dialog.component.html',
  styleUrls: ['./upload-offering-listing-photo-dialog.component.scss']
})
export class UploadOfferingListingPhotoDialogComponent {
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale = 1;
  transform: ImageTransform = {};
  fileName: string;
  routeParam = true;
  aspectRatio: number;
  cropWidth: number;
  width?: string;
  round: boolean = true;
  image: any;
  loading = false;
  url?: string | ArrayBuffer;
  errorMessage: string | null = null;

  constructor(private dialogRef: MatDialogRef<UploadOfferingListingPhotoDialogComponent>,
              private offeringListingService: OfferingListingService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private imageUploadUtils: ImageUploadUtilsService) {
    this.fileName = 'no file selected';
    this.aspectRatio = 1;
    this.cropWidth = 200;
    this.round = true;
  }

  upload(): void {
    this.errorMessage = null;

    const blob = this.imageUploadUtils.dataURItoBlob(this.croppedImage);
    if (blob.size > 10485760) {
      this.errorMessage = 'File size too large, files must be less than 10MB';
      return;
    }

    const type = this.fileName.replace(/^.+[\W]/, '');
    const file = new File([blob], this.fileName, {
      type: `image/${type}`
    });

    this.loading = true;

    lastValueFrom(this.offeringListingService.uploadOfferingListingImage(file, this.data.locationOfferingUuid))
      .then((res: LocationOffering) => {
        this.close(res);
      })
  }

  fileChangeEvent($event: Event): void {
    this.errorMessage = null;
    this.fileName = this.fileInput.nativeElement.value.replace(/^.*[\\\/]/, '');
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(image?: HTMLImageElement): void {
    console.log(image);
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
    this.errorMessage = 'Invalid image type, only png and jpg are allowed';
  }


  onZoom(amount: number): void {
    console.log(this.scale);
    if ((amount > 0) && (this.scale < 2)) {
      this.scale = this.scale + 0.1;
    } else if ((amount < 0) && (this.scale >= 0.5)) {
      this.scale = this.scale - 0.1;
    }
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  close(data?: LocationOffering) {
    this.dialogRef.close(data);
  }

  isFileSelected(): boolean {
    return !(this.fileName == 'no file selected')
  }
}
