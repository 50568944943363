import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {PageHeaderTab} from '../../../../core/components/page-header/page-header-tabs.interface';
import {TenantType} from "../retailers.model";

@Component({
  selector: 'app-retailer-view',
  templateUrl: './retailer-view.component.html',
  styleUrls: ['./retailer-view.component.scss']
})
export class RetailerViewComponent extends BasePageComponent {
  retailerId!: string;
  tenantType: TenantType;
  companyName!: string;
  tabs: PageHeaderTab[] = [
    {
      name: 'Details',
      isActive: true,
      code: "retailer_details",
      disabled: false
    },
    {
      name: 'Application',
      isActive: false,
      code: "retailer_application",
      disabled: false
    }
  ];

  activeTab: string = "retailer_details"

  constructor(injector: Injector) {
    super(injector);
    this.retailerId = this.data.id;
    this.companyName = this.data.companyName;
    this.tenantType = this.data.tenantType;

    this.tabs[1].disabled = this.tenantType == TenantType.OFFERING;
  }

  changeTabs($event: PageHeaderTab): void {
    this.activeTab = $event.code;
  }

  getInitialTab(): PageHeaderTab {
    return this.tabs.at(0)!;
  }

  protected readonly TenantType = TenantType;
}
