<div class="full-width" fxLayout="column" fxLayoutGap="15px">
  <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <span class="subtitle-default">Set minimum pay-out amount</span>
      <div fxLayout="row" fxLayoutGap="15px">
        <button (click)="toggleEdit()" *ngIf="!this.edit" [disabled]="loader.loading$ | async" class="button-action"
                mat-flat-button>
          <div class="button-text">Update</div>
        </button>
        <button (click)="saveMinimumAmount()" *ngIf="this.edit" [disabled]="loader.loading$ | async"
                class="button-action" mat-flat-button>
          <div class="button-text">Save</div>
        </button>
        <button (click)="toggleEdit()" *ngIf="this.edit" [disabled]="loader.loading$ | async"
                class="button-action" mat-flat-button>
          <div class="button-text">Cancel</div>
        </button>
      </div>
    </div>
    <div *ngIf="newRetailersBool" fxFlexOffset="20px">
      <app-info-panel
        [message]="['There are new retailers that have not been added to a payment batch']"></app-info-panel>
    </div>
  </div>

  <div class="info-card-dark" fxLayout="column" fxLayoutGap="20px">
    <div class="mat-icon-encircle">
      <mat-icon class="mat-icon material-icons-outlined">shopping_bag</mat-icon>
    </div>
    <div [formGroup]="amountForm" class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="heading-bold">Minimum pay-out</span>
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label>Amount</mat-label>
        <input class="text-number-input" formControlName="amount" matInput min="0" type="number"/>
      </mat-form-field>
    </div>
    <div>
      <span class="body-1-regular-2">This setting is applied for all payment groups</span>
    </div>
  </div>


</div>
