import {AgmCoreModule} from "@agm/core";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {registerLocaleData} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import localeZa from '@angular/common/locales/en-ZA';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterOutlet} from "@angular/router";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GridsterModule} from "angular-gridster2";
import moment from "moment";
import {NgOtpInputModule} from 'ng-otp-input';
import {NgChartsModule} from "ng2-charts";
import {ImageCropperModule} from 'ngx-image-cropper';
import {environment} from "../environments/environment";
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {FooterLinksComponent} from './core/components/footer/footer-links/footer-links.component';
import {PrivacyPolicyComponent} from "./core/components/footer/privacy-policy/privacy-policy.component";
import {
  TermsAndConditionsComponent
} from "./core/components/footer/terms-and-conditions/terms-and-conditions.component";
import {HomeScreenComponent} from './core/components/home-screen/home-screen.component';
import {
  MyPropertiesLandingComponent
} from './core/components/home-screen/my-properties-landing/my-properties-landing.component';
import {
  NotificationsDialogComponent
} from "./core/components/notifications/notifications-dialog/notifications-dialog.component";
import {NotificationsComponent} from "./core/components/notifications/notifications.component";
import {BasePageComponent} from './core/components/page-content/base-page.component';
import {PageHeaderComponent} from "./core/components/page-header/page-header.component";
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {PageTabContentComponent} from './core/components/page-tab-content/page-tab-content.component';
import {PageTabNavigatorComponent} from './core/components/page-tab-navigator/page-tab-navigator.component';
import {
  PasswordChangeOtpDialogComponent
} from './core/components/user-profile-popover/dialogs/password-change-otp-dialog/password-change-otp-dialog.component';
import {
  UploadUserProfileImageComponent
} from './core/components/user-profile-popover/dialogs/upload-user-profile-image/upload-user-profile-image.component';
import {
  UserProfileDialogComponent
} from './core/components/user-profile-popover/dialogs/user-profile-dialog/user-profile-dialog.component';
import {UserProfilePopoverComponent} from './core/components/user-profile-popover/user-profile-popover.component';
import {HeaderLessTabDirective} from './core/directives/header-less-tab.directive';
import {NetworkInterceptor} from "./core/services/network-interceptor.service";
import {TokenInterceptor} from './core/services/security/token-interceptor.service';
import {TabManagementService} from './core/services/tab-management.service';
import {
  PayoutHistoryListComponent
} from "./features/accounts/payout-history/payout-history-list/payout-history-list.component";
import {
  RunClientPayoutsViewComponent
} from "./features/accounts/run-client-payouts/run-client-payouts-view/run-client-payouts-view.component";
import {ProductsJobcardComponent} from "./features/accounts/run-products/products-jobcard/products-jobcard.component";
import {RunProductsComponent} from "./features/accounts/run-products/run-products.component";
import {
  ActivePayoutGroups
} from "./features/accounts/run-tenant-payouts/run-tenant-payouts-view/active-payout-groups/active-payout-groups.component";
import {
  PaymentBatchTransactionsComponent
} from "./features/accounts/run-tenant-payouts/run-tenant-payouts-view/payment-batches-list/payment-batch-dialog/payment-batch-transactions.component";
import {
  PaymentBatchesListComponent
} from "./features/accounts/run-tenant-payouts/run-tenant-payouts-view/payment-batches-list/payment-batches-list.component";
import {
  PaymentGroupsComponent
} from './features/accounts/run-tenant-payouts/run-tenant-payouts-view/payment-groups/payment-groups.component';
import {
  PayoutJobcardComponent
} from "./features/accounts/run-tenant-payouts/run-tenant-payouts-view/payout-jobcard/payout-jobcard.component";
import {
  PayoutMinimumAmountComponent
} from './features/accounts/run-tenant-payouts/run-tenant-payouts-view/payout-minimum-amount/payout-minimum-amount.component';
import {
  RunTenantPayoutsViewComponent
} from "./features/accounts/run-tenant-payouts/run-tenant-payouts-view/run-tenant-payouts-view.component";
import {
  DailyTransactionSummaryComponent
} from "./features/accounts/transactions/transactions-list-view/daily-transaction-summary/daily-transaction-summary.component";
import {
  TransactionsListViewComponent
} from "./features/accounts/transactions/transactions-list-view/transactions-list-view.component";
import {
  TransactionsScheduleComponent
} from "./features/accounts/transactions/transactions-list-view/transactions-schedule/transactions-schedule.component";
import {
  DashboardsBestStatsComponent
} from "./features/dashboards/common/dashboards-best-stats/dashboards-best-stats.component";
import {
  DashboardsChartSectionComponent
} from "./features/dashboards/common/dashboards-chart-section/dashboards-chart-section.component";
import {
  DashboardFootfallComponentComponent
} from './features/dashboards/dashboard-footfall-component/dashboard-footfall-component.component';
import {DashboardsLandingComponent} from "./features/dashboards/dashboards-landing.component";
import {
  DashboardsLeadsReportComponent
} from "./features/dashboards/dashboards-leads-report/dashboards-leads-report.component";
import {
  DashboardsLeasingReportComponent
} from "./features/dashboards/dashboards-leasing-report/dashboards-leasing-report.component";
import {
  DashboardsPerformanceReportComponent
} from "./features/dashboards/dashboards-performance-report/dashboards-performance-report.component";
import {
  DashboardsRetailerReportComponent
} from "./features/dashboards/dashboards-retailer-report/dashboards-retailer-report.component";
import {AddQuestionsComponent} from "./features/leads/application-form-builder/add-questions/add-questions.component";
import {
  QuestionConfigComponent
} from "./features/leads/application-form-builder/add-questions/question-config/question-config.component";
import {FormStepperComponent} from "./features/leads/application-form-builder/form-stepper/form-stepper.component";
import {
  ManageApplicationFormsComponent
} from './features/leads/application-form-builder/manage-application-forms/manage-application-forms.component';
import {NameFormComponent} from "./features/leads/application-form-builder/name-form/name-form.component";
import {
  PreviewApplicationFormComponent
} from './features/leads/application-form-builder/preview-application-form/preview-application-form.component';
import {
  QuestionWeightConfigComponent
} from './features/leads/application-form-builder/smart-form-configure/question-weight-config/question-weight-config.component';
import {
  SmartFormConfigureComponent
} from './features/leads/application-form-builder/smart-form-configure/smart-form-configure.component';
import {ApplicationsListComponent} from "./features/leads/applications/applications-list/applications-list.component";
import {
  ApplicantResultsComponent
} from "./features/leads/applications/applications-view/applicant-results/applicant-results.component";
import {ApplicationsViewComponent} from "./features/leads/applications/applications-view/applications-view.component";
import {InterestsListComponent} from "./features/leads/interests/interests-list/interests-list.component";
import {PipelineListComponent} from "./features/leads/pipeline/pipeline-list/pipeline-list.component";
import {PipelineViewComponent} from "./features/leads/pipeline/pipeline-view/pipeline-view.component";
import {
  CreateARetailerDialogComponent
} from "./features/leads/retailers/create-a-retailer/create-a-lease-dialog/create-a-retailer-dialog.component";
import {
  RetailerBillingDetailsComponent
} from "./features/leads/retailers/retailer-view/retailer-billing-details/retailer-billing-details.component";
import {
  RetailerDetailsComponent
} from "./features/leads/retailers/retailer-view/retailer-details/retailer-details.component";
import {
  RetailerEventsComponent
} from "./features/leads/retailers/retailer-view/retailer-events/retailer-events.component";
import {
  RetailerLeasingHistoryComponent
} from "./features/leads/retailers/retailer-view/retailer-leasing-history/retailer-leasing-history.component";
import {
  RetailerRolesAndPermissionsComponent
} from "./features/leads/retailers/retailer-view/retailer-roles-and-permissions/retailer-roles-and-permissions.component";
import {RetailerViewComponent} from "./features/leads/retailers/retailer-view/retailer-view.component";
import {RetailersListComponent} from "./features/leads/retailers/retailers-list/retailers-list.component";
import {
  CreateALeaseDialogComponent
} from "./features/leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {
  CreateASubLeaseDialogComponent
} from "./features/leasing/create-a-lease/create-a-sub-lease-dialog/create-a-sub-lease-dialog.component";
import {
  CurrentLeasesListComponent
} from "./features/leasing/current-leases/current-leases-list/current-leases-list.component";
import {
  LeaseOccupancyComponent
} from "./features/leasing/lease-planning/lease-planning-view/lease-occupancy/lease-occupancy.component";
import {
  LeasePlanningViewComponent
} from "./features/leasing/lease-planning/lease-planning-view/lease-planning-view.component";
import {
  LeaseSpaceInfoPanelComponent
} from "./features/leasing/lease-planning/lease-planning-view/lease-space-info-panel/lease-space-info-panel.component";
import {
  PlannedLeasesListComponent
} from "./features/leasing/lease-planning/lease-planning-view/planned-leases-list/planned-leases-list.component";
import {
  RetailersPipelineComponent
} from './features/leasing/lease-planning/lease-planning-view/retailers-pipeline/retailers-pipeline.component';
import {
  LeaseScheduleListComponent
} from "./features/leasing/lease-schedule/lease-schedule-list/lease-schedule-list.component";
import {LeaseDetailsComponent} from "./features/leasing/lease-view/lease-details/lease-details.component";
import {
  AddDocumentDialogComponent
} from "./features/leasing/lease-view/lease-documents/add-document-dialog/add-document-dialog.component";
import {LeaseDocumentsComponent} from "./features/leasing/lease-view/lease-documents/lease-documents.component";
import {LeasePosSettingsComponent} from "./features/leasing/lease-view/lease-pos-settings/lease-pos-settings.component";
import {
  PaymentDevicesSettingsDialogComponent
} from './features/leasing/lease-view/lease-pos-settings/payment-devices-settings-dialog/payment-devices-settings-dialog.component';
import {
  PosSoftwareSettingsDialogComponent
} from './features/leasing/lease-view/lease-pos-settings/pos-software-settings-dialog/pos-software-settings-dialog.component';
import {
  LeaseRatesAndChargesComponent
} from "./features/leasing/lease-view/lease-rates-and-charges/lease-rates-and-charges.component";
import {
  AddASpaceDialogComponent
} from "./features/leasing/lease-view/lease-spaces/add-a-space-dialog/add-a-space-dialog.component";
import {LeaseSpacesComponent} from "./features/leasing/lease-view/lease-spaces/lease-spaces.component";
import {LeaseSubLeasesComponent} from "./features/leasing/lease-view/lease-sub-leases/lease-sub-leases.component";
import {LeaseTurnoverComponent} from "./features/leasing/lease-view/lease-turnover/lease-turnover.component";
import {LeaseViewComponent} from "./features/leasing/lease-view/lease-view.component";
import {LoginComponent} from './features/login/login.component';
import {CheckYourEmailComponent} from './features/password-reset/check-your-email/check-your-email.component';
import {ForgotPasswordComponent} from './features/password-reset/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './features/password-reset/password-reset/password-reset.component';
import {
  VerifyUpdatePasswordOtpComponent
} from './features/password-reset/verify-update-password-otp/verify-update-password-otp.component';
import {SettingsDetailsComponent} from './features/settings/details/settings-details.component';
import {GlobalSettingsComponent} from './features/settings/global-settings/global-settings.component';
import {HardwareSettingsComponent} from './features/settings/hardware-settings/hardware-settings.component';
import {ManageListingComponent} from './features/settings/manage-listing/manage-listing.component';
import {
  UploadOfferingListingPhotoDialogComponent
} from './features/settings/manage-listing/upload-offering-listing-photo-dialog/upload-offering-listing-photo-dialog.component';
import {
  AssignedSuppliersDialogComponent
} from "./features/settings/payments/assigned-suppliers-dialog/assigned-suppliers-dialog.component";
import {PaymentsSettingsComponent} from "./features/settings/payments/payments-settings.component";
import {
  BankingDetailsComponent
} from './features/settings/settings-billing-and-banking/banking-details/banking-details.component';
import {
  BillingDetailsComponent
} from './features/settings/settings-billing-and-banking/billing-details/billing-details.component';
import {
  SettingsBillingAndBankingComponent
} from './features/settings/settings-billing-and-banking/settings-billing-and-banking.component';
import {
  VerifyBankingDetailsComponent
} from './features/settings/settings-billing-and-banking/verify-banking-details/verify-banking-details.component';
import {UsersAndAccessComponent} from './features/settings/users-and-access/users-and-access.component';
import {EditSingleSpaceComponent} from './features/spaces/edit-single-space/edit-single-space.component';
import {ListSpacesComponent} from './features/spaces/list-spaces/list-spaces.component';
import {ManageFloorPlanComponent} from './features/spaces/manage-floor-plan/manage-floor-plan.component';
import {ManageSpacesComponent} from './features/spaces/manage-spaces/manage-spaces.component';
import {CustomerSupportComponent} from "./features/support/customer-support/customer-support.component";
import {PosHelpCenterComponent} from "./features/support/pos-help-center/pos-help-center.component";
import {RequestPosDialogComponent} from "./features/support/request-pos-dialog/request-pos-dialog.component";
import {VerifyAccountComponent} from './features/verify-account/verify-account.component';
import {MaterialModule} from "./material.module";
import {ConfirmRemoveComponent} from "./shared/components/confirm-remove/confirm-remove.component";
import {ConfirmationDialogComponent} from './shared/components/confirmation-dialog/confirmation-dialog.component';
import {CsvImportComponent} from "./shared/components/csv-import/csv-import.component";
import {CustomDateFilterComponent} from './shared/components/custom-date-filter/custom-date-filter.component';
import {CustomRangeSelectorComponent} from "./shared/components/custom-range-selector/custom-range-selector.component";
import {
  CustomTimeRangePickerComponent
} from './shared/components/custom-time-range-picker/custom-time-range-picker.component';
import {ErrorPopupComponent} from './shared/components/dialogs/error-popup/error-popup.component';
import {IconPopupComponent} from './shared/components/dialogs/icon-popup/icon-popup.component';
import {IndustriesPickerComponent} from "./shared/components/industries-picker/industries-picker.component";
import {InfoPanelComponent} from "./shared/components/info-panel/info-panel.component";
import {
  LandlordPropertiesCarouselComponent
} from './shared/components/landlord-properties-carousel/landlord-properties-carousel.component';
import {PreAuthToolbarComponent} from "./shared/components/pre-auth-toolbar/pre-auth-toolbar.component";
import {ResponsiveDrawerComponent} from './shared/components/responsive-drawer/responsive-drawer.component';
import {StyledComponentsComponent} from "./shared/components/styled-components/styled-components.component";
import {TimelineComponent} from "./shared/components/timeline/timeline.component";
import {TemplateBuilderComponent} from './features/settings/template-builder/template-builder.component';
import {EmailEditorModule} from 'angular-email-editor';
import {
  EmailTemplateDesignerComponent
} from './features/settings/template-builder/email-template-designer/email-template-designer.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {BaseTemplateBuilderComponent} from './features/internal/base-template-builder/base-template-builder.component';
import {InternalFeaturesLandingComponent} from './features/internal/internal-features-landing.component';

registerLocaleData(localeZa, 'en-za');
moment.locale('en-za');

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    ActivePayoutGroups,
    AddASpaceDialogComponent,
    AddQuestionsComponent,
    AddDocumentDialogComponent,
    ApplicantResultsComponent,
    AppComponent,
    ApplicantResultsComponent,
    ApplicationsListComponent,
    ApplicationsListComponent,
    ApplicationsViewComponent,
    ApplicationsViewComponent,
    AssignedSuppliersDialogComponent,
    BankingDetailsComponent,
    BasePageComponent,
    BaseTemplateBuilderComponent,
    BillingDetailsComponent,
    CheckYourEmailComponent,
    ConfirmRemoveComponent,
    ConfirmationDialogComponent,
    CreateALeaseDialogComponent,
    CreateARetailerDialogComponent,
    CreateASubLeaseDialogComponent,
    CurrentLeasesListComponent,
    CustomDateFilterComponent,
    CustomRangeSelectorComponent,
    CustomTimeRangePickerComponent,
    CustomerSupportComponent,
    DailyTransactionSummaryComponent,
    DashboardsBestStatsComponent,
    DashboardsChartSectionComponent,
    DashboardFootfallComponentComponent,
    DashboardsLandingComponent,
    DashboardsLeadsReportComponent,
    DashboardsLeasingReportComponent,
    DashboardsPerformanceReportComponent,
    DashboardsRetailerReportComponent,
    EditSingleSpaceComponent,
    EmailTemplateDesignerComponent,
    ErrorPopupComponent,
    FooterLinksComponent,
    ForgotPasswordComponent,
    FormStepperComponent,
    GlobalSettingsComponent,
    GlobalSettingsComponent,
    HardwareSettingsComponent,
    HeaderLessTabDirective,
    HomeScreenComponent,
    IconPopupComponent,
    IndustriesPickerComponent,
    InfoPanelComponent,
    InterestsListComponent,
    InterestsListComponent,
    InternalFeaturesLandingComponent,
    LandlordPropertiesCarouselComponent,
    LeaseDetailsComponent,
    LeaseDocumentsComponent,
    LeaseOccupancyComponent,
    LeasePlanningViewComponent,
    LeasePosSettingsComponent,
    LeaseRatesAndChargesComponent,
    LeaseScheduleListComponent,
    LeaseSpaceInfoPanelComponent,
    LeaseSpacesComponent,
    LeaseSubLeasesComponent,
    LeaseTurnoverComponent,
    LeaseViewComponent,
    ListSpacesComponent,
    LoginComponent,
    ManageApplicationFormsComponent,
    ManageFloorPlanComponent,
    ManageListingComponent,
    ManageSpacesComponent,
    MyPropertiesLandingComponent,
    NameFormComponent,
    NotificationsComponent,
    NotificationsComponent,
    NotificationsDialogComponent,
    PageHeaderComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    PageTabContentComponent,
    PageTabNavigatorComponent,
    PasswordChangeOtpDialogComponent,
    PaymentBatchTransactionsComponent,
    PaymentBatchesListComponent,
    PaymentDevicesSettingsDialogComponent,
    PaymentGroupsComponent,
    PaymentsSettingsComponent,
    PayoutHistoryListComponent,
    PayoutJobcardComponent,
    PayoutMinimumAmountComponent,
    PipelineListComponent,
    PipelineListComponent,
    PipelineViewComponent,
    PipelineViewComponent,
    PlannedLeasesListComponent,
    PosHelpCenterComponent,
    PosSoftwareSettingsDialogComponent,
    PreAuthToolbarComponent,
    PreviewApplicationFormComponent,
    PrivacyPolicyComponent,
    ProductsJobcardComponent,
    QuestionConfigComponent,
    QuestionWeightConfigComponent,
    RequestPosDialogComponent,
    ResetPasswordComponent,
    ResponsiveDrawerComponent,
    RetailerBillingDetailsComponent,
    RetailerDetailsComponent,
    RetailerEventsComponent,
    RetailerLeasingHistoryComponent,
    RetailerRolesAndPermissionsComponent,
    RetailerViewComponent,
    RetailersListComponent,
    RetailersPipelineComponent,
    RunClientPayoutsViewComponent,
    RunProductsComponent,
    RunTenantPayoutsViewComponent,
    SettingsBillingAndBankingComponent,
    SettingsDetailsComponent,
    SmartFormConfigureComponent,
    StyledComponentsComponent,
    TemplateBuilderComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsComponent,
    TimelineComponent,
    TransactionsListViewComponent,
    TransactionsScheduleComponent,
    UploadOfferingListingPhotoDialogComponent,
    UploadUserProfileImageComponent,
    UserProfileDialogComponent,
    UserProfileDialogComponent,
    UserProfilePopoverComponent,
    UserProfilePopoverComponent,
    UsersAndAccessComponent,
    VerifyAccountComponent,
    VerifyBankingDetailsComponent,
    VerifyUpdatePasswordOtpComponent,
  ],
  entryComponents: [],
  exports: [
    FormsModule,
    InfoPanelComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    RouterOutlet,
    NgOtpInputModule,
    ClipboardModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places'],
    }),
    NgChartsModule,
    ImageCropperModule,
    GridsterModule,
    ScrollingModule,
    CsvImportComponent,
    EmailEditorModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [
    TabManagementService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'en-ZA'}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
