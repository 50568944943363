import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {UtilsService} from "../../../../shared/services/utils.service";
import {LeasePricingPeriod, PaymentMethod} from "../../leasing.model";
import {LeasingService} from "../../leasing.service";
import {LeaseViewService} from "../lease-view.service";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-lease-rates-and-charges',
  templateUrl: './lease-rates-and-charges.component.html',
  styleUrls: ['./lease-rates-and-charges.component.scss']
})
export class LeaseRatesAndChargesComponent implements OnInit {
  @Input() id!: string;
  @Output() reloadLease = new EventEmitter<void>();

  leasePricingPeriod!: LeasePricingPeriod;
  leasePricingPeriodOld!: LeasePricingPeriod;
  paymentMethods: PaymentMethod[] = [];
  isAddingTransactionSource: boolean = false;

  constructor(public utils: UtilsService, private leasingService: LeasingService, private leaseViewService: LeaseViewService, public loader: LoadingService) {
  }

  ngOnInit() {
    this.loadLeaseTransactionSource();
    this.loadPaymentMethods();
  }

  loadLeaseTransactionSource() {
    this.leaseViewService.getLeaseCurrentPricingPeriod(this.id).subscribe({
      next: value => {
        this.leasePricingPeriodOld =  {...value};
        this.leasePricingPeriod =  {...value};
      },
      error: err => console.log(err)
    })
  }

  loadPaymentMethods() {
    this.leaseViewService.getPaymentMethods(this.id).subscribe({
      next: value => {
        this.paymentMethods = value;
      },
      error: err => console.log(err)
    })
  }

  displayFeeType(type: string): string {
    if (type === 'RATE') return 'Turnover percentage';
    if (type === 'FIXED') return 'Fixed Rate';
    return '-';
  }

  applyTransactionFee(event: any) {
    this.leasePricingPeriod.applyTransactionFee = event.checked;
  }

  save() {
    if (this.leasePricingPeriod.applyTransactionFee === null || this.leasePricingPeriod.applyTransactionFee === undefined) {
      this.leasePricingPeriod.applyTransactionFee = false;
    }
    this.leaseViewService.savePricingChanges(this.leasePricingPeriod, this.id).subscribe({
      next: () => {
        this.loadLeaseTransactionSource();
        this.reloadLease.emit();
        this.isAddingTransactionSource = false;
      },
      error: err => console.log(err)
    })
  }

  cancel() {
    this.leasePricingPeriod = {...this.leasePricingPeriodOld};
    this.isAddingTransactionSource = false;
  }

  configChanged() {
    return this.leasePricingPeriodOld === this.leasePricingPeriod;
  }

  protected readonly PanelType = PanelType;
}
