import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {UtilsService} from "../../../../shared/services/utils.service";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {LeaseCreateMethod} from "../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {PipelineViewComponent} from "../pipeline-view/pipeline-view.component";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PipelineService} from "../pipeline.service";
import {FormControl, FormGroup} from "@angular/forms";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {DateRange} from "@angular/material/datepicker";
import {BaseChartDirective} from "ng2-charts";
import {ChartConfiguration, ChartData} from "chart.js";
import {IndustryCategory, IndustryType} from "../../industry.model";
import {barChartOptions, barChartPlugins} from "./chart-config.constant";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {Retailer} from "../../retailers/retailers.model";
import {GenericChart} from "../../../../shared/model/generic-chart.interface";
import {chartColors} from "../../../dashboards/chart-options/chart-colors.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {LoadingService} from "../../../../core/services/loading.service";
import {
  CreateALeaseDialogComponent
} from "../../../leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-pipeline-list',
  templateUrl: './pipeline-list.component.html',
  styleUrls: ['./pipeline-list.component.scss']
})
export class PipelineListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  displayedColumns: string[] = ['createDate', 'companyName', 'industryType', 'version', 'preferredAndPassed', 'firstRating', 'secondThirdRating', 'overallScore'];
  dataSource = new GenericDatasource<Retailer>(this.pipelineService);

  tableFilterForm: FormGroup;

  dateRange: DateRange<Date> = new DateRange<Date>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('all_time', 0, TimeFrames.DATE_RANGE, 'All time', false, new DateRange<Date>(new Date(this.offering!.offeringStartDate!), new Date())),
    new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
    new CustomDateRangeModel('last_month', 1, TimeFrames.MONTH, 'Last month', true),
  ]

  allIndustries: IndustryCategory[] = [];
  selectedIndustries: string[] = [];


  // @ts-ignore
  barChartOptions: ChartConfiguration['options'] = barChartOptions;
  barChartPlugins = barChartPlugins;
  chartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {data: []}
    ]
  }

  pipelineChartLoading: boolean = false;

  approvedRetailers: number = 0;
  rejectedRetailers: number = 0;

  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private tabService: TabManagementService,
              private paginatorService: PaginatorService,
              private pipelineService: PipelineService,
              private context: CurrentContextService,
              public loader: LoadingService,
              private matDialog: MatDialog) {
    super(injector);

    this.tableFilterForm = new FormGroup({
      companyName: new FormControl(null),
      industryTypes: new FormControl([]),
      usesActiveForm: new FormControl(null),
      preferredAndPassedAbove: new FormControl(null),
      overallScoreAbove: new FormControl(null)
    })

    context.getCurrentOfferingId()
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<Retailer>(this.pipelineService);
    this.dataSource.loadData('/qualified-applicants', {
      size: '50',
      page: '0',
      sort: ['overallScore,desc', 'retailerRegistrationDate,desc']
    }, []);
    this.loadIndustries();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadPipeline()))
      .subscribe();

    this.tableFilterForm.valueChanges.subscribe(() => this.loadPipeline());
  }

  loadPipeline() {
    const offeringType = this.tableFilterForm.get('offeringType')?.value;

    const filters = [
      {name: 'offeringType', val: offeringType && offeringType.length > 0 ? offeringType.join(',') : null},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange?.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
      {name: 'industries', val: this.tableFilterForm.get('industryTypes')?.value},
      {name: 'companyName', val: this.tableFilterForm.get('companyName')?.value},
      {name: 'usesActiveForm', val: this.tableFilterForm.get('usesActiveForm')?.value},
      {name: 'preferredAndPassedAbove', val: this.tableFilterForm.get('preferredAndPassedAbove')?.value},
      {name: 'overallScoreAbove', val: this.tableFilterForm.get('overallScoreAbove')?.value},
    ]
    const page = {
      size: this.paginator ? this.paginator.pageSize.toString() : '50',
      page: this.paginator ? this.paginator.pageIndex.toString() : '0',
      sort: ['overallScore,desc', 'retailerRegistrationDate,desc']
    };
    this.dataSource.loadData('/qualified-applicants', page, filters);
  }

  loadChartData() {
    this.pipelineChartLoading = true;
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange?.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null}
    ]
    this.pipelineService.getChartData(filters).subscribe({
      next: (value: GenericChart) => {
        const industryData = value.chartSeries[0].chartData;
        this.approvedRetailers = Object(value.params)['approvedRetailers'];
        this.rejectedRetailers = Object(value.params)['rejectedRetailers'];
        this.chartData = {
          labels: industryData.map(m => this.getLabels(this.getIndustryCategoryName(m.label))),
          datasets: [
            {data: industryData.map(m => m.data), backgroundColor: chartColors.BLUE, borderColor: chartColors.BLUE, borderRadius: 5},
          ]
        }
        this.pipelineChartLoading = false;
      },
      error: err => {
        console.log(err);
        this.pipelineChartLoading = false;
      }
    })
  }

  loadIndustries() {
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
        this.loadChartData();
      }
    })
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
        this.updateSelected();
      }
    });
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
  }

  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  openPipelineViewTab(id: string) {
    let payload: PageEventInterface = {
      componentToRegister: PipelineViewComponent,
      pageName: 'Pipeline ' + id,
      pageHeader: this.offering!.label,
      data: { id: id },
      id: this.utils.generateUuidWithPrefix('leads'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'leads',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadPipeline();
    this.loadChartData();
  }

  // chart

  getLabels(label: string) {
    let arr = [];
    let str = '';
    label.split(' ').forEach(x => {
      if (str !== '' && (str + ' ' + x).length > 10) {
        arr.push(str);
        str = '';
      }
      str = (str + ' ' + x).trim();
    });

    if (str !== '') {
      arr.push(str);
    }
    return arr;
  }

  getApplicationVersion(retailer: Retailer) {
    switch (retailer.usesActiveForm) {
      case true:
        return {
          status: this.utils.displayStatus('Current'),
          icon: 'check_circle',
          outline: false,
          className: 'success-chip'
        };
      case false:
        return {
          status: this.utils.displayStatus('Previous'),
          icon: 'check_circle',
          outline: false,
          className: 'warn-chip'
        };
    }
    return null;
  }

  displayIndustryList(listString: string) {
    if (listString) {
      const list = listString.split(',').map(m => this.utils.displayStatus(m));
      return this.utils.displayListAsString(list);
    }
    return null;
  }

  getIndustryCategoryName(code: string) {
    return this.allIndustries.filter(f => f.code === code)[0].name;
  }

  openCreateLease(): void {
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: LeaseCreateMethod.NEW_LEASE,
        lease: null,
        header: 'Create a new lease',
        offering: this.data.offering
      }
    })
  }

}
