import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {
  ControlPayload,
  FormQuestionFilter,
  NumberType,
  Question,
  QuestionTemplate,
  SelectType
} from "../../../../../core/model/retailer/application-form.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {ApplicationsService} from "../../../applications/applications.service";
import {FormBuilderService} from "../../form-builder.service";
import {
  ComponentType,
  ControlType,
  FormFileValue,
  QuestionItem,
  templateComponents
} from "../question-components.model";

@Component({
  selector: 'app-question-config',
  templateUrl: './question-config.component.html',
  styleUrls: ['./question-config.component.scss']
})
export class QuestionConfigComponent {
  @Input() selectedItem!: QuestionItem | null;
  @Input() newFiles!: Map<string, { qUuid: string, file: File, fileCode: string }[]>;
  @Input() questionFilter!: FormQuestionFilter;
  @Output() itemChange = new EventEmitter<QuestionItem>();
  @Output() saveForm = new EventEmitter<boolean>();
  @Output() previewForm = new EventEmitter<boolean>();
  @Output() questionTypeChange = new EventEmitter();

  protected readonly ControlType = ControlType;
  protected readonly templateComponents = templateComponents;

  numberTypes = Object.keys(NumberType);
  constructor(public dateUtils: DateUtilsService, public utils: UtilsService, public formBuilderService: FormBuilderService, private applicationService: ApplicationsService) {
  }

  saveFormTemplate(next: boolean) {
    this.saveForm.emit(next);
  }

  itemChanged() {
    this.itemChange.emit({...this.selectedItem!});
  }

  toggleMultiSelect(event: MatCheckboxChange) {
    this.getQuestionTemplate.controlPayload.selectType = event.checked ? SelectType.MULTI : SelectType.SINGLE;
    this.itemChanged();
  }

  addOptionToQuestion() {
    const currentOptions = this.getQuestionControlPayload.options!;
    this.getQuestionControlPayload.options!.push({
      label: 'Answer ' + (currentOptions.length + 1).toString(),
      valueCode: 'answer_' + (currentOptions.length + 1).toString(),
    });
    this.itemChanged();
  }

  addFileToQuestion() {
    const currentOptions = this.getQuestionControlPayload.files!;
    this.getQuestionControlPayload.files!.push({
      label: 'File ' + (currentOptions.length + 1).toString(),
      valueCode: 'file_' + (currentOptions.length + 1).toString(),
    });
    this.itemChanged();
  }

  toggleFileTypes(event: MatCheckboxChange, type: 'image' | 'document' | 'dataFile') {
    if (event.checked) {
      if (type == 'dataFile') {
        this.getQuestionControlPayload.fileTypes = [...this.fileExtensions[type]];
      } else {
        if (this.includesFileTypes(this.fileExtensions['dataFile'])) {
          this.getQuestionControlPayload.fileTypes = this.getQuestionControlPayload.fileTypes!.filter(f => !this.fileExtensions['dataFile'].includes(f));
        }
        if (!this.includesFileTypes(this.fileExtensions[type])) {
          this.getQuestionControlPayload.fileTypes?.push(...this.fileExtensions[type]);
        }
      }
    } else {
      if (this.includesFileTypes(this.fileExtensions[type])) {
        this.getQuestionControlPayload.fileTypes = this.getQuestionControlPayload.fileTypes!.filter(f => !this.fileExtensions[type].includes(f));
      }
    }
  }

  includesFileTypes(types: string[]): boolean {
    return types.every(e => this.getQuestionControlPayload.fileTypes!.includes(e));
  }

  downloadFormFile(fileValue: FormFileValue, event: HTMLInputElement) {
    const anchor = document.createElement('a');
    let fileURL = "";
    if (!!fileValue.fileUuid && fileValue.fileUuid.length > 0) {
      this.questionFilter.fileCode = fileValue.fileCode;
      this.formBuilderService.downloadFormFile(fileValue.fileUuid, this.questionFilter).subscribe({
        next: (value) => {
          const type = ('application/' + fileValue.fileType).toString();
          const file = new Blob([value], { type });
          fileURL = URL.createObjectURL(file);
        },
        error: err => console.log(err)
      })
    } else {
      const file = event.files!.item(0)!
      fileURL = window.URL.createObjectURL(file);
    }
    anchor.download = fileValue.fileName;
    anchor.href = fileURL;
    anchor.click();
  }

  get getQuestion(): Question {
    return this.selectedItem!.questionConfig!.questions[0];
  }

  get getQuestionTemplate(): QuestionTemplate {
    return this.selectedItem!.questionConfig!.questions[0].questionTemplate!;
  }

  get getQuestionControlPayload(): ControlPayload {
    return this.selectedItem!.questionConfig!.questions[0].questionTemplate.controlPayload!;
  }

  get getControlType(): ControlType {
    return this.selectedItem!.template.controlType;
  }

  get getComponentType(): ComponentType {
    return this.selectedItem!.template.type;
  }

  get canBeScored(): boolean {
    return this.selectedItem!.template.canScore;
  }

  protected readonly SelectType = SelectType;

  sendPreviewForm(): void {
    this.previewForm.emit(true);
  }

  changeValue(event: any, fileCode: string) {
    const value = JSON.stringify(this.fileChangeEvent(event, this.getQuestionTemplate, fileCode, this.getQuestion));
    this.getQuestion.value = value;
  }

  fileChangeEvent(event: any, qt: QuestionTemplate, fileCode: string, q: Question): FormFileValue[] {
    const fileToUpload: File = event.target.files.item(0);

    if (!this.newFiles.has(qt.uuid)) {
      this.newFiles.set(qt.uuid, [{ qUuid: qt.uuid, file: fileToUpload, fileCode: fileCode }])
    } else {
      const questionFiles = this.newFiles.get(qt.uuid)!;

      const questionIndex = questionFiles.findIndex(f => f.fileCode == fileCode);
      if (questionIndex > -1) {
        questionFiles[questionIndex].file = fileToUpload;
      } else {
        questionFiles.push({ qUuid: qt.uuid, file: fileToUpload, fileCode: fileCode });
      }
    }

    const currentValue = this.getQuestionFileValues() && this.getQuestionFileValues().length > 0 ? this.getQuestionFileValues() : [];
    const currentIndex = currentValue.findIndex(f => f.fileCode == fileCode);

    if (currentIndex > -1) {
      currentValue[currentIndex] = { fileName: fileToUpload.name, fileUuid: '', fileType: fileToUpload.type, fileCode: fileCode};
    } else {
      currentValue.push({ fileName: fileToUpload.name, fileUuid: '', fileType: fileToUpload.type, fileCode: fileCode});
    }
    return currentValue;
  }

  getQuestionFileValues(): FormFileValue[] {
    if (this.getQuestion.value != null) return JSON.parse(this.getQuestion.value);
    return [];
  }

  getQuestionFileValue(fileCode: string): FormFileValue {
    const fileValue = this.getQuestionFileValues().filter(f => f.fileCode == fileCode);
    if (fileValue.length > 0) {
      return fileValue[0];
    }
    return { fileName: 'Upload file', fileUuid: '', fileType: '', fileCode: fileCode};
  }

  showExtensions(array: string[]) {
    return this.utils.displayListAsString(array).replaceAll(',', '');
  }

  get fileExtensions() {
    return this.formBuilderService.fileExtensions;
  }

  protected readonly ComponentType = ComponentType;
}
