<div fxLayout="column">
  <app-page-header [section]="'Hardware'"
                   [title]="'Setup or link your hardware'"
                   [description]="'The hardware module allows you to setup and configure your offering. This configuration\n'+
'automatically integrates across all other features like leasing.'">
  </app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

    <div fxFlexOffset="10px">
      <div fxLayout="row">
        <span class="subtitle-default">Point of sale card readers</span>
        <div fxLayoutGap="10px" fxFlexOffset="30px" *ngIf="isInternalUser">
          <button class="button-action" mat-flat-button (click)="addCardReader()" [disabled]="loader.loading$ | async">
            <div class="button-text">Add</div>
          </button>
          <button [disabled]="!(cardReaderSelectionModel.selected.length > 0) || (loader.loading$ | async)" class="button-action" mat-flat-button
                  (click)="removeSelectedCardReader()">
            <div class="button-text">Remove</div>
          </button>
        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">

      <span class="heading-default">Point of sale card readers</span>
      <!--    Edit row-->
      <div class="edit-table-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px"
           fxFlexOffset="20px" *ngIf="addingCard === true">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div class="body-1-primary">NEW_ID</div>
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Card Reader code</mat-label>
            <input matInput type="text" placeholder="Card reader number"
                   [formControl]="cardReaderValue"/>
          </mat-form-field>
        </div>

        <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <button (click)="saveNewCard()" class="button-primary" mat-flat-button>
            <mat-icon>check</mat-icon>
            <div class="button-text">Save</div>
          </button>

          <button class="button-close-top" mat-icon-button (click)="addingCard = false" [disabled]="loader.loading$ | async">
            <mat-icon>clear</mat-icon>
          </button>
        </div>

      </div>
      <!--Filters-->
      <form *ngIf="addingCard === false" [formGroup]="filterForm" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px" fxFlexOffset="20px">
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>ID</mat-label>
            <input matInput type="text" placeholder="search here" formControlName="uuid"/>
          </mat-form-field>
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Card Reader code</mat-label>
            <input matInput type="text" placeholder="search here"
                   formControlName="cardReaderCode"/>
          </mat-form-field>
        </div>
      </form>

      <!-- table start-->
      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length">Card
            Readers
          </th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID</th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
              *matCellDef="let element"> {{utilsService.displayUuid(element.uuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="cardReaderCode">
          <th mat-header-cell *matHeaderCellDef>Card Reader Code</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{element.cardReaderCode}}</td>
        </ng-container>

      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" class="custom-table-cell" mat-cell>
          <mat-checkbox (change)="$event ? cardReaderSelectionModel.toggle(row) : null"
                        (click)="$event.stopPropagation()" *ngIf="row.uuid"
                        [checked]="cardReaderSelectionModel.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"
          [class.selected-row]="selectedReader === row.uuid"
          (click)="setSelectedReader(row.uuid)"></tr>
    </table>

      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>
