import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommunicationDialogInterface} from '../../../interfaces/communication-dialog.interface';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {
  form?: FormGroup;

  header: string;
  description: string;
  boldDescription: string;
  imageSrc: string;

  constructor(
    private dialogRef: MatDialogRef<ErrorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data : CommunicationDialogInterface
  ) {
    this.header = data.header;
    this.description = data.description;
    this.boldDescription = data.boldDescription;
    this.imageSrc = '../../assets/icons/actions/error_exclamation.svg';
  }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close(this.form?.value);
  }

  close(): void {
    this.dialogRef.close();
  }
}
