import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/shared/services/base.service';
import {environment} from 'src/environments/environment';
import {PasswordUpdate} from '../model/password-update.model';
import {RequestPasswordUpdateOTP} from '../model/request-password-update-otp.model';
import {UPDATE_PASSWORD_OTP_TYPE} from '../model/update-password-otp-type.enum.model';
import {FormGroup} from '@angular/forms';
import {UpdatePasswordContextModel} from './update-password-context.model';
import {VerifyOTPRequest} from '../../../core/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService extends BaseService {

  private baseUrl: string = environment.apiSecurityHost + '/api/user/security/password';
  private baseUserUrl: string = environment.apiSecurityHost + '/api/user';
  private currentContext: UpdatePasswordContextModel;

  constructor(private http: HttpClient) {
    super();

    this.clearContext();
    this.currentContext = UpdatePasswordContextModel.getEmptyContext();
  }

  public requestForgotPasswordOTP(otpRequest: RequestPasswordUpdateOTP, retainContext?: boolean): Observable<any> {
    if (!retainContext) {
      this.setOtpRequest(otpRequest);
    }
    if (otpRequest.email) {
      this.currentContext.email = otpRequest.email;
      this.currentContext.updateType = UPDATE_PASSWORD_OTP_TYPE.FORGOT_PASSWORD;
    }
    const uri = this.baseUrl + '/otp/forgot';
    return this.http.post(uri, {email: this.currentContext?.email}, this.options);
  }

  public requestUpdatePasswordOTP(otpRequest: RequestPasswordUpdateOTP, retainContext?: boolean): Observable<any> {
    if (!retainContext) {
      this.setOtpRequest(otpRequest);
    }
    this.currentContext.updateType = UPDATE_PASSWORD_OTP_TYPE.UPDATE_PASSWORD;

    const uri = this.baseUrl + '/otp/update';
    return this.http.post(uri, { userId: this.currentContext?.userId }, this.options);
  }

  public requestNewOTP(retainContext?: boolean): Observable<any> {
    retainContext = retainContext ? true : false;

    switch (this.currentContext!.updateType) {
      case UPDATE_PASSWORD_OTP_TYPE.FORGOT_PASSWORD:
        return this.requestForgotPasswordOTP({ email: this.currentContext!.email }, retainContext);
      case UPDATE_PASSWORD_OTP_TYPE.UPDATE_PASSWORD:
        return this.requestUpdatePasswordOTP({ userId: this.currentContext!.userId }, retainContext);
      default: throw new Error('Unexpected operation');
    }


  }

  /**
   * Update the user's password as the final step to the forgot password or update password flow
   *
   * Note: the payload is built automatically from the update password context that must be set before this service is called.
   * The fields required to do an update are: otp, secret, confirmSecret, [userId | email]
   */
  public updatePassword(): Observable<any> {
    const update = new PasswordUpdate({
      otp: this.currentContext?.otp,
      otpType: this.currentContext?.updateType,
      secret: this.currentContext?.secret,
      secretConfirm: this.currentContext?.confirmSecret,
      user: {
        email: this.currentContext?.email ? this.currentContext.email : null,
        userId: this.currentContext?.userId ? this.currentContext.userId : null
      },
    });

    // if (!update.user || !update.user.userId)
    return this.http.post(this.baseUrl, update, this.options);
  }


  public clearContext() {
    this.currentContext = UpdatePasswordContextModel.getEmptyContext();
  }

  public setContext(value: any) {
    this.currentContext = value;
  }

  public updateContextSecrets(secret: string, confirmSecret: string) {
    this.currentContext.secret = secret;
    this.currentContext.confirmSecret = confirmSecret;
  }

  updateOtp(otp: string): void {
    this.currentContext!.otp = otp;
  }

  public passwordInvalid(passwordResetForm: FormGroup): boolean {
    const secret = passwordResetForm.value.password;
    const confirmSecret = passwordResetForm.value.confirmPassword;

    if (
      (secret.length < 8)
      || (/[A-Z]+/g.test(secret) === false)
      || (/[a-z]+/g.test(secret) === false)
      || (/[0-9]+/g.test(secret) === false)
      || (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(secret) === false)
    ) {
      passwordResetForm.controls['password'].setErrors({'not-valid': true});
      passwordResetForm.get('confirmPassword')!.setValue(null);
      passwordResetForm.controls['confirmPassword'].setErrors(null);
      return true;
    }

    if (secret !== confirmSecret) {
      passwordResetForm.get('confirmPassword')!.setValue(null);
      passwordResetForm.controls['confirmPassword'].setErrors({'no-match': true});
      return true;
    }

    return false;
  }

  newPasswordRestContext(secret: string, confirmSecret: string, email: string, userId: number): void {
    this.currentContext.secret = secret;
    this.currentContext.confirmSecret = confirmSecret;
    this.currentContext.email = email;
    this.currentContext.userId = userId;
    this.currentContext.updateType = UPDATE_PASSWORD_OTP_TYPE.UPDATE_PASSWORD;
    this.currentContext.otp = null;
  }

  public setOtpRequest(value: RequestPasswordUpdateOTP) {
    if (value.email) {
      this.currentContext.email = value.email;
    }
    if (value.userId) {
      this.currentContext.userId = value.userId;
    }
  }

  public verifyNewUserAccount(request: VerifyOTPRequest): Observable<any> {
    const uri = this.baseUserUrl + '/verify';
    return this.http.post(uri, request, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public requestOtp(id: number): Observable<any> {
    const uri = this.baseUserUrl + '/' + id + '/verify/otp/resend';
    return this.http.post(uri, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }


}
