import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {ConfigType} from "../../../../support/request-pos-dialog/request-pos.model";
import {LinkedRetailer, PaymentBatch, PaymentBatchMembership} from "../../../transactions/transactions.model";
import {BatchType} from "../../model/run-payouts.model";

@Component({
  selector: 'app-active-payout-groups',
  templateUrl: './active-payout-groups.component.html',
  styleUrls: ['./active-payout-groups.component.scss']
})
export class ActivePayoutGroups implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('linkedPaginator') linkedPaginator!: MatPaginator;
  @Input() groupType!: ConfigType;
  @Input() data: PaymentBatchMembership[] = [];
  @Input() offering!: PageOffering;

  paymentGroupDatasource = new MatTableDataSource<PaymentBatchMembership>();
  paymentGroupColumns = ['select', 'name', 'lastRunDate', 'runDateRange'];

  linkedRetailerDatasource = new MatTableDataSource<LinkedRetailer>();
  linkedRetailerColumns = ['companyName', 'leaseStartDate', 'leaseEndDate'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  selectedBatch: PaymentBatch | null = null;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.linkedPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paymentGroupDatasource.data = this.data;
    this.paymentGroupDatasource.paginator = this.paginator;
    this.generateDateRanges();
  }

  generateDateRanges() {
    this.data.forEach(ea => {
      ea.dateFilter = [
        new CustomDateRangeModel('up_to', 1, TimeFrames.BOUNDARY, 'Up to', false, new DateRange<Date>(new Date(this.offering!.offeringStartDate!), new Date())),
        new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
        new CustomDateRangeModel('all_time', 0, TimeFrames.DATE_RANGE, 'All time', false, new DateRange<Date>(new Date(this.offering!.offeringStartDate!), new Date())),
      ];
    })
  }

  getDateRange(event: CustomDateRangeChangeEventModel, el: PaymentBatchMembership) {
    el.startDate = this.dateUtils.displayShortDate(event.dateRange.start)!;
    el.endDate = this.dateUtils.displayShortDate(event.dateRange.end)!;
  }

  selectGroup(group: PaymentBatchMembership) {
    group.selected = !group.selected;
  }

  viewGroup(group: PaymentBatchMembership) {
    this.linkedRetailerDatasource.data = group.linkedRetailers;
    this.linkedRetailerDatasource.paginator = this.linkedPaginator;
  }

  get configLabel() {
    switch (this.groupType) {
      case ConfigType.VEND:
        return 'Single Leases';
      case ConfigType.VEND_SUPPLIER_LEASE:
        return 'Shared Leases';
      case ConfigType.VEND_SUPPLIER_OFFERING:
        return 'Offering POS';
      case ConfigType.POSTER:
        return 'Single Leases'
    }
  }

  get selectedGroups() {
    return this.data.filter(f => f.selected).length;
  }

  protected readonly BatchType = BatchType;
}
