import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {GenericService, PageRequest} from '../../../../shared/services/generic.service';
import {
  AddLeaseToBatchRequest,
  LeaseNotInPaymentBatch,
  LinkedRetailer,
  PaymentBatch,
  PaymentBatchMembership
} from '../../transactions/transactions.model';

@Injectable({
  providedIn: 'root'
})
export class RunPayoutsConfigService extends GenericService {
  httpParams: HttpParams = new HttpParams();
  areThereNewRetailers = new BehaviorSubject<boolean>(false);


  constructor(http: HttpClient) {
    super(http, environment.apiTransactHost + '/console/offering/district/accounts/run-tenant-payouts/config');
    this.httpParams.set('Content-Type', 'application/json');
    this.httpParams.set('Accept', 'application/json');
  }

  get newRetailers(): Observable<boolean> {
    this.getLeaseNotInGroup()
      .subscribe((res: { content: LeaseNotInPaymentBatch[] }) => {
        if (res.content.length > 0) {
          this.areThereNewRetailers.next(true);
        } else {
          this.areThereNewRetailers.next(false);
        }
      });
    return this.areThereNewRetailers.asObservable();
  }

  createNewPaymentBatch(batchName: string, parentUuid: string | null, configType: string): Observable<PaymentBatch> {
    return this.http.post<PaymentBatch>(`${this.baseUrl}/new-batch`, {
      batchName,
      parentUuid,
      configType
      }, {params: this.httpParams}
    );
  }

  getActiveBatchConfigs(page: PageRequest): Observable<{ content: PaymentBatchMembership[] }> {
    let requestParams = new HttpParams()
      .set('size', page.size)
      .set('page', page.page)
      .set('active', true);
    return this.http.get<{ content: PaymentBatchMembership[] }>(`${this.baseUrl}/membership/filtered`, {params: requestParams});
  }

  removeLeaseFromBatch(element: LinkedRetailer): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${element.paymentGroupUuid}/${element.leaseUuid}`);
  }

  addLeaseToPaymentBatch(request: AddLeaseToBatchRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/membership`, request, {params: this.httpParams});
  }

  toggleGroupActive(element: PaymentBatchMembership): Observable<void> {
    let request = {
      active: !element.active,
      uuid: element.uuid
    }
    return this.http.post<void>(`${this.baseUrl}/membership/toggle-active`, request, {params: this.httpParams})
  }

  getLeaseNotInGroup(): Observable<{ content: LeaseNotInPaymentBatch[] }> {
    return this.http.get<{ content: LeaseNotInPaymentBatch[] }>(`${this.baseUrl}/no-membership`);
  }
}
