import {HttpClient} from "@angular/common/http";
import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateRange, MatDatepicker} from "@angular/material/datepicker";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {LocationOffering} from "../../../core/model/side-nav.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CustomDateRangeModel} from "../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {DashboardChart, MonthlyStat, RetailerPerformance, StatisticType} from "../dashboards.model";
import {
  build as buildDashboardService,
  DashboardsPerformanceReportService
} from "./dashboards-performance-report.service";
import {PerformanceDashboardChartConfig} from "./performance-dashboard-chart-config";

@Component({
  selector: 'app-dashboards-leads-report',
  templateUrl: './dashboards-performance-report.component.html',
  styleUrls: ['./dashboards-performance-report.component.scss']
})
export class DashboardsPerformanceReportComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('topPerformersPaginator') topPaginator!: MatPaginator;
  @ViewChild('sortSales', { read: MatSort, static: false }) set salesContent(sort: MatSort) {
    if (this.topPerformersBySalesDataSource) this.topPerformersBySalesDataSource.sort = sort;
  }
  @ViewChild('sortTd', { read: MatSort, static: false }) set tdContent(sort: MatSort) {
    if (this.topPerformersByTradingDensityDataSource) this.topPerformersByTradingDensityDataSource.sort = sort;
  }


  chartConfig: PerformanceDashboardChartConfig;
  dashboardService: DashboardsPerformanceReportService;

  // best stats

  bestMonthlyStats: MonthlyStat[] = [];

  // dashboard charts
  salesPerformanceChart: DashboardChart<'bar' | 'line'>;
  salesComparisonChart!: DashboardChart<'line'>;
  salesByIndustryChart!: DashboardChart<'radar'>;
  tradingDensityByIndustryChart!: DashboardChart<'radar'>;
  busiestSalesTimesChart!: DashboardChart<'bar'>;
  busiestSalesDaysChart!: DashboardChart<'bar'>;
  footfallStatisticsChart!: DashboardChart<'bar'>;

  // dashboard tables

  topPerformersBySalesDataSource!: MatTableDataSource<RetailerPerformance>;
  topPerformersByTradingDensityDataSource!: MatTableDataSource<RetailerPerformance>;

  topPerformersLoading: boolean = false;

  displayedColumnsBySales: string[] = ['retailerLogo', 'retailerCompanyName', 'industryCategoryCode', 'saleAmount', 'contributingPercentage'];
  displayedColumnsByTradingDensity: string[] = ['retailerLogo', 'retailerCompanyName', 'industryCategoryCode', 'tradingDensity'];

  // filtering

  dateRanges: DateRange<Date | null>[] = [
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null)
  ];

  month2: Date = new Date();
  month1: Date = new Date(this.month2.getFullYear(), this.month2.getMonth() - 1, 1);

  customDateRangePresets1: CustomDateRangeModel[] = [];
  customDateRangePresets2: CustomDateRangeModel[] = [];
  dbOffering!: LocationOffering;

  topPercentSalesOptions: number[] = [20, 40, 60, 80, 100];
  topFilter: number = 100;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              injector: Injector,
              chartOptionsService: ChartOptionsService,
              httpClient: HttpClient,
              public dashboardUtils: DashboardUtilsService) {
    super(injector);
    this.dashboardService = buildDashboardService(httpClient);
    this.dbOffering = this.data.offering;
    this.chartConfig = new PerformanceDashboardChartConfig(this, chartOptionsService, dashboardUtils);

    this.salesPerformanceChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.salesPerformance.options,
      chartType: 'line',
      stats: {
        totalSales: new MonthlyStat('Sales', 'totalSales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
        avgTradingDensity: new MonthlyStat('Trading density', 'avgTradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
      },
      loading: false
    };

    this.salesComparisonChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.salesComparison.options,
      chartType: 'line',
      stats: {
        month1Sales: new MonthlyStat('Sales in ' + this.dateUtils.displayFullMonthAndYear(this.month1), 'month1Sales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
        month2Sales: new MonthlyStat('Sales in ' + this.dateUtils.displayFullMonthAndYear(this.month2), 'month2Sales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.salesByIndustryChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.salesByIndustry.options,
      chartType: 'radar',
      stats: {
        totalSales: new MonthlyStat('Sales', 'totalSales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.tradingDensityByIndustryChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.tradingDensityByIndustry.options,
      chartType: 'radar',
      stats: {
        avgTradingDensity: new MonthlyStat('Average trading density', 'avgTradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
      },
      loading: false
    };

    this.busiestSalesTimesChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.busiestSalesTimes.options,
      chartType: 'bar',
      stats: {
        salesPerHour: new MonthlyStat('Hourly Sales', 'salesPerHour', 'Average sales made per hour', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.busiestSalesDaysChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.busiestSalesDays.options,
      chartType: 'bar',
      stats: {
        salesPerDay: new MonthlyStat('Daily Sales', 'salesPerDay', 'Average sales made per day', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.footfallStatisticsChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.footfallStatistics.options,
      chartType: 'bar',
      stats: {
        footfallConversion: new MonthlyStat('Footfall Conversion', 'footfallConversion', 'Percentage of walkway instances converted into the store', StatisticType.PERCENTAGE),
        salesConversion: new MonthlyStat('Sales Conversion', 'salesConversion', 'Percentage of store instances converted into sales', StatisticType.PERCENTAGE),
      },
      loading: false
    };
  }

  ngOnInit() {
    this.loadBestMonthlyStats();
    this.loadSalesComparisonChart();
    this.setCustomDateRange();
  }

  ngOnDestroy() {
    this.dashboardService.flush();
  }

  setCustomDateRange() {
    this.customDateRangePresets1 = this.dashboardUtils.customDateRangeChartPreset(this.dbOffering.offeringStartDate!);
    this.customDateRangePresets2 = this.dashboardUtils.customDateRangeTablePreset(this.dbOffering.offeringStartDate!);
  }

  loadBestMonthlyStats() {
    const start = this.dbOffering.offeringStartDate!;
    const end = new Date();
    const next = this.chartConfig.bestMonthlyStats.next;
    const error = this.chartConfig.bestMonthlyStats.error;
    this.dashboardService.refreshBestMonthlyStats(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadSalesPerformanceChart() {
    this.salesPerformanceChart.loading = true;
    const start = this.dateRanges[0].start ? this.dateRanges[0].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[0].end ? this.dateRanges[0].end : new Date();
    const next = this.chartConfig.salesPerformance.next;
    const error = this.chartConfig.salesPerformance.error;
    this.dashboardService.refreshSalesPerformanceChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadSalesComparisonChart() {
    this.salesComparisonChart.loading = true;
    const start = this.month1;
    const end = this.month2;
    const next = this.chartConfig.salesComparison.next;
    const error = this.chartConfig.salesComparison.error;
    this.dashboardService.refreshSalesComparisonChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadSalesByIndustryChart() {
    this.salesByIndustryChart.loading = true;
    const start = this.dateRanges[1].start ? this.dateRanges[1].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[1].end ? this.dateRanges[1].end : new Date();
    const next = this.chartConfig.salesByIndustry.next;
    const error = this.chartConfig.salesByIndustry.error;
    this.dashboardService.refreshSalesByIndustryChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadTradingDensityByIndustryChart() {
    this.tradingDensityByIndustryChart.loading = true;
    const start = this.dateRanges[1].start ? this.dateRanges[1].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[1].end ? this.dateRanges[1].end : new Date();
    const next = this.chartConfig.tradingDensityByIndustry.next;
    const error = this.chartConfig.tradingDensityByIndustry.error;
    this.dashboardService.refreshTradingDensityByIndustryChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadTopPerformersTable() {
    this.topPerformersLoading = true;
    const start = this.dateRanges[2].start ? this.dateRanges[2].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[2].end ? this.dateRanges[2].end : new Date();
    const topPercent = this.topFilter;
    const next = this.chartConfig.topPerformers.next;
    const error = this.chartConfig.topPerformers.error;
    this.dashboardService.refreshTopPerformersTable(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, topPercent, next, error);
  }

  loadBusiestSalesTimesChart() {
    this.busiestSalesTimesChart.loading = true;
    const start = this.dateRanges[3].start ? this.dateRanges[3].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[3].end ? this.dateRanges[3].end : new Date();
    const next = this.chartConfig.busiestSalesTimes.next;
    const error = this.chartConfig.busiestSalesTimes.error;
    this.dashboardService.refreshBusiestSalesTimesChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadBusiestSalesDaysChart() {
    this.busiestSalesDaysChart.loading = true;
    const start = this.dateRanges[3].start ? this.dateRanges[3].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[3].end ? this.dateRanges[3].end : new Date();
    const next = this.chartConfig.busiestSalesDays.next;
    const error = this.chartConfig.busiestSalesDays.error;
    this.dashboardService.refreshBusiestSalesDaysChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadFootfallStatisticsChart() {
    this.footfallStatisticsChart.loading = true;
    const start = this.dateRanges[4].start ? this.dateRanges[4].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[4].end ? this.dateRanges[4].end : new Date();
    const next = this.chartConfig.footfallStatistics.next;
    const error = this.chartConfig.footfallStatistics.error;
    this.dashboardService.refreshFootfallStatisticsChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  getDateRange(event: CustomDateRangeChangeEventModel, index: number) {
    this.dateRanges[index] = event.dateRange;

    if (event.dateRange.start && event.dateRange.end) {
      switch (index) {
        case 0:
          this.loadSalesPerformanceChart();
          break;
        case 1:
          this.loadSalesByIndustryChart();
          this.loadTradingDensityByIndustryChart();
          break;
        case 2:
          this.loadTopPerformersTable();
          break;
        case 3:
          this.loadBusiestSalesTimesChart();
          this.loadBusiestSalesDaysChart();
          break;
        case 4:
          // this.loadFootfallStatisticsChart();
          break;
      }
    }
  }

  datePickerUpdated() {
    this.salesComparisonChart.stats['month1Sales'].statName = 'Sales in ' + this.dateUtils.displayFullMonthAndYear(this.month1);
    this.salesComparisonChart.stats['month2Sales'].statName = 'Sales in ' + this.dateUtils.displayFullMonthAndYear(this.month2);
    this.loadSalesComparisonChart();
  }

}
