import {Injectable} from '@angular/core';
import {CurrentContextService} from './security/current-context.service';
import {UserFeaturesResponseInterface} from '../interfaces/user-features-response.interface';
import {LocationOffering} from '../model/side-nav.model';
import {UtilsService} from '../../shared/services/utils.service';

@Injectable(
  {providedIn: 'root'}
)
export class AccessVerificationService {

  locationOfferingTypes: string[] = ['KIOSK', 'DISTRICT', 'NON-GLA']
  locationOfferings: LocationOffering[] = [];

  constructor(private contextService: CurrentContextService,
              private utilsService: UtilsService) {
  }

/*  canAccessFeature(featureCode: string): boolean {
    let canAccess: boolean = false;
    let userGrants: UserFeaturesResponseInterface = this.contextService.getCurrentUserGrants();
    for (const grantedOffering of userGrants.userGrantedOfferings) {
      for (const grantedModule of grantedOffering.userGrantedModules) {
        for (const grantedRole of grantedModule.userGrantedRoles) {
          for (const grantedFeature of grantedRole.userGrantedFeatures) {
            if (grantedFeature.featureCode.toLowerCase() === featureCode.toLowerCase()) {
              canAccess = true;
              break;
            }
          }
        }
      }
    }
    return canAccess;
  }*/

/*  canAccessModule(moduleCode: string): boolean {
    let userGrants: UserFeaturesResponseInterface = this.contextService.getCurrentUserGrants();

    let grantedModule = userGrants.userGrantedOfferings
      .map((userGrant) => {
        return userGrant.userGrantedModules
      })
      .map((modules) => {
        return modules.find((module) => {
          return module.moduleCode.toLowerCase() == moduleCode.toLowerCase()
        })
      })
      .filter(this.utilsService.arrayNotEmptyFilter);
    return !(grantedModule.length === 0);
  }

  getLocationOfferings(): LocationOffering[] {
    let map: LocationOffering[] = [];
    let userGrants: UserFeaturesResponseInterface = this.contextService.getCurrentUserGrants();
    userGrants.userGrantedOfferings
      .filter((grantedOffering) => {
        return this.locationOfferingTypes.includes(grantedOffering.platformOfferingTypeCode);
      })
      .forEach((grantedOffering) => {
        grantedOffering.userGrantedModules
          .forEach((grantedModule) => {
            map.push({
              uuid: grantedModule.moduleId.toString(),
              locationUuid: grantedOffering.locationUuid,
              platformOfferingType: grantedOffering.platformOfferingTypeCode,
              offeringUuid: grantedModule.offeringUuid,
              landlordUuid: userGrants.landlordUuid,
              offeringName: grantedModule.name
            })
          });
      });
    return map;
  }*/
}
