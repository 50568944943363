import {Component, Injector} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';

@Component({
  selector: 'app-settings-billing-and-banking',
  templateUrl: './settings-billing-and-banking.component.html',
  styleUrls: ['./settings-billing-and-banking.component.scss']
})
export class SettingsBillingAndBankingComponent extends BasePageComponent {


  constructor(injector: Injector) {
    super(injector);
  }
}
