import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AgreementType, LeaseStatus, LeaseSummary, OccupancyType} from "../../../leasing.model";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {tap} from "rxjs";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {GenericDatasource} from "../../../../../shared/datasource/generic.datasource";
import {LeasingService} from "../../../leasing.service";
import {MatTableDataSource} from "@angular/material/table";
import {PageEventInterface} from '../../../../../core/interfaces/page-event.interface';
import {LeaseViewComponent} from '../../../lease-view/lease-view.component';
import {CurrentContextService} from '../../../../../core/services/security/current-context.service';
import {TabManagementService} from '../../../../../core/services/tab-management.service';
import {PageOffering} from '../../../../../core/model/side-nav.model';
import {OfferingSpacesInterface} from '../../../../spaces/model/offering-spaces.interface';

@Component({
  selector: 'app-lease-occupancy',
  templateUrl: './lease-occupancy.component.html',
  styleUrls: ['./lease-occupancy.component.scss']
})
export class LeaseOccupancyComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  leasesDisplayedColumns: string[] = ['companyName', 'industryType', 'endDate', 'expiresInDays'];
  spacesDisplayedColumns: string[] = ['spaceName', 'size'];

  @Input() offering!: PageOffering;

  leasesDataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
  leasesDataSourceDisplayed = new GenericDatasource<LeaseSummary>(this.leasingService);
  spacesDataSource!: MatTableDataSource<OfferingSpacesInterface>;

  dateRanges: number[] = [30, 60, 90];
  expiresIn: number | null = null;

  leaseStatuses = [LeaseStatus.APPROVED, LeaseStatus.ACTIVE];
  filterForm: FormGroup;

  constructor(private paginatorService: PaginatorService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private leasingService: LeasingService,
              private contextService: CurrentContextService,
              private tabManagementService: TabManagementService) {
    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      spaceName: new FormControl(null)
    })
  }

  ngOnInit() {
    this.leasesDataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
    this.leasesDataSource.loadData('/lease-planning/current', {
      size: '25',
      page: '0',
      sort: 'leaseStartDate,desc'
    }, [{name: 'statusList', val: this.leaseStatuses}, {name: 'agreementType', val: AgreementType.LEASE}]);

    this.leasesDataSource.loading$.subscribe({
      next: value => {
        if (!value) {
          this.loadSpaces();
        }
      }
    })
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadLeases());
  }

  loadLeases() {
    const filters = [
      { name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value},
      { name: 'expiresInDays', val: this.expiresIn},
      { name: 'statusList', val: this.leaseStatuses},
      {name: 'agreementType', val: AgreementType.LEASE}
    ];
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'leaseStartDate,desc'}
    this.leasesDataSource.loadData('/lease-planning/current', page, filters);
  }

  loadSpaces() {
    const spaceNames = this.filterForm.get('spaceNames')?.value ? this.filterForm.get('spaceNames')?.value : null;
    this.leasingService.getDistrictSpaces(spaceNames).subscribe({
      next: value => {
        this.spacesDataSource = new MatTableDataSource<OfferingSpacesInterface>(this.getSpaces(value));
      },
      error: err => console.log(err)
    })
  }

  displayIndustriesList(list: string| null): string {
    if (list) {
      const res = list.split(',').map(m => this.utils.displayStatus(m));
      return this.utils.displayListAsString(res);
    }
    return '-';
  }

  getSpaces(spaces: OfferingSpacesInterface[]): OfferingSpacesInterface[] {
    const column1 = this.leasesDataSource.getData();
    const column2 = spaces.map(m => m);
    if (column2.length > column1.length) {
      const length = column1.length;
      for (let i = 0; i < column2.length - length; i++) column1.push(Object.create(null));
    }
    if (column2.length < column1.length) {
      const length = column2.length;
      for (let i = 0; i < column1.length - length; i++) column2.push(Object.create(null));
    }
    this.leasesDataSourceDisplayed.updateData(column1);
    return column2;
  }

  setExpiresIn(event: any) {
    let toggle = event.source;
    if (toggle) {
      const group = toggle.buttonToggleGroup;
      if (event.value.some((item: boolean) => item == toggle.value)) {
        group.value = [toggle.value];
        this.expiresIn = group.value[0];
      } else {
        this.expiresIn = null;
      }
      this.loadLeases();
    }
  }

  openLeaseViewTab(id: string, occupancyType: OccupancyType, agreementType: AgreementType) {
    let payload: PageEventInterface = {
      componentToRegister: LeaseViewComponent,
      pageName: 'Lease ' + this.utils.displayUuid(id),
      pageHeader: this.offering.label,
      data: { id , occupancyType, agreementType },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering.offeringUuid,
      sectionCode: 'leasing',
      pageCode: 'lease-planning',
      offering: this.offering
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

}
