<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <div fxLayout="row" fxLayoutGap="15px">
      <div class="subtitle-default">Turnover</div>
      <button class="button-action-cancel logout-button" mat-flat-button>
        <div class="button-text">Cancel</div>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="15px">
      <button (click)="changeImportType('sales')"
              [class.button-tab-active]="importType == 'sales'"
              [disabled]="!canViewTab('sales')"
              class="button-tab" mat-flat-button>
        <div class="button-text">Sales</div>
      </button>

      <button (click)="changeImportType('turnover')"
              [class.button-tab-active]="importType == 'turnover'"
              [disabled]="!canViewTab('turnover')"
              class="button-tab" mat-flat-button>
        <div class="button-text">Turnover</div>
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="15px">
    <div class="info-card" *ngIf="importType == 'sales' && canViewTab('sales')">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="body-1-default">Your latest uploaded sale date for this lease is: {{dateUtils.displayShortDate(latestTransactionDate)}}</div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <div fxLayout="column" class="body-1-regular-1" *ngIf="params.val == 'REPLACE'">
            <div>Any uploaded sales before or on this date will either be added or replace existing duplicates.</div>
            <div>Duplicates will be determined by the transaction invoice number.</div>
          </div>
          <div fxLayout="column" class="body-1-regular-1" *ngIf="params.val == 'IGNORE'">
            <div>Any uploaded sales before or on this date will be ignored.</div>
          </div>
          <mat-button-toggle-group (change)="updateParams($event)"
                                   [value]="params.val"
                                   class="custom-date-button-toggle">
            <mat-button-toggle value="REPLACE">REPLACE</mat-button-toggle>
            <mat-button-toggle value="IGNORE">IGNORE</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <div class="info-card" fxLayout="column" *ngIf="importType == 'turnover' && canViewTab('turnover')">

      <div fxLayout="column" fxLayoutGap="15px">
        <div class="body-1-default">The table below displays this lease's billing periods with the corresponding dates for upload data.</div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <div fxLayout="column" class="body-1-regular-1" *ngIf="params.val == 'REPLACE'">
            <div>Any uploaded data that overlaps with existing data will replace the existing data.</div>
            <div>Overlapping data will be determined by period start and end dates.</div>
          </div>
          <div fxLayout="column" class="body-1-regular-1" *ngIf="params.val == 'IGNORE'">
            <div>Any uploaded data that overlaps with existing data will be ignored.</div>
          </div>
          <mat-button-toggle-group (change)="updateParams($event)"
                                   [value]="params.val"
                                   class="custom-date-button-toggle">
            <mat-button-toggle value="REPLACE">REPLACE</mat-button-toggle>
            <mat-button-toggle value="IGNORE">IGNORE</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <mat-table class="custom-table" [dataSource]="dataSource" matSort fxFlexOffset="15px">
        <ng-container matColumnDef="table-label">
          <mat-header-cell mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Pricing Periods </mat-header-cell>
        </ng-container>

        <ng-container
          *ngFor="let column of displayedColumns; let i = index"
          [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{displayedLabels[i]}}</mat-header-cell>
          <mat-cell class="custom-table-cell" *matCellDef="let row">{{ row[column] ? row[column] : '-' }}</mat-cell>
        </ng-container>

        <mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></mat-header-row>
        <mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator class="custom-paginator"
                     [length]="dataSource.data.length"
                     [pageSizeOptions]="[5, 10, 25]"
                     [pageSize]="pageSize"
      ></mat-paginator>
    </div>


    <app-csv-import [uploadDataModel]="uploadConfig"
                    [uploadEndpoint]="uploadEndpoint"
                    [params]="[params]"
                    [disableUpload]="disableUpload"
                    *ngIf="canViewTab(importType)"
    >
    </app-csv-import>
  </div>



</div>

