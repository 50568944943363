<div class="full-width" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Legal Documents</span>
    <div fxLayoutGap="10px" fxFlexOffset="30px">
      <button (click)="addDocument()" class="button-action" mat-flat-button [disabled]="loader.loading$ | async">
        <div class="button-text">Add</div>
      </button>
      <button (click)="this.isRemovePanelOpen = true" [disabled]="!(this.selection.selected.length > 0) || (loader.loading$ | async)" class="button-action"
              mat-flat-button>
        <div class="button-text">Remove</div>
      </button>
    </div>
    <div *ngIf="isRemovePanelOpen" fxFlexOffset="20px">
      <app-confirm-remove (confirmRemove)="removeDocuments($event)"
                          [message]="'Do you want to remove the selected document(s).'"></app-confirm-remove>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Documents</span>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">

      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Lease Documents </th>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="custom-table-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.fileName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{utils.displayStatus(element.documentTypeCode)}}</td>
      </ng-container>

      <ng-container matColumnDef="versionNo">
        <th mat-header-cell *matHeaderCellDef> Version # </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.versionNo}} </td>
      </ng-container>

      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef> Document Type </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.mimeType, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element">
          <button mat-flat-button class="button-action" (click)="downloadDocumentFile(element)">
            <div class="button-text">Download</div>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource ? dataSource.data.length : 0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
