<div class="page-content page-padding" fxLayout="column">
  <div fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <span class="subtitle-default">Billing details</span>
      <button (click)="toggleIsEditing()"
              *ngIf="!isEditing"
              [disabled]="loader.loading$ | async"
              class="button-action"
              mat-flat-button>
        <div class="button-text">Update</div>
      </button>
      <button (click)="onSaveChanges()"
              *ngIf="isEditing"
              [disabled]="loader.loading$ | async"
              class="button-action"
              mat-flat-button>
        <div class="button-text">Submit</div>
      </button>
      <button (click)="toggleIsEditing()"
              *ngIf="isEditing"
              [disabled]="loader.loading$ | async"
              class="button-action-cancel"
              mat-flat-button>
        <div class="button-text">Cancel</div>
      </button>
    </div>
    <app-info-panel [message]="['You can update your billing information anytime.']"
                    [type]="PanelType.INFO"></app-info-panel>
  </div>

  <div class="full-width" fxFlexOffset="30px" fxLayout="row" fxLayoutGap="20px">
    <div class="info-card" fxLayout="column" style="padding: 30px 0;">
      <form [formGroup]="userBillingForm" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="35px">

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>Company name</mat-label>
            <input #billingName formControlName="billingName" matInput type="text"/>
            <mat-error *ngIf="userBillingForm.get('billingName')!.hasError('required')" class="link-error">
              Company name is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>Company registration number</mat-label>
            <input #compReg formControlName="compReg" matInput type="text"/>
            <mat-error *ngIf="userBillingForm.get('compReg')!.hasError('required')" class="link-error">
              Company registration is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>Billing address</mat-label>
            <input
              #billingAddress
              (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
              (onLocationSelected)="onAddressLocationSelected($event)"
              formControlName="address"
              matGoogleMapsAutocomplete
              matInput
            />
          </mat-form-field>
        </div>


        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>VAT registration number</mat-label>
            <input #vatRegistration formControlName="vatReg" matInput type="text">
            <mat-error *ngIf="userBillingForm.get('vatReg')!.hasError('required')" class="link-error">
              VAT registration number is required
            </mat-error>
          </mat-form-field>
        </div>


        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>Email</mat-label>
            <input
              #email
              [email]="true"
              formControlName="billingEmail"
              matInput
              type="email"
            />
            <mat-error *ngIf="userBillingForm.get('billingEmail')!.hasError('email')" class="link-error">
              Email is not valid
            </mat-error>
            <mat-error *ngIf="userBillingForm.get('billingEmail')!.hasError('required')" class="link-error">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" hideRequiredMarker>
            <mat-label>Phone number</mat-label>
            <input #phoneNum [pattern]="mobNumberPattern" formControlName="phoneNum" matInput type="text">
            <mat-error *ngIf="userBillingForm.get('phoneNum')!.hasError('required')" class="link-error">
              Phone number is required
            </mat-error>
            <mat-error *ngIf="userBillingForm.get('phoneNum')!.hasError('pattern')" class="link-error">
              Mobile number not valid, please enter 9 digits
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
