import {Injectable} from "@angular/core";
import {GenericService} from "../../../../shared/services/generic.service";
import {Retailer, RetailerBillingDetails} from "../retailers.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RetailerViewService extends GenericService<Retailer> {

  constructor(http: HttpClient) {
    super(http, environment.apiRetailersHost + `/console/manage-retailer`);
  }

  getRetailerDetails(id: string): Observable<Retailer> {
    return this.http.get<Retailer>(this.getBaseUrl + `/${id}/details`);
  }

  getRetailerBillingDetails(id: string): Observable<RetailerBillingDetails> {
    return this.http.get<RetailerBillingDetails>(this.getBaseUrl + `/${id}/billing-info`);
  }

  get getBaseUrl() {
    return environment.apiRetailersHost + `/console/manage-retailer`;
  }


}
