import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {
  CreateARetailerDialogComponent
} from "../create-a-retailer/create-a-lease-dialog/create-a-retailer-dialog.component";
import {RetailerViewComponent} from "../retailer-view/retailer-view.component";
import {ApplicationAssessmentStatus, Retailer, TenantType} from "../retailers.model";
import {RetailersService} from "../retailers.service";

@Component({
  selector: 'app-retailers-list',
  templateUrl: './retailers-list.component.html',
  styleUrls: ['./retailers-list.component.scss']
})
export class RetailersListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  curationStatuses = Object.keys(ApplicationAssessmentStatus);
  displayedColumns: string[] = ['id', 'companyName', 'retailer', 'website', 'socialLink', 'email', 'tenantType', 'curationStatus'];
  dataSource = new GenericDatasource<Retailer>(this.retailersService);

  filterForm: FormGroup;

  constructor(injector: Injector,
              public utils: UtilsService,
              public context: CurrentContextService,
              private tabService: TabManagementService,
              private paginatorService: PaginatorService,
              private retailersService: RetailersService,
              public loader: LoadingService,
              private matDialog: MatDialog) {
    super(injector)
    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      // userName: new FormControl(null),
      curated: new FormControl([]),
      tenantType: new FormControl(TenantType.TENANT)
    });
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<Retailer>(this.retailersService);
    this.dataSource.loadData('',{ size: '10', page: '0', sort: 'companyName,asc' }, [{ name: 'tenantType', val: TenantType.TENANT }]);
  }
  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadRetailers()))
      .subscribe();

    this.filterForm.valueChanges.subscribe({
      next: () => this.loadRetailers()
    })
  }

  loadRetailers() {
    const curated = this.filterForm.get('curated')?.value;
    const filter = [
      {name: 'companyName', val: this.filterForm.get('companyName')?.value},
      {name: 'curated' , val: curated.length > 0 ? curated.join(',') : null},
      {name: 'tenantType', val: this.filterForm.get('tenantType')?.value},
    ]

    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'companyName,asc'};
    this.dataSource.loadData('', page, filter);
  }

  openRetailerViewTab(id: string, companyName: string, tenantType: TenantType) {
    let payload: PageEventInterface = {
      componentToRegister: RetailerViewComponent,
      pageName: 'Retailer ' + id,
      pageHeader: this.offering!.label,
      data: { id: id, companyName: companyName, tenantType: tenantType },
      id: this.utils.generateUuidWithPrefix('retailer'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'retailer',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  openCreateRetailer(): void {
    this.matDialog.open(CreateARetailerDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        edit: false,
        lease: null,
        header: 'Create a new retailer',
        offering: this.offering
      }
    })
  }

  protected readonly TenantType = TenantType;
}
