import {CdkDragDrop, CdkDragEnter, CdkDragExit, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {forkJoin, lastValueFrom, Observable} from "rxjs";
import {
  ApplicationForm,
  Category,
  ControlPayload,
  FormQuestionFilter,
  NumberType,
  Option,
  Question,
  QuestionGroup,
  QuestionTemplate,
  RangeType,
  SelectType
} from "../../../../core/model/retailer/application-form.model";
import {PageOffering} from "../../../../core/model/side-nav.model";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {FormBuilderService} from "../form-builder.service";
import {ComponentType, ControlType, FormFileValue, QuestionItem, templateComponents} from "./question-components.model";

@Component({
  selector: 'app-add-questions',
  templateUrl: './add-questions.component.html',
  styleUrls: ['./add-questions.component.scss']
})
export class AddQuestionsComponent implements OnInit {
  @Output() nextStep = new EventEmitter<void>();
  @Input() edit!: boolean;
  @Output() formChange = new EventEmitter<ApplicationForm>();
  @Input() formTemplate!: ApplicationForm;
  @Input() offering!: PageOffering;


  protected readonly templateComponents = templateComponents;
  protected readonly ControlType = ControlType;
  protected readonly ComponentType = ComponentType;

  formName: string = '';
  selectedItem: QuestionItem | null = null;

  formComponents: QuestionItem[] = templateComponents
    .filter(f => f.type == ComponentType.FORM)
    .map(m => {
      return { uuid: null, template: m, questionConfig: null, temp: false}
    });
  questionComponents: QuestionItem[] = templateComponents
    .filter(f => f.type == ComponentType.QUESTION)
    .map(m => {
      return { uuid: null, template: m, questionConfig: null, temp: false}
    });

  newFiles: Map<string, { qUuid: string, file: File, fileCode: string }[]>
    = new Map<string, { qUuid: string, file: File; fileCode: string}[]>();

  displayedFormComponents: QuestionItem[] = [];
  displayedQuestionComponents: QuestionItem[] = [];

  searchTerm: string = '';

  questionItems: QuestionItem[] = [];

  sameAddress = true;
  formattedAddress = '';
  place: any = null;
  geometry: any = null;

  questionFilter: FormQuestionFilter | null = null;

  constructor(public utils: UtilsService,
              private contextService: CurrentContextService,
              private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              private formBuilderService: FormBuilderService,
              private router: Router) {

    this.matIconRegistry.addSvgIcon('arrow_range',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/arrow_range.svg'));
    this.matIconRegistry.addSvgIcon('attachment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/attachment.svg'));
    this.matIconRegistry.addSvgIcon('calendar_month',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/calendar_month.svg'));
    this.matIconRegistry.addSvgIcon('format_size',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/format_size.svg'));
    this.matIconRegistry.addSvgIcon('location_on',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/location_on.svg'));
     this.matIconRegistry.addSvgIcon('pin',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/pin.svg'));
     this.matIconRegistry.addSvgIcon('rule_folder',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/rule_folder.svg'));
     this.matIconRegistry.addSvgIcon('title',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/title.svg'));
     this.matIconRegistry.addSvgIcon('view_agenda',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/view_agenda.svg'));
     this.matIconRegistry.addSvgIcon('view_agenda_2',
       this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/view_agenda_2.svg'));
    this.matIconRegistry.addSvgIcon('file_download',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/file_download.svg'));
   }

   ngOnInit() {
     this.onSearch();
     if (this.formTemplate) {
       this.parseExistingFormTemplate();
     } else {
       this.addNewQuestionItem(this.questionComponents[0], 0);
     }
   }

  onSearch() {
     const pattern = new RegExp(this.searchTerm.trim(), 'i');
     this.displayedFormComponents = templateComponents.filter(f => f.type == ComponentType.FORM
       && (f.label.search(pattern) > -1 || f.controlType.search(pattern) > -1))
       .map(m => { return { uuid: null, template: m, questionConfig: null, temp: false} });
     this.displayedQuestionComponents = templateComponents.filter(f => f.type == ComponentType.QUESTION
       && (f.label.search(pattern) > -1 || f.controlType.search(pattern) > -1))
       .map(m => { return { uuid: null, template: m, questionConfig: null, temp: false} });
   }

  drop(event: CdkDragDrop<QuestionItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reCalculateDisplayOrder();
    } else {
      const clone = {...event.previousContainer.data[event.previousIndex]};
      event.container.data.splice(event.currentIndex, 0, clone);

      this.addQuestionConfig(event.container.data[event.currentIndex], event.currentIndex);
    }

    if (event.previousContainer.data) {
      if (event.container.data[event.currentIndex].template.type == ComponentType.FORM) {
        this.formComponents = this.formComponents.filter((f: any) => !f.temp);
      }
      if (event.container.data[event.currentIndex].template.type == ComponentType.QUESTION) {
        this.questionComponents = this.questionComponents.filter((f: any) => !f.temp);
      }
    }
  }

  exited(event: CdkDragExit, componentType: ComponentType) {
    const currentIdx = event.container.data.findIndex(
      (f: any) => {
        return f.template.controlType === event.item.data.template.controlType
      }
    );

    if (componentType == ComponentType.FORM) {
      this.formComponents.splice(currentIdx + 1, 0, {
        ...event.item.data,
        temp: true,
      });
    }
    if (componentType == ComponentType.QUESTION) {
      this.questionComponents.splice(currentIdx + 1, 0, {
        ...event.item.data,
        temp: true,
      });
    }
  }

  entered(event: CdkDragEnter, componentType: ComponentType) {
    if (componentType == ComponentType.FORM) {
      this.formComponents = event.container.data.filter((f: any) => !f.temp);
    }
    if (componentType == ComponentType.QUESTION) {
      this.questionComponents = event.container.data.filter((f: any) => !f.temp);
    }
  }

  addNewQuestionItem(item: QuestionItem, index: number) {
     const question = {...item};
     this.questionItems.splice(index + 1, 0, question);
     this.addQuestionConfig(question, index + 1);
  }

  addQuestionConfig(item: QuestionItem, index: number) {
    const groupUuid = this.utils.generateUuid();
    const questionUuid = this.utils.generateUuid();

    const template: QuestionTemplate = {
      uuid: this.utils.generateUuid(),
      districtUuid: this.contextService.getCurrentOfferingId(),
      controlCode: null,
      controlDescription: null,
      controlType: item.template.controlType,
      controlPayload: {label: null}
    };

    const question: Question = {
      uuid: questionUuid,
      questionGroupUuid: groupUuid,
      displayOrder: 0,
      required: !this.formBuilderService.isTypeHeading(item),
      value: null,
      replacedByQuestionUuid: null,
      questionTemplate: template
    }

    item.questionConfig = {
      uuid: groupUuid,
      categoryUuid: null,
      displayOrder: index,
      code: null,
      displayLabel: null,
      displayDescription: null,
      questions: [question],
      repeatedInput: false,
      repeatedValues: null
    };

    item.uuid = groupUuid;
    this.setControlPayload(item);
    this.reCalculateDisplayOrder();
    this.selectedItem = item;
  }

  removeItem(item: QuestionItem) {
    this.questionItems = this.questionItems.filter(f => f.uuid != item.uuid);
    if (this.questionItems.length > 0) {
      this.reCalculateDisplayOrder();
    }
  }

  reCalculateDisplayOrder() {
     this.questionItems.forEach((ea, i) => {
       ea.questionConfig!.displayOrder = i;
     });
  }

  setControlPayload(item: QuestionItem) {
     this.getQuestionTemplate(item).controlType = item.template.controlType;

    let payload: ControlPayload = {
      label: null
    }

     switch (item.template.controlType) {
       case ControlType.SELECT:
         payload = {
           label: null,
           selectType: SelectType.SINGLE,
           options: [
             {label: 'Answer 1', valueCode: 'answer_1'},
             {label: 'Answer 2', valueCode: 'answer_2'},
             {label: 'Answer 3', valueCode: 'answer_3'}
           ]
         };
         break;
       case ControlType.CHOICE:
         payload = {
           label: null,
           selectedOption: {label: 'Yes', valueCode: 'yes'},
           unselectedOption: {label: 'No', valueCode: 'no'}
         };
         break;
       case ControlType.DATE:
         payload = {
           label: null,
         };
         break;
       case ControlType.FILE:
         payload = {
           label: null,
           maxSizeMb: 16,
           fileTypes: ['.jpg', '.png', '.jpeg'],
           files: [
             {label: 'File 1', valueCode: 'file_1'}
           ]
         };
         break;
       case ControlType.RANGE:
         const increments = [
           {label: '0 to 5', valueCode: null, start: 0, end: 5, incrementSize: 5},
           {label: '5 to 10', valueCode: null, start: 5, end: 10, incrementSize: 5},
         ];
         payload = {
           label: null,
           rangeType: RangeType.NUMBER,
           numberType: NumberType.NUMBER,
           start: 0,
           end: 10,
           increments: increments
         };
         break;
       case ControlType.NUMBER:
         payload = {
           label: null,
           numberType: NumberType.NUMBER,
           precision: null,
           min: null,
           max: null
         };
         break;
       case ControlType.INFO_FILE:
         payload = {
           label: null,
           maxSizeMb: 16,
           fileTypes: [...this.formBuilderService.fileExtensions.image, ...this.formBuilderService.fileExtensions.document, ...this.formBuilderService.fileExtensions.dataFile],
           files: [
             {label: 'File 1', valueCode: 'file_1'}
           ]
         };
         break;
       default:
         break;
     }

    this.getQuestionTemplate(item).controlPayload = payload;
    item.questionConfig?.questions.forEach(q => q.required = !this.formBuilderService.isTypeHeading(item));
  }

  saveForm(next: boolean) {
    if (this.newFiles.size > 0) {
      this.uploadNewFiles().then((res: boolean) => {
        return res ? this.saveFormTemplate(next) : null;
      })
    } else {
      this.saveFormTemplate(next);
    }
  }

  saveFormTemplate(next: boolean) {
    const form = {...this.formTemplate};
    const category = new Category();

    category.questionGroups = this.questionItems.map(m => m.questionConfig!);
    form.categories = [category];

    if (!this.edit && this.formTemplate.uuid == null) {
      this.formBuilderService.createNewFormTemplate(form).subscribe({
        next: value => {
          this.formTemplate = value;
          if (next) {
            this.formChange.emit(this.formTemplate);
            this.nextStep.emit();
            this.edit = true;
          }
        },
        error: err => console.log(err),
      });
    } else {
      this.formBuilderService.updateFormTemplate(form).subscribe({
        next: value => {
          this.formTemplate = value;
          if (next) {
            this.formChange.emit(this.formTemplate);
            this.nextStep.emit();
          }
        },
        error: err => console.log(err),
      });
    }
  }

  log(event: any) {
    console.log(event);
  }

  public onAddressAutocompleteSelected(event: any): void {
    this.sameAddress = false;
    this.place = event;
  }

  public onAddressLocationSelected(event: any): void {
    this.sameAddress = false;
    this.geometry = event;
  }

  selectQuestionItem(item: QuestionItem) {
    this.questionFilter = this.generateQuestionFilter(
      item.uuid!,
      item.questionConfig!.uuid,
      item.questionConfig!.questions[0].uuid,
      null
    );
    this.selectedItem = item;
  }

  itemChangeHandler(event: QuestionItem) {
    const itemIndex = this.questionItems.findIndex(f => f.uuid == event.uuid);
    this.questionItems[itemIndex] = {...event};
    this.selectedItem = event;
  }

  addOptionToQuestion(item: QuestionItem) {
     const currentOptions = this.getQuestionControlPayload(item).options!;
     this.getQuestionControlPayload(item).options!.push({
       label: 'Answer ' + (currentOptions.length + 1).toString(),
       valueCode: 'answer_' + (currentOptions.length + 1).toString(),
     });
  }

  addFileToQuestion(item: QuestionItem) {
    const currentOptions = this.getQuestionControlPayload(item).files!;
    this.getQuestionControlPayload(item).files!.push({
      label: 'File ' + (currentOptions.length + 1).toString(),
      valueCode: 'file_' + (currentOptions.length + 1).toString(),
    });
  }

  removeOptionFromQuestion(item: QuestionItem, option: Option) {
    this.getQuestionControlPayload(item).options = this.getQuestionControlPayload(item).options!.filter(f => f != option);
  }

  removeFileFromQuestion(item: QuestionItem, file: Option) {
    this.getQuestionControlPayload(item).files = this.getQuestionControlPayload(item).files!.filter(f => f != file);
  }

  displayQuestionLabel(item: QuestionItem): string {
     if (this.getQuestionTemplate(item).controlDescription != null) {
       return this.getQuestionTemplate(item).controlDescription!;
     }

     if (this.formBuilderService.isTypeHeading(item)) {
       return 'Your heading goes here';
     }

     return 'Your question goes here';
  }

  getQuestionTemplate(item: QuestionItem): QuestionTemplate {
    return item.questionConfig!.questions[0].questionTemplate!;
  }

  getControlType(item: QuestionItem): ControlType {
    return item.template.controlType;
  }

  getQuestionControlPayload(item: QuestionItem): ControlPayload {
    return item.questionConfig!.questions[0].questionTemplate.controlPayload;
  }

  getFileValue(item: QuestionItem, fileCode: string): FormFileValue | null {
    const questionValue = item.questionConfig!.questions[0].value;
    if (questionValue != null) {
      const fileValues: FormFileValue[] = JSON.parse(questionValue);
      const fileValue = fileValues.filter(f => f.fileCode == fileCode);

      return fileValue.length > 0 ? fileValue[0] : null;
    }
    return null;
  }

  public previewForm() {
    const locationOffering = this.contextService.getCurrentLocation()?.locationOfferings?.find(offering => offering.offeringUuid === this.offering?.offeringUuid)!;

    const url = this.router.serializeUrl(this.router.createUrlTree([`/preview-apply/${this.formTemplate.formCode}`],
      {
        queryParams: {
          locationCode: locationOffering.locationCode!,
          offeringCode: locationOffering.offeringCode!,
          embedKey: locationOffering.embedKey!
        }
      }));
    window.open(url, '_blank');
  }

  private parseExistingFormTemplate(): void {
    this.formTemplate.categories.flatMap(category => category.questionGroups)
      .flatMap(group => group.questions)
      .forEach(question => {
        let converted: QuestionItem = {
          uuid: question.questionGroupUuid,
          template: this.formBuilderService.getControlTemplate(question.questionTemplate)!,
          questionConfig: this.getQuestionGroupForQuestion(question.questionGroupUuid),
          temp: false
        };
        this.questionItems.push(converted)
      });

  }

  private getQuestionGroupForQuestion(questionGroupUuid: string): QuestionGroup | null {
    let questionGroups: QuestionGroup[] = this.formTemplate.categories
      .flatMap(category => category.questionGroups)
      .filter(group => group.uuid == questionGroupUuid);
    if (questionGroups.length > 0) {
      return questionGroups[0];
    }
    return null;
  }

  uploadNewFiles() {
    const newFileRequests: {[key: string]: Observable<string>} = {};

    this.newFiles.forEach((value, key) => {
      value.forEach(ea => {
        const compositeKey: string = `${key}_|_${ea.fileCode}`;
        newFileRequests[compositeKey] = this.formBuilderService.uploadFormFile(ea.file);
      });
    });

    return lastValueFrom(forkJoin(newFileRequests))
      .then((value: {[key: string]: string}) => {
        this.questionItems.forEach(item => {
          item.questionConfig!.questions!.forEach(q => {
            if (q.questionTemplate.controlType == ControlType.INFO_FILE) {
              const currentValue: FormFileValue[] = JSON.parse(q.value);
              Object.keys(value).forEach(ea => {
                const [qUuid, fileCode] = ea.split('_|_');
                if (qUuid == q.questionTemplate.uuid) {
                  const updateValueIndex = currentValue.findIndex(f => f.fileCode == fileCode);
                  if (updateValueIndex > -1) {
                    currentValue[updateValueIndex].fileUuid = JSON.parse(value[ea]);
                    q.value = JSON.stringify(currentValue);
                  }
                }
              });
            }
          })
        })
        return Promise.resolve(true);
      }, err => {
        console.log(err);
        return Promise.resolve(false);
      })
  }

  generateQuestionFilter(catId: string, qgId: string, qId: string, fileCode: string | null) {
    const filter: FormQuestionFilter = {
      assessmentId: null,
      formCode: this.formTemplate.formCode!,
      categoryUuid: catId,
      questionGroupUuid: qgId,
      questionUuid: qId,
      fileCode: fileCode
    }
    return filter;
  }
}
