<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close(false, null)">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-default">{{header}}</div>
    </div>

    <form class="full-width" [formGroup]="requestForm" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxFlexOffset="10px">
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Select your property type</span>
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" formControlName="location">
            <mat-option class="custom-select-field-option" *ngFor="let property of properties" [value]="property">{{property.locationName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Select your offering type</span>
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" formControlName="offering">
            <mat-option class="custom-select-field-option" *ngFor="let offering of getLocationOfferings()" [value]="offering">{{offering.offeringName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose an {{cancel ? 'deactivation' : 'activation'}} date</span>
        <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always" style="width: 100%">
          <input matInput placeholder="Custom" [matDatepicker]="startPicker" formControlName="activationDate"/>
          <mat-datepicker-toggle matIconSuffix [for]="startPicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>

          <mat-hint class="footer-default">Free trial period thereafter billing on a monthly basis</mat-hint>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose a Retailer</span>
        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" style="width: 100%;">
          <input class="input-field" [placeholder]="data.retailerId ? selectedRetailer!.companyName : 'Start typing to search all retailers'" matInput type="text"
                 [formControl]="searchTerm"
                 (input)="loadRetailers()"
                 [maxlength]="data.retailerId ? 0 : null"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectRetailer($event.option.value)">
            <mat-option *ngFor="let option of allRetailers" [value]="option">
              {{option.id}}. {{option.companyName}}
            </mat-option>
          </mat-autocomplete>
          <mat-spinner class="me-2" matSuffix diameter="20" color="primary" *ngIf="loadingSearch"></mat-spinner>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose a lease</span>
        <mat-form-field appearance="outline" class="custom-select-field" floatLabel="always" style="width: 100%;">
          <mat-select class="custom-select-field-value" formControlName="leaseUuid" [placeholder]="data.leaseId ? selectedRetailer!.leaseId : 'Select a lease uuid'">
            <mat-option *ngFor="let lease of retailerLeases" [value]="lease.uuid"
                        class="custom-select-field-option">{{lease.uuid}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="!cancel">
        <span class="body-3-default">Choose a configuration type</span>
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" formControlName="configType">
            <mat-option class="custom-select-field-option" [value]="ConfigType.VEND">Lightspeed Retail X-Series (Vend) Point-of-Sale</mat-option>
            <mat-option class="custom-select-field-option" [value]="ConfigType.POSTER">Poster Point-of-Sale</mat-option>
            <mat-option class="custom-select-field-option" *ngIf="selectedLease"
                        [value]="selectedLease.parentUuid ? ConfigType.VEND_SUPPLIER_LEASE : ConfigType.VEND_SUPPLIER_OFFERING">Lightspeed Retail X-Series (Vend) Supplier</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="!cancel">
        <span class="body-3-default">Do you need a new payment device</span>
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" formControlName="newPaymentDevice">
            <mat-option class="custom-select-field-option" [value]="true">Yes</mat-option>
            <mat-option class="custom-select-field-option" [value]="false">No</mat-option>
          </mat-select>
          <mat-hint class="footer-default">Limited to your amount of retail spaces</mat-hint>
        </mat-form-field>
      </div>

    </form>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="30px">
    <button class="button-primary" mat-flat-button (click)="save()" [disabled]="(loader.loading$ | async) || requestForm.invalid">
      <mat-icon>check</mat-icon>
      <div class="button-text">{{cancel ? 'Cancel' : 'Request'}} POS</div>
    </button>
  </div>

</div>
