import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';
import {SharedServicesService} from '../../../../settings/service/shared-services.service';
import {lastValueFrom, tap} from 'rxjs';
import {GroupedRetailerPipeline, Retailer} from '../../../../leads/retailers/retailers.model';
import {PaginatorService} from '../../../../../shared/services/paginator.service';
import {PipelineService} from '../../../../leads/pipeline/pipeline.service';
import {MatPaginator} from '@angular/material/paginator';
import {PageEventInterface} from '../../../../../core/interfaces/page-event.interface';
import {PipelineViewComponent} from '../../../../leads/pipeline/pipeline-view/pipeline-view.component';
import {TabManagementService} from '../../../../../core/services/tab-management.service';
import {PageOffering} from '../../../../../core/model/side-nav.model';
import {UtilsService} from '../../../../../shared/services/utils.service';
import {DateUtilsService} from '../../../../../shared/services/dateUtils.service';
import {IndustryCategory, IndustryType} from '../../../../leads/industry.model';
import {LeaseGridSpaceGroup, OccupiedSpace} from '../../../leasing.model';
import {
  ResponsiveDrawerComponent
} from '../../../../../shared/components/responsive-drawer/responsive-drawer.component';


export interface ApplicationGroup {
  group: string;
  isGroup: boolean;
}

@Component({
  selector: 'app-retailers-pipeline',
  templateUrl: './retailers-pipeline.component.html',
  styleUrls: ['./retailers-pipeline.component.scss']
})
export class RetailersPipelineComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('paginator') paginator!: MatPaginator;

  filtersFormGroup: FormGroup;
  allIndustries: IndustryCategory[] = [];

  selectedIndustries: string[] = [];

  displayedColumns: string[] = ['select', 'companyName', 'industryType', 'overallScore'];

  dataSource: any[] = [];
  totalPages: number = 0;
  totalElements: number = 0;

  @Input() selectedSpace!: OccupiedSpace;
  @Input() offering?: PageOffering;
  @Input() selectedLeaseSpaceGroup!: LeaseGridSpaceGroup | null;
  @Output() selectedLeaseSpaceGroupChange: EventEmitter<LeaseGridSpaceGroup> = new EventEmitter<LeaseGridSpaceGroup>();
  private preferredRetailers: Retailer[] = [];
  private preferredIndustries: string[] = [];

  @Output() addRetailer = new EventEmitter<Retailer>();
  @Output() removeRetailer = new EventEmitter<Retailer>();


  constructor(@Inject(ResponsiveDrawerComponent) public responsiveDrawer: ResponsiveDrawerComponent,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private sharedServices: SharedServicesService,
              private paginatorService: PaginatorService,
              private pipelineService: PipelineService,
              private tabService: TabManagementService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
  ) {
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/back-arrow-button.svg'));
    this.filtersFormGroup = new FormGroup({
      companyName: new FormControl(''),
      industryTypes: new FormControl([])
    });
  }


  ngOnInit(): void {
    lastValueFrom(this.pipelineService.loadQualifiedGroupedApplicants({
      size: '50',
      page: '0',
      sort: 'overallScore,desc'
    }, [{name: 'preferredIndustries', val: this.preferredIndustries.join(',')}
    ]))
      .then((res: GroupedRetailerPipeline) => {
        this.setDatasourceValues(res);
      });
    this.loadIndustries();
  }


  ngAfterViewInit(): void {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadPipeline()))
      .subscribe();
  }

  loadPipeline() {

    const filters = [
      {name: 'industries', val: this.selectedIndustries.join(',')},
      {name: 'companyName', val: this.filtersFormGroup.get('companyName')?.value},
      {name: 'preferredIndustries', val: this.preferredIndustries.join(',')}
    ];
    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'overallScore,desc'
    };
    lastValueFrom(this.pipelineService.loadQualifiedGroupedApplicants(page, filters))
      .then((res: GroupedRetailerPipeline) => {
        this.setDatasourceValues(res);
      });
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
    this.loadPipeline();
  }

  ngOnChanges(changes: SimpleChanges) {
    let loadPipeline: boolean = false;
    if (changes['selectedSpace']) {
      this.selectedSpace = changes['selectedSpace'].currentValue;
      if (this.selectedSpace && this.selectedSpace.districtSpace.districtSpacesIndustries && this.selectedSpace.districtSpace.districtSpacesIndustries.length > 0) {
        loadPipeline = true;
      }
    }
    if (changes['selectedLeaseSpaceGroup']) {
      this.selectedLeaseSpaceGroup = changes['selectedLeaseSpaceGroup'].currentValue;
    }
    this.resetIndustrySelection(loadPipeline);
    this.setRetailers();
  }

  loadIndustries(){
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
      }
    })
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
        this.updateSelected();
      }
    });
  }

  removeFromSelectedIndustries(industryCode: string): void {
    this.allIndustries.forEach(category => {
      category.industries.forEach(ind => {
        if (ind.code == industryCode) {
          ind.selected = false;
        }
      });
    });
    this.updateSelected();
  }

  isGroup(index: any, item: any): boolean {
    return item.isGroup;
  }

  getSpaceName(): string {
    if (this.selectedSpace) {
      return this.selectedSpace.districtSpace.spaceName;
    } else {
      return '';
    }
  }


  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  openPipelineViewTab(id: string) {
    let payload: PageEventInterface = {
      componentToRegister: PipelineViewComponent,
      pageName: 'Pipeline ' + id,
      pageHeader: this.offering!.label,
      data: { id: id },
      id: this.utils.generateUuidWithPrefix('leads'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'leads',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  getApplicationVersion(retailer: Retailer) {
    switch (retailer.usesActiveForm) {
      case true:
        return {
          status: this.utils.displayStatus('Current'),
          icon: 'check_circle',
          outline: false,
          className: 'success-chip'
        };
      case false:
        return {
          status: this.utils.displayStatus('Previous'),
          icon: 'check_circle',
          outline: false,
          className: 'warn-chip'
        };
    }
    return null;
  }

  getRetailerIndustries(listString: string, max: number) {
    if (listString) return listString.split(',').map(m => this.utils.displayStatus(m)).slice(0, max);
    return null;
  }

  addRetailerToShortlist(element: Retailer): void {
    this.addRetailer.emit(element);
  }

  removeRetailerFromShortlist(element: Retailer): void{
    this.removeRetailer.emit(element);
  }

  setRetailers() {
    if (this.selectedLeaseSpaceGroup && this.selectedLeaseSpaceGroup.leaseGridSpaces) {
      const sorted = this.selectedLeaseSpaceGroup?.leaseGridSpaces
        .filter(f => f.retailerId)
        .sort((a, b) => b.periodGroupRanking! - a.periodGroupRanking!)!;
      this.preferredRetailers = sorted
        .map(m => new Retailer({id: m.retailerId, companyName: m.retailerCompanyName}));
    }
  }

  private setDatasourceValues(res: GroupedRetailerPipeline): void {
    this.dataSource = [];
    this.dataSource.push({group: 'Best Suited', isGroup: true, styleClass: 'table-group'});
    if (res.bestSuited.length == 0) {
      this.dataSource.push({
        group: 'There are no recommended retailers for the current filters',
        subText: 'Adjust the filters to update recommendations',
        isGroup: true,
        styleClass: 'table-message-bold'
      });
    } else {
      this.dataSource.push(...res.bestSuited);
    }
    this.dataSource.push({group: 'Other', isGroup: true, styleClass: 'table-group'});
    this.totalElements = res.retailerPipelines.totalElements;
    this.totalPages = res.retailerPipelines.totalPages;
    this.dataSource.push(...res.retailerPipelines.content);

  }

  private resetIndustrySelection(loadPipeline: boolean = false): void {
    this.selectedIndustries = [];
    this.preferredIndustries = [];
    if (this.selectedSpace && this.selectedSpace.districtSpace.districtSpacesIndustries) {
      let industryCategoryCodes: string[] = this.selectedSpace.districtSpace.districtSpacesIndustries.map(ind => ind.code);
      this.allIndustries.forEach(industryCategory => {
        if (industryCategoryCodes.includes(industryCategory.code)) {
          industryCategory.industries.forEach(ind => {
            ind.selected = true;
          });
          industryCategory.selected = true;
          this.selectedIndustries.push(...industryCategory.industries.map(ind => ind.code));
          this.preferredIndustries.push(...industryCategory.industries.map(ind => ind.code));
        }
      });

    }
    if (loadPipeline) {
      this.loadPipeline();
    }
  }

  isPreferredRetailer(element: Retailer): Boolean {
    return this.preferredRetailers.map(ret => ret.id).includes(element.id)
  }

}
