import {Injectable} from "@angular/core";
import {GenericService} from "../../../shared/services/generic.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";


import {Retailer} from "../retailers/retailers.model";
import {
  FormQuestionFilter,
  RecommendationConfig,
  RetailerApplicationAssessment
} from '../../../core/model/retailer/application-form.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService extends GenericService<Retailer> {
  constructor(http: HttpClient) {
    super(http, environment.apiRetailersHost + `/console/offering/district/pipeline/applications`);
  }

  getLatestRetailerAssessment(retailerId: string): Observable<RetailerApplicationAssessment> {
    return this.http.get<RetailerApplicationAssessment>(this.getBaseUrl + `/${retailerId}/assessment`);
  }

  getAssessmentRecommendation(retailerId: string): Observable<RecommendationConfig> {
    return this.http.get<RecommendationConfig>(this.getBaseUrl + `/${retailerId}/assessment/recommendation`);
  }

  updateCurateDetails(retailerId: string, curateDetails: RetailerApplicationAssessment): Observable<RetailerApplicationAssessment> {
    return this.http.post<RetailerApplicationAssessment>(this.getBaseUrl + `/${retailerId}/assessment`, curateDetails);
  }

  downloadFormFile(retailerId: string, fileId: string, questionFilter: FormQuestionFilter): Observable<ArrayBuffer> {
    const requestParams = new HttpParams()
      .set('filter', JSON.stringify(questionFilter));
    const options = { responseType: 'arraybuffer' as 'json', params: requestParams };
    return this.http.get<ArrayBuffer>(this.getBaseUrl + `/${retailerId}/file-download/${fileId}`, options);
  }

  get getBaseUrl() {
    return environment.apiRetailersHost + `/console/offering/district/pipeline/applications`;
  }
}
