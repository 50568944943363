import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {GenericDatasource} from "../../../../../shared/datasource/generic.datasource";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {POSSaleStatus, SaleStatus, TransactionsSummary, TransactionStatus} from "../../transactions.model";
import {TransactionsService} from "../../transactions.service";

@Component({
  selector: 'app-transactions-schedule',
  templateUrl: './transactions-schedule.component.html',
  styleUrls: ['./transactions-schedule.component.scss']
})
export class TransactionsScheduleComponent implements OnInit, AfterViewInit  {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new GenericDatasource<TransactionsSummary>(this.transactionsService);
  displayedColumns = ['paymentBatchUuid', 'transactionUuid', 'retailerCompanyName', 'transactionDate', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'posStatus', 'saleStatus', 'processingStatus'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  posStatuses: string[] = Object.keys(POSSaleStatus);
  transactionStatusesFilter: string[] = Object.keys(TransactionStatus);
  saleStatuses: string[] = Object.keys(SaleStatus);
  transactionStatuses!: any[];

  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 0, TimeFrames.MONTH, 'This month')
  ]

  constructor(private transactionsService: TransactionsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService) {
    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      saleAmountIncl: new FormControl(null),
      saleStatus: new FormControl([]),
      processingStatus: new FormControl([]),
      pointOfSaleStatus: new FormControl([]),
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<TransactionsSummary>(this.transactionsService);
    this.dataSource.loadData('/transactions/schedule', {size: '10', page: '0', sort: 'transactionDate,desc'}, []);

    this.setTransactionStatuses();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadTransactionsSchedule()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadTransactionsSchedule());
  }

  loadTransactionsSchedule() {
    const filters = [
      {name: 'companyName', val: this.filterForm.get('companyName')?.value},
      {name: 'saleAmount', val: this.filterForm.get('saleAmountIncl')?.value},
      {name: 'saleStatus', val: this.filterForm.get('saleStatus')?.value},
      {name: 'processingStatus', val: this.filterForm.get('processingStatus')?.value},
      {name: 'pointOfSaleStatus', val: this.filterForm.get('pointOfSaleStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]

    let page = {size: '10', page: '0', sort: 'transactionDate,desc'};
    if (this.paginator) page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'transactionDate,desc'}
    this.dataSource.loadData('/transactions/schedule', page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.dateRange = event.dateRange;
      this.loadTransactionsSchedule();
    }
  }

  setTransactionStatuses() {
    const pending = [
      TransactionStatus.NEW,
      TransactionStatus.PENDING_PAYMENT,
      TransactionStatus.STAGED_IN_PAYMENT_BATCH,
      TransactionStatus.SUBMITTED_FOR_PAYMENT,
      TransactionStatus.PENDING_REFUND_RECOVERY,
      TransactionStatus.STAGED_FOR_RECOVERY,
      TransactionStatus.SUBMITTED_FOR_RECOVERY
    ]
      .map(m => m.toString());
    const error = Object.keys(TransactionStatus).filter(f => f.includes('ERROR')).map(m => m.toString());
    this.transactionStatuses = Object.keys(TransactionStatus).map(m => {
      if (pending.includes(m) || error.includes(m)) {
        return;
      }
      return {label: this.utils.displayStatus(m), val: [m]};
    });
  }
}
