import {
  dateTimePattern,
  monthPattern,
  numberPattern,
  UploadDataModel,
  yearPattern
} from "../../../../../shared/components/csv-import/csv-import.model";

export interface TurnoverPeriod {
  billingPeriodUuid: string,
  pricingPeriodStartDate: string,
  pricingPeriodEndDate: string,
  turnoverMinDate: string,
  turnoverMaxDate: string
}

export const monthlyTurnoverConfig: UploadDataModel[] = [
  {
    property: 'periodStartDate',
    label: 'Period Start Date',
    required: true,
    validators: [dateTimePattern],
    validationMessage: 'Invalid format, requires: yyyy-MM-dd',
    description: 'The start of the period over which the turnover amount was generated',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'periodEndDate',
    label: 'Period End Date',
    required: true,
    validators: [dateTimePattern],
    validationMessage: 'Invalid format, requires: yyyy-MM-dd',
    description: 'The end of the period over which the turnover amount was generated. This should be within the same month of the start date.',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'turnoverAmount',
    label: 'Turnover Amount',
    required: true,
    validators: [numberPattern],
    validationMessage: 'Invalid format, requires only numbers',
    description: 'The turnover generated for this month period',
    selectColumn: true,
    showInTable: true,
    computeValue: false
  },
  {
    property: 'turnoverYear',
    label: 'Turnover Year',
    required: false,
    validators: [yearPattern],
    validationMessage: 'Invalid format, requires: yyyy',
    description: 'The year of this turnover period',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  },
  {
    property: 'turnoverMonth',
    label: 'Turnover Month',
    required: false,
    validators: [monthPattern],
    validationMessage: 'Invalid format, requires a full month name',
    description: 'The month of this turnover period',
    selectColumn: false,
    showInTable: true,
    computeValue: true
  }
];
