<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
      <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
        <button class="button-close-top" mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title-default">Payment Config Suppliers</div>
        <div class="pe-3" fxLayout="row" fxLayoutAlign="center center" *ngIf="loading.loading$ | async">
          <mat-spinner color="primary" diameter="20"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="full-width" fxLayout="column" fxFlexOffset="10px" fxLayoutGap="7px" *ngIf="validSuppliersDataSource.data.length > 0">
      <span class="body-3-default">Valid Assigned Suppliers</span>
      <table mat-table class="custom-table" matSort [dataSource]="validSuppliersDataSource">
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="leaseSupplierColumns.length" class="custom-table-label" mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="leaseUuid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Lease ID </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayUuid(element.leaseUuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="retailerCompanyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Retailer </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.retailerCompanyName}} </td>
        </ng-container>

        <ng-container matColumnDef="supplierName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.supplierName}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="leaseSupplierColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: leaseSupplierColumns;"></tr>
      </table>

      <mat-paginator #validSuppliersPaginator class="custom-paginator"
                     [length]="validSuppliersDataSource ? validSuppliersDataSource.data.length : 0"
                     [hidePageSize]="true"
                     [pageSize]="5"></mat-paginator>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="invalidSuppliersDataSource.data.length > 0">
      <span class="body-3-default">Invalid Assigned Suppliers</span>
      <table mat-table class="custom-table" matSort [dataSource]="invalidSuppliersDataSource">
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="leaseSupplierColumns.length" class="custom-table-label" mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="leaseUuid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Lease ID </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayUuid(element.leaseUuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="retailerCompanyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Retailer </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.retailerCompanyName}} </td>
        </ng-container>

        <ng-container matColumnDef="supplierName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.supplierName}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="leaseSupplierColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: leaseSupplierColumns;"></tr>
      </table>

      <mat-paginator #invalidSuppliersPaginator class="custom-paginator"
                     [length]="invalidSuppliersDataSource ? invalidSuppliersDataSource.data.length : 0"
                     [hidePageSize]="true"
                     [pageSize]="5"></mat-paginator>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="unassignedSuppliersDataSource.data.length > 0">
      <span class="body-3-default">Unassigned Suppliers</span>
      <table mat-table class="custom-table" matSort [dataSource]="unassignedSuppliersDataSource">
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="unassignedSupplierColumns.length" class="custom-table-label" mat-header-cell></th>
        </ng-container>

        <ng-container matColumnDef="supplierUuid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier ID </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="supplierName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="unassignedSupplierColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: unassignedSupplierColumns;"></tr>
      </table>

      <mat-paginator #unassignedSuppliersPaginator class="custom-paginator"
                     [length]="unassignedSuppliersDataSource ? unassignedSuppliersDataSource.data.length : 0"
                     [hidePageSize]="true"
                     [pageSize]="5"></mat-paginator>
    </div>

  </div>

</div>
