import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {TurnoverPeriod} from "./import-config/monthly-turnover-config.model";

@Injectable({
  providedIn: 'root'
})
export class LeaseTurnoverService {

  constructor(private http: HttpClient) {
  }

  getLatestTransactionDate(leaseUuid: string): Observable<Date> {
    return this.http.get<Date>(this.baseUrl(leaseUuid) + `/sales`);
  }

  getTurnoverPeriods(leaseUuid: string): Observable<TurnoverPeriod[]> {
    return this.http.get<TurnoverPeriod[]>(this.baseUrl(leaseUuid) + `/turnover`);
  }

  baseUrl(leaseUuid: string) {
    return environment.apiTransactHost + `/console/manage-lease/${leaseUuid}/turnover-upload`
  }
}
