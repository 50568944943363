import {
  PayoutHistoryListComponent
} from "../../features/accounts/payout-history/payout-history-list/payout-history-list.component";
import {
  RunClientPayoutsViewComponent
} from "../../features/accounts/run-client-payouts/run-client-payouts-view/run-client-payouts-view.component";
import {RunProductsComponent} from "../../features/accounts/run-products/run-products.component";
import {
  RunTenantPayoutsViewComponent
} from "../../features/accounts/run-tenant-payouts/run-tenant-payouts-view/run-tenant-payouts-view.component";
import {
  TransactionsListViewComponent
} from "../../features/accounts/transactions/transactions-list-view/transactions-list-view.component";
import {
  ManageApplicationFormsComponent
} from '../../features/leads/application-form-builder/manage-application-forms/manage-application-forms.component';
import {
  ApplicationsListComponent
} from "../../features/leads/applications/applications-list/applications-list.component";
import {PipelineListComponent} from "../../features/leads/pipeline/pipeline-list/pipeline-list.component";
import {RetailersListComponent} from "../../features/leads/retailers/retailers-list/retailers-list.component";
import {
  CreateALeaseDialogComponent
} from "../../features/leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {
  LeaseCreateMethod
} from "../../features/leasing/create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {
  CurrentLeasesListComponent
} from "../../features/leasing/current-leases/current-leases-list/current-leases-list.component";
import {
  LeasePlanningViewComponent
} from "../../features/leasing/lease-planning/lease-planning-view/lease-planning-view.component";
import {
  LeaseScheduleListComponent
} from "../../features/leasing/lease-schedule/lease-schedule-list/lease-schedule-list.component";
import {SettingsDetailsComponent} from '../../features/settings/details/settings-details.component';
import {HardwareSettingsComponent} from '../../features/settings/hardware-settings/hardware-settings.component';
import {ManageListingComponent} from '../../features/settings/manage-listing/manage-listing.component';
import {PaymentsSettingsComponent} from "../../features/settings/payments/payments-settings.component";
import {
  SettingsBillingAndBankingComponent
} from '../../features/settings/settings-billing-and-banking/settings-billing-and-banking.component';
import {TemplateBuilderComponent} from '../../features/settings/template-builder/template-builder.component';
import {UsersAndAccessComponent} from '../../features/settings/users-and-access/users-and-access.component';
import {ManageSpacesComponent} from '../../features/spaces/manage-spaces/manage-spaces.component';
import {CustomerSupportComponent} from "../../features/support/customer-support/customer-support.component";
import {PageSectionInfo, PageSectionItemInfo} from "../model/side-nav.model";

export const dashboardSection: PageSectionInfo = {
  code: 'dashboards',
  label: 'Dashboards',
  icon: 'bar_chart'
}

export const internalAdminSection: PageSectionInfo = {
  code: 'internal_admin',
  label: 'Internal Admin',
  icon: 'bar_chart'
}

const PIPELINE_PAGE_SECTION: PageSectionInfo = {
  code: 'leads',
  label: 'Leads',
  icon: 'person_search'
};
const LEASING_PAGE_SECTION: PageSectionInfo = {
  code: 'leasing',
  label: 'Leasing',
  icon: 'assignment_ind'
};
const SPACES_PAGE_SECTION: PageSectionInfo = {
  code: 'spaces',
  label: 'Spaces',
  icon: 'space_dashboard'
};
const ACCOUNTS_PAGE_SECTION: PageSectionInfo = {
  code: 'accounts',
  label: 'Accounts',
  icon: 'account_balance_wallet'
};
const SETTINGS_PAGE_SECTION: PageSectionInfo = {
  code: 'settings',
  label: 'Settings',
  icon: 'settings_applications'
}

export const districtPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];

export const generalLeasingPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];

export const exhibitionPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];
export const kiosksPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];
export const marketsPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];
export const digitalAdvertisingPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];
export const popUpsPageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];
export const storagePageSections: PageSectionInfo[] = [
  PIPELINE_PAGE_SECTION,
  LEASING_PAGE_SECTION,
  SPACES_PAGE_SECTION,
  ACCOUNTS_PAGE_SECTION,
  SETTINGS_PAGE_SECTION
];

export const spacesItems: PageSectionItemInfo[] = [
  {
    label: 'All Spaces',
    componentToRegister: ManageSpacesComponent,
    pageName: 'Manage spaces',
    idPrefix: 'spaces_all',
    sectionCode: 'spaces',
    pageCode: 'spaces_all',
    openType: 'tab',
    data: {
      selectedTabCode: 'spaces'
    },
    featureCodes: ['spaces_admin']
  },
  // {
  //   label: 'Floor plan',
  //   componentToRegister: ManageSpacesComponent,
  //   pageName: 'Manage spaces',
  //   idPrefix: 'spaces_floor_plan',
  //   sectionCode: 'spaces',
  //   pageCode: 'spaces_floor_plan',
  //   openType: 'tab',
  //   data: {
  //     selectedTabCode: 'floor_plan'
  //   },
  //   featureCodes: ['spaces_admin']
  // }
]

export const pipelineItems: PageSectionItemInfo[] = [
  {
    label: 'Applications',
    componentToRegister: ApplicationsListComponent,
    pageName: 'Applications',
    idPrefix: 'applications',
    sectionCode: 'leads',
    pageCode: 'applications',
    openType: 'tab',
    data: {},
    featureCodes: ['pipeline_app_view_all']
  },
  {
    label: 'Pipeline',
    componentToRegister: PipelineListComponent,
    pageName: 'Pipeline',
    idPrefix: 'leads',
    sectionCode: 'leads',
    pageCode: 'leads',
    openType: 'tab',
    data: {},
    featureCodes: ['pipeline_pipeline_view_all', 'pipeline_pipeline_view_stats']
  },
  {
    label: 'Contacts',
    componentToRegister: RetailersListComponent,
    pageName: 'Contacts',
    idPrefix: 'retailers',
    sectionCode: 'leads',
    pageCode: 'retailers',
    openType: 'tab',
    data: {},
    featureCodes: ['pipeline_retailers_view_all']
  },
  {
    label: 'Application Forms',
    componentToRegister: ManageApplicationFormsComponent,
    pageName: 'Application Forms',
    idPrefix: 'application_forms',
    sectionCode: 'leads',
    pageCode: 'application_forms',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_manage_forms_admin']
  }
]

export const settingsItems: PageSectionItemInfo[] = [
  {
    label: 'Details',
    componentToRegister: SettingsDetailsComponent,
    pageName: 'Settings',
    idPrefix: 'settings',
    sectionCode: 'settings',
    pageCode: 'settings',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_details_view']
  },
  {
    label: 'Manage Listing',
    componentToRegister: ManageListingComponent,
    pageName: 'Manage Listing',
    idPrefix: 'manage_listing',
    sectionCode: 'settings',
    pageCode: 'manage_listing',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_manage_listing_admin']
  },
  {
    label: 'Users and access',
    componentToRegister: UsersAndAccessComponent,
    pageName: 'Users and access',
    idPrefix: 'users_and_settings',
    sectionCode: 'settings',
    pageCode: 'users_and_settings',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_users_view_all']
  },
  {
    label: 'Hardware',
    componentToRegister: HardwareSettingsComponent,
    pageName: 'Hardware',
    idPrefix: 'hardware',
    sectionCode: 'settings',
    pageCode: 'hardware',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_hardware_view_all']

  },
  {
    label: 'Billing and Banking Details',
    componentToRegister: SettingsBillingAndBankingComponent,
    pageName: 'District Banking details',
    idPrefix: 'district_banking_details',
    sectionCode: 'settings',
    pageCode: 'district_banking_details',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_billing_and_banking_admin']
  },
  {
    label: 'Payments',
    componentToRegister: PaymentsSettingsComponent,
    pageName: 'Payments',
    idPrefix: 'payments',
    sectionCode: 'settings',
    pageCode: 'payments',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_internal_admin']
  },
  {
    label: 'Template Builder',
    componentToRegister: TemplateBuilderComponent,
    pageName: 'Template Builder',
    pageCode: 'template_builder',
    idPrefix: 'template_builder',
    sectionCode: 'settings',
    openType: 'tab',
    data: {},
    featureCodes: ['settings_template_builder']
  }
]

export const leasingItems: PageSectionItemInfo[] = [
  {
    label: 'Occupancy and planning',
    componentToRegister: LeasePlanningViewComponent,
    pageName: 'Occupancy and planning',
    idPrefix: 'lease-planning',
    sectionCode: 'leasing',
    pageCode: 'lease-planning',
    openType: 'tab',
    data: {},
    featureCodes: ['leasing_planned_view_all', 'leasing_planned_view_occupancy']
  },
  {
    label: 'Tenants',
    componentToRegister: CurrentLeasesListComponent,
    pageName: 'Tenants',
    idPrefix: 'current-leases',
    sectionCode: 'leasing',
    pageCode: 'current-leases',
    openType: 'tab',
    data: {},
    featureCodes: ['leasing_current_view_all']
  },
  {
    label: 'Lease schedule',
    componentToRegister: LeaseScheduleListComponent,
    pageName: 'Lease schedule',
    idPrefix: 'lease-schedule',
    sectionCode: 'leasing',
    pageCode: 'lease-schedule',
    openType: 'tab',
    data: {},
    featureCodes: ['leasing_schedule_view_all']
  },
  {
    label: 'Create a lease',
    componentToRegister: CreateALeaseDialogComponent,
    pageName: 'Create a lease',
    idPrefix: 'create-a-lease',
    sectionCode: 'leasing',
    pageCode: 'create-a-lease',
    openType: 'dialog',
    data: {
      createMethod: LeaseCreateMethod.NEW_LEASE,
      lease: null,
      header: 'Create a new lease'
    },
    featureCodes: ['leasing_create_add_one']
  }
]

export const accountsItems: PageSectionItemInfo[] = [
  {
    label: 'Transactions',
    componentToRegister: TransactionsListViewComponent,
    pageName: 'Transactions',
    idPrefix: 'transactions',
    sectionCode: 'accounts',
    pageCode: 'transactions',
    openType: 'tab',
    data: {},
    featureCodes: ['accounts_transactions_view_all', 'accounts_transactions_view_chart']
  },
  {
    label: 'Run Tenant Pay-outs',
    componentToRegister: RunTenantPayoutsViewComponent,
    pageName: 'Run Tenant Pay-outs',
    idPrefix: 'run-tenant-payouts',
    sectionCode: 'accounts',
    pageCode: 'run-tenant-payouts',
    openType: 'tab',
    data: {},
    featureCodes: ['accounts_internal_admin']
  },
  {
    label: 'Run Client Pay-out',
    componentToRegister: RunClientPayoutsViewComponent,
    pageName: 'Run Client Pay-out',
    idPrefix: 'run-client-payouts',
    sectionCode: 'accounts',
    pageCode: 'run-client-payouts',
    openType: 'tab',
    data: {},
    featureCodes: ['accounts_internal_admin']
  },
  {
    label: 'Pay-out History',
    componentToRegister: PayoutHistoryListComponent,
    pageName: 'Pay-out History',
    idPrefix: 'payout-history',
    sectionCode: 'accounts',
    pageCode: 'payout-history',
    openType: 'tab',
    data: {},
    featureCodes: ['accounts_payouts_view_all', 'accounts_payout_history_view_all']
  },
  {
    label: 'Run Products',
    componentToRegister: RunProductsComponent,
    pageName: 'Run Products',
    idPrefix: 'run-products',
    sectionCode: 'accounts',
    pageCode: 'run-products',
    openType: 'tab',
    data: {},
    featureCodes: ['retailer_products_import_products', 'retailer_products_import_inventory']
  },
]

export const supportItem: PageSectionItemInfo = {
  label: 'Support',
  componentToRegister: CustomerSupportComponent,
  pageName: 'Support',
  idPrefix: 'support',
  sectionCode: 'support',
  pageCode: 'support',
  openType: 'tab',
  data: {},
  featureCodes: []
}



