<div fxLayout="column" fxLayoutGap="20px">
  <app-page-header
    [section]="'Base Template Builder'"
    [title]="'Setup your default communication templates'">
  </app-page-header>

  <mat-sidenav-container class="sidenav-container body-1-regular-2" fxFlexOffset="20px">
    <!--  left Side Nav-->

    <mat-sidenav #leftSideNav [fixedBottomGap]="0" [fixedInViewport]="false" [fixedTopGap]="0" class="sidenav-left"
                 mode="side" opened
                 position="start">
      <div class="sidenav-left-content template-left-content" fxLayout="column" fxLayoutGap="15px">


        <ng-container [ngSwitch]="isTemplateSelected" class="full-width" fxLayout="column">
          <ng-container *ngSwitchCase="false">

            <div class="full-width" fxLayout="row">
              <div fxLayout="column" fxLayoutGap="15px">
                <div class="heading-default">Select a template to edit by clicking on its name. Activate a template by
                  clicking on the toggle
                </div>
              </div>
            </div>

            <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="15px">
                <div class="body-1-default">Retailer Communication</div>
                <div *ngFor="let template of retailTemplates" fxFlexOffset="5px" fxLayout="row"
                     fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-slide-toggle (change)="changeNotificationEnabled($event, template)" [checked]="template.active"
                                    [disabled]="loader.loading$ | async"
                                    class="template-slide-toggle"
                                    color="primary"></mat-slide-toggle>
                  <span (click)="selectTemplate(template)"
                        class="heading-default btn-cursor"> {{ template.templateName }}</span>
                </div>
              </div>
            </div>
            <div class="full-width" fxLayout="row" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="body-1-default">System Notifications</div>
                <div *ngFor="let template of systemTemplates" fxFlexOffset="5px" fxLayout="row"
                     fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-slide-toggle (change)="changeNotificationEnabled($event, template)" checked="true" class="template-slide-toggle"
                                    color="primary"
                                    disabled></mat-slide-toggle>
                  <span (click)="selectTemplate(template)"
                        class="heading-default btn-cursor"> {{ template.templateName }}</span>
                </div>
              </div>
            </div>

            <div class="full-width" fxLayout="row" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="body-1-default">Internal Communication</div>
                <div *ngFor="let template of internalTemplates" fxFlexOffset="5px" fxLayout="row" fxLayoutAlign="start center"
                     fxLayoutGap="10px">
                  <mat-slide-toggle (change)="changeNotificationEnabled($event, template)" [checked]="template.active"
                                    [disabled]="loader.loading$ | async"
                                    class="template-slide-toggle"
                                    color="primary"></mat-slide-toggle>
                  <span (click)="selectTemplate(template)"
                        class="heading-default btn-cursor"> {{ template.templateName }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="true">
            <div fxLayout="row">
              <span>You are in edit mode. You can add dynamic values to your template and manage recipients of these emails.</span>
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxLayoutGap="10px">
                <div class="body-1-default">You are currently editing</div>
                <div class="currently-selected-template-icon" fxLayout="row" fxLayoutAlign="start center"
                     fxLayoutGap="10px">
                  <mat-icon class="material-symbols-outlined">done</mat-icon>
                  <span class="heading-default"> {{ selectedTemplate!.templateName }}</span>
                </div>
              </div>
            </div>

            <div class="full-width" fxLayout="row">
              <div fxLayout="column" fxLayoutGap="15px">
                <div class="body-1-default">Dynamic Values</div>
                <div>These dynamic values are required for this specific template. They can be found under merge tags in
                  the editor
                </div>

                <div *ngFor="let tag of selectedTemplate!.mergeTags">
                  <button class="button-primary merge-tag-display" mat-flat-button>
                    <div class="button-text">{{ utils.camelCaseToWords(Object.keys(tag)[0]) }}</div>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>
      </div>
    </mat-sidenav>

    <!--  Main Content-->
    <mat-sidenav-content cdkScrollable class="sidenav-main-content">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">

        <!--      Main content area-->
        <div class="content-padding" fxLayout="row" fxLayoutAlign="space-between center">
          <app-email-template-designer #templateDesignerComponent (saveTemplate)="saveAllChanges()" (selectedTemplateChange)="changeSelectedTemplate($event)"
                                       [offeringUuid]="currentLandlord" [selectedTemplate]="this.selectedTemplate!"
                                       style="width: 100%">

          </app-email-template-designer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
