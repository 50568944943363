import {Component, Injector, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {PageHeaderTab} from "../../../core/components/page-header/page-header-tabs.interface";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {AgreementType, OccupancyType} from "../leasing.model";
import {LeasePosSettingsComponent} from "./lease-pos-settings/lease-pos-settings.component";

@Component({
  selector: 'app-lease-view',
  templateUrl: './lease-view.component.html',
  styleUrls: ['./lease-view.component.scss']
})
export class LeaseViewComponent extends BasePageComponent {
  @ViewChild('posSettingsComponent') posSettingsComponent!: LeasePosSettingsComponent;

  leaseId!: string;
  occupancyType!: OccupancyType;
  agreementType!: AgreementType;

  tabs: PageHeaderTab[] = [
    {
      name: 'Details',
      isActive: true,
      code: "lease_details",
      disabled: false
    },
    {
      name: 'Turnover',
      isActive: false,
      code: "lease_turnover",
      disabled: true
    }
  ];

  activeTab: string = "lease_details"
  reloadSubject: Subject<void> = new Subject<void>();
  reloadPosSubject: Subject<void> = new Subject<void>();

  constructor(injector: Injector, private contextService: CurrentContextService) {
    super(injector);
    this.leaseId = this.data.id;
    this.occupancyType = this.data.occupancyType;
    this.agreementType = this.data.agreementType;

    this.tabs[1].disabled = !this.contextService.checkUserFeatures(['leasing_current_view_sales_upload', 'leasing_current_edit_sales_upload', 'leasing_current_view_turnover_upload', 'leasing_current_edit_turnover_upload'],  this.offering!.offeringUuid);
  }

  changeTabs($event: PageHeaderTab): void {
    this.activeTab = $event.code;
  }

  getInitialTab(): PageHeaderTab {
    return this.tabs.at(0)!;
  }

  reloadLeaseDetails() {
    this.reloadSubject.next();
  }

  reloadPosSettings() {
    this.reloadPosSubject.next();
  }

  get isHeadLease() {
    return this.occupancyType == OccupancyType.SHARED && this.agreementType == AgreementType.LEASE;
  }

  protected readonly OccupancyType = OccupancyType;
  protected readonly AgreementType = AgreementType;
}
