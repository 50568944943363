<div class="form-stepper-container page-padding" fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="5px">
    <div class="subheading-default" fxFlex="26">Application form builder</div>

    <div class="form-stepper" fxLayout="row" fxFlex="52">
      <div class="stepper-section" fxLayout="column" fxLayoutGap="10px"
           [class.stepper-section-selected]="selectedIndex == step.index"
           [class.stepper-section-completed]="selectedIndex > step.index"
           *ngFor="let step of steps"
           (click)="setIndex(step.index)">

        <div class="stepper-label">{{step.label}}</div>

        <div class="stepper-bar-container" fxLayout="row" fxLayoutAlign="center center">
          <div class="stepper-bar"></div>
          <div class="stepper-progress-indicator"></div>
        </div>
      </div>
    </div>

    <div class="subheading-default" fxFlex="22"></div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title-default" *ngIf="selectedIndex != 0">{{formTemplate.formName}}</div>
    <div class="footer-regular" *ngIf="selectedIndex != 0 && formTemplate && formTemplate.updateDate">Last updated on {{dateUtils.displayDayAndDateWithTime(formTemplate!.updateDate)}}</div>
  </div>

</div>

<app-name-form (closeTab)="closeTab()"
               (formChange)="formTemplateChanges($event)"
               (nextStep)="advanceForm()"
               *ngIf="selectedIndex == 0"
               [edit]="edit"
               [formTemplate]="formTemplate"></app-name-form>

<app-add-questions (formChange)="formTemplateChanges($event)"
                   (nextStep)="advanceForm()"
                   *ngIf="selectedIndex == 1"
                   [edit]="edit"
                   [offering]="offering!"
                   [formTemplate]="formTemplate"></app-add-questions>

<app-smart-form-configure (formChange)="formTemplateChanges($event)"
                          (nextStep)="advanceForm()"
                          *ngIf="selectedIndex == 2"
                          [edit]="edit"
                          [offering]="offering!"
                          [formTemplate]="formTemplate"></app-smart-form-configure>
