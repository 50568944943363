import {GenericTypeDef} from '../../../core/model/generic-type-def.model';

export interface OfferingSpacesInterface {
  id: number;
  districtUuid: string;
  spaceType: SpaceType;
  spaceName: string;
  spaceSize: number;
  startDate: Date;
  endDate: Date | string | null;
  locked: boolean;
  hasHadLease: boolean;
  earliestEndDate: Date | null;
  latestStartDate: Date | null;
  districtSpacesIndustries: GenericTypeDef[];
}

export interface SpaceFilterRequest {
  spaceName: string | null;
  locked: boolean | null;
}

export enum SpaceType {
  FASHION = 'FASHION',
  FITNESS = 'FITNESS',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  HEALTH_PERSONAL_CARE = 'HEALTH_PERSONAL_CARE',
  HOME = 'HOME',
  LIFESTYLE = 'LIFESTYLE',
  PETS = 'PETS',
  SOKO_COMPOUND = 'SOKO_COMPOUND',
  KIOSK = 'KIOSK'
}

export interface DailyDistrictOccupancy {
  id: number;
  districtUuid: string;
  day: string;
  occupiedSpace: number;
  glaSqm: number;
  percentageOccupancy: number;
}

export interface LifeTimeDistrictOccupancyStats {
  districtUuid: string;
  avgSpaceSize: number;
  avgGlaSqm: number;
  avgPercentageOccupancy: number;
  avgTerm: number;
  avgRentalPricingPerSqmValue: number;
}

export interface OfferingSpaceStatistics {
  id: number,
  avgRentalPricingPerSqmValue: number;
  avgTerm: number;
}

export interface OfferingSpaceLeaseHistory {
  uuid: string;
  districtSpaceId: number;
  spaceName: string;
  spaceSize: number;
  districtUuid: string;
  districtSpaceStartDate: Date;
  districtSpaceEndDate: Date;
  leaseUuid: string;
  leaseStartDate: Date;
  leaseEndDate: Date;
  leaseStatus: string;
  rentalType: string;
  monthlyPricePerSqm: number;
  turnoverPercentageValue: number;
  spaceAvailabilityStatus: string;
  leaseGridSpaceUuid: string;
  retailerId: number;
  companyName: string;
  isExpanded: boolean;
}
