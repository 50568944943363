import {Injectable} from "@angular/core";
import * as uuid from 'uuid'


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

  randFormat = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'});
  randFormatWithoutCents = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR', maximumFractionDigits: 0});
  randFormatCompact = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR', maximumFractionDigits: 0, notation: 'compact'});

  compactFormat = new Intl.NumberFormat('en', {notation: "compact"});
  ordinalNumberFormat = new Intl.PluralRules('en', {type: "ordinal"});

  suffixes: Map<Intl.LDMLPluralRule, string> = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  displayUuid(uuid: string): string {
    if (uuid) {
      return uuid.substring(0, 8).toUpperCase();
    }
    return uuid;
  }

  displayStatus(status: string): string {
    if (status) {
      const words = status.split('_');
      const formatted = words.map(word => {
        const lowerCase = word.toLowerCase();
        return lowerCase[0].toUpperCase() + lowerCase.slice(1);
      });
      return formatted.join(' ');
    }
    return '';
  }

  displayString(string: string | null, length: number): string {
    if (string != null) {
      if (string.length > length) {
        return (string.slice(0, length - 3) + '...');
      }
      return string;
    }
    return '-';
  }

  displayListAsString(list: string[]) {
    if (list) {
      return list.join(', ');
    }
    return '';
  }

  generateCodeFromString(string: string): string {
    return string.toLowerCase().replace(' ', '_');
  }

  displayNumber(amount: number | null, showZero?: boolean): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {maximumFractionDigits: 0});
    }
    return showZero ? '0' : '-';
  }

  displayDecimal(amount: number | null, digits: number): string {
    if (amount || amount == 0) {
      return amount.toLocaleString('en-ZA', {maximumFractionDigits: digits});
    }
    return '-';
  }

  displayOrdinalNumber(amount: number | null): string {
    if (amount) {
      const category = this.ordinalNumberFormat.select(amount);
      const suffix = this.suffixes.get(category)!;
      return amount + suffix;
    }
    return '-';
  }

  displayOrdinalSuffix(amount: number | null): string {
    if (amount) {
      const category = this.ordinalNumberFormat.select(amount);
      return this.suffixes.get(category)!;
    }
    return '-';
  }

  displayRandAmount(amount: number | null): string {
    if (amount) {
      return this.randFormatWithoutCents.format(amount);
    }
    return '-';
  }

  displayCompactRandAmount(amount: number | null): string {
    if (amount) {
      return this.randFormatCompact.format(amount);
    }
    return '-';
  }

  displayRandAmountWithDecimal(amount: number | null): string {
    if (amount) {
      return this.randFormat.format(amount);
    }
    return '-';
  }

  displayCompactNumber(amount: number | null): string {
    if (amount == 0) {
      return '0';
    }
    if (amount) {
      return this.compactFormat.format(amount);
    }
    return '-';
  }

  openLinkInNewTab(link: string): void {
    window.open(this.sanitiseUrl(link), '_blank', 'noopener noreferrer');
  }

  generateUuidWithPrefix(prefix: string): string {
    return prefix + '-' + uuid.v4();
  }

  generateUuid(): string {
    return uuid.v4();
  }

  arrayNotEmptyFilter<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  capitalizeAndLower(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  toTitleCase(sentence: string): string {
    return sentence.split(" ").map((l: string) => l[0].toUpperCase() + l.substr(1)).join(" ")
  }

  camelCaseToWords(str: string): string {
    return str.replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  isValidUuid(inputUuid: string): boolean {
    return this.uuidRegex.test(inputUuid);
  }

  sanitiseUrl(url: string) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return '//' + url;
  }
}
