import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  ApplicationForm,
  FormQuestionFilter,
  QuestionTemplate
} from "../../../core/model/retailer/application-form.model";
import {
  ControlType,
  QuestionItem,
  TemplateComponent,
  templateComponents
} from "./add-questions/question-components.model";

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  get baseUrl() {
    return environment.apiRetailersHost + '/console/offering/district/settings/application-forms'
  }

  constructor(private http: HttpClient) {
  }

  createNewFormTemplate(form: ApplicationForm) {
    return this.http.post<ApplicationForm>(this.baseUrl, form);
  }

  updateFormTemplate(form: ApplicationForm) {
    return this.http.post<ApplicationForm>(`${this.baseUrl}/${form.uuid}`, form);
  }

  public uploadFormFile(fileToUpload: File) {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    return this.http.post(this.baseUrl + `/file-upload`, formData, { responseType: 'text' });
  }

  downloadFormFile(fileId: string, questionFilter: FormQuestionFilter): Observable<ArrayBuffer> {
    const requestParams = new HttpParams()
      .set('filter', JSON.stringify(questionFilter));
    const options = { responseType: 'arraybuffer' as 'json', params: requestParams };
    return this.http.get<ArrayBuffer>(this.baseUrl + `/file-download/${fileId}`, options);
  }

  public getControlTemplate(questionTemplate: QuestionTemplate): TemplateComponent | null {
    let filtered: TemplateComponent[] = templateComponents.filter(template => template.controlType === questionTemplate.controlType);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return null;
  }

  // public getTemplateComponentFromQuestion(questionTemplate: QuestionTemplate): TemplateComponent {
  //
  //   return {
  //     label: (questionTemplate.controlDescription) ? questionTemplate.controlDescription : '',
  //     controlType: questionTemplate.controlType,
  //     icon: this.getIconFromControlTemplate(questionTemplate),
  //     type: ComponentType.QUESTION
  //   }
  // }

  isTypeHeading(item: QuestionItem) {
    return item.template.controlType == ControlType.HEADING
      || item.template.controlType == ControlType.HEADING_TEXT
      || item.template.controlType == ControlType.INFO_FILE;
  }

  get fileExtensions() {
    return {
      image: ['.jpg', '.png', '.jpeg'],
      document: ['.pdf', '.doc', '.docx', '.txt'],
      dataFile: ['.xls', '.xlsx', '.xlsm', '.csv']
    }
  }
}
