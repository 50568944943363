<div class="add-questions-container" fxLayout="row" cdkDropListGroup>

  <div class="component-container" fxLayout="column" fxFlex="300px" fxLayoutGap="30px">

    <!--  LEFT-->

    <div fxLayout="column" fxLayoutGap="15px">
      <div class="heading-regular">
        Drag and drop components to your form. You can add questions from previous forms.
      </div>
      <div class="full-width">
        <mat-form-field class="custom-text-field" style="width: 100%" appearance="outline">
          <input matInput placeholder="Search Components" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()">
          <mat-icon class="search-icon-suffix" matIconSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="20px" *ngIf="displayedFormComponents.length > 0">
      <div class="heading-bold">Form Components</div>
      <div class="component-wrapper" fxLayout="row wrap"
           cdkDropList cdkDropListSortingDisabled
           #formComponentsList="cdkDropList"
           [cdkDropListData]="displayedFormComponents"
           [cdkDropListConnectedTo]="[formTemplateList]"
           (cdkDropListDropped)="drop($event)"
           (cdkDropListExited)="exited($event, ComponentType.FORM)"
           (cdkDropListEntered)="entered($event, ComponentType.FORM)"
      >
        <div class="template-component btn-cursor"
             cdkDrag [cdkDragData]="comp"
             (click)="addNewQuestionItem(comp, questionItems.length)"
             *ngFor="let comp of displayedFormComponents"
             fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-icon class="component-icon" [svgIcon]="comp.template.icon"></mat-icon>
          <div class="body-1-regular-1">{{comp.template.label}}</div>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="20px" *ngIf="displayedQuestionComponents.length > 0">
      <div class="heading-bold">Question Components</div>
      <div class="component-wrapper" fxLayout="row wrap"
           cdkDropList cdkDropListSortingDisabled
           #questionComponentsList="cdkDropList"
           [cdkDropListData]="displayedQuestionComponents"
           [cdkDropListConnectedTo]="[formTemplateList]"
           (cdkDropListDropped)="drop($event)"
           (cdkDropListExited)="exited($event, ComponentType.QUESTION)"
           (cdkDropListEntered)="entered($event, ComponentType.QUESTION)"
      >
        <div class="template-component btn-cursor"
             cdkDrag [cdkDragData]="comp"
             (click)="addNewQuestionItem(comp, questionItems.length)"
             *ngFor="let comp of displayedQuestionComponents"
             fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-icon class="component-icon" [svgIcon]="comp.template.icon"></mat-icon>
          <div class="body-1-regular-1">{{comp.template.label}}</div>
        </div>
      </div>
    </div>

  </div>

<!--  MIDDLE-->

  <div class="middle-container" fxLayout="column" fxFlex fxLayoutGap="15px" cdkScrollable
       cdkDropList
       #formTemplateList="cdkDropList"
       [cdkDropListData]="questionItems"
       (cdkDropListDropped)="drop($event)">
    <div class="form-container" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="column" *ngFor="let item of questionItems; let i = index">
        <div class="question-card btn-cursor ps-0"
             [class.question-card-active]="selectedItem?.uuid == item.uuid"
             fxLayout="row" cdkDrag
             (click)="selectQuestionItem(item)">
          <div class="question-card-left" fxLayout="column" fxLayoutAlign="center start">
            <mat-icon class="more-icon">more_vert</mat-icon>
          </div>

          <div class="full-width" fxLayout="column" fxLayoutGap="15px">
            <div class="question-card-header pb-2" fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <mat-form-field class="custom-select-field-borderless" appearance="outline" fxLayoutGap="5px">
                  <div class="select-icon me-2" matPrefix>
                    <mat-icon [svgIcon]="item.template.icon"></mat-icon>
                  </div>
                  <mat-select [(ngModel)]="item.template" (ngModelChange)="setControlPayload(item)">
                    <mat-option *ngFor="let comp of templateComponents" [value]="comp">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <div class="select-icon">
                          <mat-icon [svgIcon]="comp.icon"></mat-icon>
                        </div>
                        <div class="body-1-default">{{comp.label}}</div>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div class="body-1-regular-2" *ngIf="getControlType(item) == ControlType.SELECT && getQuestionControlPayload(item).selectType == 'MULTI'">
                  Multiple answers accepted
                </div>
                <div class="btn-cursor" (click)="removeItem(item)">
                  <mat-icon class="material-icons-outlined remove-icon">delete</mat-icon>
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="10px">
              <pre class="heading-bold">{{displayQuestionLabel(item)}}</pre>
              <div>

                <div *ngIf="getControlType(item) == ControlType.HEADING"></div>

                <div *ngIf="getControlType(item) == ControlType.HEADING_TEXT">
                  <pre class="heading-regular">{{getQuestionControlPayload(item).label ? getQuestionControlPayload(item).label : 'Your sub-heading goes here'}}</pre>
                </div>

                <div *ngIf="getControlType(item) == ControlType.TEXT">
                  <mat-form-field [class.custom-text-field-fill]="item.uuid === selectedItem?.uuid"
                                  class="custom-text-field-full-width form-input-field" appearance="outline">
                    <input matInput placeholder="Enter your answer" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="getControlType(item) == ControlType.SELECT">
                  <mat-selection-list class="custom-selection-list" readonly
                                      [ngClass]="item.uuid == selectedItem?.uuid ? 'custom-selection-list-fill' : 'custom-selection-list-outline'"
                                      fxLayout="column" fxLayoutGap="5px"
                                      *ngIf="getQuestionControlPayload(item).selectType == 'MULTI'">
                    <mat-list-option *ngFor="let option of getQuestionControlPayload(item).options!"
                                     [value]="option.valueCode"
                                     togglePosition="before">
                      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                        <div>{{option.label}}</div>
                        <div class="close-button-icon" (click)="removeOptionFromQuestion(item, option)"><mat-icon>close</mat-icon></div>
                      </div>
                    </mat-list-option>
                    <div class="add-list-item"
                         [class.add-list-item-outline]="item.uuid != selectedItem?.uuid"
                         fxLayoutGap="10px" (click)="addOptionToQuestion(item)">
                      <div class="body-1-primary">Add Choice</div>
                      <mat-icon>add</mat-icon>
                    </div>
                  </mat-selection-list>

                  <mat-selection-list class="custom-selection-list" readonly
                                      [ngClass]="item.uuid == selectedItem?.uuid ? 'custom-selection-list-fill' : 'custom-selection-list-outline'"
                                      fxLayout="column" fxLayoutGap="5px"
                                      [multiple]="false"
                                      *ngIf="getQuestionControlPayload(item).selectType == 'SINGLE'">
                    <mat-list-option *ngFor="let option of getQuestionControlPayload(item).options!"
                                     [value]="option.valueCode"
                                     togglePosition="before">
                      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                        <div>{{option.label}}</div>
                        <div class="close-button-icon" (click)="removeOptionFromQuestion(item, option)"><mat-icon>close</mat-icon></div>
                      </div>
                    </mat-list-option>
                    <div class="add-list-item"
                         [class.add-list-item-outline]="item.uuid != selectedItem?.uuid"
                         fxLayoutGap="10px" matRipple (click)="addOptionToQuestion(item)">
                      <div class="body-1-primary">Add Choice</div>
                      <mat-icon>add</mat-icon>
                    </div>
                  </mat-selection-list>
                </div>

                <div *ngIf="getControlType(item) == ControlType.CHOICE">
                  <mat-selection-list class="custom-selection-list-outline" readonly
                                      fxLayout="column" fxLayoutGap="5px"
                                      [multiple]="false">
                    <mat-list-option togglePosition="before" [value]="getQuestionControlPayload(item).selectedOption">Yes</mat-list-option>
                    <mat-list-option togglePosition="before" [value]="getQuestionControlPayload(item).unselectedOption">No</mat-list-option>
                  </mat-selection-list>
                </div>

                <div *ngIf="getControlType(item) == ControlType.LOCATION">
                  <mat-form-field [class.custom-text-field-fill]="item.uuid === selectedItem?.uuid"
                                  class="custom-text-field-full-width form-input-field" appearance="outline">
                    <input
                      matInput readonly
                      matGoogleMapsAutocomplete
                      placeholder="Enter your location"
                      (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
                      (onLocationSelected)="onAddressLocationSelected($event)">
                  </mat-form-field>
                </div>

                <div *ngIf="getControlType(item) == ControlType.FILE" fxLayout="column" fxLayoutGap="10px">

                  <div fxLayout="row" fxLayoutGap="15px">
                    <div *ngFor="let file of getQuestionControlPayload(item).files">

                      <input id="file-upload" type="file" [accept]="getQuestionControlPayload(item).fileTypes!" hidden readonly/>

                      <div class="file-upload-container btn-cursor" [class.file-upload-container-fill]="item.uuid == selectedItem?.uuid"
                           fxLayout="column" fxLayoutAlign="space-between center" matRipple>
                        <mat-icon class="material-icons-outlined cancel-icon btn-cursor" (click)="removeFileFromQuestion(item, file)">cancel</mat-icon>
                        <mat-icon class="material-icons-outlined upload-icon">upload_file</mat-icon>
                        <div class="body-1-regular-1 py-3">{{file.label}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="add-list-item"
                       [class.add-list-item-outline]="item.uuid != selectedItem?.uuid"
                       fxLayoutGap="10px" (click)="addFileToQuestion(item)">
                    <div class="body-1-primary">Add File</div>
                    <mat-icon>add</mat-icon>
                  </div>

                </div>

                <div *ngIf="getControlType(item) == ControlType.DATE">
                  <mat-form-field [class.custom-date-picker-fill]="item.uuid == selectedItem?.uuid"
                                  class="custom-date-picker-full-width form-input-field" appearance="outline">
                    <input matInput placeholder="Select your date" [matDatepicker]="picker" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div *ngIf="getControlType(item) == ControlType.NUMBER">
                  <mat-form-field [class.custom-text-field-fill]="item.uuid === selectedItem?.uuid"
                                  class="custom-text-field-full-width form-input-field" appearance="outline">
                    <input matInput type="number" placeholder="Enter your answer" readonly>
                    <div matPrefix class="ms-2 body-1-regular-1" *ngIf="getQuestionControlPayload(item).numberType == 'CURRENCY'">R </div>
                    <div matSuffix class="me-2 body-1-regular-1" *ngIf="getQuestionControlPayload(item).numberType == 'PERCENTAGE'"> %</div>
                  </mat-form-field>
                </div>

                <div *ngIf="getControlType(item) == ControlType.RANGE">
                  <app-custom-range-selector [questionItem]="item" [readonly]="true"></app-custom-range-selector>
                </div>
              </div>
            </div>

            <div *ngIf="getControlType(item) == ControlType.INFO_FILE" fxLayout="column" fxLayoutGap="10px">

              <div fxLayout="row" fxLayoutGap="15px">
                <div *ngFor="let file of getQuestionControlPayload(item).files">
                  <label class="file-upload-container btn-cursor" [class.file-upload-container-fill]="item.uuid == selectedItem?.uuid"
                       fxLayout="column" fxLayoutAlign="space-between center" matRipple>
                    <mat-icon class="material-icons-outlined cancel-icon btn-cursor" (click)="removeFileFromQuestion(item, file)">cancel</mat-icon>
                    <mat-icon class="material-icons-outlined upload-icon">file_download</mat-icon>
                    <div class="body-1-regular-1 pt-3 pb-1 full-width" fxLayout="column" fxLayoutAlign="center center">
                      <span>{{ file.label }}</span>
                      <span class="footer-regular pb-1" *ngIf="getFileValue(item, file.valueCode!)">
                        ( {{ utils.displayString(getFileValue(item, file.valueCode!)!.fileName, 25) }} )
                      </span>
                      <span class="link-error pb-1" *ngIf="!getFileValue(item, file.valueCode!)">
                        Upload file
                      </span>
                    </div>

                  </label>
                </div>
              </div>

              <div class="add-list-item"
                   [class.add-list-item-outline]="item.uuid != selectedItem?.uuid"
                   fxLayoutGap="10px" (click)="addFileToQuestion(item)">
                <div class="body-1-primary">Add File</div>
                <mat-icon>add</mat-icon>
              </div>

            </div>

          </div>
        </div>

        <div class="question-card-divider"
             fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="15px">
          <mat-divider></mat-divider>
          <mat-icon (click)="addNewQuestionItem(questionComponents[0], i)">add</mat-icon>
          <mat-divider></mat-divider>
        </div>

      </div>


    </div>
  </div>

<!--  RIGHT-->

  <div class="right-container" fxFlex="300px">
    <app-question-config [selectedItem]="selectedItem"
                         [newFiles]="newFiles"
                         [questionFilter]="questionFilter!"
                         (itemChange)="itemChangeHandler($event)"
                         (questionTypeChange)="setControlPayload($event)"
                         (previewForm)=" previewForm()"
                         (saveForm)="saveForm($event)"></app-question-config>
  </div>

</div>




