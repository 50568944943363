import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {DistrictCardReader} from './district-card-readers.model';
import {GenericService} from '../../../shared/services/generic.service';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class DistrictCardReadersService extends GenericService<DistrictCardReader> {


  constructor(http: HttpClient) {
    super(http, environment.apiDistrictsHost + `/console/districts`);
  }

  /*
    public getAll(districtId: string): any {
      const uri = this.baseUrl(districtId);
      return new Promise((resolve, reject) => {
        this.http.get<any>(uri).subscribe((res) => {
          resolve(res);
        }, err => {
          reject(err);
        });
      });
    }*/

  public saveNew(cardReader: DistrictCardReader): Promise<boolean> {
    // const uri = this.baseUrl(model.districtUuid);
    const uri = `${this.baseUrl}/${cardReader.districtUuid}/card-reader`;

    return lastValueFrom(this.http.post(uri, cardReader))
      .then(() => {
        console.log("successfully created");
        return Promise.resolve(true);
      })
      .catch((error: string) => {
        console.error("failed to create card reader: ", error);
        return Promise.resolve(false);
      })
  }

  public remove(districtUuid: string, cardReaderUuid: string): void {

    // const uri = this.baseUrl(model.districtUuid) + `/${model.uuid}`;
    const uri = `${this.baseUrl}/${districtUuid}/card-reader/${cardReaderUuid}`;
    lastValueFrom(this.http.delete(uri))
      .then(() => {
        console.log('successfully deleted:', cardReaderUuid);
      })
      .catch((error: string) => {
        console.log(`error deleting ${cardReaderUuid}: `, error);
      })
  }

  /*  private baseUrl(districtId: string): string {
      return environment.apiDistrictsHost + `/api/districts/${districtId}/card-reader`;
    }*/
}




