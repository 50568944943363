<div fxLayout="column">

  <app-page-header (tabsChangeEvent)="changeTabs($event)"
                   [description]="'The retailers module allows you to see all the relevant retailer information. This information automatically updates across all other functionalities as the retailer makes changes.'"
                   [isTabbed]="true"
                   [section]="'Retailer View'"
                   [selectedTab]="getInitialTab()"
                   [tabs]="tabs"
                   [title]="'View specific retailer information'"
  >
  </app-page-header>

  <app-retailer-details *ngIf="retailerId" [createLease]="false" [header]="'Retailer Details'" [id]="retailerId"
                        [offering]="offering!"></app-retailer-details>

  <mat-divider fxFlexOffset="30px"></mat-divider>

  <div *ngIf="activeTab == 'retailer_details'" fxLayout="column">

    <app-retailer-billing-details *ngIf="retailerId && tenantType == TenantType.TENANT" [id]="retailerId"></app-retailer-billing-details>

    <mat-divider fxFlexOffset="30px" *ngIf="tenantType == TenantType.TENANT"></mat-divider>

    <app-retailer-leasing-history *ngIf="retailerId" [companyName]="companyName" [id]="retailerId" [tenantType]="tenantType"
                                  [offering]="offering!"></app-retailer-leasing-history>

    <mat-divider fxFlexOffset="30px"></mat-divider>

      <app-retailer-roles-and-permissions [id]="retailerId" [offering]="offering!" *ngIf="tenantType == TenantType.OFFERING"></app-retailer-roles-and-permissions>

      <mat-divider fxFlexOffset="30px" *ngIf="tenantType == TenantType.OFFERING"></mat-divider>

    <app-retailer-events *ngIf="retailerId" [id]="retailerId"></app-retailer-events>
  </div>

  <div *ngIf="activeTab == 'retailer_application'">
    <app-applicant-results *ngIf="retailerId" [id]="retailerId"></app-applicant-results>
  </div>


</div>
