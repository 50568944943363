<div style="background-color: white" fxLayout="column">

  <app-page-header [banner]="true" [title]="'Leasing Report'" [subtitle]="'Explore the report for a great overview of your leasing statistics!'"></app-page-header>

  <div class="page-padding" fxLayout="column" fxLayoutGap="30px">

    <app-dashboards-best-stats [bestMonthlyStats]="bestMonthlyStats"></app-dashboards-best-stats>

    <app-dashboards-chart-section sectionName="Rental Income"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [pickerType]="'year'"
                                  [(date1)]="year1"
                                  [(date2)]="year2"
                                  [dateFilterType]="'picker'"
                                  [chart1]="rentalIncomeChart"
                                  (datePickerClosed)="datePickerUpdated();">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Rental Income & Occupancy"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="0"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="rentalIncomeOccupancyChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Trading Density & Occupancy"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="1"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="tradingDensityOccupancyChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Rental Income & Trading Density"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="2"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="rentalIncomeTradingDensityChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Effective Income & Rent Ratio"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="3"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="effectiveIncomeRentRatioChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider fxFlexOffset="20px"></mat-divider>

    <app-dashboards-chart-section sectionName="Rental Category Statistics"
                                  [customDateRangePresets]="customDateRangePresets"
                                  [initialPreset]="'this_month'"
                                  [dateRangeIndex]="4"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="rentalIncomeByCategoryChart"
                                  [chart2]="rentalIncomePerSqmByCategoryChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

  </div>
</div>
