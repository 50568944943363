import {Injectable} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {CustomDateRangeModel, TimeFrames} from "../../shared/components/custom-date-filter/custom-date-range.model";
import {UtilsService} from '../../shared/services/utils.service';
import {PipelineService} from '../leads/pipeline/pipeline.service';
import {industryColors} from './chart-options/chart-colors.model';
import {ChartOptionsService} from "./chart-options/chart-options.service";
import {MonthlyStat, StatisticType} from "./dashboards.model";

@Injectable({
  providedIn: 'root'
})
export class DashboardUtilsService {

  allIndustries: { code: string, label: string, color: string }[] = [];

  constructor(public pipelineService: PipelineService,
              public utils: UtilsService,
              public chartOptionsService: ChartOptionsService) {
    this.loadIndustries();
  }

  loadIndustries() {
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value.map((m) => {
          const industryColor = industryColors.filter(f => f.code == m.code);
          return {
            code: m.code,
            label: m.name,
            color: industryColor.length > 0 ? industryColor[0].color : industryColors[Math.floor(Math.random() * industryColors.length)].color
          };
        })
      },
      error: err => {
        console.log(err);
      }
    })
  }

  getIndustryColors(code: string) {
    const type = this.allIndustries.filter(f => f.code === code);
    let industryColor = '';

    if (code == 'UNOCCUPIED') {
      return industryColors.filter(f => f.code == 'UNOCCUPIED')[0].color;
    }

    if (type.length > 0 && type[0].color) {
      industryColor = type[0].color;
    }

    if (type.length == 0) {
      industryColor = industryColors[Math.floor(Math.random() * industryColors.length)].color;
      this.allIndustries.push({code: code, label: this.utils.displayStatus(code), color: industryColor});
    }

    return industryColor;
  }

  getIndustryName(code: string) {
    if (code == null) return '';
    if (code == 'UNOCCUPIED') return 'Unoccupied Space';

    const codeNames: string[] = [];

    code.split(',').forEach(ea => {
      const type = this.allIndustries.filter(f => f.code === ea);
      let name = '';

      if (type.length == 0) {
        this.allIndustries.push({
          code: code,
          label: this.utils.displayStatus(ea),
          color: industryColors[Math.floor(Math.random() * industryColors.length)].color
        });
        name = this.utils.displayStatus(ea);
      }

      if (type.length > 0) {
        name = type[0].label;
      }
      codeNames.push(name);
    })

    return codeNames.join(', ');
  }

  displayBestStat(stat: MonthlyStat, fullNumber?: boolean) {
    switch (stat.statType) {
      case StatisticType.DECIMAL:
        return this.utils.displayDecimal(stat.statValue, 2);
      case StatisticType.CURRENCY:
        return fullNumber ? this.chartOptionsService.displayRandAmount(stat.statValue) : this.chartOptionsService.displayCompactRandAmount(stat.statValue);
      case StatisticType.CURRENCY_PER_SQM:
        return this.chartOptionsService.displayRandAmount(stat.statValue) + ' m²';
      case StatisticType.PERCENTAGE:
        return this.utils.displayNumber(stat.statValue) + ' %';
      case StatisticType.INTEGER:
        return this.utils.displayNumber(stat.statValue);
      case StatisticType.DAYS:
        return this.utils.displayNumber(stat.statValue) + ' days';
      case StatisticType.COMPARE:
        return stat.statFormattedValue;
    }
  }

  customDateRangeChartPreset(offeringStartDate: string): CustomDateRangeModel[] {
    return [
      new CustomDateRangeModel('all_time', 0, TimeFrames.DATE_RANGE, 'All time', false, new DateRange<Date>(new Date(offeringStartDate), new Date())),
      new CustomDateRangeModel('this_year', 0, TimeFrames.YEAR, 'This year'),
      new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
    ]
  }

  customDateRangeTablePreset(offeringStartDate: string): CustomDateRangeModel[] {
    return [
      new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
      new CustomDateRangeModel('past_month', 1, TimeFrames.MONTH, 'Past month'),
      new CustomDateRangeModel('past_3_months', 3, TimeFrames.MONTH, 'Past 3 months'),
      new CustomDateRangeModel('past_6_months', 6, TimeFrames.MONTH, 'Past 6 months'),
      new CustomDateRangeModel('past_year', 1, TimeFrames.YEAR, 'Past year'),
    ]
  }
}
