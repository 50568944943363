import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {QuestionItem} from "../../../features/leads/application-form-builder/add-questions/question-components.model";
import {ControlPayload, NumberType, RangeOption} from "../../../core/model/retailer/application-form.model";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-custom-range-selector',
  templateUrl: './custom-range-selector.component.html',
  styleUrls: ['./custom-range-selector.component.scss']
})
export class CustomRangeSelectorComponent implements OnInit, OnChanges {
  @Input() questionItem!: QuestionItem;
  @Input() readonly: boolean = false;

  selectedIncr!: RangeOption;

  constructor(public utils: UtilsService) {
  }

  ngOnInit() {
    this.updateNumberIncrements();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateNumberIncrements();
  }

  updateNumberIncrements(): void {
    const incrementSize = this.getQuestionControlPayload.increments![0].incrementSize;

    const start = Number(this.getQuestionControlPayload.start!);
    const end = Number(this.getQuestionControlPayload.end!);
    const range = end - start;
    const steps = Math.ceil(range / incrementSize);

    let array: RangeOption[] = [];

    if (steps > 15 || end <= start) {
      const endLabel = start + ' to ' + end;
      array = [
        {label: start.toString(), valueCode: null, start: start, end: start, incrementSize: incrementSize},
        {label: endLabel, valueCode: null, start: start, end: end, incrementSize: incrementSize},
      ];
    } else {
      for (let i = 0, l = steps; i <= l; i++) {
        const optionStart = (i > 0) ? (start + Math.min((i-1) * incrementSize, range)) : 0;
        const optionEnd = start + Math.min(i * incrementSize, range);
        const optionLabel = optionStart + ' to ' + optionEnd;

        const option = {
          label: optionLabel,
          valueCode: null,
          start: optionStart,
          end: optionEnd,
          incrementSize: incrementSize
        }
        array.push(option);
      }
    }

    this.getQuestionControlPayload.increments = array;

  }

  setSelectedIncr(incr: RangeOption, index: number) {
    if (index != 0 && !this.readonly) this.selectedIncr = incr;
  }

  isLowerBound(incr: RangeOption) {
    const selectedIndex = this.getQuestionControlPayload.increments!.findIndex(f => f == this.selectedIncr);
    return incr == this.getQuestionControlPayload.increments![selectedIndex - 1];
  }

  get getQuestionControlPayload(): ControlPayload {
    return this.questionItem.questionConfig!.questions[0].questionTemplate.controlPayload;
  }

  displayRangeLabel(incr: RangeOption): string {
    const start = this.utils.displayCompactNumber(incr.start);
    const end = this.utils.displayCompactNumber(incr.end);
    const defaultLabel = start + ' to ' + end;
    switch (this.getQuestionControlPayload.numberType) {
      case NumberType.NUMBER:
        return defaultLabel;
      case NumberType.PERCENTAGE:
        return start + '% to ' + end + '%';
      case NumberType.CURRENCY:
        return 'R' + start + ' to R' + end;
      default:
        return defaultLabel + ' ' + this.getQuestionControlPayload.numberType!.toLowerCase();
    }
  }

  protected readonly NumberType = NumberType;
}
