<div fxLayout="column">
  <app-page-header [section]="'Payment Settings'"
                   [title]="'Setup or link your payment configurations'"
                   [description]="'The payments module allows you to setup and configure your offering payment settings. This configuration\n'+
'automatically integrates across all other features like leasing.'">
  </app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column" fxLayoutGap="30px">

    <div fxFlexOffset="10px">
      <div fxLayout="row">
        <span class="subtitle-default">District Payment Settings</span>
        <div fxLayoutGap="10px" fxFlexOffset="30px" *ngIf="isInternalUser">
          <button class="button-action-cancel" mat-flat-button
                  *ngIf="isInternalUser"
                  (click)="activatePOSAccount(true)"
                  [disabled]="configSelectionModel.isEmpty() || !!(loader.loading$ | async)">
            <div class="button-text">Confirm POS Activated</div>
          </button>
          <button class="button-action-cancel" mat-flat-button
                  *ngIf="isInternalUser"
                  (click)="activatePOSAccount(false)"
                  [disabled]="configSelectionModel.isEmpty() || !!(loader.loading$ | async)">
            <div class="button-text">Confirm POS Deactivated</div>
          </button>
        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px"  *ngIf="posDataSource">

      <div fxLayout="row" fxLayoutGap="10px">
        <span class="heading-default">Point-of-sale software settings</span>
        <button class="button-action" mat-flat-button (click)="addPosConfig()" [disabled]="!!(loader.loading$ | async)">
          <div class="button-text">Add</div>
        </button>
        <button [disabled]="!selectedConfig || !!(loader.loading$ | async)" class="button-action" mat-flat-button
                (click)="removePosConfig()">
          <div class="button-text">Remove</div>
        </button>
      </div>

      <!-- table start-->
      <table mat-table class="custom-table" [dataSource]="posDataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="posDisplayedColumns.length">
            Point of sale
          </th>
        </ng-container>

        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef> ID</th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
              *matCellDef="let element"> {{utilsService.displayUuid(element.uuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef> ID</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{getConfigValue('displayName', element.transactionSourceConfig)}} </td>
        </ng-container>

        <ng-container matColumnDef="paymentSourceName">
          <th mat-header-cell *matHeaderCellDef>Payment Source</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('paymentSourceName', element.transactionSourceConfig)}}</td>
        </ng-container>

        <ng-container matColumnDef="paymentTypeName">
          <th mat-header-cell *matHeaderCellDef>Payment Type</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('paymentTypeName', element.transactionSourceConfig)}}</td>
        </ng-container>

        <ng-container matColumnDef="domainPrefix">
          <th mat-header-cell *matHeaderCellDef>Domain Prefix</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('domainPrefix', element.transactionSourceConfig)}}</td>
        </ng-container>

        <ng-container matColumnDef="outletId">
          <th mat-header-cell *matHeaderCellDef>Outlet ID</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('outletId', element.transactionSourceConfig)}}</td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.startDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.endDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="activationStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{utilsService.displayStatus(element.activationStatus)}}</td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="custom-table-cell" mat-cell>
            <mat-checkbox (change)="$event ? setSelectedConfig(row) : null"
                          (click)="$event.stopPropagation();" *ngIf="row.uuid"
                          [checked]="configSelectionModel.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">
            <button (click)="checkSuppliers(element)" class="button-action" mat-flat-button>
              <div class="button-text">Linked Suppliers</div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="posDisplayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: posDisplayedColumns;"
            [class.selected-row]="selectedConfig === row.uuid"></tr>
      </table>

      <mat-paginator #posPaginator class="custom-paginator"
                     [length]="posDataSource.data.length"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

<!--   !!!   CARD READERS   !!!-->

      <div class="info-card px-0" fxLayout="column" *ngIf="selectedConfig">

        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutGap="10px">
            <span class="heading-default">Payment device settings</span>
            <button class="button-action" mat-flat-button *ngIf="editingCardReaders" (click)="saveCardReadersChanges()" [disabled]="cardReadersOverlap() || !!(loader.loading$ | async)"><div class="button-text">Save</div></button>
            <button class="button-action-cancel" mat-flat-button *ngIf="editingCardReaders" (click)="cancelCardReaderChanges()" [disabled]="!!(loader.loading$ | async)"><div class="button-text">Cancel</div></button>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-error class="error-text" *ngIf="cardReadersOverlap()">Card readers cannot have over-lapping dates
          </mat-error>

          <div fxLayout="row" fxLayoutGap="15px" *ngFor="let reader of cardReaders">
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Card Reader Code</mat-label>
              <mat-select class="custom-select-field-value" [placeholder]="reader.cardReaderCode!"
                          [(ngModel)]="reader.cardReaderCode"
                          [disabled]="!cardReaderFutureDated(reader)">
                <mat-option class="custom-select-field-option" *ngFor="let available of getAvailableCardReaders(reader)"
                            [value]="available.cardReaderCode">{{available.cardReaderCode}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
              <mat-label>Start Date</mat-label>
              <input matInput placeholder="select" [matDatepicker]="startPicker"
                     [(ngModel)]="reader.startDate" (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)"
                     [disabled]="!cardReaderFutureDated(reader)"/>
              <mat-datepicker-toggle matIconSuffix [for]="startPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
              <mat-label>End Date</mat-label>
              <input (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)" [(ngModel)]="reader.endDate" [matDatepicker]="endPicker"
                     matInput placeholder="select"/>
              <mat-datepicker-toggle matIconSuffix [for]="endPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="15px" *ngFor="let newCardReader of newCardReaders">
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Card Reader Code</mat-label>
              <mat-select [(ngModel)]="newCardReader.cardReaderCode" class="custom-select-field-value"
                          placeholder="Not Assigned">
                <mat-option class="custom-select-field-option" *ngFor="let reader of newAvailableCardReaders" [value]="reader.cardReaderCode">{{reader.cardReaderCode}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
              <mat-label>Start Date</mat-label>
              <input (ngModelChange)="loadAvailableLeaseCardReaders(newCardReader, true)" [(ngModel)]="newCardReader.startDate" [matDatepicker]="startPicker"
                     matInput
                     placeholder="select"/>
              <mat-datepicker-toggle matIconSuffix [for]="startPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
              <mat-label>End Date</mat-label>
              <input (ngModelChange)="loadAvailableLeaseCardReaders(newCardReader, true)" [(ngModel)]="newCardReader.endDate" [matDatepicker]="endPicker"
                     matInput
                     placeholder="select"/>
              <mat-datepicker-toggle matIconSuffix [for]="endPicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px"  *ngIf="netCashDataSource && netCashDataSource.data.length">

      <span class="heading-default">Netcash</span>

      <!-- table start-->
      <table mat-table class="custom-table" [dataSource]="netCashDataSource" fxFlexOffset="25px" *ngIf="netCashDataSource.data.length">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="netCashDisplayedColumns.length">
            Netcash
          </th>
        </ng-container>

        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef> ID</th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
              *matCellDef="let element"> {{utilsService.displayUuid(element.uuid)}} </td>
        </ng-container>

        <ng-container matColumnDef="statementRef">
          <th mat-header-cell *matHeaderCellDef>Statement Reference</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('providerProps.beneficiaryStatementReference', element.providerConfig)}}</td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.startDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.endDate)}}</td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="netCashDisplayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: netCashDisplayedColumns;"></tr>
      </table>

      <mat-paginator #netCashPaginator class="custom-paginator"
                     [length]="netCashDataSource.data.length"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>
