import {ChartConfig, ChartDataConfig} from "../common/chart-config";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardsPerformanceReportComponent} from "./dashboards-performance-report.component";
import {ChartConfiguration} from "chart.js";
import {defaultChartOptions, defaultRadarChartOptions} from "../chart-options/chart-options.model";
import {chartColors} from "../chart-options/chart-colors.model";
import {MatTableDataSource} from "@angular/material/table";
import {RetailerPerformance} from "../dashboards.model";
import {DashboardUtilsService} from "../dashboard-utils.service";

export class PerformanceDashboardChartConfig extends ChartConfig<DashboardsPerformanceReportComponent> {
  constructor(componentInstance: DashboardsPerformanceReportComponent,
              private chartOptionsService: ChartOptionsService,
              private dashboardUtils: DashboardUtilsService) {
    super(componentInstance);
  }

  get bestMonthlyStats(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestMonthlyStats = value;
      },
      error: err => console.log(err)
    };
  }

  get salesPerformance(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y1')!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Trading density'], ['Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[salesPerformanceChartConfig] service response -> completed");
        const sales = value.chartSeries[1];
        const tradingDensity = value.chartSeries[0];

        this.instance.salesPerformanceChart.chartName = value.name;

        this.instance.salesPerformanceChart.stats.totalSales.statValue = Object(value.params)['totalSales'];
        this.instance.salesPerformanceChart.stats.avgTradingDensity.statValue = Object(value.params)['avgTradingDensity'];

        this.instance.salesPerformanceChart.chartData = {
          labels: sales.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.BLUE)
            },
            {
              label: tradingDensity.name,
              data: tradingDensity.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            },
          ]
        }
        this.instance.salesPerformanceChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.salesPerformanceChart.loading = false;
      }
    }
  }

  get salesComparison(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setAutoSkipValue(chartOptions!, 'x', false)!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[salesComparisonChartConfig] service response -> completed");
        const month1Sales = value.chartSeries[0];
        const month2Sales = value.chartSeries[1];

        this.instance.salesComparisonChart.chartName = value.name;

        this.instance.salesComparisonChart.stats.month1Sales.statValue = Object(value.params)['month1Sales'];
        this.instance.salesComparisonChart.stats.month2Sales.statValue = Object(value.params)['month2Sales'];

        const largerMonth = month1Sales.chartData.length > month2Sales.chartData.length ? month1Sales : month2Sales;

        this.instance.salesComparisonChart.chartData = {
          labels: largerMonth.chartData.map((m: any) => m.label ? m.dateLabel : ''),
          xLabels: largerMonth.chartData.map((m: any) => m.label ? m.label : ''),
          datasets: [
            {
              label: month1Sales.name,
              data: month1Sales.chartData.map((m: any) => m.data),
              order: 1,
              ...this.chartOptionsService.areaChart(chartColors.MAROON, chartColors.MAROON_85)
            },
            {
              label: month2Sales.name,
              data: month2Sales.chartData.map((m: any) => m.data),
              order: 0,
              ...this.chartOptionsService.areaChart(chartColors.YELLOW, chartColors.YELLOW_85)
            }
          ]
        }
        this.instance.salesComparisonChart.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.instance.salesComparisonChart.loading = false;
      }
    }
  }

  get salesByIndustry(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultRadarChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'r')!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Sales by Industry'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[salesByIndustryChartConfig] service response -> completed");
        const sales = value.chartSeries[0];

        this.instance.salesByIndustryChart.chartName = value.name;
        this.instance.salesByIndustryChart.stats.totalSales.statValue = Object(value.params)['totalSales'];

        this.instance.salesByIndustryChart.chartData = {
          labels: sales.chartData.map((m: any) => this.dashboardUtils.getIndustryName(m.label)),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.radarChart(chartColors.LIGHT_PURPLE, chartColors.LIGHT_PURPLE_85)
            }
          ]
        }
        this.instance.salesByIndustryChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.salesByIndustryChart.loading = false;
      }
    }
  }

  get tradingDensityByIndustry(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultRadarChartOptions))!;
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'r')!;
    this.chartOptionsService.setTooltips(chartOptions!, ['Trading density by Industry'], [], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[tradingDensityByIndustryChartConfig] service response -> completed");
        const tradingDensity = value.chartSeries[0];

        this.instance.tradingDensityByIndustryChart.chartName = value.name;
        this.instance.tradingDensityByIndustryChart.stats.avgTradingDensity.statValue = Object(value.params)['avgTradingDensity'];

        this.instance.tradingDensityByIndustryChart.chartData = {
          labels: tradingDensity.chartData.map((m: any) => this.dashboardUtils.getIndustryName(m.label)),
          datasets: [
            {
              label: tradingDensity.name,
              data: tradingDensity.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.radarChart(chartColors.BRIGHT_PINK, chartColors.BRIGHT_PINK_85)
            }
          ]
        }
        this.instance.tradingDensityByIndustryChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.tradingDensityByIndustryChart.loading = false;
      }
    }
  }

  get topPerformers(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.topPerformersBySalesDataSource = new MatTableDataSource<RetailerPerformance>(value.bySalesList);
        this.instance.topPerformersBySalesDataSource.paginator = this.instance.topPaginator;
        this.instance.topPerformersByTradingDensityDataSource = new MatTableDataSource<RetailerPerformance>(value.byTradingDensityList);
        this.instance.topPerformersByTradingDensityDataSource.paginator = this.instance.topPaginator;
        this.instance.topPerformersLoading = false;
      },
      error: err => {
        console.log(err);
        this.instance.topPerformersLoading = false;
      }
    }
  }

  get busiestSalesTimes(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setTimeTooltips(chartOptions!, [], ['Hourly Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[busiestSalesTimesChartConfig] service response -> completed");
        const sales = value.chartSeries[0];

        this.instance.busiestSalesTimesChart.chartName = value.name;
        this.instance.busiestSalesTimesChart.stats.salesPerHour.statValue = Object(value.params)['salesPerHour'];

        this.instance.busiestSalesTimesChart.chartData = {
          labels: sales.chartData.map((m: any) => m.intLabel),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.barChart(chartColors.GREEN)
            }
          ]
        }
        this.instance.busiestSalesTimesChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.busiestSalesTimesChart.loading = false;
      }
    }
  }

  get busiestSalesDays(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[busiestSalesDaysChartConfig] service response -> completed");
        const sales = value.chartSeries[0];

        this.instance.busiestSalesDaysChart.chartName = value.name;
        this.instance.busiestSalesDaysChart.stats.salesPerDay.statValue = Object(value.params)['salesPerDay'];

        this.instance.busiestSalesDaysChart.chartData = {
          labels: sales.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.barChart(chartColors.PASTEL_PINK)
            }
          ]
        }
        this.instance.busiestSalesDaysChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.busiestSalesDaysChart.loading = false;
      }
    }
  }

  get footfallStatistics(): ChartDataConfig {
    let chartOptions: ChartConfiguration<'bar'>["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setStackedAxis(chartOptions!, 'y');

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', 100)!;

    this.chartOptionsService.setTooltips(chartOptions!, [], [], ['Sales Conversion'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: any) => {
        console.log("[footfallStatisticsChartConfig] service response -> completed");
        const walkwayInstance = value.chartSeries[1];
        const storeInstance = value.chartSeries[2];
        const salesConversion = value.chartSeries[0];

        this.instance.footfallStatisticsChart.chartName = value.name;

        this.instance.footfallStatisticsChart.stats.footfallConversion.statValue = Object(value.params)['footfallConversion'];
        this.instance.footfallStatisticsChart.stats.salesConversion.statValue = Object(value.params)['salesConversion'];

        this.instance.footfallStatisticsChart.chartData = {
          labels: walkwayInstance.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: walkwayInstance.name,
              data: walkwayInstance.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.LIGHT_PURPLE)
            },
            {
              label: storeInstance.name,
              data: storeInstance.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.GREY)
            },
            {
              label: salesConversion.name,
              data: salesConversion.chartData.map((m: any) => m.data),
              order: 2,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.LIGHT_PINK)
            },
          ]
        }
        this.instance.footfallStatisticsChart.loading = false;
      },

      error: (err: any) => {
        console.log(err);
        this.instance.footfallStatisticsChart.loading = false;
      }
    }
  }
}
