import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ApplicationForm} from "../../../../core/model/retailer/application-form.model";
import {FormBuilderService} from "../form-builder.service";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingService} from '../../../../core/services/loading.service';

@Component({
  selector: 'app-name-form',
  templateUrl: './name-form.component.html',
  styleUrls: ['./name-form.component.scss']
})
export class NameFormComponent implements OnInit {
  @Output() nextStep = new EventEmitter<void>();
  @Input() edit!: boolean;
  @Output() closeTab = new EventEmitter<boolean>();
  @Output() formChange = new EventEmitter<ApplicationForm>();


  @Input() formTemplate!: ApplicationForm;
  formNameGroup: FormGroup;

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private formBuilderService: FormBuilderService,
              public loader: LoadingService) {
    this.matIconRegistry.addSvgIcon('assignment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/form-icons/assignment.svg'));

    this.formNameGroup = new FormGroup({
      formName: new FormControl('', Validators.required)
    });

    this.formNameGroup.valueChanges.subscribe((changes) => {
      this.formTemplate.formName = changes.formName;
    });

  }

  setFormName() {
    this.formChange.emit(this.formTemplate);
    this.nextStep.emit();
  }


  exit(): void {
    this.closeTab.emit(true);
  }

  saveForm(): void {
    this.formBuilderService.updateFormTemplate(this.formTemplate)
      .subscribe({
        next: value => {
          this.formTemplate = value;
        },
        error: err => console.log(err)
      });
  }

  ngOnInit(): void {
    if (this.formTemplate) {
      this.formNameGroup.get('formName')!.setValue(this.formTemplate.formName);
    }

  }
}
