import {AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {OfferingSpacesInterface} from "../model/offering-spaces.interface";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {lastValueFrom, merge, of, startWith, switchMap} from "rxjs";
import {PaginatorService} from "../../../shared/services/paginator.service";
import {ManageSpacesService} from "../service/manage-spaces.service";
import {LocationOffering} from "../../../core/model/side-nav.model";
import {UtilsService} from "../../../shared/services/utils.service";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {LandlordsService} from "../../../core/services/landlords.service";
import {ImageUploadUtilsService} from "../../../shared/services/image-upload-utils.service";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {HomeScreenComponent} from 'src/app/core/components/home-screen/home-screen.component';

@Component({
  selector: 'app-manage-floor-plan',
  templateUrl: './manage-floor-plan.component.html',
  styleUrls: ['./manage-floor-plan.component.scss']
})
export class ManageFloorPlanComponent extends BasePageComponent implements OnInit, AfterViewInit {
  availableSpaces: OfferingSpacesInterface[] = [];
  availableSpacesDataSource: OfferingSpacesInterface[] = [];
  @ViewChild('availPaginator') availPaginator!: MatPaginator;
  totalSize: number = 0;
  previewImageSrc: string | null = null;
  imageUploadFormGroup: FormGroup;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  fileName = 'no file selected';

  availableSpacesForm: FormGroup;
  showingActions = false;
  showUploadAction = false;

  constructor(injector: Injector,
    public utils: UtilsService,
    public dateUtils: DateUtilsService,
    private spaceService: ManageSpacesService,
    private landlordService: LandlordsService,
    private imageUploadUtils: ImageUploadUtilsService,
    private currentContext: CurrentContextService,
    private paginatorService: PaginatorService,
    private homeScreenComponent: HomeScreenComponent
  ) {
    super(injector);

    this.availableSpacesForm = new FormGroup({
      spaceName: new FormControl(''),
      locked: new FormControl(false)
    });

    this.imageUploadFormGroup = new FormGroup({
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required])
    });

    if (this.offering && this.offering.floorPlanImage) {
      this.previewImageSrc = this.decodeImageUrl();
    }

  }

  private decodeImageUrl(): string | null {
    let url = this.offering?.floorPlanImage ?? null;

    if (url?.startsWith('https://ips.') && !url?.endsWith('&height=660')) {
      url += '&height=660';
    }
    return url;
  }

  private decodeImageUrlFromSource(url: string): string | null {
    if (url?.startsWith('https://ips.') && !url?.endsWith('&height=660')) {
      url += '&height=660';
    }
    return url;
  }

  ngAfterViewInit(): void {
    this.searchAvailableForms();
    this.loadTotalSize();
  }

  ngOnInit(): void {
  }

  private loadTotalSize() {
    this.spaceService.loadTotalSize().then((res: number) => {
      this.totalSize =  Math.trunc(res);
    });
  }

  searchAvailableForms(): void {
    const spaceName = this.availableSpacesForm.get('spaceName')?.value ?? null;

    lastValueFrom(this.spaceService.getSpacesForOffering({
      spaceName: spaceName,
      locked: false
    }))
      .then((res: OfferingSpacesInterface[]) => {
        this.availableSpaces = res.sort((a, b) => a.spaceName.localeCompare(b.spaceName));
        this.linkAvailableSpacesPaginator();
      });
  }

  linkAvailableSpacesPaginator() {
    if (this.availPaginator) {
      this.availPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
      merge(this.availPaginator.page).pipe(
        startWith({}),
        switchMap(() => {
          return of(this.availableSpaces);
        })
      ).subscribe(res => {
        const from = this.availPaginator.pageIndex * 10;
        const to = from + 10;
        this.availableSpacesDataSource = res.slice(from, to);
      });
    }

  }

  fileChangeEvent($event: any): void {
    const reader = new FileReader();

    if ($event.target && $event.target.files && $event.target.files.length) {
      const [file] = $event.target.files;
      reader.readAsDataURL(file);

      this.fileName = this.fileInput.nativeElement.value.replace(/^.*[\\\/]/, '');

      reader.onload = () => {

        this.previewImageSrc = reader.result as string;
        this.imageUploadFormGroup.patchValue({
          fileSource: reader.result
        });

      };
      this.showingActions = true;
      this.showUploadAction = true;

    }
  }

  upload() {
    const blob = this.imageUploadUtils.dataURItoBlob(this.imageUploadFormGroup.get('fileSource')!.value);
    if (blob.size > 10485760) {
      // this.errorMessage = 'File size too large, files must be less than 10MB';
      return;
    }

    const type = this.fileName.replace(/^.+[\W]/, '');
    const file = new File([blob], this.fileName, {
      type: `image/${type}`
    });

    lastValueFrom(this.landlordService.uploadFloorPlan(file, this.currentContext.getCurrentLocation()!.uuid, this.offering!.uuid))
      .then((res: LocationOffering) => {
        let updatedOffering;
        this.previewImageSrc = this.decodeImageUrlFromSource(res.floorPlanImage);
        this.currentContext.getCurrentLocation()?.locationOfferings?.forEach((offering: LocationOffering) => {
          if (offering.uuid === this.offering!.uuid) {
            offering.floorPlanImage = res.floorPlanImage;
            updatedOffering = offering;
          }
        });

        if (updatedOffering) {
          console.log("found an updated offering running context update and offering update")
          this.currentContext.updateLocationOfferings(updatedOffering);
          this.homeScreenComponent.updateOffering(updatedOffering);
        }
        this.clearUpload();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  clearUpload(): void {
    this.fileInput.nativeElement.value = "";
    this.imageUploadFormGroup.reset();
    if (this.offering && this.offering.floorPlanImage) {
      this.previewImageSrc = this.offering.floorPlanImage;
    } else {
      this.previewImageSrc = '';
    }
    this.showUploadAction = false;
    this.showingActions = false;
  }

  getLastUpdated(): string {
    return this.dateUtils.displayFullDate(new Date())!;
  }
}
