import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UpdatePasswordService} from '../service/update-password.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class ResetPasswordComponent {
  hideFirst = true;
  hideSecond = true;

  public passwordResetForm;
  public errMessage: string | null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private service: UpdatePasswordService,
              private matIconRegistry: MatIconRegistry,
              private fb: FormBuilder,
              private domSanitizer: DomSanitizer,
              public loader: LoadingService
  ) {
    this.matIconRegistry.addSvgIcon('icon-show-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-show-password.svg'));

    this.matIconRegistry.addSvgIcon('icon-hide-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-hide-password.svg'));

    this.errMessage = null;
    this.passwordResetForm = this.resetForm();
  }

  public resetForm(): FormGroup {
    return this.fb.group({
      password: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required),
    });
  }

  private passwordInvalid(): boolean {
    const secret = this.passwordResetForm.value.password;
    const confirmSecret = this.passwordResetForm.value.confirmPassword;

    if (
      (secret.length < 8)
      || (/[A-Z]+/g.test(secret) === false)
      || (/[a-z]+/g.test(secret) === false)
      || (/[0-9]+/g.test(secret) === false)
      || (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(secret) === false)
    ) {
      this.passwordResetForm.controls['password'].setErrors({'not-valid': true});
      this.passwordResetForm.get('confirmPassword')!.setValue(null);
      this.passwordResetForm.controls['confirmPassword'].setErrors(null);
      return true;
    }

    if (secret !== confirmSecret) {
      this.passwordResetForm.get('confirmPassword')!.setValue(null);
      this.passwordResetForm.controls['confirmPassword'].setErrors({'no-match': true});
      return true;
    }

    return false;
  }

  public onSubmit(): void {
    if (this.passwordInvalid()) {
      return;
    }
    const values = this.passwordResetForm.value;
    this.service.updateContextSecrets(values.password, values.confirmPassword);
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.router.navigate([`${clientId}/forgot-password/verify`]);
  }
}
