import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../core/services/security/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentContextService} from '../../core/services/security/current-context.service';
import {ThemeService} from "../../../themes/theme-service.service";
import {LoadingService} from "../../core/services/loading.service";
import {CurrentLandlordModel} from '../../core/model/landlord.model';
import {AuthGuard} from '../../core/services/security/auth-guard.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  loading: boolean = false;
  hidePassword = true;
  clientId: string;
  @Input() forgotPassword!: boolean;
  @Output() forgotPasswordChange = new EventEmitter<boolean>();


  constructor(private authService: AuthenticationService,
              private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              private route: ActivatedRoute,
              private router: Router,
              private themeService: ThemeService,
              private currentContextService: CurrentContextService,
              public loader: LoadingService,
              private authGuard: AuthGuard) {
    this.matIconRegistry.addSvgIcon('icon-show-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-show-password.svg'));
    this.matIconRegistry.addSvgIcon('icon-hide-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-hide-password.svg'));
    this.loginForm = new FormGroup({
      email: new FormControl({value: '', disabled: this.loading}, {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl({value: '', disabled: this.loading}, {validators: [Validators.required]}),
    });

    this.clientId = route.snapshot.paramMap.get('clientId') != null ? route.snapshot.paramMap.get('clientId')! : '';

    this.currentContextService.setCurrentWebClient(this.clientId)
      .then((response: CurrentLandlordModel | null) => {
        if (response == null) {
          this.router.navigate(['/404'])
        }
        this.themeService.setThemeColors(response ? response.theme : null);
      });


  }

  userForgotPassword(): void {
    this.forgotPassword = true;
    this.forgotPasswordChange.emit(this.forgotPassword);
    this.router.navigate([`${this.clientId}/forgot-password`])
      .catch((error: string) => {
        console.log(error)
      })
  }

  onSubmit(): void {
    let value = this.loginForm.value;
    this.loading = true;

    this.authService.doLogin({email: value.email, secret: value.password})
      .then(() => {
          this.loading = false;
          let returnUrl = this.route.snapshot.paramMap.get('returnUrl');
          returnUrl = returnUrl ? returnUrl : `${this.clientId}/home/dashboard`;

          let queryParams: any = {}

          // get all query params exclude return url
          this.route.snapshot.queryParamMap.keys.filter(key => {
            return key !== 'returnUrl'
          }).forEach(key => {
            queryParams[key] = this.route.snapshot.queryParamMap.get(key);
          });


          this.currentContextService.setCurrentWebClient(this.clientId)
            .then((response) => {
              if (response == null) {
                this.router.navigate(['/404']);
              }
            });

          // if user not yet verified, take to verification screen first
          if (!this.authService.isVerified) {
            this.authGuard.tryNavigateToVerify(queryParams)
            return;
          }


          // login success -> go to referrer/homepage
          this.router.navigate([returnUrl])
            .catch((reason: string) => {
              console.log(reason)
            });
        },
        (error: any) => {
          this.loading = false;
          console.log(error)
          // login failed -> reset password and show error
          this.loginForm.get('password')!.setValue(null);
          this.loginForm.get('password')!.setErrors({'login-failed': true});
        }
      );
  }

}
