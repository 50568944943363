<div class="px-4 py-3 full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px"
     style="padding-left: 40px !important;">
  <div class="full-width full-height" fxLayout="row" fxLayoutAlign="end center" style="margin-bottom: 0">
    <button (click)="close()" class="button-close-top" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="title-default">Upload a new profile image</div>
  <form class="full-width full-height"
        fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="30px">

    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="7px">
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <label class="subtitle-default file-label-wrapper" for="file-upload">
          <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
            <div class="file-upload-button" style="width: 130px">
              <div class="button-text">Select file to upload</div>
            </div>
          </div>
          <input #fileInput (change)="fileChangeEvent($event)" accept=".jpg,.png,.jpeg" class="custom-text-field"
                 id="file-upload"
                 type="file"/>
        </label>

        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
          <mat-icon (click)="onZoom(-1)" class="btn-cursor">zoom_out</mat-icon>
          Zoom
          <mat-icon (click)="onZoom(1)" class="btn-cursor">zoom_in</mat-icon>
        </div>
      </div>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutGap="7px" style="min-height: 300px">
      <image-cropper
        (cropperReady)="cropperReady()"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        [aspectRatio]="aspectRatio"
        [containWithinAspectRatio]="true"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [roundCropper]=round
        [transform]="transform"
        backgroundColor="#EFEFF3"
        format="png"
      ></image-cropper>
    </div>


    <div class="full-width pe-4" fxLayout="row" fxLayoutAlign="center">
      <button (click)="upload()" class="button-primary" mat-flat-button style="width: 130px" type="submit">
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          Upload
        </div>
      </button>
    </div>
  </form>
</div>


