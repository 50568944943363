import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, Observable, startWith} from "rxjs";
import {PageOffering} from "../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../core/services/loading.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {ApplicationAssessmentStatus, Retailer} from "../../../leads/retailers/retailers.model";
import {RetailersService} from "../../../leads/retailers/retailers.service";
import {productJobs, JobRunConfig} from "../model/run-products.model";
import {RunProductsService} from "../service/run-products.service";

@Component({
  selector: 'app-products-jobcard',
  templateUrl: './products-jobcard.component.html',
  styleUrls: ['./products-jobcard.component.scss']
})
export class ProductsJobcardComponent implements OnInit {
  @Input() offering!: PageOffering;
  @Input() jobType: 'products' | 'inventory' = 'products'
  @Input() jobNames: string[] = [];

  runResponse: JobRunConfig[] = [];

  allRetailers: any[] = [];
  filteredRetailers!: Observable<Retailer[]>;
  selectedRetailer: Retailer | null = null;
  searchTerm = new FormControl('');

  constructor(private productsService: RunProductsService,
              public loader: LoadingService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private retailersService: RetailersService) {
  }

  ngOnInit() {
    this.loadProductsRunInfo();
    this.loadRetailers();
  }

  loadRetailers() {
    const page = {size: '1000', page: '0', sort: 'id,asc'}
    this.retailersService.getAll('', page, [{
      name: 'curated',
      val: ApplicationAssessmentStatus.APPROVED
    }]).subscribe({
      next: value => {
        this.allRetailers = value.content;
        this.filteredRetailers = this.searchTerm.valueChanges.pipe(
          startWith(''),
          map((value: string | Retailer | null) => {
            const name = typeof value == 'string' ? value : value?.companyName;
            return (name && name.trim().length > 0) ? this._filter(name as string) : this.allRetailers.slice();
          }),
        );
      },
      error: err => {
        console.log(err);
      }
    })
  }

  loadProductsRunInfo() {
    this.runResponse = [];
    productJobs.forEach(job => {
      if (this.jobNames.includes(job.id)) {
        const model = new JobRunConfig({
          id: job.id,
          jobStatus: 'Ready',
          name: job.name,
        });

        this.runResponse.push(model);

        this.productsService.getJobConfig(this.jobType).subscribe({
          next: value => {
            if (['RUNNING', 'LOCKED'].includes(value.status!)) model.jobStatus = 'Busy';
            if ('ERROR' == value.status!) model.jobStatus = 'Error';
            if (!value.enabled) model.jobStatus = 'Disabled';
            model.status = value.status;
            model.enabled = value.enabled;
            model.lockedOn = value.lockedOn;
          },
          error: err => {
            model.jobStatus = 'Error';
          }
        })
      }
    });
  }

  runService(model: JobRunConfig): void {
    if (this.isPageLoading()){
      return;
    } else {
      model.loading = true;

      switch (model.id) {
        case 'products_job_import_products':
          this.productsService.importAllProducts().subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'products_job_import_product_inventory':
          this.productsService.importAllInventory().subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'products_job_import_retailer_products':
          this.productsService.importRetailerProducts(this.selectedRetailer!.id!).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
          break;
        case 'products_job_import_retailer_product_inventory':
          this.productsService.importRetailerInventory(this.selectedRetailer!.id!).subscribe({
            next: value => this.handleRes(value, model.id!),
            error: err => this.handleErr(err, model.id!)
          })
      }
    }
  }

  public refreshJob(id: string): void {
    const model = this.getModel(id);
    if (!model) return;
    this.loadProductsRunInfo();
  }

  handleRes(res: any, id: string): void {
    const model = this.getModel(id);
    model.loading = false;
    if (res != null && res.length > this.productsService.timeOutSecs){
      model.jobStatus = 'Success';
    } else {
      model.jobStatus = 'Timeout';
    }
    this.refreshJob(id);
  }

  handleErr(err: any, id: string): void {
    const model = this.getModel(id);
    model.loading = false;
    model.jobStatus = 'Error';
    this.refreshJob(id);
  }

  private getModel(id: string): JobRunConfig {
    return this.runResponse.filter(model => model.id === id)[0];
  }

  public isPageLoading(): boolean {
    let value = false;
    this.runResponse.forEach(model => {
      if (model.loading){
        value = true;
      }
    });
    return value;
  }

  private _filter(name: string): Retailer[] {
    const filterValue = name.toLowerCase();
    return this.allRetailers.filter(option => option.companyName.toLowerCase().includes(filterValue));
  }

  selectRetailer(event: MatAutocompleteSelectedEvent) {
    this.selectedRetailer = event.option.value;
  }

  displayFn(value: Retailer) {
    if (value) {
      return value.id && value.companyName ? `${value.id}. ${value.companyName}` : '';
    }
    return '';
  }


}
