import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {clone} from "chart.js/helpers";
import * as moment from "moment";
import {forkJoin, map, Observable, of, tap} from "rxjs";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {PageOffering} from "../../../../core/model/side-nav.model";
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {PanelType} from "../../../../shared/components/info-panel/info-panel.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {RetailerViewComponent} from "../../../leads/retailers/retailer-view/retailer-view.component";
import {TenantType} from "../../../leads/retailers/retailers.model";
import {PaymentConfigsService} from "../../../settings/service/payment-configs.service";
import {RequestPosDialogComponent} from "../../../support/request-pos-dialog/request-pos-dialog.component";
import {
  AgreementType,
  CardReader,
  LeaseDetailsInfoPanel,
  LeaseStatus,
  LeaseSummary,
  mapRequestToData,
  OccupancyType,
  PaymentConfigData,
  SupplierSourceConfig,
  TransactionSource
} from "../../leasing.model";
import {LeasingService} from "../../leasing.service";
import {LeaseSubLeasesService} from "../lease-sub-leases/lease-sub-leases.service";
import {LeaseViewService} from "../lease-view.service";
import {leaseNotifications} from "./lease-details.model";

@Component({
  selector: 'app-lease-details',
  templateUrl: './lease-details.component.html',
  styleUrls: ['./lease-details.component.scss']
})
export class LeaseDetailsComponent implements OnInit {
  @Input() id!: string;
  @Input() offering!: PageOffering;
  @Input() reload!: Observable<void>;

  @Output() reloadPos = new EventEmitter<void>();

  leaseSummary!: LeaseSummary;
  parentLeaseSummary!: LeaseSummary;
  subLeases!: LeaseSummary[];

  paymentConfigData!: PaymentConfigData;
  parentPaymentConfigData!: PaymentConfigData;
  cardReaders: CardReader[] = [];

  displayedLeaseNotifications: Map<string, LeaseDetailsInfoPanel> = new Map<string, LeaseDetailsInfoPanel>();

  endLeaseKeys = ['active_sub_leases_not_abandoned', 'active_pos_active'];
  leaseApprovalPanel?: LeaseDetailsInfoPanel;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private leasingService: LeasingService,
              private leaseViewService: LeaseViewService,
              private subLeaseService: LeaseSubLeasesService,
              private tabService: TabManagementService,
              private matDialog: MatDialog,
              private currentContext: CurrentContextService,
              private offeringPaymentConfigsService: PaymentConfigsService,
              public loader: LoadingService) {
  }

  ngOnInit() {
    this.getLease();
    this.reload.subscribe(() => this.getLease());
  }

  getLease() {
    this.leaseViewService.getOne(``, this.id).subscribe({
      next: value => {
        this.leaseSummary = value;
        this.getLeaseData();
      },
      error: err => console.log(err)
    })
  }

  getLeaseData() {
    forkJoin({
      leasePaymentConfig: this.getLeasePaymentConfig(),
      assignedCardReaders: this.getAssignedCardReaders(),
      parentLease: this.leaseSummary.agreementType == AgreementType.SUB_LEASE ? this.getParentLease() : of(null),
      subLeases: this.isHeadLease ? this.getSubLeases() : of(null)
    }).subscribe({
      next: ({ leasePaymentConfig, assignedCardReaders, parentLease, subLeases }) => {
        this.cardReaders = assignedCardReaders;
        if (parentLease) this.parentLeaseSummary = parentLease;
        if (subLeases) this.subLeases = subLeases.content;
        this.setLeaseNotifications();
      },
      error: err => {
        console.log(err);
      }
    });
  }

  getParentLease(): Observable<LeaseSummary> {
    return this.leaseViewService.getOne(``, this.leaseSummary.parentUuid!);
  }

  getSubLeases(): Observable<{ content: LeaseSummary[] }> {
    let filters = [
      {name: 'parentUuid', val: this.leaseSummary.uuid},
      {name: 'agreementType', val: AgreementType.SUB_LEASE}
    ];

    const page = {size: '1000', page: '0', sort: 'leaseStartDate,desc'}
    return this.subLeaseService.getAll('', page, filters)
  }

  getLeasePaymentConfig(): Observable<any> {
    return this.leaseViewService.getLeaseCurrentPaymentConfig(this.id).pipe(
      tap(value => {
        if (value) {
          this.paymentConfigData = mapRequestToData(value);
          if (this.paymentConfigData.transactionSource == TransactionSource.VEND_SUPPLIER_LEASE
            || this.paymentConfigData.transactionSource == TransactionSource.VEND_SUPPLIER_OFFERING) {
            this.getParentPaymentConfig(this.paymentConfigData.transactionSource as TransactionSource);
          }
        }
      })
    );
  }

  getParentPaymentConfig(source: TransactionSource) {
    if (source == TransactionSource.VEND_SUPPLIER_LEASE) {
      const parentConfigUuid = this.paymentConfigData.transactionSourceConfig?.paymentConfigUuid;
      if (parentConfigUuid) {
        return this.leaseViewService.getLeaseCurrentPaymentConfig(parentConfigUuid).pipe(
          tap(value => {
            if (value) this.parentPaymentConfigData = mapRequestToData(value);
          })
        );
      }
    } else if (source == TransactionSource.VEND_SUPPLIER_OFFERING) {
      const parentConfigUuid = this.paymentConfigData.transactionSourceConfig?.paymentConfigUuid;
      if (parentConfigUuid) {
        return this.offeringPaymentConfigsService.getDistrictPosConfigs().pipe(
          map(value => {
            const config = value.filter(f => f.uuid == parentConfigUuid)[0];
            this.parentPaymentConfigData = mapRequestToData(config);
          })
        );
      }
    }
    return of(null);
  }

  getAssignedCardReaders(): Observable<CardReader[]> {
    return this.leaseViewService.getAssignedLeaseCardReaders(this.id);
  }

  displayRentalType(type: string): string {
    return this.leasingService.getRentalType(type);
  }

  getLeaseStatus(lease: LeaseSummary) {
    switch (lease.leaseStatus) {
      case 'APPROVED':
        return {
          status: this.utils.displayStatus('APPROVED'),
          icon: 'check_circle',
          outline: true,
          className: 'success-chip'
        };
      case 'ACTIVE':
        return {
          status: this.utils.displayStatus('ACTIVE'),
          icon: 'check_circle',
          outline: false,
          className: 'success-chip'
        };
      case 'EXPIRED':
        return {
          status: this.utils.displayStatus('EXPIRED'),
          icon: 'check_circle',
          outline: false,
          className: 'disabled-chip'
        };
      case 'TERMINATED':
        return {
          status: this.utils.displayStatus('TERMINATED'),
          icon: 'cancel',
          outline: false,
          className: 'disabled-chip'
        };
      case LeaseStatus.INVALID:
        return {
          status: this.utils.displayStatus('INVALID'),
          icon: 'cancel',
          outline: false,
          className: 'disabled-chip'
        };
      default:
        return {
          status: this.utils.displayStatus('PLANNED'),
          icon: 'check_circle',
          outline: true,
          className: 'warn-chip'
        };
    }
  }

  openRetailerViewTab(id: string, companyName: string) {
    let payload: PageEventInterface = {
      componentToRegister: RetailerViewComponent,
      pageName: 'Retailer ' + id,
      pageHeader: this.offering.label,
      data: {id: id, companyName: companyName, tenantType: this.isHeadLease ? TenantType.OFFERING : TenantType.TENANT},
      id: this.utils.generateUuidWithPrefix('retailer'),
      offeringId: this.offering.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'retailer',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  approveLeaseApplication(): void {
    if (!this.isSubLease) {
      this.leaseViewService.updateLeaseStatus(this.leaseSummary.retailerId, this.leaseSummary.uuid, LeaseStatus.APPROVED).subscribe({
        next: () => {
          this.getLease();
          this.reloadPos.emit();
        },
        error: err => console.log(err)
      });
    } else {
      this.subLeaseService.updateSubLeaseStatus(this.leaseSummary.uuid!, LeaseStatus.APPROVED).subscribe({
        next: () => {
          this.getLease();
          this.reloadPos.emit();
        },
        error: err => console.log(err)
      });
    }
  }

  setLeaseActive(): void {
    if (!this.isSubLease) {
      this.leaseViewService.updateLeaseStatus(this.leaseSummary.retailerId, this.leaseSummary.uuid, LeaseStatus.ACTIVE).subscribe({
        next: () => this.getLease(),
        error: err => console.log(err)
      });
    } else {
      this.subLeaseService.updateSubLeaseStatus(this.leaseSummary.uuid!, LeaseStatus.ACTIVE).subscribe({
        next: () => this.getLease(),
        error: err => console.log(err)
      });
    }
  }

  abandonLease(): void {
    if (!this.isSubLease) {
      this.leaseViewService.removeLease(this.leaseSummary.retailerId, this.leaseSummary.uuid).subscribe({
        next: () => {
          // this.getLease();
          this.leaseSummary.leaseStatus = LeaseStatus.CANCELED;
          this.leaseApprovalPanel!.display = false;
          this.setLeaseNotifications();
        },
        error: err => console.log(err)
      });
    } else {
      this.subLeaseService.removeSubLease(this.leaseSummary.uuid).subscribe({
        next: () => {
          // this.getLease();
          this.leaseSummary.leaseStatus = LeaseStatus.CANCELED;
          this.leaseApprovalPanel!.display = false;
          this.setLeaseNotifications();
        },
        error: err => console.log(err)
      });
    }
  }

  abandonSubLeases() {
    const subLeaseApplications = this.subLeases.filter(f => f.leaseType == 'APPLICATION');
    subLeaseApplications.forEach(subLease => {
      this.subLeaseService.removeSubLease(subLease.uuid).subscribe({
        next: value => console.log(`Abandoned sub-lease application with uuid: [${subLease.uuid}]`),
        error: err => console.log(err)
      })
    })
  }

  endLease(): void {
    if (!this.isSubLease) {
      this.leaseViewService.updateLeaseStatus(this.leaseSummary.retailerId, this.leaseSummary.uuid, LeaseStatus.EXPIRED).subscribe({
        next: () => {
          this.getLease();
        },
        error: err => console.log(err)
      });
    } else {
      this.subLeaseService.updateSubLeaseStatus(this.leaseSummary.uuid, LeaseStatus.EXPIRED).subscribe({
        next: () => {
          this.getLease();
        },
        error: err => console.log(err)
      });
    }
  }

  requestRetailerBillingUpdate() {
    this.leaseViewService.requestBillingDetailsUpdate(this.leaseSummary.retailerId).subscribe({
      next: () => {
        this.leaseViewService.updateBillingDetailsRequested(this.id).subscribe({
          next: () => {
            this.getLease();
          },
          error: err => console.log(err)
        })
      },
      error: err => console.log(err)
    })
  }

  requestPOS(cancel: boolean) {
    this.matDialog.open(RequestPosDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        cancel: cancel,
        header: cancel ? 'Cancel a POS account' : 'Request a POS account',
        property: this.currentContext.getCurrentLocation(),
        offering: this.offering,
        retailerId: this.leaseSummary.retailerId,
        companyName: this.leaseSummary.retailerCompanyName,
        leaseId: this.leaseSummary.uuid
      }
    }).afterClosed().subscribe((res: { save: boolean, leaseId: string }) => {
      if (res.save) {
        this.updatePaymentConfigStatus(cancel ? 'DEACTIVATION_REQUESTED' : 'ACTIVATION_REQUESTED');
      }
    })
  }

  updatePaymentConfigStatus(status: string) {
    this.leaseViewService.updatePaymentConfigStatus(this.paymentConfigData ? this.paymentConfigData.uuid! : this.utils.generateUuid(), this.id, status).subscribe({
      next: () => {
        this.getLease();
      },
      error: err => console.log(err)
    })
  }

  get isSubLease() {
    return this.leaseSummary.agreementType == AgreementType.SUB_LEASE;
  }

  get isHeadLease() {
    return this.leaseSummary.occupancyType == OccupancyType.SHARED &&
      this.leaseSummary.agreementType == AgreementType.LEASE;
  }

  // *** APPROVAL *** //

  setLeaseNotifications() {
    this.displayedLeaseNotifications = new Map<string, LeaseDetailsInfoPanel>();
    switch (this.leaseSummary.leaseStatus) {

      case LeaseStatus.INVALID:
        console.log('INVALID');
        this.addToDisplayedNotifications('invalid_spaces');
        if (this.isHeadLease) this.checkSubLeasesExpired();
        this.leaseApprovalPanel = this.getNotification('lease_approval_planned');
        break;

      case LeaseStatus.PLANNED:
        console.log('PLANNED')
        if (this.leaseSummary.spaceNames?.length == 0 || this.leaseSummary.totalSize == 0) {
          this.addToDisplayedNotifications('planned_incomplete_spaces');
        }
        if (this.leaseSummary.rentalType === null || this.leaseSummary.monthlyPricePerSqm === null) {
          this.addToDisplayedNotifications('planned_incomplete_rates_and_charges');
        }

        if (this.isSubLease) this.checkParentLeaseApproved();
        if (this.isHeadLease) {
          this.checkSubLeasesExpired();
          this.checkHasSubLeases();
        }

        this.leaseApprovalPanel = this.getNotification('lease_approval_planned');
        break;

      case LeaseStatus.APPROVED:
        console.log('APPROVED')
        this.addToDisplayedNotifications('approved_legal_docs');

        if (!this.paymentConfigData) {
          this.addToDisplayedNotifications('approved_pos_incomplete');
          if (this.isSubLease) this.displayedLeaseNotifications.get('approved_pos_incomplete')!.buttons = false;
        }
        if (this.paymentConfigData) this.checkPOSValidation();

        if (this.isSubLease) this.checkParentLeaseActive();
        if (this.isHeadLease) this.checkSubLeasesExpired();

        this.leaseApprovalPanel = this.getNotification('lease_approval_approved');
        break;

      case LeaseStatus.ACTIVE:
        console.log('ACTIVE');
        if (moment(this.leaseSummary.leaseEndDate).isBefore(moment(new Date()))) {
          this.addToDisplayedNotifications('active_outside_of_lease');
        }

        if (!this.paymentConfigData) this.addToDisplayedNotifications('approved_pos_incomplete');
        if (this.paymentConfigData) this.checkPOSValidation();

        if (this.isHeadLease) this.checkSubLeasesExpired();

        this.leaseApprovalPanel = this.getNotification('lease_approval_active');
        break;

      case LeaseStatus.CANCELED:
        console.log('CANCELED');
        this.addToDisplayedNotifications('abandoned');
        break;

      case LeaseStatus.TERMINATED:
        console.log('TERMINATED');
        this.addToDisplayedNotifications('abandoned');
        break;
    }

    const errorNotifications: LeaseDetailsInfoPanel[] = [];
    this.displayedLeaseNotifications.forEach((value, key) => {
      if (value.type == PanelType.ERROR && !this.endLeaseKeys.includes(value.code)) errorNotifications.push(value);
    })

    if (errorNotifications.length > 0
      && this.leaseApprovalPanel!.code.includes('lease_approval')
      && this.leaseApprovalPanel!.code != 'lease_approval_active') {
      this.leaseApprovalPanel!.buttonDisabled[0] = true;
      this.leaseApprovalPanel!.type = PanelType.WARN;
    }
  }

  acceptLease() {
    switch (this.leaseSummary.leaseStatus) {
      case LeaseStatus.PLANNED:
        this.approveLeaseApplication();
        break;
      case LeaseStatus.APPROVED:
        this.setLeaseActive();
        break;
    }
  }

  rejectLease() {
    let canRejectLease = false;
    const endNotifications: string[] = [];

    this.endLeaseKeys.forEach(key => {
      if (this.displayedLeaseNotifications.has(key)) endNotifications.push(key);
    })

    if (endNotifications.length == 0) {
      canRejectLease = true;
    } else {
      this.showEndNotifications();
      const buttonLength = this.leaseApprovalPanel!.buttonDisabled.length
      this.leaseApprovalPanel!.buttonDisabled[buttonLength - 1] = true;
      this.leaseApprovalPanel!.type = PanelType.WARN;
    }

    if (canRejectLease) {
      switch (this.leaseSummary.leaseStatus) {
        case LeaseStatus.INVALID:
          this.abandonLease();
          break;

        case LeaseStatus.PLANNED:
          this.abandonLease();
          break;

        case LeaseStatus.APPROVED:
          this.abandonLease();
          break;

        case LeaseStatus.ACTIVE:
          this.endLease();
          break;

        default:
          break;
      }
    }
  }

  infoPanelRequest(code: string, buttonIndex: number) {
    switch (code) {
      case 'approved_billing_incomplete': {
        this.requestRetailerBillingUpdate();
        break;
      }
      case 'approved_billing_requested': {
        this.requestRetailerBillingUpdate();
        break;
      }
      case 'approved_pos_incomplete': {
        this.requestPOS(false);
        break;
      }
      case 'active_pos_active': {
        this.requestPOS(true);
        break;
      }
      case 'active_sub_leases_not_abandoned': {
        this.abandonSubLeases();
        break;
      }
      case 'lease_approval':
        if (buttonIndex == 0) this.acceptLease();
        if (buttonIndex == 1) this.rejectLease();
        break;
      case 'lease_approval_active':
        if (buttonIndex == 0) this.rejectLease();
    }
  }

  checkPOSValidation() {
    switch (this.paymentConfigData.transactionSource) {
      case TransactionSource.VEND || TransactionSource.POSTER:
        if (this.leaseSummary.occupancyType == OccupancyType.SINGLE) {
          // 1. SINGLE - retailer billing, active POS, card readers assigned
          this.checkBillingDetails();
          this.checkPOSActive();
          this.checkCardReadersAssigned();
          this.checkPOSDeactivated();
        }
        if (this.leaseSummary.occupancyType == OccupancyType.SHARED) {
          // 2. SHARED - active POS, card readers assigned
          this.checkPOSActive();
          this.checkCardReadersAssigned();
          this.checkPOSDeactivated();
        }
        break;
      case TransactionSource.VEND_SUPPLIER_LEASE:
        // - retailer billing, valid supplier link, active lease parent POS
        this.checkBillingDetails();
        this.checkLinkedSuppliers();
        this.checkParentPOSActive();
        break;

      case TransactionSource.VEND_SUPPLIER_OFFERING:
        // - retailer billing, valid supplier link, active offering parent POS
        this.checkBillingDetails();
        this.checkLinkedSuppliers();
        this.checkParentPOSActive();
        break;
    }
  }

  checkBillingDetails() {
    if (this.leaseSummary.bankAccountRef == null) {
      if (this.paymentConfigData.billingUpdateRequested) {
        this.addToDisplayedNotifications('approved_billing_requested');
        const notification = this.displayedLeaseNotifications.get('approved_billing_requested')!;
        const newMessage = notification.message.map(m => {
          return m.replace('{{}}', this.dateUtils.displayDateWithTime(this.paymentConfigData.billingUpdateRequested)!.toString());
        })
        notification.message = [...newMessage];
      } else {
        this.addToDisplayedNotifications('approved_billing_incomplete');
      }
      return true;
    }
    return false;
  }

  checkPOSActive() {
    if (this.paymentConfigData.activationStatus != 'ACTIVATED') {
      if (this.paymentConfigData.activationStatus == 'ACTIVATION_REQUESTED') {
        this.addToDisplayedNotifications('approved_pos_requested');
      } else {
        this.addToDisplayedNotifications('approved_pos_incomplete');
      }
      return true;
    }
    return false;
  }

  checkPOSDeactivated() {
    if (this.paymentConfigData.activationStatus == 'ACTIVATED') {
      this.addToDisplayedNotifications('active_pos_active');
      return true;
    }
    return false;
  }

  checkParentPOSActive() {
    if (this.parentPaymentConfigData && this.parentPaymentConfigData.activationStatus != 'ACTIVATED') {
      this.addToDisplayedNotifications('approved_parent_pos_not_active');
      return true;
    }
    return false;
  }

  checkHasSubLeases() {
    if (this.subLeases.length == 0 && this.isHeadLease) {
      this.addToDisplayedNotifications('planned_no_sub_leases');
    }
  }

  checkLinkedSuppliers() {
    const supplierConfigs = [TransactionSource.VEND_SUPPLIER_LEASE.toString(), TransactionSource.VEND_SUPPLIER_OFFERING.toString()];
    if (!(supplierConfigs.includes(this.paymentConfigData.transactionSource!))) {
      const config = this.paymentConfigData.transactionSourceConfig as SupplierSourceConfig;
      if (!config.suppliers || config.suppliers.length == 0) {
        this.addToDisplayedNotifications('approved_suppliers_incomplete');
        return true;
      }
    }
    return false;
  }

  checkCardReadersAssigned() {
    if (this.cardReaders.length == 0) {
      this.addToDisplayedNotifications('approved_card_readers_incomplete');
      return true;
    }
    return false;
  }

  checkParentLeaseApproved() {
    const activeStatuses = [LeaseStatus.APPROVED, LeaseStatus.ACTIVE];
    if (!activeStatuses.includes(this.parentLeaseSummary.leaseStatus)) {
      this.addToDisplayedNotifications('planned_parent_not_approved');
      return true;
    }
    return false;
  }

  checkParentLeaseActive() {
    const activeStatuses = [LeaseStatus.ACTIVE];
    if (!activeStatuses.includes(this.parentLeaseSummary.leaseStatus)) {
      this.addToDisplayedNotifications('approved_parent_not_active');
      return true;
    }
    return false;
  }

  checkSubLeasesExpired() {
    const expiredStatuses = [LeaseStatus.CANCELED, LeaseStatus.TERMINATED, LeaseStatus.EXPIRED];
    const activeSubLeases = this.subLeases.filter(f => !expiredStatuses.includes(f.leaseStatus));
    const activeSubLeaseApplications = activeSubLeases.filter(f => f.leaseType == "APPLICATION");
    if (activeSubLeases.length > 0) {
      this.addToDisplayedNotifications('active_sub_leases_not_abandoned');
      this.displayedLeaseNotifications.get('active_sub_leases_not_abandoned')!.buttons = activeSubLeaseApplications.length > 0;
      return true;
    }
    return false;
  }

  showEndNotifications() {
    this.displayedLeaseNotifications.forEach((value, key) => {
      if (this.endLeaseKeys.includes(key)) value.display = true;
    })
  }

  addToDisplayedNotifications(code: string) {
    this.displayedLeaseNotifications.set(code, this.getNotification(code));
  }

  get sortedNotifications() {
    const array = Array.from(this.displayedLeaseNotifications);
    array.sort((a, b) => a[1].type - b[1].type);
    return new Map(array);
  }

  getNotification(code: string): LeaseDetailsInfoPanel {
    return clone({ ...leaseNotifications.get(code)! });
  }

}
