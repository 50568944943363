<div class="full-width py-2 px-3" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="heading-default">Point-of-sales-software settings</div>
    <div class="pe-3" fxLayout="row" fxLayoutAlign="center center" *ngIf="loading$ | async">
      <mat-spinner color="primary" diameter="20"></mat-spinner>
    </div>
  </div>

  <div fxLayout="column" class="full-width" fxLayoutGap="15px" fxFlexOffset="20px">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <span class="body-3-default">Payment Source Name</span>
        <span class="body-2-default">The point-of-sale software through which sales and inventory will be recorded.</span>
      </div>
      <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
        <mat-select class="custom-select-field-value" placeholder="Select"
                    [disabled]="isEditing" [(ngModel)]="posType" (ngModelChange)="vendConfig!.paymentSourceName = $event">
          <mat-option class="custom-select-field-option" *ngFor="let type of allowedPosTypes"
                      [value]="type.source">{{ type.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutGap="15px" *ngIf="(posType == TransactionSource.VEND || posType == TransactionSource.POSTER) && vendConfig">
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="offeringConfig">
        <div fxLayout="column">
          <span class="body-3-default">Display Name</span>
          <span class="body-2-default">A display name for this config to make it more easily identifiable when applying to a lease.</span>
        </div>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput type="text" [disabled]="!this.canEdit" [(ngModel)]="vendConfig.displayName!">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px">
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
          <div fxLayout="column">
            <span class="body-3-default">Domain Prefix</span>
            <span class="body-2-default">The domain prefix assigned on the point-of-sale software.</span>
          </div>
          <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
            <input matInput type="text"
                   [disabled]="!this.canEdit || isEditing"
                   [(ngModel)]="vendConfig.domainPrefix!">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
          <div fxLayout="column">
            <span class="body-3-default">Outlet ID</span>
            <span class="body-2-default">The outlet ID for this location on the point-of-sale software.</span>
          </div>
          <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
            <input matInput type="text"
                   [disabled]="!this.canEdit || isEditing || !!(loading$ | async)"
                   [(ngModel)]="vendConfig.outletId!">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Access Token</span>
          <span class="body-2-default">The unique access token/key assigned on the point-of-sale software.</span>
        </div>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
          <input matInput
                 [type]="this.canEdit ? 'text' : 'password'"
                 [disabled]="!this.canEdit"
                 [(ngModel)]="vendConfig.accessToken!">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px">
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
          <div fxLayout="column">
            <span class="body-3-default">Login Username</span>
            <span class="body-2-default">The username for the admin account on the point-of-sale software.</span>
          </div>
          <mat-form-field class="custom-text-field" appearance="outline" *ngIf="this.canEdit" style="width: 100%;">
            <input matInput type="text" [(ngModel)]="vendConfig.loginUsername!">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
          <div fxLayout="column">
            <span class="body-3-default">Login Password</span>
            <span class="body-2-default">The password for the admin account on the point-of-sale software.</span>
          </div>
          <mat-form-field class="custom-text-field" appearance="outline" *ngIf="this.canEdit" style="width: 100%;"
                          [matTooltip]="!isEditing ? 'Click to copy password' : ''" [cdkCopyToClipboard]="vendConfig.loginPassword!">
            <input matInput type="'password'" [(ngModel)]="vendConfig.loginPassword!">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Login URL</span>
          <span class="body-2-default">The login URL for the admin account on the point-of-sale software.</span>
        </div>
        <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;"
                        *ngIf="this.canEdit">
          <input matInput type="text" [(ngModel)]="vendConfig.loginUrl">
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Payment Provider Name</span>
          <span class="body-2-default">The software through which sale payments will take place (e.g. card machine).</span>
        </div>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select"
                      [disabled]="!this.canEdit || isEditing || !!(loading$ | async)"
                      [(ngModel)]="vendConfig.paymentTypeName">
            <mat-option class="custom-select-field-option" [value]="'YOCO'">YOCO</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="15px" *ngIf="posType == TransactionSource.VEND_SUPPLIER_OFFERING && supplierConfig && vendConfigs">
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Parent Transaction Source</span>
          <span class="body-2-default">The name of the parent point-of-sale config to which this lease will be a linked supplier.</span>
        </div>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select"
                      [disabled]="!this.canEdit || isEditing || !!(loading$ | async)"
                      [(ngModel)]="supplierConfig.paymentConfigUuid" (ngModelChange)="loadConfigSuppliers($event, posType)">
            <mat-option class="custom-select-field-option" *ngFor="let config of vendConfigs"
                        [value]="config.uuid">{{ config.label }} ({{config.code}})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Available Suppliers</span>
          <span class="body-2-default">The names of the suppliers which this lease will be trading under which are linked to the parent transaction source.</span>
        </div>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" [multiple]="true"
                      [disabled]="!this.canEdit || !!(loading$ | async)"
                      [(ngModel)]="supplierConfig.suppliers">
            <mat-option class="custom-select-field-option" *ngFor="let supplier of availableSuppliers"
                        [value]="supplier">{{ supplier.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="15px" *ngIf="posType == TransactionSource.VEND_SUPPLIER_LEASE && supplierConfig">
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <span class="body-3-default">Available Suppliers</span>
          <span class="body-2-default">The names of the suppliers linked to the parent payment source which this lease will be trading under.</span>
        </div>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" [multiple]="true"
                      [disabled]="!this.canEdit || !!(loading$ | async)"
                      [(ngModel)]="supplierConfig.suppliers">
            <mat-option class="custom-select-field-option" *ngFor="let supplier of availableSuppliers"
                        [value]="supplier">{{ supplier.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="end" fxFlexOffset="20px" fxLayoutGap="20px">
      <button mat-flat-button class="button-outline button-primary" (click)="cancelChanges()">
        <div class="button-text">Cancel</div>
      </button>
      <button mat-flat-button class="button-primary" [disabled]="!canSave" (click)="saveChanges()">
        <div class="button-text">Save</div>
      </button>
    </div>
  </div>
</div>
