<div fxLayout="column">
  <app-page-header [section]="'Applications'" [title]="'Hand pick retailers for your leads'" [description]="'The applications module allows you to see who applied to your retail offering. Based on the relevant curation criteria you can decide who do and don’t qualify.'"></app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">
    <div fxFlexOffset="20px">
      <div fxLayout="row">
        <span class="subtitle-default">Applicants</span>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
      <span class="heading-default">Applicants to curate</span>
      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Applicants </th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell class="custom-table-cell btn-cursor"
              matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.id"
              *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>

        <ng-container matColumnDef="createDate">
          <th mat-header-cell *matHeaderCellDef> Application Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.createDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="retailer">
          <th mat-header-cell *matHeaderCellDef> Retailer </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.ownerFirstname + ' ' + element.ownerLastname, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="website">
          <th mat-header-cell *matHeaderCellDef> Website </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element" (click)="utils.openLinkInNewTab(element.websiteUrl)"> {{utils.displayString(element.websiteUrl, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="industryType">
          <th mat-header-cell *matHeaderCellDef> Industry </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayString(this.displayIndustriesList(element), 30)}} </td>
        </ng-container>

        <ng-container matColumnDef="overallScore">
          <th mat-header-cell *matHeaderCellDef> Overall Score </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-progress-bar class="score-progress-bar" color="primary" mode="determinate"
                                [value]="element.overallScore">
              </mat-progress-bar>
              <div *ngIf="element.overallScore"> {{element.overallScore}}% </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">
            <button class="button-action" mat-flat-button (click)="openApplicationsViewTab(element.id)" [disabled]="(loader.loading$ | async)"><div class="button-text">Curate</div></button>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" class="custom-table-row" mat-row></tr>
      </table>
      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>
