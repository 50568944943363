import {AfterViewInit, Component, Injector, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';
import {MatPaginator} from '@angular/material/paginator';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {PaginatorService} from '../../../shared/services/paginator.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserAccessService} from '../../../core/services/user-access.service';
import {
  GrantedModuleRole,
  GrantedUser,
  GrantForConsoleRequest,
  RBACModule,
  RBACRole,
  RevokeGrant
} from '../../../core/model/user-access.model';
import {lastValueFrom, of, tap} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {catchError} from 'rxjs/operators';
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-users-and-access',
  templateUrl: './users-and-access.component.html',
  styleUrls: ['./users-and-access.component.scss']
})
export class UsersAndAccessComponent extends BasePageComponent implements AfterViewInit {

  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  addUserToRoleForm: FormGroup;
  addingUserToRole: boolean = false;
  revokingUserFromRole: boolean = false;
  availableRoles: RBACRole[] = [];
  availableModules: RBACModule[] = [];

  grantedModuleTableSelection = new SelectionModel<GrantedModuleRole>(false, []);
  selectedModule: number = 1;
  grantedModuleDataSource: MatTableDataSource<GrantedModuleRole> = new MatTableDataSource<GrantedModuleRole>([]);
  grantedRolesDisplayedColumns: string[] = ['id', 'module', 'role', 'description'];
  // grantedRolesDisplayedColumns: string[] = ['select', 'id', 'module', 'role', 'description']; -- use it when we want multipe


  userDisplayedColumns: string[] = ['select', 'userId', 'userFirstname', 'userLastname', 'userEmail'];
  userDataSource: MatTableDataSource<GrantedUser> = new MatTableDataSource<GrantedUser>([]);
  userSelection = 1;
  userTableSelection = new SelectionModel<GrantedUser>(true, []);
  totalPages: number = 0;
  totalElements: number = 0;

  constructor(injector: Injector,
              private contextService: CurrentContextService,
              private paginatorService: PaginatorService,
              public utils: UtilsService,
              private userAccessService: UserAccessService,
              public loader: LoadingService
  ) {
    super(injector);
    this.filterForm = new FormGroup({
      moduleCodes: new FormControl([]),
    });

    this.addUserToRoleForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      roleCodes: new FormControl([], [Validators.required])
    });

    lastValueFrom(this.userAccessService.getAllRoles())
      .then((rolesList: RBACRole[]) => {
        this.availableRoles = rolesList;
        this.availableRoles.sort((roleA, roleB) => {
          if (roleA.name.toLowerCase() < roleB.name.toLowerCase()) {
            return -1
          }
          return 1;
        })
      });

    lastValueFrom(this.userAccessService.getAllModules())
      .then((modulesList: RBACModule[]) => {
        this.availableModules = modulesList;
      })
  }

  ngOnInit() {
    this.userDataSource = new MatTableDataSource<GrantedUser>([]);
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadUserAccess()))
      .subscribe();
    this.filterForm.valueChanges.subscribe(() => this.loadUserAccess());

  }

  refreshData(): void {
    this.userAccessService.getAll('/granted-roles', {
      size: '10',
      page: '0',
      sort: 'moduleName,asc'
    }).pipe(
      catchError(() => of([]))
    ).subscribe((value: any) => {
      this.totalPages = value.totalPages;
      this.totalElements = value.totalElements;
      this.grantedModuleDataSource = new MatTableDataSource<GrantedModuleRole>(value.content);
    });
  }

  setSelectedRoleUsers(row: GrantedModuleRole) {
    this.grantedModuleTableSelection.toggle(row);
    this.userTableSelection.clear();
    this.selectedModule = row.roleId;
    this.userDataSource = new MatTableDataSource<GrantedUser>(row.users)
  }

  addUserToRoles(): void {
    this.addingUserToRole = true;
  }

  revokeUserRole(): void {
    this.revokingUserFromRole = true;
  }

  saveAddUserToRole(): void {
    let request: GrantForConsoleRequest = {
      offeringUuid: this.contextService.getCurrentOfferingId()!,
      roleCodes: this.addUserToRoleForm.get('roleCodes')!.value,
      user: {
        email: this.addUserToRoleForm.get('email')!.value,
        firstname: this.addUserToRoleForm.get('firstName')!.value,
        lastname: this.addUserToRoleForm.get('lastName')!.value
      }
    };
    lastValueFrom(this.userAccessService.saveAccessRequest(request))
      .then(() => {
        this.addingUserToRole = false;
        this.refreshData();
        this.addUserToRoleForm.reset();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  revokeResponse(confirm: boolean) {
    confirm ? this.confirmRevoke() : this.cancelRevoke();
  }

  confirmRevoke(): void {
    let request: RevokeGrant[] = [];
    const offeringUuid = this.contextService.getCurrentOfferingId()!;
    this.userTableSelection.selected
      .forEach((selectedGrantUser) => {
        request.push({
          grantId: selectedGrantUser.grantId!,
          userId: selectedGrantUser.userId,
          offeringUuid: offeringUuid,
          principleId: this.grantedModuleTableSelection.selected[0].grantPrincipleId,
          roleId: this.grantedModuleTableSelection.selected[0].roleId
        });
      });
    lastValueFrom(this.userAccessService.revokeAccess(request))
      .then(() => {
        this.revokingUserFromRole = false;
        this.userTableSelection.clear();
        this.grantedModuleTableSelection.clear();
        this.selectedModule = 1;
        this.userSelection = 1;
        this.loadUserAccess();
      })
      .catch((error: string) => {
        console.log(error)
      });
  }

  filterResults(): void {
    this.loadUserAccess();
  }

  private loadUserAccess() {
    const filters = [
      {name: 'moduleCodes', val: this.filterForm.get('moduleCodes')?.value},
    ];
    this.userAccessService.getAll('/granted-roles', {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'moduleName,asc'
    }, filters)
      .pipe(
        catchError(() => of([]))
      ).subscribe((value: any) => {
      this.totalPages = value.totalPages;
      this.totalElements = value.totalElements;
      this.grantedModuleDataSource = new MatTableDataSource<GrantedModuleRole>(value.content);
    });
  }

  getPanelType(): { icon: string } {
    return {icon: 'warn-icon'}
  }

  cancelRevoke(): void {
    this.revokingUserFromRole = false;
  }
}
