<div class="full-width" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Sub-leases</span>
      <div fxLayoutGap="10px" fxFlexOffset="30px">
        <button (click)="createSubLease()" class="button-action" mat-flat-button [disabled]="loader.loading$ | async">
          <div class="button-text">Add</div>
        </button>
<!--        <button (click)="removePanelOpen = true" [disabled]="isLastSpace() || areSpacePastDated() || !(this.selection.selected.length > 0) || (loader.loading$ | async)" class="button-action"-->
<!--                mat-flat-button *ngIf="lease?.leaseType == 'LEASE'">-->
<!--          <div class="button-text">Remove</div>-->
<!--        </button>-->
<!--        <button (click)="editSpace()" *ngIf="this.selection.selected.length == 1 || lease?.leaseType == 'APPLICATION'" class="button-action" mat-flat-button [disabled]="loader.loading$ | async">-->
<!--          <div class="button-text">Edit</div>-->
<!--        </button>-->

      </div>
<!--      <div fxFlexOffset="20px" *ngIf="removePanelOpen">-->
<!--        <app-confirm-remove [message]="'Do you want to remove the selected spaces from this lease.'" (confirmRemove)="removeSpace($event)"></app-confirm-remove>-->
<!--      </div>-->
    </div>

  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default"> Lease sub-leases </span>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px" fxFlexOffset="20px" [formGroup]="filterForm">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Company Name</mat-label>
          <input matInput type="text" placeholder="Search here" formControlName="companyName">
        </mat-form-field>
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
          <mat-label>Lease Status</mat-label>
          <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="leaseStatus">
            <mat-option class="custom-select-field-option"  *ngFor="let status of leaseStatuses" [value]="status">{{utils.displayStatus(status)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Lease schedule </th>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailerCompanyName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.totalSize}} m<sup>2</sup></td>
      </ng-container>

      <ng-container matColumnDef="rentalType">
        <th mat-header-cell *matHeaderCellDef> Rental Type </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.rentalType ? displayRentalType(element.rentalType) : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="monthlyRentalFee">
        <th mat-header-cell *matHeaderCellDef> Monthly Rental Fee </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.totalMonthlyFee!) + (element.rentalType == 'TURNOVER_PERCENTAGE' ? ' + ' + element.turnoverPercentageValue + '%' : '')}}</td>
      </ng-container>

      <ng-container matColumnDef="expiresInDays">
        <th mat-header-cell *matHeaderCellDef> Days Until Expiration </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.expiresInDays ? element.expiresInDays + ' days' : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="leaseStatus">
        <th mat-header-cell *matHeaderCellDef> Current Lease Status </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element">
          <div [class]="status.className" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="getLeaseStatus(element) as status">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon [class.material-icons-outlined]="status.outline">{{status.icon}}</mat-icon>
            </div>
            <span class="footer-default">{{status.status}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="openLeaseViewTab(row.uuid, row.occupancyType, row.agreementType)"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
