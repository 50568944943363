import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LeaseDocumentsService} from "../lease-documents.service";
import {LeaseDocument, LeaseDocumentType} from "../../../leasing.model";
import {UtilsService} from "../../../../../shared/services/utils.service";

@Component({
  selector: 'app-add-document-dialog',
  templateUrl: './add-document-dialog.component.html',
  styleUrls: ['./add-document-dialog.component.scss']
})
export class AddDocumentDialogComponent implements OnInit {

  docTypes: LeaseDocumentType[] = [];
  fileToUpload: File | null = null;
  fileName: string | null = null;

  document: LeaseDocument | null = null;
  documentUuid: string | null = null;
  documentTypeCode: string | null = null;

  errorMessage: string | null = null;

  leaseId!: string;

  constructor(public dialogRef: MatDialogRef<AddDocumentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private documentsService: LeaseDocumentsService,
              public utils: UtilsService) {
    this.leaseId = data.leaseId;
  }

  ngOnInit() {
    this.loadLeaseDocumentTypes();
  }

  loadLeaseDocumentTypes() {
    this.documentsService.getDocumentTypes().subscribe({
      next: value => this.docTypes = value,
      error: err => console.log(err)
    })
  }

  uploadDocument() {
    this.errorMessage = '';
    if (this.fileToUpload !== null) {
      this.documentsService.saveLeaseDocument(this.documentTypeCode!, this.fileToUpload!, this.leaseId).subscribe({
        next: value => this.close(),
        error: err => this.errorMessage = err.message
      })
    }
  }

  fileChangeEvent(event: any): void {
    this.fileToUpload = event.files.item(0);
    this.fileName = this.fileToUpload!.name;
  }

  close() {
    this.dialogRef.close();
  }

}
