import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";


export interface PagedContent<T> {
  totalPages: number;
  totalElements: number;
  content: T;
}

export interface PageRequest {
  size: string,
  page: string,
  sort: string | string[]
}

@Injectable({
  providedIn: 'root'
})
export class GenericService<T = any> {

  baseUrl: string = '';

  constructor(protected http: HttpClient, @Inject('baseUrl') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getAll(suffix: string, page: PageRequest, params?: { name: string, val: any }[]): Observable<{ content: T[] }> {
    let requestParams = new HttpParams()
      .set('size', page.size)
      .set('page', page.page);

    if (typeof page.sort === 'string') {
      requestParams = requestParams.set('sort', page.sort)
    } else if (Array.isArray(page.sort)) {
      page.sort.forEach(sortCondition => {
        requestParams = requestParams.append('sort', sortCondition)
      });
    }
    if (params) {
      params.filter(f => f.val !== null && f.val !== 'null').forEach(ea => {
        requestParams = requestParams.append(ea.name, ea.val)
      });
    }
    return this.http.get<{ content: T[] }>(this.baseUrl + suffix, {params: requestParams});
  }

  getOne(suffix: string, id: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + suffix + `/${id}`);
  }
}
