
<mat-sidenav-container class="sidenav-container body-1-regular-2">
    <!-- Right sidenav -->
    <mat-sidenav #rightSidenav mode="side" position="end" class="sidenav-right"
                 [fixedInViewport]="false" [fixedTopGap]="0"
                 [fixedBottomGap]="0">

      <div class="sidenav-right-content" fxLayout="column" fxLayoutGap="15px">
        <div class="pane right-pane" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="body-2-default" fxLayout="row">
<!--            Switch View-->
          </div>

          <mat-tab-group (selectedTabChange)="switchTabs($event)" [(selectedIndex)]="selectedTabIndex"
                         class="custom-tab-group" mat-align-tabs="center"
                         mat-stretch-tabs="false" preserveContent>
            <mat-tab>

                <ng-template mat-tab-label>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <div class="square-icon icon-smaller">
                            <mat-icon svgIcon="stacked_lines"></mat-icon>
                        </div>
                        <span class="body-1-default">Spaces</span>
                    </div>
                </ng-template>
              <app-edit-single-space #editSpaceComponent
                [selectedSpace]="selectedSpace"
                (dirty)="selectedSpaceDirty = $event"
                (spaceUpdated)="selectedSpace = $event"
                [addNewSpace]="addNewSpace">
              </app-edit-single-space>

            </mat-tab>
<!--            <mat-tab>-->
<!--                <ng-template mat-tab-label>-->
<!--                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">-->
<!--                        <div class="square-icon">-->
<!--                            <mat-icon class="material-icons-outlined">maps</mat-icon>-->
<!--                        </div>-->
<!--                        <span class="body-1-default">Floor plan</span>-->
<!--                    </div>-->
<!--                </ng-template>-->

<!--              <div class="full-width" fxLayout="column" fxLayoutGap="10px">-->

<!--                <div class="info-card-dark" fxLayout="row" fxLayoutAlign="start center">-->
<!--                  <div class="person-home-icon">-->
<!--                    <mat-icon svgIcon="person_home"></mat-icon>-->
<!--                  </div>-->
<!--                  <span class="heading-bold" fxFlexOffset="20px">{{getCurrentOffering()}}</span>-->
<!--                </div>-->

<!--                <div *ngIf="!showingActions" class="info-card-dark" fxLayout="column" fxLayoutGap="10px">-->
<!--                  <span>Quick Statistics (lifetime)</span>-->
<!--                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">-->
<!--                    <div class="quick-stat-item">-->
<!--                      <span class="quick-stat-title">Avg Gla</span>-->
<!--                      <span class="quick-stat-content">{{getOfferingGLA()}}m<sup>2</sup></span>-->
<!--                    </div>-->
<!--                    <div class="quick-stat-item">-->
<!--                      <span class="quick-stat-title">Avg price</span>-->
<!--                      <span class="quick-stat-content">R{{getOfferingAvgPrice()}} m<sup>2</sup></span>-->
<!--                    </div>-->
<!--                    <div class="quick-stat-item">-->
<!--                      <span class="quick-stat-title">Avg term</span>-->
<!--                      <span class="quick-stat-content">{{getOfferingAvgTerm()}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div *ngIf="showUploadAction" class="action-info-card" fxLayout="column" fxLayoutAlign="space-between">-->
<!--                  <div class="full-width body-1-regular-1" style="text-align: start">Upload Image</div>-->
<!--                  <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">-->
<!--                    <button (click)="clearUpload()" class="button-secondary button-outline" mat-flat-button>-->
<!--                      <div class="button-text">Cancel</div>-->
<!--                    </button>-->

<!--                    <button (click)="upload()" class="button-primary" mat-flat-button>-->
<!--                      <div class="button-text">Upload</div>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="info-card-dark" fxLayout="column" fxLayoutGap="10px">-->
<!--                  <span>Occupancy Statistics</span>-->
<!--                  <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">-->
<!--                    <span class="body-2-default">Occupancy percentage and size</span>-->
<!--                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">-->
<!--                      <div class="quick-stat-item">-->
<!--                        <span class="quick-stat-title">Average</span>-->
<!--                        <span class="quick-stat-content">{{getOfferingAvgOccupancy()}} %</span>-->
<!--                      </div>-->
<!--                      <div class="quick-stat-item">-->
<!--                        <span class="quick-stat-title">Current %</span>-->
<!--                        <span class="quick-stat-content">{{getCurrentOccupancy()}} %</span>-->
<!--                      </div>-->
<!--                      <div class="quick-stat-item">-->
<!--                        <span class="quick-stat-title">Current size</span>-->
<!--                        <span class="quick-stat-content">{{getCurrentSize()}} m<sup>2</sup></span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="full-width" fxLayout="row">-->
<!--                      <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">-->
<!--                        <div class="body-2-default">Industry by Occupancy</div>-->
<!--                        <div style="height: 100%; width: 100%; min-height: 250px !important;">-->
<!--                          &lt;!&ndash;                      Current day chart not monthly&ndash;&gt;-->
<!--                          <canvas *ngIf="!industryPerformanceByTradingDensityLoading; else canvasLoadingSpinner" [data]="industryPerformanceByTradingDensityChartData"-->
<!--                                  [options]="industryPerformanceByTradingDensityChartOptions"-->
<!--                                  [type]="'doughnut'"-->
<!--                                  [width]="230"-->
<!--                                  baseChart-->
<!--                                  class="dashboard-canvas"></canvas>-->
<!--                        </div>-->
<!--                        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">-->
<!--                          <div *ngFor="let ind of industryColors" class="full-width" fxLayout="row"-->
<!--                               fxLayoutAlign="start center"-->
<!--                               fxLayoutGap="5px">-->
<!--                            <div [ngStyle]="{'background-color': ind.color}" class="legend-key"></div>-->
<!--                            <span class="body-1-regular-2"-->
<!--                                  style="font-size: 11px !important;">{{getIndustryName(ind.code)}}</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </mat-tab>-->
          </mat-tab-group>
        </div>
      </div>

    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content cdkScrollable class="sidenav-main-content" >
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
        <!-- Page header -->
        <app-page-header [backgroundWhite]="false"
                         [bottomBorder]="false"
                         [section]="'Manage Spaces'"
                         [title]="'Setup and configure leasing spaces'"
                         [description]="'Manage and configure both available and unavailable leasing spaces\n'+
                      ''">
        </app-page-header>

        <ng-container *ngIf="data.selectedTabCode === 'floor_plan'">
          <app-manage-floor-plan #floorPlanComponent  class="page-padding">
          </app-manage-floor-plan>
        </ng-container>

        <!-- spaces list -->
          <ng-container *ngIf="data.selectedTabCode === 'spaces'">
            <app-list-spaces #listSpacesComponent
              class="page-padding"
              (spaceSelected)="selectedSpace = $event"
              [dirty]="selectedSpaceDirty"
              [spaceUpdated]="selectedSpace"
              (addNewSpace)="addNewSpace = $event">
            </app-list-spaces>
          </ng-container>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
  <ng-template #canvasLoadingSpinner>
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>
  </ng-template>
