<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-default">{{ header }}</div>
    </div>

    <form [formGroup]="leaseForm" class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
<!--      *** CHOOSE OCCUPANCY *** -->
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Occupancy</span>
        <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;">
          <mat-select class="custom-select-field-value" placeholder="Select" formControlName="occupancyType">
            <mat-option class="custom-select-field-option" [value]="OccupancyType.SINGLE">Single Lease</mat-option>
            <mat-option class="custom-select-field-option" [value]="OccupancyType.SHARED">Shared Space</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="body-1-regular-1">Existing retailer?</span>
        <mat-checkbox [checked]="existingRetailer" (change)="existingRetailer = !existingRetailer"></mat-checkbox>
      </div>

<!--      *** EXISTING RETAILER *** -->
      <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="existingRetailer">
        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="body-3-default">Choose a retailer</span>
          <mat-form-field [ngClass]="disableRetailerSelection() ? 'custom-text-autofill' : 'custom-text-field'" appearance="outline" style="width: 100%;">
            <input class="input-field" matInput type="text"
                   [formControl]="searchTerm"
                   [matAutocomplete]="auto"
                   [maxlength]="!!lease ? 0 : null"
                   [placeholder]="getPlaceHolder()"
                   [attr.disabled]="!!lease">
            <mat-spinner class="me-2" matSuffix diameter="20" color="primary" *ngIf="loadingSearch"></mat-spinner>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectRetailer($event)">
              <mat-option *ngFor="let option of filteredRetailers | async" [value]="option">
                {{option.id}}. {{option.companyName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

<!--      *** NEW RETAILER *** -->
      <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="!existingRetailer">
        <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="leaseForm.get('occupancyType')!.value == OccupancyType.SHARED">
          <span class="body-3-default">Choose lease name</span>
          <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" style="width: 100%;">
            <input matInput placeholder="Type here" type="text" formControlName="companyName"/>
          </mat-form-field>
        </div>

        <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="leaseForm.get('occupancyType')!.value == OccupancyType.SINGLE">
          <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="full-width" fxLayout="column" fxLayoutGap="7px" fxFlex="48">
              <span class="body-3-default">First Name</span>
              <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
                <input matInput type="text" placeholder="Input text" formControlName="firstName">
                <mat-error class="link-error">{{getErrorMessage('firstName')}}</mat-error>
              </mat-form-field>
            </div>

            <div class="full-width" fxLayout="column" fxLayoutGap="7px" fxFlex="48">
              <span class="body-3-default">Last Name</span>
              <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
                <input matInput type="text" placeholder="Input text" formControlName="lastName">
                <mat-error class="link-error">{{getErrorMessage('lastName')}}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="full-width" fxLayout="column" fxLayoutGap="7px"  fxFlex="48">
              <span class="body-3-default">Email</span>
              <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
                <input matInput type="email" placeholder="Input text" formControlName="email">
                <mat-error class="link-error">{{getErrorMessage('email')}}</mat-error>
              </mat-form-field>
            </div>

            <div class="full-width" fxLayout="column" fxLayoutGap="7px" fxFlex="48">
              <span class="body-3-default">Company Name</span>
              <mat-form-field class="custom-text-field" appearance="outline" style="width: 100%;">
                <input matInput type="email" placeholder="Input text" formControlName="companyName">
                <mat-error class="link-error">{{getErrorMessage('companyName')}}</mat-error>
              </mat-form-field>
            </div>
          </div>


        </div>

        <div class="full-width" fxLayout="column" fxLayoutGap="7px">
          <span class="body-3-default">Industry And Product Collection</span>
          <mat-form-field class="custom-select-field" appearance="outline" style="width: 100%;" hideRequiredMarker>
            <mat-select [(value)]="selectedIndustries" class="custom-select-field-value"
                        formControlName="industryTypes" [multiple]="true" placeholder="Industry And Product Collection">
              <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
                <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                           fxLayoutGap="5px">
                  <span>{{group.name}}</span>
                  <mat-icon class="btn-cursor">add</mat-icon>
                </mat-label>
                <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                            class="custom-select-field-option">{{ ind.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error class="link-error">{{getErrorMessage('industryTypes')}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose a lease period</span>
        <mat-form-field class="custom-date-picker" appearance="outline" style="width: 100%;">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Custom" formControlName="startDate" />
            <input matEndDate formControlName="endDate"/>
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div *ngIf="!this.edit" class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="body-3-default">Choose an available space</span>
        <table mat-table class="custom-table" matSort
               [dataSource]="dataSource">
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="displayedColumns.length" class="custom-table-label"
                mat-header-cell></th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class="custom-table-cell" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell class="custom-table-cell btn-cursor"
                matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.districtSpaceId"
                *matCellDef="let element"> {{element.districtSpaceId}} </td>
          </ng-container>

          <ng-container matColumnDef="spaceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.spaceName, 20)}} </td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.spaceSize}} m<sup>2</sup> </td>
          </ng-container>

          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator class="custom-paginator"
                       [length]="dataSource ? dataSource.data.length : 0"
                       [hidePageSize]="true"
                       [pageSize]="5"></mat-paginator>
      </div>

    </form>

  </div>

  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="15px">
    <button class="button-primary" mat-flat-button (click)="submitLease()" [disabled]="loader.loading$ | async">
      <mat-icon>check</mat-icon>
      <div class="button-text">Save Changes</div>
    </button>
  </div>

</div>

