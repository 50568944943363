<div class="full-width" fxLayout="column">
  <div fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">{{batchType == BatchType.DISTRICT_PAYOUT ? 'Client payouts' : 'Payment batches'}}</span>
    </div>
  </div>

  <mat-drawer-container [hasBackdrop]="true" class="table-drawer">
    <mat-drawer #batchDrawer class="table-drawer-container"
                mode="over" position="end"
                [opened]="!!selectedBatch">
      <app-payment-batch-dialog [batch]="selectedBatch" (toggleDrawer)="selectedBatch = null; batchDrawer.toggle($event)"></app-payment-batch-dialog>
    </mat-drawer>

    <mat-drawer-content class="table-drawer-content">
      <div class="table-card mat-elevation-z3" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="heading-default">{{batchType == BatchType.DISTRICT_PAYOUT ? 'Offering batches list' : 'Payment batches list'}}</span>

          <span [matBadge]="selectedGroups" matBadgeSize="small" matBadgeOverlap="false" class="heading-default mx-2">Batches to submit</span>
        </div>
        <form [formGroup]="filterForm" fxFlexOffset="20px">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
              <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
                <mat-label>Batch ID</mat-label>
                <input matInput type="text" placeholder="Search here" formControlName="batchId">
              </mat-form-field>

              <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
                <mat-label>Batch Status</mat-label>
                <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="batchStatus">
                  <mat-option class="custom-select-field-option" *ngFor="let status of batchStatuses" [value]="status">{{utils.displayStatus(status)}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <app-custom-date-filter [customDateRangePresets]="dateFilters" (dateRange)="getDateRange($event)"></app-custom-date-filter>

          </div>
        </form>

        <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
          <ng-container matColumnDef="table-label">
            <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Payment Batches </th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="selectAll($event)" [checked]="isAllSelected" color="primary"></mat-checkbox>
            </th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell>
              <mat-checkbox (click)="$event.stopPropagation()" (change)="selectBatch(element.uuid)" color="primary"
                            [checked]="selectedBatches.includes(element.uuid)" [disabled]="element.processStatus != BatchStatus.UNSUBMITTED"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentBatchUuid">
            <th mat-header-cell *matHeaderCellDef> Batch ID </th>
            <td mat-cell class="custom-table-cell btn-cursor"
                matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
                *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentGroupName">
            <th mat-header-cell *matHeaderCellDef> Group Name </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.paymentGroupName}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentBatchStatus">
            <th mat-header-cell *matHeaderCellDef> Batch Status </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayStatus(element.processStatus)}} </td>
          </ng-container>

          <ng-container matColumnDef="processedDate">
            <th mat-header-cell *matHeaderCellDef> Processed Date </th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.processedDate)}} </td>
          </ng-container>

          <ng-container matColumnDef="saleAmountIncl">
            <th mat-header-cell *matHeaderCellDef> Pay-out Amount </th>
            <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmountWithDecimal(element.batchTotalAmount)}} </td>
          </ng-container>

          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="viewBatchItems(row); batchDrawer.toggle(true)"></tr>
        </table>

        <mat-paginator #paginator class="custom-paginator"
                       [length]="dataSource ? dataSource.totalElements : 0"
                       [hidePageSize]="true"
                       [pageSize]="5"></mat-paginator>
      </div>

    </mat-drawer-content>

  </mat-drawer-container>


</div>
