<div class="full-width" fxLayout="column">
  <div fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Transaction Schedule</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3 pb-3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">All transactions</span>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

        <div fxLayout="column" fxFlexAlign="center center" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
              <mat-label>Company Name</mat-label>
              <input matInput type="text" placeholder="Search here" formControlName="companyName">
            </mat-form-field>

            <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
              <mat-label>Sale Amount</mat-label>
              <input matInput type="number" placeholder="Search here" formControlName="saleAmountIncl">
            </mat-form-field>

            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Sale Status</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="saleStatus">
                <mat-option class="custom-select-field-option" *ngFor="let status of saleStatuses"
                            [value]="status">{{utils.displayStatus(status)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Point of Sale Status</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="pointOfSaleStatus">
                <mat-option class="custom-select-field-option" *ngFor="let status of posStatuses"
                            [value]="status">{{utils.displayStatus(status)}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
              <mat-label>Payout Status</mat-label>
              <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="processingStatus">
                <mat-option class="custom-select-field-option" *ngFor="let status of transactionStatusesFilter"
                            [value]="status">{{utils.displayStatus(status)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <app-custom-date-filter [customDateRangePresets]="dateFilters" (dateRange)="getDateRange($event)" [initialPreset]="'1'"></app-custom-date-filter>

      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Daily Transaction Summary </th>
      </ng-container>

      <ng-container matColumnDef="paymentBatchUuid">
        <th mat-header-cell *matHeaderCellDef> Batch ID </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayUuid(element.paymentBatchUuid) }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionUuid">
        <th mat-header-cell *matHeaderCellDef> Trans ID </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayUuid(element.uuid) }} </td>
      </ng-container>

      <ng-container matColumnDef="retailerCompanyName">
        <th mat-header-cell *matHeaderCellDef> Retailer </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayString(element.retailerCompanyName, 20) }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.transactionDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="saleAmountIncl">
        <th mat-header-cell *matHeaderCellDef> Total Sales </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.saleAmountIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="transactionFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.transactionFeeIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="turnoverRentalFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.turnoverRentalFeeIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
        <th mat-header-cell *matHeaderCellDef> Net Balance </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.netBalancePayableRetailerInclRounded)}} </td>
      </ng-container>

      <ng-container matColumnDef="posStatus">
        <th mat-header-cell *matHeaderCellDef> Point Of Sale Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.pointOfSaleStatus)}} </td>
      </ng-container>

      <ng-container matColumnDef="saleStatus">
        <th mat-header-cell *matHeaderCellDef> Sale Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.saleStatus)}} </td>
      </ng-container>

      <ng-container matColumnDef="processingStatus">
        <th mat-header-cell *matHeaderCellDef> Payout Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.processingStatus)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
