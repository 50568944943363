import {BlockingQueueService} from "../../../shared/services/blocking-queue.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {MonthlyStat} from "../dashboards.model";

export function build(http: HttpClient): DashboardsPerformanceReportService {
  return new DashboardsPerformanceReportService(http);
}

export class DashboardsPerformanceReportService extends BlockingQueueService {
  private http: HttpClient;

  constructor(http: HttpClient) {
    super();
    this.http = http;
  }

  private get getBaseUrl(): string {
    return environment.apiTransactHost + '/console/offering/district/dashboard/performance';
  }

  refreshBestMonthlyStats(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<MonthlyStat[]>(this.getBaseUrl + `/best-stats`, {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshSalesPerformanceChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-performance');
  }

  refreshSalesComparisonChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-comparison');
  }

  refreshSalesByIndustryChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-industry');
  }

  refreshTradingDensityByIndustryChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'trading-density-industry');
  }

  refreshTopPerformersTable(fromDate: string, toDate: string, topPercentage: number, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('topPercentage', topPercentage);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl + `/top-performers`, {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshBusiestSalesTimesChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-times');
  }

  refreshBusiestSalesDaysChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-days');
  }

  refreshFootfallStatisticsChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'footfall-stats');
  }

  refreshChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void, urlSuffix: string): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl + `/${urlSuffix}`, {params: requestParams});
    this.enqueue(task, callback, error);
  }
}
