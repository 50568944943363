import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {ManageSpacesService} from "../service/manage-spaces.service";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {IndustryCategory, IndustryType} from "../../leads/industry.model";
import {OfferingSpacesInterface, SpaceType} from "../model/offering-spaces.interface";
import {forkJoin} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {GenericTypeDef} from "../../../core/model/generic-type-def.model";
import {PipelineService} from "../../leads/pipeline/pipeline.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  ConfirmationDialogComponent
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-list-spaces',
  templateUrl: './list-spaces.component.html',
  styleUrls: ['./list-spaces.component.scss']
})
export class ListSpacesComponent extends BasePageComponent implements OnInit, AfterViewInit, OnChanges {
  @Input("dirty") selectedSpaceDirty!: boolean;
  @Input() spaceUpdated!: OfferingSpacesInterface | null;
  @Output() spaceSelected = new EventEmitter<OfferingSpacesInterface>();
  @Output() addNewSpace = new EventEmitter();

  // selectedSpace: OfferingSpacesInterface | null = null;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = ['name', 'size', 'availableFrom', 'availableUntil', 'industry', 'status'];

  initialData: OfferingSpacesInterface[] = [];
  dataSource!: MatTableDataSource<OfferingSpacesInterface>;

  // filters: TableFilers;
  filters: FormGroup;

  allIndustries: IndustryCategory[] = [];

  spaceTypes: string[] = Object.values(SpaceType);
  pageSize = 10;
  pageIndex = 0;
  selectedRowId: number = 0;

  constructor(injector: Injector,
              private fb: FormBuilder,
              private spaceService: ManageSpacesService,
              private pipelineService: PipelineService,
              private matDialog: MatDialog,) {
    super(injector);
    this.filters = this.emptyFilter();
  }

  ngOnInit(): void {
    this.loadIndustries();

    this.filters.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }
  ngAfterViewInit(): void {
    this.loadSpaces();
  }

  ngOnChanges(changes: any) {
    if (changes.spaceUpdated && changes.spaceUpdated.currentValue) {
      const index = this.initialData.findIndex(space => space.id === this.spaceUpdated!.id);
      index !== -1 ? this.initialData[index] = this.spaceUpdated! : this.initialData.push(this.spaceUpdated!);
      this.applyFilters();
    }
  }

  loadSpaces() {
    const loadUnavailableSpaces$ = this.spaceService.getSpacesForOffering({spaceName: null, locked: true});
    const loadAvailableSpaces$ = this.spaceService.getSpacesForOffering({spaceName: null, locked: false});

    forkJoin([loadUnavailableSpaces$, loadAvailableSpaces$]).subscribe(([unavailableSpaces, availableSpaces]) => {
      this.initialData = [...unavailableSpaces, ...availableSpaces];
      this.dataSource = new MatTableDataSource(this.initialData.filter(space => !space.locked));
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilters() {
    let filteredSpaces = this.initialData.filter(space => {
      this.initialData.sort((a, b) => a.spaceName.localeCompare(b.spaceName));

      if (this.filters.get('spaceName')!.value && !space.spaceName.toLowerCase().includes(this.filters.get('spaceName')!.value.toLowerCase())) {
        return false;
      }
      if (this.filters.get('spaceSize')!.value && space.spaceSize != this.filters.get('spaceSize')!.value) {
        return false;
      }
      if (this.filters.get('spaceType')!.value && space.spaceType !== this.filters.get('spaceType')!.value) {
        return false;
      }
      if (this.filters.get('districtSpacesIndustryCodes')!.value && this.filters.get('districtSpacesIndustryCodes')!.value!.length > 0) {
        const spaceIndustryCodes = space.districtSpacesIndustries.map(m => m.code);
        const filterIndustryCodes = this.filters.get('districtSpacesIndustryCodes')!.value!;

        return spaceIndustryCodes.some(code => filterIndustryCodes.includes(code));
      }
      if (this.filters.get('startDate')!.value && space.startDate <= this.filters.get('startDate')!.value) {
        return false;
      }
      if (this.filters.get('endDate')!.value && space.endDate! >= this.filters.get('endDate')!.value) {
        return false;
      }
      if (!this.filters.get('locked')!.value && space.locked) {
        return false;
      }
      return true;
    });
    this.dataSource.data = filteredSpaces;
    this.dataSource.paginator!.firstPage();
  }

  private emptyFilter(): FormGroup {
    return this.fb.group({
      spaceName: [''],
      spaceSize: [null],
      spaceType: [''],
      districtSpacesIndustryCodes: [],
      startDate: [null],
      endDate: [null],
      locked: [false],
    });
  }

  resetFilters() {
    this.filters = this.emptyFilter();
    this.applyFilters();
  }

  onPageChange(event: { pageSize: number; pageIndex: number; }) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  getIndustryLabels(industries: GenericTypeDef[]): string {
    return industries.map(industry => industry.name).join(', ');
  }

  loadIndustries() {
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
      }
    })
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.filters.get('districtSpacesIndustryCodes')!.setValue(newSelects);
  }

  updateShowExpired(checked: boolean) {
    this.filters.get('locked')!.setValue(checked);
  }

  showExpired(): boolean {
    return this.filters.get('locked')?.value === true;
  }

  totalElements(): number {
    return this.dataSource?.data?.length ?? 0;
  }

  spaceSelectedEvent(space: OfferingSpacesInterface) {
    if(this.selectedSpaceDirty){
      this.showConfirmationDialog(space);
      return;
    }

    this.emitSpaceSelectedEvent(space);
  }

  private showConfirmationDialog(space: OfferingSpacesInterface){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: 'There are unsaved changes to the space you are currently editing. Are you sure you want to change the space?',
      buttonConfirmLabel: 'Discard changes',
      buttonCancelLabel: 'Cancel'
    };

    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this. emitSpaceSelectedEvent(space);
      }
    });
  }

  private emitSpaceSelectedEvent(space: OfferingSpacesInterface){
    this.selectedRowId = space.id;
    this.spaceSelected.emit(space);
  }

  addNewSpaceEvent() {
    this.addNewSpace.emit({instruction: 'addNewSpace'})
  }
}
