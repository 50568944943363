<div class="full-width" fxLayout="column">
  <div class="full-width" fxLayout="column">
    <div class="full-width" fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="subtitle-default">Products Jobcard</span>
        <button mat-icon-button class="refresh-button" (click)="loadProductsRunInfo()">
          <mat-icon *ngIf="!(loader.loading$ | async)" color="primary">refresh</mat-icon>
          <mat-spinner diameter="20" *ngIf="(loader.loading$ | async)"></mat-spinner>
        </button>
      </div>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="20px">
      <div class="info-card" fxLayout="column">

        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" *ngFor="let job of runResponse; let last = last;">
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="40">
              <span class="body-1-default">{{job.name}}</span>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <span class="body-2-default">{{job.jobStatus}}</span>
                <button mat-icon-button class="run-job-button"
                        [class.run-button-success]="job.jobStatus === 'Success'"
                        [class.run-button-disbaled]="job.jobStatus === 'Disabled'"
                        [class.run-button-error]="job.jobStatus === 'Error'"
                        [class.run-button-timeout]="job.jobStatus === 'Timeout' || job.jobStatus == 'Busy'"
                        (click)="runService(job)" [disabled]="isPageLoading() || job.jobStatus == 'Disabled' || !!(loader.loading$ | async)">
                  <mat-icon *ngIf="job.jobStatus === 'Ready'">arrow_forward</mat-icon>
                  <mat-icon *ngIf="job.jobStatus === 'Success'">check</mat-icon>
                  <mat-icon *ngIf="job.jobStatus === 'Timeout'">error_outline</mat-icon>
                  <mat-icon *ngIf="job.jobStatus === 'Error'">close</mat-icon>
                  <mat-icon *ngIf="job.jobStatus === 'Busy'">error_outline</mat-icon>
                  <mat-icon *ngIf="job.jobStatus === 'Disabled'">close</mat-icon>
                </button>
                <mat-spinner diameter="20" color="primary" *ngIf="isPageLoading()"></mat-spinner>
              </div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="17">
              <div class="body-2-default">Job Status</div>
              <div class="body-2-default">{{utils.displayStatus(job.status!)}}</div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="17">
              <div class="body-2-default">Job Enabled</div>
              <div class="body-2-default">{{job.enabled ? 'Enabled' : 'Disabled'}}</div>
            </div>

            <div class="full-width py-3" fxLayout="column" fxLayoutGap="10px" fxFlex="16">
              <div class="body-2-default">Locked On</div>
              <div class="body-2-default">{{dateUtils.displayDateWithTime(job.lockedOn)}}</div>
            </div>

          </div>

          <div class="full-width" fxLayout="column" fxLayoutGap="7px" *ngIf="job.id == 'products_job_import_retailer_products' || job.id == 'products_job_import_retailer_product_inventory'">
            <span class="body-3-default">Choose a retailer</span>
            <mat-form-field class="custom-text-field" appearance="outline">
              <input class="input-field" matInput type="text" [formControl]="searchTerm" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectRetailer($event)">
                <mat-option *ngFor="let option of filteredRetailers | async" [value]="option">
                  {{option.id}}. {{option.companyName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <mat-divider class="full-width" fxFlexOffset="10px" *ngIf="!last"></mat-divider>
        </div>

      </div>
    </div>

  </div>
</div>

