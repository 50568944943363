import {ChartConfiguration} from "chart.js";

export const defaultChartOptions: ChartConfiguration<"line" | "bar" | "scatter">['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.5
    }
  },
  scales: {
    x: {
      border: {
        display: false
      },
      grid: {
        tickColor: '#000000BD',
        color: 'rgba(0,0,0,0)',
        offset: false
      },
      ticks: {
        maxRotation: 0,
        maxTicksLimit: 15,
        autoSkip: true,
        font: {
          family: 'Roboto'
        }
      }
    },
    y: {
      position: 'left',
      ticks: {
        maxTicksLimit: 6,
      },
      min: 0,
      border: {
        width: 1,
        color: '#000000BD',
      },
      grid: {
        tickColor: '#000000BD'
      },
      grace: '1%'
    },
    y1: {
      display: false,
      position: 'right',
      ticks: {
        maxTicksLimit: 6
      },
      min: 0,
      grid: { display: false },
      grace: '1%'
    },
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      reverse: true,
      labels: {}
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      backgroundColor: 'white',
      titleFont: {
        family:'Roboto',
        size: 10,
        weight: '500'
      },
      titleColor: '#000000BD',
      bodyFont: {
        family:'Roboto',
        size: 10,
        weight: '300'
      },
      bodyColor: '#00000099',
      usePointStyle: true
    }
  },
  layout: {
    padding: 10
  }
}

export const defaultRadarChartOptions: ChartConfiguration<'radar'>["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    r: {
      ticks: {
        display: false
      },
      grid: {
        circular: true
      },
      pointLabels: {
        font: {
          family:'Roboto',
          size: 13,
          weight: '400'
        },
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      reverse: true,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 5,
        boxHeight: 5,
        useBorderRadius: true,
        borderRadius: 1,
      }
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      backgroundColor: 'white',
      titleFont: {
        family:'Roboto',
        size: 10,
        weight: '500'
      },
      titleColor: '#000000BD',
      bodyFont: {
        family:'Roboto',
        size: 10,
        weight: '300'
      },
      bodyColor: '#00000099',
      usePointStyle: true,
    }
  }
}

export const defaultDoughnutChartOptions: ChartConfiguration<'doughnut'>["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '66.7%',
  radius: 100,
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      reverse: true,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 5,
        boxHeight: 5,
        useBorderRadius: true,
        borderRadius: 1,
      }
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      backgroundColor: 'white',
      titleFont: {
        family:'Roboto',
        size: 10,
        weight: '500'
      },
      titleColor: '#000000BD',
      bodyFont: {
        family:'Roboto',
        size: 10,
        weight: '300'
      },
      bodyColor: '#00000099',
      usePointStyle: true,
    }
  }
}
