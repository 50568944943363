<div class="notifications-body px-2 pb-3 pt-2" fxLayout="column" fxLayoutAlign="space-between center" fxFlex style="flex-grow: 1 !important;">
  <div fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width mb-2" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-small" mat-icon-button (click)="close(false)">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width px-2 pb-1" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="body-1-default">Notification title</div>
      <div class="body-4-primary btn-cursor" (click)="markAllAsRead()">Mark all as read</div>
    </div>

    <div [ngClass]="showAll ? 'notifications-container' : 'notifications-container-small'" class="full-width" fxLayout="column" fxLayoutAlign="start center">

      <div class="full-width"
           [ngClass]="notification.critical ? 'notification-tile-warn' : (notification.read ? 'notification-tile-read' : 'notification-tile-unread')"
           fxFlexOffset="10px"
           *ngFor="let notification of messagesToDisplay()">
        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="notification-title">{{notification.title}}</div>
          <div class="notification-time">{{displayNotificationTime(notification.created)}}</div>
        </div>

        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="notification-text">{{notification.description}}</div>
          <button class="button-close-small" mat-icon-button (click)="markAsRead(notification)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="notifications-button" fxFlexOffset="15px">
    <button mat-flat-button class="dialog-button" (click)="toggleShowAll()">
      <div class="footer-primary">Show {{showAll ? 'less' : 'all'}} messages</div>
    </button>
  </div>

</div>
