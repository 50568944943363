import {HttpClient, HttpParams} from "@angular/common/http";
import {BlockingQueueService} from "../../../shared/services/blocking-queue.service";
import {environment} from "../../../../environments/environment";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {MonthlyStat} from "../dashboards.model";

export function build(http: HttpClient): DashboardsLeasingReportService {
  return new DashboardsLeasingReportService(http);
}

export class DashboardsLeasingReportService extends BlockingQueueService {
private http: HttpClient;

  constructor(http: HttpClient) {
    super();
    this.http = http;
  }

  private get getBaseUrl(): string {
    return environment.apiTransactHost + '/console/offering/district/dashboard/leasing';
  }

  refreshBestMonthlyStats(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<MonthlyStat[]>(this.getBaseUrl + `/best-stats`, {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshRentalIncomeChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rental-income');
  }

  refreshRentalIncomeOccupancyChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rental-income-occupancy');
  }

  refreshTradingDensityOccupancyChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'trading-density-occupancy');
  }

  refreshRentalIncomeTradingDensityChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rental-income-trading-density');
  }

  refreshEffectiveIncomeRentRatioChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'effective-income-rent-ratio');
  }

  refreshRentalIncomeByCategoryChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rental-income-category');
  }

  refreshRentalIncomePerSqmByCategoryChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rental-income-sqm-category');
  }

  refreshChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void, urlSuffix: string): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl + `/${urlSuffix}`, {params: requestParams});
    this.enqueue(task, callback, error);
  }

}
