import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {lastValueFrom} from "rxjs";
import {PageEventInterface} from "../../../../../core/interfaces/page-event.interface";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {RegisterUserRequest} from "../../../../../core/model/user.model";
import {LoadingService} from "../../../../../core/services/loading.service";
import {UserService} from "../../../../../core/services/security/user.service";
import {TabManagementService} from "../../../../../core/services/tab-management.service";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {IndustryCategory, IndustryType} from "../../../industry.model";
import {PipelineService} from "../../../pipeline/pipeline.service";
import {RetailerViewComponent} from "../../retailer-view/retailer-view.component";
import {RegisterRetailer, TenantType} from "../../retailers.model";
import {CreateARetailerService} from "./create-a-retailer.service";

@Component({
  selector: 'app-create-a-retailer-dialog',
  templateUrl: './create-a-retailer-dialog.component.html',
  styleUrls: ['./create-a-retailer-dialog.component.scss']
})
export class CreateARetailerDialogComponent implements OnInit {
  offering!: PageOffering;

  retailerForm: FormGroup;

  selectedIndustries: string[] = [];
  allIndustries: IndustryCategory[] = [];

  userCreated = false;
  errMessage = '';

  userId!: number;

  constructor(public dialogRef: MatDialogRef<CreateARetailerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private tabService: TabManagementService,
              private pipelineService: PipelineService,
              public loader: LoadingService,
              private userService: UserService,
              private createRetailerService: CreateARetailerService) {
    this.offering = data.offering;

    this.retailerForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      companyName: new FormControl(null, Validators.required),
      industryTypes: new FormControl([], Validators.required)
    })
  }

  ngOnInit() {
    this.loadIndustries();
  }

  loadIndustries(){
    this.pipelineService.getAllIndustries().subscribe({
      next: value => {
        this.allIndustries = value;
        this.allIndustries.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => { ind.selected = false });
        });
      }
    })
  }

  groupClicked(group: IndustryCategory): void {
    this.allIndustries.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
        this.updateSelected();
      }
    });
  }

  optionClicked(group: IndustryCategory, item: IndustryType): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.allIndustries.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
  }

  submitForm() {
    if (this.userCreated) {
      this.registerRetailer();
    } else {
      this.registerUser()
        .then(res => {
          if (res) this.registerRetailer();
        })
    }
  }

  registerUser() {
    const newUser = new RegisterUserRequest({
      firstname: this.retailerForm.get('firstName')?.value,
      lastname: this.retailerForm.get('lastName')?.value,
      email: this.retailerForm.get('email')?.value,
    })

    return lastValueFrom(this.userService.registerNewPortalUser(newUser))
      .then(res => {
        this.userCreated = true;
        this.userId = res.id!;
        return true;
      })
      .catch((err) => {
        this.userCreated = false;
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
            }
            break;
          default:
            this.errMessage = 'An unexpected error occurred, please try again';
        }
        console.log(err);
        return false;
      })
  }

  registerRetailer() {
    const newRetailer: RegisterRetailer = {
      companyName: this.retailerForm.get('companyName')?.value,
      userId: this.userId,
      industries: this.retailerForm.get('industryTypes')?.value
    }

    lastValueFrom(this.createRetailerService.registerNewRetailer(newRetailer))
      .then(res => {
        console.log('Retailer created')
        this.openRetailerViewTab(res.id!.toString(), res.companyName, res.tenantType);
        this.close();
      })
      .catch(err => {
        console.log('Error');
        // if ('ValidationException' === err.headers.get('error_code')) {
        //   this.errMessage = err.headers.get('error_message');
        // }
        console.log(err);
      })
  }

  close() {
    this.dialogRef.close();
  }

  openRetailerViewTab(id: string, companyName: string, tenantType: TenantType) {
    let payload: PageEventInterface = {
      componentToRegister: RetailerViewComponent,
      pageName: 'Retailer ' + id,
      pageHeader: this.offering!.label,
      data: { id: id, companyName: companyName, tenantType: tenantType },
      id: this.utils.generateUuidWithPrefix('retailer'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leads',
      pageCode: 'retailer',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  getErrorMessage(formField: string) {
    if (this.retailerForm.get(formField)!.hasError('required')) return 'You must enter a value';
    if (formField == 'email' && this.retailerForm.get(formField)!.hasError('email')) return 'Not a valid email';
    return null;
  }

}
