import {Type} from '@angular/core';
import {PageOffering} from '../model/side-nav.model';

export interface PageEventInterface {
  componentToRegister: Type<any>,
  pageName: string,
  pageHeader: string,
  data: any
  id: string,
  offeringId?: string,
  sectionCode?: string,
  pageCode?: string
  offering?: PageOffering
}

export class BasePageId {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}
