export class PayoutRunResponse {
  loading: boolean = false;
  currentCorrelationId: string | null = null;
  status: string | null = null;
  updateDate: string | null = null;
  name: string | null = null;
  id: string | null = null;
  prevCorrelationId: string | null = null;
  prevOutcome: string | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export enum BatchType {
  RETAILER_PAYOUT = 'RETAILER_PAYOUT',
  DISTRICT_PAYOUT = 'DISTRICT_PAYOUT'
}

export const payoutJobs = [
  {
    name: 'Import New Transactions',
    id: 'transaction_job_import_new_transactions'
  },
  {
    name: 'Process Payout Billing',
    id: 'transaction_job_process_payout_billing'
  },
  {
    name: 'Create Payment Batch',
    id: 'transaction_job_create_daily_payment_batch'
  },
  {
    name: 'Submit Payment Batch',
    id: 'transaction_job_submit_daily_transactions_for_payment'
  },
];

export const clientPayoutJobs = [
  {
    name: 'Create Payment Batch',
    id: 'transaction_job_create_client_payment_batch'
  },
  {
    name: 'Submit Payment Batch',
    id: 'transaction_job_submit_client_transactions_for_payment'
  },
]
