import {Component, Injector, Input} from '@angular/core';
import {PageItemModel} from '../../model/page-item.model';
import {PageComponentDataProvider} from '../../interfaces/page-component-data-provider';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TabManagementService} from '../../services/tab-management.service';
import {PageOffering} from '../../model/side-nav.model';
import {LandlordPropertyModel} from '../../model/landlordProperty.model';
import {BasePageId} from '../../interfaces/page-event.interface';

@Component({
  selector: 'app-page-tab-content',
  templateUrl: './page-tab-content.component.html',
  styleUrls: ['./page-tab-content.component.scss']
})
export class PageTabContentComponent {

  @Input() pages: PageItemModel[] = [];
  @Input() selectedProperty!: LandlordPropertyModel;

  selectedTab = 0;
  injectors: { [key: string]: Injector } = {};

  constructor(private injector: Injector,
              private tabManagementService: TabManagementService) {
    this.tabManagementService.getTabChangeEvent()
      .subscribe(($event) => {
        this.selectedTab = $event.tabEvent.index;
      });
    this.tabManagementService.getPageCloseEvent()
      .subscribe((pageToClose: PageItemModel) => {
        let injectorToDelete = this.injectors[pageToClose.id];
        if (injectorToDelete) {
          delete this.injectors[pageToClose.id]
        }
      });
  }

  getInjector(comp: PageItemModel) {
    let inject = this.injectors[comp.id];
    if (!inject) {
      inject = Injector.create([{provide: PageComponentDataProvider, useValue: comp.data}, {provide: PageOffering, useValue: comp.offering}, {provide: BasePageId , useValue: {id: comp.id}}], this.injector);
      this.injectors[comp.id] = inject;
    }
    return inject;
  }

  onTabChange($event: MatTabChangeEvent): void {
    this.selectedTab = $event.index;
  }

  getTitle(): string {
    return `Welcome to your ${this.selectedProperty.locationName} Property`;
  }
}
