<div class="full-width" fxLayout="column" xmlns="http://www.w3.org/1999/html">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Occupancy and lease planning</span>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Current occupancy schedule</span>

    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="companyName">
          </mat-form-field>
          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Space Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="spaceNames">
          </mat-form-field>
        </div>

        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
          <mat-label>Expiration In</mat-label>
          <input matInput type="text" disabled >
          <mat-button-toggle-group matSuffix class="custom-borderless-button-toggle" multiple (change)="setExpiresIn($event)">
            <ng-container *ngFor="let customRange of dateRanges">
              <mat-button-toggle value="{{customRange}}">{{customRange}} days</mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </mat-form-field>
      </div>
    </form>

    <div fxLayout="row" fxFlexOffset="25px">
      <table mat-table class="custom-table half-table occupancy-table" [dataSource]="leasesDataSourceDisplayed" fxFlex="60">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="leasesDisplayedColumns.length"> Leases </th>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.retailerCompanyName}} </td>
        </ng-container>

        <ng-container matColumnDef="industryType">
          <th mat-header-cell *matHeaderCellDef> Industry or Product Collection </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.uuid ? utils.displayString(displayIndustriesList(element.retailerIndustryTypes), 30) : null}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="expiresInDays">
          <th mat-header-cell *matHeaderCellDef> Days Until Expiration </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.expiresInDays ? element.expiresInDays + ' days' : '-'}} </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="leasesDisplayedColumns"></tr>
        <tr mat-row class="custom-table-row btn-cursor"
            (click)="openLeaseViewTab(row.uuid, row.occupancyType, row.agreementType)" *matRowDef="let row; columns: leasesDisplayedColumns;"></tr>
      </table>

      <table mat-table class="custom-table half-table occupancy-table" [dataSource]="spacesDataSource" fxFlex="40">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="spacesDisplayedColumns.length"></th>
        </ng-container>

        <ng-container matColumnDef="spaceName">
          <th mat-header-cell *matHeaderCellDef> Space Name </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.spaceName}} </td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef> Size </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.spaceSize}} <span *ngIf="element.id">m<sup>2</sup></span> </td>
        </ng-container>

<!--        <ng-container matColumnDef="preferredIndustries">-->
<!--          <th mat-header-cell *matHeaderCellDef> Preferred Industry or Product Collection </th>-->
<!--          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">-->
<!--            <mat-form-field class="custom-select-field table-select" appearance="outline">-->
<!--              <mat-select class="custom-select-field-value" placeholder="Select" multiple>-->
<!--                <mat-option class="custom-select-field-option" *ngFor="let offering of industryTypes" [value]="offering.code">{{offering.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </td>-->
<!--        </ng-container>-->

<!--        <ng-container matColumnDef="renew">-->
<!--          <th mat-header-cell *matHeaderCellDef>Plan To Renew</th>-->
<!--          <td mat-cell class="custom-table-cell" *matCellDef="let row"><mat-checkbox [checked]="false" color="primary"></mat-checkbox></td>-->
<!--        </ng-container>-->

<!--        <ng-container matColumnDef="preferredRetailers">-->
<!--          <th mat-header-cell *matHeaderCellDef> Preferred Retailers </th>-->
<!--          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">-->
<!--            <mat-form-field class="custom-text-field table-select" appearance="outline">-->
<!--              <input class="input-field" placeholder="select" matInput type="text" [formControl]="searchTerm" (input)="loadRetailers()" [matAutocomplete]="auto">-->
<!--              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">-->
<!--                <mat-option *ngFor="let option of allRetailers | async" [value]="option">-->
<!--                  {{option.uuid}}. {{option.companyName}}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
<!--          </td>-->
<!--        </ng-container>-->

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="spacesDisplayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: spacesDisplayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator #paginator class="custom-paginator"
                   [length]="leasesDataSource.totalElements"
                   [pageSize]="25"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
