import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {MatSidenav} from "@angular/material/sidenav";
import * as moment from "moment";
import {finalize, forkJoin, lastValueFrom} from 'rxjs';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {TimelineComponent} from "../../../../shared/components/timeline/timeline.component";
import {
  Timeline,
  TimelineElement,
  TimelineList,
  TimelineObject,
  TimelineObjectData,
  TimelinePeriod
} from "../../../../shared/components/timeline/timeline.model";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {Retailer} from "../../../leads/retailers/retailers.model";
import {ManageSpacesComponent} from "../../../spaces/manage-spaces/manage-spaces.component";
import {OfferingSpaceStatistics} from '../../../spaces/model/offering-spaces.interface';
import {ManageSpacesService} from '../../../spaces/service/manage-spaces.service';
import {LeaseViewService} from "../../lease-view/lease-view.service";
import {
  AgreementType,
  LeaseGridSpace,
  LeaseGridSpaceGroup,
  LeaseStatus,
  LeaseSummary,
  OccupancyType,
  OccupiedSpace
} from "../../leasing.model";
import {LeasingService} from "../../leasing.service";

@Component({
  selector: 'app-lease-planning-view',
  templateUrl: './lease-planning-view.component.html',
  styleUrls: ['./lease-planning-view.component.scss']
})
export class LeasePlanningViewComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild("timelineComponent") timelineComponent?: TimelineComponent;
  @ViewChild('rightSidenav') sidenav!: MatSidenav;

  timeline: Timeline = {
    displayName: 'Manage Vacancy',
    description: 'The lease planning module allows you to plan renewals or new leases according to vacancies.',
    timelineLists: [],
    focusCurrentDate: true,
    earliestDate: new Date(this.offering!.offeringStartDate!),
    latestDate: moment(new Date()).add(1000, 'weeks').toDate(),
    initialPeriod: TimelinePeriod.WEEK,
    newObjectLabel: 'New planned lease'
  };

  editTimeline: boolean = false;

  timelineSpaces: OccupiedSpace[] = [];
  invalidTimelineSpaces: OccupiedSpace[] = [];

  timelineDrawerOpened: boolean = false;

  dateRange: DateRange<Date> = new DateRange<Date>(this.timeline.earliestDate, this.timeline.latestDate);

  selectedTab: string = "timeline";
  selectedSpace!: OccupiedSpace;

  selectedLeaseSpace: LeaseGridSpaceGroup | null = null;
  originalLeaseSpace: LeaseGridSpaceGroup | null = null;

  selectedLease: LeaseSummary | null = null;

  statSpaceStatistics: OfferingSpaceStatistics | null = null;

  constructor(injector: Injector,
              private leasingService: LeasingService,
              private leaseViewService: LeaseViewService,
              private dateUtils: DateUtilsService,
              private utils: UtilsService,
              private spaceService: ManageSpacesService,
              private tabManagementService: TabManagementService) {
    super(injector);
  }

  ngOnInit() {
    this.loadAllSpaces(true);
    if (this.selectedSpace && this.selectedSpace.districtSpace) {
      lastValueFrom(this.spaceService.getStatsForSpace(this.selectedSpace.districtSpace.id))
        .then((res) => {
          this.statSpaceStatistics = res;
        });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.sidenav.open(), 200);
  }

  loadAllSpaces(init: boolean) {
    let spacesSearchTerm = this.timeline?.timelineLists?.find(f => f.displayOrder == 0)?.searchTerm ?? null;
    let invalidSpacesSearchTerm = this.timeline?.timelineLists?.find(f => f.displayOrder == 1)?.searchTerm ?? null;

    const loadSpaces$ = this.leasingService.getLeaseOccupancy(spacesSearchTerm, this.dateUtils.displayShortDate(this.dateRange.start)!, this.dateUtils.displayShortDate(this.dateRange.end)!, null);
    const loadInvalidSpaces$ = this.leasingService.getLeaseOccupancy(invalidSpacesSearchTerm, this.dateUtils.displayShortDate(this.dateRange.start)!, this.dateUtils.displayShortDate(this.dateRange.end)!, 'INVALID')

    forkJoin([loadSpaces$, loadInvalidSpaces$])
      .pipe(
        finalize(() => {
          // This block will execute after both requests are complete
          console.log('Both requests completed');
          // Call your additional functions here
          if (init) {
            this.timeline = { ...this.timeline };
          }
        })
      )
      .subscribe(([spaces, invalidSpaces]) => {
        if(init){
          this.timeline.timelineLists = [];
          this.timelineSpaces = spaces;
          this.invalidTimelineSpaces = invalidSpaces;
          this.createTimelineList(this.timelineSpaces, 'Spaces', 0);
          this.createTimelineList(this.invalidTimelineSpaces, 'Invalid Leases', 1);
          return;
        } else {
          // Call a function for each result
          this.addLeaseSpaces(spaces, this.timelineSpaces);
          this.addLeaseSpaces(invalidSpaces, this.invalidTimelineSpaces);
          this.addToTimelineList(spaces, 0);
          this.addToTimelineList(invalidSpaces, 1);
        }

      }, error => console.log(error));
  }

  addLeaseSpaces(newSpaces: OccupiedSpace[], spacesList: OccupiedSpace[]) {
    spacesList.forEach(space => {
      const newSpace = newSpaces.filter(f => f.districtSpace.id == space.districtSpace.id)[0];
      const existingGroupUuids = space.leaseGridSpaceGroups.map(m => m.groupUuid);

      const newGroups = newSpace.leaseGridSpaceGroups.filter(f => !existingGroupUuids.includes(f.groupUuid));
      space.leaseGridSpaceGroups.push(...newGroups);
    })
  }

  createTimelineList(data: OccupiedSpace[], listName: string, displayOrder: number) {
    const elements: TimelineElement[] = [];
    const listId = this.utils.generateUuid();

    data.forEach(space => {
      const elementId = space.districtSpace.id.toString();

      const objects: TimelineObject[] = [];

      space.leaseGridSpaceGroups.forEach(group => {
        objects.push(this.createTimelineObject(group, elementId, listId));
      })

      const element: TimelineElement = {
        displayName: space.districtSpace.spaceName,
        displayValue: this.utils.displayNumber(Number(space.districtSpace.spaceSize)) + ' m²',
        uuid: elementId,
        listId: listId,
        elementObjects: objects
      }

      elements.push(element);
    })

    const list: TimelineList = {
      displayName: listName,
      displayOrder: displayOrder,
      listId: listId,
      searchTerm: '',
      actionButton: displayOrder == 0,
      actionLabel: 'Manage spaces',
      listElements: elements,
      maxLines: displayOrder == 0 ? 10 : 3
    }

    this.timeline.timelineLists.push(list);
  }

  addToTimelineList(data: OccupiedSpace[], displayOrder: number) {
    const existingListIndex = this.timeline.timelineLists.findIndex(f => f.displayOrder == displayOrder);
    const existingList = this.timeline.timelineLists[existingListIndex];

    data.forEach(space => {
      const elementId = space.districtSpace.id.toString();
      const element = existingList.listElements.filter(f => f.uuid == elementId)[0];

      const existingObjectUuids = element.elementObjects.map(m => m.uuid);

      const newObjects: TimelineObject[] = space.leaseGridSpaceGroups
        .filter(f => !existingObjectUuids.includes(f.groupUuid))
        .map(group => this.createTimelineObject(group, elementId, existingList.listId));

      element.elementObjects.push(...newObjects);
    })
  }

  createTimelineObject(group: LeaseGridSpaceGroup, elementId: string, listId: string) {
    const objectData: TimelineObjectData[] = group.leaseGridSpaces.map(lgs => {
      return {
        uuid: lgs.uuid,
        displayName: lgs.retailerCompanyName!,
        groupUuid: group.groupUuid,
        parentUuid: lgs.leaseUuid,
        groupRanking: lgs.periodGroupRanking!
      }
    });

    const objectInfo = group.leaseGridSpaces[0];

    const object: TimelineObject = {
      uuid: group.groupUuid,
      elementId: elementId,
      listId: listId,
      startDate: this.dateUtils.displayShortDate(group.startDate)!,
      endDate: this.dateUtils.displayShortDate(group.endDate)!,
      objectData: objectData,
      editable: objectInfo.availabilityStatus != 'LEASED',
      lighten: this.getLightenValue(objectInfo.leaseStatus!),
      draggable: objectInfo.availabilityStatus == 'LEASED' ? 'none' : 'any',
      expandable: objectInfo.availabilityStatus == 'LEASED' ? 'none' : 'any',
      expandInfinitely: false,
      icon: this.getIcon(objectInfo.leaseStatus!)
    }
    return object;
  }

  updateDateRange(event: DateRange<Date>) {
    this.dateRange = event;
    this.timeline.focusCurrentDate = false;
    this.loadAllSpaces(false);
  }

  getLightenValue(status: LeaseStatus) {
    switch (status) {
      case LeaseStatus.ACTIVE:
        return 0;
      case LeaseStatus.APPROVED:
        return 20;
      case LeaseStatus.PLANNED:
        return 40;
      default:
        return 100;
    }
  }

  getIcon(status: LeaseStatus) {
    switch (status) {
      case LeaseStatus.ACTIVE:
        return 'check_circle';
      case LeaseStatus.APPROVED:
        return 'preliminary';
      case LeaseStatus.PLANNED:
        return 'pending';
      default:
        return 'cancel';
    }
  }

  switchTabs(tabName: string) {
    this.selectedTab = tabName;
    this.loadAllSpaces(true);
  }

  selectSpace($event: string | number) {
    if ($event) {
      this.selectedSpace = this.timelineSpaces
        .filter(f => f.districtSpace.id.toString() == $event)[0];
      if (this.selectedSpace) {
        lastValueFrom(this.spaceService.getStatsForSpace(this.selectedSpace.districtSpace.id))
          .then((res) => {
            this.statSpaceStatistics = res;
          });
      } else {
        this.statSpaceStatistics = null;
      }

    }
  }

  selectLeaseSpace($event: string) {
    const existing = this.selectedSpace
      ? this.selectedSpace.leaseGridSpaceGroups.filter(f => f.groupUuid == $event) : [];
    const alreadySelected = this.selectedLeaseSpace?.groupUuid == $event;

    if (!alreadySelected) {
      if (existing.length > 0) {
        this.selectedLeaseSpace = { ...existing[0] };
        this.originalLeaseSpace = { ...existing[0] };

        this.selectedLeaseSpace.leaseGridSpaces = existing[0].leaseGridSpaces.map(m => {
          return {...m}
        });
        this.originalLeaseSpace.leaseGridSpaces = existing[0].leaseGridSpaces.map(m => {
          return {...m}
        });
      }

      const object = this.getTimelineObject($event);
      this.selectedLease = null;

      if (this.selectedLeaseSpace && !object.editable && object.objectData.length > 0) {
        const leaseUuid = object.objectData[0].parentUuid!;
        lastValueFrom(this.leaseViewService.getOne(``, leaseUuid))
          .then(sum => {
            this.selectedLease = sum;
            lastValueFrom(this.leaseViewService.getLeaseSpaces(leaseUuid))
              .then(spaces => {
                this.selectedLease!.leaseGridSpaces! = spaces;
              })
              .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
      }
    }
  }

  updateLeaseSpace() {
    this.editTimeline = true;

    const updatedObject = this.getTimelineObject(this.selectedLeaseSpace!.groupUuid);

    updatedObject.startDate = this.selectedLeaseSpace!.startDate.toString();
    updatedObject.endDate = this.selectedLeaseSpace!.endDate.toString();

    this.timelineComponent!.updateTimelineItemFromObject(updatedObject.uuid, updatedObject.listId, true, false);
  }

  removeLeaseSpace(uuid: string) {
    this.editTimeline = true;

    this.removeObjectData(this.selectedLeaseSpace!.groupUuid, uuid);
  }

  addLeaseGridSpaceToGroup(retailer: Retailer) {
    const rank = this.selectedLeaseSpace!.leaseGridSpaces.length;

    const lgs: LeaseGridSpace = {
      uuid: this.utils.generateUuid(),
      leaseUuid: null,
      occupancyType: this.selectedLease ? this.selectedLease.occupancyType! : OccupancyType.SINGLE,
      agreementType: this.selectedLease ? this.selectedLease.agreementType! : AgreementType.LEASE,
      districtSpaceId: this.selectedLeaseSpace!.spaceId,
      leaseStartDate: this.selectedLeaseSpace!.startDate,
      leaseEndDate: this.selectedLeaseSpace!.endDate,
      availabilityStatus: 'PENDING',
      spaceSize: null,
      spaceName: null,
      retailerId: retailer.id!,
      retailerCompanyName: retailer.companyName!,
      leaseStatus: null,
      periodGroupUuid: this.selectedLeaseSpace!.groupUuid,
      periodGroupRanking: rank
    };

    this.selectedLeaseSpace!.leaseGridSpaces.push(lgs);
    this.selectedLeaseSpace = {...this.selectedLeaseSpace!};
    this.addObjectData(this.selectedLeaseSpace!.groupUuid, lgs)
  }

  removeLeaseGridSpaceFromGroup(retailer: Retailer): void {
    let index: number = this.selectedLeaseSpace!.leaseGridSpaces.findIndex(lgs => lgs.retailerId == retailer.id);
    if (index >= 0) {
      let leaseGridSpace: LeaseGridSpace = this.selectedLeaseSpace!.leaseGridSpaces[index];
      this.selectedLeaseSpace!.leaseGridSpaces.splice(index, 1);
      this.selectedLeaseSpace! = {...this.selectedLeaseSpace!}
      this.removeLeaseSpace(leaseGridSpace.uuid);
    }
  }

  addObjectData(groupUuid: string, lgs: LeaseGridSpace) {
    const data: TimelineObjectData = {
      uuid: lgs.uuid,
      displayName: lgs.retailerCompanyName!,
      groupUuid: groupUuid,
      parentUuid: lgs.leaseUuid,
      groupRanking: lgs.periodGroupRanking!
    }
    this.getTimelineObject(groupUuid).objectData.push(data);
  }

  removeObjectData(groupUuid: string, lgsUuid: string) {
    const object = this.getTimelineObject(groupUuid);
    object.objectData = object.objectData.filter(f => f.uuid != lgsUuid);
    this.selectedSpace = { ...this.selectedSpace! };
  }

  getTimelineElement(uuid: string): TimelineElement {
    let element: TimelineElement[] = [];
    this.timeline.timelineLists
      .forEach(list => list.listElements
        .forEach(el => {
          if (!!el && el.uuid == uuid) element.push(el);
        })
      )
    return element[0];
  }

  getTimelineObject(uuid: string): TimelineObject {
    let object: TimelineObject[] = [];
    this.timeline.timelineLists
      .forEach(list => list.listElements
        .forEach(el => el.elementObjects
          .forEach(obj => {
            if (!!obj && obj.uuid == uuid) object.push(obj);
          })
        )
    );
    return object[0];
  }

  getTimelineObjectData(groupUuid: string, lgsUuid: string) {
    return this.getTimelineObject(groupUuid).objectData.filter(f => f.uuid == lgsUuid)[0];
  }

  addNewLeaseSpaceGroup($event: string) {
    this.editTimeline = true;
    this.selectedLease = null;

    const newObject = this.getTimelineObject($event);
    this.selectSpace(newObject.elementId);

    const newLeaseSpaces: LeaseGridSpace[] = newObject.objectData.map((space, i) => {
      return {
        uuid: space.uuid,
        leaseUuid: space.parentUuid,
        occupancyType: this.selectedLease ? this.selectedLease.occupancyType! : OccupancyType.SINGLE,
        agreementType: this.selectedLease ? this.selectedLease.agreementType! : AgreementType.LEASE,
        districtSpaceId: Number(newObject.elementId),
        leaseStartDate: this.dateUtils.displayShortDate(newObject.startDate)!,
        leaseEndDate: this.dateUtils.displayShortDate(newObject.endDate!)!,
        availabilityStatus: null,
        spaceSize: null,
        spaceName: null,
        retailerId: null,
        retailerCompanyName: null,
        leaseStatus: null,
        periodGroupUuid: space.groupUuid,
        periodGroupRanking: space.groupRanking
      }
    });

    const newLeaseSpaceGroup: LeaseGridSpaceGroup = {
      groupUuid: newObject.uuid,
      spaceId: Number(newObject.elementId),
      spaceName: this.selectedSpace.districtSpace.spaceName,
      startDate: this.dateUtils.displayShortDate(newObject.startDate)!,
      endDate: this.dateUtils.displayShortDate(newObject.endDate!)!,
      leaseGridSpaces: newLeaseSpaces
    };

    this.selectedLeaseSpace = newLeaseSpaceGroup;
  }

  updateLeaseSpaceGroup($event: {listIndex: number, oldEl: string, newEl: string, objId: string}) {
    this.editTimeline = true;

    this.selectSpace($event.oldEl);
    this.selectLeaseSpace($event.objId);
    const updatedObject = this.getTimelineObject($event.objId);

    this.selectedLeaseSpace!.startDate = this.dateUtils.displayShortDate(updatedObject.startDate!)!;
    this.selectedLeaseSpace!.endDate = this.dateUtils.displayShortDate(updatedObject.endDate!)!;

    if ($event.oldEl !== $event.newEl) {
      const spaceList = $event.listIndex == 0 ? this.timelineSpaces : this.invalidTimelineSpaces;
      const oldSpace = spaceList.filter(f => f.districtSpace.id.toString() == $event.oldEl)[0];
      const newSpace = spaceList.filter(f => f.districtSpace.id.toString() == $event.newEl)[0];

      this.selectedLeaseSpace!.spaceId = Number($event.newEl);

      oldSpace.leaseGridSpaceGroups = oldSpace.leaseGridSpaceGroups.filter(f => f.spaceId.toString() != $event.objId);
      newSpace.leaseGridSpaceGroups.push(this.selectedLeaseSpace!);
    }
    this.selectSpace($event.newEl);
  }

  savePlannedLeases() {
    this.leasingService.savePlannedLeaseSpaceGroup(this.selectedLeaseSpace!).subscribe({
      next: value => {
        this.editTimeline = false;
        this.loadAllSpaces(false);
        this.timelineComponent?.initTimeline(false);
        this.timelineDrawerOpened = false;
        this.originalLeaseSpace = { ...this.selectedLeaseSpace! };

        this.originalLeaseSpace.leaseGridSpaces = this.selectedLeaseSpace!.leaseGridSpaces.map(m => {
          return {...m}
        });

        const timelineSpace = this.timelineSpaces[this.timelineSpaces.findIndex(i => i.districtSpace.id == this.selectedLeaseSpace!.spaceId)];
        const groupIndex = timelineSpace.leaseGridSpaceGroups.findIndex(i => i.groupUuid == this.selectedLeaseSpace?.groupUuid);
        timelineSpace.leaseGridSpaceGroups[groupIndex] = {...value};
      },
      error: err => console.log(err)
    })
  }

  cancelChanges() {
    if (this.selectedLeaseSpace) {
      const object = this.getTimelineObject(this.selectedLeaseSpace.groupUuid);
      if (this.originalLeaseSpace == null) {
        this.timelineComponent?.removeItemViaObject(object);
      } else {
        const dateChanged = this.dateUtils.displayShortDate(this.selectedLeaseSpace.startDate) != this.dateUtils.displayShortDate(this.originalLeaseSpace.startDate)
          || this.dateUtils.displayShortDate(this.selectedLeaseSpace.endDate) != this.dateUtils.displayShortDate(this.originalLeaseSpace.endDate);
        const spaceChanged = this.selectedLeaseSpace.spaceId != this.originalLeaseSpace.spaceId;
        const leaseSpacesChanged = this.leaseGridSpacesChanged(this.selectedLeaseSpace.leaseGridSpaces, this.originalLeaseSpace.leaseGridSpaces);

        const updatedObject = this.getTimelineObject(this.selectedLeaseSpace.groupUuid);

        if (dateChanged) {
          updatedObject.startDate = moment(this.originalLeaseSpace.startDate).toDate();
          updatedObject.endDate = moment(this.originalLeaseSpace.endDate).toDate();
        }

        const oldEl = this.getTimelineElement(this.selectedLeaseSpace.spaceId.toString());
        const newEl = this.getTimelineElement(this.originalLeaseSpace.spaceId.toString());

        if (spaceChanged) {
          updatedObject.elementId = newEl.uuid;
          oldEl.elementObjects = oldEl.elementObjects.filter(f => f.uuid != updatedObject.uuid);
          newEl.elementObjects.push(updatedObject);
        }

        if (leaseSpacesChanged) {
          const objectData: TimelineObjectData[] = this.originalLeaseSpace.leaseGridSpaces.map(lgs => {
            return {
              uuid: lgs.uuid,
              displayName: lgs.retailerCompanyName!,
              groupUuid: this.originalLeaseSpace!.groupUuid,
              parentUuid: lgs.leaseUuid,
              groupRanking: lgs.periodGroupRanking!
            }
          });
          updatedObject.objectData = [...objectData];
        }

        this.timelineComponent!.updateTimelineItemFromObject(updatedObject.uuid, updatedObject.listId, dateChanged, spaceChanged, oldEl.uuid, newEl.uuid);

        this.updateLeaseSpaceGroup({
          listIndex: 0,
          oldEl: this.originalLeaseSpace.spaceId.toString(),
          newEl: this.selectedLeaseSpace.spaceId.toString(),
          objId: this.selectedLeaseSpace.groupUuid
        });

        this.selectedLeaseSpace.leaseGridSpaces = this.originalLeaseSpace.leaseGridSpaces.map(m => {
          return {...m};
        })
        this.selectSpace(newEl.uuid);
        this.timelineComponent?.selectElement(newEl.uuid, newEl.listId, false);
      }
      this.editTimeline = false;
      this.selectedLeaseSpace = null;
      this.originalLeaseSpace = null;
    }
    this.timelineDrawerOpened = false;
  }

  openSpacesTab(event: any) {
    let payload: PageEventInterface = {
      componentToRegister: ManageSpacesComponent,
      pageName: 'Manage spaces',
      pageHeader: this.offering!.label,
      data: {
        selectedTabCode: 'spaces'
      },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'spaces',
      pageCode: 'spaces_all',
      offering: this.offering
    }
    this.tabManagementService.sendPageAddEvent(payload);
  }

  filterSpaces(listId: string): void {
    this.loadAllSpaces(true);
    this.editTimeline = false;
    this.timelineComponent?.initTimeline(true);
  }

  spaceChangesDirty(): boolean {
    if (this.selectedLeaseSpace == null) return false;
    if (this.originalLeaseSpace == null) return true;

    const spaceChange = this.selectedLeaseSpace?.spaceId != this.originalLeaseSpace!.spaceId;
    const dateChange = this.dateUtils.displayShortDate(this.selectedLeaseSpace?.startDate) != this.dateUtils.displayShortDate(this.originalLeaseSpace!.startDate)
      || this.dateUtils.displayShortDate(this.selectedLeaseSpace?.endDate) != this.dateUtils.displayShortDate(this.originalLeaseSpace!.endDate);

    if (spaceChange || dateChange) return true;

    const gridSpacesChange = this.leaseGridSpacesChanged(this.selectedLeaseSpace!.leaseGridSpaces, this.originalLeaseSpace!.leaseGridSpaces);

    return spaceChange || dateChange || gridSpacesChange;
  }

  leaseGridSpacesChanged(arr1: LeaseGridSpace[], arr2: LeaseGridSpace[]): boolean {
    const uuidSet1 = new Set(arr1.map(item => item.uuid));
    const uuidSet2 = new Set(arr2.map(item => item.uuid));

    const periodGroupRankMap1 = new Map(arr1.map(item => [item.uuid, item.periodGroupRanking]));
    const periodGroupRankMap2 = new Map(arr2.map(item => [item.uuid, item.periodGroupRanking]));

    return !(
      arr1.length === arr2.length &&
      uuidSet1.size === uuidSet2.size &&
      [...uuidSet1].every(uuid => periodGroupRankMap1.get(uuid) === periodGroupRankMap2.get(uuid))
    );
  }
}
