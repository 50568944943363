import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of, timeout} from "rxjs";
import {catchError} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";
import {JobRunConfig} from "../model/run-products.model";

@Injectable({
  providedIn: 'root'
})
export class RunProductsService {

  timeOutSecs = 300; // 5 minutes

  constructor(private http: HttpClient) {
  }

  public importAllProducts(): Observable<any> {
    let body: object | null = { correlationId: null };
    return this.callEndpoint( this.baseUrl + `/import`, body);
  }

  public importRetailerProducts(retailerId: number): Observable<any> {
    let body: object | null = { correlationId: null };
    return this.callEndpoint( this.baseUrl + `/${retailerId}/import`, body);
  }

  public importAllInventory(): Observable<any> {
    let body: object | null = { correlationId: null };
    return this.callEndpoint( this.baseUrl + `/import-inventory`, body);
  }

  public importRetailerInventory(retailerId: number): Observable<any> {
    let body: object | null = { correlationId: null };
    return this.callEndpoint( this.baseUrl + `/${retailerId}/import-inventory`, body);
  }

  public getJobConfig(suffix: string): Observable<JobRunConfig> {
    return this.http.get<JobRunConfig>(this.baseUrl + `/job-config/${suffix}`);
  }

  private callEndpoint(uri: string, body: object | null) {
    // @ts-ignore
    return this.http.post<any>(uri, body, {responseType: 'text'})
      .pipe(
        timeout( this.timeOutSecs * 1000),
        catchError(e => {
          console.log(e, 'timeout')
          return of(null)
        }));
  }

  get baseUrl(): string {
    return environment.apiProductsHost + '/console/retailer-products/processing'
  }
}
