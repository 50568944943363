export class JobRunConfig {
  loading: boolean = false;
  enabled: boolean = false;
  jobStatus: string | null = null;
  lockedOn: string | null = null;
  maxTimeout: string | null = null;
  schedulerConfig: string | null = null;
  id: string | null = null;
  name: string | null = null;
  status: string | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export const productJobs = [
  {
    name: 'Import All Products',
    id: 'products_job_import_products'
  },
  {
    name: 'Import All Product Inventory',
    id: 'products_job_import_product_inventory'
  },
  {
    name: 'Import Retailer Products',
    id: 'products_job_import_retailer_products'
  },
  {
    name: 'Import Retailer Product Inventory',
    id: 'products_job_import_retailer_product_inventory'
  },
];
