import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {User} from '../../../core/model/user.model';
import {CurrentLandlordModel} from '../../../core/model/landlord.model';
import {LandlordPropertyModel} from '../../../core/model/landlordProperty.model';
import {LandlordsService} from '../../../core/services/landlords.service';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl, FormGroup} from '@angular/forms';
import {BasePageComponent} from '../../../core/components/page-content/base-page.component';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent extends BasePageComponent implements OnInit {

  loggedInUser: User | null;
  currentLandlord: CurrentLandlordModel | null = null;
  properties: LandlordPropertyModel[] = [];
  filterForm: FormGroup;
  /*dataSource = new GenericDatasource<{ content: UserContacts }>(this.globalSettingsService);*/

  @ViewChild('paginator') paginator!: MatPaginator;


  constructor(injector: Injector,
              private contextService: CurrentContextService,
              private landlordService: LandlordsService,
              /*private globalSettingsService: GlobalSettingsService*/) {
    super(injector);
    this.loggedInUser = contextService.getCurrentUser();
    contextService.getCurrentWebClient()
      .then((landlord: CurrentLandlordModel | null) => {
        this.currentLandlord = landlord;
        landlordService.getPropertiesForLandlord(landlord!.uuid)
          .then((propertyList: LandlordPropertyModel[]) => {
            this.properties = propertyList;
          })
      });
    this.filterForm = new FormGroup({
      eventType: new FormControl(null),
      eventId: new FormControl(null)
    })
  }

  ngOnInit() {
    /*    this.dataSource = new GenericDatasource<{ content: UserContacts }>(this.globalSettingsService);
        this.dataSource.loadData('', {size: '10', page: '0', sort: 'createDate,desc'}, [{
          name: '',
          val: ''
        }]);*/
  }

  /* loadEvents() {
     const filters = [
       { name: 'eventType', val: { id: this.filterForm.get('eventId')?.value, code: this.filterForm.get('eventType')?.value} },
     ]
     const page = {size: this.paginator.pageSize.toString(), page: this.paginator.page.toString(), sort: 'createDate,desc'};
     this.dataSource.loadData('', page, filters);
   }*/
}
