<div fxLayout="column">

  <app-page-header (tabsChangeEvent)="changeTabs($event)"
                   [description]="'This is your lease schedule summarising the lease terms of the retailers lease categorised according to their current lease status.'"
                   [isTabbed]="true"
                   [section]="'Lease View'"
                   [selectedTab]="getInitialTab()"
                   [tabs]="tabs"
                   [title]="'View specific lease information'"
  ></app-page-header>

  <div fxLayout="column" class="page-padding page-content">
    <app-lease-details [id]="leaseId" [offering]="offering!" [reload]="reloadSubject.asObservable()" *ngIf="leaseId" (reloadPos)="reloadPosSettings()"></app-lease-details>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <div *ngIf="activeTab == 'lease_details'" fxLayout="column">
      <app-lease-sub-leases [id]="leaseId" [offering]="offering!" (reloadLease)="reloadLeaseDetails()"
                            *ngIf="leaseId && isHeadLease">
      </app-lease-sub-leases>

      <mat-divider fxFlexOffset="30px" *ngIf="leaseId && isHeadLease"></mat-divider>

      <app-lease-rates-and-charges [id]="leaseId" *ngIf="leaseId && !isHeadLease" (reloadLease)="reloadLeaseDetails()"></app-lease-rates-and-charges>

      <mat-divider fxFlexOffset="30px" *ngIf="leaseId && !isHeadLease"></mat-divider>

      <app-lease-spaces [id]="leaseId" [offering]="offering!" (reloadLease)="reloadLeaseDetails()" *ngIf="leaseId"></app-lease-spaces>

      <mat-divider fxFlexOffset="30px"></mat-divider>

      <app-lease-documents [id]="leaseId" *ngIf="leaseId"></app-lease-documents>

      <mat-divider fxFlexOffset="30px"></mat-divider>

      <app-lease-pos-settings #posSettingsComponent [reload]="reloadPosSubject.asObservable()"
        (reloadLease)="reloadLeaseDetails()" *ngIf="leaseId" [id]="leaseId"
        [offering]="offering!"></app-lease-pos-settings>
    </div>

    <div *ngIf="activeTab == 'lease_turnover'" fxLayout="column">
      <app-lease-turnover [id]="leaseId" [offeringId]="offering!.offeringUuid"></app-lease-turnover>
    </div>

  </div>

</div>
