import {SelectionModel} from "@angular/cdk/collections";
import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {PageEventInterface} from "../../../../core/interfaces/page-event.interface";
import {TabManagementService} from "../../../../core/services/tab-management.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {Retailer} from "../../../leads/retailers/retailers.model";
import {CreateALeaseDialogComponent} from "../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {LeaseCreateMethod} from "../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {LeaseViewComponent} from "../../lease-view/lease-view.component";
import {AgreementType, LeaseStatus, LeaseSummary, OccupancyType} from "../../leasing.model";
import {LeasingService} from "../../leasing.service";

@Component({
  selector: 'app-lease-schedule-list',
  templateUrl: './current-leases-list.component.html',
  styleUrls: ['./current-leases-list.component.scss']
})
export class CurrentLeasesListComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'companyName', 'startDate', 'endDate', 'spaceName', 'size', 'rentalType', 'monthlyRentalFee', 'expiresInDays'];

  dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
  selection = new SelectionModel<Retailer>(true, []);

  dateRanges: number[] = [30, 60, 90];
  expiresIn: number | null = null;

  leaseStatuses = [LeaseStatus.APPROVED, LeaseStatus.ACTIVE, LeaseStatus.EXPIRED];
  occupancyTypes = [OccupancyType.SINGLE, OccupancyType.SHARED];

  filterForm: FormGroup;
  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  constructor(injector: Injector, public utils: UtilsService, public dateUtils: DateUtilsService, private paginatorService: PaginatorService, private tabService: TabManagementService, private leasingService: LeasingService, private matDialog: MatDialog) {
    super(injector);

    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      spaceNames: new FormControl(null),
      occupancyType: new FormControl([])
    });

    this.filterForm.get('companyName')!.valueChanges.subscribe((formValue) => {
      if (formValue.length >= 3) {
        this.loadLeases();
      } else if (formValue.length == 0) {
        this.loadLeases();
      }
    });

    this.filterForm.get('spaceNames')!.valueChanges.subscribe((formValue) => {
      if (formValue.length >= 3) {
        this.loadLeases();
      } else if (formValue.length == 0) {
        this.loadLeases();
      }
    });

    this.filterForm.get('occupancyType')!.valueChanges.subscribe(() => {
      this.loadLeases();
    });
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);
    this.dataSource.loadData('/current-leases', {size: '10', page: '0', sort: 'leaseStartDate,desc'},
      [
        {name: 'statusList', val: this.leaseStatuses},
        {name: 'typeList', val: this.occupancyTypes},
        {name: 'agreementType', val: AgreementType.LEASE}
      ]);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();
  }

  loadLeases() {
    const filters = [
      {name: 'expiresInDays', val: this.expiresIn},
      {name: 'statusList', val: this.leaseStatuses},
      {name: 'leaseStartDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'leaseEndDate', val: this.dateUtils.displayShortDate(this.dateRange.end)},
      {name: 'typeList', val: this.filterForm.get('occupancyType')?.value.length === 0 ? this.occupancyTypes : this.filterForm.get('occupancyType')?.value },
      {name: 'agreementType', val: AgreementType.LEASE}
    ];
    let spaceNames = this.filterForm.get('spaceNames')!.value;
    let companyName = this.filterForm.get('companyName')!.value;

    if (spaceNames !== null && spaceNames !== undefined && spaceNames.length >= 3) {
      filters.push({name: 'spaceNames', val: spaceNames});
    }

    if (companyName !== null && companyName !== undefined && companyName.length >= 3) {
      filters.push({name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value})
    }

    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'leaseStartDate,desc'
    }
    this.dataSource.loadData('/current-leases', page, filters);
  }

  openLeaseViewTab(id: string, occupancyType: OccupancyType, agreementType: AgreementType) {
    let payload: PageEventInterface = {
      componentToRegister: LeaseViewComponent,
      pageName: 'Lease ' + this.utils.displayUuid(id),
      pageHeader: this.offering!.label,
      data: { id, occupancyType, agreementType },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering!.offeringUuid,
      sectionCode: 'leasing',
      pageCode: 'current-leases',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  createLease() {
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: LeaseCreateMethod.NEW_LEASE,
        lease: null,
        header: 'Create a new lease',
        offering: this.offering
      }
    })
  }

  displayRentalType(type: string | null) {
    return this.leasingService.getRentalType(type);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadLeases();
  }

  setExpiresIn(event: any) {
    let toggle = event.source;
    if (toggle) {
      const group = toggle.buttonToggleGroup;
      if (event.value.some((item: boolean) => item == toggle.value)) {
        group.value = [toggle.value];
        this.expiresIn = group.value[0];
      } else {
        this.expiresIn = null;
      }
      this.loadLeases();
    }
  }
}
