import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-confirm-remove',
  templateUrl: './confirm-remove.component.html',
  styleUrls: ['./confirm-remove.component.scss']
})
export class ConfirmRemoveComponent {
  @Input() message: string = 'Do you want to remove the selected item(s)?';
  @Input() buttonLabel1: string = 'Yes';
  @Input() buttonLabel2: string = 'No';
  @Input() buttonDisabled1: boolean = false;
  @Input() buttonDisabled2: boolean = false;

  @Output() confirmRemove = new EventEmitter<boolean>();

  loading$ = this.loader.loading$;

  constructor(private loader: LoadingService) {
  }

  removeItems(confirm: boolean) {
    this.confirmRemove.emit(confirm);
  }

}
