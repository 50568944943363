import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";
import {MatStepper} from "@angular/material/stepper";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {ApplicationForm, ApplicationFormImp} from "../../../../core/model/retailer/application-form.model";
import {SettingsApplicationFormsService} from '../../../settings/service/settings-application-forms.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss']
})
export class FormStepperComponent extends BasePageComponent implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;

  selectedIndex: number = 0;

  steps = [
    {label: 'Name form', index: 0},
    {label: 'Add questions', index: 1},
    {label: 'Configure smart form', index: 2},
  ];

  formTemplate!: ApplicationForm;

  edit: boolean;

  constructor(injector: Injector,
              public dateUtils: DateUtilsService,
              private applicationFormService: SettingsApplicationFormsService) {
    super(injector);

    if (this.data) {
      this.formTemplate = this.data;
      this.edit = true;
    } else {
      this.formTemplate = new ApplicationFormImp({});
      this.edit = false;
    }

    if (this.formTemplate.uuid) {
      lastValueFrom(this.applicationFormService.getOneTemplate(this.formTemplate.uuid))
        .then((res: ApplicationForm) => {
          this.formTemplate = res;
          this.edit = true;
        });
    }
  }

  ngOnInit() {

  }

  setIndex(index: number) {
    switch (index) {
      case 2:
        if (this.formTemplate.formName) this.selectedIndex = index;
        break;
      case 1:
        if (this.formTemplate.formName) this.selectedIndex = index;
        break;
      default:
        this.selectedIndex = index;
    }
  }

  advanceForm() {
    this.setIndex(this.selectedIndex + 1);
  }


  formTemplateChanges(form: ApplicationForm) {
    this.formTemplate = form;
  }

  closeTab(): void {
    //  todo
  }
}
