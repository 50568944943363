<div [ngStyle]="{'max-width': sideNavOpenStatus ? 'calc(100vw - 600px)' : 'calc(100vw - 475px)'}"
      fxLayout="row">
  <mat-tab-group #tabGroup class="nav-page-tab-navigator" [(selectedIndex)]="selectedTab"
                 (selectedTabChange)="onTabChange($event)"
                 animationDuration="0ms" [disableRipple]="true">

    <mat-tab *ngFor="let page of pages; let i = index">
      <ng-template mat-tab-label>
        <div [ngClass]="selectedTab === i ? 'tab-label-active' : 'tab-label'" fxLayout="row"
             fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutAlign="center start">
            <span class="tab-label-page truncate-text">{{page.name}}</span>
            <span class="tab-label-heading truncate-text">{{page.heading}}</span>
          </div>
          <button mat-icon-button class="tab-label-button" (click)="closeTab(page)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="pages.length > 2">
      <ng-template mat-tab-label>
        <mat-label class="tab-navbar-close-all" (click)="closeAllTabs()">Close all</mat-label>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<!--
<div [ngStyle]="{'max-width': sidenav.opened ? 'calc(100vw - 700px)' : 'calc(100vw - 475px)'}">
          <mat-tab-group class="nav-page-tab" [(selectedIndex)]="selectedTab" (selectedIndexChange)="logMe()" disableRipple>

            <mat-tab *ngFor="let page of pageTabs; let i = index">
              <ng-template mat-tab-label>
                <div [ngClass]="selectedTab === i ? 'tab-label-active' : 'tab-label'" fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxLayout="column" fxLayoutAlign="center start">
                    <span class="tab-label-page">{{page.page}}</span>
                    <span class="tab-label-heading">{{page.heading}}</span>
                  </div>
                  <button mat-icon-button class="tab-label-button">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </ng-template>
            </mat-tab>

          </mat-tab-group>
        </div>

      </div>
-->

