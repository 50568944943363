<app-pre-auth-toolbar></app-pre-auth-toolbar>

<div class="p-3 full-width full-screen-height flex-column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width reset-password-header-div" fxLayout="column" fxLayoutGap="20px"  fxLayoutAlign="center center">
    <h1 class="title-large">Forgot your password?</h1>
    <h2 class="subtitle-regular">Please enter your registered email address to reset your password.</h2>
  </div>

  <div class="p-2 full-width flex-row" fxLayoutAlign="center center">
    <form class="reset-password-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()"
          fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" hideRequiredMarker>
        <mat-label>Email</mat-label>
        <input matInput
               type="email"
               placeholder="Email address"
               formControlName="email"
               pattern="[a-zA-Z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-z]{2,4}$"
        />
        <mat-error class="link-error" *ngIf="forgotPasswordForm.get('email')!.hasError('required')">Email is required</mat-error>
        <mat-error class="link-error" *ngIf="forgotPasswordForm.get('email')!.hasError('pattern')">Email is not valid</mat-error>
      </mat-form-field>

      <button class="button-primary" mat-button type="submit" fxFlexOffset="90px" [disabled]="loader.loading$ | async" style="width: 200px;">
        <div class="button-text">Reset Password</div>
      </button>

    </form>
  </div>

  <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
</div>
