import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {lastValueFrom} from 'rxjs';
import {
  ApplicationForm,
  Category,
  Question,
  QuestionGroup,
  QuestionTemplate
} from '../../../../core/model/retailer/application-form.model';
import {PageOffering} from "../../../../core/model/side-nav.model";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {SettingsApplicationFormsService} from '../../../settings/service/settings-application-forms.service';
import {ControlType, QuestionItem} from '../add-questions/question-components.model';
import {FormBuilderService} from '../form-builder.service';

@Component({
  selector: 'app-smart-form-configure',
  templateUrl: './smart-form-configure.component.html',
  styleUrls: ['./smart-form-configure.component.scss']
})
export class SmartFormConfigureComponent implements OnInit {

  @Output() nextStep = new EventEmitter<void>();
  @Input() edit!: boolean;
  @Output() formChange = new EventEmitter<ApplicationForm>();
  @Input() formTemplate!: ApplicationForm;
  @Input() offering!: PageOffering;
  @Output() publishForm = new EventEmitter<void>();
  readonly ControlType = ControlType;


  questionItems: Question[] = [];
  selectedItem: Question | null = null;
  controlTypesFilterList = [
    ControlType.FILE,
    ControlType.TEXT,
    ControlType.LOCATION,
    ControlType.DATE,
    ControlType.HEADING,
    ControlType.HEADING_TEXT,
    ControlType.INFO_FILE
  ];
  selectedQuestionGroup: QuestionGroup | null = null;


  constructor(private formBuilderService: FormBuilderService,
              private applicationFormsService: SettingsApplicationFormsService,
              private router: Router,
              private context: CurrentContextService) {
  }

  ngOnInit(): void {
    this.formTemplate.categories.forEach((category: Category) => {
      category.questionGroups.forEach(questionGroup => {
        if (questionGroup.questions.length == 1) {
          if (questionGroup.importanceRating == null && this.canBeScored(questionGroup.questions[0])) {
            questionGroup.importanceRating = 0;
          }
          if (!this.canBeScored(questionGroup.questions[0])) questionGroup.importanceRating = null;
          this.questionItems.push(...questionGroup.questions);
        }
      });
    });
  }

  public saveForm() {
    this.formBuilderService.updateFormTemplate(this.formTemplate)
      .subscribe({
        next: value => {
          this.formTemplate = value;
          this.formChange.emit(this.formTemplate);
          this.edit = true;
        },
        error: err => console.log(err)
      });
    this.formChange.emit(this.formTemplate);
  }

  public previewForm() {
    const locationOffering = this.context.getCurrentLocation()?.locationOfferings?.find(offering => offering.offeringUuid === this.offering?.offeringUuid)!;
    const url = this.router.serializeUrl(this.router.createUrlTree([`/preview-apply/${this.formTemplate.formCode}`],
      {
        queryParams: {
          locationCode: locationOffering.locationCode!,
          offeringCode: locationOffering.offeringCode!,
          embedKey: locationOffering.embedKey!
        }
      }));
    window.open(url, '_blank');
  }

  selectQuestionItem(question: Question): void {
    if (this.canBeScored(question)) {
      this.selectedItem = question;
      this.selectedQuestionGroup = this.getQuestionGroup(question.questionGroupUuid);
    }
  }

  getQuestionGroup(groupUuid: string): QuestionGroup | null {
    let questionGroups: QuestionGroup[] = this.formTemplate.categories
      .flatMap(category => category.questionGroups)
      .filter(groups => groups.uuid === groupUuid);
    if (questionGroups.length > 0) {
      return questionGroups[0];
    }
    return null;
  }

  canBeScored(question: Question): Boolean {
    return !this.controlTypesFilterList.includes(question.questionTemplate.controlType);
  }

  displayCantBeScoredMessage(question: Question): string {
    const template = this.formBuilderService.getControlTemplate(question.questionTemplate);
    return `${template?.label} questions can't be scored`;
  }

  publishFormTemplate(): void {
    this.formBuilderService.updateFormTemplate(this.formTemplate)
      .subscribe({
        next: value => {
          this.formTemplate = value;
          this.formChange.emit(this.formTemplate);
          lastValueFrom(this.applicationFormsService.setOneFormTemplateAsActive(this.formTemplate.uuid!))
            .then((res: ApplicationForm[]) => {
              let applicationForms: ApplicationForm[] = res.filter(form => {
                return form.uuid === this.formTemplate.uuid
              });
              if (applicationForms.length == 1) {
                this.formTemplate = applicationForms[0];
              }
            })
            .catch((error: string) => {
              console.log(error);
            });
        },
        error: err => console.log(err),
      });

  }

  updateQuestionGroup($event: QuestionGroup): void {
    this.formTemplate.categories
      .flatMap(category => category.questionGroups)
      .filter(groups => groups.uuid === $event.uuid)
      .map(() => $event);
  }

  getQuestionTemplate(question: Question): QuestionTemplate {
    return question.questionTemplate;
  }

  toQuestionItem(question: Question): QuestionItem {
    return {
      uuid: question.questionGroupUuid,
      template: this.formBuilderService.getControlTemplate(question.questionTemplate)!,
      questionConfig: this.getQuestionGroup(question.questionGroupUuid),
      temp: false
    };
  }

  updateGroupRating(value: number): void {
    if (this.selectedQuestionGroup) {
      this.selectedQuestionGroup!.importanceRating = value;
    }
  }
}
