import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GenericService, PagedContent} from "../../../shared/services/generic.service";
import {
  CardReader,
  DistrictGridSpacesForTerm,
  LeaseGridSpace,
  LeasePricingPeriod,
  LeaseStatus,
  LeaseSummary,
  LeaseSupplierState,
  PaymentConfigRequest,
  PaymentMethod,
  SpaceSelection
} from "../leasing.model";

@Injectable({
  providedIn: 'root'
})
export class LeaseViewService extends GenericService<LeaseSummary> {
  constructor(http: HttpClient) {
    super(http, environment.apiLeaseHost + `/console/manage-lease`);
  }

  getLeaseSpaces(leaseId: string): Observable<LeaseGridSpace[]> {
    return this.http.get<LeaseGridSpace[]>(this.getBaseUrl + `/${leaseId}/spaces`);
  }

  saveLeaseSpaces(request: SpaceSelection, leaseId: string): Observable<LeaseSummary> {
    return this.http.post<LeaseSummary>(this.getBaseUrl + `/${leaseId}/spaces`, request);
  }

  removeLeaseSpaces(request: SpaceSelection, leaseId: string, spaceUuids: string[]) {
    let requestParams = new HttpParams()
      .set('retailerId', request.retailerId!)
      .set('selectedFloorSpaceNames', request.selectedFloorSpaceNames.toString())
      .set('districtUuid', request.districtUuid!)
      .set('leaseId', request.leaseUuid!)
      .set('spaceUuids', spaceUuids.toString());

    return this.http.delete<void>(this.getBaseUrl + `/${leaseId}/spaces`, {params: requestParams});
  }

  createPlannedLease(lease: SpaceSelection): Observable<LeaseSummary> {
    return this.http.post<LeaseSummary>(this.getBaseUrl, lease);
  }

  getDistrictSpacesForDateRange(startDate: string, endDate: string | null): Observable<DistrictGridSpacesForTerm> {
    let requestParams = new HttpParams()
      .set('startDate', startDate);

    if (endDate) {
      requestParams = requestParams.append('endDate', endDate);
    }

    return this.http.get<DistrictGridSpacesForTerm>(this.getBaseUrl + `/grid-spaces`, {params: requestParams});
  }

  updateLeaseStatus(retailerId: number, leaseId: string, newStatus: LeaseStatus): Observable<LeaseSummary> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<LeaseSummary>(this.getBaseUrl + `/${leaseId}/update-status`, `"${newStatus}"`, options);
  }

  removeLease(retailerId: number, leaseId: string) {
    return this.http.delete<void>(this.getBaseUrl + `/${leaseId}`);
  }

  getLeasePaymentConfigHistory(leaseId: string, page: { size: string; page: string; sort: string }, params?: {
    val: string;
    name: string
  }[]): Observable<PagedContent<PaymentConfigRequest[]>> {
    let requestParams = new HttpParams()
      .set('sort', page.sort)
      .set('size', page.size)
      .set('page', page.page);

    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {
        requestParams = requestParams.append(ea.name, ea.val)
      });
    }
    return this.http.get<PagedContent<PaymentConfigRequest[]>>(this.getBaseUrl + `/${leaseId}/rates`);
  }

  getLeaseCurrentPaymentConfig(leaseUuid: string): Observable<PaymentConfigRequest> {
    return this.http.get<PaymentConfigRequest>(this.getBaseUrl + `/${leaseUuid}/rates/current`);
  }

  getPaymentMethods(leaseId: string): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(this.getBaseUrl + `/${leaseId}/rates/payment-methods`)
  }

  savePaymentConfig(request: PaymentConfigRequest, leaseId: string): Observable<PaymentConfigRequest> {
    return this.http.post<PaymentConfigRequest>(this.getBaseUrl + `/${leaseId}/rates`, request);
  }

  getLeaseSuppliers(leaseId: string, paymentConfigId: string) {
    return this.http.get<LeaseSupplierState>(this.getBaseUrl + `/${leaseId}/rates/${paymentConfigId}/suppliers`)
  }

  updatePaymentConfigStatus(paymentConfigUuid: string, leaseId: string, status: string): Observable<PaymentConfigRequest> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<PaymentConfigRequest>(this.getBaseUrl + `/${leaseId}/rates/status/${paymentConfigUuid}`, `"${status}"`, options);
  }

  getAssignedLeaseCardReaders(leaseId: string): Observable<CardReader[]> {
    return this.http.get<CardReader[]>(this.getBaseUrl + `/${leaseId}/card-readers/assigned`)
  }

  getAvailableLeaseCardReaders(leaseId: string, startDate: string, endDate: string): Observable<CardReader[]> {
    let requestParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);
    return this.http.get<CardReader[]>(this.getBaseUrl + `/${leaseId}/card-readers/available`, {params: requestParams})
  }

  assignLeaseCardReader(leaseId: string, request: CardReader): Observable<CardReader> {
    return this.http.post<CardReader>(this.getBaseUrl + `/${leaseId}/card-readers`, request)
  }

  removeLeaseCardReader(leaseId: string, cardReaderId: any): Observable<void> {
    return this.http.delete<void>(this.getBaseUrl + `/${leaseId}/card-readers/${cardReaderId}`)
  }

  requestBillingDetailsUpdate(retailerId: number): Observable<void> {
    return this.http.post<void>(environment.apiRetailersHost + `/console/manage-retailer/${retailerId}/billing-info/request`, null)
  }

  updateBillingDetailsRequested(leaseId: string): Observable<PaymentConfigRequest> {
    return this.http.post<PaymentConfigRequest>(this.getBaseUrl + `/${leaseId}/rates/billing-details`, null);
  }

  get getBaseUrl() {
    return environment.apiLeaseHost + '/console/manage-lease'
  }

  getRentalType(typeCode: string | null): string {
    if (typeCode === 'TURNOVER_PERCENTAGE') return 'Percentage rental';
    if (typeCode === 'PRICING_MODEL') return 'Fixed rental';
    return '-';
  }

  getLeaseCurrentPricingPeriod(leaseUuid: string): Observable<LeasePricingPeriod> {
    return this.http.get<LeasePricingPeriod>(this.getBaseUrl + `/${leaseUuid}/pricing/current`)
  }

  savePricingChanges(leasePricingPeriod: LeasePricingPeriod, leaseUuid: string): Observable<LeasePricingPeriod> {
    return this.http.post<LeasePricingPeriod>(this.getBaseUrl + `/${leaseUuid}/pricing`, leasePricingPeriod);
  }

  removePaymentConfigFromLease(paymentConfigUuid: string, leaseUuid: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/${leaseUuid}/rates/${paymentConfigUuid}`);
  }
}
