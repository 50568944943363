import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {MatDialog} from "@angular/material/dialog";
import {RequestPosDialogComponent} from "../request-pos-dialog/request-pos-dialog.component";
import {TabManagementService} from "../../../core/services/tab-management.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {PosHelpCenterComponent} from "../pos-help-center/pos-help-center.component";
import {LeaseViewService} from "../../leasing/lease-view/lease-view.service";

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']
})
export class CustomerSupportComponent extends BasePageComponent {

  constructor(injector: Injector,
              private matDialog: MatDialog,
              private tabsService: TabManagementService,
              private utils: UtilsService,
              private leaseViewService: LeaseViewService,
              private utilsService: UtilsService) {
    super(injector);
  }

  requestPOSAccount(cancel: boolean) {
    this.matDialog.open(RequestPosDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        cancel: cancel,
        header: cancel ? 'Cancel a POS account' : 'Request a POS account',
        properties: this.data.properties
      }
    }).afterClosed().subscribe((res: {save: boolean, leaseId: string}) => {
      if (res.save && res.leaseId) {
        this.leaseViewService.getLeaseCurrentPaymentConfig(res.leaseId).subscribe({
          next: value => {
            this.updatePaymentConfigStatus(value.uuid!, cancel ? 'DEACTIVATION_REQUESTED' : 'ACTIVATION_REQUESTED', res.leaseId)
          }, error: err => {
            console.log(err);
          }
        });
      }
    })
  }

  goToHelpCentre() {
    this.tabsService.sendPageAddEvent({
        componentToRegister: PosHelpCenterComponent,
        pageName: 'Point-of-sale Help Centre',
        pageHeader: 'Customer Care',
        id: this.utils.generateUuidWithPrefix('pos_help_center'),
        data: {},
      }
    );
  }

  updatePaymentConfigStatus(paymentConfigUuid: string, status: string, leaseId: string) {
    this.leaseViewService.updatePaymentConfigStatus(paymentConfigUuid, leaseId, status).subscribe({
      next: () => {},
      error: err => console.log(err)
    })
  }

  logSupportRequest() {
    this.utilsService.openLinkInNewTab('https://sokodistrict.atlassian.net/servicedesk/customer/portal/1');
  }

  logAccountsRequest() {
    this.utilsService.openLinkInNewTab('https://sokodistrict.atlassian.net/servicedesk/customer/portal/1/group/1/create/3');
  }
}
