import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ChartConfiguration, ChartData} from "chart.js";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {chartColors} from "../../../../dashboards/chart-options/chart-colors.model";
import {defaultChartOptions} from "../../../../dashboards/chart-options/chart-options.model";
import {ChartOptionsService} from "../../../../dashboards/chart-options/chart-options.service";
import {DailyTransactionsSummary} from "../../transactions.model";
import {TransactionsService} from "../../transactions.service";

@Component({
  selector: 'app-daily-transaction-summary',
  templateUrl: './daily-transaction-summary.component.html',
  styleUrls: ['./daily-transaction-summary.component.scss']
})
export class DailyTransactionSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new MatTableDataSource<DailyTransactionsSummary>();
  displayedColumns = ['retailerCompanyName', 'transactionDate', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'chart'];

  chartOptions: ChartConfiguration<'line'>['options'];
  chartData: ChartData<'line'> = {labels: [], datasets: [{data: []}]}

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);

  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 0, TimeFrames.MONTH, 'This month')
  ]

  constructor(private transactionsService: TransactionsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private chartOptionsService: ChartOptionsService) {
    this.filterForm = new FormGroup({
      companyName: new FormControl(null)
    })

    let chartOptions: ChartConfiguration<"line">["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setDateTooltips(chartOptions!, [], ['Sales'], [])!;
    this.chartOptions = chartOptions;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;

    this.filterForm.valueChanges.subscribe(() => {
      this.loadTransactionsSummary();
      this.loadChartData()
    });
  }

  loadTransactionsSummary() {
    const filters = [
      {name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]

    this.transactionsService.getAllForDateRange(filters).subscribe({
      next: data => {
        this.dataSource.data = data;
        this.checkTableRows();
      },
      error: err => {
        console.log(err);
      }
    });
  }

  loadChartData() {
    const filters = [
      {name: 'retailerCompanyName', val: this.filterForm.get('companyName')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    this.transactionsService.getDailyTransactionChartData(filters).subscribe({
      next: value => {
        this.chartData = {
          labels: value.chartData.map(m => this.dateUtils.displayDateAndMonth(m.dateLabel)),
          datasets: [
            {
              data: value.chartData.map(m => m.data),
              label: 'Sales',
              backgroundColor: chartColors.BLUE,
              borderColor: chartColors.BLUE,
              pointBackgroundColor: chartColors.BLUE,
              pointBorderColor: chartColors.BLUE,
              pointRadius: 0,
              fill: 'origin'
            }
          ]
        }
      }
    })
  }

  checkTableRows() {
    if (this.dataSource.data.length % 10 != 0) {
      const fullPages = Math.floor(this.dataSource.data.length / 10);
      const rowsToAdd = 10 - (this.dataSource.data.length - (fullPages * 10))
      for (let i = 0; i < rowsToAdd; i++) this.dataSource.data.push(Object.create(null));
    }
    this.dataSource.paginator = this.paginator;
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.dateRange = event.dateRange;
      this.loadTransactionsSummary();
      this.loadChartData();
    }
  }

  get dataSourceLength() {
    return this.dataSource.data.filter(f => f.leaseUuid).length;
  }
}
