import {Auditable} from './auditable.model';

export class User extends Auditable {
  public disabled!: boolean;
  public email!: string;
  public firstname!: string;
  public lastLogin?: string;
  public lastname!: string;
  public locked?: boolean;
  public loginAttempts?: number;
  public secret?: string;
  public override verified!: boolean;
  public profileImage?: string;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class RegisterUserRequest {
  public email!: string;
  public firstname!: string;
  public lastname!: string;
  public secret?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class VerifyOTPRequest {
  public id!: number;
  public pin!: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class UserUpdateRequest {
  public id!: number;
  public firstname!: string;
  public lastname!: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
