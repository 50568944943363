export class Retailer {
  uuid: string = '';
  companyName: string = '';
  retailer: string = '';
  website: string = '';
  socialLink: string = '';
  email: string = '';
  curationStatus: string = '';
}

export enum CurationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export const retailers: Retailer[] = [
  {
    uuid: 'bjwvrwuvcdskevuyveuv',
    companyName: 'The Company Name 1',
    retailer: 'John Smith 1',
    website: 'www.google.com',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'APPROVED'
  },
  {
    uuid: 'lwfwiuuyvbwjhebde',
    companyName: 'The Company Name 2',
    retailer: 'John Smith 2',
    website: 'www.websitecompany.co.za',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'APPROVED'
  },
  {
    uuid: '47ty4hen437y3hwfyeuw',
    companyName: 'The Company Name 3',
    retailer: 'John Smith',
    website: 'www.websitecompany.co.za',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'REJECTED'
  },
  {
    uuid: '57ytghujnr45ythgr',
    companyName: 'The Company Name 4',
    retailer: 'John Smith',
    website: 'www.websitecompany.co.za',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'PENDING'
  },
  {
    uuid: '67ujhgr56yhgfer456yhgf',
    companyName: 'The Company Name 5',
    retailer: 'John Smith',
    website: 'www.websitecompany.co.za',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'APPROVED'
  },
  {
    uuid: '67ujhgr56yhgfer456yhgf',
    companyName: 'The Company Name 6',
    retailer: 'John Smith',
    website: 'www.websitecompany.co.za',
    socialLink: 'https://.instagram.com/companyname98463',
    email: 'name@websitename.com',
    curationStatus: 'APPROVED'
  }
]

export interface Role {
  id: number,
  name: string,
  description: string,
}

export interface UserGrant {
  id: number,
  roleId: number,
  userId: number,
  userEmail: string
}

export const retailerRoles: Role[] = [
  {
    id: 1,
    name: 'Portal User',
    description: 'Can log in to the public portal',
  },
  {
    id: 2,
    name: 'Owner',
    description: 'Can perform any action',
  },
  {
    id: 3,
    name: 'Administrator',
    description: 'Can perform administrative functions but does not have access too operational features',
  },
  {
    id: 4,
    name: 'Shopkeeper',
    description: 'Can perform product sales, update stock info and process product payments',
  },
  {
    id: 5,
    name: 'Co-Owner',
    description: 'Access to everything and can add other people',
  },
]

export const userGrants: UserGrant[] = [
  {
    id: 1,
    roleId: 1,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 2,
    roleId: 1,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 3,
    roleId: 5,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 4,
    roleId: 5,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 5,
    roleId: 2,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 1,
    roleId: 1,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 2,
    roleId: 1,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 3,
    roleId: 5,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 4,
    roleId: 5,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 5,
    roleId: 2,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 1,
    roleId: 1,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 2,
    roleId: 1,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 3,
    roleId: 5,
    userId: 17,
    userEmail: 'info@daniellefrylinck.com'
  },
  {
    id: 4,
    roleId: 5,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  },
  {
    id: 5,
    roleId: 2,
    userId: 58,
    userEmail: 'accounts@daniellefrylinck.com'
  }
]



