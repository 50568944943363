import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {MatIconRegistry} from "@angular/material/icon";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {DomSanitizer} from "@angular/platform-browser";
import {PageEventInterface} from "../../../../../core/interfaces/page-event.interface";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {TabManagementService} from "../../../../../core/services/tab-management.service";
import {
  CustomDateFilterComponent
} from "../../../../../shared/components/custom-date-filter/custom-date-filter.component";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {Retailer} from "../../../../leads/retailers/retailers.model";
import {OfferingSpaceStatistics} from "../../../../spaces/model/offering-spaces.interface";
import {ManageSpacesService} from "../../../../spaces/service/manage-spaces.service";
import {LeaseViewComponent} from "../../../lease-view/lease-view.component";
import {
  AgreementType,
  LeaseGridSpace,
  LeaseGridSpaceGroup,
  LeaseSummary,
  OccupancyType,
  OccupiedSpace
} from "../../../leasing.model";

@Component({
  selector: 'app-lease-space-info-panel',
  templateUrl: './lease-space-info-panel.component.html',
  styleUrls: ['./lease-space-info-panel.component.scss']
})
export class LeaseSpaceInfoPanelComponent implements OnInit, OnChanges {
  @ViewChild("dateRangeFilter") dateRangeComp!: CustomDateFilterComponent;

  @Input() editTimeline: boolean = false;
  @Input() offering?: PageOffering;

  @Input() selectedSpace!: OccupiedSpace;
  @Input() selectedLeaseSpaceGroup!: LeaseGridSpaceGroup | null;
  @Input() statSpaceStatistics: OfferingSpaceStatistics | null = null;
  @Input() leaseSummary: LeaseSummary | null = null;

  @Output() switchTab = new EventEmitter<string>();
  @Output() updateLeaseSpace = new EventEmitter<void>();
  @Output() removeLeaseSpace = new EventEmitter<string>();

  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  selectedTabIndex: number = 0;

  showingActions: boolean = false;
  showSaveAction: boolean = false;

  minDate = new Date();

  preferredRetailers: Retailer[] = [];

  //timeline drawer
  @Input() timelineDrawerOpen!: boolean;
  @Output() timelineDrawerOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private spaceService: ManageSpacesService,
              private tabManagementService: TabManagementService) {
    this.matIconRegistry.addSvgIcon('person_home', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/person_home.svg'));
    this.matIconRegistry.addSvgIcon('stacked_lines', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/spaces/stacked_lines.svg'));
    this.matIconRegistry.addSvgIcon('open_in_new', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/timeline/open_in_new.svg'));
    this.matIconRegistry.addSvgIcon('list_alt', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/timeline/list_alt.svg'));
    this.matIconRegistry.addSvgIcon('pending', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/timeline/pending.svg'));
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedLeaseSpaceGroup && this.dateRangeComp) {
      const spaceDates = new DateRange<Date | null>(
        new Date(this.selectedLeaseSpaceGroup.startDate),
        new Date(this.selectedLeaseSpaceGroup.endDate)
      );
      this.setRetailers();
      this.dateRangeComp.setCustomDate(spaceDates);
    }
  }

  switchTabs($event: MatTabChangeEvent): void {
    switch ($event.index) {
      case 0:
        this.switchTab.emit("timeline");
        break;
      case 1:
        this.switchTab.emit("lease_list");
        break;
      default:
        this.switchTab.emit("retailers");
        break;
    }
  }

  updateDateRange(event: CustomDateRangeChangeEventModel) {
    this.selectedLeaseSpaceGroup!.startDate = this.dateUtils.displayShortDate(event.dateRange.start)!;
    this.selectedLeaseSpaceGroup!.endDate = this.dateUtils.displayShortDate(event.dateRange.end)!;

    if (this.selectedLeaseSpaceGroup?.startDate && this.selectedLeaseSpaceGroup.endDate) {
      this.updateLeaseSpace.emit();
    }
  }

  setRetailers() {
    if (this.selectedLeaseSpaceGroup?.leaseGridSpaces) {
      const sorted = this.selectedLeaseSpaceGroup?.leaseGridSpaces
        .filter(f => f.retailerId)
        .sort((a, b) => a.periodGroupRanking! - b.periodGroupRanking!)!;
      this.preferredRetailers = sorted
        .map(m => new Retailer({id: m.retailerId, companyName: m.retailerCompanyName}));
    }
  }

  getLeaseSpaceFromRetailer(retailerId: number): LeaseGridSpace | null {
    const existing = this.selectedLeaseSpaceGroup!.leaseGridSpaces.filter(f => f.retailerId == retailerId);
    return existing.length > 0 ? existing[0] : null;
  }

  getSpaceAveragePrice(): any {
    return (this.statSpaceStatistics) ? this.utils.displayCompactNumber(this.statSpaceStatistics.avgRentalPricingPerSqmValue) : 0
  }

  getSpaceAverageTerm(): any {
    return (this.statSpaceStatistics) ? this.spaceService.displayDuration(this.statSpaceStatistics.avgTerm) : '0 months';
  }

  toggleTimelineDrawer(): void {
    this.timelineDrawerOpen = !this.timelineDrawerOpen;
    this.timelineDrawerOpenChange.emit(this.timelineDrawerOpen);
  }

  openLeaseViewTab(leaseId: string | null, occupancyType: OccupancyType, agreementType: AgreementType) {
    if (leaseId) {
      let payload: PageEventInterface = {
        componentToRegister: LeaseViewComponent,
        pageName: 'Lease ' + this.utils.displayUuid(leaseId),
        pageHeader: this.offering!.label,
        data: {id: leaseId, occupancyType, agreementType },
        id: this.utils.generateUuidWithPrefix('lease'),
        offeringId: this.offering!.offeringUuid,
        sectionCode: 'leasing',
        pageCode: 'lease-planning',
        offering: this.offering
      }
      this.tabManagementService.sendPageAddEvent(payload);
    }
  }

  removePlannedSpace(retailerId: number) {
    const lgs = this.selectedLeaseSpaceGroup!.leaseGridSpaces.filter(f => f.retailerId == retailerId)[0];
    this.removeLeaseSpace.emit(lgs.uuid);
    this.selectedLeaseSpaceGroup!.leaseGridSpaces = this.selectedLeaseSpaceGroup!.leaseGridSpaces.filter(f => f.uuid !== lgs.uuid);
    this.setRetailers();
    this.setLeaseSpaceRank();
  }

  drop(event: CdkDragDrop<Retailer[]>) {
    moveItemInArray(this.preferredRetailers, event.previousIndex, event.currentIndex);
    this.setLeaseSpaceRank();
  }

  setLeaseSpaceRank() {
    this.selectedLeaseSpaceGroup?.leaseGridSpaces.forEach(ea => {
      ea.periodGroupRanking = this.preferredRetailers.findIndex(i => i.id == ea.retailerId);
    })
  }

}
