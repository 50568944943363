import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LandlordPropertyModel} from '../../../model/landlordProperty.model';

@Component({
  selector: 'app-my-properties-landing',
  templateUrl: './my-properties-landing.component.html',
  styleUrls: ['./my-properties-landing.component.scss']
})
export class MyPropertiesLandingComponent {
  @Input() isPropertySelected: boolean = false;
  @Output() isPropertySelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedProperty: LandlordPropertyModel | null = null;
  @Output() selectedPropertyChange: EventEmitter<LandlordPropertyModel> = new EventEmitter<LandlordPropertyModel>();


  @Input() properties: LandlordPropertyModel[] = [];

  constructor() {
  }

  broadcastChangeProperty($event: LandlordPropertyModel) {
    this.selectedProperty = $event;
    this.selectedPropertyChange.emit($event);
  }
}
