import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserProfileDialogComponent} from './dialogs/user-profile-dialog/user-profile-dialog.component';

import {UserProfileDialogInterface} from './dialogs/user-profile-dialog/user-profile-dialog.interface';
import {CurrentContextService} from '../../services/security/current-context.service';
import {CurrentLandlordModel} from '../../model/landlord.model';
import {TabManagementService} from '../../services/tab-management.service';
import {MatSelectOption} from '../../interfaces/mat-select-component-option.interface';
import {User} from '../../model/user.model';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-user-profile-popover',
  templateUrl: './user-profile-popover.component.html',
  styleUrls: ['./user-profile-popover.component.scss']
})
export class UserProfilePopoverComponent {

  ownerLogo = "../assets/icons/default-logo.svg";
  loggedInUser: string = '';
  userCompany: string = '';
  userAccessibleTenants: any[] = [];
  loggedInUserObject: User | null = null


  constructor(public matDialog: MatDialog,
              private contextService: CurrentContextService,
              private tabManagementService: TabManagementService,
              public utilsService: UtilsService
  ) {
    let loggedInUserObject = this.contextService.currentUser;

    if (loggedInUserObject) {
      this.loggedInUser = loggedInUserObject.firstname + ' ' + loggedInUserObject.lastname;
      this.loggedInUserObject = loggedInUserObject;
    }

    this.contextService.getCurrentWebClient()
      .then((result: CurrentLandlordModel | null) => {
        this.userCompany = this.utilsService.displayString(result!.companyName, 25);
      });

    this.tabManagementService.getLandlordChangeEvent()
      .subscribe(() => {
        setTimeout(() => {
          this.contextService.getCurrentWebClient()
            .then((result: CurrentLandlordModel | null) => {
              this.userCompany = this.utilsService.displayString(result!.companyName, 25);
            });
        }, 500)

      });

    contextService.getUserAccessibleLandlordsFromToken()
      .then((options: MatSelectOption[]) => {
        this.userAccessibleTenants = options;
      });

  }

  openDialog(): void {
    let dialogData: UserProfileDialogInterface = {
      fullName: this.loggedInUser,
      currentProperty: this.userCompany,
      accessibleTenants: this.userAccessibleTenants,
      user: this.loggedInUserObject!
    };
    this.matDialog.open(UserProfileDialogComponent, {
      panelClass: 'dialog-medium-user-popover',
      backdropClass: 'dialog-backdrop',
      position: {right: '10px', top: '0px'},
      data: dialogData
    }).afterClosed()
      .subscribe((data: UserProfileDialogInterface) => {
        if (data) {
          this.loggedInUserObject = data.user;
          this.loggedInUser = `${this.loggedInUserObject.firstname} ${this.loggedInUserObject.lastname}`
        }
      });
  }

  getLoggedInImage(): string {
    if (this.loggedInUserObject != null &&
      this.loggedInUserObject.profileImage !== null &&
      this.loggedInUserObject.profileImage !== undefined &&
      this.loggedInUserObject.profileImage !== "") {
      return this.loggedInUserObject.profileImage;
    }
    return '../assets/icons/default-nter-logo.svg';
  }

}
