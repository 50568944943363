<div class="config-container full-width" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="sendPreviewForm()" class="form-icon-button" mat-icon-button>
      <mat-icon class="material-icons-outlined">visibility</mat-icon>
    </button>

    <button class="button-outline-white form-button" mat-flat-button (click)="saveFormTemplate(false)">
      <div class="button-text">Save</div>
    </button>

    <button class="button-primary form-button" mat-flat-button (click)="saveFormTemplate(true)">
      <div class="button-text">Next</div>
    </button>
  </div>

  <div *ngIf="selectedItem" fxLayout="column" fxLayoutGap="15px">

    <div>
      <mat-form-field class="custom-select-field-borderless select-white"
                      appearance="outline" fxLayoutGap="5px">
        <div class="select-icon mx-2" matPrefix>
          <mat-icon [svgIcon]="selectedItem!.template.icon"></mat-icon>
        </div>
        <mat-select [(ngModel)]="selectedItem!.template" (ngModelChange)="questionTypeChange.emit(selectedItem)">
          <mat-option *ngFor="let comp of templateComponents" [value]="comp">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
              <div class="select-icon">
                <mat-icon [svgIcon]="comp.icon"></mat-icon>
              </div>
              <div class="body-1-default">{{comp.label}}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="question-card" fxLayout="column">
      <div class="heading-regular mb-2">{{ formBuilderService.isTypeHeading(selectedItem) ? 'Heading' : 'Question' }}</div>
      <div fxLayout="column">
        <mat-form-field class="custom-text-area" appearance="outline">
          <textarea rows="3" matInput
                    (input)="itemChanged()"
                    [(ngModel)]="getQuestionTemplate.controlDescription!"
                    [placeholder]="formBuilderService.isTypeHeading(selectedItem)
                    ? 'Write your heading down here' : 'Write your question here'">
          </textarea>
        </mat-form-field>

        <div class="answer-item-container" *ngIf="getComponentType == ComponentType.QUESTION && !canBeScored">
          <mat-checkbox color="primary" [checked]="getQuestion!.required" [(ngModel)]="getQuestion.required">
            <span class="body-1-regular-1">Mandatory</span>
          </mat-checkbox>
        </div>

      </div>
    </div>

    <div class="question-card" fxLayout="column" *ngIf="getControlType == ControlType.SELECT">
      <div class="heading-regular mb-2">Answers</div>

      <div fxLayout="column" fxLayoutGap="5px">
        <mat-checkbox color="primary" [checked]="getQuestionControlPayload.selectType! == SelectType.MULTI" (change)="toggleMultiSelect($event)">
          <span class="body-1-regular-1">Multiple answer accepted</span>
        </mat-checkbox>

        <mat-accordion>
          <mat-expansion-panel class="config-expansion-panel"
                               *ngFor="let option of getQuestionTemplate.controlPayload.options; let i = index">
            <mat-expansion-panel-header>
              <div class="body-1-default">Answer {{i + 1}}</div>
            </mat-expansion-panel-header>
            <mat-form-field class="custom-text-area" appearance="outline" style="background-color: white">
              <textarea rows="2" matInput [(ngModel)]="option.label"
                        placeholder="Write your answer down here">
              </textarea>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>

        <button class="button-primary mt-2" mat-flat-button (click)="addOptionToQuestion()">
          <div class="button-text">Add Answer</div>
        </button>

      </div>
    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.HEADING_TEXT">
      <div class="heading-regular mb-2">Sub-heading</div>

      <div>
        <mat-form-field class="custom-text-area" appearance="outline">
          <textarea rows="4" matInput [(ngModel)]="getQuestionTemplate.controlPayload.label"
                    placeholder="Write your sub-heading down here">
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.FILE">
      <div class="heading-regular mb-2">File types</div>

      <mat-checkbox color="primary"
                    [checked]="includesFileTypes(fileExtensions.image)"
                    (change)="toggleFileTypes($event, 'image')">
        <div fxLayout="column">
          <span class="body-1-regular-1">Image format accepted</span>
          <span class="body-3-default">{{showExtensions(fileExtensions.image)}}</span>
        </div>
      </mat-checkbox>

      <mat-checkbox color="primary"
                    [checked]="includesFileTypes(fileExtensions.document)"
                    (change)="toggleFileTypes($event,'document')">
        <div fxLayout="column">
          <span class="body-1-regular-1">Document format accepted</span>
          <span class="body-3-default">{{showExtensions(fileExtensions.document)}}</span>
        </div>
      </mat-checkbox>

      <mat-checkbox color="primary"
                    [checked]="includesFileTypes(fileExtensions.dataFile)"
                    (change)="toggleFileTypes($event, 'dataFile')">
        <div fxLayout="column">
          <span class="body-1-regular-1">Data file format accepted</span>
          <span class="body-3-default">{{showExtensions(fileExtensions.dataFile)}}</span>
        </div>

      </mat-checkbox>

    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.FILE">
      <div class="heading-regular mb-2">Files</div>

      <div fxLayout="column" fxLayoutGap="5px">
        <mat-accordion>
          <mat-expansion-panel class="config-expansion-panel"
                               *ngFor="let file of getQuestionTemplate.controlPayload.files; let i = index">
            <mat-expansion-panel-header>
              <div class="body-1-default">File {{i + 1}}</div>
            </mat-expansion-panel-header>
            <mat-form-field class="custom-text-area" appearance="outline" style="background-color: white">
              <textarea rows="2" matInput [(ngModel)]="file.label"
                        placeholder="Write your file name here">
              </textarea>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>

        <button class="button-primary mt-2" mat-flat-button (click)="addFileToQuestion()">
          <div class="button-text">Add File</div>
        </button>

      </div>

    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.INFO_FILE">
      <div class="heading-regular mb-2">Files</div>

      <div fxLayout="column" fxLayoutGap="5px">
        <mat-accordion>
          <mat-expansion-panel class="config-expansion-panel"
                               *ngFor="let file of getQuestionTemplate.controlPayload.files; let i = index">
            <mat-expansion-panel-header>
              <div class="body-1-default">File {{i + 1}}</div>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutGap="10px">
              <mat-form-field class="custom-text-area" appearance="outline" style="background-color: white">
              <textarea rows="2" matInput [(ngModel)]="file.label"
                        placeholder="Write your file name here">
              </textarea>
              </mat-form-field>
              <ng-container *ngIf="getQuestionFileValue(file.valueCode!); let fileValue">
                <input #infoUpload type="file"
                       (change)="changeValue($event, file.valueCode!)"
                       [accept]="getQuestionTemplate.controlPayload.fileTypes!" hidden>
                <mat-chip-row color="primary" highlighted>
                  <span class="btn-cursor body-1-active"
                        matTooltip="Click file name to download file"
                        (click)="downloadFormFile(fileValue, infoUpload)">
                    {{ utils.displayString(fileValue.fileName, 20) }}
                  </span>
                  <button matChipTrailingIcon class="me-2"
                          matTooltip="Click to select file to upload"
                          (click)="infoUpload.click()">
                    <mat-icon>upload</mat-icon>
                  </button>
                </mat-chip-row>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <button class="button-primary mt-2" mat-flat-button (click)="addFileToQuestion()">
          <div class="button-text">Add File</div>
        </button>

      </div>

    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.NUMBER">
      <div class="heading-regular mb-2">Number types</div>

      <mat-radio-group [(ngModel)]="getQuestionControlPayload.numberType" color="primary">
        <mat-radio-button value="NUMBER">
          <span class="body-1-regular-1">Any number</span>
        </mat-radio-button>
        <mat-radio-button value="PERCENTAGE">
          <span class="body-1-regular-1">Percentage</span>
        </mat-radio-button>
        <mat-radio-button value="CURRENCY">
          <span class="body-1-regular-1">Currency</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="question-card" fxLayout="column" fxLayoutGap="5px" *ngIf="getControlType == ControlType.RANGE">
      <div class="heading-regular mb-2">Answer</div>

      <mat-form-field class="custom-select-field" appearance="outline" fxLayoutGap="5px">
        <mat-select [(ngModel)]="getQuestionControlPayload.numberType">
          <mat-option *ngFor="let type of numberTypes" [value]="type">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
              <div class="body-1-default">{{utils.capitalizeAndLower(type)}}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxFlexOffset="10px">
        <mat-accordion *ngIf="getQuestionControlPayload.rangeType == 'NUMBER'">
          <mat-expansion-panel class="config-expansion-panel">
            <mat-expansion-panel-header>
              <div class="body-1-default">Start at</div>
            </mat-expansion-panel-header>
            <mat-form-field class="custom-text-field" appearance="outline" style="background-color: white; width: 100%;">
              <input type="number" matInput [(ngModel)]="getQuestionControlPayload.start" (ngModelChange)="itemChanged()">
            </mat-form-field>
          </mat-expansion-panel>

          <mat-expansion-panel class="config-expansion-panel">
            <mat-expansion-panel-header>
              <div class="body-1-default">Increments</div>
            </mat-expansion-panel-header>
            <mat-form-field class="custom-text-field" appearance="outline" style="background-color: white; width: 100%;">
              <input type="number" matInput [(ngModel)]="getQuestionControlPayload.increments![0].incrementSize" (ngModelChange)="itemChanged()">
            </mat-form-field>
          </mat-expansion-panel>

          <mat-expansion-panel class="config-expansion-panel">
            <mat-expansion-panel-header>
              <div class="body-1-default">End at</div>
            </mat-expansion-panel-header>
            <mat-form-field class="custom-text-field" appearance="outline" style="background-color: white; width: 100%;">
              <input type="number" matInput [(ngModel)]="getQuestionControlPayload.end" (ngModelChange)="itemChanged()">
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </div>

  </div>
</div>
