import {Component, ContentChild, EventEmitter, Injector, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {LeaseCreateMethod} from "../../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.interface";
import {AgreementType, LeaseStatus, OccupancyType} from "../../../leasing.model";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {TabManagementService} from "../../../../../core/services/tab-management.service";
import {lastValueFrom, tap} from "rxjs";
import {PageEventInterface} from "../../../../../core/interfaces/page-event.interface";
import {LeaseViewComponent} from "../../../lease-view/lease-view.component";
import {LeasingService} from "../../../leasing.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {
  CreateALeaseDialogComponent
} from "../../../create-a-lease/create-a-lease-dialog/create-a-lease-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../../shared/components/custom-date-filter/custom-date-range.model";
import {PageOffering} from "../../../../../core/model/side-nav.model";
import {LeaseViewService} from "../../../lease-view/lease-view.service";
import {LoadingService} from "../../../../../core/services/loading.service";
import {ManageSpacesService} from '../../../../spaces/service/manage-spaces.service';
import {OfferingSpaceLeaseHistory} from '../../../../spaces/model/offering-spaces.interface';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatTableDataSource} from '@angular/material/table';
import {PagedContent} from '../../../../../shared/services/generic.service';

@Component({
  selector: 'app-planned-leases-list',
  templateUrl: './planned-leases-list.component.html',
  styleUrls: ['./planned-leases-list.component.scss']
})
export class PlannedLeasesListComponent {
  @ViewChild('paginator') paginator!: MatPaginator;

  @Input() offering!: PageOffering;
  @Output() spaceSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedLeaseGridSpace: EventEmitter<string> = new EventEmitter<string>();
  @ContentChild('timelineDrawerContent') timelineDrawerContent!: TemplateRef<any>;
  @Input() drawerOpened = false;

  displayedColumns: string[] = ['spaceName', 'size', 'companyName', 'startDate', 'endDate', 'rentalType', 'monthlyRentalFee', 'leaseStatus'];

  dataSource = new MatTableDataSource<OfferingSpaceLeaseHistory>([]);
  selection = new SelectionModel<OfferingSpaceLeaseHistory>(true, []);
  selectedRow: OfferingSpaceLeaseHistory | null = null;

  filterForm: FormGroup;
  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = []

  removePanelOpen = false;
  selectedSpaceId: string = '';
  totalPages: number = 0;
  totalElements: number = 0;

  constructor(injector: Injector,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private paginatorService: PaginatorService,
              private tabService: TabManagementService,
              private leasingService: LeasingService,
              private leaseViewService: LeaseViewService,
              private matDialog: MatDialog,
              public loader: LoadingService,
              private spaceService: ManageSpacesService) {
    this.matIconRegistry.addSvgIcon('pending', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/timeline/pending.svg'));
    this.filterForm = new FormGroup({
      companyName: new FormControl(null),
      spaceName: new FormControl(null),
    });
    let thisMonth: CustomDateRangeModel = new CustomDateRangeModel('1', 0, TimeFrames.MONTH, 'This month');
    let nextMonth: CustomDateRangeModel = new CustomDateRangeModel('2', 1, TimeFrames.MONTH, 'Next month');
    nextMonth.isFutureDate = true;
    thisMonth.isFutureDate = true;
    this.dateFilters.push(thisMonth);
    this.dateFilters.push(nextMonth);
  }

  ngOnInit() {
    lastValueFrom(this.spaceService.getLeaseHistory({size: '10', page: '0', sort: 'leaseStartDate,desc'},
      [{ name: 'leaseStatus', val: LeaseStatus.PLANNED}, {name: 'agreementType', val: AgreementType.LEASE}]
    )).then((res: PagedContent<OfferingSpaceLeaseHistory[]>) => {
      this.totalPages = res.totalPages;
      this.totalElements = res.totalElements;
      this.dataSource.data = res.content;
      this.setRowExpandedStatus();
    });
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();
  }

  loadLeases() {
    const filters = [
      {name: 'companyName', val: this.filterForm.get('companyName')?.value},
      {name: 'spaceName', val: this.filterForm.get('spaceName')?.value},
      {name: 'leaseStatus', val: LeaseStatus.PLANNED},
      {name: 'leaseStartDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'leaseEndDate', val: this.dateUtils.displayShortDate(this.dateRange.end)},
      {name: 'agreementType', val: AgreementType.LEASE}
    ];
    const page = {
      size: this.paginator.pageSize.toString(),
      page: this.paginator.pageIndex.toString(),
      sort: 'leaseStartDate,desc'
    }
    lastValueFrom(this.spaceService.getLeaseHistory(page, filters)).then((res: PagedContent<OfferingSpaceLeaseHistory[]>) => {
      this.totalPages = res.totalPages;
      this.totalElements = res.totalElements;
      this.dataSource.data = res.content;
      this.setRowExpandedStatus();
    });
  }

  openLeaseViewTab(id: string, occupancyType: OccupancyType, agreementType: AgreementType) {
    let payload: PageEventInterface = {
      componentToRegister: LeaseViewComponent,
      pageName: 'Lease ' + this.utils.displayUuid(id),
      pageHeader: this.offering.label,
      data: { id, occupancyType, agreementType },
      id: this.utils.generateUuidWithPrefix('lease'),
      offeringId: this.offering.offeringUuid,
      sectionCode: 'leasing',
      pageCode: 'current-leases',
      offering: this.offering
    }
    this.tabService.sendPageAddEvent(payload);
  }

  createLease(edit: boolean) {
    this.matDialog.open(CreateALeaseDialogComponent, {
      panelClass: 'dialog-large',
      data: {
        createMethod: edit ? LeaseCreateMethod.EDIT_LEASE : LeaseCreateMethod.NEW_LEASE,
        lease: edit ? this.selection.selected[0] : null,
        header: edit ? 'Edit existing lease' : 'Create a new lease',
        offering: this.offering
      }
    }).afterClosed()
      .subscribe(() => {
        this.loadLeases();
      });
  }

  removeLease($event: boolean) {
    if ($event) {
      this.selection.selected.forEach((lease, i) => {
        this.leaseViewService.removeLease(lease.retailerId, lease.uuid).subscribe({
          next: () => this.selection.selected.length == (i - 1) ? this.loadLeases() : null,
          error: err => console.log(err)
        })
      });
    }
    this.removePanelOpen = false;
  }

  displayRentalType(type: string | null) {
    return this.leasingService.getRentalType(type);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadLeases();
  }

  approveLease(item: OfferingSpaceLeaseHistory) {
    this.leaseViewService.updateLeaseStatus(item.retailerId, item.leaseUuid, LeaseStatus.APPROVED).subscribe({
      next: () => this.loadLeases(),
      error: err => console.log(err)
    });
  }

  setSelectedSpace(space: OfferingSpaceLeaseHistory): void {
    this.selectedSpaceId = space.districtSpaceId.toString();
    this.spaceSelected.emit(space.districtSpaceId.toString());
    this.selectedLeaseGridSpace.emit(space.leaseGridSpaceUuid);
  }

  onExpandedRowClick(row: OfferingSpaceLeaseHistory): void {
    this.approveLease(row);
  }

  onRowClick(row: OfferingSpaceLeaseHistory): void {
    row.isExpanded = !row.isExpanded;
    let data: any[] = this.dataSource.data;
    let index: number = data.findIndex(item => item.uuid == row.uuid);
    if (index >= 0) {
      data[index].isExpanded = row.isExpanded;
    }
    if (this.selectedRow && this.selectedRow.uuid !== row.uuid) {
      let currentRowIndex: number = data.findIndex(item => item.uuid == this.selectedRow!.uuid);
      if (currentRowIndex >= 0) {
        data[currentRowIndex].isExpanded = false;
      }
    }
    this.selectedRow = row;
    this.dataSource.data = data;
    this.setSelectedSpace(row);
  }

  isExpanded(index: any, item: any): boolean {
    return item.isExpanded;
  }

  private setRowExpandedStatus(): void {
    let itemsArray = this.dataSource.data;
    itemsArray.forEach(item => item.isExpanded = false);
    this.dataSource.data = itemsArray
  }
}
