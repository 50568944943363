<div class="full-width" fxLayout="column">
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="subtitle-default">Point-of-sale Settings</span>
      <div fxLayoutGap="10px" fxFlexOffset="50px" *ngIf="leaseSummary && !isSubLease">
        <button (click)="requestPOS(false)"
                [disabled]="!!(loader.loading$ | async)"
                class="button-action" mat-flat-button>
          <div class="button-text">Request POS</div>
        </button>
        <button (click)="requestPOS(true)"
                [disabled]="!canCancelPos || !!(loader.loading$ | async)"
                class="button-action" mat-flat-button>
          <div class="button-text">Cancel POS</div>
        </button>
        <button class="button-action-cancel" mat-flat-button
                *ngIf="!editingCardReaders && isInternalUser"
                (click)="activatePOSAccount(true)"
                [disabled]="(posTransactionSourceSelection.isEmpty() && posSupplierSourceSelection.isEmpty()) || !!(loader.loading$ | async)">
          <div class="button-text">Confirm POS Activated</div>
        </button>
        <button class="button-action-cancel" mat-flat-button
                *ngIf="!editingCardReaders && isInternalUser"
                (click)="activatePOSAccount(false)"
                [disabled]="(posTransactionSourceSelection.isEmpty() && posSupplierSourceSelection.isEmpty()) || !!(loader.loading$ | async)">
          <div class="button-text">Confirm POS Deactivated</div>
        </button>
      </div>
    </div>
    <div>
      <app-info-panel [type]="PanelType.INFO" [message]="[getActivationConfirmedMessage()]"
                      *ngIf="selectedPaymentConfig && (selectedPaymentConfig.activationStatus == 'ACTIVATED' || selectedPaymentConfig.activationStatus == 'DEACTIVATED')">
      </app-info-panel>
      <app-info-panel [type]="PanelType.INFO" [message]="[getActivationRequestMessage()]"
                      *ngIf="selectedPaymentConfig && (selectedPaymentConfig.activationStatus == 'ACTIVATION_REQUESTED' || selectedPaymentConfig.activationStatus == 'DEACTIVATION_REQUESTED')">
      </app-info-panel>

      <app-info-panel *ngIf="leaseIsNotApproved" [message]="[getLeaseMustBeApprovedRequiredMessage()]" [type]="PanelType.INFO"></app-info-panel>
      <app-info-panel *ngIf="hasNoPricingPeriod && !leaseIsNotApproved" [message]="[getLeasePricingPeriodRequiredMessage()]" [type]="PanelType.INFO"></app-info-panel>
    </div>
  </div>

  <div class="full-width" fxLayout="column" fxLayoutGap="20px" fxFlexOffset="30px">
    <div class="info-card" fxLayout="column">

      <div fxLayout="row" fxLayoutGap="10px">
        <span class="heading-default">Point-of-sale software settings</span>
        <button [disabled]="hasNoPricingPeriod || leaseIsNotApproved || !!(loader.loading$ | async)" class="button-action" mat-flat-button
                (click)="addNewPOSSettings()">
          <div class="button-text">Add</div>
        </button>
        <button class="button-action" mat-flat-button
                [disabled]="(posTransactionSourceSelection.isEmpty() && posSupplierSourceSelection.isEmpty()) ||  !!(loader.loading$ | async)"
                (click)="removeSelectedPosSetting()">
          <div class="button-text">Remove</div>
        </button>
        <button class="button-action" mat-flat-button
                [disabled]="(posTransactionSourceSelection.isEmpty() && posSupplierSourceSelection.isEmpty()) || !!(loader.loading$ | async)"
                (click)="editPosSettings()">
          <div class="button-text">Edit</div>
        </button>
      </div>

      <app-info-panel [type]="PanelType.INFO" [message]="['Lease has not been assigned any Point of Sale']"
        *ngIf="!displayTransactionSourceTable && !displaySupplierSourceTable"></app-info-panel>

      <div fxFlexOffset="25px" fxLayout="row" *ngIf="displayTransactionSourceTable">
        <table [dataSource]="leaseTransactionSourceConfigDataSource" class="custom-table" fxFlex="50" mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="posSettingsDisplayedColumns.length" class="custom-table-label"
                mat-header-cell>
              Point-of-sale Config
            </th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="$event ? setSelectedPosSettings(row) : null"
                            (click)="$event.stopPropagation()"
                            [checked]="posTransactionSourceSelection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="uuid">
            <th *matHeaderCellDef mat-header-cell> ID</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{utils.displayUuid(element.paymentConfigUuid)}}</td>
          </ng-container>

          <ng-container matColumnDef="sourceType">
            <th *matHeaderCellDef mat-header-cell> Source Type</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{element.paymentTypeName}} </td>
          </ng-container>

          <ng-container matColumnDef="provider">
            <th *matHeaderCellDef mat-header-cell>Provider</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell>{{getProviderName(element.paymentSourceName)}}</td>
          </ng-container>

          <ng-container matColumnDef="domainPrefix">
            <th *matHeaderCellDef mat-header-cell> Domain Prefix</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{element.domainPrefix}} </td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th *matHeaderCellDef mat-header-cell>Start Date</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{dateUtils.displayShortDateUnix(element.startDate)}}</td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th *matHeaderCellDef mat-header-cell>End Date</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{dateUtils.displayShortDateUnix(element.endDate)}}</td>
          </ng-container>

          <ng-container matColumnDef="activationStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{utils.displayStatus(element.activationStatus)}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell>Action</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell>
              <button (click)="checkSuppliers(element)" class="button-action" mat-flat-button [disabled]="leaseSummary.occupancyType == OccupancyType.SINGLE">
                <div class="button-text"> Linked Suppliers</div>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="posSettingsDisplayedColumns" class="custom-table-header" mat-header-row></tr>
          <tr (click)="setSelectedPosSettings(row)" *matRowDef="let row; columns: posSettingsDisplayedColumns;"
              [class.selected-row]="selectedPosSettingDomainPrefix === row.domainPrefix"
              class="custom-table-row btn-cursor"
              mat-row></tr>
        </table>

      </div>

      <mat-paginator #posSettingsPaginator *ngIf="displayTransactionSourceTable"
                     [length]="leaseTransactionSourceConfigDataSource ? leaseTransactionSourceConfigDataSource.data.length : 0"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageSize]="10"
                     class="custom-paginator"></mat-paginator>


      <div fxFlexOffset="25px" fxLayout="row" *ngIf="displaySupplierSourceTable">
        <table [dataSource]="leaseSupplierSourceConfigDataSource" class="custom-table" fxFlex="50" mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="posSuppliersDisplayedColumns.length" class="custom-table-label"
                mat-header-cell>
              Point-of-sale Suppliers Config
            </th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="setSelectedPosSupplier(row)"
                            (click)="$event.stopPropagation()"
                            [checked]="selectedPosSupplierUuid == row.uuid"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="uuid">
            <th *matHeaderCellDef mat-header-cell> ID</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{utils.displayUuid(element.uuid)}}</td>
          </ng-container>

          <ng-container matColumnDef="displayName">
            <th *matHeaderCellDef mat-header-cell> Linked Source </th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{ isSubLease ? element.domainPrefix : element.displayName }} </td>
          </ng-container>

          <ng-container matColumnDef="supplierName">
            <th *matHeaderCellDef mat-header-cell> Supplier </th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{element.supplierName}}</td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th *matHeaderCellDef mat-header-cell>Start Date</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{dateUtils.displayShortDateUnix(element.startDate)}}</td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th *matHeaderCellDef mat-header-cell>End Date</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{dateUtils.displayShortDateUnix(element.endDate)}}</td>
          </ng-container>

          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="posSuppliersDisplayedColumns" class="custom-table-header" mat-header-row></tr>
          <tr (click)="setSelectedPosSupplier(row)" *matRowDef="let row; columns: posSuppliersDisplayedColumns;"
              [class.selected-row]="selectedPosSupplierUuid === row.uuid"
              class="custom-table-row btn-cursor"
              mat-row></tr>
        </table>

      </div>

      <mat-paginator #posSupplierPaginator *ngIf="displaySupplierSourceTable"
                     [length]="leaseSupplierSourceConfigDataSource ? leaseSupplierSourceConfigDataSource.data.length : 0"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageSize]="10"
                     class="custom-paginator"></mat-paginator>

    </div>

    <div class="info-card" fxLayout="column" *ngIf="hasOwnPosSettings">

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="10px">
        <span class="heading-default">Payment device settings</span>
        <button class="button-action" mat-flat-button *ngIf="editingCardReaders" (click)="saveCardReadersChanges()" [disabled]="cardReadersOverlap() || !!(loader.loading$ | async)"><div class="button-text">Save</div></button>
        <button class="button-action-cancel" mat-flat-button *ngIf="editingCardReaders" (click)="cancelCardReaderChanges()" [disabled]="!!(loader.loading$ | async)"><div class="button-text">Cancel</div></button>
        </div>
        <div fxLayout="row">
          <app-info-panel *ngIf="!isLeaseActiveOrApproved" [message]="[getCardReaderLeaseNotActiveMessage()]"
                          [type]="PanelType.INFO"></app-info-panel>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">
        <mat-error class="error-text" *ngIf="cardReadersOverlap()">Card readers cannot have over-lapping dates
        </mat-error>

        <div fxLayout="row" fxLayoutGap="15px" *ngFor="let reader of cardReaders">
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Card Reader Code</mat-label>
            <mat-select class="custom-select-field-value" [placeholder]="reader.cardReaderCode!"
                        [(ngModel)]="reader.cardReaderCode"
                        [disabled]="!isLeaseActiveOrApproved || !cardReaderFutureDated(reader)">
              <mat-option class="custom-select-field-option" *ngFor="let available of getAvailableCardReaders(reader)"
                          [value]="available.cardReaderCode">{{available.cardReaderCode}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
            <mat-label>Start Date</mat-label>
            <input matInput placeholder="select" [matDatepicker]="startPicker"
                   [(ngModel)]="reader.startDate" (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)"
                   [disabled]="!isLeaseActiveOrApproved || !cardReaderFutureDated(reader)"/>
            <mat-datepicker-toggle matIconSuffix [for]="startPicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
            <mat-label>End Date</mat-label>
            <input (ngModelChange)="loadAvailableLeaseCardReaders(reader, false)" [(ngModel)]="reader.endDate" [disabled]="!isLeaseActiveOrApproved" [matDatepicker]="endPicker"
                   matInput placeholder="select"/>
            <mat-datepicker-toggle matIconSuffix [for]="endPicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" *ngFor="let newCardReader of newCardReaders">
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Card Reader Code</mat-label>
            <mat-select [(ngModel)]="newCardReader.cardReaderCode" [disabled]="!isLeaseActiveOrApproved" class="custom-select-field-value"
                        placeholder="Not Assigned">
              <mat-option class="custom-select-field-option" *ngFor="let reader of newAvailableCardReaders" [value]="reader.cardReaderCode">{{reader.cardReaderCode}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
            <mat-label>Start Date</mat-label>
            <input (ngModelChange)="loadAvailableLeaseCardReaders(newCardReader, true)" [(ngModel)]="newCardReader.startDate" [disabled]="!isLeaseActiveOrApproved" [matDatepicker]="startPicker"
                   matInput
                   placeholder="select"/>
            <mat-datepicker-toggle matIconSuffix [for]="startPicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="custom-date-picker" appearance="outline" floatLabel="always">
            <mat-label>End Date</mat-label>
            <input (ngModelChange)="loadAvailableLeaseCardReaders(newCardReader, true)" [(ngModel)]="newCardReader.endDate" [disabled]="!isLeaseActiveOrApproved" [matDatepicker]="endPicker"
                   matInput
                   placeholder="select"/>
            <mat-datepicker-toggle matIconSuffix [for]="endPicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
    </div>

  </div>

</div>
