import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LandlordPropertyModel} from "../../../core/model/landlordProperty.model";
import {LocationOffering, PageOffering} from "../../../core/model/side-nav.model";
import {LoadingService} from "../../../core/services/loading.service";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {RetailerViewService} from "../../leads/retailers/retailer-view/retailer-view.service";
import {Retailer} from "../../leads/retailers/retailers.model";
import {RetailersService} from "../../leads/retailers/retailers.service";
import {LeaseSummary} from '../../leasing/leasing.model';
import {LeasingService} from "../../leasing/leasing.service";
import {ConfigType, PosRequest} from "./request-pos.model";
import {RequestPosService} from "./request-pos.service";

@Component({
  selector: 'app-request-pos-dialog',
  templateUrl: './request-pos-dialog.component.html',
  styleUrls: ['./request-pos-dialog.component.scss']
})
export class RequestPosDialogComponent implements OnInit {

  cancel: boolean = false;
  header: string;

  properties: LandlordPropertyModel[] = [];
  property?: LandlordPropertyModel | null;
  offering?: PageOffering | null;

  searchTerm = new FormControl('');
  loadingSearch: boolean = false;
  allRetailers!: {id: number, companyName: string, leaseId: string}[];
  selectedRetailer!: {id: number, companyName: string, leaseId: string} | null;

  requestForm: FormGroup;
  retailerLeases: LeaseSummary[] = [];

  constructor(public dialogRef: MatDialogRef<RequestPosDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private retailerViewService: RetailerViewService,
              private retailersService: RetailersService,
              private leasingService: LeasingService,
              private currentContext: CurrentContextService,
              private dateUtils: DateUtilsService,
              private requestPosService: RequestPosService,
              public loader: LoadingService) {
    this.cancel = this.data.cancel;
    this.header = this.data.header;
    this.properties = this.data.properties;
    this.selectedRetailer = {id: this.data.retailerId, companyName: this.data.companyName, leaseId: this.data.leaseId};
    this.property = this.data.property;
    this.offering = this.data.offering;

    if (this.property) {
      this.properties = [this.property];
    }
    let offering = null;

    if (this.offering && this.property) {
      offering = this.property.locationOfferings!.filter(f => f.offeringUuid === this.offering!.offeringUuid)[0];
    }

    this.requestForm = new FormGroup({
      location: new FormControl<LandlordPropertyModel | null>({value: this.property ? this.property : null, disabled: !!this.property}, Validators.required),
      offering: new FormControl<LocationOffering | null>({value: offering, disabled: !this.property || !!this.offering}, Validators.required),
      activationDate: new FormControl({value: null, disabled: !this.property || !this.offering}, Validators.required),
      newPaymentDevice: new FormControl<boolean>({value: false, disabled: !this.property || !this.offering}, Validators.required),
      // todo set update default depending on lease type
      configType: new FormControl<ConfigType>({value: ConfigType.VEND, disabled: true}, Validators.required),
      leaseUuid: new FormControl<string>({value: '', disabled: !this.selectedRetailer.leaseId}, Validators.required)
    })

    if (!!this.data.retailerId) {
      this.searchTerm.disable();
      this.loadLeasesForRetailer(this.data.retailerId);
    }

    if (!!this.data.leaseId) {
      this.selectedRetailer.leaseId = this.data.leaseUuid;
      this.requestForm.get('leaseUuid')!.setValue(this.data.leaseId);
      this.requestForm.get('leaseUuid')!.disable();
    }
  }

  ngOnInit() {

    this.requestForm.valueChanges.subscribe(() => {
      this.enableFormItems();
    })

    this.requestForm.get('location')?.valueChanges.subscribe(() => {
      this.requestForm.get('offering')?.setValue(null);
      this.selectRetailer(null);
      this.searchTerm.setValue('');
      this.allRetailers = [];
    })

    this.requestForm.get('offering')?.valueChanges.subscribe(() => {
      this.selectRetailer(null);
      this.searchTerm.setValue('');
      this.allRetailers = [];
    })
  }

  getLocationOfferings(): LocationOffering[] {
    return this.requestForm.get('location')?.value ? this.requestForm.get('location')?.value.locationOfferings : [];
  }

  loadRetailers() {
    const selectedOffering: LocationOffering = this.requestForm.get('offering')?.value;
    if (selectedOffering) {
      this.currentContext.setCurrentOfferingId(selectedOffering.offeringUuid);
      const searchTerm = this.searchTerm.value;
      if (searchTerm && searchTerm.length >= 3) {
        this.loadingSearch = true;
        const page = {size: '10', page: '0', sort: 'retailerCompanyName,asc'}
        this.leasingService.getAll('/lease-schedule', page, [
          {name: 'retailerCompanyName', val: searchTerm}
        ]).subscribe({
          next: value => {
            const leaseSum = value.content;
            this.allRetailers = leaseSum.map(m => {
              return { id: m.retailerId, companyName: m.retailerCompanyName!, leaseId: m.uuid };
            })
            this.loadingSearch = false;
          },
          error: err => {
            console.log(err);
            this.loadingSearch = false;
          }
        })
      }
    }
  }

  save() {
    let request;

    const locationId = this.requestForm.get('location')?.value.uuid;
    const offeringId = this.requestForm.get('offering')?.value.offeringUuid;

    if (!this.cancel) {
      request = new PosRequest(
        this.requestForm.get('leaseUuid')?.value,
        this.requestForm.get('location')?.value.locationName,
        this.requestForm.get('offering')?.value.offeringName,
        this.dateUtils.displayShortDate(this.requestForm.get('activationDate')?.value)!,
        this.requestForm.get('configType')?.value,
        this.requestForm.get('newPaymentDevice')?.value
      )

      this.requestPosService.requestNewPos(this.selectedRetailer!.id!, request, locationId, offeringId).subscribe({
        next: value => this.close(true, this.selectedRetailer!.leaseId),
        error: err => console.log(err)
      })
    }
    if (this.cancel) {
      request = new PosRequest(
        this.requestForm.get('leaseUuid')?.value,
        this.requestForm.get('location')?.value.locationName,
        this.requestForm.get('offering')?.value.offeringName,
        this.dateUtils.displayShortDate(this.requestForm.get('activationDate')?.value)!,
        this.requestForm.get('configType')?.value
      )

      this.requestPosService.requestCancelPos(this.selectedRetailer!.id!, request, locationId, offeringId).subscribe({
        next: value => this.close(true, this.selectedRetailer!.leaseId),
        error: err => console.log(err)
      })
    }
  }

  selectRetailer(retailer: {id: number, companyName: string, leaseId: string} | null) {
    this.selectedRetailer = retailer;
    this.loadLeasesForRetailer(retailer?.id!)
  }

  displayFn(value: Retailer) {
    if (value) {
      return value.id && value.companyName ? `${value.id}. ${value.companyName}` : '';
    }
    return '';
  }

  close(save: boolean, leaseId: string | null) {
    this.dialogRef.close({ save, leaseId });
  }

  enableFormItems(): void {
    const property = this.requestForm.get('location')?.value;
    const offering = this.requestForm.get('offering')?.value;

    // check if retailer is selected enable lease dropdown and fill lease drop down
    if (!!this.selectedRetailer && !this.data.leaseId) {
      if (this.requestForm.controls['leaseUuid'].disabled) {
        this.requestForm.controls['leaseUuid'].enable();
      }
    }
    if (!!property && !!offering) {
      this.requestForm.controls['offering'].enable({ emitEvent: false });
      this.requestForm.controls['activationDate'].enable({ emitEvent: false });
      this.requestForm.controls['newPaymentDevice'].enable({ emitEvent: false });
    }
    if (!!property) {
      this.requestForm.controls['offering'].enable({ emitEvent: false });
    }
  }

  private loadLeasesForRetailer(retailerId: number): void {
    if (!!retailerId) {
      const page = {size: '100', page: '0', sort: 'retailerCompanyName,asc'}
      this.leasingService.getAll('/lease-schedule', page, [
        {name: 'retailerId', val: retailerId}
      ]).subscribe({
        next: value => {
          this.retailerLeases = value.content
        },
        error: err => {
          console.log(err);
          this.loadingSearch = false;
        }
      });
    }
  }

  get selectedLease() {
    const leaseUuid = this.requestForm.get('leaseUuid')?.value;
    if (leaseUuid) {
      return this.retailerLeases.filter(f => f.uuid == leaseUuid)[0];
    }
    return null;
  }

  protected readonly ConfigType = ConfigType;
}
