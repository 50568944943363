import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {LoadingService} from "./loading.service";
import {finalize, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NetworkInterceptor implements HttpInterceptor {

  totalRequests = 0;
  completedRequests = 0;

  constructor(private loader: LoadingService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.show();
    this.totalRequests++;
    return next.handle(req).pipe(
      finalize(() => {
        this.completedRequests++;

        if (this.completedRequests == this.totalRequests) {
          this.loader.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
        }
      })
    )
  }
}
