import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs";
import {UtilsService} from "../../../../../shared/services/utils.service";
import {EventsService} from "../../../../../core/services/events.service";
import {PaginatorService} from "../../../../../shared/services/paginator.service";
import {GenericDatasource} from "../../../../../shared/datasource/generic.datasource";
import {FormControl, FormGroup} from "@angular/forms";
import {Event} from "../../../../../shared/model/event.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {DateRange} from "@angular/material/datepicker";
import {RetailerViewService} from "../retailer-view.service";
import {RetailerEvent} from "../../retailers.model";
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";

@Component({
  selector: 'app-retailer-events',
  templateUrl: './retailer-events.component.html',
  styleUrls: ['./retailer-events.component.scss']
})
export class RetailerEventsComponent implements OnInit, AfterViewInit{
  @Input() id!: string;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['id', 'eventType', 'time', 'triggeredBy'];
  dataSource = new GenericDatasource<RetailerEvent>(this.retailerViewService);

  filterForm: FormGroup;

  dateRange?: DateRange<Date>;

  constructor(public utils: UtilsService, public dateUtils: DateUtilsService, private retailerViewService: RetailerViewService, private paginatorService: PaginatorService) {
    this.filterForm = new FormGroup({
      eventType: new FormControl(null),
      eventId: new FormControl(null)
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<RetailerEvent>(this.retailerViewService);
    this.dataSource.loadData(`/${this.id}/events`, {size: '10', page: '0', sort: 'createDate,desc'}, []);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadEvents()))
      .subscribe();

    this.filterForm.valueChanges.subscribe({
      next: () => this.loadEvents()
    })
  }

  loadEvents() {
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'createDate,desc'};
    this.dataSource.loadData('', page, []);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadEvents();
  }
}
