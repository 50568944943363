import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AbstractControl, FormBuilder, FormGroup, FormsModule, ValidationErrors, ValidatorFn} from '@angular/forms';
import {HttpClient, HttpParams} from '@angular/common/http';
import {of, timeout} from "rxjs";
import {catchError} from "rxjs/operators";
import {LoadingService} from "../../../core/services/loading.service";
import {MaterialModule} from "../../../material.module";
import {dateTimePattern, UploadDataModel, UploadResponse} from "./csv-import.model";
import {MatSelectModule} from "@angular/material/select";
import {DateUtilsService} from "../../services/dateUtils.service";
import {MatDialog} from "@angular/material/dialog";
import {CsvImportReportComponent} from "./csv-import-report/csv-import-report.component";

@Component({
  selector: 'app-csv-import',
  standalone: true,
  imports: [MaterialModule, FormsModule, MatSelectModule],
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent implements OnInit, OnChanges {

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() uploadDataModel: UploadDataModel[] = [];
  @Input() params: { name: string, val: string }[] = [];
  @Input() uploadEndpoint!: string;
  @Input() disableUpload: boolean = false;

  selectedFileName: string = '';
  originalData: any[] = []; // Store the original CSV data
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]); // Initialize with empty data
  displayedColumns: string[] = [];
  csvColumnHeaders: string[] = [];
  searchText: string = '';
  pageSize: number = 10;
  showColumnMappingForm: boolean = false;

  infoIcon = 'info-icon';
  infoMsg = 'Set up your file to import';
  uploadReport!: UploadResponse;

  // Define a form group for column mapping
  columnMappingForm!: FormGroup;
  loading = false;

  constructor(
    private papa: Papa,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private dateUtils: DateUtilsService,
    private matDialog: MatDialog,
    private _loader: LoadingService
  ) {
    this._loader.loading$.subscribe(load => this.loading = load);
    // Initialize the form group with default mappings
    // const initialMappings: any = {};
    // for (const modelProp of this.uploadDataModel) {
    //   initialMappings[modelProp.property] = '';
    // }
    // this.columnMappingForm = this.fb.group(initialMappings);
  }

  ngOnInit() {
    this.resetPage();
  }

  ngOnChanges() {
    this.resetPage();
  }

  get formColumns(): UploadDataModel[] {
    return this.uploadDataModel.filter(f => f.selectColumn);
  }

  onFileSelected(event: any) {
    this.infoIcon = 'info-icon';
    this.infoMsg = 'Set up your file to import';

    const file = event.target.files[0];
    this.resetParsedData();
    if (file) {
      this.selectedFileName = file.name;
      this.parseCsv(file);
    }
  }

  parseCsv(file: File) {
    this.papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      transformHeader: (header) => header.trim(),
      complete: (result) => {
        if (result.meta.fields) {
          this.originalData = result.data; // Store the original CSV data
          this.showColumnMappingForm = true; // Show the column mapping form
          this.dataSource = new MatTableDataSource<any>([]); // Initialize with empty data
          this.displayedColumns = this.uploadDataModel
            .filter(f => f.showInTable)
            .map((modelProp) => modelProp.property); // Initialize displayed columns
          this.csvColumnHeaders = result.meta.fields;
        }
      },
    });
  }

  applyFilter() {
    const filterValue = this.searchText.trim().toLowerCase();
    this.dataSource.filter = filterValue;

    // Handle pagination when search filters are applied
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Function to update the table based on form mappings
  // updateTable() {
  //   const mappedData = this.mapCsvData(this.originalData); // Use original data
  //   this.dataSource = new MatTableDataSource<any>(mappedData);
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  // }

  updateTable() {
    // Reset validation messages
    for (const modelProp of this.uploadDataModel) {
      this.columnMappingForm.get(modelProp.property)?.setErrors(null);
    }

    // Check if the form is valid before updating the table
    if (this.columnMappingForm.valid) {
      const mappedData = this.mapCsvData(this.originalData);
      this.dataSource = new MatTableDataSource<any>(mappedData);

      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 1500)

    }
    this.columnMappingForm.updateValueAndValidity();
  }

  // Function to map CSV data to the specified model
  // mapCsvData(data: any[]): any[] {
  //   const mappings = this.columnMappingForm.value;
  //   return data.map((row) => {
  //     const mappedRow: any = {}; // Specify type 'any'
  //     for (const modelProp of this.uploadDataModel) {
  //       const csvColumn = mappings[modelProp.property].trim(); // Trim leading/trailing spaces
  //       if (csvColumn) {
  //         mappedRow[modelProp.property] = row[csvColumn];
  //       }
  //     }
  //     return mappedRow;
  //   });
  // }

  private trimIfString(value: any): any {
    if (typeof value === 'string') {
      value = value.trim(); // Trim the value if it's a string
    }
    return value;
  }

  // Function to map CSV data to the specified model
  mapCsvData(data: any[]): any[] {
    const mappings = this.columnMappingForm.value;
    const firstRow = data[0]; // Get the first row of data

    // Check and set validation messages
    for (const modelProp of this.uploadDataModel) {
      const csvColumn = mappings[modelProp.property] ? mappings[modelProp.property].trim() : mappings[modelProp.property]; // Trim leading/trailing spaces
      if (csvColumn) {
        const value = firstRow[csvColumn];
        const isValid = modelProp.validators.every((validator) =>
          validator.test(this.trimIfString(value))
        );
        if (!isValid) {
          // Set validation message for the corresponding select control
          this.columnMappingForm.get(modelProp.property)?.setErrors({
            patternMismatch: true,
          });
        }
      }
    }

    return data.map((row) => {
      const mappedRow: any = {}; // Specify type 'any'
      for (const modelProp of this.uploadDataModel) {
        const csvColumn = mappings[modelProp.property] ? mappings[modelProp.property].trim() : mappings[modelProp.property]; // Trim leading/trailing spaces
        if (modelProp.selectColumn && csvColumn) {
          mappedRow[modelProp.property] = this.trimIfString(row[csvColumn]);
          if (modelProp.validators.includes(dateTimePattern)) {
            mappedRow[modelProp.property] = this.dateUtils.displayOffsetDateTime(mappedRow[modelProp.property]);
          }
        } else {
          if (modelProp.computeValue) {
            mappedRow[modelProp.property] = modelProp.computeMethod(mappedRow);
          } else {
            mappedRow[modelProp.property] = mappings[modelProp.property];
          }
        }
      }
      return mappedRow;
    });
  }

  // Function to get the label for a column based on the selected mappings
  getLabelForColumn(column: string): string {
    const mapping = this.uploadDataModel.find(
      (modelProp) => modelProp.property === column
    );
    return mapping ? mapping.label : column;
  }

  // Function to send data to the API
  uploadData() {
    const mappedData = this.mapCsvData(this.originalData);
    let requestParams = new HttpParams();
    const timeoutAmount = 300; // 5min

    this.infoMsg = 'Uploading your file...';

    if (this.params) {
      this.params.filter(f => f.val !== null).forEach(ea => {
        requestParams = requestParams.append(ea.name, ea.val)
      });
    }
    this.httpClient.post(this.uploadEndpoint, mappedData, { params: requestParams })
      .pipe(
        timeout(timeoutAmount * 1000),
        catchError(e => {
          console.log(e, timeout)
          return of(null)
        })
      )
      .subscribe({
      next: (value) => {
        console.log('Data sent to the API:', mappedData);
        console.log('Response: ', value);
        // @ts-ignore
        this.uploadReport = value;
        this.infoMsg = 'File successfully uploaded, click here to see the results.'
        this.resetPage();
      },
      error: err => {
        console.log(err);
        this.infoMsg = 'Something went wrong, please check your data!'
        this.infoIcon = 'error-icon'
      }
    });
  }

  private resetParsedData() {
    this.originalData = [];
    this.dataSource = new MatTableDataSource<any>([]);
    this.paginator?.firstPage();
    this.csvColumnHeaders = [];
    this.showColumnMappingForm = false;
  }

  private resetPage(): void {
    this.selectedFileName = '';
    this.originalData = [];
    this.dataSource = new MatTableDataSource<any>([]);
    this.paginator?.firstPage();

    // this.displayedColumns: string[] = [];
    this.csvColumnHeaders = [];
    this.searchText = '';
    this.showColumnMappingForm = false;

    if (this.fileInput) this.fileInput.nativeElement.value = "";

    // Create a custom validator function
    // const requiredFieldsValidator = (formGroup: FormGroup) => {
    //   const modelProps = this.uploadDataModel;
    //   const missingFields = modelProps
    //     .filter((modelProp) => modelProp.required)
    //     .filter((modelProp) => !formGroup.get(modelProp.property)?.value);

    //   return missingFields.length === 0 ? null : { requiredFields: true };
    // };

    // Define a form group for column mapping
    const initialMappings: any = {};
    for (const modelProp of this.uploadDataModel) {
      initialMappings[modelProp.property] = null;
    }
    this.columnMappingForm = this.fb.group(initialMappings, {
      validators: [this.requiredColumnsValidator()],
    });
    this.showColumnMappingForm = true;

    // this.columnMappingForm.valueChanges.subscribe(value => {
    //   this.updateTable();
    // })
  }

  requiredColumnsValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const invalidColumns: string[] = [];

      for (const modelProp of this.uploadDataModel) {
        if (modelProp.required) {
          const selectedColumn = formGroup.get(modelProp.property)?.value;
          if (!selectedColumn || selectedColumn && selectedColumn.trim() === '') {
            invalidColumns.push(modelProp.property);
          }
        }
      }

      return invalidColumns.length > 0
        ? { requiredColumns: invalidColumns }
        : null;
    };
  }

  openReport() {
    this.matDialog.open(CsvImportReportComponent, {
      panelClass: 'dialog-large',
      data: {
        uploadReport: this.uploadReport
      }
    })
  }
}
