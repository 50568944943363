<div class="full-width" fxLayout="column" fxLayoutGap="15px">
  <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="subtitle-default">Manage payment groups</span>
      <div fxFlexOffset="30px" fxLayoutGap="10px">
        <button (click)="toggleAddGroup()" [disabled]="(loader.loading$ | async) || this.addingGroup"
                class="button-action"
                mat-flat-button>
          <div class="button-text">Add Group</div>
        </button>
      </div>
    </div>

<!--  1.  Select which type of batch you want to configure-->

    <div>
      <mat-button-toggle-group [value]="configsType.value" (change)="setConfigsType($event)"
                               class="custom-date-button-toggle">
        <mat-button-toggle [value]="TransactionSource.VEND">Vend Leases</mat-button-toggle>
        <mat-button-toggle [value]="TransactionSource.POSTER">Poster Leases</mat-button-toggle>
        <mat-button-toggle [value]="TransactionSource.VEND_SUPPLIER_OFFERING">Offering</mat-button-toggle>
        <mat-button-toggle [value]="TransactionSource.VEND_SUPPLIER_LEASE">Shared Leases</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxFlexOffset="30px" fxLayout="column">

<!--  2A. OFFERING: Select which offering config to batches to edit-->

    <div fxLayout="column" *ngIf="configsType.value == TransactionSource.VEND_SUPPLIER_OFFERING">
      <span class="heading-default">Payment Configs</span>

      <div fxFlexOffset="25px" fxLayout="column">
        <table mat-table class="custom-table" [dataSource]="paymentConfigDatasource">
          <ng-container matColumnDef="table-label">
            <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="paymentConfigColumns.length">
              Point of sale
            </th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="$event ? paymentConfigSelection.toggle(row) : null"
                            (click)="setSelectedPaymentConfig(row)"
                            [checked]="paymentConfigSelection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="uuid">
            <th mat-header-cell *matHeaderCellDef> ID</th>
            <td mat-cell class="custom-table-cell btn-cursor"
                matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
                *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
          </ng-container>

          <ng-container matColumnDef="transactionSource">
            <th mat-header-cell *matHeaderCellDef>Payment Provider</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{element.transactionSource}}</td>
          </ng-container>

          <ng-container matColumnDef="domainPrefix">
            <th mat-header-cell *matHeaderCellDef>Domain Prefix</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('domainPrefix', element.transactionSourceConfig)}}</td>
          </ng-container>

          <ng-container matColumnDef="outletId">
            <th mat-header-cell *matHeaderCellDef>Outlet ID</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{getConfigValue('outletId', element.transactionSourceConfig)}}</td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.startDate)}}</td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>End Date</th>
            <td mat-cell class="custom-table-cell" *matCellDef="let element">{{dateUtils.displayShortDate(element.endDate)}}</td>
          </ng-container>

          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
          <tr mat-header-row class="custom-table-header" *matHeaderRowDef="paymentConfigColumns"></tr>
          <tr (click)="setSelectedPaymentConfig(row)" *matRowDef="let row; columns: paymentConfigColumns;"
              [class.selected-row]="selectedPaymentConfigUuid === row.uuid"
              class="custom-table-row"
              mat-row
          ></tr>
        </table>
        <mat-paginator #configPaginator [length]="paymentConfigDatasource.data.length"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [pageSize]="5"
                       class="custom-paginator"></mat-paginator>
      </div>
    </div>


<!--  2B. SHARED: Select which head lease config to edit-->

    <div fxLayout="column" *ngIf="configsType.value == TransactionSource.VEND_SUPPLIER_LEASE">
      <span class="heading-default">Shared Leases</span>
      <form [formGroup]="sharedLeasesForm" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Lease Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" [multiple]="true" formControlName="leaseStatus">
              <mat-option class="custom-select-field-option" *ngFor="let status of leaseStatuses"
                          [value]="status">{{ utils.displayStatus(status) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <table [dataSource]="sharedLeasesDatasource" class="custom-table" fxFlex="60" fxFlexOffset="25px" mat-table>
        <ng-container matColumnDef="table-label">
          <th *matHeaderCellDef [attr.colspan]="sharedLeasesColumns.length" class="custom-table-label"
              mat-header-cell>
            Head Leases
          </th>
        </ng-container>

        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="custom-table-cell" mat-cell>
            <mat-checkbox (change)="$event ? sharedLeaseSelection.toggle(row) : null"
                          (click)="setSelectedSharedLease(row)"
                          [checked]="sharedLeaseSelection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="retailerId">
          <th *matHeaderCellDef mat-header-cell>Retailer ID</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ element.retailerId }} </td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th *matHeaderCellDef mat-header-cell>Company Name</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ element.companyName }} </td>
        </ng-container>

        <ng-container matColumnDef="leaseUuid">
          <th *matHeaderCellDef mat-header-cell>Lease ID</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ utils.displayUuid(element.leaseUuid) }} </td>
        </ng-container>

        <ng-container matColumnDef="leaseStartDate">
          <th *matHeaderCellDef mat-header-cell>Commencement Date</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ dateUtils.displayShortDate(element.leaseStartDate) }} </td>
        </ng-container>

        <ng-container matColumnDef="leaseEndDate">
          <th *matHeaderCellDef mat-header-cell>Expiry Date</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ dateUtils.displayShortDate(element.leaseEndDate) }} </td>
        </ng-container>

        <ng-container matColumnDef="leaseStatus">
          <th *matHeaderCellDef mat-header-cell>Status</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ utils.displayStatus(element.leaseStatus) }} </td>
        </ng-container>

        <ng-container matColumnDef="leasePaymentConfigStatus">
          <th *matHeaderCellDef mat-header-cell>Payment Config Status</th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ utils.displayStatus(element.leasePaymentConfigStatus) }} </td>
        </ng-container>

        <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
        <tr *matHeaderRowDef="sharedLeasesColumns" class="custom-table-header" mat-header-row></tr>
        <tr (click)="setSelectedSharedLease(row)" *matRowDef="let row; columns: sharedLeasesColumns;"
            [class.selected-row]="selectedSharedLeaseUuid === row.leaseUuid"
            class="custom-table-row"
            mat-row
        ></tr>
      </table>
      <mat-paginator #sharedLeasesPaginator [length]="sharedLeasesDatasource.data.length"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageSize]="5"
                     class="custom-paginator"></mat-paginator>
    </div>


    <!--  3. Edit payment batch groups-->

    <div fxLayout="column">
      <span class="heading-default">Payment Groups</span>

      <!--    Edit row-->
      <div *ngIf="addingGroup === true" class="edit-table-row" fxFlexOffset="20px" fxLayout="row"
           fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <div [formGroup]="addGroupForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

          <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
            <mat-label>New Group Name</mat-label>
            <input formControlName="groupName" matInput placeholder="Type here" type="text"/>
          </mat-form-field>
        </div>

        <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <button (click)="saveNewGroup()" [disabled]="!!(loader.loading$ | async)" class="button-primary"
                  mat-flat-button>
            <mat-icon>check</mat-icon>
            <div class="button-text">Save</div>
          </button>

          <button (click)="toggleAddGroup()" class="button-close-top" mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <!--Filters-->
      <div *ngIf="addingGroup === false" fxLayout="column">
        <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
          <div class="full-width" fxLayoutAlign="space-between center">
            <mat-button-toggle-group (change)="filterResults($event)"
                                     [value]="this.filterGroupForm.get('active')!.value"
                                     class="custom-date-button-toggle">
              <mat-button-toggle value="null">All</mat-button-toggle>
              <mat-button-toggle value="true">Active</mat-button-toggle>
              <mat-button-toggle value="false">Inactive</mat-button-toggle>
            </mat-button-toggle-group>

            <div *ngIf="removingRetailer">
              <app-confirm-remove (confirmRemove)="confirmRemoveOfRetailer($event)" [message]="'Do you want to remove the selected retailer \n'+
'from selected payment group.'"></app-confirm-remove>
            </div>
          </div>
        </div>
      </div>

      <!--      Table start-->
      <div fxFlexOffset="25px" fxLayout="row">
        <table [dataSource]="paymentBatchMembershipDataSource" class="custom-table half-table" fxFlex="50"
               mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="batchMembershipColumns.length" class="custom-table-label"
                mat-header-cell>
              Payment Batch Group
            </th>
          </ng-container>
          <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell>Active</th>
            <td *matCellDef="let element" class="custom-table-cell btn-cursor" mat-cell>
              <mat-slide-toggle (toggleChange)="toggleGroupActive(element)"
                                [checked]="calculateActiveStatus(element)"
                                color="primary">

              </mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="uuid">
            <th *matHeaderCellDef mat-header-cell>ID</th>
            <td *matCellDef="let element" class="custom-table-cell btn-cursor"
                mat-cell> {{utils.displayUuid(element.uuid)}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentGroupName">
            <th *matHeaderCellDef mat-header-cell>Name</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell>{{element.name}}</td>
          </ng-container>

          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="batchMembershipColumns" class="custom-table-header" mat-header-row></tr>
          <tr (click)="setSelectedGroup(row)" *matRowDef="let row; columns: batchMembershipColumns;"
              [class.selected-row]="selectedGroupUuid === row.uuid"
              class="custom-table-row btn-cursor"
              mat-row></tr>
        </table>

        <table [dataSource]="linkedRetailerTableDataSource" class="custom-table half-table" fxFlex="50" mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="leaseInMembershipColumns.length" class="custom-table-label"
                mat-header-cell></th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="$event ? linkedRetailerSelectionModel.toggle(row) : null"
                            (click)="$event.stopPropagation()"
                            *ngIf="row.userId"
                            [checked]="linkedRetailerSelectionModel.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="retailerId">
            <th *matHeaderCellDef mat-header-cell>Retailer ID</th>
            <td *matCellDef="let element" class="custom-table-cell btn-cursor"
                mat-cell> {{element.retailerId}} </td>
          </ng-container>

          <ng-container matColumnDef="companyName">
            <th *matHeaderCellDef mat-header-cell>Company Name</th>
            <td *matCellDef="let element"
                class="custom-table-cell btn-cursor" mat-cell
            > {{element.companyName}} </td>
          </ng-container>

          <ng-container matColumnDef="leaseUuid">
            <th *matHeaderCellDef mat-header-cell> Lease ID</th>
            <td *matCellDef="let element"
                class="custom-table-cell btn-cursor" mat-cell
            > {{utils.displayUuid(element.leaseUuid)}} </td>
          </ng-container>

          <ng-container matColumnDef="leaseStartDate">
            <th *matHeaderCellDef mat-header-cell>Lease Start</th>
            <td *matCellDef="let element"
                class="custom-table-cell btn-cursor" mat-cell
            > {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
          </ng-container>

          <ng-container matColumnDef="leaseEndDate">
            <th *matHeaderCellDef mat-header-cell>Lease End</th>
            <td *matCellDef="let element"
                class="custom-table-cell btn-cursor" mat-cell
            > {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell>Action</th>
            <td *matCellDef="let element"
                class="custom-table-cell btn-cursor" mat-cell
            >
              <button (click)="removeRetailerFromBatch(element)" class="button-action-cancel" mat-flat-button>
                <div class="button-text">Remove</div>
              </button>
            </td>
          </ng-container>


          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="leaseInMembershipColumns" class="custom-table-header" mat-header-row></tr>
          <tr *matRowDef="let row; columns: leaseInMembershipColumns;" class="custom-table-row" mat-row></tr>
        </table>
      </div>

      <mat-paginator #paymentGroupPaginator [length]="paymentBatchMembershipDataSource.totalElements"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageSize]="10"
                     class="custom-paginator"></mat-paginator>

    </div>
  </div>

  <mat-divider fxFlexOffset="30px"></mat-divider>

<!--  4. Retailers not in batch-->

  <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="subtitle-default">Retailers not in a payment group</span>
        <div *ngIf="!isOnePaymentGroupSelected()">
          <app-info-panel
            [message]="['Please select a payment group from the above table to proceed']"></app-info-panel>
        </div>

        <div *ngIf="addingLeaseToGroup">
          <app-confirm-remove (confirmRemove)="confirmAddLeaseToGroup($event)" [message]="'Do you want to add the selected retailer \n'+
'To selected payment group.'"></app-confirm-remove>
        </div>
      </div>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxFlexOffset="30px" fxLayout="column">
    <span class="heading-default">Retailers not batched</span>
    <form [formGroup]="notInBatchForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
          <mat-label>Lease Status</mat-label>
          <mat-select class="custom-select-field-value" placeholder="Select" [multiple]="true" formControlName="leaseStatus">
            <mat-option class="custom-select-field-option" *ngFor="let status of leaseStatuses"
                        [value]="status">{{ utils.displayStatus(status) }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
          <mat-label>Payment Config Status</mat-label>
          <mat-select class="custom-select-field-value" placeholder="Select" [multiple]="true" formControlName="leasePaymentConfigStatus">
            <mat-option class="custom-select-field-option" *ngFor="let status of leasePaymentConfigStatuses"
                        [value]="status">{{ utils.displayStatus(status) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <table [dataSource]="notInGroupDataSource" class="custom-table" fxFlex="60" fxFlexOffset="25px" mat-table
           (matSortChange)="sortData($event)" matSort>
      <ng-container matColumnDef="table-label">
        <th *matHeaderCellDef [attr.colspan]="notInGroupColumns.length" class="custom-table-label"
            mat-header-cell>
          Retailer Non Batched
        </th>
      </ng-container>

      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" class="custom-table-cell" mat-cell>
          <mat-checkbox (change)="$event ? leaseNotInGroupSelection.toggle(row) : null"
                        (click)="setSelectedLeaseNotInGroup(row)"
                        [checked]="leaseNotInGroupSelection.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="retailerId">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Retailer ID</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ element.retailerId }} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Company Name</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ element.companyName }} </td>
      </ng-container>

      <ng-container matColumnDef="leaseUuid">
        <th *matHeaderCellDef mat-header-cell>Lease ID</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ utils.displayUuid(element.leaseUuid) }} </td>
      </ng-container>

      <ng-container matColumnDef="leaseStartDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Commencement Date</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayShortDate(element.leaseStartDate) }} </td>
      </ng-container>

      <ng-container matColumnDef="leaseEndDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Expiry Date</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayShortDate(element.leaseEndDate) }} </td>
      </ng-container>

      <ng-container matColumnDef="leaseStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ utils.displayStatus(element.leaseStatus) }} </td>
      </ng-container>

      <ng-container matColumnDef="leasePaymentConfigStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Payment Config Status</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ displayConfigStatus(element.leasePaymentConfigStatus) }} </td>
      </ng-container>

      <ng-container matColumnDef="leaseBillingStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Billing Status</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ utils.displayStatus(element.leaseBillingStatus) }} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef mat-header-cell>Action</th>
        <td *matCellDef="let row" class="custom-table-cell" mat-cell>
          <button (click)="addLeaseToSelectedGroup(row)" [disabled]="!isOnePaymentGroupSelected()" class="button-action"
                  mat-flat-button>
            <div class="button-text">Add</div>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
      <tr *matHeaderRowDef="notInGroupColumns" class="custom-table-header" mat-header-row></tr>
      <tr (click)="setSelectedLeaseNotInGroup(row)" *matRowDef="let row; columns: notInGroupColumns;"
          [class.selected-row]="selectedLeaseNotInGroupUuid === row.leaseUuid"
          class="custom-table-row"
          mat-row
      ></tr>
    </table>
    <mat-paginator #retailerNotBatchedPaginator [length]="notInGroupDataSource.totalElements"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   [pageSize]="10"
                   class="custom-paginator"></mat-paginator>
  </div>
</div>
