import {Component, Input, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../environments/environment";
import {AuthenticationService} from './core/services/security/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public loggedIn!: Observable<boolean>;
  title = 'soko-console-web';
  @Input() public forgotPassword: boolean;

  constructor(private authService: AuthenticationService, private renderer: Renderer2) {
      this.forgotPassword = false;
  }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn;
  }
}
