<div class="custom-time-range-picker-wrapper" [formGroup]="range">
  <div class="time-range-label">
    <mat-icon class="material-icons-outlined">
      schedule
    </mat-icon>
    <span>{{this.tradingHours.name}}</span>
  </div>
  <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
    <input formControlName="startTime" matInput max="23:59" min="00:00" placeholder="00:00" type="time"/>
  </mat-form-field>
  <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
    <input formControlName="endTime" matInput max="23:59" min="00:00" placeholder="00:00" type="time"/>
  </mat-form-field>
</div>
