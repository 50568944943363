import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent {
  @Output() openSidenav = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<NotificationsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  close(switchView: boolean): void {
    if (switchView) {
      this.openSidenav.emit();
    }
    this.dialogRef.close();
  }
}
