<div fxLayout="column">

  <app-page-header
    (tabsChangeEvent)="changeTabs($event)"
    [selectedTab]="getInitialTab()"
    [description]="'This is the internal system pay-out module to setup and create payment batches for automatic pay-outs to retailers.'"
    [isTabbed]="true"
    [section]="'Run Pay-Outs'"
    [tabs]="tabs"
    [title]="'Setup your retailer pay-outs to run smoothly'"></app-page-header>

  <div class="full-screen-height-page-banner" fxLayout="column">
    <div *ngIf="activeTab == 'import_transactions'" class="page-content page-padding" fxLayout="column">
      <app-payout-jobcard [jobType]="BatchType.RETAILER_PAYOUT"
                          [jobNames]="['transaction_job_import_new_transactions']"
                          [offering]="offering!"
      ></app-payout-jobcard>
    </div>

    <div *ngIf="activeTab == 'process_billing'" class="page-content page-padding" fxLayout="column">
      <app-payout-jobcard [jobType]="BatchType.RETAILER_PAYOUT"
                          [jobNames]="['transaction_job_process_payout_billing']"
                          [offering]="offering!"
      ></app-payout-jobcard>
    </div>

    <div *ngIf="activeTab == 'create_batches'" class="page-content page-padding" fxLayout="column">
      <app-payout-jobcard [jobType]="BatchType.RETAILER_PAYOUT"
                          [jobNames]="['transaction_job_create_daily_payment_batch']"
                          [offering]="offering!"
      ></app-payout-jobcard>
    </div>

    <div *ngIf="activeTab == 'submit_batches'" class="page-content page-padding" fxLayout="column">
      <app-payout-jobcard [jobType]="BatchType.RETAILER_PAYOUT"
                          [jobNames]="['transaction_job_submit_daily_transactions_for_payment']"
                          [offering]="offering!"
                          [selectedBatches]="selectedBatches"
      ></app-payout-jobcard>

      <mat-divider fxFlexOffset="30px"></mat-divider>

      <app-payment-batches-list [batchType]="BatchType.RETAILER_PAYOUT" [(selectedBatches)]="selectedBatches"></app-payment-batches-list>
    </div>

    <div *ngIf="activeTab == 'setup_configs'" class="page-content page-padding" fxLayout="column">
      <app-payout-minimum-amount></app-payout-minimum-amount>

      <mat-divider fxFlexOffset="30px"></mat-divider>

      <app-payment-groups></app-payment-groups>
    </div>
  </div>



</div>
