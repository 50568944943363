import { Component } from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";

@Component({
  selector: 'app-transactions-list-view',
  templateUrl: './transactions-list-view.component.html',
  styleUrls: ['./transactions-list-view.component.scss']
})
export class TransactionsListViewComponent extends BasePageComponent {
}
