import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../../core/components/page-content/base-page.component";

@Component({
  selector: 'app-applications-view',
  templateUrl: './applications-view.component.html',
  styleUrls: ['./applications-view.component.scss']
})
export class ApplicationsViewComponent extends BasePageComponent {
  retailerId!: string

  constructor(injector: Injector) {
    super(injector);
    this.retailerId = this.data.id;
  }
}
