import {Auditable} from "../../../core/model/auditable.model";
import {GenericTypeDef} from "../../../core/model/generic-type-def.model";
import {IndustryType} from "../industry.model";
import {RetailerApplicationAssessment, ScoringBreakdown} from '../../../core/model/retailer/application-form.model';

export interface GroupedRetailerPipeline {
  bestSuited: Retailer[],
  retailerPipelines: {
    totalPages: number;
    totalElements: number;
    content: Retailer[];
  }
}
export class Retailer extends Auditable {
  companyName!: string;
  ownerId!: number;
  ownerFirstname!: string;
  ownerLastname!: string;
  ownerEmail!: string;
  phoneNumber!: string | null;
  socialLink!: string | null;
  websiteUrl!: string;
  addresses!: any[];
  contacts!: any[];
  curated!: ApplicationAssessmentStatus;
  industries!: { industryType: IndustryType }[];
  logoPicUrl?: string;
  coverImageUrl?: string;
  brandImageUrl?: string;
  curationStatusUpdateRequested?: boolean;
  curationAssessment?: RetailerApplicationAssessment;
  vatReg?: string;
  overallScore!: number | null;
  scoringBreakdown!: ScoringBreakdown | null;
  usesActiveForm?: boolean;
  tenantType!: TenantType

  constructor(obj: any) {
    super(obj)
    Object.assign(this, obj);
  }
}

export interface RegisterRetailer {
  companyName: string;
  userId: number | null;
  industries: string[];
  websiteUrl?: string;
}

export enum ApplicationAssessmentStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NEW = 'NEW'
}

export interface RetailerContact {
  id: number;
  contactType: GenericTypeDef;
  retailerId: number;
  valueCode: string;
  value: string;
}

export class RetailerBillingDetails {
  formattedAddress: string | null = null;
  billingName: string | null = null;
  compReg: string | null = null;
  vatReg: string | null = null;
  phoneNum: string | null = null;
  billingEmail: string | null = null;
  uuid: string | null = null;
}

export interface RetailerEvent {
  id: number,
  eventTypeId: number,
  eventTypeCode: string,
  eventTypeName: string,
  userId: number,
  triggeredBy: string,
  retailerId: number,
  communityId: number,
  createDate: string
}

export class RetailerFilterDto{
  id: string | null = null;
  websiteUrl: string | null = null;
  companyName: string | null = null;
  curated: string | null = null;
  industries: number[] = [];

  constructor(id: string | null, website: string | null, companyName: string | null, curated: string | null, industries: number[]) {
    this.id = id;
    this.websiteUrl = website;
    this.companyName = companyName;
    this.curated = curated;
    this.industries = industries;
  }
}

export enum TenantType {
  OFFERING = "OFFERING",
  TENANT = "TENANT"
}
