import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CustomTimeRangeChangeEvent} from './custom-time-range-change-event.interface';
import {TradingHours} from '../../../core/model/side-nav.model';
import {LoadingService} from '../../../core/services/loading.service';


@Component({
  selector: 'app-custom-time-range-picker',
  templateUrl: './custom-time-range-picker.component.html',
  styleUrls: ['./custom-time-range-picker.component.scss']
})
export class CustomTimeRangePickerComponent implements AfterViewInit {

  @Input() tradingHours!: TradingHours;
  @Output() timeRangeChange: EventEmitter<CustomTimeRangeChangeEvent> = new EventEmitter();

  loading$ = this.loader.loading$;

  range = new FormGroup({
    startTime: new FormControl<string | null>(null),
    endTime: new FormControl<string | null>(null),
  });


  constructor(public loader: LoadingService) {
    this.range.valueChanges.subscribe((values) => {
      this.timeRangeChange.emit({
        startTime: values.startTime!,
        endTime: values.endTime!
      })
    })
  }

  ngAfterViewInit(): void {
    let startTime: string | undefined = this.tradingHours.startTime;
    let endTime: string | undefined = this.tradingHours.endTime;
    if (startTime) {
      this.range.get('startTime')!.setValue(startTime);
    }
    if (endTime) {
      this.range.get('endTime')!.setValue(endTime);
    }
  }

}
