<div fxLayout="column">
  <app-page-header [section]="'Pipeline'" [title]="'Explore your curated leads'" [description]="'This is your leads with all the retailers that applied to your retail offering categorised according to their qualification status.'"></app-page-header>

  <div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

    <div fxFlexOffset="20px">
      <div fxLayout="row">
        <span class="subtitle-default">Statistics</span>
      </div>
    </div>

    <div fxLayout="row" fxFlexOffset="30px" fxLayoutGap="20px">

      <div class="info-card pt-3" fxLayout="row" fxLayoutGap="30px">

        <div class="full-width" fxLayout="column" fxLayoutGap="30px" fxFlex="35">

          <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="title-regular">Pipeline Overview</div>
            <mat-icon>more_vert</mat-icon>
          </div>


          <div class="dashboard-card full-width" fxLayout="column" fxLayoutGap="10px" fxFlexOffset="30px">
            <div class="dashboard-icon">
              <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div class="subtitle-default">Pipeline</div>
              <div class="body-1-default">{{ approvedRetailers }}</div>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <mat-divider></mat-divider>
              <div class="body-1-regular-2">Amount of approved applications indicating good suitability</div>
            </div>
          </div>

          <div class="dashboard-card full-width" fxLayout="column" fxLayoutGap="10px">
            <div class="dashboard-icon">
              <mat-icon class="material-icons-outlined">store</mat-icon>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div class="subtitle-default">Rejected Applications</div>
              <div class="body-1-default">{{ rejectedRetailers }}</div>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <mat-divider></mat-divider>
              <div class="body-1-regular-2">Amount of rejected applications indicating poor suitability</div>
            </div>
          </div>
        </div>

        <div class="full-width" fxLayout="column" fxLayoutAlign="start end" fxFlex="65">
          <app-custom-date-filter [customDateRangePresets]="dateFilters"
                                  [initialPreset]="'last_month'"
                                  (dateRange)="getDateRange($event)"></app-custom-date-filter>

          <div class="full-width" fxFlexOffset="20px" style="height: 350px">
            <canvas  class="dashboard-canvas" baseChart *ngIf="!pipelineChartLoading; else canvasLoadingSpinner"
                     [data]="chartData"
                     [plugins]="barChartPlugins"
                     [options]="barChartOptions"
                     [type]="'bar'"></canvas>
          </div>

          <ng-template #canvasLoadingSpinner>
            <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
              <mat-spinner color="primary" diameter="30"></mat-spinner>
            </div>
          </ng-template>

        </div>

      </div>

    </div>

    <mat-divider fxFlexOffset="30px"></mat-divider>

    <div fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutGap="20px">
        <span class="subtitle-default">Pipeline</span>
        <button class="button-action" mat-flat-button
                (click)="openCreateLease()"
                [disabled]="loader.loading$ | async">
          <div class="button-text">Create a lease</div>
        </button>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
      <span class="heading-default"> Qualified applicants pipeline </span>
      <form [formGroup]="tableFilterForm" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">

          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Search here" formControlName="companyName">
          </mat-form-field>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" style="width: 270px;">
            <mat-label>Industry And Product Collection</mat-label>
            <mat-select class="custom-select-field-value" formControlName="industryTypes" multiple [(value)]="selectedIndustries">
              <mat-optgroup class="btn-cursor" *ngFor="let group of allIndustries">
                <mat-label class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" (click)="groupClicked(group)">
                  <span>{{group.name}}</span>
                  <mat-icon class="btn-cursor">add</mat-icon>
                </mat-label>
                <mat-option class="custom-select-field-option" *ngFor="let ind of group.industries" [value]="ind.code" (click)="optionClicked(group, ind)">{{ ind.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Application form version</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" formControlName="usesActiveForm">
              <mat-option class="custom-select-field-option" [value]="true">Current</mat-option>
              <mat-option class="custom-select-field-option" [value]="false">Previous</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always" fxFlexOffset="20px">
            <mat-label>Preferred and Passed Above</mat-label>
            <input matInput type="number" placeholder="Search here" formControlName="preferredAndPassedAbove">
          </mat-form-field>

          <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
            <mat-label>Overall Score Above</mat-label>
            <input matInput type="number" placeholder="Search here" formControlName="overallScoreAbove">
          </mat-form-field>

        </div>
      </form>

      <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Pipeline </th>
        </ng-container>

        <ng-container matColumnDef="createDate">
          <th mat-header-cell *matHeaderCellDef> Application Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.retailerRegistrationDate)}}</td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.companyName, 20)}} </td>
        </ng-container>

        <ng-container matColumnDef="industryType">
          <th mat-header-cell *matHeaderCellDef> Industry or Product Collection </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayString(displayIndustryList(element.industryTypes), 30)}} </td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef> Application form and version </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element">
            <div [class]="status.className" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="getApplicationVersion(element) as status">
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-icon [class.material-icons-outlined]="status.outline">{{status.icon}}</mat-icon>
              </div>
              <span class="footer-default">{{status.status}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="preferredAndPassed">
          <th mat-header-cell *matHeaderCellDef> Preferred And Passed </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ element.scoringBreakdown ? element.scoringBreakdown.preferredAndPassed + '%' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="firstRating">
          <th mat-header-cell *matHeaderCellDef> 1<sup>st</sup> Rating results </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ element.scoringBreakdown ? element.scoringBreakdown.firstRating + '%' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="secondThirdRating">
          <th mat-header-cell *matHeaderCellDef> 2<sup>nd</sup> and 3<sup>rd</sup> Rating results </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ element.scoringBreakdown ? element.scoringBreakdown.secondThirdRating + '%' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="overallScore">
          <th mat-header-cell *matHeaderCellDef> Overall Score </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-progress-bar class="score-progress-bar" color="primary" mode="determinate" [value]="element.overallScore"></mat-progress-bar>
              <div *ngIf="element.overallScore"> {{element.overallScore}}% </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;" (click)="openPipelineViewTab(row.id)"></tr>
      </table>
      <mat-paginator #paginator class="custom-paginator"
                     [length]="dataSource.totalElements"
                     [pageSize]="50"
                     [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>

</div>
