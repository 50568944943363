import {Component, Input, OnChanges} from '@angular/core';
import {DashboardUtilsService} from "../../dashboard-utils.service";
import {MonthlyStat} from "../../dashboards.model";

@Component({
  selector: 'app-dashboards-best-stats',
  templateUrl: './dashboards-best-stats.component.html',
  styleUrls: ['./dashboards-best-stats.component.scss']
})
export class DashboardsBestStatsComponent implements OnChanges{
  @Input() bestMonthlyStats: MonthlyStat[] = []

  constructor(public dashboardUtils: DashboardUtilsService) {
  }

  ngOnChanges() {
    while (this.bestMonthlyStats.length < 3) this.bestMonthlyStats.push(Object.create(null));
  }

  get displayBestStats(): boolean {
    if (!this.bestMonthlyStats) return false;
    if (this.bestMonthlyStats.length == 0) return false;
    return !this.bestMonthlyStats.every(item => item !== null && typeof item === 'object' && Object.getPrototypeOf(item) === null);
  }
}
