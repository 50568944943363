import {Component} from '@angular/core';
import {LoadingService} from '../../../../../core/services/loading.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DistrictPayoutsConfigService} from '../../service/district-payouts-config.service';
import {lastValueFrom} from 'rxjs';
import {DistrictPaymentConfig, UpdateMinPayoutRequest} from '../../model/district-payout-config.model';
import {RunPayoutsConfigService} from '../../service/run-payouts-config.service';
import {LeaseNotInPaymentBatch} from '../../../transactions/transactions.model';

@Component({
  selector: 'app-payout-minimum-amount',
  templateUrl: './payout-minimum-amount.component.html',
  styleUrls: ['./payout-minimum-amount.component.scss']
})
export class PayoutMinimumAmountComponent {
  edit: boolean = false;
  amountForm: FormGroup;
  newRetailersBool: boolean = false;


  constructor(public loader: LoadingService,
              private payoutsService: DistrictPayoutsConfigService,
              private runPayoutConfig: RunPayoutsConfigService) {
    this.amountForm = new FormGroup({
      uuid: new FormControl({value: '', disabled: false}),
      amount: new FormControl({value: 0, disabled: true}, [Validators.required, Validators.min(0)])
    });

    this.amountForm.disable();
    this.runPayoutConfig.newRetailers.subscribe((val) => {
      this.newRetailersBool = val;
    });
    this.resetForm();
  }


  saveMinimumAmount(): void {
    const request: UpdateMinPayoutRequest = {
      uuid: this.amountForm.get('uuid')!.value,
      applyPayoutMinAmount: true,
      payoutMinAmount: this.amountForm.get('amount')!.value
    }
    lastValueFrom(this.payoutsService.updateMinPayoutAmount(request))
      .then((res: DistrictPaymentConfig) => {
        this.amountForm.get('amount')!.setValue(res.payoutMinAmount);
      })
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        this.toggleEdit();
      })
  }

  toggleEdit(): void {
    this.edit = !this.edit;
    if (this.edit) {
      this.amountForm.enable();
    } else {
      this.amountForm.disable();
    }
  }

  getLeaseNotBatchedStatus(): Promise<boolean> {
    return lastValueFrom(this.runPayoutConfig.getLeaseNotInGroup())
      .then((res: { content: LeaseNotInPaymentBatch[] }) => {
        if (res.content.length > 0) {
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      })
  }

  private resetForm(): void {
    lastValueFrom(this.payoutsService.getConfigForOffering())
      .then((res: DistrictPaymentConfig) => {
        this.amountForm.get('uuid')!.setValue(res.uuid);
        this.amountForm.get('amount')!.setValue(res.payoutMinAmount);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
