import {HttpClient} from "@angular/common/http";
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {DateRange, MatDatepicker} from "@angular/material/datepicker";
import {BasePageComponent} from "../../../core/components/page-content/base-page.component";
import {LocationOffering} from "../../../core/model/side-nav.model";
import {
  CustomDateRangeChangeEventModel
} from "../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CustomDateRangeModel} from "../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {DashboardChart, MonthlyStat, StatisticType} from "../dashboards.model";
import {build as buildDashboardService, DashboardsLeadsReportService} from "./dashboards-leads-report.service";
import {LeadsDashboardChartConfig} from "./leads-dashboard-chart-config";

@Component({
  selector: 'app-dashboards-leads-report',
  templateUrl: './dashboards-leads-report.component.html',
  styleUrls: ['./dashboards-leads-report.component.scss']
})
export class DashboardsLeadsReportComponent extends BasePageComponent implements OnInit, OnDestroy {
  chartConfig: LeadsDashboardChartConfig;
  dashboardService: DashboardsLeadsReportService;

  // best stats

  bestMonthlyStats: MonthlyStat[] = [];

  // dashboard charts
  newQualifiedLeadsChart: DashboardChart<'line'>;
  approvalRatesChart!: DashboardChart<'line'>;
  leadCategoriesChart!: DashboardChart<'bar' | 'line'>;

  // filtering

  dateRanges: DateRange<Date | null>[] = [
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null)
  ];

  year2 = new Date();
  year1 = new Date(this.year2.getFullYear() - 1, 1, 1);

  customDateRangePresets: CustomDateRangeModel[] = []
  dbOffering!: LocationOffering;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              injector: Injector,
              chartOptionsService: ChartOptionsService,
              httpClient: HttpClient,
              public dashboardUtils: DashboardUtilsService) {
    super(injector);
    this.dashboardService = buildDashboardService(httpClient);
    this.dbOffering = this.data.offering;
    this.chartConfig = new LeadsDashboardChartConfig(this, chartOptionsService, dashboardUtils);

    this.newQualifiedLeadsChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.newQualifiedLeads.options,
      chartType: 'line',
      stats: {
        year1Leads: new MonthlyStat('Qualified Leads ' + this.year1.getFullYear(), 'year1Leads', 'Total amount of prospective tenant with an approved application', StatisticType.INTEGER),
        year2Leads: new MonthlyStat('Qualified Leads ' + this.year2.getFullYear(), 'year2Leads', 'Total amount of prospective tenant with an approved application', StatisticType.INTEGER),
      },
      loading: false
    };

    this.approvalRatesChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.approvalRates.options,
      chartType: 'line',
      stats: {
        averageApprovalRate: new MonthlyStat('Average approval rate', 'averageApprovalRate', 'Average percentage of prospective tenants approved as a qualified lead', StatisticType.PERCENTAGE),
        averageLeadTime: new MonthlyStat('Average time to approve', 'averageLeadTime', 'Average time it takes from when an application is received until its approval', StatisticType.DAYS),
      },
      loading: false
    };

    this.leadCategoriesChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      config: this.chartConfig.leadCategories.options,
      chartType: 'bar',
      stats: {
        categoriesWithLeads: new MonthlyStat('Categories with leads', 'categoriesWithLeads', 'Total amount of lead categories', StatisticType.INTEGER),
      },
      loading: false
    };
  }

  ngOnInit() {
    this.loadBestMonthlyStats();
    this.loadNewQualifiedLeadsChart();
    this.setCustomDateRange();
  }

  ngOnDestroy() {
    this.dashboardService.flush();
  }

  setCustomDateRange() {
    this.customDateRangePresets = this.dashboardUtils.customDateRangeChartPreset(this.dbOffering.offeringStartDate!);
  }

  loadBestMonthlyStats() {
    const start = this.dbOffering.offeringStartDate!;
    const end = new Date();
    const next = this.chartConfig.bestMonthlyStats.next;
    const error = this.chartConfig.bestMonthlyStats.error;
    this.dashboardService.refreshBestMonthlyStats(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadNewQualifiedLeadsChart() {
    this.newQualifiedLeadsChart.loading = true;
    const start = this.year1;
    const end = this.year2;
    const next = this.chartConfig.newQualifiedLeads.next;
    const error = this.chartConfig.newQualifiedLeads.error;
    this.dashboardService.refreshNewQualifiedLeadsChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadApprovalRatesChart() {
    this.approvalRatesChart.loading = true;
    const start = this.dateRanges[0].start ? this.dateRanges[0].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[0].end ? this.dateRanges[0].end : new Date();
    const next = this.chartConfig.approvalRates.next;
    const error = this.chartConfig.approvalRates.error;
    this.dashboardService.refreshApprovalRatesChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  loadLeadCategoriesChart() {
    this.leadCategoriesChart.loading = true;
    const start = this.dateRanges[1].start ? this.dateRanges[1].start : this.dbOffering.offeringStartDate!;
    const end = this.dateRanges[1].end ? this.dateRanges[1].end : new Date();
    const next = this.chartConfig.leadCategories.next;
    const error = this.chartConfig.leadCategories.error;
    this.dashboardService.refreshLeadCategoriesChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
  }

  getDateRange(event: CustomDateRangeChangeEventModel, index: number) {
    this.dateRanges[index] = event.dateRange;

    if (event.dateRange.start && event.dateRange.end) {
      switch (index) {
        case 0:
          this.loadApprovalRatesChart();
          break;
        case 1:
          this.loadLeadCategoriesChart();
          break;
      }
    }
  }

  datePickerUpdated() {
    this.newQualifiedLeadsChart.stats['year1Leads'].statName = 'Qualified Leads ' + this.year1.getFullYear();
    this.newQualifiedLeadsChart.stats['year2Leads'].statName = 'Qualified Leads ' + this.year2.getFullYear();
    this.loadNewQualifiedLeadsChart();
  }

}
