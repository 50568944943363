import {GenericTypeDef} from './generic-type-def.model';

export class PageOffering {
  uuid!: string;
  label!: string;
  offeringType!: string;
  selected: boolean = true;
  offeringUuid!: string;
  offeringAddress?: string;
  offeringStartDate?: string
  tileImage?: string;
  bannerImage?: string;
  floorPlanImage?: string;
  offeringListing?: OfferingListing;


  constructor(uuid: string,
              label: string,
              offeringType: string,
              offeringUuid: string,
              offeringAddress?: string,
              offeringStartDate?: string,
              tileImage?: string,
              bannerImage?: string,
              floorPlanImage?: string,
              offeringListing?: OfferingListing) {
    this.uuid = uuid;
    this.label = label;
    this.offeringType = offeringType;
    this.offeringUuid = offeringUuid;
    this.offeringAddress = offeringAddress;
    this.offeringStartDate = offeringStartDate
    this.offeringListing = offeringListing;
    this.tileImage = tileImage;
    this.bannerImage = bannerImage;
    this.floorPlanImage = floorPlanImage;
  }
}

export class PageSection {
  uuid!: string;
  offeringUuid!: string;
  offering?: PageOffering;
  code: string;
  label!: string;
  selected: boolean = false;
  icon!: string

  constructor(uuid: string, offeringUuid: string, label: string, code: string, icon: string, offering?: PageOffering) {
    this.uuid = uuid;
    this.offeringUuid = offeringUuid;
    this.label = label;
    this.code = code;
    this.offering = offering;
    this.icon = icon;
  }
}

export class PageSectionItem {
  id!: string;
  sectionUuid!: string;
  label!: string;
  selected: boolean = false;
  componentToRegister!: any;
  pageName!: string;
  pageHeader!: string;
  data!: object;
  offeringId!: string;
  sectionCode!: string;
  pageCode!: string;
  openType!: string;
  offering?: PageOffering;
  featureCodes!: string[]

  constructor(uuid: string,
              sectionUuid: string,
              label: string,
              component: any,
              pageName: string,
              pageHeader: string,
              data: any,
              offeringId: string,
              sectionCode: string,
              pageCode: string,
              openType: string,
              featureCodes: string[],
              offering?: PageOffering) {
    this.id = uuid;
    this.sectionUuid = sectionUuid;
    this.label = label;
    this.componentToRegister = component;
    this.pageName = pageName;
    this.pageHeader = pageHeader;
    this.data = data;
    this.offeringId = offeringId;
    this.sectionCode = sectionCode;
    this.pageCode = pageCode;
    this.openType = openType;
    this.offering  = offering;
    this.featureCodes = featureCodes;
  }
}

export interface LocationOffering {
  uuid: string,
  locationUuid: string,
  locationCode: string,
  bannerImage: string,
  tileImage: string,
  floorPlanImage: string,
  platformOfferingType: string,
  offeringUuid: string,
  landlordUuid: string,
  offeringName: string,
  description: string,
  offeringStartDate?: string,
  offeringEndDate?: string | null,
  offeringCode?: string,
  offeringAddress?: string
  offeringListing?: OfferingListing;
  embedKey?: string;
}

export interface OfferingListing {
  uuid?: string
  locationOfferingUuid: string;
  description: string | null,
  tradingHours: TradingHours[] | null,
  offeringListingIndustries: GenericTypeDef[]
}

export interface TradingHours {
  name: string;
  startTime?: string;
  endTime?: string;
}

export interface PageSectionInfo {
  code: string,
  label: string,
  icon: string
}

export interface PageSectionItemInfo {
  label: string,
  componentToRegister: any,
  pageName: string,
  idPrefix: string,
  sectionCode: string,
  pageCode: string,
  openType: string,
  data: any,
  featureCodes: string[]
}
